<div class="row options-panel">
  <span class="pagination-counter skeleton-loader-item"></span>

  <span class="spacer"></span>
  <span class="list-options">
    <button mat-icon-button class="skeleton-loader-item" disabled>
      <mat-icon></mat-icon>
    </button>

    <button *ngIf="expandAll" mat-icon-button (click)="expandAll = false">
      <mat-icon>unfold_less</mat-icon>
    </button>

    <button *ngIf="!expandAll" mat-icon-button (click)="expandAll = true">
      <mat-icon>unfold_more</mat-icon>
    </button>
  </span>
</div>

<mat-accordion [multi]="true">
  <mat-expansion-panel *ngFor="let item of items" [expanded]="expandAll">
    <mat-expansion-panel-header>
      <mat-panel-title class="full-width">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-1 d-none d-sm-block">
              <button
                mat-icon-button
                class="skeleton-loader-item"
                disabled
              ></button>
            </div>
            <div class="col-sm-5" style="padding-left: 0px; padding-right: 0px">
              <div>
                <div class="attribute skeleton-loader-item">
                  <!-- Attribute -->
                </div>
                <div class="attribute-value skeleton-loader-item">
                  <!-- Attribute Value -->
                </div>
              </div>
            </div>
            <div class="col-sm-5 d-none d-sm-block">
              <div>
                <div class="attribute skeleton-loader-item">
                  <!-- Attribute -->
                </div>
                <div class="attribute-value skeleton-loader-item">
                  <!-- Attribute Value -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="row">
      <div class="col-sm-4 d-block">
        <div>
          <div class="attribute skeleton-loader-item">
            <!-- Attribute -->
          </div>
          <div class="attribute-value skeleton-loader-item">
            <!-- Attribute Value -->
          </div>
        </div>
      </div>
      <div class="col-sm-4 d-block">
        <div>
          <div class="attribute skeleton-loader-item">
            <!-- Attribute -->
          </div>
          <div class="attribute-value skeleton-loader-item">
            <!-- Attribute Value -->
          </div>
        </div>
      </div>

      <div class="col-sm-4 d-block">
        <div>
          <div class="attribute skeleton-loader-item">
            <!-- Attribute -->
          </div>
          <div class="attribute-value skeleton-loader-item">
            <!-- Attribute Value -->
          </div>
        </div>
      </div>
    </div>

    <div class="row action-button-row">
      <div>
        <button
          class="action-button btn skeleton-loader-item"
          mat-flat-button
          disabled
        >
          <span></span>
        </button>
        <button
          class="action-button skeleton-loader-item"
          mat-icon-button
          disabled
        ></button>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div class="row">
  <div class="col-sm-6">
    <button class="show-more-button skeleton-loader-item" mat-button></button>
  </div>

  <div class="col-sm-6">
    <button class="show-more-button skeleton-loader-item" mat-button></button>
  </div>
</div>
