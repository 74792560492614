import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { RESPONSE_STATUSES } from '@shared/constants';
import { MaterialIconResponseDTO } from '@shared/dtos';
import { getNavigationRegex } from '@shared/utils';

import { environment } from '../../../../../environments/environment';
import { noWhitespaceValidator } from '../../../../modules/core/helpers/form-field-white-space-validator';
import {
  CreateQuickAction,
  IQuickAction,
  QuickActionService,
} from '../../../../services/quick-action.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../../../services/snackbar.service';

interface AddQuickActionPopupData {
  pathURL?: string;
}

@Component({
  selector: 'app-add-quick-action',
  templateUrl: './add-quick-action.component.html',
  styleUrls: ['./add-quick-action.component.scss'],
})
export class AddQuickActionComponent implements OnInit {
  isLoading = false;
  searchCount = new Array(50);
  recentlyUsedIconsSkeletonCount = new Array(6);
  recentSkeleton = 5;
  recentlyUsedIcons = [];
  quickActions: IQuickAction[] = [];
  environmentUrl: string;

  isIconClicked = false;
  clickedIcon = '';

  quickActionFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl(null, [
      Validators.required,
      noWhitespaceValidator,
    ]),
    icon: new UntypedFormControl(null, Validators.required),
    link: new UntypedFormControl(null, []),
  });

  icons: MaterialIconResponseDTO[] = [];
  searchResults: MaterialIconResponseDTO[] = [];
  iconsName: string[] = [];

  topPosToStartShowing = 100;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddQuickActionPopupData,
    private quickActionService: QuickActionService,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private dialogRefCategory: MatDialogRef<AddQuickActionComponent>
  ) {
    this.environmentUrl = environment.WEB_URL;
  }

  ngOnInit(): void {
    this.isLoading = false;
    // gets the recently used 6 icons

    this.getQuickActions(() => {
      const iconsArray = {
        icons: this.quickActions.map((quickAction) => ({
          icon: quickAction.icon,
        })),
      };
      this.recentlyUsedIcons = [...iconsArray.icons].reverse().slice(0, 6);

      if (this.data?.pathURL) {
        this.quickActionFormGroup.controls.link.setValue(this.data.pathURL);
      } else {
        this.quickActionFormGroup.controls.link.setValidators([
          Validators.required,
          Validators.pattern(getNavigationRegex(environment.WEB_DOMAIN)),
        ]);
      }

      this.isLoading = true;
      this.getIconList();
    });
  }

  getQuickActions(_callback) {
    this.quickActionService.getAllQuickActions().subscribe((res) => {
      if (res) {
        this.isLoading = false;
        res.data.forEach((item) => {
          this.quickActions.push({
            _id: item._id,
            title: item.title,
            link: decodeURIComponent(
              item.link.replace(getNavigationRegex(environment.WEB_DOMAIN), '')
            ),
            icon: item.icon,
            index_number: item.index_number,
          });
          this.quickActions.sort((a, b) => {
            return a.index_number - b.index_number;
          });
        });
      }
      _callback();
    });
  }

  getIconList() {
    this.quickActionService.iconsList.subscribe((data) => {
      if (data && data.length > 0) {
        this.icons = data;
        this.iconsName = this.icons.map((item) => item.name);
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } else {
        this.quickActionService.getMaterialIconsList().subscribe({
          next: (res) => {
            if (res) {
              this.icons = [];
              this.icons = res.data.icons;
              this.iconsName = [];
              res.data.icons.forEach((item) => {
                this.iconsName.push(item.name);
              });
              setTimeout(() => {
                this.isLoading = false;
              }, 500);
            }
          },
          error: () => {
            this.isLoading = false;
          },
        });
      }
    });
  }

  applyFilter(event: Event, name: string = ''): void {
    let filterValue = name;

    if (event && name === '') {
      filterValue = (event.target as HTMLInputElement).value;
      this.isIconClicked = false;
    }

    const searchKey = filterValue.trim().toLowerCase();
    const results = this.icons.filter((icon) => icon.name.includes(searchKey));

    if (this.clickedIcon === searchKey) {
      // Show all icons again
      this.searchResults = this.icons;
    } else {
      this.searchResults = results;
    }
  }

  onSelectIcon(name: string, event: Event): void {
    this.isIconClicked = true;
    this.clickedIcon = name;
    this.quickActionFormGroup.controls.icon.setValue(name);
    this.applyFilter(event, name);
    window.scroll(0, 0);
  }

  onCreateQuickAction(): void {
    const data: CreateQuickAction = {
      title: this.quickActionFormGroup.value.title,
      link: this.data.pathURL
        ? this.data.pathURL
        : this.quickActionFormGroup.value.link,
      icon: this.quickActionFormGroup.value.icon,
    };
    this.quickActionService.createQuickAction(data).subscribe({
      next: (res) => {
        if (res.statusCode === RESPONSE_STATUSES.SUCCESS) {
          this.dialogRefCategory.close(res.data);
          this.snackbar.success(
            SUCCESS_TYPES.SAVED,
            this.translate.instant('pages.quick-actions.create.quick-action')
          );
        } else {
          this.snackbar.error(res.message);
        }
      },
      error: (err) => {
        this.snackbar.error(err);
      },
    });
  }

  clearFilter() {
    this.quickActionFormGroup.controls.icon.setValue('');
    this.isIconClicked = false;
  }
}
