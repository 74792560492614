<button
  *ngIf="colorSchemeService.currentActive() === 'light'"
  mat-menu-item
  data-testid="theme_btn_toggle_theme_light"
  (click)="toggleTheme()"
  matTooltip="{{
    'pages.dashboard.dark-mode-tooltip' | translate
  }} (Alt/ ⌥ + D)"
>
  <mat-icon matListIcon>brightness_high</mat-icon
  >{{ 'core.dark-mode.dark' | translate }}
</button>

<button
  *ngIf="colorSchemeService.currentActive() === 'dark'"
  mat-menu-item
  data-testid="theme_btn_toggle_theme_dark"
  (click)="toggleTheme()"
  matTooltip="{{
    'pages.dashboard.light-mode-tooltip' | translate
  }} (Alt/ ⌥ + D)"
>
  <mat-icon matListIcon>brightness_low</mat-icon
  >{{ 'core.dark-mode.light' | translate }}
</button>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
