<mat-chip
  (click)="$event.stopPropagation()"
  (focus)="$event.stopPropagation()"
  class="mat-chip"
  [disabled]="loading || selectedDataChipProperties?.properties?.disabled"
  [removable]="
    (isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()) &&
    !loading
  "
  (removed)="remove(selectedData)"
>
  <label class="has-float-label">
    <mat-select
      disableOptionCentering
      *ngIf="!multi"
      [matTooltip]="selectedOption?.key || selectedData?.name || ''"
      #select
      class="chip-input"
      [style.width.px]="getInputWidth(selectedItemLength, label)"
      (click)="$event.stopPropagation()"
      (focus)="$event.stopPropagation()"
      [style.vertical-align]="
        selectedDataChipProperties?.value || this.inputFocusOn
          ? 'sub'
          : 'baseline'
      "
      (keydown)="$event.stopImmediatePropagation()"
      [formControl]="selectItems"
      [value]="selectItems.value"
      attr.data-testid="cis_select_single_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}"
    >
      <mat-option
        attr.data-testid="cis_select_single_{{
          selectedData?.name?.toLowerCase()?.replace(' ', '_')
        }}_option_{{ option.key.toLowerCase().replace(' ', '_') }}"
        *ngFor="let option of options | async"
        [value]="option.value"
      >
        {{ option.key }}
      </mat-option>
    </mat-select>

    <mat-select
      disableOptionCentering
      multi
      *ngIf="multi"
      [matTooltip]="
        (selectedOption?.key && value?.length > 1
          ? selectedOption?.key + suffixDependsOnSelectedItems
          : value?.length === 1
          ? selectedOption?.key
          : selectedData?.name) ?? ''
      "
      class="chip-input"
      [style.width.px]="getInputWidth(selectedItemLength, label)"
      (click)="$event.stopPropagation()"
      (focus)="$event.stopPropagation()"
      (keydown)="$event.stopImmediatePropagation()"
      [style.vertical-align]="
        selectedDataChipProperties?.value || this.inputFocusOn
          ? 'sub'
          : 'baseline'
      "
      [formControl]="selectItems"
      [value]="selectItems.value"
      attr.data-testid="cis_select_multi_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}"
    >
      <mat-select-trigger>
        {{ selectItems.value ? this.selectedOption?.key : '' }}
        <span *ngIf="(selectItems.value?.length ?? 0) > 1">
          {{ suffixDependsOnSelectedItems }}
        </span>
      </mat-select-trigger>
      <mat-option
        attr.data-testid="cis_select_multi_{{
          selectedData?.name?.toLowerCase()?.replace(' ', '_')
        }}_option_{{ option.key.toLowerCase().replace(' ', '_') }}"
        *ngFor="let option of options | async"
        [value]="option.value"
      >
        {{ option.key }}
      </mat-option>
    </mat-select>
    <div #label class="label" [ngClass]="selectItems.value ? 'selected' : ''">
      {{ selectedData?.name ?? '' | translate }}
    </div>
  </label>

  <mat-icon
    matChipRemove
    *ngIf="
      isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()
    "
    [style.cursor]="loading ? 'context-menu' : 'pointer'"
    style="vertical-align: text-top"
    attr.data-testid="cis_select_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_btn_cancel"
    >cancel</mat-icon
  >
  <mat-icon
    *ngIf="isChipMandatory()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_select_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_mandatory"
    >push_pin</mat-icon
  >
  <mat-icon
    *ngIf="isChipLock()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_select_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_locked"
    >lock</mat-icon
  >
</mat-chip>
