<div class="align-items-center justify-content-between d-flex">
  <div>
    <h1 mat-dialog-title class="d-flex align-items-center mb-0">
      <mat-icon class="mr-2">mail</mat-icon>
      {{ 'configuration-fields.set-emails.title' | translate }}
    </h1>
    <mat-card-subtitle>{{
      'configuration-fields.set-emails.subtitle' | translate
    }}</mat-card-subtitle>
  </div>
  <div class="ml-3">
    <button
      type="button"
      (click)="addNewContact()"
      mat-stroked-button
      id="configuration-fields-set-add-btn-add-contacts"
      [matTooltip]="'configuration-fields.set-emails.add-new-btn' | translate"
    >
      +&nbsp;{{ 'configuration-fields.set-emails.add-new-btn' | translate }}
    </button>
  </div>
</div>

<mat-divider></mat-divider>

<mat-dialog-content class="mt-4">
  <div *ngFor="let contactEmail of contactEmails; let i = index">
    <form [formGroup]="contactEmail">
      <ng-container>
        <div class="row">
          <div class="col-12">
            <mat-radio-group formControlName="is_primary">
              <mat-radio-button
                class="pt-3"
                color="primary"
                [value]="true"
                (click)="$event.stopPropagation(); radioChange(i)"
                >{{ 'configuration-fields.set-emails.use-primary' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row" style="overflow: visible">
          <div class="col-4">
            <mat-form-field
              style="width: 100%; font-size: 14px"
              appearance="fill"
            >
              <mat-label>{{
                'configuration-fields.set-emails.type' | translate
              }}</mat-label>
              <mat-select formControlName="type" id="contacts-type">
                <mat-option [value]="CONTACT_EMAIL_TYPES.PERSONAL">{{
                  'configuration-fields.set-emails.contact-types.personal'
                    | translate
                }}</mat-option>
                <mat-option [value]="CONTACT_EMAIL_TYPES.WORK">{{
                  'configuration-fields.set-emails.contact-types.work'
                    | translate
                }}</mat-option>
                <mat-option [value]="CONTACT_EMAIL_TYPES.EDUCATIONAL">{{
                  'configuration-fields.set-emails.contact-types.educational'
                    | translate
                }}</mat-option>
              </mat-select>
              <mat-error>{{
                'configuration-fields.set-emails.error-messages.type-error'
                  | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-7" style="margin-left: -15px">
            <mat-form-field
              appearance="fill"
              style="width: 100%; font-size: 14px"
            >
              <mat-label>{{
                'configuration-fields.set-emails.email' | translate
              }}</mat-label>
              <input
                matInput
                formControlName="email"
                required
                id="email"
                type="email"
                [placeholder]="
                  'identities.create-user.email-placeholder' | translate
                "
                email="true"
              />
              <mat-error *ngIf="contactEmail.get('email').hasError('email')">
                {{
                  'configuration-fields.set-emails.error-messages.email-error'
                    | translate
                }}
              </mat-error>
              <mat-error
                *ngIf="contactEmail.get('email').hasError('required')"
                >{{
                  'configuration-fields.set-emails.error-messages.email-error'
                    | translate
                }}</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-1">
            <button
              class="my-2 center"
              mat-icon-button
              [disabled]="
                contactEmail.controls.is_primary.value && this.isRequired
              "
              (click)="onDeleteClicked(i)"
              [matTooltip]="
                'configuration-fields.set-emails.email-delete-btn' | translate
              "
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>

        <mat-divider></mat-divider>
      </ng-container>
    </form>
  </div>

  <div *ngIf="contactEmails.length === 0" style="align-items: center">
    <app-no-results-found></app-no-results-found>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  class="d-flex justify-content-end"
  style="overflow: visible"
>
  <button
    mat-button
    class="m-2"
    [mat-dialog-close]="false"
    id="configuration-fields-set-add-cancel"
  >
    {{ 'configuration-fields.set-emails.btn-cancel' | translate }}
  </button>
  <button
    type="button"
    (click)="clearAll()"
    mat-stroked-button
    id="configuration-fields-clear-all-btn"
    [matTooltip]="'configuration-fields.set-emails.clear-all-btn' | translate"
  >
    {{ 'configuration-fields.set-emails.clear-all-btn' | translate }}
  </button>
  <button
    [disabled]="isNotValid()"
    type="submit"
    mat-raised-button
    (click)="onSaveClicked()"
    color="primary"
    class="m-2"
    id="configuration-fields-set-add-save"
  >
    {{ 'configuration-fields.set-emails.btn-save' | translate }}
  </button>
</mat-dialog-actions>
