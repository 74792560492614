export enum CONNECTED_STORAGE_STATUS {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum EXTERNAL_SHARE_SECURITY_TYPES {
  NONE = 'NONE',
  PASSWORD = 'PASSWORD',
  OTP = 'OTP',
}

export enum DRIVE_VIEW_MODES {
  PERSONAL = 'Personal',
  SHARED = 'Shared',
}

export enum EXTERNAL_SHARE_VALIDATION_TYPE {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export enum ENTITIES_SORT_TYPES {
  NAME = 'name',
  TYPE = 'type',
  SIZE = 'size',
  CREATED_ON = 'created_on',
  LAST_MODIFIED_ON = 'last_modified_on',
  LAST_READ = 'last_read',
}

export enum DMS_STATUS {
  INTERNAL = 'INTERNAL', // internal STORAGE is in use (0 config exist)
  EXTERNAL = 'EXTERNAL', // external STORAGE is in use (1 config exist)
  INTERNAL_PENDING = 'INTERNAL_PENDING', // internal STORAGE is in use & there is a pending external config (1 config exist)
  EXTERNAL_PENDING = 'EXTERNAL_PENDING', // external STORAGE is in use & there is a pending external config (2 configs exist)
  INTERNAL_DISCONNECTED = 'INTERNAL_DISCONNECTED', // internal STORAGE is in use (1 config exist)
}

export enum DMS_CONFIG_STATUS {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
}

export enum STORAGE_QUOTA_UNIT {
  TB = 'TB',
  GB = 'GB',
  MB = 'MB',
}

export enum THRESHOLD_TYPES {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED',
}

export enum STORAGE_QUOTA_BASE_UNIT {
  BYTES = 'BYTES',
}

export enum ENTITY_SHARE_TYPES {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  PUBLIC = 'PUBLIC',
}

export enum SHARE_PERMISSIONS {
  EDIT = 'edit',
  VIEW = 'view',
}
