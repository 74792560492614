<div class="horizontal-center-container pt-5">
  <div>
    <div class="d-flex justify-content-center">
      <lottie-player
        src="/assets/animations/not-found.json"
        background="transparent"
        speed="1"
        style="width: 400px; height: 350px"
        loop
        autoplay
        alt="404-page-animation"
      ></lottie-player>
    </div>
  </div>
</div>

<div class="content">
  <div class="col-12">
    <h3 class="text-center">
      {{ 'pages.not-found.title-not-found' | translate }}
    </h3>
  </div>

  <div class="col">
    <p class="text-center">
      {{ 'pages.not-found.one' | translate }} <br />
      {{ 'pages.not-found.two' | translate }}
      <a routerLink="/app/launchpad" routerLinkActive="active">{{
        'pages.not-found.launchpad' | translate
      }}</a>
    </p>
  </div>
</div>
