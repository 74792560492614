import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'copy-chip',
  templateUrl: './copy-chip.component.html',
  styleUrls: ['./copy-chip.component.scss'],
  // encapsulation: ViewEncapsulation.Emulated
})
export class CopyChipComponent {
  // Input string
  @Input() contentToCopy?: string | number;
  @ViewChild('copyBtn') element?: ElementRef;

  constructor(private renderer: Renderer2) {}

  // Display text for copy button
  copyText = ' Copy ';

  // Begin:: Copy text to clipboard
  copyTextToClipboard() {
    if (!this.contentToCopy) return;

    navigator.clipboard.writeText(this.contentToCopy?.toString());
    this.renderer.addClass(this.element?.nativeElement, 'copy-btn-animate');
    this.copyText = 'Copied';
    setTimeout(() => {
      this.copyText = ' Copy ';
      this.renderer.removeClass(
        this.element?.nativeElement,
        'copy-btn-animate'
      );
    }, 2000);
  }
  // End:: Copy text to clipboard
}
