import { Injectable } from '@angular/core';
import {
  DataListTableActionElement,
  DataListTableDataElement,
} from '@el-data-list-table';

import { ANALYZING_MODES, USER_TYPES } from '@shared/constants';

import {
  LoggedUserService,
  UserInfoResponseDTO,
} from '../modules/auth/services';
import { IActionButton } from '../modules/shared/components/action-button/action-button.component';
import { IModuleIconData } from '../pages/home/home.component';

export interface ICreateAction {
  action: DataListTableActionElement;
  allowedPermissions?: string[];
}

export interface ICreateData {
  data: DataListTableDataElement;
  allowedPermissions?: string[];
}

export interface IShowModule {
  data: IModuleIconData;
  allowedPermissions?: string[];
}

export interface ISpeedDialButtons {
  data: IActionButton;
  allowedPermissions?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class PermissionFiltrationService {
  private loggedUserInfo: UserInfoResponseDTO;

  constructor(loggedUserService: LoggedUserService) {
    loggedUserService.dataStore.subscribe((data) => {
      this.loggedUserInfo = data;
    });
  }

  public validatePermissions({
    analyzingMode = ANALYZING_MODES.EVERY,
    permissions,
  }: {
    analyzingMode?: ANALYZING_MODES;
    permissions?: string[];
  }): boolean {
    // allow EL Admin everything
    if (this.loggedUserInfo?.user_type === USER_TYPES.EL_ADMIN) return true;

    // no permissions provided means no permission restrictions
    if (!permissions || permissions.length === 0) {
      return true;
    }

    if (analyzingMode === ANALYZING_MODES.EVERY) {
      return (
        // validate user's permissions with all the permission restrictions
        permissions.every((permission) =>
          this.loggedUserInfo?.connection.permissions.includes(permission)
        )
      );
    }

    return (
      // validate user's permissions with some of the permission restrictions
      permissions.some((permission) =>
        this.loggedUserInfo?.connection.permissions.includes(permission)
      )
    );
  }

  public getActions(
    items: ICreateAction[],
    analyzingMode = ANALYZING_MODES.EVERY
  ): DataListTableActionElement[] {
    const allowedItems = items.filter((item) =>
      this.validatePermissions({
        analyzingMode,
        permissions: item.allowedPermissions,
      })
    );

    return allowedItems.map((item) => item.action);
  }

  public getData(
    items: ICreateData[],
    analyzingMode = ANALYZING_MODES.EVERY
  ): DataListTableDataElement[] {
    const allowedItems = items.filter((item) =>
      this.validatePermissions({
        analyzingMode,
        permissions: item.allowedPermissions,
      })
    );

    return allowedItems.map((item) => item.data);
  }

  public filterModules(
    items: IShowModule[],
    analyzingMode = ANALYZING_MODES.EVERY
  ): IModuleIconData[] {
    const allowedItems = items.filter((item) =>
      this.validatePermissions({
        analyzingMode,
        permissions: item.allowedPermissions,
      })
    );

    return allowedItems.map((item) => item.data);
  }

  public filterSpeedDialButtons(
    items: ISpeedDialButtons[],
    analyzingMode = ANALYZING_MODES.EVERY
  ): IActionButton[] {
    const allowedItems = items.filter((item) =>
      this.validatePermissions({
        analyzingMode,
        permissions: item.allowedPermissions,
      })
    );

    return allowedItems.map((item) => item.data);
  }
}
