export const MAPPING_FIELD_SELECTION_MAIN_RESPONSE = 'Main Response';

export enum MAPPING_STATUS {
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFIED = 'VERIFIED',
  DELETED = 'DELETED',
  ENDPOINT_DELETED = 'ENDPOINT_DELETED',
}

export enum MAPPING_RETURN_TYPES {
  DIRECT_VALUE = 'DIRECT_VALUE',
  KEY_VALUE_PAIR = 'KEY_VALUE_PAIR',
  DIRECT_VALUE_ARRAY = 'DIRECT_VALUE_ARRAY',
  KEY_VALUE_PAIR_ARRAY = 'KEY_VALUE_PAIR_ARRAY',
}

export enum MAPPING_RETURN_DATA_TYPES {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
}
