import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';

export interface SecurityRequestDTO {
  secure_tunneling?: boolean;
  encryption_key?: string;
  ddos_attack_protection?: boolean;
  ddos_duration?: number;
  ddos_attempts?: number;
  is_pending_restart?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  public readonly dataStore = new BehaviorSubject<
    SecurityRequestDTO | undefined
  >(undefined);

  constructor(private http: HttpClient) {}

  updateSecurityData(
    security: Partial<SecurityRequestDTO>
  ): Observable<CommonResponseDTO<SecurityRequestDTO>> {
    return this.http
      .patch<CommonResponseDTO<SecurityRequestDTO>>(
        URLS.SECURITY_BASE_URL,
        security
      )
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }

  getSecurityData(): Observable<CommonResponseDTO<SecurityRequestDTO>> {
    return this.http
      .get<CommonResponseDTO<SecurityRequestDTO>>(URLS.SECURITY_BASE_URL)
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }
}
