<svg
  xmlns="http://www.w3.org/2000/svg"
  width="371.659"
  height="232.036"
  viewBox="0 0 371.659 232.036"
  style="margin: auto; background: none; display: block"
  width="230px"
  height="230px"
  preserveAspectRatio="xMidYMid"
>
  <g
    id="Group_2573"
    data-name="Group 2573"
    transform="translate(0)"
    opacity="1"
  >
    <path
      id="Path_4161"
      data-name="Path 4161"
      d="M70.706,0A70.706,70.706,0,1,1,0,70.706,70.706,70.706,0,0,1,70.706,0Z"
      transform="translate(26.427)"
      fill="#fff"
    />
    <path
      id="Path_4145"
      data-name="Path 4145"
      d="M31.391,151.642c0,25.726,16.139,46.543,36.084,46.543"
      transform="translate(-31.391 -134.665)"
      fill="#606061"
    />
    <path
      id="Path_4146"
      data-name="Path 4146"
      d="M140.092,197.133c0-26.015,18.01-47.066,40.267-47.066"
      transform="translate(-104.008 -133.613)"
      fill="#868686"
    />
    <path
      id="Path_4147"
      data-name="Path 4147"
      d="M70.775,158.668c0,24.437,10.292,44.211,23.01,44.211"
      transform="translate(-57.701 -139.359)"
      fill="#868686"
    />
    <path
      id="Path_4148"
      data-name="Path 4148"
      d="M140.092,170.823c0-33.241,20.817-60.14,46.543-60.14"
      transform="translate(-104.008 -107.303)"
      fill="#606061"
    />
    <path
      id="Path_4149"
      data-name="Path 4149"
      d="M117.224,287.274s5.117-.158,6.659-1.256,7.872-2.41,8.254-.648,7.69,8.76,1.913,8.806-13.424-.9-14.963-1.837S117.224,287.274,117.224,287.274Z"
      transform="translate(-88.731 -223.427)"
      fill="#a8a8a8"
    />
    <path
      id="Path_4150"
      data-name="Path 4150"
      d="M134.154,299.115c-5.777.047-13.424-.9-14.963-1.838-1.172-.714-1.639-3.276-1.8-4.458l-.171.007s.324,4.127,1.863,5.065,9.186,1.884,14.963,1.838c1.668-.013,2.244-.607,2.212-1.486C136.031,298.773,135.395,299.1,134.154,299.115Z"
      transform="translate(-88.731 -228.978)"
      opacity="0.2"
    />
    <ellipse
      id="Ellipse_4"
      data-name="Ellipse 4"
      cx="62.075"
      cy="8.445"
      rx="62.075"
      ry="8.445"
      transform="translate(3.854 132.47)"
      fill="#3f3d56"
    />
    <ellipse
      id="Ellipse_5"
      data-name="Ellipse 5"
      cx="52.117"
      cy="7.09"
      rx="52.117"
      ry="7.09"
      transform="translate(13.812 133.825)"
      opacity="0.1"
    />
    <ellipse
      id="Ellipse_6"
      data-name="Ellipse 6"
      cx="93.943"
      cy="12.78"
      rx="93.943"
      ry="12.78"
      transform="translate(183.773 206.476)"
      fill="#58585a"
    />
    <path
      id="Path_4151"
      data-name="Path 4151"
      d="M494,666.376c29.876,7.635,87.3-9.959,93.611-29.876"
      transform="translate(-340.435 -458.572)"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="2"
    />
    <path
      id="Path_4152"
      data-name="Path 4152"
      d="M341,355.973s43.154-11.95,45.81,26.556-35.519,49.461-5.643,57.1"
      transform="translate(-238.224 -269.996)"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="2"
    />
    <path
      id="Path_4153"
      data-name="Path 4153"
      d="M215.4,636.723s12.97-3.592,13.768,7.982-10.675,14.866-1.7,17.161"
      transform="translate(-154.319 -458.369)"
      fill="none"
      stroke="#2f2e41"
      stroke-miterlimit="10"
      stroke-width="2"
    />
    <path
      id="Path_4154"
      data-name="Path 4154"
      d="M790.651,661.716l-2.615,16.7s-12.874,6.839-3.822,7.04,51.7,0,51.7,0,8.247,0-4.828-7.242l-2.615-17.5Z"
      transform="translate(-532.156 -474.746)"
      fill="#fff"
    />
    <path
      id="Path_4155"
      data-name="Path 4155"
      d="M782.4,685.271a35.6,35.6,0,0,1,5.643-3.735l2.615-16.7,37.817.035,2.615,16.459a34.323,34.323,0,0,1,6.043,3.976c1.679-.383,3.515-1.807-6.043-7.1l-2.615-17.5-37.817,1.006-2.615,16.7S777.223,684.159,782.4,685.271Z"
      transform="translate(-532.161 -474.746)"
      opacity="0.1"
    />
    <path
      id="Path_4162"
      data-name="Path 4162"
      d="M8.81,0H161.566a8.81,8.81,0,0,1,8.811,8.81V109.87a8.81,8.81,0,0,1-8.811,8.81H8.81A8.81,8.81,0,0,1,0,109.87V8.81A8.81,8.81,0,0,1,8.81,0Z"
      transform="translate(192.013 70.603)"
      fill="#fff"
    />
    <rect
      id="Rectangle_433"
      data-name="Rectangle 433"
      width="158.911"
      height="88.91"
      transform="translate(197.746 76.939)"
      fill="#c5c5c5"
    />
    <circle
      id="Ellipse_8"
      data-name="Ellipse 8"
      cx="1.006"
      cy="1.006"
      r="1.006"
      transform="translate(276.196 73.117)"
      fill="#f2f2f2"
    />
    <path
      id="Path_4156"
      data-name="Path 4156"
      d="M780.2,621.322v10.4a5.99,5.99,0,0,1-5.991,5.99h-158.4a5.99,5.99,0,0,1-5.99-5.991v-10.4Z"
      transform="translate(-417.811 -448.433)"
      fill="#a6a6a6"
    />
    <path
      id="Path_4157"
      data-name="Path 4157"
      d="M751.188,652.893V654.9H642.968V653.3l.149-.4,2.667-7.241H748.976Z"
      transform="translate(-429.532 -431.324)"
      fill="#fff"
    />
    <path
      id="Path_4158"
      data-name="Path 4158"
      d="M1064.687,748.1c-.2.843-.942,1.732-2.623,2.573-6.035,3.017-18.305-.8-18.305-.8s-9.454-1.609-9.454-5.834a7.555,7.555,0,0,1,.828-.493c2.537-1.342,10.949-4.654,25.865.14a6.217,6.217,0,0,1,2.835,1.858A2.968,2.968,0,0,1,1064.687,748.1Z"
      transform="translate(-701.383 -528.496)"
      fill="#fafafa"
    />
    <path
      id="Path_4159"
      data-name="Path 4159"
      d="M1066.355,748.1c-7.386,2.83-13.97,3.041-20.727-1.651a14.838,14.838,0,0,0-8.826-2.907c2.537-1.342,10.949-4.654,25.865.14a6.217,6.217,0,0,1,2.835,1.858A2.966,2.966,0,0,1,1066.355,748.1Z"
      transform="translate(-703.051 -528.496)"
      fill="#fafafa"
      opacity="0.1"
    />
    <ellipse
      id="Ellipse_9"
      data-name="Ellipse 9"
      cx="2.615"
      cy="0.805"
      rx="2.615"
      ry="0.805"
      transform="translate(351.428 216.338)"
      fill="#423c3c"
    />
    <circle
      id="Ellipse_10"
      data-name="Ellipse 10"
      cx="3.822"
      cy="3.822"
      r="3.822"
      transform="translate(273.38 177.315)"
    />
    <path
      id="Path_4160"
      data-name="Path 4160"
      d="M751.188,667.466v2.012H642.968v-1.609l.149-.4Z"
      transform="translate(-429.532 -445.898)"
      opacity="0.1"
    />
    <rect
      id="Rectangle_434"
      data-name="Rectangle 434"
      width="69.047"
      height="80.333"
      transform="translate(36.053 52.781)"
      fill="#2f2e41"
    />
    <rect
      id="Rectangle_435"
      data-name="Rectangle 435"
      width="82.989"
      height="28.548"
      transform="translate(29.082 44.814)"
      fill="#a6a6a6"
    />
    <rect
      id="Rectangle_436"
      data-name="Rectangle 436"
      width="82.989"
      height="28.548"
      transform="translate(29.082 78.673)"
      fill="#a6a6a6"
    />
    <rect
      id="Rectangle_437"
      data-name="Rectangle 437"
      width="82.989"
      height="28.548"
      transform="translate(29.082 112.533)"
      fill="#a6a6a6"
    />
    <rect
      id="Rectangle_438"
      data-name="Rectangle 438"
      width="5.311"
      height="5.311"
      transform="translate(90.162 49.793)"
      fill="#fff"
      opacity="0.4"
    />
    <rect
      id="Rectangle_439"
      data-name="Rectangle 439"
      width="5.311"
      height="5.311"
      transform="translate(97.797 49.793)"
      fill="#868686"
      opacity="0.8"
    />
    <rect
      id="Rectangle_440"
      data-name="Rectangle 440"
      width="5.311"
      height="5.311"
      transform="translate(105.432 49.793)"
      fill="#363541"
    />
    <rect
      id="Rectangle_441"
      data-name="Rectangle 441"
      width="5.311"
      height="5.311"
      transform="translate(90.162 83.321)"
      fill="#fff"
      opacity="0.4"
    />
    <rect
      id="Rectangle_442"
      data-name="Rectangle 442"
      width="5.311"
      height="5.311"
      transform="translate(97.797 83.321)"
      fill="#868686"
      opacity="0.8"
    />
    <rect
      id="Rectangle_443"
      data-name="Rectangle 443"
      width="5.311"
      height="5.311"
      transform="translate(105.432 83.321)"
      fill="#363541"
    />
    <rect
      id="Rectangle_444"
      data-name="Rectangle 444"
      width="5.311"
      height="5.311"
      transform="translate(90.162 116.848)"
      fill="#fff"
      opacity="0.4"
    />
    <rect
      id="Rectangle_445"
      data-name="Rectangle 445"
      width="5.311"
      height="5.311"
      transform="translate(97.797 116.848)"
      fill="#868686"
      opacity="0.8"
    />
    <rect
      id="Rectangle_446"
      data-name="Rectangle 446"
      width="5.311"
      height="5.311"
      transform="translate(105.432 116.848)"
      fill="#363541"
    />
  </g>
</svg>

<h1 *ngIf="hasTranslations" mat-dialog-title>
  {{ 'core.server-error-overlay.error-title' | translate }}
</h1>
<h1 *ngIf="!hasTranslations" mat-dialog-title>Something Went Wrong!</h1>
<div mat-dialog-content>
  <div style="display: flex; justify-content: center">
    <div class="col"></div>
    <div class="col-9">
      <p *ngIf="hasTranslations">
        {{ 'core.server-error-overlay.error-line-1' | translate }}
      </p>
      <p
        *ngIf="
          hasTranslations && (supportPhoneNumber || supportEmail || supportWeb)
        "
      >
        {{ 'core.server-error-overlay.error-line-2' | translate }}
      </p>
      <p
        *ngIf="
          hasTranslations && !supportPhoneNumber && !supportEmail && !supportWeb
        "
      >
        {{ 'core.server-error-overlay.error-line-2-not-support' | translate }}
      </p>
      <p *ngIf="!hasTranslations">
        Looks like we're not able to connect to our servers.
      </p>
      <p
        *ngIf="
          !hasTranslations && (supportPhoneNumber || supportEmail || supportWeb)
        "
      >
        Please try again after sometime or contact our support team.
      </p>
      <p
        *ngIf="
          !hasTranslations &&
          !supportPhoneNumber &&
          !supportEmail &&
          !supportWeb
        "
      >
        Please try again after sometime.
      </p>
    </div>
    <div class="col"></div>
  </div>
</div>

<div
  style="
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3%;
  "
>
  <span
    *ngIf="supportEnabled && (supportPhoneNumber || supportEmail || supportWeb)"
    class="col-4 support"
  >
    <a *ngIf="supportPhoneNumber" [href]="'tel:' + supportPhoneNumber"
      ><mat-icon>phone</mat-icon></a
    >
    <a *ngIf="supportEmail" [href]="'mailto:' + supportEmail"
      ><mat-icon>email</mat-icon></a
    >
    <a *ngIf="supportWeb" target="_blank" [href]="supportWeb"
      ><mat-icon>language</mat-icon></a
    >
  </span>
  <button
    mat-flat-button
    data-testid="server_error_btn_close_overlay"
    color="primary"
    class="try-again"
    (click)="closeOverlay()"
  >
    Try again
  </button>
</div>
