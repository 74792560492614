<div
  class="mx-0 row row-cols-sm-auto d-flex align-items-center justify-content-between"
>
  <div>
    <h1 mat-dialog-title class="d-flex align-items-center mb-0">
      <mat-icon class="mr-2">other_houses</mat-icon>
      {{ 'configuration-fields.set-addresses.title' | translate }}
    </h1>
    <mat-card-subtitle>{{
      'configuration-fields.set-addresses.subtitle' | translate
    }}</mat-card-subtitle>
  </div>
  <div class="mb-2">
    <button
      type="button"
      (click)="addNewAddresses()"
      mat-stroked-button
      id="configuration-fields-set-add-btn-add-addresses"
      [matTooltip]="
        'configuration-fields.set-addresses.add-new-btn' | translate
      "
    >
      +&nbsp;{{ 'configuration-fields.set-addresses.add-new-btn' | translate }}
    </button>
  </div>
</div>

<mat-divider></mat-divider>

<mat-dialog-content class="mt-4">
  <mat-accordion [multi]="true">
    <ng-container *ngFor="let address of addresses; let i = index">
      <form [formGroup]="address">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="pl-2 d-flex align-items-center">
              <ng-container class="">
                <mat-radio-group formControlName="is_primary">
                  <mat-radio-button
                    [disableRipple]="true"
                    class="mb-0"
                    color="primary"
                    [value]="true"
                    (click)="$event.stopPropagation(); radioChange(i)"
                    >{{
                      'configuration-fields.set-addresses.use-primary'
                        | translate
                    }}
                    <p
                      *ngIf="this.getCurrentAddressForHeaderSection(address, i)"
                      class="m-0 p-0 mat-card-subtitle"
                    >
                      {{ this.getCurrentAddressForHeaderSection(address, i) }}
                    </p>
                  </mat-radio-button>
                </mat-radio-group>
              </ng-container>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <span formGroupName="address">
            <div class="row mt-3">
              <div class="col">
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>{{
                    'configuration-fields.set-addresses.line1' | translate
                  }}</mat-label>
                  <input
                    matInput
                    formControlName="line1"
                    required
                    id="lead-line1"
                  />
                  <mat-error>{{
                    'configuration-fields.set-addresses.error-messages.line1-error'
                      | translate
                  }}</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>{{
                    'configuration-fields.set-addresses.line2' | translate
                  }}</mat-label>
                  <input matInput formControlName="line2" id="lead-line2" />
                  <mat-error>{{
                    'configuration-fields.set-addresses.error-messages.line2-error'
                      | translate
                  }}</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>{{
                    'configuration-fields.set-addresses.line3' | translate
                  }}</mat-label>
                  <input matInput formControlName="line3" id="lead-line3" />
                  <mat-error>{{
                    'configuration-fields.set-addresses.error-messages.line3-error'
                      | translate
                  }}</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 pr-md-1">
                <el-autocomplete
                  [options]="countryListForDropDown"
                  [multiSelect]="false"
                  [hideTheAllOption]="true"
                  [label]="
                    'configuration-fields.set-addresses.country' | translate
                  "
                  appearance="fill"
                  [required]="true"
                  [selected]="address.controls.address.get('country').value"
                  (itemsSelected)="updateCountryValues($event, i)"
                >
                </el-autocomplete>
              </div>

              <div class="col-12 col-md-6 pl-md-1">
                <el-autocomplete
                  (focusin)="updateDropdownOnFocus('state', i)"
                  [isFieldsLoading]="loadingStates"
                  [options]="dropdownsList[i].statesListForDropdown"
                  [multiSelect]="false"
                  [hideTheAllOption]="true"
                  [label]="
                    'configuration-fields.set-addresses.state' | translate
                  "
                  appearance="fill"
                  [required]="true"
                  (itemsSelected)="updateStateValues($event, i)"
                  [selected]="address.controls.address.get('state').value"
                  [disabled]="!address.controls.address.get('country').value"
                >
                </el-autocomplete>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <el-autocomplete
                  (focusin)="updateDropdownOnFocus('district', i)"
                  [isFieldsLoading]="loadingDistricts"
                  [options]="dropdownsList[i].districtsListForDropdown"
                  [multiSelect]="false"
                  [hideTheAllOption]="true"
                  [label]="
                    'configuration-fields.set-addresses.district' | translate
                  "
                  appearance="fill"
                  [required]="true"
                  (itemsSelected)="updateDistrictValues($event, i)"
                  [selected]="address.controls.address.get('district').value"
                  [disabled]="!address.controls.address.get('state').value"
                >
                </el-autocomplete>
              </div>
            </div>

            <div class="row" style="padding-left: 15px; padding-right: 15px">
              <div class="cityDropDown">
                <el-autocomplete
                  (focusin)="updateDropdownOnFocus('city', i)"
                  [isFieldsLoading]="loadingCities"
                  [options]="dropdownsList[i].citiesListForDropdown"
                  [multiSelect]="false"
                  [hideTheAllOption]="true"
                  [label]="
                    'configuration-fields.set-addresses.city' | translate
                  "
                  appearance="fill"
                  [required]="true"
                  (itemsSelected)="updateCityValues($event, i)"
                  [selected]="address.controls.address.get('city').value"
                  [disabled]="!address.controls.address.get('district').value"
                >
                </el-autocomplete>
              </div>

              <mat-divider vertical></mat-divider>

              <div class="postalCodeField">
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>{{
                    'configuration-fields.set-addresses.postal-code' | translate
                  }}</mat-label>
                  <input
                    [readonly]="
                      dropdownsList[i].postalCodesListForDropdown?.length > 0 ||
                      loadingPostalCodes
                    "
                    matInput
                    formControlName="postal_code"
                    id="postal_code"
                  />
                  <mat-error>
                    {{
                      'configuration-fields.set-addresses.error-messages.postal-code-error'
                        | translate
                    }}</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
          </span>
          <div class="float-right panel-size">
            <button
              class="my-2 center delete-btn"
              type="button"
              mat-icon-button
              *ngIf="
                (!address.controls.is_primary.value && this.isRequired) ||
                (address.controls.is_primary.value && !this.isRequired) ||
                (!address.controls.is_primary.value && !this.isRequired)
              "
              (click)="onDeleteClicked(i)"
              [matTooltip]="
                'configuration-fields.set-addresses.delete-address' | translate
              "
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
      </form>
    </ng-container>
  </mat-accordion>
  <div *ngIf="addresses.length === 0" style="align-items: center">
    <app-no-results-found></app-no-results-found>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button
    mat-button
    class="m-2"
    [mat-dialog-close]="false"
    id="configuration-fields-set-add-cancel"
    [matTooltip]="'configuration-fields.set-addresses.btn-cancel' | translate"
  >
    {{ 'configuration-fields.set-addresses.btn-cancel' | translate }}
  </button>
  <button
    type="button"
    (click)="clearAll()"
    mat-stroked-button
    id="configuration-fields-set-add-clear-all"
    [matTooltip]="
      'configuration-fields.set-addresses.clear-all-btn' | translate
    "
  >
    {{ 'configuration-fields.set-addresses.clear-all-btn' | translate }}
  </button>
  <button
    [disabled]="!isValid()"
    type="submit"
    mat-raised-button
    (click)="onSaveClicked()"
    color="primary"
    class="m-2"
    id="configuration-fields-set-add-save"
    [matTooltip]="'configuration-fields.set-addresses.btn-save' | translate"
  >
    {{ 'configuration-fields.set-addresses.btn-save' | translate }}
  </button>
</mat-dialog-actions>
