import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { REQUEST_TYPES } from '@shared/constants';

import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../../../services/snackbar.service';

interface EndpointResponse {
  body: any;
  description: string;
  statusCode: number;
}

export interface EndpointData {
  title: string;
  method: REQUEST_TYPES;
  url: string;
  description?: string;
  headers?: { key: string; value: string }[];
  body?: any;
  responses?: EndpointResponse[];
  parameters?: any;
  pathParamInfo?: { param: string; description: string }[];
}

@Component({
  selector: 'app-api-doc-endpoint',
  templateUrl: './api-doc-endpoint.component.html',
  styleUrls: ['./api-doc-endpoint.component.scss'],
})
export class ApiDocEndpointComponent implements OnInit {
  @Input() data: EndpointData;
  @Input() expandAll = false;

  copiedItemType: string;
  requestBodyType = '';
  multipartData = [];

  pathParamsColumns = ['parameter', 'description'];
  queryParamsColumns = [
    'name',
    'description',
    'schema-type',
    'required',
    'example',
  ];

  headerColumns = ['type', 'value'];

  multipartColumns = ['name', 'type', 'description', 'example', 'format'];

  constructor(
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const requestBodyHeader = this.data?.headers?.find(
      (header) => header.key === 'content-type'
    );

    this.requestBodyType = requestBodyHeader
      ? `(${requestBodyHeader.value})`
      : '';

    if (requestBodyHeader?.value === 'multipart/form-data') {
      this.multipartData = Object.entries(JSON.parse(this.data.body)).map(
        ([key, value]) => ({
          name: key,
          format: value?.['format'] === 'binary' ? 'File' : 'Text',
          description: value?.['description'] || '-',
          example: value?.['example'] || '-',
          type: value?.['type'] || '-',
        })
      );
    } else {
      this.multipartData = [];
    }
  }

  onCopy(text: string): void {
    navigator.clipboard.writeText(text);

    if (text.match('http/*') !== null) {
      if (text.match('file_url') !== null)
        this.copiedItemType = this.translate.instant(
          'apps.api-doc.request-body'
        );
      else this.copiedItemType = this.translate.instant('apps.api-doc.url');
    } else if (text.match('file_url') !== null)
      this.copiedItemType = this.translate.instant('apps.api-doc.request-body');
    else
      this.copiedItemType = this.translate.instant('apps.api-doc.request-body');

    this.snackbarService.success(SUCCESS_TYPES.COPIED, this.copiedItemType);
  }
}
