import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { ENDPOINTS, URLS } from '@shared/constants';
import {
  GenerateSecretsResponseDTO,
  InitiateHandshakeRequestDTO,
} from '@shared/dtos';
import {
  CommonResponseDTO,
  IConfigurableFieldConfigResponse,
  IConnectedAppRequest,
  IConnectedAppResponse,
  IHandshakeResponse,
  IRoleResponse,
  IStorageClientResponse,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

import { PermissionResponseDTO } from '../../permissions/services/permissions.service';
import { EndpointData } from '../../shared/components/api-doc-endpoint/api-doc-endpoint.component';
interface UniqueErrorField {
  field_id: string;
  values: string[];
}
@Injectable({
  providedIn: 'root',
})
export class ConnectedAppsService {
  public $additionalFieldsInvalid = new BehaviorSubject<boolean>(false);
  public $additionalFieldsUniqueness = new BehaviorSubject<UniqueErrorField[]>(
    []
  );
  private appWiseFieldConfigs = new BehaviorSubject<{
    [key: string]: IConfigurableFieldConfigResponse[];
  }>({});
  constructor(private http: HttpClient) {}

  public getAllApps(
    params?: HttpParams
  ): Observable<CommonResponseDTO<IConnectedAppResponse[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_GET_ALL_APPS,
    });
    const config = { params };
    return this.http.get<CommonResponseDTO<IConnectedAppResponse[]>>(
      url,
      config
    );
  }
  public listenToAdditionalChange(value: boolean) {
    this.$additionalFieldsInvalid.next(value);
  }

  public listenToUniqueErrorFields(data: UniqueErrorField[]): void {
    this.$additionalFieldsUniqueness.next(data);
  }
  public getConnectedApp(
    id: string
  ): Observable<CommonResponseDTO<IConnectedAppResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_GET_ONE_APP,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<IConnectedAppResponse>>(url);
  }

  public initiateHandshake(
    data: InitiateHandshakeRequestDTO
  ): Observable<CommonResponseDTO<IHandshakeResponse>> {
    const url = generateURL({ endpoint: ENDPOINTS.CONNECTED_APPS_HANDSHAKE });
    return this.http.post<CommonResponseDTO<IHandshakeResponse>>(url, data);
  }

  public generateSecrets(): Observable<
    CommonResponseDTO<GenerateSecretsResponseDTO>
  > {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_GENERATE_SECRETS,
    });
    return this.http.get<CommonResponseDTO<GenerateSecretsResponseDTO>>(url);
  }

  public connectNewApp(
    data: IConnectedAppRequest
  ): Observable<CommonResponseDTO<IConnectedAppResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_CONNECT_NEW_APPS,
    });
    return this.http.post<CommonResponseDTO<IConnectedAppResponse>>(url, data);
  }

  public updateConnectedApp(
    data: IConnectedAppRequest,
    id: string
  ): Observable<CommonResponseDTO<IConnectedAppResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_UPDATE_ONE_APP,
      params: { id },
    });
    return this.http.put<CommonResponseDTO<IConnectedAppResponse>>(url, data);
  }

  public connectOrDisconnectApp(
    id: string,
    status_change: 'connect' | 'disconnect'
  ): Observable<CommonResponseDTO<IConnectedAppResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_CONNECT_OR_DISCONNECT_APP,
      params: { id, status_change },
    });
    return this.http.patch<CommonResponseDTO<IConnectedAppResponse>>(url, {});
  }

  public async getAllFields(
    appId: string
  ): Promise<IConfigurableFieldConfigResponse[]> {
    const allAppFields = this.appWiseFieldConfigs.getValue();

    if (allAppFields[appId]) {
      return allAppFields[appId];
    }

    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_GET_ALL_FIELDS_OF_A_CONNECTED_APP,
      params: { id: appId },
    });
    const { data } = await firstValueFrom(
      this.http.get<CommonResponseDTO<IConfigurableFieldConfigResponse[]>>(url)
    );

    this.appWiseFieldConfigs.next({
      ...allAppFields,
      [appId]: data,
    });

    return data;
  }

  public getAllPermissions(
    id: string
  ): Observable<CommonResponseDTO<PermissionResponseDTO[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_GET_ALL_PERMISSIONS_OF_A_CONNECTED_APP,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<PermissionResponseDTO[]>>(url, {});
  }

  public getAllRoles(
    id: string
  ): Observable<CommonResponseDTO<IRoleResponse[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_GET_ALL_ROLES_OF_A_CONNECTED_APP,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<IRoleResponse[]>>(url, {});
  }

  getDocumentation(id: string): Observable<CommonResponseDTO<EndpointData[]>> {
    const url = generateURL({
      endpoint:
        ENDPOINTS.CONNECTED_APPS_GET_ALL_DOCUMENTATIONS_OF_A_CONNECTED_APP,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<EndpointData[]>>(url);
  }

  deleteConnectedApp(id: string) {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_DELETE_ONE_APP,
      params: { id },
    });
    return this.http.delete<CommonResponseDTO<IConnectedAppResponse>>(url, {});
  }

  getClientStorageConfig(appId: string, identityId: string) {
    const url = `${URLS.CONNECTED_APPS}/${appId}/storage-config`;

    const params = new HttpParams().append('identity_id', identityId);

    return this.http.get<CommonResponseDTO<IStorageClientResponse>>(url, {
      params,
    });
  }
}
