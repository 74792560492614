import { FORMULA_ELEMENT_TYPES, VARIABLE_DATA_TYPES } from '@shared/constants';
import { IFormulaCustomVariableElement } from '@shared/interfaces';

export const customVariables: IFormulaCustomVariableElement[] = [
  {
    type: FORMULA_ELEMENT_TYPES.CUSTOM_VARIABLE,
    customVariable: {
      type: VARIABLE_DATA_TYPES.NUMBER,
      variableName: undefined,
      displayText: 'Custom Variable: Number',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.CUSTOM_VARIABLE,
    customVariable: {
      type: VARIABLE_DATA_TYPES.TEXT,
      variableName: undefined,
      displayText: 'Custom Variable: Text',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.CUSTOM_VARIABLE,
    customVariable: {
      type: VARIABLE_DATA_TYPES.DATE,
      variableName: undefined,
      displayText: 'Custom Variable: Date',
    },
  },
];
