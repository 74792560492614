import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

import { ConfirmationPopupComponent } from '../../popups/confirmation-popup/confirmation-popup.component';

import { CONFIRMATION_TYPES } from './constants';
import { IConfirmationPopup, IConfirmationPopupValues } from './types';

export { CONFIRMATION_TYPES, IConfirmationPopup, IConfirmationPopupValues };

@Injectable({ providedIn: 'root' })
export class GetUserConfirmationService {
  constructor(private dialog: MatDialog) {}

  confirm(
    type: CONFIRMATION_TYPES,
    data?: Omit<IConfirmationPopup, 'type'>
  ): Promise<boolean> {
    const { icon, color, description, need_authentication } = data || {};

    return Promise.resolve(
      firstValueFrom(
        this.dialog
          .open(ConfirmationPopupComponent, {
            data: { type, icon, color, description, need_authentication },
            width: '90%',
            maxWidth: '600px',
          })
          .afterClosed()
      )
    );
  }
}
