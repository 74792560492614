import { Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-quick-actions',
  templateUrl: './skeleton-quick-actions.component.html',
  styleUrls: ['./skeleton-quick-actions.component.scss'],
})
export class SkeletonQuickActionsComponent {
  quickActions = [1, 2];
}
