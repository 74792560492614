export const USERNAME_REGEX =
  /^(?:[A-Z\d_@.]{6,50}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;
export const URL_NO_TRAILING_SLASH_REGEX = /^(http|https):\/\/([^/ ]+)[^/.]$/;
export const URL_REGEX =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
export const URL_INTERNAL = /^\/app.*$/;
export const MAX_QUICK_ACTIONS = 8;
export const SUCCESS_MESSAGE = 'SUCCESS';

export const INTERCEPTOR_SKIPPERS = {
  ERROR_INTERCEPTOR: 'X-Skip-Error-Interceptor',
  JWT_INTERCEPTOR: 'X-Skip-JWT-Interceptor',
  TUNNELING_QUERY_INTERCEPTOR: 'X-Skip-Tunneling-Query-Interceptor',
  TUNNELING_BODY_INTERCEPTOR: 'X-Skip-Tunneling-Body-Interceptor',
};

export const DIALOG_SIZES = {
  X_SMALL: {
    width: '50%',
    minWidth: '330px',
    maxWidth: '700px',
  },
  SMALL: {
    width: '90%',
    maxWidth: '400px',
  },
  MEDIUM: {
    width: '90%',
    maxWidth: '550px',
  },
  LARGE: {
    width: '90%',
    maxWidth: '750px',
  },
  EXTRA_LARGE: {
    width: '80%',
    maxWidth: '1200px',
  },
  FULL_SCREEN: {
    width: '100vw',
    height: '100vh',
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
};
