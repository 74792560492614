import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

interface IPayloadData {
  filename: string;
  fileSize: string;
  type: 'DOWNLOAD' | 'UPLOAD';
  onCancel: () => void;
  progress: number;
}

@Component({
  selector: 'app-progress-toast-component',
  templateUrl: './progress-toast.component.html',
  styleUrls: ['./progress-toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
    ]),
  ],
})
export class ProgressToastComponent extends Toast implements OnInit {
  payloadData: IPayloadData;

  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit(): void {
    this.payloadData = this.options.payload;
  }

  cancelEvent() {
    this.delayedHideToast();
    this.payloadData.onCancel();
  }
}
