import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { KeyboardShortcutsListComponent } from './popups/keyboard-shortcuts-list/keyboard-shortcuts-list.component';

@Component({
  selector: 'app-keyboard-shortcuts',
  templateUrl: './keyboard-shortcuts.component.html',
  styleUrls: ['./keyboard-shortcuts.component.scss'],
})
export class KeyboardShortcutsComponent {
  constructor(private dialog: MatDialog) {}

  showKeyboardShortcuts() {
    this.dialog.open(KeyboardShortcutsListComponent, {
      width: '80%',
      maxWidth: '920px',
    });
  }
}
