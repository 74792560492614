import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import {
  CommonResponseDTO,
  IApiKey,
  IDeletedApiKey,
  IDeletedClientSecret,
  IPublicKeyResponse,
} from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class StorageKeysService {
  constructor(private http: HttpClient) {}

  generatePublicApiKey(
    storageId: string
  ): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = `${URLS.STORAGE}/${storageId}/keys/generate`;
    return this.http.get<CommonResponseDTO<IPublicKeyResponse>>(url);
  }
  generatePublicClientCredential(
    storageId: string
  ): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = `${URLS.STORAGE}/${storageId}/keys/generate-client-credentials`;
    return this.http.get<CommonResponseDTO<IPublicKeyResponse>>(url);
  }

  saveGeneratedPublicApiKey({
    storageId,
    key,
    secret,
    reference,
    origins,
    remarks,
  }: {
    storageId: string;
    key?: string;
    secret?: string;
    reference: string;
    origins: string[];
    remarks: string;
  }): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = `${URLS.STORAGE}/${storageId}/keys`;
    return this.http.post<CommonResponseDTO<IPublicKeyResponse>>(url, {
      apiKey: key,
      apiSecret: secret,
      reference,
      origins,
      remarks,
    });
  }

  saveDeletedApiKey(
    storageId: string,
    key: string,
    referer: string
  ): Observable<CommonResponseDTO<IDeletedApiKey>> {
    const url = `${URLS.STORAGE}/${storageId}/keys/save-deleted-api-key`;
    return this.http.post<CommonResponseDTO<IDeletedApiKey>>(url, {
      key,
      referer,
    });
  }

  saveDeletedClientSecret(
    storageId: string,
    secret: string,
    referer: string
  ): Observable<CommonResponseDTO<IDeletedClientSecret>> {
    const url = `${URLS.STORAGE}/${storageId}/keys/save-deleted-client-secret`;
    return this.http.post<CommonResponseDTO<IDeletedClientSecret>>(url, {
      secret,
      referer,
    });
  }

  updatePublicApiKeyOrSecret({
    id,
    key,
    secret,
    storageId,
    origins,
    remarks,
  }: {
    storageId: string;
    id: string;
    key?: string;
    secret?: string;
    origins: string[];
    remarks: string;
  }): Observable<CommonResponseDTO<IApiKey>> {
    const url = `${URLS.STORAGE}/${storageId}/keys/${id}`;
    return this.http.patch<CommonResponseDTO<IApiKey>>(url, {
      key,
      secret,
      origins,
      remarks,
    });
  }

  getGeneratedKey(
    id: string,
    storageId: string
  ): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = `${URLS.STORAGE}/${storageId}/keys/${id}`;
    return this.http.get<CommonResponseDTO<IPublicKeyResponse>>(url);
  }

  getGeneratedKeys(
    storageId: string
  ): Observable<CommonResponseDTO<IApiKey[]>> {
    const url = `${URLS.STORAGE}/${storageId}/keys`;
    return this.http.get<CommonResponseDTO<IApiKey[]>>(url);
  }

  deleteKey(
    storageId: string,
    id: string
  ): Observable<CommonResponseDTO<IApiKey>> {
    const url = `${URLS.STORAGE}/${storageId}/keys/${id}`;
    return this.http.delete<CommonResponseDTO<IApiKey>>(url);
  }

  restoreKey(
    storageId: string,
    id: string
  ): Observable<CommonResponseDTO<IApiKey>> {
    const url = `${URLS.STORAGE}/${storageId}/keys/${id}/restore`;
    return this.http.patch<CommonResponseDTO<IApiKey>>(url, {});
  }
}
