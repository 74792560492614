import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import validator from 'validator';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return !control.value || validator.isEmail(control.value)
      ? null
      : { email: { value: control.value } };
  };
}
