<mat-card
  class="row mat-elevation-z2 quick-actions-panel primary-bg"
  style="margin-left: 0px; margin-right: 0px"
>
  <mat-card-header class="quick-actions-panel--header">
    <mat-card-title
      data-testid="about_text_title"
      class="quick-actions-panel__title"
    >
      <strong>{{ 'pages.about.prod-info' | translate }}</strong>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="container">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-3">
            <img
              data-testid="about_img_logo"
              fetchpriority="high"
              class="crm-logo"
              src="{{ systemInformation?.logo_path_light }}"
              (error)="errorHandler($event, 'logo')"
              alt="ipc-logo"
            />
          </div>

          <div class="col-sm-9">
            <p data-testid="about_text_description" class="content-white">
              {{ systemInformation?.description }}
            </p>
            <p data-testid="about_text_version" class="version">
              {{ 'pages.about.app-version' | translate }} {{ version }}
              {{ 'pages.about.core-version' | translate }} {{ core_version }}
            </p>
            <p
              data-testid="about_text_release_note"
              class="content-white"
              *ngIf="isReleaseDataAvailable"
            >
              <a
                data-testid="about_link_release_note"
                style="color: #fff"
                rel="noopener"
                href="javascript:void(0);"
                (click)="viewReleaseNote()"
                >{{ 'pages.about.release-note' | translate }}</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div class="container page-content">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-6 mt-3">
        <mat-card>
          <mat-card-title-group>
            <mat-card-title>
              <h6 data-testid="about_text_client_info_title">
                <mat-icon
                  data-testid="about_icon_client_info"
                  class="heading-icon"
                  >accessibility_new</mat-icon
                >{{ clientInformation?.name }}
              </h6>
            </mat-card-title>
            <mat-card-subtitle data-testid="about_text_client_info_subtitle">{{
              clientInformation?.tagline
            }}</mat-card-subtitle>
          </mat-card-title-group>
          <img
            data-testid="about_client_info_img_banner"
            fetchpriority="high"
            mat-card-image
            src="{{ clientInformation?.banner_path }}"
            alt="client-logo"
            (error)="errorHandler($event, 'banner')"
          />
          <mat-card-content>
            <div class="container-fluid pt-3">
              <mat-card>
                <mat-card-content>
                  <div class="row">
                    <div class="col-3">
                      <mat-icon
                        class="center"
                        aria-hidden="false"
                        aria-label="about"
                        >info</mat-icon
                      >
                    </div>
                    <div class="col-9">
                      <p data-testid="about_client_info_text_description">
                        {{ clientInformation?.description }}
                      </p>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card class="mt-3">
                <mat-card-content>
                  <div class="row">
                    <div class="col-3">
                      <mat-icon
                        class="center"
                        aria-hidden="false"
                        aria-label="web"
                        >web</mat-icon
                      >
                    </div>
                    <div class="col-9">
                      <span
                        data-testid="about_client_info_text_website"
                        class="call-hotline"
                        >{{ 'pages.about.find-us-on' | translate }}<br /><a
                          href="{{ clientInformation?.website }}"
                          rel="noopener"
                          >{{ clientInformation?.website }}</a
                        ></span
                      >
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card class="mt-3">
                <mat-card-content>
                  <div class="row">
                    <div class="col-3">
                      <mat-icon
                        class="center"
                        aria-hidden="false"
                        aria-label="phone"
                        >phone</mat-icon
                      >
                    </div>
                    <div class="col-9">
                      <span
                        data-testid="about_client_info_text_hotline"
                        class="call-hotline"
                        >{{ 'pages.about.call-hotline' | translate }}<br /><a
                          href="tel:{{
                            clientInformation?.phone_number.e164Number
                          }}"
                          rel="noopener"
                          >{{
                            clientInformation?.phone_number.nationalNumber
                          }}</a
                        ></span
                      >
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-sm-6 mt-3">
        <mat-card>
          <mat-card-title-group>
            <mat-card-title data-testid="about_el_info_text_title">
              <h6>
                <mat-icon class="heading-icon">build</mat-icon
                >{{ 'pages.about.echonlabs-name' | translate }}
              </h6>
            </mat-card-title>
            <mat-card-subtitle data-testid="about_el_info_text_subtitle">{{
              'pages.about.echonlabs-tagline' | translate
            }}</mat-card-subtitle>
          </mat-card-title-group>
          <img
            data-testid="about_el_info_img_banner"
            fetchpriority="high"
            mat-card-image
            src="assets/echonlabs-cover.png"
            alt="echonlabs-photo"
          />

          <mat-card-content>
            <div class="container-fluid pt-3">
              <mat-card>
                <mat-card-content>
                  <div class="row">
                    <div class="col-3">
                      <mat-icon
                        class="center"
                        aria-hidden="false"
                        aria-label="about"
                        >info</mat-icon
                      >
                    </div>
                    <div class="col-9">
                      <p data-testid="about_el_info_text_description_less">
                        {{ 'pages.about.echonlabs-story-line1' | translate }}
                      </p>
                      <button
                        *ngIf="!showMoreAboutEchonLabs"
                        data-testid="about_el_info_btn_description_show_more"
                        mat-flat-button
                        (click)="showMoreAboutEchonLabs = true"
                      >
                        {{ 'pages.about.read-more' | translate }}
                      </button>

                      <p
                        *ngIf="showMoreAboutEchonLabs"
                        data-testid="about_el_info_text_description_more_1"
                      >
                        {{ 'pages.about.echonlabs-story-line2' | translate }}
                      </p>
                      <p
                        *ngIf="showMoreAboutEchonLabs"
                        data-testid="about_el_info_text_description_more_2"
                      >
                        {{ 'pages.about.echonlabs-story-line3' | translate }}
                      </p>
                      <p
                        *ngIf="showMoreAboutEchonLabs"
                        data-testid="about_el_info_text_description_more_3"
                      >
                        {{ 'pages.about.team-echonLabs' | translate }}
                      </p>

                      <button
                        *ngIf="showMoreAboutEchonLabs"
                        data-testid="about_el_info_btn_description_show_less"
                        (click)="showMoreAboutEchonLabs = false"
                        mat-flat-button
                      >
                        {{ 'pages.about.hide' | translate }}
                      </button>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card class="mt-3">
                <mat-card-content>
                  <div class="row">
                    <div class="col-3">
                      <mat-icon
                        class="center"
                        aria-hidden="false"
                        aria-label="web"
                        >web</mat-icon
                      >
                    </div>
                    <div class="col-9">
                      <span
                        data-testid="about_el_info_text_website"
                        class="call-hotline"
                        >{{ 'pages.about.find-us-on' | translate }}<br /><a
                          target="_blank"
                          href="http://www.echonlabs.com/"
                          rel="noopener"
                          >{{ 'pages.about.echonlabs-website' | translate }}</a
                        ></span
                      >
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card class="mt-3">
                <mat-card-content>
                  <div class="row">
                    <div class="col-3">
                      <mat-icon
                        class="center"
                        aria-hidden="false"
                        aria-label="phone"
                        >phone</mat-icon
                      >
                    </div>
                    <div class="col-9">
                      <span
                        data-testid="about_el_info_text_hotline"
                        class="call-hotline"
                        >{{ 'pages.about.call-hotline' | translate }}<br /><a
                          href="tel:(+44) 333 339 5950"
                          rel="noopener"
                          >{{ 'pages.about.echonlabs-hotline' | translate }}</a
                        ></span
                      >
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
