export enum ENVIRONMENTS {
  LOCAL = 'local',
  QA = 'qa',
  DEV = 'development',
  UAT = 'uat',
  PROD = 'production',
}

export interface IWebEnvironment {
  env: ENVIRONMENTS;
  production: boolean;
  isDebugMode: boolean;
  isSentryDebugMode: boolean;
  VERSION: string;
  CORE_VERSION: string;
  API_URL: string;
  WEB_URL: string;
  WEB_DOMAIN: string;
  API_DOMAIN: string;
  SENTRY_DSN: string;
}

export interface IApiEnvironment {
  assetsPath: string;
  production: boolean;
  isDebugMode: boolean;
  VERSION: string;
  CORE_VERSION: string;
  NODE_ENV: string;
  API_BASE_URL: string;
  WEB_BASE_URL: string;
  EMAIL_USER: string;
  EMAIL_ADDRESS: string;
  EMAIL_HOST: string;
  EMAIL_PORT: string;
  MAILBOX_IMAP_HOST: string;
  MAILBOX_IMAP_PORT: string;
  MAILBOX_SMTP_HOST: string;
  MAILBOX_SMTP_PORT: string;
  EL_URL_SHORTENER_GATEWAY: string;
  EL_URL_SHORTENER_DOMAIN: string;
  CORS_ORIGIN: string;
  SENTRY_DSN: string;
}
