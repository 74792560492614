import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';

import { BaseChipComponent } from '../../base-chip/base-chip.component';
import { ChipInputSearchHelper } from '../../helpers/chip-input-search.helper';
import { DateRangeChipProperties } from '../../types/chip-properties/date-range';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-cis-date-range-chip-search-filter',
  templateUrl: './date-range-chip-search-filter.component.html',
  styleUrls: ['./date-range-chip-search-filter.component.scss'],
})
export class DateRangeChipSearchFilterComponent
  extends BaseChipComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() labelSuffix = '';

  @ViewChild('startDate') chipInput?: ElementRef<HTMLInputElement>;
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  get selectedDataChipProperties(): DateRangeChipProperties | undefined {
    return this.selectedData?.chipProperties as DateRangeChipProperties;
  }

  constructor(chipInputSearchHelper: ChipInputSearchHelper) {
    super(chipInputSearchHelper);
    this.dateRange.valueChanges
      .pipe(startWith({ start: undefined, end: undefined }), debounceTime(200))
      .subscribe((chipValue) => {
        if (this.selectedDataChipProperties) {
          this.selectedDataChipProperties.minValue = chipValue.start;
          this.selectedDataChipProperties.maxValue = chipValue.end;
          // Adjust end date to include the entire day
          if (this.selectedDataChipProperties.maxValue) {
            const endDate = new Date(this.selectedDataChipProperties.maxValue);
            endDate.setHours(23, 59, 59, 999); // Set end time to 11:59:59 PM
            this.selectedDataChipProperties.maxValue = endDate;
          }
        }
        this.editedData.emit(this.selectedData);
      });
  }

  ngOnInit(): void {
    this.minWidth = this.selectedData?.size || this.minWidth;
    if (this.selectedDataChipProperties) {
      const start = this.selectedDataChipProperties.minValue;
      const end = this.selectedDataChipProperties.maxValue;

      this.dateRange.setValue({ start, end });
    }
    this.dateRange.valueChanges
      .pipe(debounceTime(50), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.formControl = this.dateRange;
        this.value = this.dateRange.value;
        if (this.selectedData?.validations) {
          super.validate_value();
        }
        super.resize(this.minWidth);
      });
    this.formControl = this.dateRange;
    this.value = this.dateRange.value;
    super.resize(this.minWidth);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.isChipLock()) {
        this.chipInput?.nativeElement.focus();
      }
    }, 10);
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
