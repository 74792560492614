<span class="date-chip-content">
  <mat-datepicker-toggle
    class="prefix-icon"
    [disableRipple]="true"
    [disabled]="disabled"
    [for]="picker"
    attr.data-testid="cis_year_month_date_{{
      label?.toLowerCase()?.replace(' ', '_')
    }}_toggle"
  ></mat-datepicker-toggle>
  <label class="has-float-label">
    <input
      class="chip-input"
      [style.width.px]="inputWidth"
      (click)="$event.stopPropagation()"
      (focus)="$event.stopPropagation()"
      [formControl]="formControl"
      (keydown)="$event.stopImmediatePropagation()"
      [placeholder]="placeholder"
      (blur)="picker.close()"
      [readonly]="readonly"
      [matDatepicker]="picker"
      type="datetime"
      style="max-width: 80px; min-width: 76px"
      attr.data-testid="cis_year_month_date_{{
        label?.toLowerCase()?.replace(' ', '_')
      }}_input"
    />
    <div class="label">{{ label }}</div>
    <mat-datepicker
      attr.data-testid="cis_year_month_date_{{
        label?.toLowerCase()?.replace(' ', '_')
      }}_date_picker"
      #picker
    ></mat-datepicker>
  </label>
</span>
