<div class="row footer primary-bg vector_background">
  <div class="status d-none d-md-block my-0">
    <span *ngIf="online" class="status-section">
      <mat-icon
        data-testid="footer_icon_network_connected"
        style="font-size: 16px; padding-top: 7px"
        >wifi</mat-icon
      >
      <span class="status-section__text">{{
        'core.footer.online' | translate
      }}</span>
    </span>
    <span *ngIf="!online" class="status-section">
      <mat-icon
        data-testid="footer_icon_network_disconnected"
        style="font-size: 16px; padding-top: 7px"
        >wifi_off</mat-icon
      >
      <span class="status-section__text">{{
        'core.footer.offline' | translate
      }}</span>
    </span>
  </div>
  <p class="footer__copyright my-2">
    <span
      data-testid="footer_text_copyright_credentials"
      class="footer-text"
      [innerHTML]="footerText ? footerText : this.systemInformation?.footer"
    >
    </span>
    <span
      data-testid="footer_text_version_no"
      class="version mt-1 d-none d-md-block"
      >v{{ version }}</span
    >
  </p>
</div>
