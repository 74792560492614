import { FORMULA_ELEMENT_TYPES, OPERATOR_TYPES } from '@shared/constants';
import { IFormulaOperatorElement } from '@shared/interfaces';

export const comparativeOperators: IFormulaOperatorElement[] = [
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.COMPARATIVE,
      displayText: 'Equals',
      symbol: '==',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.COMPARATIVE,
      displayText: 'Not Equals',
      symbol: '!=',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.COMPARATIVE,
      displayText: 'Greater Than',
      symbol: '>',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.COMPARATIVE,
      displayText: 'Greater Than or Equal',
      symbol: '>=',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.COMPARATIVE,
      displayText: 'Less Than',
      symbol: '<',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.COMPARATIVE,
      displayText: 'Less Than or Equal',
      symbol: '<=',
    },
  },
];
