import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { AUTH_TYPE, ENDPOINTS, REGISTER_BY, URLS } from '@shared/constants';
import {
  CommonResponseDTO,
  IAuthAzure,
  IAuthorizerConfig,
  IIAMConfig,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

import { PasswordService } from './password.service';
import { IAMPasswordConfig, PasswordPolicyRegex } from './types';

export enum USER_MGT_FEATURES {
  SELF_REGISTRATION = 'self_registration',
  UNIQUENESS_EMAIL = 'uniqueness_email',
  SELF_PASSWORD_RESET = 'self_password_reset',
  FORGOT_PASSWORD = 'forgot_password',
}

export enum IAM_STATUS {
  INTERNAL = 'INTERNAL', // internal IAM is in use
  EXTERNAL = 'EXTERNAL', // external IAM is in use
  INTERNAL_PENDING = 'INTERNAL_PENDING', // internal IAM is in use
  EXTERNAL_PENDING = 'EXTERNAL_PENDING', // external IAM is in use
  INTERNAL_DISCONNECTED = 'INTERNAL_DISCONNECTED', // internal IAM is in use
}

interface IAMSecurity {
  brute_force_enabled: boolean;
  brute_force_duration: number;
  brute_force_no_of_attempts: number;
}

export interface IAMUserManagement {
  self_registration?: boolean;
  uniqueness_email?: boolean;
  uniqueness_phone_number?: boolean;
  self_register_type?: REGISTER_BY;
  self_password_reset?: boolean;
  forgot_password_reset_email?: {
    active: boolean;
    otp: boolean;
    link: boolean;
    username_required: boolean;
  };
  forgot_password_reset_phone?: {
    active: boolean;
    username_required: boolean;
  };
}

export interface Config {
  url?: string;
  description?: string;
  status?: CONFIG_STATUS;
  connected_on?: string;
  deactivated_on?: string;
  valid_till?: string;
  auth_type?: AUTH_TYPE;
  iam?: {
    status: IAM_STATUS;
    centralizedIAM?: boolean;
    current_config?: {
      url: string;
      client_id: string;
      client_secret?: string;
      connected_on?: Date;
      deactivated_on?: Date;
    };
  };
}

export enum CONFIG_STATUS {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

interface IDeleteIAMConfig {
  status: CONFIG_STATUS;
  url: string;
}

export interface IAMConfigRequestDTO {
  use_internal_iam: boolean;
  config?: {
    url: string;
    client_id: string;
    client_secret?: string;
  };
}

export const auth_code_mapping = [
  {
    key: 'setup.iam-config.auth-type.pkce',
    value: AUTH_TYPE.OAUTH_2_PKCE,
  },
  {
    key: 'setup.iam-config.auth-type.legacy-type',
    value: AUTH_TYPE.TOKEN_MODE,
  },
];

export interface IAMConfigAuthTypeRequestDTO {
  internal_auth_type: AUTH_TYPE;
}
@Injectable({
  providedIn: 'root',
})
export class IamConfigService {
  public readonly dataStore = new BehaviorSubject<IIAMConfig | undefined>(
    undefined
  );
  public readonly passRegex = new BehaviorSubject<PasswordPolicyRegex>(null);

  constructor(
    private http: HttpClient,
    private passwordService: PasswordService
  ) {}

  private async updateLocalConfig(iamConfig: IIAMConfig): Promise<void> {
    const res = await this.passwordService.generatePasswordRegexByPolicy(
      iamConfig.password_configurations
    );

    this.passRegex.next(res);
    this.dataStore.next(iamConfig);
  }

  generatePasswordUsingConfig(): string {
    return this.passwordService.generateRandomPassword(
      this.dataStore.value.password_configurations,
      this.passRegex.value
    );
  }

  getAllConfigData(): Observable<CommonResponseDTO<IIAMConfig>> {
    return this.http
      .get<CommonResponseDTO<IIAMConfig>>(URLS.IAM_CONFIG_IAM)
      .pipe(
        tap((res) => {
          this.updateLocalConfig(res.data);
        })
      );
  }

  updateIAMConfig(
    data: IAMConfigRequestDTO
  ): Observable<CommonResponseDTO<IIAMConfig>> {
    return this.http
      .patch<CommonResponseDTO<IIAMConfig>>(URLS.IAM_CONFIG_IAM, data)
      .pipe(
        tap((res) => {
          this.updateLocalConfig(res.data);
        })
      );
  }

  updateIAMAuthTypeConfig(
    data: IAMConfigAuthTypeRequestDTO,
    centralized_iam: boolean
  ): Observable<CommonResponseDTO<IIAMConfig>> {
    return this.http
      .patch<CommonResponseDTO<IIAMConfig>>(URLS.IAM_CONFIG_IAM_AUTH_TYPE, data)
      .pipe(
        tap((res) => {
          this.updateLocalConfig({
            ...res.data,
            iam: { ...res.data.iam, centralizedIAM: centralized_iam },
          });
        })
      );
  }

  deleteIamConfig(
    data: IDeleteIAMConfig
  ): Observable<CommonResponseDTO<IIAMConfig>> {
    return this.http
      .patch<CommonResponseDTO<IIAMConfig>>(URLS.IAM_CONFIG_IAM_DELETE, data)
      .pipe(
        tap((res) => {
          this.updateLocalConfig(res.data);
        })
      );
  }

  patchSecurityData(
    data: IAMSecurity
  ): Observable<CommonResponseDTO<IIAMConfig>> {
    return this.http
      .patch<CommonResponseDTO<IIAMConfig>>(URLS.IAM_CONFIG_SECURITY, data)
      .pipe(
        tap((res) => {
          this.updateLocalConfig(res.data);
        })
      );
  }

  patchUserManagementData(
    data: IAMUserManagement
  ): Observable<CommonResponseDTO<IIAMConfig>> {
    return this.http
      .patch<CommonResponseDTO<IIAMConfig>>(
        URLS.IAM_CONFIG_USER_MANAGEMENT,
        data
      )
      .pipe(
        tap((res) => {
          this.updateLocalConfig(res.data);
        })
      );
  }

  patchPasswordConfigData(
    data: IAMPasswordConfig
  ): Observable<CommonResponseDTO<IIAMConfig>> {
    return this.http
      .patch<CommonResponseDTO<IIAMConfig>>(
        URLS.IAM_CONFIG_PASSWORD_CONFIG,
        data
      )
      .pipe(
        tap((res) => {
          this.updateLocalConfig(res.data);
        })
      );
  }

  updateAzureConfig(
    data: IAuthorizerConfig<IAuthAzure>
  ): Observable<CommonResponseDTO<IIAMConfig>> {
    const endpoint = generateURL({
      endpoint: ENDPOINTS.SETUP_IAM_AUTHORIZERS_AZURE,
    });

    return this.http.patch<CommonResponseDTO<IIAMConfig>>(endpoint, data).pipe(
      tap((res) => {
        this.updateLocalConfig(res.data);
      })
    );
  }
}
