<mat-card [ngStyle]="{ 'padding-right': notices?.length > 0 ? 0 : '' }">
  <div class="d-flex justify-content-between mb-3">
    <div class="d-flex align-items-center title-container">
      <mat-icon>newspaper</mat-icon>
      <mat-card-title class="px-2">
        <h6 class="title-align">
          {{ 'pages.notices.root.title' | translate }}
        </h6>
      </mat-card-title>
    </div>
    <div [ngStyle]="{ 'margin-right': notices?.length > 0 ? '1.0rem' : '' }">
      <button
        mat-icon-button
        (click)="getNotices()"
        [matTooltip]="'pages.notices.root.reload-tooltip' | translate"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>

  <div *ngIf="isLoading">
    <app-skeleton-notices></app-skeleton-notices>
  </div>
  <div *ngIf="!isLoading">
    <mat-card-content
      *ngIf="notices?.length > 0; else noNotices"
      style="overflow-y: scroll; height: fit-content; max-height: 230px"
    >
      <div
        matRipple
        [matRippleCentered]="false"
        class="notice-box app-border mt-3 d-flex mr-2 px-2"
        *ngFor="let notice of notices"
        (click)="onClickNotice(notice)"
      >
        <div class="pl-2 title-body-align">
          <div class="title">{{ notice.title }}</div>
        </div>
        <div class="pr-2 date-align">
          <small style="white-space: nowrap">{{
            setStartDate(notice.start_date)
          }}</small>
        </div>
      </div>
    </mat-card-content>
  </div>
  <ng-template #noNotices>
    <app-no-results-found *ngIf="!isLoading"></app-no-results-found>
  </ng-template>
</mat-card>
