<div *ngIf="fullScreenLoading" class="loading-overlay"></div>

<mat-drawer-container [hasBackdrop]="screenWidth < 840" class="dashboard">
  <mat-drawer
    [disableClose]="true"
    [opened]="screenWidth > 840"
    #sidenav
    class="dashboard__sidenav"
    style="--drawer-width: {{ iconOnlyMode ? 'min-content' : '245px' }}"
    [mode]="screenWidth > 840 ? 'push' : 'over'"
  >
    <app-side-menu
      [iconOnlyMode]="iconOnlyMode && screenWidth > 840"
      (onClickedMenuItem)="changeMenuItem($event)"
      (isItemSelected)="isItemSelected($event, sidenav)"
    ></app-side-menu>
  </mat-drawer>

  <mat-drawer-content
    style="transition: all 0.3s ease; margin-right: 0 !important"
  >
    <mat-toolbar
      class="dashboard__sidenavcontent-toolbar vector_background"
      color="primary"
    >
      <mat-toolbar-row class="">
        <div class="truncate">
          <ng-container *ngIf="screenWidth > 840">
            <!-- side menu button -->
            <button
              mat-icon-button
              data-testid="header_btn_shrink_side_menu"
              *ngIf="!isSidenavOpen"
              (click)="
                screenWidth < 840 && sidenav.toggle(); onToggle(true, false)
              "
              matTooltip="{{
                'pages.dashboard.toggle-side-menu' | translate
              }} (Alt/ ⌥ + S)"
            >
              <mat-icon aria-label="menu" style="margin-bottom: 4px"
                >menu</mat-icon
              >
            </button>

            <!-- Mini side menu button -->
            <button
              mat-icon-button
              data-testid="header_btn_expand_side_menu"
              *ngIf="isSidenavOpen"
              (click)="
                screenWidth < 840 && sidenav.toggle(); onToggle(false, true)
              "
              matTooltip="{{
                'pages.dashboard.toggle-side-menu' | translate
              }} (Alt/ ⌥ + S)"
            >
              <mat-icon aria-label="menu" style="margin-bottom: 4px"
                >menu_open</mat-icon
              >
            </button>
          </ng-container>

          <!-- Mini side menu button for the mobile view -->
          <ng-container *ngIf="screenWidth <= 840">
            <button
              mat-icon-button
              data-testid="header_btn_expand_side_menu_mobile"
              *ngIf="!sidenav.opened"
              (click)="sidenav.toggle()"
              matTooltip="{{ 'pages.dashboard.toggle-side-menu' | translate }}"
            >
              <mat-icon aria-label="menu" style="margin-bottom: 4px"
                >menu</mat-icon
              >
            </button>
            <button
              mat-icon-button
              data-testid="header_btn_shrink_side_menu_mobile"
              *ngIf="sidenav.opened"
              (click)="sidenav.toggle()"
              matTooltip="{{ 'pages.dashboard.toggle-side-menu' | translate }}"
            >
              <mat-icon aria-label="menu" style="margin-bottom: 4px"
                >menu_open</mat-icon
              >
            </button>
          </ng-container>
          <span
            class="dashboard__app-title"
            *ngIf="systemInformation && isLaunchPad"
            >{{ systemInformation?.name }}
          </span>
          <span
            class="dashboard__app-title"
            data-testid="header_title_name"
            *ngIf="systemInformation && !isLaunchPad"
            style="cursor: pointer"
            routerLink="/app/launchpad"
            >{{ systemInformation?.name }}
          </span>
        </div>

        <div class="spacer"></div>
        <span class="right-side-options">
          <mat-chip-list *ngIf="impersonatingIdentity" #chipList>
            <!--  -->
            <mat-chip (removed)="removeImpersonate()">
              <label class="has-float-label">
                <input
                  #textInput
                  [style.width.px]="
                    getInputWidth(textInput.value.toString().length, label)
                  "
                  data-testid="header_impersonate_chip_input_name"
                  class="chip-input"
                  [value]="
                    impersonatingIdentity?.first_name +
                    ' ' +
                    (impersonatingIdentity?.last_name || ' ')
                  "
                  [readonly]="true"
                />
                <div #label class="label">Impersonating as</div>
              </label>

              <button
                data-testid="header_impersonate_chip_btn_cancel"
                matChipRemove
              >
                <mat-icon style="vertical-align: super">cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-list>

          <app-notification-panel
            data-testid="header_btn_notification_pannel"
            [user]="userInformation"
            [mobileView]="screenWidth < 481"
          >
          </app-notification-panel>
          <button
            *ngIf="screenWidth > 480 && !isFullScreen"
            mat-icon-button
            data-testid="header_btn_enable_full_screen"
            matTooltip="{{
              'pages.dashboard.full-screen' | translate
            }} (Alt/ ⌥ + F)"
            (click)="openFullscreen()"
          >
            <mat-icon aria-label="fullscreen" style="margin-bottom: 4px"
              >fullscreen</mat-icon
            >
          </button>
          <button
            *ngIf="screenWidth > 480 && isFullScreen"
            data-testid="header_btn_disable_full_screen"
            mat-icon-button
            matTooltip="{{ 'pages.dashboard.full-screen' | translate }}"
            (click)="closeFullscreen()"
          >
            <mat-icon aria-label="fullscreen_exit" style="margin-bottom: 4px"
              >fullscreen_exit</mat-icon
            >
          </button>

          <!-- open in new tab button -->
          <button
            *ngIf="screenWidth > 480 && !isFullScreen"
            mat-icon-button
            data-testid="header_btn_open_new_tab"
            matTooltip="{{
              'pages.dashboard.open-in-new-tab' | translate
            }} (Alt/ ⌥ + N)"
            (click)="openInNewTab()"
          >
            <mat-icon
              aria-label="open_in_new"
              style="margin-bottom: 4px; font-size: 20px"
              >open_in_new</mat-icon
            >
          </button>

          <button
            *ngIf="
              router.url !== '/app/launchpad' &&
              quickActionsCount < maxQuickActions
            "
            mat-icon-button
            data-testid="header_btn_pin_quick_action"
            matTooltip="{{ 'pages.dashboard.quick-action' | translate }}"
            (click)="onCreateQuickAction()"
          >
            <mat-icon
              aria-label="fullscreen"
              style="margin-bottom: 4px; font-size: 20px"
              >push_pin</mat-icon
            >
          </button>

          <button
            *ngIf="screenWidth > 480"
            mat-button
            data-testid="header_btn_logged_in_user_expanded"
            class="logged_in_user_chip truncate"
            [matMenuTriggerFor]="profile"
            matTooltip="{{ 'pages.dashboard.options' | translate }}"
          >
            <span class="truncate"
              >{{ 'pages.dashboard.hi' | translate
              }}{{ userInformation?.first_name || '' }}</span
            >
            <mat-icon style="font-size: 20px; padding-top: 2px"
              >keyboard_arrow_down</mat-icon
            >
          </button>

          <button
            *ngIf="480 > screenWidth"
            mat-icon-button
            data-testid="header_btn_logged_in_user"
            matTooltip="{{ 'pages.dashboard.options' | translate }}"
            [matMenuTriggerFor]="profile"
          >
            <mat-icon aria-label="more_vert" style="margin-bottom: 4px"
              >more_vert</mat-icon
            >
          </button>

          <mat-menu #profile="matMenu">
            <button
              *ngIf="480 > screenWidth"
              mat-menu-item
              data-testid="header_side_menu_btn_profile"
              routerLink="/app/profile"
            >
              <img
                matListIcon
                fetchpriority="high"
                style="border-radius: 50%"
                class="mat-icon"
                [src]="imageUrl"
                alt="profile-pic"
              />
              {{ userInformation?.first_name || '' }}
            </button>

            <button
              *ngIf="screenWidth > 480"
              mat-menu-item
              data-testid="header_side_menu_btn_profile_expanded"
              routerLink="/app/profile"
            >
              <img
                matListIcon
                fetchpriority="high"
                style="border-radius: 50%"
                class="mat-icon"
                [src]="imageUrl"
                alt="profile-pic"
              />{{ 'pages.dashboard.my-account' | translate }}
            </button>

            <mat-divider></mat-divider>

            <button
              data-testid="header_side_menu_btn_personalization"
              mat-menu-item
              routerLink="/app/personalization"
            >
              <mat-icon matListIcon>person_pin</mat-icon
              >{{ 'pages.dashboard.personalization' | translate }}
            </button>

            <button
              data-testid="header_side_menu_btn_accessibility"
              mat-menu-item
              [matMenuTriggerFor]="accessibility"
            >
              <mat-icon matListIcon>accessibility</mat-icon
              >{{ 'pages.dashboard.accessibility' | translate }}
            </button>

            <mat-menu #accessibility="matMenu">
              <app-dark-mode
                data-testid="header_side_menu_accessibility_menu_btn_dark_mode"
              ></app-dark-mode>
              <app-keyboard-shortcuts
                data-testid="header_side_menu_accessibility_menu_btn_keyboard_shortcuts"
              ></app-keyboard-shortcuts>
              <button
                mat-menu-item
                data-testid="header_side_menu_accessibility_menu_btn_language"
                (click)="openBottomSheet()"
                [disabled]="languageDisable"
              >
                <div *ngIf="!languageDisable">
                  <mat-icon
                    data-testid="header_side_menu_accessibility_menu_btn_language_enabled"
                    matListIcon
                    >translate</mat-icon
                  >{{ 'pages.dashboard.choose-a-language' | translate }}
                </div>
                <div *ngIf="languageDisable">
                  <mat-icon
                    data-testid="header_side_menu_accessibility_menu_btn_language_disabled"
                    matListIcon
                    style="color: rgb(231, 229, 229)"
                    >translate</mat-icon
                  >{{ 'pages.dashboard.choose-a-language' | translate }}
                </div>
              </button>
            </mat-menu>

            <button
              *ngIf="resetPasswordDisabled && !resetPasswordDisabledByAuthType"
              mat-menu-item
              data-testid="header_side_menu_btn_reset_password"
              routerLink="/app/profile/reset-password"
            >
              <mat-icon matListIcon>lock</mat-icon
              >{{ 'pages.dashboard.security' | translate }}
            </button>

            <button
              data-testid="header_side_menu_accessibility_menu_btn_about"
              mat-menu-item
              routerLink="about"
            >
              <mat-icon matListIcon>info</mat-icon
              >{{ 'pages.dashboard.about' | translate }}
            </button>
            <mat-divider></mat-divider>
            <button
              data-testid="header_side_menu_accessibility_menu_btn_logout"
              mat-menu-item
              (click)="logout()"
            >
              <mat-icon matListIcon>exit_to_app</mat-icon
              >{{ 'pages.dashboard.log-out' | translate }}
            </button>
          </mat-menu>
        </span>
      </mat-toolbar-row>

      <mat-toolbar-row class="last-mat-toolbar-row" style="margin-top: -10px">
        <span *ngIf="goBackEnabled">
          <button
            mat-icon-button
            data-testid="header_btn_back"
            (click)="onBackClicked()"
            class="back-button"
            matTooltip="{{
              'pages.dashboard.go-to-the-previous-page' | translate
            }}"
          >
            <mat-icon aria-label="back">arrow_back</mat-icon>
          </button>
        </span>
        <span *ngIf="!searchBarEnabled" class="dashboard__tab pl-2"
          >{{ title || '' | translate }}
        </span>

        <el-chip-input-search
          *ngIf="searchBarEnabled"
          class="search-box"
          [config]="config"
          [title]="title"
        >
        </el-chip-input-search>
      </mat-toolbar-row>
    </mat-toolbar>

    <div class="page-container-frame">
      <div *ngIf="pageContentLoading" class="loading-overlay">
        <mat-progress-bar
          color="accent"
          mode="indeterminate"
        ></mat-progress-bar>
      </div>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
