import {
  MODULES,
  REFERENCE_PERMISSION_ACTIONS,
  USER_TYPES,
} from '@shared/constants';
import { IConnectedAppResponse, IIdentityResponse } from '@shared/interfaces';
import { generatePermissionKey } from '@shared/utils';

import { IConnectionResponse } from '../../connections/services/connections.service';

import { validatePermissionsForLoggedUser } from './validate-logged-user-permissions.helper';

export const validateReferenceModulePermissions = (
  identity: {
    user_type: USER_TYPES;
    connection?: IConnectionResponse<IConnectedAppResponse, IIdentityResponse>;
  },
  checkingCategories: { name: string }[],
  permission: REFERENCE_PERMISSION_ACTIONS
): 'all' | 'specific' | 'none' => {
  if (
    // checks whether the user is EL type user or have all-reference-category permission
    validatePermissionsForLoggedUser(identity, [
      generatePermissionKey(MODULES.REFERENCES, permission),
    ])
  ) {
    return 'all';
  } else if (
    // check whether if the user does have any other category-specific permission
    (identity?.connection?.permissions || []).some((connectionPermission) =>
      checkingCategories
        .map((category) =>
          generatePermissionKey(MODULES.REFERENCES, permission, category?.name)
        )
        .includes(connectionPermission)
    )
  ) {
    return 'specific';
  } else {
    // no any permission
    return 'none';
  }
};
