<mat-card
  class="widget__container"
  *ngIf="hasPermissions && clickable"
  matRipple
  (dblclick)="onClickWidget()"
>
  <mat-card-content *ngIf="isLoading || !loggedUserInfo">
    <app-line-chart-skeleton></app-line-chart-skeleton>
  </mat-card-content>

  <mat-card-content
    *ngIf="isFailedToLoad && !isLoading && !loggedUserInfo"
    class="widget__content"
  >
    <div class="load-error">
      <div class="error-content">
        <mat-icon class="error-icon">error_outline</mat-icon>
        <div class="error-message">
          <div class="pl-1 error-text">
            <span class="failed-message-bold">{{
              'widgets.error.failed-to-load' | translate
            }}</span>
            <span class="failed-message">{{
              'widgets.error.refresh' | translate
            }}</span>
          </div>
        </div>
      </div>
      <button mat-icon-button (click)="refreshWidget()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-content>

  <mat-card-content
    class="widget__content"
    *ngIf="!isFailedToLoad && !isLoading && loggedUserInfo"
  >
    <div class="widget__details">
      <div class="widget__description">
        <span class="title">{{ widget?.title | translate }}</span>
        <span class="subtitle">{{
          widget?.category_description | translate
        }}</span>
        <span class="subtitle">{{ description }}</span>
      </div>

      <button mat-icon-button (click)="refreshWidget()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>

    <div class="widget__chart">
      <ngx-charts-area-chart
        [scheme]="scheme"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [results]="multi"
      >
      </ngx-charts-area-chart>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="widget__container" *ngIf="hasPermissions && !clickable">
  <mat-card-content *ngIf="isLoading">
    <app-line-chart-skeleton></app-line-chart-skeleton>
  </mat-card-content>

  <mat-card-content
    *ngIf="isFailedToLoad && !isLoading"
    class="widget__content"
  >
    <div class="load-error">
      <div class="error-content">
        <mat-icon class="error-icon">error_outline</mat-icon>
        <div class="error-message">
          <div class="pl-1 error-text">
            <span class="failed-message-bold">{{
              'widgets.error.failed-to-load' | translate
            }}</span>
            <span class="failed-message">{{
              'widgets.error.refresh' | translate
            }}</span>
          </div>
        </div>
      </div>
      <button mat-icon-button (click)="refreshWidget()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-content>

  <mat-card-content
    class="widget__content"
    *ngIf="!isFailedToLoad && !isLoading"
  >
    <div class="widget__details">
      <div class="widget__description">
        <span class="title">{{ widget?.title | translate }}</span>
        <span class="subtitle">{{
          widget?.category_description | translate
        }}</span>
        <span class="subtitle">{{ description }}</span>
      </div>

      <button mat-icon-button (click)="refreshWidget()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>

    <div class="widget__chart">
      <ngx-charts-area-chart
        [scheme]="scheme"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [results]="multi"
      >
      </ngx-charts-area-chart>
    </div>
  </mat-card-content>
</mat-card>
