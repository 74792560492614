import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';

import { SnackbarService } from '../../../services/snackbar.service';
import { CreateNoteComponent } from '../popups/create-note/create-note.component';

export interface PostItNotesActionsRequestDTO {
  ids: string[];
}
export interface CreateNote {
  title: string;
  start_date: Date;
  end_date: Date;
  background_color: string;
  text_color: string;
  description: string;
}

export interface IPostItNotesDTO extends CreateNote {
  _id: string;
  index_number: number;
}

export interface IDraggedNoteIndexChangeDTO {
  current_note_id: string;
  above_note_id?: string;
  below_note_id?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PostItNotesService {
  shortcuts: ShortcutInput[] = [];

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private snackBar: SnackbarService,
    private translate: TranslateService
  ) {}

  onShortcutTrigger() {
    const dialog = this.dialog.open(CreateNoteComponent, {
      width: '40%',
      minWidth: '330px',
      maxWidth: '600px',
      panelClass: 'create-note-dialog-container',
    });

    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.getSingleNote(data).subscribe({
          error: () => {
            this.snackBar.error(
              this.translate.instant(
                'pages.post-it-notes.root.created-note-not-found-snackbar'
              )
            );
          },
        });
      }
    });
  }

  getAllNotes(): Observable<CommonResponseDTO<IPostItNotesDTO[]>> {
    return this.http.get<CommonResponseDTO<IPostItNotesDTO[]>>(
      URLS.POST_IT_NOTES_NOTES,
      {}
    );
  }

  getAllArchivedNotes(): Observable<CommonResponseDTO<IPostItNotesDTO[]>> {
    return this.http.get<CommonResponseDTO<IPostItNotesDTO[]>>(
      URLS.POST_IT_NOTES_ARCHIVED,
      {}
    );
  }

  getSingleNote(id: string): Observable<CommonResponseDTO<IPostItNotesDTO>> {
    const url = `${URLS.POST_IT_NOTES_NOTES}/${id}`;
    return this.http.get<CommonResponseDTO<IPostItNotesDTO>>(url, {});
  }

  createNote(data: CreateNote): Observable<CommonResponseDTO<IPostItNotesDTO>> {
    return this.http.post<CommonResponseDTO<IPostItNotesDTO>>(
      URLS.POST_IT_NOTES_NOTES,
      data
    );
  }

  changeNotePosition(
    data: IDraggedNoteIndexChangeDTO
  ): Observable<CommonResponseDTO<IPostItNotesDTO>> {
    return this.http.patch<CommonResponseDTO<IPostItNotesDTO>>(
      URLS.POST_IT_NOTES_REARRANGE,
      data
    );
  }

  archiveNote(id: string): Observable<CommonResponseDTO<IPostItNotesDTO>> {
    const url = `${URLS.POST_IT_NOTES_NOTES}/${id}/archive`;
    return this.http.patch<CommonResponseDTO<IPostItNotesDTO>>(url, {});
  }

  restoreNotes(
    data: PostItNotesActionsRequestDTO
  ): Observable<CommonResponseDTO<IPostItNotesDTO[]>> {
    return this.http.patch<CommonResponseDTO<IPostItNotesDTO[]>>(
      URLS.POST_IT_NOTES_RESTORE,
      data
    );
  }

  deleteNotes(
    data: PostItNotesActionsRequestDTO
  ): Observable<CommonResponseDTO<IPostItNotesDTO[]>> {
    return this.http.post<CommonResponseDTO<IPostItNotesDTO[]>>(
      URLS.POST_IT_NOTES_DELETE,
      data
    );
  }
}
