import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';

import { NoticeDTO } from '../../../modules/notices/services/notice.service';

@Injectable({
  providedIn: 'root',
})
export class NoticesService {
  constructor(private http: HttpClient) {}

  getAllNotices(): Observable<CommonResponseDTO<NoticeDTO[]>> {
    return this.http.get<CommonResponseDTO<NoticeDTO[]>>(URLS.NOTICES, {});
  }

  getSingleNotice(notice: NoticeDTO) {
    return this.http.get<CommonResponseDTO<NoticeDTO>>(
      `${URLS.NOTICE}/${notice._id}`
    );
  }
}
