import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SingleElementComponent } from './components/single-element/single-element.component';
import { SizeDetectorComponent } from './components/size-detector/size-detector.component';
import { CopyChipComponent } from './copy-chip/copy-chip.component';
import { ElDataListTable } from './el-data-list-table.component';
@NgModule({
  declarations: [
    ElDataListTable,
    ProgressBarComponent,
    SizeDetectorComponent,
    SingleElementComponent,
    CopyChipComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatTooltipModule,
    MatChipsModule,
    MatCheckboxModule,
    DragDropModule,
  ],
  exports: [ElDataListTable],
})
export class ElDataListTableModule {}
