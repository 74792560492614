<div *ngIf="isLoading" class="loading-overlay" style="padding-right: 150px">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="container pt-3">
  <div class="row">
    <div class="col-12 col-md-7 mb-3">
      <div style="margin-top: 15px">
        <mat-accordion multi>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h6 class="d-flex align-items-center">
                  <strong>{{
                    'personalization.root.title' | translate
                  }}</strong>
                </h6>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="iamConfigTwoFactorEnabled">
              <mat-divider></mat-divider>
              <div class="mt-4">
                <div class="row pl-2 pr-2">
                  <div class="col-12 col-sm-3">
                    <div class="pb-2">
                      <mat-title>
                        <strong>{{
                          'personalization.two-factor.title' | translate
                        }}</strong>
                      </mat-title>
                    </div>
                    <mat-card-subtitle>
                      {{ 'personalization.two-factor.description' | translate }}
                    </mat-card-subtitle>
                  </div>
                  <div class="col-12 col-sm-8">
                    <form [formGroup]="twoFactorFormGroup">
                      <div class="d-sm-flex justify-content-between">
                        <div>
                          <mat-checkbox
                            data-testid="personalization_check_select_option_email"
                            formControlName="emailEnable"
                            >{{
                              'personalization.two-factor.email' | translate
                            }}</mat-checkbox
                          >
                        </div>
                        <div>
                          <mat-checkbox
                            data-testid="personalization_check_select_option_sms"
                            formControlName="smsEnable"
                            [disabled]="true"
                            >{{
                              'personalization.two-factor.sms' | translate
                            }}</mat-checkbox
                          >
                        </div>
                        <div>
                          <mat-checkbox
                            data-testid="personalization_check_select_option_google"
                            formControlName="googleEnable"
                            [disabled]="true"
                            >{{
                              'personalization.two-factor.google-authenticator'
                                | translate
                            }}</mat-checkbox
                          >
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row justify-content-end pb-4 pr-4">
                  <button
                    data-testid="personalization_btn_update_tf_config"
                    mat-raised-button
                    color="primary"
                    (click)="onUpdateTwoFactor()"
                    [disabled]="twoFactorFormGroup.pristine"
                  >
                    {{ 'personalization.root.save-button' | translate }}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <mat-divider></mat-divider>
              <div class="row pt-4 pl-2 pr-2">
                <div class="col-12 col-sm-3">
                  <div class="pb-2">
                    <mat-title>
                      <strong>{{
                        'personalization.language-preferences.title' | translate
                      }}</strong>
                    </mat-title>
                  </div>
                  <mat-card-subtitle>
                    {{
                      'personalization.language-preferences.description'
                        | translate
                    }}
                  </mat-card-subtitle>
                </div>
                <div class="col-12 col-sm-8">
                  <mat-form-field appearance="fill">
                    <mat-label>{{
                      'personalization.language-preferences.label' | translate
                    }}</mat-label>
                    <form>
                      <mat-select
                        data-testid="personalization_input_select_language"
                        required
                        [formControl]="languagePreferences"
                      >
                        <mat-option
                          attr.data-testid="personalization_input_select_language_option_{{
                            language.viewValue
                              .toLowerCase()
                              .replaceAll(' ', '_')
                          }}"
                          *ngFor="let language of languages"
                          [value]="language.value"
                        >
                          {{ language.viewValue }}
                        </mat-option>
                      </mat-select>
                    </form>
                  </mat-form-field>
                </div>
              </div>
              <div class="row justify-content-end pb-4 pr-4">
                <button
                  data-testid="personalization_btn_update_language_config"
                  mat-raised-button
                  color="primary"
                  (click)="onUpdateLanguage()"
                  [disabled]="languagePreferences.pristine"
                >
                  {{ 'personalization.root.save-button' | translate }}
                </button>
              </div>
            </div>

            <div>
              <mat-divider></mat-divider>
              <div class="row pt-4 pl-2 pr-2">
                <div class="col-12 col-sm-3">
                  <div class="pb-2">
                    <mat-title>
                      <strong>{{
                        'personalization.dark-mode.title' | translate
                      }}</strong>
                    </mat-title>
                  </div>
                  <mat-card-subtitle>
                    {{ 'personalization.dark-mode.description' | translate }}
                  </mat-card-subtitle>
                </div>
              </div>
              <div class="row pb-4 pl-2 pr-2">
                <div class="col-2">
                  <form>
                    <mat-slide-toggle
                      data-testid="personalization_btn_toggle_enable_dark_mode"
                      [formControl]="darkModeEnable"
                      (change)="onChangeDarkModeToggle()"
                    ></mat-slide-toggle>
                  </form>
                </div>
              </div>
            </div>

            <div *ngIf="isNotificationEnableViaEmail">
              <mat-divider></mat-divider>
              <div class="row pt-4 pl-2 pr-2">
                <div class="col-4col-12 col-sm-3">
                  <div class="pb-2">
                    <mat-title>
                      <strong>{{
                        'personalization.email-notifications.title' | translate
                      }}</strong>
                    </mat-title>
                  </div>
                  <mat-card-subtitle>
                    {{
                      'personalization.email-notifications.description'
                        | translate
                    }}
                  </mat-card-subtitle>
                </div>
              </div>
              <div class="row pb-4 pl-2 pr-2">
                <div class="col-2">
                  <form>
                    <mat-slide-toggle
                      data-testid="personalization_btn_toggle_enable_email_notifications"
                      [formControl]="emailNotificationsEnable"
                      (change)="onChangeEmailNotificationsToggle()"
                    >
                    </mat-slide-toggle>
                  </form>
                </div>
              </div>
            </div>

            <div *ngIf="isPushNotificationEnable">
              <mat-divider></mat-divider>
              <div class="row pt-4 pl-2 pr-2">
                <div class="col-4col-12 col-sm-3">
                  <div class="pb-2">
                    <mat-title>
                      <strong>{{
                        'personalization.push-notifications.title' | translate
                      }}</strong>
                    </mat-title>
                  </div>
                  <mat-card-subtitle>
                    {{
                      'personalization.push-notifications.description'
                        | translate
                    }}
                  </mat-card-subtitle>
                </div>
              </div>
              <div class="row pb-4 pl-2 pr-2">
                <div class="col-2">
                  <form>
                    <mat-slide-toggle
                      data-testid="personalization_btn_toggle_enable_push_notifications"
                      [formControl]="pushNotificationsEnable"
                      (change)="onChangePushNotificationsToggle()"
                    >
                    </mat-slide-toggle>
                  </form>
                </div>
              </div>
            </div>

            <div>
              <mat-divider></mat-divider>
              <div class="row pt-4 pl-2 pr-2">
                <div class="col-12 col-sm-3">
                  <div class="pb-2">
                    <mat-title>
                      <strong>{{
                        'personalization.mini-side-menu.title' | translate
                      }}</strong>
                    </mat-title>
                  </div>
                  <mat-card-subtitle>{{
                    'personalization.mini-side-menu.description' | translate
                  }}</mat-card-subtitle>
                </div>
              </div>
              <div class="row pb-4 pl-2 pr-2">
                <div class="col-2">
                  <form>
                    <mat-slide-toggle
                      data-testid="personalization_btn_toggle_enable_mini_side_menu"
                      [formControl]="miniSideMenuEnable"
                      (change)="onChangeSideMenuToggle()"
                    ></mat-slide-toggle>
                  </form>
                </div>
              </div>
            </div>

            <mat-divider></mat-divider>

            <div class="layout-container">
              <div class="row pt-3">
                <div class="col-3">
                  <div class="pb-2">
                    <mat-title>
                      <strong>{{
                        'widgets.personalization.title' | translate
                      }}</strong>
                    </mat-title>
                  </div>
                  <mat-card-subtitle>
                    {{ 'widgets.personalization.description' | translate }}
                  </mat-card-subtitle>
                </div>
                <div class="col-9">
                  <mat-form-field appearance="fill">
                    <mat-label>{{
                      'widgets.personalization.title' | translate
                    }}</mat-label>

                    <mat-select
                      data-testid="personalization_input_select_language"
                      required
                      [formControl]="widgetLayout"
                      (selectionChange)="onChangeWidgetLayout()"
                    >
                      <mat-option *ngFor="let role of roles" [value]="role._id">
                        {{ role.role }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="layout-btn">
                <button
                  data-testid="personalization_btn_update_widget_layout"
                  mat-raised-button
                  color="primary"
                  (click)="onSaveWidgetLayout()"
                  [disabled]="widgetLayout.invalid || widgetLayout.pristine"
                >
                  {{ 'personalization.root.widget-save' | translate }}
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <!-- For the help & support page -->
    <div class="col-12 col-md-5 mb-3"></div>
  </div>
</div>
