export enum LOG_ACTIONS {
  // BASIC ACTIONS
  BASIC_ADD_DOCUMENT = 'BASIC_ADD_DOCUMENT',
  BASIC_UPDATE_DOCUMENT = 'BASIC_UPDATE_DOCUMENT',
  BASIC_DELETE_DOCUMENT = 'BASIC_DELETE_DOCUMENT',
  BASIC_RESTORE_DOCUMENT = 'BASIC_RESTORE_DOCUMENT',
  BASIC_ROLE_BACK = 'BASIC_ROLE_BACK',
  BASIC_DELETE_MULTIPLE_DOCUMENTS = 'BASIC_DELETE_MULTIPLE_DOCUMENTS',

  // BACKUPS SPECIFIC ACTIONS
  ENABLE_BACKUP_SCHEDULE = 'ENABLE_BACKUP_SCHEDULE',
  DISABLE_BACKUP_SCHEDULE = 'DISABLE_BACKUP_SCHEDULE',
  MANUAL_BACKUP = 'MANUAL_BACKUP',
  DOWNLOAD_BACKUP = 'DOWNLOAD_BACKUP',
  DELETE_BACKUP = 'DELETE_BACKUP',

  // AUTHENTICATION SPECIFIC ACTIONS
  AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED',
  AUTH_REPLAY_ATTACK_ATTEMPT = 'AUTH_REPLAY_ATTACK_ATTEMPT',
  AUTH_LOGGED_OUT = 'AUTH_LOGGED_OUT',

  // USER SPECIFIC ACTIONS
  BLOCK_BRUTE_FORCE_USER = 'BLOCK_BRUTE_FORCE_USER',
  UNBLOCK_BRUTE_FORCE_USER = 'UNBLOCK_BRUTE_FORCE_USER',

  // DMS::FS SPECIFIC ACTIONS
  STORAGE_CREATE_FOLDER = 'STORAGE_CREATE_FOLDER',
  STORAGE_UPLOAD_BUCKET = 'STORAGE_UPLOAD_BUCKET',
  STORAGE_UPLOAD_FILE = 'STORAGE_UPLOAD_FILE',
  STORAGE_MODIFY_FILE = 'STORAGE_MODIFY_FILE',
  STORAGE_DOWNLOAD_ENTITY = 'STORAGE_DOWNLOAD_ENTITY',
  STORAGE_ENTITY_PREVIEW = 'STORAGE_ENTITY_PREVIEW',
  STORAGE_MOVE_ENTITY = 'STORAGE_MOVE_ENTITY',
  STORAGE_VERIFY_ENTITY = 'STORAGE_VERIFY_ENTITY',
  STORAGE_DELETE_ENTITY = 'STORAGE_DELETE_ENTITY',
  STORAGE_RENAME_ENTITY = 'STORAGE_RENAME_ENTITY',
  STORAGE_RESTORE_ENTITY = 'STORAGE_RESTORE_ENTITY',
  STORAGE_EDIT_TAGS_ENTITY = 'STORAGE_EDIT_TAGS_ENTITY',
  STORAGE_INTERNALLY_SHARED = 'STORAGE_INTERNALLY_SHARED',
  STORAGE_INTERNALLY_SHARED_DELETE_ENTITY = 'STORAGE_INTERNALLY_SHARED_DELETE_ENTITY',
  STORAGE_INTERNALLY_SHARED_PERMISSION_CHANGE = 'STORAGE_INTERNALLY_SHARED_PERMISSION_CHANGE',
  STORAGE_EXTERNALLY_SHARED = 'STORAGE_ENTITY_EXTERNALLY_SHARED',
  STORAGE_ENTITY_EXTERNALLY_SHARED_DELETE = 'STORAGE_ENTITY_EXTERNALLY_SHARED_DELETE',
  STORAGE_ENTITY_EXTERNALLY_SHARED_OTP_GENERATED = 'STORAGE_ENTITY_EXTERNALLY_SHARED_OTP_GENERATED',
  STORAGE_ENTITY_EXTERNALLY_SHARED_USER_VALIDATED = 'STORAGE_ENTITY_EXTERNALLY_SHARED_USER_VALIDATED',
  STORAGE_ENTITY_EXTERNALLY_SHARED_PASSWORD_VALIDATED = 'STORAGE_ENTITY_EXTERNALLY_SHARED_PASSWORD_VALIDATED',
  STORAGE_ENTITY_EXTERNALLY_SHARED_OTP_VALIDATED = 'STORAGE_ENTITY_EXTERNALLY_SHARED_OTP_VALIDATED',
  STORAGE_ENTITY_EXTERNALLY_SHARED_ENTITY_PREVIEW = 'STORAGE_ENTITY_EXTERNALLY_SHARED_ENTITY_PREVIEW',
  STORAGE_ENTITY_EXTERNALLY_SHARED_ENTITY_DOWNLOAD = 'STORAGE_ENTITY_EXTERNALLY_SHARED_ENTITY_DOWNLOAD',
  STORAGE_QUOTA_UPDATE = 'STORAGE_QUOTA_UPDATE',

  // IMAGE UPLOAD
  IMAGE_UPLOAD = 'IMAGE_UPLOAD',

  // EXTERNAL APP IAM HANDSHAKE
  IAM_HANDSHAKE_INITIATED = 'IAM_HANDSHAKE_INITIATED',
  IAM_HANDSHAKE_SUCCESS = 'IAM_HANDSHAKE_SUCCESS',
  IAM_HANDSHAKE_FAILED = 'IAM_HANDSHAKE_FAILED',

  // EXTERNAL STORAGE DMS HANDSHAKE
  STORAGE_HANDSHAKE_INITIATED = 'STORAGE_HANDSHAKE_INITIATED',
  STORAGE_HANDSHAKE_SUCCESS = 'STORAGE_HANDSHAKE_SUCCESS',
  STORAGE_HANDSHAKE_FAILED = 'STORAGE_HANDSHAKE_FAILED',
}
