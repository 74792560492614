import { Injectable } from '@angular/core';

import { ModulesService } from '../modules/modules/services/modules.service';
import { ReferenceCategoryService } from '../modules/references/services';
import { PersonalizationService } from '../pages/personalization/services/personalization.service';

import { MailboxApiService } from './../modules/mailbox/services/mailbox.api.service';

@Injectable({ providedIn: 'root' })
export class AfterLoginService {
  constructor(
    private personalizationService: PersonalizationService,
    private modulesService: ModulesService,
    private mailboxApiService: MailboxApiService,
    private referenceCategoryService: ReferenceCategoryService
  ) {}

  do() {
    this.personalizationService.getPersonalizationData().subscribe();
    this.referenceCategoryService.getAllReferenceCategories();
    this.modulesService.getAllModules().subscribe();
    this.mailboxApiService.initMailboxConfigs().subscribe();
  }
}
