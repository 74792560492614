import { ENDPOINTS } from '@shared/constants';

import { EndpointParams } from './modules/types';

const BASE_URLS = {
  ASSETS: {
    IMAGES: `/assets/images`,
  },
  AUTH: `/auth`,
  CONNECTED_APPS: `/connected-apps`,
  CONNECTIONS: `/connections`,
  IDENTITIES: `/identities`,
  INTEGRATIONS: {
    ENDPOINTS: `/integrations/endpoints`,
    MAPPING: `/integrations/mappings`,
  },
  JOB_QUEUE: `/job-queue`,
  MODULES: `/modules`,
  PERSONALIZATION: `/personalization`,
  NOTIFICATION: `/notifications`,
  CONFIGURABLE_FIELDS: {
    VALIDATIONS: `/configurable-fields/validations`,
  },
  REFERENCES: {
    REFERENCE_CATEGORIES: `/reference-categories`,
    REFERENCES: `/references`,
    IAM_REFERENCE_CATEGORIES: `/iam/reference-categories`,
    IAM_REFERENCES: `/iam/references`,
    EXTERNAL_REFERENCE_CATEGORIES: `/external/reference-categories`,
    EXTERNAL_REFERENCES: `/external/references`,
    CLIENT_REFERENCE_CATEGORIES: `/client/reference-categories`,
    CLIENT_REFERENCES: `/client/references`,
    TEMPLATES: `/templates`,
    FORMULA: `/formula`,
  },
  SETUP: {
    AUTHORIZERS: `/setup/iam-config/authorizers`,
    IAM_HANDSHAKE: `/setup/iam-config/handshake`,
    BACKUPS: `/setup/backups`,
    BACKUP_SCHEDULES: `/setup/backup-schedules`,
    LANGUAGES: `/setup/languages`,
    REFERENCE_MAPPINGS: `/setup/reference-mappings`,
    CURRENCIES: `/setup/currencies`,
    EXTERNAL_SYSTEMS: `/setup/external-systems`,
    EXTERNAL_SYSTEMS_KEYS: `/setup/external-systems/keys`,
  },
  STORAGE: {
    INTERNAL: '/internal/storage',
    INTERNAL_ENTITY: '/internal/storage/entity',
    INTERNAL_SHARE_INTERNALLY: '/internal/storage/share/internal',
  },
};

/**
 *
 * NOTE: if you get an error here that might be due to missing cases.
 * Check whether you have added all the ENDPOINTS to cases in below switch or not
 *
 */
const getURLPath = (endpoint: EndpointParams): string => {
  switch (endpoint.endpoint) {
    // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //

    // AUTH
    case ENDPOINTS.AUTH_REFRESH_TOKEN:
      return `${BASE_URLS.AUTH}/token`;

    case ENDPOINTS.AUTH_LOGIN:
      return `${BASE_URLS.AUTH}/login`;

    case ENDPOINTS.AUTH_PROFILE_IMAGE_UPLOAD:
      return `${BASE_URLS.AUTH}/upload/profile-image`;

    case ENDPOINTS.AUTH_LOGIN_IMPERSONATE:
      return `${BASE_URLS.AUTH}/impersonate/login`;

    case ENDPOINTS.AUTH_CHECK_USER:
      return `${BASE_URLS.AUTH}/check`;

    case ENDPOINTS.AUTH_LOGOUT:
      return `${BASE_URLS.AUTH}/logout`;

    case ENDPOINTS.AUTH_FORGOT_PASSWORD:
      return `${BASE_URLS.AUTH}/password/forgot`;

    case ENDPOINTS.AUTH_VALIDATE_TOKEN:
      return `${BASE_URLS.AUTH}/token/validate`;

    case ENDPOINTS.AUTH_RESET_PASSWORD:
      return `${BASE_URLS.AUTH}/password/reset`;

    case ENDPOINTS.AUTH_REGISTER:
      return `${BASE_URLS.AUTH}/register`;

    case ENDPOINTS.AUTH_VERIFY:
      return `${BASE_URLS.AUTH}/verify`;

    case ENDPOINTS.AUTH_PROFILE:
      return `${BASE_URLS.AUTH}/profile`;

    case ENDPOINTS.AUTH_SEND_OTP:
      return `${BASE_URLS.AUTH}/otp/send`;

    case ENDPOINTS.AUTH_USERNAME:
      return `${BASE_URLS.AUTH}/username`;

    case ENDPOINTS.AUTH_CODE:
      return `${BASE_URLS.AUTH}/code`;

    // CONNECTED - APPS
    case ENDPOINTS.CONNECTED_APPS_CONNECT_NEW_APPS:
    case ENDPOINTS.CONNECTED_APPS_GET_ALL_APPS:
      return BASE_URLS.CONNECTED_APPS;

    case ENDPOINTS.CONNECTED_APPS_GET_ONE_APP:
    case ENDPOINTS.CONNECTED_APPS_UPDATE_ONE_APP:
    case ENDPOINTS.CONNECTED_APPS_DELETE_ONE_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_FIELDS_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/fields`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_ROLES_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/roles`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_PERMISSIONS_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/permissions`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_DOCUMENTATIONS_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/docs`;

    case ENDPOINTS.CONNECTED_APPS_CONNECT_OR_DISCONNECT_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/${endpoint.params.status_change}`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_REFERENCES_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/reference-categories/${endpoint.params.categoryId}/references`;

    case ENDPOINTS.CONNECTED_APPS_HANDSHAKE:
      return `${BASE_URLS.CONNECTED_APPS}/handshake`;

    case ENDPOINTS.CONNECTED_APPS_GENERATE_SECRETS:
      return `${BASE_URLS.CONNECTED_APPS}/generate-secrets`;

    case ENDPOINTS.CONNECTED_APPS_GENERATE_PUBLIC_API_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/generate`;

    case ENDPOINTS.CONNECTED_APPS_GENERATE_PUBLIC_CLIENT_CREDENTIALS:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/generate-client-credentials`;

    case ENDPOINTS.CONNECTED_APPS_SAVE_DELETED_CLIENT_SECRET:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/save-deleted-client-secret`;

    case ENDPOINTS.CONNECTED_APPS_SAVE_DELETED_PUBLIC_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/save-deleted-api-key`;

    case ENDPOINTS.CONNECTED_APPS_SAVE_GENERATED_PUBLIC_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys`;

    case ENDPOINTS.CONNECTED_APPS_UPDATE_GENERATED_PUBLIC_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_GET_SPECIFIC_GENERATED_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_GENERATED_KEYS:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys`;

    case ENDPOINTS.CONNECTED_APPS_DELETE_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_RESTORE_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}/restore`;

    //CONNECTIONS
    case ENDPOINTS.CONNECTIONS_GET_ALL:
      return BASE_URLS.CONNECTIONS;

    case ENDPOINTS.CONNECTIONS_UPDATE:
      return `${BASE_URLS.CONNECTIONS}/${endpoint.params.id}`;

    // IDENTITIES
    case ENDPOINTS.IDENTITIES_SUBORDINATES:
      return `${BASE_URLS.IDENTITIES}/subordinates`;

    case ENDPOINTS.IDENTITIES_SUBORDINATE_IDS:
      return `${BASE_URLS.IDENTITIES}/subordinate-ids`;

    case ENDPOINTS.IDENTITIES_HIERARCHICAL:
      return `${BASE_URLS.IDENTITIES}/hierarchy`;

    case ENDPOINTS.IDENTITIES_GET_INTERNAL_FIELD:
      return `${BASE_URLS.IDENTITIES}/get-field-data`;

    case ENDPOINTS.IDENTITIES_GET_ONE:
      return `${BASE_URLS.IDENTITIES}/${endpoint.params.id}`;

    // INTEGRATION - ENDPOINTS

    case ENDPOINTS.INTEGRATION_ENDPOINTS_GET_ALL:
    case ENDPOINTS.INTEGRATION_ENDPOINTS_ADD_ONE:
      return BASE_URLS.INTEGRATIONS.ENDPOINTS;
    case ENDPOINTS.INTEGRATION_ENDPOINTS_UPDATE_ONE:
    case ENDPOINTS.INTEGRATION_ENDPOINTS_DELETE_ONE:
      return `${BASE_URLS.INTEGRATIONS.ENDPOINTS}/${endpoint.params.id}`;
    case ENDPOINTS.INTEGRATION_ENDPOINTS_RESTORE_ONE:
      return `${BASE_URLS.INTEGRATIONS.ENDPOINTS}/${endpoint.params.id}/restore`;
    case ENDPOINTS.INTEGRATION_ENDPOINTS_VERIFY_ONE:
      return `${BASE_URLS.INTEGRATIONS.ENDPOINTS}/${endpoint.params.id}/verify`;

    // INTEGRATION -MAPPINGS
    case ENDPOINTS.INTEGRATION_MAPPINGS_ADD_ONE:
    case ENDPOINTS.INTEGRATION_MAPPINGS_GET_ALL:
      return BASE_URLS.INTEGRATIONS.MAPPING;
    case ENDPOINTS.INTEGRATION_MAPPINGS_UPDATE_ONE:
    case ENDPOINTS.INTEGRATION_MAPPINGS_DELETE_ONE:
      return `${BASE_URLS.INTEGRATIONS.MAPPING}/${endpoint.params.id}`;
    case ENDPOINTS.INTEGRATION_MAPPINGS_RESTORE_ONE:
      return `${BASE_URLS.INTEGRATIONS.MAPPING}/${endpoint.params.id}/restore`;
    case ENDPOINTS.INTEGRATION_MAPPINGS_VERIFY_ONE:
      return `${BASE_URLS.INTEGRATIONS.MAPPING}/${endpoint.params.id}/verify`;

    // JOB_QUEUE
    case ENDPOINTS.JOB_QUEUE_CHECK_STATUS:
      return `${BASE_URLS.JOB_QUEUE}/${endpoint.params.id}`;

    // MODULES
    case ENDPOINTS.MODULES_GET_ALL:
      return BASE_URLS.MODULES;

    case ENDPOINTS.MODULES_GET_ONE_BY_ID:
    case ENDPOINTS.MODULES_UPDATE:
      return `${BASE_URLS.MODULES}/${endpoint.params.id}`;

    case ENDPOINTS.MODULES_RESET:
      return `${BASE_URLS.MODULES}/${endpoint.params.id}/reset`;

    // PERSONALIZATION
    case ENDPOINTS.PERSONALIZATION_GET:
    case ENDPOINTS.PERSONALIZATION_UPDATE:
      return BASE_URLS.PERSONALIZATION;

    // NOTIFICATION
    case ENDPOINTS.NOTIFICATION_CERATE_WITHOUT_USER:
      return BASE_URLS.NOTIFICATION;

    case ENDPOINTS.NOTIFICATION_CREATE_MULTIPLE_NOTIFICATIONS_WITH_USERS:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}/user-notifications`;

    case ENDPOINTS.NOTIFICATION_CERATE_WITH_USER:
      return `${BASE_URLS.NOTIFICATION}/user-notifications`;

    case ENDPOINTS.NOTIFICATION_GET:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}`;

    case ENDPOINTS.NOTIFICATION_GET_USER_NOTIFICATIONS:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}/user-notifications`;

    case ENDPOINTS.NOTIFICATION_FILTER:
      return BASE_URLS.NOTIFICATION;

    case ENDPOINTS.NOTIFICATIONS_MARK_AS_READ:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}/read`;

    case ENDPOINTS.NOTIFICATIONS_MARK_ALL_AS_READ:
      return `${BASE_URLS.NOTIFICATION}/mark-all-read`;

    // CONFIGURABLE_FIELDS - VALIDATIONS
    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_NAME:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/name`;

    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_PHONE_NUMBER:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/phone-number`;

    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_ADDRESS:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/address`;

    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_EMAIL:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/email`;

    // REFERENCES - PREDEFINED_REFERENCES
    case ENDPOINTS.PREDEFINED_REFERENCES_GET_VALUE:
      return `${BASE_URLS.REFERENCES.REFERENCES}/predefined-value`;

    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.REFERENCE_CATEGORIES_GET_ALL:
    case ENDPOINTS.REFERENCE_CATEGORIES_ADD_NEW:
      return BASE_URLS.REFERENCES.REFERENCE_CATEGORIES;

    case ENDPOINTS.REFERENCE_CATEGORIES_GET_SINGLE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_GET_EXTERNAL:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/external/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_GET_EXTERNAL_DELETE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/external/delete/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_UPDATE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_DELETE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/delete`;

    case ENDPOINTS.REFERENCE_CATEGORIES_RESTORE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/restore`;

    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_ADD_NEW:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_GET_ALL_V2:
      return `/v2${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;
    // REFERENCES - REFERENCES
    case ENDPOINTS.REFERENCE_EDIT:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.categoryId}/references/${endpoint.params.referenceId}`;

    case ENDPOINTS.REFERENCE_DELETE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.categoryId}/references/${endpoint.params.referenceId}/delete`;

    case ENDPOINTS.REFERENCE_RESTORE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.categoryId}/references/${endpoint.params.referenceId}/restore`;

    case ENDPOINTS.REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;

    // REFERENCES - BULK ADD
    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_BULK_ADD:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/references/bulk`;

    // REFERENCES - TEMPLATES
    case ENDPOINTS.TEMPLATES_ADD_NEW:
      return BASE_URLS.REFERENCES.TEMPLATES;

    case ENDPOINTS.TEMPLATES_UPDATE_ONE:
    case ENDPOINTS.TEMPLATES_GET_ONE:
      return `${BASE_URLS.REFERENCES.TEMPLATES}/${endpoint.params.id}`;

    // REFERENCES - FORMULA
    case ENDPOINTS.FORMULA_ANALYZE:
      return `${BASE_URLS.REFERENCES.FORMULA}/analyze`;

    case ENDPOINTS.FORMULA_EVALUATE:
      return `${BASE_URLS.REFERENCES.FORMULA}/evaluate`;

    // STORAGE - INTERNAL
    case ENDPOINTS.STORAGE_INTERNAL_UPLOAD:
      return `${BASE_URLS.STORAGE.INTERNAL}/upload`;

    case ENDPOINTS.STORAGE_INTERNAL_DELETE:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}`;

    case ENDPOINTS.STORAGE_INTERNAL_PREVIEW:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/preview`;

    case ENDPOINTS.STORAGE_INTERNAL_DOWNLOAD:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/download`;

    case ENDPOINTS.STORAGE_INTERNAL_SHARE_INTERNALLY:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/share/internal`;

    case ENDPOINTS.STORAGE_INTERNAL_CONTENT:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/content`;

    // SETUP - REFERENCE_MAPPING
    case ENDPOINTS.SETUP_GET_ALL_REFERENCE_MAPPINGS:
      return BASE_URLS.SETUP.REFERENCE_MAPPINGS;

    case ENDPOINTS.SETUP_GET_REFERENCE_MAPPING_BY_KEY:
      return `${BASE_URLS.SETUP.REFERENCE_MAPPINGS}/${endpoint.params.key}`;

    case ENDPOINTS.SETUP_UPDATE_REFERENCE_MAPPING:
      return `${BASE_URLS.SETUP.REFERENCE_MAPPINGS}/${endpoint.params.id}`;

    // SETUP - BACKUP_SCHEDULES
    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_ADD_NEW:
      return BASE_URLS.SETUP.BACKUP_SCHEDULES;

    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_GET_ALL:
      return BASE_URLS.SETUP.BACKUP_SCHEDULES;

    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_DELETE_ONE:
      return `${BASE_URLS.SETUP.BACKUP_SCHEDULES}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_ENABLE_DISABLE_ONE:
      return `${BASE_URLS.SETUP.BACKUP_SCHEDULES}/${endpoint.params.id}/${endpoint.params.status}`;

    // SETUP - IAM
    case ENDPOINTS.SETUP_IAM_AUTHORIZERS_AZURE:
      return `${BASE_URLS.SETUP.AUTHORIZERS}/azure`;

    case ENDPOINTS.SETUP_IAM_HANDSHAKE:
      return `${BASE_URLS.SETUP.IAM_HANDSHAKE}`;

    // SETUP - BACKUPS
    case ENDPOINTS.SETUP_BACKUPS_BACKUP_NOW:
    case ENDPOINTS.SETUP_BACKUPS_GET_ALL:
      return BASE_URLS.SETUP.BACKUPS;

    case ENDPOINTS.SETUP_BACKUPS_DOWNLOAD_ONE:
    case ENDPOINTS.SETUP_BACKUPS_DELETE_ONE:
      return `${BASE_URLS.SETUP.BACKUPS}/${endpoint.params.name}`;

    // SETUP - LANGUAGES
    case ENDPOINTS.SETUP_LANGUAGES_ADD_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_GET_ALL:
      return BASE_URLS.SETUP.LANGUAGES;

    case ENDPOINTS.SETUP_LANGUAGES_GET_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_UPDATE_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_ENABLE_DISABLE_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_DELETE_ONE:
      return `${BASE_URLS.SETUP.LANGUAGES}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_LANGUAGES_REFRESH_ONE:
      return `${BASE_URLS.SETUP.LANGUAGES}/${endpoint.params.id}/refresh`;

    case ENDPOINTS.SETUP_LANGUAGES_GET_CONTENT_OF_ONE:
      return `${BASE_URLS.SETUP.LANGUAGES}/${endpoint.params.id}/content`;

    // SETUP - CURRENCIES
    case ENDPOINTS.SETUP_CURRENCIES_ADD_ONE:
    case ENDPOINTS.SETUP_CURRENCIES_GET_ALL:
      return BASE_URLS.SETUP.CURRENCIES;

    case ENDPOINTS.SETUP_CURRENCIES_GET_ONE:
    case ENDPOINTS.SETUP_CURRENCIES_UPDATE_ONE:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_CURRENCIES_DELETE_ONE:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}/delete`;

    case ENDPOINTS.SETUP_CURRENCIES_RESTORE_ONE:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}/restore`;

    case ENDPOINTS.SETUP_CURRENCIES_TOGGLE_BASE_CURRENCY:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}/base_currency`;

    // ASSETS
    case ENDPOINTS.ASSETS_IMAGES_UPLOAD:
      return BASE_URLS.ASSETS.IMAGES;

    case ENDPOINTS.ASSETS_IMAGES_DELETE:
      return `${BASE_URLS.ASSETS.IMAGES}/${endpoint.params.name}`;

    // SETUP - EXTERNAL SYSTEMS
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_GET_ALL:
      return BASE_URLS.SETUP.EXTERNAL_SYSTEMS;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEM_GET_EXTERNAL_REFERENCE_CATEGORIES:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS}/${endpoint.params.id}/reference-categories`;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_UPDATE_ONE:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_SAVE_KEY:
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ALL:
      return BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GENERATE_NEW_KEY:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS}/generate`;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ONE:
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_DELETE_ONE:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS}/${endpoint.params.id}`;
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_RESTORE_ONE:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS}/${endpoint.params.id}/restore`;

    // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //

    // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //
    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.CLIENT_REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
      return `${BASE_URLS.REFERENCES.CLIENT_REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    // REFERENCES - REFERENCES
    case ENDPOINTS.CLIENT_REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.CLIENT_REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;
    // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //

    // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //
    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.EXTERNAL_REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
      return `${BASE_URLS.REFERENCES.EXTERNAL_REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    // REFERENCES - REFERENCES
    case ENDPOINTS.EXTERNAL_REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.EXTERNAL_REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;
    // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //

    // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //
    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.IAM_REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
      return `${BASE_URLS.REFERENCES.IAM_REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    // REFERENCES - REFERENCES
    case ENDPOINTS.IAM_REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.IAM_REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;
    // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //
  }
};

export const generateURL = (endpoint: EndpointParams): string => {
  const path = getURLPath(endpoint);
  const apiVersion = endpoint.version ? `/v${endpoint.version}` : '';

  return `/api${apiVersion}${path}`;
};
