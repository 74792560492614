import { FORMULA_ELEMENT_TYPES, OPERATOR_TYPES } from '@shared/constants';
import { IFormulaOperatorElement } from '@shared/interfaces';

export const bracketOperators: IFormulaOperatorElement[] = [
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.BASIC,
      displayText: 'Open Bracket',
      symbol: '(',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.BASIC,
      displayText: 'Close Bracket',
      symbol: ')',
    },
  },
];
