import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput } from 'ng-keyboard-shortcuts';

import { IBaseEntity } from '@shared/interfaces';

import { DIALOG_SIZES } from '../../constants';
import { DateTimeFormatService } from '../../services/date-time-format.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../services/snackbar.service';

import { ArchivedListComponent } from './popups/archived-list/archived-list.component';
import { CreateNoteComponent } from './popups/create-note/create-note.component';
import {
  IDraggedNoteIndexChangeDTO,
  PostItNotesService,
} from './services/post-it-notes.service';
export interface INotes extends IBaseEntity {
  _id?: string;
  title: string;
  start_date: string;
  end_date?: Date;
  background_color?: string;
  text_color?: string;
  description: string;
  selected?: boolean;
  index_number: number;
  collapsed?: boolean;
  loading?: boolean;
}

@Component({
  selector: 'app-post-it-notes',
  templateUrl: './post-it-notes.component.html',
  styleUrls: ['./post-it-notes.component.scss'],
})
export class PostItNotesComponent implements OnInit {
  isLoading = false;

  notes: INotes[] = [];
  archivedNotes: INotes[] = [];
  shortcuts: ShortcutInput[] = [];

  archiveIconLoading = false;

  constructor(
    private dialog: MatDialog,
    private postItNotesService: PostItNotesService,
    private snackBar: SnackbarService,
    private translate: TranslateService,
    private dateTimeFormatService: DateTimeFormatService
  ) {}

  ngOnInit(): void {
    this.getNotes();
  }

  drop(
    event: CdkDragDrop<
      { title: string; start_date: string; description: string }[]
    >
  ) {
    if (!event.isPointerOverContainer) {
      // if a post-it note is dropped outside of the container
      const archivingNote = this.notes[event.previousIndex];
      archivingNote.loading = true;

      this.postItNotesService.archiveNote(archivingNote._id).subscribe({
        next: () => {
          archivingNote.loading = false;
          this.notes.splice(event.previousIndex, 1);

          this.snackBar.success(
            SUCCESS_TYPES.ARCHIVED,
            this.translate.instant('pages.post-it-notes.note')
          );
        },
        error: () => {
          archivingNote.loading = false;
          this.snackBar.error(
            this.translate.instant(
              'pages.post-it-notes.root.archive-error-snackbar'
            )
          );
        },
      });
    } else if (this.notes.length > 1) {
      // if a selected post-it note's order changes
      moveItemInArray(this.notes, event.previousIndex, event.currentIndex);

      const data: IDraggedNoteIndexChangeDTO = {
        current_note_id: this.notes[event.currentIndex]._id,
        above_note_id: this.notes[event.currentIndex - 1]
          ? this.notes[event.currentIndex - 1]._id
          : undefined,
        below_note_id: this.notes[event.currentIndex + 1]
          ? this.notes[event.currentIndex + 1]._id
          : undefined,
      };

      this.postItNotesService.changeNotePosition(data).subscribe();
    }
  }

  getNotes() {
    this.isLoading = true;
    this.postItNotesService.getAllNotes().subscribe({
      next: (res) => {
        this.notes = [];
        res.data.forEach((note) => {
          this.notes.push({
            _id: note._id,
            title: note.title,
            start_date: this.dateTimeFormatService.formatShortenedDate(
              note.start_date
            ),
            end_date: note.end_date,
            background_color: note.background_color,
            text_color: note.text_color,
            description: note.description,
            index_number: note.index_number,
            collapsed: true,
          });
        });
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.notes = [];
      },
    });
  }

  onClickAddNote() {
    this.getNotes();

    const dialog = this.dialog.open(CreateNoteComponent, {
      ...DIALOG_SIZES.MEDIUM,
    });

    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.postItNotesService.getSingleNote(data).subscribe({
          next: (res) => {
            const noteData = {
              ...res.data,
              start_date: this.dateTimeFormatService.formatShortenedDate(
                res.data.start_date
              ),
            };
            this.notes.unshift(noteData);
          },
          error: () => {
            this.snackBar.error(
              this.translate.instant(
                'pages.post-it-notes.root.created-note-not-found-snackbar'
              )
            );
          },
        });
      }
    });
  }

  onClickArchive() {
    this.getArchivedList();
  }

  getArchivedList() {
    this.archiveIconLoading = true;

    this.postItNotesService.getAllArchivedNotes().subscribe({
      next: (res) => {
        this.archivedNotes = [];
        res.data.forEach((note) => {
          this.archivedNotes.push({
            _id: note._id,
            title: note.title,
            start_date: this.dateTimeFormatService.formatShortenedDate(
              note.start_date
            ),
            end_date: note.end_date,
            background_color: note.background_color,
            text_color: note.text_color,
            description: note.description,
            selected: false,
            index_number: note.index_number,
            collapsed: true,
          });
        });

        const dialog = this.dialog.open(ArchivedListComponent, {
          width: '90%',
          minWidth: '330px',
          maxWidth: '500px',
          panelClass: 'create-note-dialog-container',
          data: this.archivedNotes,
        });

        dialog.afterClosed().subscribe(() => {
          this.archiveIconLoading = false;

          this.getNotes();
        });
      },
      error: () => {
        this.archiveIconLoading = false;
      },
    });
  }

  panelExpanded(e: boolean, index: number) {
    if (e) {
      this.notes[index].collapsed = false;
    } else {
      this.notes[index].collapsed = true;
    }
  }
}
