import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { SideMenuService } from '../../../../../../pages/dashboard/side-menu/services/side-menu.service';
import {
  IAM_STATUS,
  IamConfigService,
} from '../../../../../setup/iam-config/services/iam-config.service';
import {
  DMS_STATUS,
  StorageSetupService,
} from '../../../../../setup/storage/services/storage-setup.service';

interface KeyboardShortcutItem {
  description: string;
  firstKey?: string;
  secondKey: string;
}

interface KBListItem {
  description: string;
  secondKey: string;
}

@Component({
  selector: 'app-keyboard-shortcuts-list',
  templateUrl: './keyboard-shortcuts-list.component.html',
  styleUrls: ['./keyboard-shortcuts-list.component.scss'],
})
export class KeyboardShortcutsListComponent implements OnInit, OnDestroy {
  kbItems: KBListItem[] = [];
  public basicKeyboardShortcutsList: Array<KeyboardShortcutItem>;
  public navigationKeyboardShortcutsList: Array<KeyboardShortcutItem>;
  public sideMenuKeyboardShortcutsList: Array<KBListItem>;
  private subscriptions = new Subscription();

  isInternalIAM = true;
  isInternalStorage = true;

  constructor(
    private translate: TranslateService,
    private sideMenuService: SideMenuService,
    private storageConfigService: StorageSetupService,
    private iamConfigService: IamConfigService
  ) {}

  ngOnInit(): void {
    this.initializeIamConfig();
    this.initializeConnectedStorageConfig();
    this.addKBItems();

    this.basicKeyboardShortcutsList = [
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.toggle-dark-mode'
        ),
        firstKey: 'Alt/ ⌥',
        secondKey: 'D',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.duplicate-in-new-tab'
        ),
        firstKey: 'Alt/ ⌥',
        secondKey: 'N',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.toggle-full-screen'
        ),
        firstKey: 'Alt/ ⌥',
        secondKey: 'F',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.add-a-post-it-note'
        ),
        firstKey: 'Alt/ ⌥',
        secondKey: 'A',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.toggle-side-menu'
        ),
        firstKey: 'Alt/ ⌥',
        secondKey: 'S',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.expand-collapse-speed-dial'
        ),
        firstKey: 'Alt/ ⌥',
        secondKey: 'E',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.go-to-profile'
        ),
        firstKey: 'Alt/ ⌥',
        secondKey: 'P',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.focus-side-menu-filter'
        ),
        firstKey: 'Alt/ ⌥',
        secondKey: 'M',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.focus-side-quick-actions'
        ),
        firstKey: 'Alt/ ⌥',
        secondKey: 'Q',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.log-out'
        ),
        firstKey: 'Alt/ ⌥',
        secondKey: 'L',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.pin-as-quick-action'
        ),
        firstKey: 'Alt/ ⌥',
        secondKey: 'X',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.basic.close-a-popup-window'
        ),
        secondKey: 'Esc',
      },
    ];

    this.navigationKeyboardShortcutsList = [
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.navigation.select-next-quick-action'
        ),
        secondKey: 'Tab',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.navigation.select-previous-quick-action'
        ),
        firstKey: 'Shift',
        secondKey: 'Tab',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.navigation.select-next-form-field'
        ),
        secondKey: 'Tab',
      },
      {
        description: this.translate.instant(
          'core.keyboard-shortcuts.navigation.select-previous-form-field'
        ),
        firstKey: 'Shift',
        secondKey: 'Tab',
      },
    ];

    this.sideMenuKeyboardShortcutsList = this.kbItems;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addKBItems() {
    this.sideMenuService.getVisibleItems().forEach((menuItem) => {
      if (menuItem.key === 'MODULES') {
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.modules'
          ),
          secondKey: 'M',
        });
      }
      if (menuItem.key === 'APPS' && this.isInternalIAM) {
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.apps'
          ),
          secondKey: 'A',
        });
      }
      if (menuItem.key === 'AUTHORIZATION') {
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.permissions'
          ),
          secondKey: 'P',
        });
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.roles'
          ),
          secondKey: 'R',
        });
      }
      if (menuItem.key === 'IDENTITIES' && this.isInternalIAM) {
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.identities'
          ),
          secondKey: 'I',
        });
      }
      if (menuItem.key === 'CONNECTIONS' && this.isInternalIAM) {
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.connections'
          ),
          secondKey: 'C',
        });
      }
      if (menuItem.key === 'MESSAGES') {
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.messages'
          ),
          secondKey: 'E',
        });
      }
      if (menuItem.key === 'STORAGE' && this.isInternalStorage) {
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.drives'
          ),
          secondKey: 'D',
        });
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.trash'
          ),
          secondKey: 'Y',
        });
      }
      if (menuItem.key === 'NOTICES') {
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.notices'
          ),
          secondKey: 'N',
        });
      }
      if (menuItem.key === 'KNOWLEDGEBASE') {
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.knowledgebase'
          ),
          secondKey: 'K',
        });
      }
      if (menuItem.key === 'LOGS') {
        this.kbItems.push({
          description: this.translate.instant(
            'core.keyboard-shortcuts.side-menu.logs'
          ),
          secondKey: 'L',
        });
      }
    });
  }

  private initializeIamConfig(): void {
    this.subscriptions.add(
      this.iamConfigService.dataStore.subscribe((data) => {
        const iamStatus = data?.iam?.status;
        if (
          iamStatus === IAM_STATUS.INTERNAL ||
          iamStatus === IAM_STATUS.INTERNAL_DISCONNECTED ||
          iamStatus === IAM_STATUS.INTERNAL_PENDING
        ) {
          this.isInternalIAM = true;
        } else {
          this.isInternalIAM = false;
        }
      })
    );
  }

  private initializeConnectedStorageConfig(): void {
    this.subscriptions.add(
      this.storageConfigService.dataStore.subscribe((data) => {
        const storageStatus = data?.storage?.status;
        if (
          storageStatus === DMS_STATUS.INTERNAL ||
          storageStatus === DMS_STATUS.INTERNAL_DISCONNECTED ||
          storageStatus === DMS_STATUS.INTERNAL_PENDING ||
          storageStatus === DMS_STATUS.EXTERNAL_PENDING
        ) {
          this.isInternalStorage = true;
        } else {
          this.isInternalStorage = false;
        }
      })
    );
  }
}
