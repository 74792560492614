import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';
import { generateURL } from '@shared/utils';

export interface TemplateRequestDTO {
  name: string;
  html: string;
  merge_tags?: object;
  design: object;
}

export interface TemplateResponseDTO extends TemplateRequestDTO {
  _id: string;
}

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private dataStore = new BehaviorSubject<{
    [key: string]: TemplateResponseDTO;
  }>({});

  constructor(private http: HttpClient) {}

  public getTemplateById(templateID: string): Promise<TemplateResponseDTO> {
    return new Promise((resolve, reject) => {
      const data = this.dataStore.value;

      if (data[templateID]) {
        resolve(data[templateID]);
      } else {
        const url = generateURL({
          endpoint: ENDPOINTS.TEMPLATES_GET_ONE,
          params: { id: templateID },
        });

        firstValueFrom(
          this.http.get<CommonResponseDTO<TemplateResponseDTO>>(url)
        )
          .then((data: CommonResponseDTO<TemplateResponseDTO>) =>
            resolve(data.data)
          )
          .catch(reject);
      }
    });
  }

  public saveNewTemplate(
    template: TemplateRequestDTO
  ): Observable<CommonResponseDTO<TemplateResponseDTO>> {
    const url = generateURL({ endpoint: ENDPOINTS.TEMPLATES_ADD_NEW });

    return this.http.post<CommonResponseDTO<TemplateResponseDTO>>(
      url,
      template
    );
  }
}
