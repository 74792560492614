<mat-expansion-panel
  *ngIf="!isEmptyDataFields"
  [expanded]="!loading && isSuccess"
  [disabled]="!isSuccess"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h6 class="d-flex align-items-center mb-0">
        <div class="avatar primary-bg">
          <img [src]="ImageUrl" width="100%" alt="logo-light" />
        </div>

        <strong class="ml-2">{{ data?.app?.general?.name || '' }}</strong>
      </h6>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-divider></mat-divider>
  <div class="mt-4">
    <form>
      <div *ngIf="internal_fields_display?.length > 0">
        <app-display-configurable-field
          class="w-100"
          [fieldArray]="internal_fields_display"
        ></app-display-configurable-field>
      </div>
    </form>
  </div>
  <div class="d-flex justify-content-end p-2 pt-3">
    <button
      [disabled]="isInternalFieldsInValid || !isInternalFieldsChanged"
      mat-raised-button
      color="accent"
      type="submit"
      (click)="onSaveDataFields()"
    >
      {{ 'pages.profile.save' | translate }}
    </button>
  </div>
</mat-expansion-panel>
