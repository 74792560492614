import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, tap } from 'rxjs/operators';
import { BehaviorSubject, interval, Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO, IHealthResponse } from '@shared/interfaces';

import { environment } from '../../../../../environments/environment';

export interface ReleaseResponseDTO {
  _id?: string;
  created_on: Date;
  title: string;
  version_no: string;
  change_log?: string;
  is_deleted: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ReleaseService {
  public readonly releases = new BehaviorSubject<ReleaseResponseDTO[]>(null);

  private readonly HEARTBEAT_INTERVAL = 60 * 1000 * 5; // 5 minute

  constructor(private http: HttpClient) {}

  public hasNewVersion(releases: ReleaseResponseDTO[]): boolean {
    if (!releases || releases.length === 0) return false;

    const _oldVersion = environment.VERSION;
    const _newVersion = releases[0].version_no.toLowerCase().includes('v')
      ? releases[0].version_no.substring(1)
      : releases[0].version_no;

    const oldVersionParts = _oldVersion.split('.').map((part) => Number(part));
    const newVersionParts = _newVersion.split('.').map((part) => Number(part));

    return (
      oldVersionParts.length === 3 &&
      newVersionParts.length === 3 &&
      (newVersionParts[0] > oldVersionParts[0] ||
        (newVersionParts[0] === oldVersionParts[0] &&
          newVersionParts[1] > oldVersionParts[1]) ||
        (newVersionParts[0] === oldVersionParts[0] &&
          newVersionParts[1] === oldVersionParts[1] &&
          newVersionParts[2] > oldVersionParts[2]))
    );
  }

  addNewRelease(
    releaseData: ReleaseResponseDTO
  ): Observable<CommonResponseDTO<ReleaseResponseDTO>> {
    return this.http
      .post<CommonResponseDTO<ReleaseResponseDTO>>(URLS.RELEASES, releaseData)
      .pipe(
        tap((res) => {
          const releases = [...this.releases.value];
          releases.push(res.data);
          const sortedArray = releases.sort((n1, n2) => {
            if (n1.created_on < n2.created_on) {
              return 1;
            } else {
              return -1;
            }
          });
          this.releases.next(sortedArray);
        })
      );
  }

  getAllReleases(): Observable<CommonResponseDTO<ReleaseResponseDTO[]>> {
    return this.http
      .get<CommonResponseDTO<ReleaseResponseDTO[]>>(URLS.RELEASES)
      .pipe(
        tap((res) => {
          this.releases.next(res.data);
        })
      );
  }

  getLatestRelease(): Observable<CommonResponseDTO<ReleaseResponseDTO>> {
    return this.http
      .get<CommonResponseDTO<ReleaseResponseDTO>>(URLS.RELEASE_LATEST_RELEASE)
      .pipe(
        tap((res) => {
          this.releases.next(res.data ? [res.data] : []);
        })
      );
  }

  getLatestRelease1() {
    this.http
      .get<CommonResponseDTO<ReleaseResponseDTO>>(URLS.RELEASE_LATEST_RELEASE)
      .subscribe((res) => {
        this.releases.next(res.data ? [res.data] : []);
      });
  }

  getSingleRelease(
    releaseID: string
  ): Observable<CommonResponseDTO<ReleaseResponseDTO>> {
    const url = `${URLS.RELEASES}/${releaseID}`;
    return this.http.get<CommonResponseDTO<ReleaseResponseDTO>>(url);
  }

  deleteRelease(
    releaseID: string
  ): Observable<CommonResponseDTO<ReleaseResponseDTO>> {
    const url = `${URLS.RELEASES}/${releaseID}`;
    return this.http.delete<CommonResponseDTO<ReleaseResponseDTO>>(url);
  }

  public startReleaseCheck(endpoint = '/api'): Observable<IHealthResponse> {
    return interval(this.HEARTBEAT_INTERVAL).pipe(
      mergeMap(() => this.http.get<IHealthResponse>(endpoint))
    );
  }
}
