import { Pipe, PipeTransform } from '@angular/core';

import { formatBytes } from '@shared/utils';

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  transform(bytes: number): any {
    return formatBytes(bytes);
  }
}
