import { Component, Input, OnInit } from '@angular/core';

import { ProgressBarProperties } from '../../types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dlt-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() properties: ProgressBarProperties = {} as ProgressBarProperties;

  linear = {
    depth: 22,
    fontSize: 12,
  };
  radial = {
    depth: 1.2,
    size: 2.5,
  };

  ngOnInit(): void {
    if (
      !this.properties.progress ||
      typeof this.properties.progress !== 'number' ||
      this.properties.progress < 0
    ) {
      this.properties.progress = 0;
    }
    if (this.properties.progress > 100) {
      this.properties.progress = 100;
    }
  }
}
