export enum MESSAGE_STATUS {
  SENDER_REPLIED = 'SENDER_REPLIED', // Sender create or reply
  RECEIVER_REPLIED = 'RECEIVER_REPLIED',
  RECEIVER_HOLD = 'RECEIVER_HOLD',
  SENDER_HOLD = 'SENDER_HOLD',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED',
}

export enum MESSAGE_RESPONSE_TYPES {
  REPLY = 'REPLY', //'Reply',
  ANSWER = 'ANSWER', //'Answer',
  CLOSE = 'CLOSE', //'Close',
  PUT_ON_HOLD = 'PUT_ON_HOLD', //'Put On Hold',
  ASSIGN_ANOTHER_AGENT = 'ASSIGN_ANOTHER_AGENT', //'Assign Another Agent',
}

export enum MESSAGE_PRIORITY {
  LOW = 'LOW', //'Low',
  MEDIUM = 'MEDIUM', //'Medium',
  HIGH = 'HIGH', //'High',
}

export enum AUTO_CLOSE_UNITS {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export enum TO_RULE_TYPE {
  TO_IDENTITY = 'TO_IDENTITY',
  TO_ROLE = 'TO_ROLE',
  TO_GROUP = 'TO_GROUP',
}

export enum RULE_CRITERIA {
  ALLOW = 'ALLOW',
  RESTRICT = 'RESTRICT',
}

export enum REPLY_ACTION_TYPES {
  REPLY = 'REPLY',
  CLOSE = 'CLOSE',
  PUT_ON_HOLD = 'PUT_ON_HOLD',
  ASSIGN_OTHER = 'ASSIGN_OTHER',
  RESOLVE = 'RESOLVE',
  RE_OPEN = 'RE_OPEN',
}
