import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { FILE_TYPES } from '@shared/constants';
import { generateFileTypeRegex } from '@shared/utils';

import { SnackbarService } from '../../../../services/snackbar.service';

interface UploadImageResponse {
  message: string;
  image: string;
}

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragAndDropComponent implements AfterViewInit {
  @Input() headers: HttpHeaders;
  @Input() displayIcon = false;
  @Input() displayImg = false;
  @Input() remainingFilesCount = 0;
  @Input() remainingFiles = false;
  @Input() showUploadedFiles = true;
  @Input() imgURLList: string[] = [];
  @Input() allowUpload = false;
  @Input() allowDelete = false;
  @Input() uploadDirectly = true;
  @Input() allowedTypesRegex = generateFileTypeRegex([FILE_TYPES.IMAGE]);
  @Input() allowedTypesNames = 'images';
  @Input() maxSize = 2; // mb
  @Input() title = 'Drag and drop a file or select one to upload';
  @Output() triggerFileSelected: EventEmitter<File> = new EventEmitter();
  @Output() triggerImageUploaded: EventEmitter<string> = new EventEmitter();
  @Output() triggerImageDelete: EventEmitter<string> = new EventEmitter();
  @ViewChild('container') container;

  dialogBoxWidth: number;
  displayedColumns = ['name', 'close'];
  loading = false;
  fileNames: string[] = [];

  constructor(private http: HttpClient, private snackBar: SnackbarService) {}

  ngAfterViewInit(): void {
    this.dialogBoxWidth = this.container.nativeElement.clientWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.container) {
      this.dialogBoxWidth = this.container.nativeElement.clientWidth;
    }
  }

  public fileProgress(fileInput: FileList) {
    if (fileInput.length === 0) {
      // ignore
    } else if (fileInput.length > this.remainingFilesCount) {
      this.snackBar.error('Maximum allowed files number exceeded');
    } else {
      let type_mismatch_count = 1;
      let size_exceeded_count = 1;
      for (let i = 0; i < fileInput.length; i++) {
        this.fileNames.push(fileInput[i].name);

        if (fileInput[i].type.match(this.allowedTypesRegex) == null) {
          this.snackBar.error(
            `Only ${
              this.allowedTypesNames
            } are supported. ${type_mismatch_count++} file(s) skipped.`
          );
        } else if (fileInput[i].size > this.maxSize * 1024 * 1024) {
          this.snackBar.error(
            'File size should be less than ' +
              this.maxSize +
              'MB. ' +
              size_exceeded_count++ +
              ' file(s) skipped.'
          );
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(fileInput[i]);
          reader.onload = () => {
            if (this.uploadDirectly) {
              this.onSubmit(fileInput[i]);
            } else {
              this.triggerFileSelected.emit(fileInput[i]);
            }
          };
        }
      }
    }
  }

  private onSubmit(file: File) {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', file);
    const url = '/api/images';
    this.http
      .post<UploadImageResponse>(url, formData, { headers: this.headers })
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.triggerImageUploaded.emit(data.image);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  deleteImage(image: string, index: number) {
    const image_paths = image.split('/');
    if (image_paths.length > 1) {
      this.triggerImageDelete.emit(image_paths[image_paths.length - 1]);
      this.fileNames.splice(index, 1);
    } else {
      this.snackBar.error('Invalid image path!');
    }
  }
}
