import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput } from 'ng-keyboard-shortcuts';

import { DEFAULT_QUICK_ACTIONS, RESPONSE_STATUSES } from '@shared/constants';
import { getNavigationRegex } from '@shared/utils';

import { environment } from '../../../environments/environment';
import { MAX_QUICK_ACTIONS } from '../../constants';
import {
  CONFIRMATION_TYPES,
  GetUserConfirmationService,
} from '../../modules/shared/services/get-user-confirmation/service';
import { PermissionFiltrationService } from '../../services/permission-filtration.service';
import {
  IDraggedQuickActionIndexChangeDTO,
  IQuickAction,
  ISQuickAction,
  QuickActionService,
} from '../../services/quick-action.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../services/snackbar.service';

import { AddQuickActionComponent } from './popups/add-quick-action/add-quick-action.component';

@Component({
  selector: 'app-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.scss'],
})
export class QuickActionsComponent implements OnInit, AfterViewInit {
  maxQuickActions = MAX_QUICK_ACTIONS;
  loading = false;
  screenWidth: number;
  quickActions: IQuickAction[] = [];
  systemQuickActions: ISQuickAction[] = [];
  shortcuts: ShortcutInput[] = [];

  skeletonNotes = [1, 2, 3];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private quickActionService: QuickActionService,
    private snackbar: SnackbarService,
    private getUserConfirmation: GetUserConfirmationService,
    private permissionFiltrationService: PermissionFiltrationService
  ) {}

  ngOnInit(): void {
    this.initDefaultQuickActions();
    this.getQuickActions();
    this.screenWidth = window.innerWidth;
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['alt + q'],
      label: 'Focus First Quick Action',
      description: 'Alt + Q',
      command: () => this.focusFirstQuickAction(),
      preventDefault: true,
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  drop(event: CdkDragDrop<{ title: string }[]>) {
    if (!event.isPointerOverContainer) {
      // if a quick action is dropped outside of the container
      this.quickActions[event.currentIndex].delete_loading_indicator = true;
      const dialogData = {
        description: this.translate.instant(
          'pages.quick-actions.delete-description'
        ),
      };
      this.getUserConfirmation
        .confirm(CONFIRMATION_TYPES.DELETE, dialogData)
        .then((result) => {
          if (result) {
            this.quickActionService
              .deleteQuickActions(this.quickActions[event.currentIndex]._id)
              .subscribe({
                next: (res) => {
                  if (res.statusCode === RESPONSE_STATUSES.SUCCESS) {
                    this.quickActions.splice(event.currentIndex, 1);
                    this.snackbar.success(
                      SUCCESS_TYPES.DELETED,
                      this.translate.instant(
                        'pages.quick-actions.create.quick-action'
                      )
                    );
                    this.quickActions[
                      event.currentIndex
                    ].delete_loading_indicator = false;
                  }
                },
              });
          } else {
            this.quickActions[event.currentIndex].delete_loading_indicator =
              false;
          }
        });
    } else if (this.quickActions.length > 1) {
      // if a selected quick action's order changes
      moveItemInArray(
        this.quickActions,
        event.previousIndex,
        event.currentIndex
      );

      const data: IDraggedQuickActionIndexChangeDTO = {
        current_quick_action_id: this.quickActions[event.currentIndex]._id,
        above_quick_action_id: this.quickActions[event.currentIndex - 1]
          ? this.quickActions[event.currentIndex - 1]._id
          : undefined,
        below_quick_action_id: this.quickActions[event.currentIndex + 1]
          ? this.quickActions[event.currentIndex + 1]._id
          : undefined,
      };

      this.quickActionService.changePosition(data).subscribe();
    }
  }

  getQuickActions() {
    this.loading = true;
    this.quickActionService.getAllQuickActions().subscribe((res) => {
      if (res) {
        this.loading = false;
        this.skeletonNotes = [];
        const slicedQuickActions = res.data.slice(0, 8);
        slicedQuickActions.forEach((item) => {
          this.quickActions.push({
            _id: item._id,
            title: item.title,
            link: decodeURIComponent(
              item.link.replace(getNavigationRegex(environment.WEB_DOMAIN), '')
            ),
            icon: item.icon,
            index_number: item.index_number,
          });
          this.quickActions.sort((a, b) => {
            return a.index_number - b.index_number;
          });
          this.skeletonNotes.push(1);
        });
      }
    });
  }

  onCreateQuickAction(): void {
    this.loading = true;

    const iconsArray = {
      icons: this.quickActions.map((quickAction) => ({
        icon: quickAction.icon,
      })),
    };
    const dialogRef = this.dialog.open(AddQuickActionComponent, {
      data: iconsArray,
      maxWidth: '600px',
      width: '90%',
    });
    dialogRef.afterClosed().subscribe((results) => {
      if (results) {
        this.quickActions.push({
          _id: results._id,
          title: results.title,
          link: decodeURIComponent(
            results.link.replace(getNavigationRegex(environment.WEB_DOMAIN), '')
          ),
          icon: results.icon,
          index_number: results.index_number,
        });
      }
      this.loading = false;
    });
    this.loading = false;
  }

  onNavigateToQuickActionPage(link: string, key: string): void {
    if (link) {
      this.router.navigateByUrl(decodeURIComponent(link));
    } else if (key) {
      //function to call if key is available
    }
  }

  focusFirstQuickAction() {
    document.getElementById('firstQuickAction').focus();
  }

  initDefaultQuickActions() {
    const systemData = DEFAULT_QUICK_ACTIONS;
    systemData.forEach((action, index) => {
      this.permissionFiltrationService.validatePermissions({
        permissions: action?.permissions ?? [],
      }) &&
        this.systemQuickActions.push({
          title: this.translate.instant(action?.title),
          link: action?.link
            ? decodeURIComponent(
                action.link.replace(
                  getNavigationRegex(environment.WEB_DOMAIN),
                  ''
                )
              )
            : null,
          icon: action?.icon,
          index_number: index,
          isSystem: true,
          key: action?.key,
        });
    });
  }
}
