import { FILE_TYPES } from '@shared/constants';

import { getMimeTypesForFileType } from './file-types';

export function getURLRegex(webDomain: string): RegExp {
  return new RegExp(
    `(http(s)?:\\/\\/)?(www\\.)?(${webDomain})\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)`
  );
}

export function getNavigationRegex(webDomain: string): RegExp {
  return new RegExp(`(http(s)?:\\/\\/)?(www\\.)?(${webDomain})`);
}

export function generateFileTypeRegex(types: FILE_TYPES[]): RegExp {
  const allowedMimeTypes = types.flatMap((type) =>
    getMimeTypesForFileType(type)
  );

  return new RegExp(allowedMimeTypes.join('|'));
}
