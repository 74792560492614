<div
  class="editor-container"
  [class.editor-big]="size === 'big'"
  [class.editor-medium]="size === 'medium'"
  [class.editor-large]="size === 'large'"
>
  <ngx-monaco-editor
    [disabled]="disabled"
    [options]="editorOptions"
    [(ngModel)]="code"
    (init)="editorInit($event)"
    (ngModelChange)="onDirChange($event)"
  ></ngx-monaco-editor>
</div>

<div class="error-container" *ngIf="errors.length > 0">
  <span class="error-title">
    {{ errors.length }}
    &nbsp;
    {{ errors.length > 1 ? 'Errors' : 'Error' }}
  </span>

  <div class="scrollable">
    <ul>
      <li *ngFor="let error of errors">
        {{ error.message }}
        [{{ error.startLineNumber }}, {{ error.startColumn }}]
      </li>
    </ul>
  </div>
</div>
