import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';

import { BaseChipComponent } from '../../base-chip/base-chip.component';
import { ChipInputSearchHelper } from '../../helpers/chip-input-search.helper';
import { TextChipProperties } from '../../types/chip-properties/text';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-cis-text-chip-search-filter',
  templateUrl: './text-chip-search-filter.component.html',
  styleUrls: ['./text-chip-search-filter.component.scss'],
})
export class TextChipSearchFilterComponent
  extends BaseChipComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('textInput') chipInput?: ElementRef<HTMLInputElement>;
  longText = new FormControl();

  get selectedDataChipProperties(): TextChipProperties | undefined {
    return this.selectedData?.chipProperties as TextChipProperties;
  }

  constructor(chipInputSearchHelper: ChipInputSearchHelper) {
    super(chipInputSearchHelper);
    this.longText.valueChanges
      .pipe(startWith(''), debounceTime(200))
      .subscribe((chipValue: string | undefined) => {
        if (this.selectedDataChipProperties) {
          this.selectedDataChipProperties.value = chipValue;
        }
        this.editedData.emit(this.selectedData);
      });
  }

  ngOnInit(): void {
    this.longText.setValue(this.selectedDataChipProperties?.value);
    this.minWidth = this.selectedData?.size || this.minWidth;
    this.longText.valueChanges
      .pipe(debounceTime(50), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.value = this.longText.value;
        this.formControl = this.longText;
        super.resize(this.minWidth);
        if (this.selectedData?.validations) {
          super.validate_value();
        }
      });
    this.formControl = this.longText;
    this.value = this.longText.value;
    super.resize(this.minWidth);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.isChipLock()) {
        this.chipInput?.nativeElement.focus();
      }
    }, 10);
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
