import { isArray } from 'lodash';

import {
  IContactAddress,
  IContactEmail,
  IContactNumber,
  IName,
  MultiFieldData,
} from '@shared/interfaces';

export const getArrangedName = (data?: Partial<IName>) => {
  const { salutation, first_name, middle_name, last_name } = data || {};
  const nameParts = [salutation, first_name, middle_name, last_name].filter(
    Boolean
  );

  return nameParts.length > 0 ? nameParts.join(' ') : undefined;
};

export const getArrangedAddress = (data?: IContactAddress) => {
  const {
    line1,
    line2,
    line3,
    countryData,
    country,
    stateData,
    state,
    districtData,
    district,
    cityData,
    city,
    postal_code,
  } = data?.address || {};
  const postal_code_with_city =
    (cityData?.name ?? city) + ' (Postal Code: ' + postal_code + ')';
  const addressParts = [
    line1,
    line2,
    line3,
    postal_code_with_city,
    districtData?.name ?? district,
    stateData?.name ?? state,
    countryData?.name ?? country,
  ].filter(Boolean);
  if (postal_code) {
    return addressParts.length > 0 ? `${addressParts.join(', ')}.` : undefined;
  } else {
    return undefined;
  }
};

export const getArrangedPhone = (
  data?: IContactNumber,
  avoidTypeSuffix = false
) => {
  const { phone_number } = data || {};
  const typeSuffix = avoidTypeSuffix ? '' : ` (${data?.type})`;

  return `${phone_number?.internationalNumber}${typeSuffix}`;
};

export const getArrangedEmail = (
  data?: IContactEmail,
  avoidTypeSuffix = false
) => {
  const { email } = data || {};
  const typeSuffix = avoidTypeSuffix ? '' : ` (${data?.type})`;

  return `${email}${typeSuffix}`;
};

export const extractDefaultValue = (
  data: MultiFieldData,
  avoidTypeSuffix = false
): string | undefined => {
  if (!data.value || (data.value as IContactNumber[]).length === 0) return '';

  switch (data.type) {
    case 'NAME': {
      return getArrangedName(data.value);
    }
    case 'ADDRESS': {
      if (!isArray(data?.value)) return '-';

      const primaryAddress =
        data?.value?.find((address) => address.is_primary) ?? data?.value?.[0];

      return getArrangedAddress(primaryAddress);
    }
    case 'PHONE': {
      if (!isArray(data?.value)) return '-';

      const primaryPhone =
        data?.value?.find((phone) => phone.is_primary) ?? data?.value?.[0];

      return getArrangedPhone(primaryPhone, avoidTypeSuffix);
    }
    case 'EMAIL': {
      if (!isArray(data?.value)) return '-';

      const primaryEmail =
        data?.value?.find((email) => email.is_primary) ?? data?.value?.[0];

      return getArrangedEmail(primaryEmail, avoidTypeSuffix);
    }
  }
};
