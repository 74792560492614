<span class="date-chip-content">
  <mat-datepicker-toggle
    class="prefix-icon"
    [disableRipple]="true"
    [disabled]="disabled"
    [for]="picker"
    attr.data-testid="cis_year_month_{{
      label?.toLowerCase()?.replace(' ', '_')
    }}_toggle"
  ></mat-datepicker-toggle>
  <label class="has-float-label">
    <input
      class="chip-input"
      [style.width.px]="inputWidth"
      (click)="picker.open(); $event.stopPropagation()"
      (focus)="picker.open(); $event.stopPropagation()"
      [formControl]="formControl"
      (keydown)="$event.stopImmediatePropagation()"
      [placeholder]="placeholder"
      (blur)="picker.close()"
      [readonly]="readonly"
      [matDatepicker]="picker"
      type="datetime"
      style="max-width: 80px"
      attr.data-testid="cis_year_month_{{
        label?.toLowerCase()?.replace(' ', '_')
      }}_input"
    />
    <div class="label">{{ label }}</div>
    <mat-datepicker
      #picker
      startView="multi-year"
      (monthSelected)="setMonthAndYear($event, picker)"
      attr.data-testid="cis_year_month_{{
        label?.toLowerCase()?.replace(' ', '_')
      }}_date_picker"
    ></mat-datepicker>
  </label>
</span>
