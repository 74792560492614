import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IImagePreview } from '../../../../modules/shared/components/image-preview/image-preview.component';
import { DateTimeFormatService } from '../../../../services/date-time-format.service';

export interface INotices {
  title: string;
  start_date: Date;
  end_date: Date;
  start_time: Date;
  end_time: Date;
  body: string;
  featured_image?: string;
}

@Component({
  selector: 'app-dialog-view-notice',
  templateUrl: './view-notice.component.html',
  styleUrls: ['./view-notice.component.scss'],
})
export class DialogViewNoticeComponent implements OnInit, AfterViewInit {
  title: string;
  publishedOn: string;
  body: string;
  @ViewChild('marqueeText') marqueeText: ElementRef;
  @ViewChild('header') header: ElementRef;
  flag = 0;
  rightToLeft = true;
  imageUri: string;

  imageConfig: IImagePreview = {
    alt: 'image preview',
    ngStyle: {
      width: '100%',
      'min-height': '100px',
    },
  };

  constructor(
    private dialogRef: MatDialogRef<DialogViewNoticeComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public notice: INotices,
    private dateTimeFormatService: DateTimeFormatService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    this.title = this.notice.title;
    this.publishedOn = `${this.dateTimeFormatService.combineAndFormatDateTime(
      this.notice.start_date,
      this.notice.start_time
    )}`;
    this.body = this.notice.body;
  }

  onClickClose() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.marquee();
  }

  marquee() {
    const textElement = this.marqueeText.nativeElement;
    const card = this.header.nativeElement;
    const elementWidth = textElement.scrollWidth;
    const parentWidth = card.offsetWidth;
    const limit = elementWidth - parentWidth;

    setTimeout(() => {
      setInterval(() => {
        if (this.flag > -limit) {
          textElement.style.marginLeft = --this.flag + 'px';
        } else {
          this.flag = 0;
        }
      }, 40);
    }, 1000);
  }
}
