import { AfterViewInit, Component } from '@angular/core';
import { ShortcutInput } from 'ng-keyboard-shortcuts';

import { PersonalizationService } from '../../../../pages/personalization/services/personalization.service';
import { ColorSchemeService } from '../../../setup/appearance/services/color-scheme.service';

@Component({
  selector: 'app-dark-mode',
  templateUrl: './dark-mode.component.html',
  styleUrls: ['./dark-mode.component.scss'],
})
export class DarkModeComponent implements AfterViewInit {
  shortcuts: ShortcutInput[] = [];

  constructor(
    public colorSchemeService: ColorSchemeService,
    private personalizationService: PersonalizationService
  ) {}

  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['alt + d'],
      label: 'Toggle Dark Mode',
      description: 'Alt + D',
      command: () => this.toggleTheme(),
      preventDefault: true,
    });
  }

  toggleTheme() {
    const currentDarkMode = this.colorSchemeService.currentActive() === 'dark';

    this.colorSchemeService.update(currentDarkMode ? 'light' : 'dark');
    // Enable below function to update the personalization page as well
    this.personalizationService
      .updatePersonalizationData({ dark_mode: !currentDarkMode })
      .subscribe();
  }
}
