import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IDocumentUploaderDialogData {
  maxSize: number;
  allowedTypesNames: string;
  allowedTypesRegex: RegExp;
  title: string;
  subtitle: string;
  icon?: string;
}

@Component({
  selector: 'app-document-uploader',
  templateUrl: './document-uploader.component.html',
  styleUrls: ['./document-uploader.component.scss'],
})
export class DocumentUploaderComponent {
  files: File[] = [];

  constructor(
    private matDialog: MatDialogRef<DocumentUploaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDocumentUploaderDialogData
  ) {}

  triggerFileSelected(file: File) {
    this.files.push(file);
    this.matDialog.close(this.files);
  }

  onClose(): void {
    this.matDialog.close();
  }
}
