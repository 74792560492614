<div class="mt-3 mr-2 menu-container">
  <mat-accordion displayMode="flat" class="child-headers-align" multi>
    <div class="action-bar">
      <mat-form-field class="filter-form-field">
        <span matPrefix>
          <mat-icon class="prefix-icon">filter_list</mat-icon>
        </span>
        <input
          #input
          matInput
          [placeholder]="'pages.home.filter' | translate"
          [(ngModel)]="searchValue"
          (ngModelChange)="checkFilterEmpty()"
        />
        <button
          mat-button
          *ngIf="searchValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchValue = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
        <span matSuffix *ngIf="!searchValue">
          <mat-icon>search</mat-icon>
        </span>
      </mat-form-field>

      <button *ngIf="expandAll" mat-icon-button (click)="expandAll = false">
        <mat-icon>unfold_less</mat-icon>
      </button>

      <button *ngIf="!expandAll" mat-icon-button (click)="expandAll = true">
        <mat-icon>unfold_more</mat-icon>
      </button>
    </div>

    <div *ngFor="let category of categories">
      <mat-expansion-panel
        style="padding: 0px; border: none"
        class="rounded-0"
        [expanded]="expandAll"
        *ngIf="filter(category)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="menu-item">{{
            groupedCategories[category][0].category_description | translate
          }}</mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngFor="let widget of groupedCategories[category]">
          <app-menu-item
            [widget]="widget"
            [isDisabled]="isDisabled"
            (onAddWidget)="onAddWidget($event)"
            (onRemoveWidget)="onRemoveWidget($event)"
          >
          </app-menu-item>
        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>
