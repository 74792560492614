import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';

import { BaseChipComponent } from '../../base-chip/base-chip.component';
import { CIS_CHIP_TYPES } from '../../constants';
import { ChipInputSearchHelper } from '../../helpers/chip-input-search.helper';
import { NumberRangeChipProperties } from '../../types/chip-properties/number-range';

import { INumberRange } from './numeric-range-form-field-control/numeric-range-form-field-control.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-cis-number-range-chip-search-filter',
  templateUrl: './number-range-chip-search-filter.component.html',
  styleUrls: ['./number-range-chip-search-filter.component.scss'],
})
export class NumberRangeChipSearchFilterComponent
  extends BaseChipComponent
  implements OnInit, OnDestroy
{
  form: FormGroup = new FormGroup({
    numberRange: new FormControl(new INumberRange()),
  });

  get selectedDataChipProperties(): NumberRangeChipProperties | undefined {
    return this.selectedData?.chipProperties as NumberRangeChipProperties;
  }

  constructor(chipInputSearchHelper: ChipInputSearchHelper) {
    super(chipInputSearchHelper);
    this.form.valueChanges
      .pipe(startWith(''), debounceTime(200))
      .subscribe((chipValue: { numberRange: INumberRange }) => {
        if (this.selectedDataChipProperties) {
          this.selectedDataChipProperties.minValue =
            chipValue.numberRange.minValue;
          this.selectedDataChipProperties.maxValue =
            chipValue.numberRange.maxValue;
        }
        this.editedData.emit(this.selectedData);
      });
  }

  ngOnInit(): void {
    this.minWidth = this.selectedData?.size || this.minWidth;
    this.form.valueChanges
      .pipe(debounceTime(50), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.formControl = this.form;
        this.value = this.form.value;
        if (this.selectedData?.validations) {
          super.validate_value();
        }
        super.resize(this.minWidth);
      });
    this.formControl = this.form;
    this.value = this.form.value;
    super.resize(this.minWidth);
  }

  styleDivObject() {
    if (this.selectedData?.chipProperties.type !== CIS_CHIP_TYPES.NUMBER_RANGE)
      return {};

    const { chipProperties } = this.selectedData;
    if (
      chipProperties.minValue ||
      chipProperties.maxValue ||
      this.inputFocusOn
    ) {
      return { marginBottom: '-5px', 'width.px': 160 };
    } else {
      return { marginBottom: '-7px', 'width.px': 160 };
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
