import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
// Material modules
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ElAutocompleteModule } from '@el-autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPanZoomModule } from 'ngx-panzoom';

import { BaseChipComponent } from './base-chip/base-chip.component';
import { AutocompleteChipSearchFilterComponent } from './components/autocomplete-chip-search-filter/autocomplete-chip-search-filter.component';
import { DateChipSearchFilterComponent } from './components/date-chip-search-filter/date-chip-search-filter.component';
import { YearMonthDatePickerComponent } from './components/date-chip-search-filter/year-month-date-picker/year-month-date-picker.component';
import { YearMonthPickerComponent } from './components/date-chip-search-filter/year-month-picker/year-month-picker.component';
import { YearPickerComponent } from './components/date-chip-search-filter/year-picker/year-picker.component';
import { DateRangeChipSearchFilterComponent } from './components/date-range-chip-search-filter/date-range-chip-search-filter.component';
import { FilterSelectChipSearchFilterComponent } from './components/filter-select-chip-search-filter/filter-select-chip-search-filter.component';
import { NumberChipSearchFilterComponent } from './components/number-chip-search-filter/number-chip-search-filter.component';
import { NumberRangeChipSearchFilterComponent } from './components/number-range-chip-search-filter/number-range-chip-search-filter.component';
import { NumericRangeFormFieldControlComponent } from './components/number-range-chip-search-filter/numeric-range-form-field-control/numeric-range-form-field-control.component';
import { SelectChipSearchFilterComponent } from './components/select-chip-search-filter/select-chip-search-filter.component';
import { SliderChipSearchFilterComponent } from './components/slider-chip-search-filter/slider-chip-search-filter.component';
import { TextChipSearchFilterComponent } from './components/text-chip-search-filter/text-chip-search-filter.component';
// Internal modules
import { ElChipInputSearchComponent } from './el-chip-input-search.component';

const customModules = [
  AutocompleteChipSearchFilterComponent,
  DateChipSearchFilterComponent,
  DateRangeChipSearchFilterComponent,
  FilterSelectChipSearchFilterComponent,
  TextChipSearchFilterComponent,
  NumberChipSearchFilterComponent,
  NumberRangeChipSearchFilterComponent,
  SelectChipSearchFilterComponent,
  SliderChipSearchFilterComponent,
  NumericRangeFormFieldControlComponent,
  BaseChipComponent,
  ElChipInputSearchComponent,
  YearMonthDatePickerComponent,
  YearMonthPickerComponent,
  YearPickerComponent,
];

const materialModules = [
  MatProgressBarModule,
  MatCardModule,
  MatChipsModule,
  MatRadioModule,
  FormsModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatDividerModule,
  MatIconModule,
  MatExpansionModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatDialogModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  MatTreeModule,
  MatSliderModule,
  MatCheckboxModule,
];

@NgModule({
  declarations: [[...customModules]],
  imports: [
    AngularCommonModule,
    NgxPanZoomModule,
    TranslateModule,
    ElAutocompleteModule,
    ...materialModules,
  ],
  exports: [[...customModules]],
  entryComponents: [ElChipInputSearchComponent],
})
export class ELChipInputSearchModule {}
