<div class="main-container">
  <div class="flex-center">
    <lottie-player
      src="/assets/animations/new-release.json"
      background="transparent"
      speed="1"
      style="width: 200px; height: 200px"
      loop
      autoplay
      alt="new-release"
    ></lottie-player>
  </div>
  <h1 mat-dialog-title>{{ 'core.popups.release-check.title' | translate }}</h1>
  <p>{{ 'core.popups.release-check.description' | translate }}</p>
  <div class="release-note" *ngIf="releaseNode && versionNumber">
    <h5>
      {{ 'core.popups.release-check.release-note' | translate }} ({{
        versionNumber
      }})
    </h5>
  </div>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div
        class="release-check mb-sm-1 note mx-1"
        mat-dialog-content
        style="overflow: scroll; border: 1px solid #fff; border-radius: 5px"
      >
        <div class="text-wrap" style="align-self: center">
          <div
            class="change-log"
            style="text-align: left"
            [innerHTML]="releaseNode"
          ></div>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
  <div class="mt-3" mat-dialog-content>
    <p>{{ 'core.popups.release-check.refresh-instruction' | translate }}</p>
    <button
      data-testid="release_check_btn_reload_page"
      mat-button
      (click)="reloadPage()"
      class="primary-bg"
    >
      {{ 'core.popups.release-check.refresh-btn' | translate }}
    </button>
  </div>
</div>
