import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO, IPhoneNumber } from '@shared/interfaces';

import { ITestEmail } from '../send-test-email/send-test-email.component';

export interface NotificationEmailConfigDTO {
  user: string;
  password: string;
  host: string;
  port: number;
  from: string;
  from_email: string;
  tls?: string;
  secure: boolean;
}
export interface PushNotificationConfigDTO {
  appId: string;
  apiKey: string;
}
export interface NotificationSMSConfigDTO {
  username: string;
  password: string;
  source: string;
  gateway: string;
}

export interface NotificationConfigResponseDTO {
  isNotificationEnable: boolean;
  isNotificationEnableViaEmail: boolean;
  isPushNotificationEnable?: boolean;
  pushNotificationConfig?: PushNotificationConfigDTO;
  emailGatewayConfig: NotificationEmailConfigDTO;
  smsGatewayConfig?: NotificationSMSConfigDTO;
  isSMTPConfigurationValid?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationConfigService {
  public readonly dataStore =
    new BehaviorSubject<NotificationConfigResponseDTO>(undefined);

  constructor(private http: HttpClient) {}

  fetchSystemNotificationConfig(): Observable<
    CommonResponseDTO<NotificationConfigResponseDTO>
  > {
    return this.http
      .get<CommonResponseDTO<NotificationConfigResponseDTO>>(
        URLS.NOTIFICATION_CONFIG_NOTIFICATION
      )
      .pipe(
        tap(({ data }) => {
          this.dataStore.next(data);
        })
      );
  }

  getEmailPassword(): Observable<CommonResponseDTO<any>> {
    return this.http.get<CommonResponseDTO<any>>(
      URLS.NOTIFICATION_CONFIG_GET_EMAIL_PASSWORD
    );
  }

  getSmsPassword(): Observable<CommonResponseDTO<any>> {
    return this.http.get<CommonResponseDTO<any>>(
      URLS.NOTIFICATION_CONFIG_GET_SMS_PASSWORD
    );
  }

  getPushNotificationKey(): Observable<CommonResponseDTO<any>> {
    return this.http.get<CommonResponseDTO<any>>(
      URLS.NOTIFICATION_CONFIG_GET_PUSH_NOTIFICATION_KEY
    );
  }

  updateSystemNotificationsConfig(
    body: NotificationConfigResponseDTO
  ): Observable<CommonResponseDTO<NotificationConfigResponseDTO>> {
    return this.http
      .put<CommonResponseDTO<NotificationConfigResponseDTO>>(
        URLS.NOTIFICATION_CONFIG_NOTIFICATION,
        body
      )
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }

  sendTestEmail(data: ITestEmail): Observable<CommonResponseDTO<ITestEmail>> {
    return this.http.post<CommonResponseDTO<ITestEmail>>(
      URLS.NOTIFICATION_CONFIG_TEST_EMAIL,
      data
    );
  }

  sendTestSMS(phone_number: IPhoneNumber): Observable<CommonResponseDTO<void>> {
    return this.http.post<CommonResponseDTO<void>>(
      URLS.NOTIFICATION_CONFIG_TEST_SMS,
      phone_number
    );
  }
}
