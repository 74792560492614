import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'embed',
})
export class OEmbedToIframePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    if (!value) {
      return value;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'text/html');
    const oEmbedElements = doc.querySelectorAll('oembed');

    if (!oEmbedElements.length) {
      return value;
    }

    oEmbedElements.forEach((element) => {
      const url = element.getAttribute('url');
      if (!url) return;

      const embedUrl = this.constructEmbedUrl(url);
      const iframe = this.generateIframe(embedUrl);
      element.replaceWith(iframe);
    });

    const updatedHtml = doc.body.innerHTML;

    return this.sanitizer.bypassSecurityTrustHtml(updatedHtml);
  }

  private constructEmbedUrl(url: string): string {
    const parsedUrl = new URL(url);

    if (parsedUrl.hostname.includes('youtube.com')) {
      const videoId = parsedUrl.searchParams.get('v');
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (url.includes('vimeo.com')) {
      const videoId = this.extractVimeoId(url);
      return `https://player.vimeo.com/video/${videoId}`;
    } else if (url.includes('facebook.com')) {
      return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
        url
      )}&data_show_text=true`;
    } else if (url.includes('instagram.com')) {
      return `https://www.instagram.com/p/${this.extractInstagramId(
        url
      )}/embed/`;
    }

    // Add more providers if needed
    return '';
  }

  extractVimeoId(url: string): string {
    const match = url.match(/vimeo\.com\/(\d+)/);
    return match ? match[1] : '';
  }

  extractInstagramId(url: string): string {
    const match = url.match(/instagram\.com\/p\/([^/]+)/);
    return match ? match[1] : '';
  }

  private generateIframe(embedUrl: string): HTMLElement {
    const iframe = document.createElement('iframe');
    iframe.src = embedUrl;
    iframe.width = '100%'; // Set desired width
    iframe.height = '512'; // Set desired height
    iframe.allowFullscreen = true; // Optional: Allow fullscreen
    return iframe;
  }
}
