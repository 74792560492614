<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  matTooltip="{{ 'pages.dashboard.notifications' | translate }}"
  *ngIf="notificationEnable && hasEnoughPermissions"
>
  <mat-icon
    aria-label="Notifications"
    style="margin-bottom: 4px"
    matBadge="{{
      notificationCount > 0
        ? notificationCount < 100
          ? notificationCount
          : '99+'
        : ''
    }}"
    matBadgePosition="after"
    matBadgeColor="accent"
    >notifications</mat-icon
  >
</button>

<mat-menu
  #menu="matMenu"
  [classList]="mobileView ? 'notification-panel' : 'special-size'"
  xPosition="before"
>
  <div
    class="container"
    [style.color]="colorSchemeService.currentActive() === 'dark' ? 'white' : ''"
  >
    <div class="d-flex justify-content-between w-100 mt-2 mb-4">
      <mat-card-title style="font-size: 18px">
        {{ 'pages.dashboard.notifications' | translate }}
      </mat-card-title>
      <button
        *ngIf="notificationCount > 0"
        mat-button
        (click)="markAllAsRead()"
        [disabled]="isLoading"
      >
        <span class="d-flex align-items-center">
          <mat-progress-spinner
            *ngIf="isLoading"
            [color]="'accent'"
            [mode]="'indeterminate'"
            [diameter]="20"
            class="mr-2"
          >
          </mat-progress-spinner
          >Mark All as Read</span
        >
      </button>
    </div>
    <div *ngIf="notificationCount > 0">
      <div *ngFor="let userNotification of userNotifications">
        <div
          class="col-sm-12 notification-border mb-2"
          [ngClass]="
            colorSchemeService.currentActive() === 'light' ? 'ripple' : ''
          "
          style="cursor: pointer"
          (click)="onClickNotification(userNotification)"
        >
          <div class="row center-item">
            <div class="col-sm-2">
              <mat-icon aria-label="Notifications" style="margin-bottom: 4px"
                >notifications</mat-icon
              >
            </div>
            <div class="col-sm-10" [ngClass]="mobileView ? 'ml-2' : ''">
              <div class="row">
                <p class="notification-title mb-0 mt-2">
                  {{ userNotification.notification?.title }}
                </p>
              </div>
              <div
                class="row notification-description"
                [innerHTML]="
                  userNotification.notification?.description?.substring(
                    0,
                    130
                  ) + '...'
                "
              ></div>
              <p
                class="notification-timestamp"
                [style.color]="
                  colorSchemeService.currentActive() === 'light'
                    ? '#3645a9'
                    : ''
                "
              >
                {{ calculateNotificationTime(userNotification.created_on) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="notificationCount === 0">
      <div class="horizontal-center-container mb-4">
        <div class="center-item">
          <img
            class="image mb-1"
            width="40%"
            src="assets/no-notifications.svg"
            alt="empty-box"
          />
        </div>
        <h6 class="title flex-center text-center">
          {{ 'pages.dashboard.no-notifications' | translate }}
        </h6>
      </div>
    </div>
    <button mat-button class="w-100 my-2" (click)="viewAllNotifications()">
      <span>{{ 'pages.dashboard.see-all-notifications' | translate }}</span>
    </button>
  </div>
</mat-menu>
