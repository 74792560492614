import { Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-diary',
  templateUrl: './skeleton-diary.component.html',
  styleUrls: ['./skeleton-diary.component.scss'],
})
export class SkeletonDiaryComponent {
  rows = ['1', '2', '3', '4', '5'];
  columns = ['1', '2', '3', '4', '5', '6', '7'];
}
