import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ERROR_CODES, RESPONSE_STATUSES } from '@shared/constants';

import { AuthService } from '../../modules/auth/services';
import { IamConfigService } from '../../modules/setup/iam-config/services/iam-config.service';
import { FlowControlService } from '../../services/flow-control.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../services/snackbar.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  isLoading = false;
  isHidden = true;
  passwordErrorMessage = '';
  passwordValidationPattern;
  private subscriptions = new Subscription();

  resetPasswordFormGroup = new UntypedFormGroup({
    current_password: new UntypedFormControl(null, Validators.required),
    new_password: new UntypedFormControl(null, [Validators.required]),
    confirm_password: new UntypedFormControl(null, Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    router: Router,
    flowControlService: FlowControlService,
    private authService: AuthService,
    private snackBar: SnackbarService,
    private translate: TranslateService,
    private iamConfigService: IamConfigService
  ) {
    flowControlService.updateRouteInfo(
      router.url.split('?')[0],
      route.snapshot.data
    );
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.resetPasswordFormGroup.valueChanges.subscribe((change) => {
        if (change.confirm_password !== change.new_password) {
          this.resetPasswordFormGroup.controls.confirm_password.setErrors({
            unMatching: true,
          });
        } else {
          this.resetPasswordFormGroup.controls.confirm_password.setErrors({
            unMatching: false,
          });
          this.resetPasswordFormGroup.controls.confirm_password.updateValueAndValidity(
            { emitEvent: false }
          );
        }
      })
    );

    this.subscriptions.add(
      this.iamConfigService.passRegex.subscribe((newPasswordRegex) => {
        if (newPasswordRegex.regExp) {
          this.resetPasswordFormGroup.controls.new_password.setValidators([
            Validators.required,
            Validators.pattern(newPasswordRegex.regExp),
          ]);
          this.resetPasswordFormGroup.controls.confirm_password.setValidators([
            Validators.required,
            Validators.pattern(newPasswordRegex.regExp),
          ]);

          this.resetPasswordFormGroup.controls.new_password.updateValueAndValidity();
          this.resetPasswordFormGroup.controls.confirm_password.updateValueAndValidity();

          this.passwordErrorMessage = newPasswordRegex.validationErrorMessage;
          this.passwordValidationPattern = newPasswordRegex.regExp;
        } else {
          this.passwordErrorMessage = null;
          this.passwordValidationPattern = null;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSubmit(): void {
    this.isLoading = true;
    this.authService
      .resetLoggedInUserPassword({
        current_password: this.resetPasswordFormGroup.value.current_password,
        new_password: this.resetPasswordFormGroup.value.new_password,
      })
      .subscribe({
        next: () => {
          this.snackBar.success(SUCCESS_TYPES.SAVED);
          this.resetPasswordFormGroup.reset();
          Object.keys(this.resetPasswordFormGroup.controls).forEach((key) => {
            this.resetPasswordFormGroup.get(key).setErrors(null);
          });
          this.isLoading = false;
        },
        error: (err) => {
          const { errorCode } = err.error;
          if (err.status === RESPONSE_STATUSES.UNPROCESSABLE_ENTITY) {
            switch (errorCode) {
              case ERROR_CODES.PASSWORD_USED_BEFORE:
                this.snackBar.error(
                  this.translate.instant(
                    'pages.reset-password.error.previously-used'
                  )
                );
                break;

              case ERROR_CODES.SAME_PASSWORD:
                this.snackBar.error(
                  this.translate.instant(
                    'pages.reset-password.error.same-password'
                  )
                );
                break;

              default:
                this.snackBar.error(
                  this.translate.instant(
                    'pages.reset-password.error.incorrect-current-password'
                  )
                );
                this.resetPasswordFormGroup.controls.current_password.setErrors(
                  { incorrect: true }
                );
                break;
            }
          }
          this.isLoading = false;
        },
      });
  }
}
