import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DateTimeFormatService } from '../../../../services/date-time-format.service';

export interface ITask {
  title: string;
  start_date: Date;
  end_date: Date;
  start_time: Date;
  end_time: Date;
  all_day: boolean;
  description: string;
}

@Component({
  selector: 'app-view-task',
  templateUrl: './view-task.component.html',
  styleUrls: ['./view-task.component.scss'],
})
export class ViewTaskComponent implements OnInit {
  title: string;
  dateRange: string;
  description: string;

  constructor(
    private dialogRef: MatDialogRef<ViewTaskComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ITask,
    private dateTimeFormatService: DateTimeFormatService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.title = this.data.title;
    if (this.data.all_day) {
      this.dateRange = `${this.dateTimeFormatService.formatDateWithoutTime(
        this.data.start_date
      )} - ${this.dateTimeFormatService.formatDateWithoutTime(
        this.data.end_date
      )} `;
      this.description = this.data.description;
    } else {
      this.dateRange = `${this.dateTimeFormatService.combineAndFormatDateTime(
        this.data.start_date,
        this.data.start_time
      )} - ${this.dateTimeFormatService.combineAndFormatDateTime(
        this.data.end_date,
        this.data.end_time
      )} `;
      this.description = this.data.description;
    }
  }

  onClickClose() {
    this.dialogRef.close();
  }
}
