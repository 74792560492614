import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { firstValueFrom, Subscription } from 'rxjs';

import {
  ASSIGNMENT_TYPES,
  ENDPOINT_PARAM_TYPES,
  ENDPOINT_STATUS,
  FORMULA_ELEMENT_TYPES,
  MAPPING_RETURN_DATA_TYPES,
  MAPPING_RETURN_TYPES,
  MAPPING_STATUS,
  VARIABLE_DATA_TYPES,
} from '@shared/constants';
import {
  IConfigurableFieldConfigResponse,
  IFormulaConstantElement,
  IFormulaCustomVariableElement,
  IFormulaDataReferenceElement,
  IFormulaElement,
  IFormulaExternalDataElement,
  IFormulaIntegrationElementParam,
  IFormulaOperatorElement,
  IMapping,
  IReferenceCategoryResponse,
} from '@shared/interfaces';
import {
  constantFormulaElement,
  cursorFormulaElement,
  customVariableFormulaElement,
  dataReferenceFormulaElement,
  externalDataFormulaElement,
  integrationFormulaElement,
  operatorFormulaElement,
  referenceFormulaElement,
} from '@shared/utils';

import { SUCCESS_MESSAGE } from '../../../../constants';
import { SnackbarService } from '../../../../services/snackbar.service';
import {
  EndpointsService,
  IEndpointResponseWithMappings,
} from '../../../integrations/endpoints/services/endpoints.service';
import { ReferenceCategoryService } from '../../../references/services';
import { FormulaService } from '../../services';

import { DialogContentDefineCustomVariableComponent } from './popups/define-custom-variable/define-custom-variable.component';
import {
  DefineIntegrationPopupData,
  DialogContentDefineIntegrationComponent,
} from './popups/define-integration/define-integration-popup.component';
import { DialogContentDefineReferenceFiltersComponent } from './popups/define-reference-filters/define-reference-filters-popup.component';
import formulaElements from './formula-elements';

const {
  conditionalOperators,
  bracketOperators,
  basicOperators,
  comparativeOperators,
  dataReferences,
  generalConstants,
  customVariables,
  // logicalOperators,
  externalData,
} = formulaElements;
const DEFAULT_SECTION = 'Uncategorized';

interface SectionedReference {
  section: string;
  references: IReferenceCategoryResponse[];
}

@Component({
  selector: 'app-formula-builder',
  templateUrl: './formula-builder.component.html',
  styleUrls: ['./formula-builder.component.scss'],
})
export class FormulaBuilderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fieldName = 'Field Name';
  @Input() formula = '';
  @Input() isDisabled = false;
  @Input() _customVariables: IFormulaCustomVariableElement[] =
    cloneDeep(customVariables);
  @Input() referenceCategory: IReferenceCategoryResponse;
  @Output() onUpdateFormula = new EventEmitter<string>();
  @Output() onErrorFormula = new EventEmitter<boolean>();
  @Output() formulaFieldElements = new EventEmitter();

  cursorElement = cursorFormulaElement;
  operatorElement = operatorFormulaElement;
  constantElement = constantFormulaElement;
  customVariableElement = customVariableFormulaElement;
  integrationElement = integrationFormulaElement;
  externalDataElement = externalDataFormulaElement;
  dataReferenceElement = dataReferenceFormulaElement;
  referenceElement = referenceFormulaElement;

  expandAllMainSections = false;
  expandAllReferenceSections = true;
  expandAllReferenceCategories = true;
  expandAllIntegrationEndpoints = true;

  private isMappingConfigValid: {
    [endpointMappingKey: string]: boolean;
  } = {};

  constructor(
    private formulaService: FormulaService,
    private dialog: MatDialog,
    private snackBar: SnackbarService,
    private endpointsService: EndpointsService,
    private referenceCategoryService: ReferenceCategoryService
  ) {}

  formControllers: UntypedFormControl[] = [
    new UntypedFormControl(undefined, Validators.required),
  ];
  selectedElements: IFormulaElement[] = [
    { type: FORMULA_ELEMENT_TYPES.CURSOR },
  ];

  panelOpened = false;
  errorFormula = false;
  validatingFormula = false;

  conditionalOperators: IFormulaOperatorElement[] = [];
  bracketOperators: IFormulaOperatorElement[] = [];
  basicOperators: IFormulaOperatorElement[] = [];
  comparativeOperators: IFormulaOperatorElement[] = [];
  dataReferences: IFormulaDataReferenceElement[] = [];
  generalConstants: IFormulaConstantElement[] = [];
  customVariables: IFormulaCustomVariableElement[] = [];
  // logicalOperators: IFormulaElement[] = [];
  integrationEndpoints: IEndpointResponseWithMappings[] = [];
  externalData: IFormulaExternalDataElement[] = [];
  sectionedReferences: SectionedReference[] = [];

  myControl = new UntypedFormControl();
  myControlChangesSubscription: Subscription;

  ngOnInit(): void {
    this.filterChips();
    this.myControlChangesSubscription = this.myControl.valueChanges.subscribe(
      (value) => this.filterChips(value)
    );
  }

  ngOnDestroy(): void {
    if (this.myControlChangesSubscription) {
      this.myControlChangesSubscription.unsubscribe();
    }
  }

  private async filterChips(key: string = undefined) {
    const checkForKeyInclusive = (value: string) =>
      !key ||
      key === '' ||
      value.toLocaleLowerCase().includes(key.toLocaleLowerCase());

    this.sectionedReferences = (
      await firstValueFrom(this.referenceCategoryService.activeCategories)
    )
      .reduce((acc, curr) => {
        const section = curr.section || DEFAULT_SECTION;
        const fields = curr.fields.filter(
          (field) =>
            checkForKeyInclusive(`${curr.name}: ${field.name}`) ||
            section.toLocaleLowerCase().includes(key.toLocaleLowerCase())
        );
        if (fields.length > 0) {
          const sectionIndex = acc.findIndex(
            (_acc) => _acc.section === section
          );
          if (sectionIndex > -1) {
            acc[sectionIndex].references.push({ ...curr, fields });
          } else {
            acc.push({
              section,
              references: [{ ...curr, fields }],
            });
          }
        }

        return acc;
      }, [] as SectionedReference[])
      .sort((a, b) => (a.section > b.section ? 1 : -1));

    this.conditionalOperators = conditionalOperators.filter(({ operator }) =>
      checkForKeyInclusive(operator.displayText)
    );
    this.bracketOperators = bracketOperators.filter(({ operator }) =>
      checkForKeyInclusive(`${operator.displayText} ${operator.symbol}`)
    );
    this.basicOperators = basicOperators.filter(({ operator }) =>
      checkForKeyInclusive(`${operator.displayText} ${operator.symbol}`)
    );
    this.comparativeOperators = comparativeOperators.filter(({ operator }) =>
      checkForKeyInclusive(`${operator.displayText} ${operator.symbol}`)
    );
    this.dataReferences = dataReferences.filter(({ dataReference }) =>
      checkForKeyInclusive(`${dataReference.module}: ${dataReference.field}`)
    );
    this.generalConstants = generalConstants.filter(({ constant }) =>
      checkForKeyInclusive(constant.displayText)
    );
    this.customVariables = this._customVariables.filter(({ customVariable }) =>
      checkForKeyInclusive(customVariable.displayText)
    );
    this.integrationEndpoints =
      await this.endpointsService.getEndpointsWithMappings();
    this.externalData = externalData.filter((data) =>
      checkForKeyInclusive(data.externalData.displayText)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const change: SimpleChange = changes.formula;

    if (change) {
      let { previousValue, currentValue } = change;
      const currentFormula = this.getCurrentFormulaString();

      if (!previousValue || previousValue.toString().length === 0) {
        previousValue = '[]';
      }
      if (!currentValue || currentValue.toString().length === 0) {
        currentValue = '[]';
      }
      if (currentValue !== previousValue && currentValue !== currentFormula) {
        try {
          this.selectedElements = new Array(...JSON.parse(currentValue));
          this.formulaFieldElements.emit(this.selectedElements);
          this.formControllers = this.selectedElements.map((element) => {
            if (element.type === FORMULA_ELEMENT_TYPES.CONSTANT) {
              return new UntypedFormControl(
                element.constant.value,
                Validators.required
              );
            } else {
              return new UntypedFormControl(undefined, Validators.required);
            }
          });
          this.selectedElements.push({ type: FORMULA_ELEMENT_TYPES.CURSOR });
          this.formControllers.push(
            new UntypedFormControl(undefined, Validators.required)
          );
        } catch {
          this.selectedElements = [{ type: FORMULA_ELEMENT_TYPES.CURSOR }];
          this.formControllers = [
            new UntypedFormControl(undefined, Validators.required),
          ];
        }
      }
    }
  }

  selectFormulaElement(element: IFormulaElement) {
    const newElement = cloneDeep(element);

    this.pushNewElement(newElement);
  }

  selectReference(
    category: IReferenceCategoryResponse,
    field: IConfigurableFieldConfigResponse
  ) {
    const newElement: IFormulaElement = {
      type: FORMULA_ELEMENT_TYPES.REFERENCE,
      reference: {
        category: {
          ...category,
          fields: category.fields.map(
            (_field) =>
              ({
                name: _field.name,
                _id: _field._id,
                type: _field.type,
                reference_type_field_config: _field.reference_type_field_config,
              } as IConfigurableFieldConfigResponse)
          ),
        },
        field,
        filters: [],
      },
    };
    this.pushNewElement(newElement);
  }

  private pushNewElement(newElement: IFormulaElement) {
    const cursorPosition = this.cursorPosition();
    this.selectedElements = [
      ...this.selectedElements.slice(0, cursorPosition),
      newElement,
      ...this.selectedElements.slice(cursorPosition),
    ];

    this.formControllers = [
      ...this.formControllers.slice(0, cursorPosition),
      new UntypedFormControl(undefined, Validators.required),
      ...this.formControllers.slice(cursorPosition),
    ];

    this.emitFormula();
  }

  moveLeft() {
    if (!this.isMoveLeftDisabled()) {
      this.swap(-1);
    }
  }

  moveRight() {
    if (!this.isMoveRightDisabled()) {
      this.swap(+1);
    }
  }

  private swap(position: number) {
    const cursorPosition = this.cursorPosition();
    const swappingPosition = cursorPosition + position;

    const elementToBeSwapped = this.selectedElements[swappingPosition];
    this.selectedElements[swappingPosition] =
      this.selectedElements[cursorPosition];
    this.selectedElements[cursorPosition] = elementToBeSwapped;

    const formToBeSwapped = this.formControllers[swappingPosition];
    this.formControllers[swappingPosition] =
      this.formControllers[cursorPosition];
    this.formControllers[cursorPosition] = formToBeSwapped;
  }

  removeElement() {
    if (!this.isMoveLeftDisabled()) {
      const cursorPosition = this.cursorPosition();
      this.selectedElements.splice(cursorPosition - 1, 1);
      this.formControllers.splice(cursorPosition - 1, 1);

      this.emitFormula();
    }
  }

  isValidIntegrationMapping(_element: IFormulaElement): boolean {
    const element = this.integrationElement(_element);

    if (!element?.integration) return false;

    const { endpoint, mapping, params } = element.integration;

    const definitionPendingPathParams = (endpoint?.path_params ?? []).filter(
      (pathParam) => pathParam.type === ENDPOINT_PARAM_TYPES.RUNTIME_COLLECT
    );

    return (
      this.isValidIntegrationConfig(_element) &&
      mapping.return_data_type === MAPPING_RETURN_DATA_TYPES.NUMBER &&
      (definitionPendingPathParams.length === 0 ||
        definitionPendingPathParams.every((pathParam) =>
          params.find((param) => param.parameterName === pathParam.name)
        ))
    );
  }

  private isValidIntegrationConfig(_element: IFormulaElement): boolean {
    const element = this.integrationElement(_element);

    if (!element?.integration) return false;

    const { endpoint, mapping } = element.integration;

    if (!endpoint?.name || !mapping.name) return false;

    const endpointMappingKey = `${endpoint.name}-${mapping.name}`;

    if (Object.keys(this.isMappingConfigValid).includes(endpointMappingKey)) {
      return this.isMappingConfigValid[endpointMappingKey];
    }

    const isValid =
      endpoint?._id &&
      endpoint?.description &&
      endpoint?.system &&
      endpoint?.endpoint &&
      endpoint?.type &&
      endpoint?.status === ENDPOINT_STATUS.VERIFIED &&
      endpoint?.path_params &&
      endpoint?.query_params &&
      endpoint?.response_schema &&
      mapping?._id &&
      mapping?.description &&
      mapping?.return_type === MAPPING_RETURN_TYPES.DIRECT_VALUE &&
      mapping?.return_data_type === MAPPING_RETURN_DATA_TYPES.NUMBER &&
      mapping?.value_field_mapping?.length > 0 &&
      mapping?.status === MAPPING_STATUS.VERIFIED;

    this.isMappingConfigValid[endpointMappingKey] = isValid;

    return isValid;
  }

  private generateNewIntegrationMappingFormulaElement(
    endpoint: IEndpointResponseWithMappings,
    mapping: IMapping
  ): IFormulaElement {
    return {
      type: FORMULA_ELEMENT_TYPES.INTEGRATION_MAPPING,
      integration: {
        params: [],
        mapping: {
          // keep this as this longer form to avoid unwanted properties. don't shorthand.
          _id: mapping._id,
          endpoint: mapping.endpoint,
          name: mapping.name,
          description: mapping.description,
          return_type: mapping.return_type,
          return_data_type: mapping.return_data_type,
          value_field_mapping: mapping.value_field_mapping,
          key_field_mapping: mapping.key_field_mapping,
          status: mapping.status,
        },
        endpoint: {
          // keep this as this longer form to avoid unwanted properties. don't shorthand.
          _id: endpoint._id,
          name: endpoint.name,
          description: endpoint.description,
          system: endpoint.system,
          endpoint: endpoint.endpoint,
          type: endpoint.type,
          status: endpoint.status,
          path_params: endpoint.path_params,
          query_params: endpoint.query_params,
          request_schema: endpoint.request_schema,
          response_schema: endpoint.response_schema,
        },
        body: {
          assignmentType: endpoint.is_predefined_body
            ? ASSIGNMENT_TYPES.PREDEFINED_STATIC
            : ASSIGNMENT_TYPES.LOCAL,
          assignmentValue: '{}',
        },
      },
    };
  }

  integrationMappingConfigurationsValid(
    endpoint: IEndpointResponseWithMappings,
    mapping: IMapping
  ) {
    const newElement = this.generateNewIntegrationMappingFormulaElement(
      endpoint,
      mapping
    );
    return this.isValidIntegrationConfig(newElement);
  }

  selectIntegrationMapping(
    endpoint: IEndpointResponseWithMappings,
    mapping: IMapping
  ) {
    const newElement = this.generateNewIntegrationMappingFormulaElement(
      endpoint,
      mapping
    );
    this.pushNewElement(newElement);
  }

  defineCustomVariable(i: number) {
    const element = this.customVariableElement(this.selectedElements[i]);
    if (element) {
      const dialogRef = this.dialog.open(
        DialogContentDefineCustomVariableComponent,
        {
          data: element.customVariable,
        }
      );

      dialogRef.afterClosed().subscribe((config) => {
        if (config) {
          element.customVariable = {
            ...element.customVariable,
            variableName: config.variableName,
            defaultValue: config.defaultValue,
          };
          this.emitFormula();
        }
      });
    } else {
      this.snackBar.error(
        "Couldn't open Custom Variable window! Try again later"
      );
    }
  }

  defineFilters(i: number) {
    const element = this.referenceElement(this.selectedElements[i]);
    if (element) {
      const dialogRef = this.dialog.open(
        DialogContentDefineReferenceFiltersComponent,
        {
          data: {
            foreignReference: element.reference.category,
            localReference: this.referenceCategory,
            filters: element.reference.filters,
          },
          width: '100%',
        }
      );

      dialogRef.afterClosed().subscribe((filters) => {
        if (!!filters && Array.isArray(filters)) {
          element.reference.filters = filters;
          this.emitFormula();
        }
      });
    } else {
      this.snackBar.error(
        "Couldn't open add filter config window! Try again later"
      );
    }
  }

  defineIntegration(i: number) {
    const element = this.integrationElement(this.selectedElements[i]);
    if (element) {
      const data: DefineIntegrationPopupData = {
        integration: element.integration,
        localReference: this.referenceCategory,
      };

      const dialogRef = this.dialog.open(
        DialogContentDefineIntegrationComponent,
        { data }
      );

      dialogRef
        .afterClosed()
        .subscribe((params: IFormulaIntegrationElementParam[]) => {
          if (!!params && Array.isArray(params)) {
            element.integration.params = params;
            this.emitFormula();
          }
        });
    } else {
      this.snackBar.error(
        "Couldn't open add filter config window! Try again later"
      );
    }
  }

  onKeyDown(e: KeyboardEvent) {
    e.stopImmediatePropagation();

    setTimeout(() => this.emitFormula(), 100);
  }

  cursorPosition = () => this.selectedElements.findIndex(this.cursorElement);
  isMoveLeftDisabled = () => this.cursorPosition() === 0;
  isMoveRightDisabled = () =>
    this.cursorPosition() === this.selectedElements.length - 1;

  private emitFormula() {
    const formula = this.getCurrentFormulaString();
    this.onUpdateFormula.emit(formula);
  }

  private getCurrentFormulaString(): string {
    let emptyConstant = false;

    const formula = this.selectedElements
      .map((element, i) => {
        const constant = {
          type: undefined,
          value: undefined,
        };

        if (element.type === FORMULA_ELEMENT_TYPES.CONSTANT) {
          constant.type = element.constant.type;
          if (this.formControllers[i] && this.formControllers[i].value) {
            if (element.constant.type === VARIABLE_DATA_TYPES.DATE) {
              constant.value = new Date(this.formControllers[i].value);
            } else {
              constant.value = this.formControllers[i].value;
            }
          } else {
            emptyConstant = true;
          }
        }

        if (constant && constant.type && constant.value) {
          return Object.assign({}, element, { constant });
        } else {
          return element;
        }
      })
      .filter((element) => !this.cursorElement(element));

    if (emptyConstant) {
      this.setErrorFormula(true);
    } else if (formula.length > 0) {
      this.validatingFormula = true;

      this.formulaService.analyzeFormula(formula).subscribe({
        next: (response) => {
          if (response.success && response.message === SUCCESS_MESSAGE) {
            this.setErrorFormula(!response.data.isValid);
          } else {
            this.setErrorFormula(true);
          }
        },
        error: () => {
          this.setErrorFormula(true);
        },
        complete: () => {
          this.validatingFormula = false;
        },
      });
    } else {
      this.setErrorFormula(false);
    }

    return JSON.stringify(formula);
  }

  private setErrorFormula(errorFormula: boolean) {
    this.errorFormula = errorFormula;
    this.onErrorFormula.emit(errorFormula);
  }
}
