<div class="align-items-center justify-content-between d-flex">
  <div>
    <h1 mat-dialog-title class="d-flex align-items-center mb-0">
      <mat-icon class="mr-2">person</mat-icon>
      {{ 'configuration-fields.set-name.title' | translate }}
    </h1>
    <mat-card-subtitle>{{
      'configuration-fields.set-name.subtitle' | translate
    }}</mat-card-subtitle>
  </div>
</div>

<mat-divider></mat-divider>

<mat-dialog-content class="mt-4">
  <form [formGroup]="form">
    <div class="row mt-3">
      <div class="col">
        <div class="d-flex justify-content-between">
          <mat-form-field style="width: 26%" appearance="fill">
            <mat-label>{{
              'configuration-fields.set-name.salutation' | translate
            }}</mat-label>
            <mat-select
              [placeholder]="
                'configuration-fields.set-name.salutation' | translate
              "
              formControlName="salutation"
            >
              <mat-option
                *ngFor="let salutation of salutations"
                [value]="salutation"
                >{{ salutation }}</mat-option
              >
            </mat-select>
            <mat-error>{{
              'configuration-fields.set-name.error-salutation' | translate
            }}</mat-error>
          </mat-form-field>

          <mat-form-field style="width: 73%" appearance="fill">
            <mat-label>{{
              'configuration-fields.set-name.first-name' | translate
            }}</mat-label>
            <input
              id="first_name"
              type="text"
              matInput
              [placeholder]="
                'identities.create-user.first-name-placeholder' | translate
              "
              formControlName="first_name"
              pattern="^[A-Za-z]+$"
              required
            />

            <mat-error *ngIf="form.controls.first_name.hasError('required')">{{
              'configuration-fields.set-name.required-error-first-name'
                | translate
            }}</mat-error>
            <mat-error *ngIf="form.controls.first_name.hasError('pattern')">
              {{
                'configuration-fields.set-name.pattern-error-first-name'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>{{
            'configuration-fields.set-name.middle-name' | translate
          }}</mat-label>
          <input
            id="middle_name"
            type="text"
            matInput
            [placeholder]="
              'identities.create-user.mid-name-placeholder' | translate
            "
            formControlName="middle_name"
            pattern="^[A-Za-z]+$"
          />
          <mat-error *ngIf="form.controls.middle_name.hasError('pattern')">
            {{
              'configuration-fields.set-name.pattern-error-middle-name'
                | translate
            }}
          </mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>{{
            'configuration-fields.set-name.last-name' | translate
          }}</mat-label>
          <input
            id="last_name"
            type="text"
            matInput
            [placeholder]="
              'identities.create-user.last-name-placeholder' | translate
            "
            formControlName="last_name"
            pattern="^[A-Za-z]+$"
          />
          <mat-error *ngIf="form.controls.last_name.hasError('required')">{{
            'configuration-fields.set-name.required-error-last-name' | translate
          }}</mat-error>
          <mat-error *ngIf="form.controls.last_name.hasError('pattern')">{{
            'configuration-fields.set-name.pattern-error-last-name' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button
    mat-button
    class="m-2"
    [mat-dialog-close]="false"
    id="configuration-fields-set-name-cancel"
    [matTooltip]="'configuration-fields.set-name.btn-cancel' | translate"
  >
    {{ 'configuration-fields.set-name.btn-cancel' | translate }}
  </button>
  <button
    type="button"
    (click)="clearAll()"
    mat-stroked-button
    id="configuration-fields-name-clear-all-btn"
    [matTooltip]="'configuration-fields.set-name.clear-all-btn' | translate"
  >
    {{ 'configuration-fields.set-name.clear-all-btn' | translate }}
  </button>
  <button
    [disabled]="!isValid()"
    type="submit"
    mat-raised-button
    (click)="onSaveClicked()"
    color="primary"
    class="m-2"
    id="configuration-fields-set-name-save"
    [matTooltip]="'configuration-fields.set-name.btn-save' | translate"
  >
    {{ 'configuration-fields.set-name.btn-save' | translate }}
  </button>
</mat-dialog-actions>
