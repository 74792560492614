<div
  role="group"
  class="example-tel-input-container"
  [formGroup]="parts"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
>
  <input
    class="example-tel-input-element"
    aria-label="Min"
    formControlName="min"
    type="number"
    (keydown)="$event.stopImmediatePropagation()"
    [placeholder]="this.focused ? placeholders[1] : ''"
    style="margin-bottom: 1px"
    #min
    attr.data-testid="cis_number_range_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_input_min"
  />
  <span class="example-tel-input-spacer">&ndash;</span>
  <input
    class="example-tel-input-element"
    aria-label="Max"
    formControlName="max"
    type="number"
    (keydown)="$event.stopImmediatePropagation()"
    [placeholder]="this.focused ? placeholders[1] : ''"
    style="margin-bottom: 1px"
    #max
    attr.data-testid="cis_number_range_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_input_max"
  />
</div>
