import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ELAutocompleteElement } from '@el-autocomplete';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

import { CIS_CHIP_BEHAVIORS, CIS_CHIP_TYPES } from '../constants';
import { ChipInputSearchHelper } from '../helpers/chip-input-search.helper';
import {
  CISQueryParams,
  IChipSearchFilterElement,
  IChipsSearchFilterSection,
  ParamsKeyValuePair,
} from '../types';

const formatDate = (date?: Date): string | undefined => {
  if (date) return moment(date).startOf('day').toISOString();

  return undefined;
};

@Injectable({
  providedIn: 'root',
})
export class ChipInputSearchService {
  public readonly chipsData = new BehaviorSubject<IChipsSearchFilterSection[]>(
    []
  );
  public readonly filterValues = new BehaviorSubject<ParamsKeyValuePair[]>([]);
  public readonly dependableChip = new BehaviorSubject<
    IChipSearchFilterElement | undefined
  >(undefined);

  private allPossibleKeys: string[] = [];

  public readonly isLoading = new BehaviorSubject(false);
  public readonly onSearchClicked = new Subject<void>();
  public readonly onParamsChanged = new Subject<void>();
  public readonly onClearChipSearchFilter = new Subject<void>();
  public readonly searchWithoutChips = new BehaviorSubject(false);

  constructor(private chipInputSearchHelper: ChipInputSearchHelper) {}

  public resetChipSearchFilter() {
    this.allPossibleKeys = [];
    this.chipsData.next([]);
    this.filterValues.next([]);
    this.isLoading.next(false);
    this.onClearChipSearchFilter.next();
  }

  get loading() {
    return this.isLoading.value;
  }

  public setChipSearchFilterData(
    allPossibleKeys: string[],
    currentParams: CISQueryParams,
    chipsData: IChipsSearchFilterSection[],
    isSearchableWithoutChips = false
  ): void {
    if (isSearchableWithoutChips) {
      this.searchWithoutChips.next(true);
    }

    const paramsAndValuesPairs: ParamsKeyValuePair[] = [];

    Object.entries(currentParams).forEach(([key, value]) => {
      if (allPossibleKeys.includes(key)) {
        paramsAndValuesPairs.push({ key, value });
      } else {
        paramsAndValuesPairs.push({ key });
      }
    });

    this.allPossibleKeys = allPossibleKeys;
    this.filterValues.next(paramsAndValuesPairs);
    this.isLoading.next(false);
    const behaviors = [
      CIS_CHIP_BEHAVIORS.CHAINING_PARAMS,
      CIS_CHIP_BEHAVIORS.LOCK,
      CIS_CHIP_BEHAVIORS.MANDATORY,
      CIS_CHIP_BEHAVIORS.DEFAULT,
    ];

    const modifiedChipsData = chipsData.map((chipData) => {
      chipData.filters = chipData.filters.map((chipDataFilter) => {
        chipDataFilter.behavior =
          chipDataFilter.behavior ?? CIS_CHIP_BEHAVIORS.OPTIONAL;
        const { behavior, chipProperties } = chipDataFilter;

        if (behaviors.includes(behavior)) {
          switch (chipProperties.type) {
            case CIS_CHIP_TYPES.DATE:
              if (chipProperties.properties?.pickerMode === 'year') {
                const year = new Date(
                  String(currentParams[chipProperties.key])
                ).getFullYear();
                chipProperties.value = new Date(year, 0);
              } else {
                chipProperties.value = new Date(
                  String(currentParams[chipProperties.key])
                );
              }
              break;
            case CIS_CHIP_TYPES.NUMBER:
            case CIS_CHIP_TYPES.SLIDER:
              chipProperties.value = Number(currentParams[chipProperties.key]);
              break;
            case CIS_CHIP_TYPES.DATE_RANGE:
              chipProperties.minValue = new Date(
                String(currentParams[chipProperties.minKey])
              );
              chipProperties.maxValue = new Date(
                String(currentParams[chipProperties.maxKey])
              );
              break;
            case CIS_CHIP_TYPES.NUMBER_RANGE:
              chipProperties.minValue = Number(
                currentParams[chipProperties.minKey]
              );
              chipProperties.maxValue = Number(
                currentParams[chipProperties.maxKey]
              );
              break;
            case CIS_CHIP_TYPES.TEXT:
            case CIS_CHIP_TYPES.SELECT:
            case CIS_CHIP_TYPES.AUTOCOMPLETE:
            case CIS_CHIP_TYPES.FILTER_SELECT:
              chipProperties.value = String(
                currentParams[chipProperties.key] ?? ''
              );
              break;
          }
          chipDataFilter.behavior = chipDataFilter.behavior
            ? chipDataFilter.behavior
            : CIS_CHIP_BEHAVIORS.PRESELECTED;
        }
        return this.chipInputSearchHelper.setChipKeyValues(
          chipDataFilter,
          currentParams
        );
      });

      return chipData;
    });

    this.chipsData.next(modifiedChipsData);
  }

  public getFilters(
    start: number,
    size: number
  ): { httpParams: HttpParams; queryParams: any } {
    let httpParams = new HttpParams();
    const queryParams: CISQueryParams = {};
    const filterValues = this.filterValues.value;

    this.allPossibleKeys.forEach((key) => {
      queryParams[key] = undefined;
    });
    queryParams['is_search'] = 'true';
    httpParams = httpParams.append('is_search', 'true');
    httpParams = httpParams.append('start', start.toString());
    httpParams = httpParams.append('size', size.toString());

    const appendParams = (key: string, value?: string) => {
      if (key && value) {
        httpParams = httpParams.append(key, value);
        queryParams[key] = value;
      } else {
        queryParams[key] = undefined;
      }
    };

    filterValues.forEach((filter) => {
      appendParams(filter.key.toString(), filter.value?.toString());
    });

    return { httpParams, queryParams };
  }

  private convertKeyValuePairsToDropdownElements(
    keyValuePairArr: ParamsKeyValuePair[]
  ): ELAutocompleteElement[] {
    const dropDownElementArr: ELAutocompleteElement[] = [];
    keyValuePairArr.forEach((item) => {
      if (item.value) {
        dropDownElementArr.push({
          value: item.value.toString(),
          originalData: item,
        });
      }
    });

    return dropDownElementArr;
  }

  public convertStringArrayToKeyValuePairs(
    stringArr: string[] | ParamsKeyValuePair[]
  ): ParamsKeyValuePair[] {
    const KeyValuePairsArr: ParamsKeyValuePair[] = [];
    stringArr.forEach((item) => {
      if (typeof item === 'string') {
        KeyValuePairsArr.push({
          key: item,
          value: item,
        });
      } else if (typeof item === 'object') {
        KeyValuePairsArr.push({
          key: item.key,
          value: item.value,
        });
      }
    });

    return KeyValuePairsArr;
  }

  public convertChipsToFilterValues(
    chips: IChipSearchFilterElement[]
  ): ParamsKeyValuePair[] {
    const filterValues: ParamsKeyValuePair[] = [];
    chips.forEach((chip) => {
      switch (chip.chipProperties.type) {
        case CIS_CHIP_TYPES.DATE_RANGE: {
          if (chip.chipProperties.minValue && chip.chipProperties.maxValue) {
            filterValues.push({
              key: chip.chipProperties.minKey,
              value: new Date(chip.chipProperties.minValue).toISOString(),
            });
            filterValues.push({
              key: chip.chipProperties.maxKey,
              value: new Date(chip.chipProperties.maxValue).toISOString(),
            });
          }
          break;
        }

        case CIS_CHIP_TYPES.NUMBER_RANGE: {
          if (chip.chipProperties?.minValue && chip.chipProperties?.maxValue) {
            filterValues.push({
              key: chip.chipProperties.minKey,
              value: chip.chipProperties.minValue,
            });
            filterValues.push({
              key: chip.chipProperties.maxKey,
              value: chip.chipProperties.maxValue,
            });
          }
          break;
        }

        case CIS_CHIP_TYPES.DATE: {
          const date = formatDate(chip.chipProperties.value);
          filterValues.push({
            key: chip.chipProperties.key,
            value: date,
          });
          break;
        }

        default:
          filterValues.push({
            key: chip.chipProperties.key,
            value: chip.chipProperties.value?.toString(),
          });
      }
    });

    return filterValues;
  }

  setOptionsToChip(
    chip: IChipSearchFilterElement,
    key: string
  ): IChipSearchFilterElement {
    if (key) {
      // TODO: Sampath. Fix this
      //   if (
      //     chip.chipProperties.type === CIS_CHIP_DATA_TYPES.SELECT ||
      //     chip.chipProperties.type === CIS_CHIP_DATA_TYPES.AUTOCOMPLETE
      //   ) {
      //     const all_options = chip.chipProperties.properties.all_options ?? [];
      //     const val = all_options
      //       .filter((option) => option.key === key)
      //       .map((opt) => opt.value);
      //     const values: any[] = [];
      //     if (val.length) {
      //       val.forEach((ele) => {
      //         values.push(ele[0]);
      //       });
      //     } else {
      //       const arr: any[] = val.toString().split(',');
      //       arr.forEach((ele) => {
      //         values.push(ele);
      //       });
      //     }
      //     chip.chipProperties.properties.options =
      //       this.convertStringArrayToKeyValuePairs(values);
      //   } else if (
      //     chip.chipProperties.type === CIS_CHIP_DATA_TYPES.FILTER_SELECT
      //   ) {
      //     const val = chip.chipProperties.properties.all_options
      //       .filter((option) => option.key === key)
      //       .map((opt) => opt.value);
      //     const values: any[] = [];
      //     if (val.length > 1) {
      //       val.forEach((ele) => {
      //         values.push(ele[0]);
      //       });
      //     } else {
      //       const arr: any[] = val.toString().split(',');
      //       arr.forEach((ele) => {
      //         values.push(ele);
      //       });
      //     }
      //     const options = this.convertStringArrayToKeyValuePairs(values);
      //     chip.chipProperties.properties.options = [];
      //     chip.chipProperties.properties.options =
      //       this.convertKeyValuePairsToDropdownElements(options);
      //   }
    } else {
      if (
        chip.chipProperties.type === CIS_CHIP_TYPES.SELECT ||
        chip.chipProperties.type === CIS_CHIP_TYPES.AUTOCOMPLETE ||
        chip.chipProperties.type === CIS_CHIP_TYPES.FILTER_SELECT
      ) {
        chip.chipProperties.properties.options = [];
      }
    }

    return chip;
  }
}
