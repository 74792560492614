<div class="row m-1 widget__content">
  <div matRipple [matRippleCentered]="false" class="notice-box mt-1 w-100">
    <div class="heading-items d-flex">
      <div class="pr-2 date-align">
        <small class="notice-box_date skeleton-loader-item"></small>
      </div>

      <div class="pl-2 title-body-align">
        <div class="title skeleton-loader-item"></div>
        <div class="body skeleton-loader-item"></div>
      </div>
    </div>

    <div class="mt-3 date-align">
      <div class="notice-box_chart skeleton-loader-item"></div>
    </div>
  </div>
</div>
