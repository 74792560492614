export enum FORMULA_ERROR_CODES {
  ERR_INVALID_CONDITIONAL_EXPRESSION = 'ERR_INVALID_CONDITIONAL_EXPRESSION',
  ERR_INVALID_COMPARISON_EXPRESSION = 'ERR_INVALID_COMPARISON_EXPRESSION',
  ERR_INVALID_MATHEMATICAL_EXPRESSION = 'ERR_INVALID_MATHEMATICAL_EXPRESSION',
  ERR_INVALID_BRACKETS = 'ERR_INVALID_BRACKETS',
  ERR_INVALID_ELEMENT = 'ERR_INVALID_ELEMENT',
  ERR_INVALID_PARAMS = 'ERR_INVALID_PARAMS',
  ERR_INVALID_FORMULA = 'ERR_INVALID_FORMULA',
  MAX_TRIES_EXCEEDED = 'ERR_MAX_TRIES_EXCEEDED',
}

export enum FORMULA_AUTHENTICATION_TYPES {
  NONE = 'NONE',
  BASIC = 'BASIC',
  BEARER = 'BEARER',
}

export enum FORMULA_ELEMENT_TYPES {
  CURSOR = 'CURSOR',
  OPERATOR = 'OPERATOR',
  CONSTANT = 'CONSTANT',
  REFERENCE = 'REFERENCE',
  DATA_REFERENCE = 'DATA_REFERENCE',
  INTEGRATION_MAPPING = 'INTEGRATION_MAPPING',
  EXTERNAL_DATA = 'EXTERNAL_DATA',
  CUSTOM_VARIABLE = 'CUSTOM_VARIABLE',
}

export enum OPERATOR_TYPES {
  BASIC = 'BASIC',
  COMPARATIVE = 'COMPARATIVE',
  LOGICAL = 'LOGICAL',
  CONDITIONAL = 'CONDITIONAL',
}

export enum VARIABLE_DATA_TYPES {
  // Be careful when adding new types here. Because it will break FormulaService
  NUMBER = 'Number',
  TEXT = 'Text',
  DATE = 'Date',
}

export enum DATA_REFERENCE_MODULES {
  ADVISOR = 'ADVISOR',
}

export enum EXTERNAL_DATA_TYPES {
  FORM_DATA_LOAN_AMOUNT = 'Form Data: Loan Amount',
  FORM_DATA_CUSTOMER_AGE = 'Form Data: Customer Age',
  FORM_DATA_CUSTOMER_GENDER = 'Form Data: Customer Gender',
}

export enum COMPARE_WITH {
  DIRECT = 'direct',
  LOCAL = 'local',
}

export enum ASSIGNMENT_TYPES {
  DIRECT = 'direct',
  LOCAL = 'local',
  RUNTIME = 'runtime',
  PREDEFINED_DYNAMIC = 'predefined_dynamic',
  PREDEFINED_STATIC = 'predefined_static',
}

export enum RUNTIME_COLLECTABLE_FIELDS {
  AGENT_CODE = 'agent_code',
  MIN_DATE = 'min_date',
  MAX_DATE = 'max_date',
}
