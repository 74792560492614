import { ELAutocompleteElement } from '../types';

export enum EL_AUTOCOMPLETE_INTERNAL_ELEMENTS {
  DEFAULT_ALL = 'DEFAULT_ALL',
}

export const SELECT_ALL_ELEMENT: ELAutocompleteElement = {
  value: 'All',
  originalData: EL_AUTOCOMPLETE_INTERNAL_ELEMENTS.DEFAULT_ALL,
};
