import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { Subject, Subscription, takeUntil } from 'rxjs';

import {
  IConfigurableFieldConfigResponse,
  IConfigurableFieldValue,
  IConfigurableFieldValueResponse,
  IConnectedAppResponse,
  IIdentityResponse,
} from '@shared/interfaces';

import { LoggedUserService } from '../../../modules/auth/services';
import {
  DisplayConfigurableFieldService,
  IFieldValidationResults,
} from '../../../modules/configurable-fields/services';
import { IDisplayConfigurableFieldElement } from '../../../modules/configurable-fields/types';
import { ConnectedAppsService } from '../../../modules/connected-apps/services/connected-apps.service';
import {
  ConnectionsService,
  IConnectionResponse,
} from '../../../modules/connections/services/connections.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../../services/snackbar.service';

export interface ExternalDataFields {
  metadata: IConfigurableFieldConfigResponse;
  form: UntypedFormControl;
}

@Component({
  selector: 'app-connections-data-fields',
  templateUrl: './connections-data-fields.component.html',
  styleUrls: ['./connections-data-fields.component.scss'],
})
export class ConnectionsDataFieldsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() data: IConnectionResponse<IConnectedAppResponse, IIdentityResponse>;
  @Input() total: number;

  isEmptyDataFields = true;

  loading = true;
  isSuccess = false;
  subscriptions = new Subscription();
  ImageUrl = '';

  internal_fields_display: IDisplayConfigurableFieldElement[];
  internalFields: IConfigurableFieldValue[] = [];
  internalFieldsDataValidation: IFieldValidationResults = {
    isValid: false,
  };

  isInternalFieldsChanged = false;
  isInternalFieldsInValid = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private snackBar: SnackbarService,
    private connectedAppsService: ConnectedAppsService,
    private loggedUserService: LoggedUserService,
    private displayConfigurableFieldService: DisplayConfigurableFieldService,
    private connectionsService: ConnectionsService
  ) {}

  ngOnInit(): void {
    this.ImageUrl = `${this.data.app.client_url}/${this.data.app.general.logo_path_light}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      if (changes.data.isFirstChange) {
        this.handshake(changes.data.currentValue);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  validateAdditionalFields(): void {
    if (this.internal_fields_display.length > 0) {
      this.internal_fields_display.forEach((field) => {
        if (field.field_value.invalid) {
          this.isInternalFieldsInValid = true;
        }
      });
      !this.isInternalFieldsChanged &&
        (this.isInternalFieldsChanged = this.internal_fields_display.some(
          (field) => {
            if (!field.field_value.pristine) {
              return true;
            }
            return false;
          }
        ));
    }
  }

  handshake(
    connection: IConnectionResponse<IConnectedAppResponse, IIdentityResponse>
  ): void {
    this.loading = true;
    this.connectedAppsService
      .getAllFields(connection.app._id.toString())
      .then((res) => {
        (async () => {
          if (res.length > 0) {
            // get additional fields
            const displayInternalFields =
              await this.displayConfigurableFieldService.generateFormFields(
                cloneDeep(res),
                { app: connection.app._id.toString() },
                this.onDestroy$,
                this.data.data_fields as IConfigurableFieldValueResponse[]
              );
            this.internal_fields_display = displayInternalFields.fields;

            //set internal fields for display
            this.internalFields.forEach((identity_fields) => {
              const foundField = this.internal_fields_display.find(
                (field) =>
                  field.configuration?._id?.toString() ===
                  identity_fields?.field_id?.toString()
              );
              if (foundField) {
                foundField.field_value.setValue(identity_fields.value);
                foundField.field_value.updateValueAndValidity();
              }
            });

            //internal fields validations
            this.internalFieldsDataValidation =
              await this.displayConfigurableFieldService.validateAndGetValueOfConfigurableFields(
                this.internal_fields_display
              );
            this.internal_fields_display.forEach((field) => {
              field.field_value.valueChanges
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(async () => {
                  this.isInternalFieldsInValid = false;
                  this.internalFieldsDataValidation =
                    await this.displayConfigurableFieldService.validateAndGetValueOfConfigurableFields(
                      this.internal_fields_display
                    );
                  this.validateAdditionalFields();
                  this.isInternalFieldsInValid =
                    !this.isAllFormsValid() || this.isInternalFieldsInValid;
                });
            });
            displayInternalFields.validation
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((validation) => {
                this.internalFieldsDataValidation = validation;
              });

            this.isEmptyDataFields = false;
          } else {
            this.isEmptyDataFields = true;
          }
          this.loading = false;
          this.isSuccess = true;
        })();
      })
      .catch(() => {
        this.loading = false;
      });
  }

  private isAllFormsValid(): boolean {
    return this.internalFieldsDataValidation.isValid;
  }

  onSaveDataFields(): void {
    const internalFieldData: IConfigurableFieldValue[] =
      this.internal_fields_display.map((field) => {
        return {
          field_id: field.configuration._id,
          value: field.field_value.value,
        };
      });
    const data = {
      _id: this.data._id,
      app: this.data.app._id.toString(),
      fields: internalFieldData,
    };
    this.connectionsService.updateUserConnections(data).subscribe({
      next: () => {
        this.snackBar.success(SUCCESS_TYPES.SAVED);
        this.loggedUserService.getLatestUserProfile();
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}
