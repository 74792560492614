<div *ngIf="isLoading" class="loading-overlay" style="padding-right: 150px">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<mat-card
  class="row mat-elevation-z2 quick-actions-panel primary-bg"
  style="margin-left: 0px; margin-right: 0px"
>
  <mat-card-content>
    <div class="container">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3 d-flex justify-content-center position-relative">
            <div
              data-testid="profile_btn_change_profile_picture"
              class="profile-pic__container"
              (click)="openFile()"
              [matTooltip]="'pages.profile.upload-profile-pic' | translate"
            >
              <img
                fetchpriority="high"
                class="profile-pic"
                [src]="imageUrl"
                alt="profile-pic"
              />
              <mat-icon class="profile-pic__icon">add_a_photo</mat-icon>
            </div>
          </div>
          <div class="col-md-9 chip" style="position: relative">
            <p class="content-white" style="margin-bottom: 5px">
              {{ loggedUserData?.first_name }}&nbsp;{{
                loggedUserData?.last_name
              }}
            </p>
            <div>
              <mat-chip-list *ngIf="loggedUserData?.connection?.role_name">
                <mat-chip style="background-color: #0dd50d; color: white">
                  {{ loggedUserData.connection.role_name }}
                </mat-chip>
              </mat-chip-list>
            </div>
            <div class="content-white my-2" style="display: inline-flex">
              <div>
                <div
                  class="profile-section"
                  [matTooltip]="'pages.profile.last-login-at' | translate"
                >
                  <ng-container *ngIf="lastLoginActivity.timestamp">
                    <mat-icon style="font-size: 20px">login</mat-icon>
                    {{ lastLoginActivity.timestamp }}&nbsp;
                  </ng-container>
                </div>
                <div
                  class="profile-section"
                  [matTooltip]="'pages.profile.last-login-from' | translate"
                >
                  <ng-container
                    *ngIf="
                      lastLoginActivity.city &&
                      lastLoginActivity.country &&
                      lastLoginActivity.country_code
                    "
                  >
                    <mat-icon style="font-size: 20px; width: 20px"
                      >location_on</mat-icon
                    >{{ lastLoginActivity.city }},
                    {{ lastLoginActivity.country }} &nbsp;<span
                      class="fi fi-{{
                        lastLoginActivity.country_code.toLowerCase()
                      }}"
                    ></span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div class="container pt-3">
  <div class="row">
    <div class="col-12 col-md-7 mb-3">
      <div style="margin-top: 15px">
        <mat-accordion multi>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-card-title
                mat-dialog-title
                class="d-flex align-items-center mb-0"
              >
                <mat-icon class="mr-2">account_circle</mat-icon>
                <span>{{
                  'pages.profile.personal-information' | translate
                }}</span>
              </mat-card-title>
            </mat-expansion-panel-header>
            <mat-card-subtitle
              style="margin-top: -18px; position: relative; z-index: 999"
              >{{ 'pages.profile.subtitle' | translate }}</mat-card-subtitle
            >
            <mat-divider></mat-divider>
            <div class="mt-4">
              <form [formGroup]="profileFormGroup">
                <div class="d-flex justify-content-between">
                  <mat-form-field style="width: 25%" appearance="fill">
                    <mat-label>{{
                      'pages.profile.salutation' | translate
                    }}</mat-label>
                    <mat-select
                      data-testid="profile_input_select_salutation"
                      [placeholder]="'pages.profile.salutation' | translate"
                      formControlName="salutation"
                    >
                      <mat-option
                        attr.data-testid="profile_sel_salutation_option_{{
                          salutation
                            .toLowerCase()
                            .replaceAll(' ', '_')
                            .replaceAll('-', '_')
                            .replaceAll('.', '')
                        }}"
                        *ngFor="let salutation of salutations"
                        [value]="salutation"
                        >{{ salutation }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field style="width: 72%" appearance="fill">
                    <mat-label>{{
                      'pages.profile.first-name' | translate
                    }}</mat-label>
                    <input
                      data-testid="profile_input_first_name"
                      id="first_name"
                      type="text"
                      matInput
                      [disabled]="!hasEditPermission"
                      [placeholder]="'pages.profile.first-name' | translate"
                      formControlName="first_name"
                      required
                      pattern="[ a-zA-Z]*"
                    />
                    <mat-icon matSuffix>person</mat-icon>
                    <mat-error
                      *ngIf="
                        profileFormGroup.controls.first_name.hasError(
                          'required'
                        ) ||
                        profileFormGroup.controls.first_name.hasError(
                          'whitespace'
                        )
                      "
                      >{{
                        'pages.profile.first-name-validation' | translate
                      }}</mat-error
                    >
                    <mat-error
                      *ngIf="
                        profileFormGroup.controls.first_name.hasError('pattern')
                      "
                      >{{
                        'pages.profile.invalid-characters-error' | translate
                      }}</mat-error
                    >
                  </mat-form-field>
                </div>

                <mat-form-field style="width: 100%" appearance="fill">
                  <mat-label>{{
                    'pages.profile.last-name' | translate
                  }}</mat-label>
                  <input
                    data-testid="profile_input_last_name"
                    id="last_name"
                    type="text"
                    matInput
                    [placeholder]="'pages.profile.last-name' | translate"
                    formControlName="last_name"
                    pattern="[ a-zA-Z]*"
                  />
                  <mat-icon matSuffix>person</mat-icon>
                  <mat-error
                    *ngIf="
                      profileFormGroup.controls.last_name.hasError('pattern')
                    "
                    >{{
                      'pages.profile.invalid-characters-error' | translate
                    }}</mat-error
                  >
                </mat-form-field>

                <mat-form-field style="width: 100%" appearance="fill">
                  <mat-label
                    >{{ 'pages.profile.email' | translate }}
                  </mat-label>
                  <input
                    data-testid="profile_input_email"
                    id="email"
                    type="text"
                    matInput
                    [placeholder]="'pages.profile.email' | translate"
                    formControlName="email"
                  />
                  <mat-icon matSuffix>email</mat-icon>

                  <mat-error
                    *ngIf="profileFormGroup.controls.email.hasError('email')"
                    >{{ 'pages.profile.email-invalid' | translate }}
                  </mat-error>
                </mat-form-field>

                <el-multi-field
                  inputFieldId="profile_input_phone"
                  btnId="profile_btn_set_phone_number"
                  [title]="'Phone Number'"
                  [placeholder]="'Phone Number'"
                  [required]="false"
                  [isConstant]="false"
                  [data]="phoneNumber"
                  [disabled]="!hasEditPermission"
                  (onSetValue)="onOtherValueEmitted($event)"
                ></el-multi-field>

                <mat-form-field style="width: 100%" appearance="fill">
                  <mat-label>{{
                    'pages.profile.username' | translate
                  }}</mat-label>
                  <input
                    data-testid="profile_input_username"
                    id="username"
                    type="text"
                    matInput
                    [placeholder]="'pages.profile.username' | translate"
                    formControlName="username"
                  />
                  <mat-icon matSuffix>person</mat-icon>
                  <mat-error
                    *ngIf="
                      profileFormGroup.controls.username.hasError('required')
                    "
                    >{{
                      'pages.profile.username-required' | translate
                    }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      profileFormGroup.controls.username.hasError('pattern')
                    "
                  >
                    {{
                      'identities.create-user.username-invalid-error'
                        | translate
                    }}
                  </mat-error>
                </mat-form-field>
                <!--only for the IAM-->
                <div
                  *ngIf="
                    internalFields?.length > 0 &&
                    IAMConfig?.iam?.status !== 'EXTERNAL'
                  "
                >
                  <strong>{{
                    'pages.profile.additional-information' | translate
                  }}</strong>
                  <hr style="margin-top: 0.51rem !important" />
                  <app-display-configurable-field
                    class="w-100"
                    *ngIf="internalFields.length > 0"
                    [fieldArray]="internal_fields_display"
                  ></app-display-configurable-field>
                </div>
                <!--only for the CLIENT APPS-->
                <div
                  *ngIf="
                    externalFields?.length > 0 &&
                    this.IAMConfig?.iam?.status === 'EXTERNAL'
                  "
                >
                  <strong>{{
                    'pages.profile.additional-information' | translate
                  }}</strong>
                  <hr style="margin-top: 0.51rem !important" />
                  <app-display-configurable-field
                    class="w-100"
                    *ngIf="externalFields.length > 0"
                    [fieldArray]="external_fields_display"
                  ></app-display-configurable-field>
                </div>
              </form>
            </div>
            <div class="d-flex justify-content-end p-2 pt-3">
              <button
                data-testid="profile_btn_save_changes"
                [disabled]="
                  !hasEditPermission ||
                  profileFormGroup.invalid ||
                  isInternalFieldsInValid ||
                  isExternalFieldsInValid ||
                  (!isInternalFieldsPristine &&
                    !isExternalFieldsChanged &&
                    profileFormGroup.pristine)
                "
                mat-raised-button
                color="accent"
                type="submit"
                (click)="onSubmitProfileChange()"
                [matTooltip]="'pages.profile.save' | translate"
              >
                {{ 'pages.profile.save' | translate }}
              </button>
            </div>
          </mat-expansion-panel>
          <!-- <div
            *ngIf="
              internalFields &&
              internalFields.length > 0 &&
              IAMConfig?.iam?.status === 'EXTERNAL'
            "
          >
            <mat-card *ngFor="let field of internalFields">
              {{ field.value }}
            </mat-card>
          </div> -->
          <!--for IAM or Self-->
          <div
            *ngIf="
              loggedUserData?.all_connections &&
              loggedUserData?.all_connections.length > 0
            "
          >
            <app-connections-data-fields
              *ngFor="let connection of loggedUserData.all_connections"
              [data]="connection"
              [total]="loggedUserData.all_connections.length"
            ></app-connections-data-fields>
          </div>
        </mat-accordion>
      </div>
    </div>

    <div class="col-12 col-md-5 mb-3">
      <mat-card>
        <mat-card-title-group>
          <mat-card-title>
            <h6>
              <strong>{{ 'pages.profile.help' | translate }}</strong>
            </h6>
          </mat-card-title>
          <mat-card-subtitle>{{
            'pages.profile.help-and-sup-description' | translate
          }}</mat-card-subtitle>
        </mat-card-title-group>

        <mat-divider></mat-divider>

        <mat-card-content>
          <mat-accordion multi="true">
            <mat-expansion-panel style="margin-top: 14px" expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>account_circle</mat-icon>
                  <strong class="ml-2">{{
                    'pages.profile.your-account' | translate
                  }}</strong>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                {{ 'pages.profile.your-account-description' | translate }}
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel expanded="true" *ngIf="hasSelfResetPassword">
              <mat-expansion-panel-header>
                <mat-panel-title
                  ><mat-icon>vpn_key</mat-icon
                  ><strong class="ml-2">{{
                    'pages.profile.reset-pwd-title' | translate
                  }}</strong>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                {{
                  'pages.profile.reset-password-side-description' | translate
                }}
              </p>
              <div class="row d-flex justify-content-end">
                <button
                  data-testid="profile_btn_reset_password"
                  mat-raised-button
                  color="primary"
                  routerLink="/app/profile/reset-password"
                  [matTooltip]="'pages.profile.reset-pwd-btn' | translate"
                >
                  {{ 'pages.profile.reset-pwd-btn' | translate }}
                </button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
