import {
  DAYS_IN_ONE_MONTH,
  HOURS_IN_ONE_DAY,
  MINUTES_IN_ONE_HOUR,
  MONTHS_IN_ONE_YEAR,
  ONE_DAY_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_MINUTE_IN_SECONDS,
  ONE_MONTH_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
} from '@shared/constants';

export interface IOutputSuffixes {
  seconds?: string;
  minutes?: string;
  hours?: string;
  days?: string;
  months?: string;
  years?: string;
}

export const calculateElapsedTime = (
  date: Date,
  outputSuffixes?: IOutputSuffixes
) => {
  const currentTime = new Date();
  const timeDiff = currentTime.getTime() - new Date(date).getTime();
  const elapsedTime = Math.floor(timeDiff / 1000);

  if (elapsedTime < ONE_MINUTE_IN_SECONDS) {
    return `${elapsedTime} ${outputSuffixes?.seconds || 'seconds ago'}`;
  } else if (
    Math.floor(elapsedTime / ONE_MINUTE_IN_SECONDS) < MINUTES_IN_ONE_HOUR
  ) {
    return `${Math.floor(elapsedTime / ONE_MINUTE_IN_SECONDS)} ${
      outputSuffixes?.minutes || 'minutes ago'
    }`;
  } else if (Math.floor(elapsedTime / ONE_HOUR_IN_SECONDS) < HOURS_IN_ONE_DAY) {
    return `${Math.floor(elapsedTime / ONE_HOUR_IN_SECONDS)} ${
      outputSuffixes?.hours || 'hours ago'
    }`;
  } else if (Math.floor(elapsedTime / ONE_DAY_IN_SECONDS) < DAYS_IN_ONE_MONTH) {
    return `${Math.floor(elapsedTime / ONE_DAY_IN_SECONDS)} ${
      outputSuffixes?.days || 'days ago'
    }`;
  } else if (
    Math.floor(elapsedTime / ONE_MONTH_IN_SECONDS) < MONTHS_IN_ONE_YEAR
  ) {
    return `${Math.floor(elapsedTime / ONE_MONTH_IN_SECONDS)} ${
      outputSuffixes?.months || 'months ago'
    }`;
  } else {
    return `${Math.floor(elapsedTime / ONE_YEAR_IN_SECONDS)} ${
      outputSuffixes?.years || 'years ago'
    }`;
  }
};
