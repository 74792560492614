import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

import {
  ENDPOINTS,
  NOTIFICATION_ACTION_TYPES,
  REQUEST_KEYS,
  RESPONSE_KEYS,
} from '@shared/constants';
import {
  CommonResponseDTO,
  INotification,
  IUserNotification,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

import { environment } from '../../../../../environments/environment';

export interface GetNotificationDTO {
  count: number;
  data: INotification[];
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private socket: Socket,
    private http: HttpClient,
    private router: Router
  ) {}

  // request handlers
  fetchNotifications(): void {
    this.socket.emit(REQUEST_KEYS.GET_ALL_NOTIFICATIONS);
  }

  onUpdateNotifications(id: string): void {
    this.socket.emit(REQUEST_KEYS.UPDATE_NOTIFICATION, id);
  }

  getNotification(_id: string): Observable<CommonResponseDTO<INotification>> {
    const url = generateURL({
      endpoint: ENDPOINTS.NOTIFICATION_GET,
      params: { id: _id },
    });

    return this.http.get<CommonResponseDTO<INotification>>(url);
  }

  getUserNotifications(
    params: HttpParams,
    id: string
  ): Observable<CommonResponseDTO<IUserNotification<string>[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.NOTIFICATION_GET_USER_NOTIFICATIONS,
      params: { id },
    });

    return this.http.get<CommonResponseDTO<IUserNotification<string>[]>>(url, {
      params,
    });
  }

  // response handlers
  onFetchNotifications(): Observable<IUserNotification<INotification>[]> {
    return this.socket.fromEvent(RESPONSE_KEYS.ALL_NOTIFICATIONS);
  }

  onCountNotifications(): Observable<number> {
    return this.socket.fromEvent(RESPONSE_KEYS.COUNT);
  }

  onStorageConfigChange(): Observable<boolean> {
    return this.socket.fromEvent(RESPONSE_KEYS.STORAGE_CONFIG);
  }

  onIamConfigChange(): Observable<boolean> {
    return this.socket.fromEvent(RESPONSE_KEYS.IAM_CONFIG);
  }

  public markAsRead(
    id: string
  ): Observable<CommonResponseDTO<IUserNotification<INotification>> | null> {
    const url = generateURL({
      endpoint: ENDPOINTS.NOTIFICATIONS_MARK_AS_READ,
      params: {
        id,
      },
    });

    return this.http.patch<CommonResponseDTO<
      IUserNotification<INotification>
    > | null>(url, {});
  }

  public markAllAsRead(): Observable<CommonResponseDTO<void>> {
    const url = generateURL({
      endpoint: ENDPOINTS.NOTIFICATIONS_MARK_ALL_AS_READ,
    });

    return this.http.patch<CommonResponseDTO<void>>(url, {});
  }

  public getAllUserNotifications(
    params: HttpParams
  ): Observable<CommonResponseDTO<IUserNotification<INotification>[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.NOTIFICATION_FILTER,
    });
    const config = { params };
    return this.http.get<CommonResponseDTO<IUserNotification<INotification>[]>>(
      url,
      config
    );
  }

  openNotification(notification: INotification): void {
    this.markAsRead(notification._id.toString()).subscribe();

    switch (notification.action.type) {
      case NOTIFICATION_ACTION_TYPES.OPEN_URL: {
        const action = notification.action;
        if (action.data.internal) {
          if (action.data.same_tab) {
            this.router.navigateByUrl(action.data.url);
          } else {
            window.open(`${environment.WEB_URL}/${action.data.url}`, '_blank');
          }
        } else {
          if (action.data.same_tab) {
            window.location.replace(action.data.url);
          } else {
            window.open(action.data.url, '_blank');
          }
        }
        break;
      }

      case NOTIFICATION_ACTION_TYPES.VIEW_MESSAGE: {
        const action = notification.action;

        this.router.navigate(['/app/messages'], {
          queryParams: {
            _id: action.data._id,
            is_search: true,
          },
        });
        break;
      }

      case NOTIFICATION_ACTION_TYPES.PREVIEW_DMS_ENTITY: {
        const action = notification.action;

        this.router.navigate(['/app/storage/drives/preview'], {
          queryParams: {
            storage_id: action.data.storage_id,
            entity_id: action.data.entity_id,
            file_type: action.data.file_type,
          },
        });
        break;
      }

      case NOTIFICATION_ACTION_TYPES.STORAGE_QUOTA_EXCEEDED: {
        const action = notification.action;

        this.router.navigate(['/app/storage/drives'], {
          queryParams: {
            url: action.data.domain,
            is_search: true,
          },
        });
        break;
      }

      case NOTIFICATION_ACTION_TYPES.USER_QUOTA_EXCEEDED: {
        const action = notification.action;

        this.router.navigate(['/app/storage/drives'], {
          queryParams: {
            url: action.data.domain,
            is_search: true,
          },
        });
        break;
      }

      default:
        break;
    }
  }
}
