import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import {
  CommonResponseDTO,
  IApiKey,
  IDeletedApiKey,
  IDeletedClientSecret,
  IPublicKeyResponse,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ConnectedAppsKeysService {
  constructor(private http: HttpClient) {}

  generatePublicApiKey(
    appId: string
  ): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_GENERATE_PUBLIC_API_KEY,
      params: { id: appId },
    });
    return this.http.get<CommonResponseDTO<IPublicKeyResponse>>(url);
  }

  generatePublicClientCredential(
    appId: string
  ): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_GENERATE_PUBLIC_CLIENT_CREDENTIALS,
      params: { id: appId },
    });
    return this.http.get<CommonResponseDTO<IPublicKeyResponse>>(url);
  }

  saveGeneratedPublicApiKey({
    appId,
    key,
    secret,
    reference,
    origins,
    remarks,
  }: {
    appId: string;
    key?: string;
    secret?: string;
    reference: string;
    origins: string[];
    remarks?: string;
  }): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_SAVE_GENERATED_PUBLIC_KEY,
      params: { id: appId },
    });
    return this.http.post<CommonResponseDTO<IPublicKeyResponse>>(url, {
      apiKey: key,
      apiSecret: secret,
      reference,
      origins,
      remarks,
    });
  }

  saveDeletedApiKey(
    appId: string,
    key: string,
    referer: string
  ): Observable<CommonResponseDTO<IDeletedApiKey>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_SAVE_DELETED_PUBLIC_KEY,
      params: { id: appId },
    });
    return this.http.post<CommonResponseDTO<IDeletedApiKey>>(url, {
      key,
      referer,
    });
  }

  saveDeletedClientSecret(
    appId: string,
    secret: string,
    referer: string
  ): Observable<CommonResponseDTO<IDeletedClientSecret>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_SAVE_DELETED_CLIENT_SECRET,
      params: { id: appId },
    });
    return this.http.post<CommonResponseDTO<IDeletedClientSecret>>(url, {
      secret,
      referer,
    });
  }

  updatePublicApiKeyOrSecret({
    appId,
    id,
    key,
    secret,
    origins,
    remarks,
  }: {
    appId: string;
    id: string;
    key?: string;
    secret?: string;
    origins: string[];
    remarks: string;
  }): Observable<CommonResponseDTO<IApiKey>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_UPDATE_GENERATED_PUBLIC_KEY,
      params: { id, appId },
    });
    return this.http.patch<CommonResponseDTO<IApiKey>>(url, {
      key,
      secret,
      origins,
      remarks,
    });
  }

  getGeneratedKey(
    id: string,
    appId: string
  ): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_GET_SPECIFIC_GENERATED_KEY,
      params: { appId, id },
    });
    return this.http.get<CommonResponseDTO<IPublicKeyResponse>>(url);
  }

  getGeneratedKeys(appId: string): Observable<CommonResponseDTO<IApiKey[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_GET_ALL_GENERATED_KEYS,
      params: { id: appId },
    });
    return this.http.get<CommonResponseDTO<IApiKey[]>>(url);
  }

  deleteKey(appId: string, id: string): Observable<CommonResponseDTO<IApiKey>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_DELETE_KEY,
      params: { appId, id },
    });
    return this.http.delete<CommonResponseDTO<IApiKey>>(url);
  }

  restoreKey(
    appId: string,
    id: string
  ): Observable<CommonResponseDTO<IApiKey>> {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTED_APPS_RESTORE_KEY,
      params: { appId, id },
    });
    return this.http.patch<CommonResponseDTO<IApiKey>>(url, {});
  }
}
