import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ShortcutInput } from 'ng-keyboard-shortcuts';

export interface IActionButton {
  action: string;
  icon: string;
  tooltip: string;
  loading?: boolean;
  id?: string;
  dataTestId?: string;
}

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent implements AfterViewInit {
  @Input() buttons: IActionButton[] = [];
  @Input() rightAlign = 14;
  @Input() disabled = false;
  @Output() clickAction = new EventEmitter<string>();

  shortcuts: ShortcutInput[] = [];

  ngAfterViewInit() {
    this.shortcuts.push({
      key: ['alt + e'],
      label: 'Expand/Collapse Speed Dial',
      description: 'Alt + e',
      command: () => this.clickOnSpeedDial(),
      preventDefault: true,
    });
  }

  onClickAction(e: string) {
    this.clickAction.emit(e);
  }

  clickOnSpeedDial() {
    document.getElementById('speedDialButton').click();
  }
}
