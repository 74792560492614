import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
  MODULES,
  PERMISSION_ACTIONS,
  SPECIAL_PERMISSION_ACTIONS,
} from '@shared/constants';
import { INotification, IUserNotification } from '@shared/interfaces';
import { calculateElapsedTime, generatePermissionKey } from '@shared/utils';

import { UserInfoResponseDTO } from '../../../modules/auth/services';
import { ColorSchemeService } from '../../../modules/setup/appearance/services/color-scheme.service';
import { NotificationConfigService } from '../../../modules/setup/notifications/services/notification-config.service';
import { NotificationsService } from '../../../modules/setup/notifications/services/notifications.service';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
})
export class NotificationPanelComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() user: UserInfoResponseDTO;
  @Input() mobileView: boolean;
  notificationEnable = false;
  hasEnoughPermissions = false;
  notificationCount = 0;
  userNotifications: IUserNotification<INotification>[] = [];
  onFetchNotificationsSubscription: Subscription;
  onCountNotificationsSubscription: Subscription;
  notificationsConfigSubscription: Subscription;
  isLoading = false;

  constructor(
    private notificationConfigService: NotificationConfigService,
    private notificationService: NotificationsService,
    private router: Router,
    public colorSchemeService: ColorSchemeService
  ) {}

  ngOnInit(): void {
    this.initNotifications();
    this.onFetchNotificationsSubscription = this.notificationService
      .onFetchNotifications()
      .subscribe((userNotifications: IUserNotification<INotification>[]) => {
        this.userNotifications = userNotifications.filter(
          (notification) => notification.identity === this.user._id
        );
        this.notificationCount = this.userNotifications.length;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user?.currentValue) {
      this.manageNotifications(changes?.user?.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.onFetchNotificationsSubscription?.unsubscribe();
    this.onCountNotificationsSubscription?.unsubscribe();
    this.notificationsConfigSubscription?.unsubscribe();
  }

  initNotifications(): void {
    this.notificationsConfigSubscription =
      this.notificationConfigService.dataStore.subscribe((data) => {
        this.notificationEnable = data?.isNotificationEnable || false;
        // call this from both notificationEnable assigning places because it depends on both
        this.manageNotifications(this.user);
      });
  }

  private manageNotifications(user?: UserInfoResponseDTO) {
    if (
      user &&
      this.notificationEnable &&
      (user.connection.permissions.includes(
        generatePermissionKey(MODULES.NOTIFICATIONS, PERMISSION_ACTIONS.READ)
      ) ||
        user.connection.permissions.includes(
          SPECIAL_PERMISSION_ACTIONS.VIEW_ALL_NOTIFICATION
        ))
    ) {
      this.hasEnoughPermissions = true;
      this.notificationService.fetchNotifications();
    } else {
      this.hasEnoughPermissions = false;
    }
  }

  calculateNotificationTime(date: Date) {
    return calculateElapsedTime(date);
  }

  onClickNotification(userNotification: IUserNotification<INotification>) {
    const notification = userNotification.notification;
    this.notificationService.openNotification(notification);
  }

  markAllAsRead(): void {
    this.isLoading = true;
    this.notificationService.markAllAsRead().subscribe({
      next: () => {
        this.isLoading = false;
        this.notificationCount = 0;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  viewAllNotifications() {
    this.router.navigate(['/app/notifications'], {
      queryParams: { is_search: true },
    });
  }
}
