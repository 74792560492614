import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { WIDGET_SIZES } from '@shared/constants';
import { IWidget } from '@shared/interfaces';

@Component({
  selector: 'app-menu-item',
  templateUrl: 'menu-item.component.html',
  styleUrls: ['menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input() widget: IWidget & { isSelected?: boolean };
  @Input() isDisabled: boolean;
  @Output() onAddWidget: EventEmitter<IWidget> = new EventEmitter();
  @Output() onRemoveWidget: EventEmitter<IWidget> = new EventEmitter();

  @ViewChild('widgetTitle') widgetTitle: ElementRef;

  WIDGET_SIZES = WIDGET_SIZES;

  showIcon: boolean;

  addOrRemoveWidget(widget: IWidget & { isSelected?: boolean }) {
    if (widget.isSelected) {
      this.onRemoveWidget.emit(widget);
    } else {
      this.onAddWidget.emit(widget);
    }
  }
}
