import { Injectable } from '@angular/core';

export interface MenuItem {
  key: string;
  searchKey: string;
  icon: string;
  face: string;
  tooltip?: string;
  link: string;
  loading?: boolean;
  type: 'link' | 'group' | 'section' | 'button';
  subRoutings?: string[];
  children?: MenuItem[];
  expanded?: boolean;
  allowedPermissions?: string[];
  params?: any;
}

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  visibleItems: MenuItem[] = [];

  addToVisibleItems(menuArray: MenuItem[]) {
    this.visibleItems = menuArray;
  }

  getVisibleItems() {
    return this.visibleItems;
  }
}
