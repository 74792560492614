/* eslint-disable @angular-eslint/no-host-metadata-property */
import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

import { CIS_CHIP_BEHAVIORS } from '../../../constants';
import { IChipSearchFilterElement } from '../../../types';
import { NumberRangeChipProperties } from '../../../types/chip-properties/number-range';

export class INumberRange {
  constructor(
    public minValue?: number | undefined,
    public maxValue?: number | undefined
  ) {}
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-cis-numeric-range-form-field-control',
  templateUrl: 'numeric-range-form-field-control.component.html',
  styleUrls: ['numeric-range-form-field-control.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: NumericRangeFormFieldControlComponent,
    },
  ],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class NumericRangeFormFieldControlComponent
  implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit
{
  static nextId = 0;

  @Input() selectedData?: IChipSearchFilterElement;
  @Input() placeholders: string[] = [];

  @Output() focused = new EventEmitter<boolean>(false);

  @ViewChild('min') minInput?: ElementRef<HTMLInputElement>;
  @ViewChild('max') maxInput?: ElementRef<HTMLInputElement>;

  parts = new FormGroup({
    minValue: new FormControl<number | undefined>(
      undefined,
      Validators.required
    ),
    maxValue: new FormControl<number | undefined>(
      undefined,
      Validators.required
    ),
  });
  private stateChanges = new Subject<void>();
  private touched = false;
  id = `numeric-range-form-field-control-${NumericRangeFormFieldControlComponent.nextId++}`;

  get empty() {
    const {
      value: { minValue, maxValue },
    } = this.parts;

    return !minValue && !maxValue;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get value(): INumberRange | null {
    const {
      value: { minValue, maxValue },
    } = this.parts;
    if (!!minValue && !!maxValue) {
      return new INumberRange(minValue, maxValue);
    }
    return null;
  }

  set value(tel: INumberRange | null) {
    const { minValue, maxValue } = tel || new INumberRange();
    this.parts.setValue({ minValue, maxValue });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>
  ) {}

  get selectedDataChipProperties(): NumberRangeChipProperties | undefined {
    return this.selectedData?.chipProperties as NumberRangeChipProperties;
  }

  ngOnInit(): void {
    const minValue = this.selectedDataChipProperties?.minValue;
    const maxValue = this.selectedDataChipProperties?.maxValue;

    this.parts.controls.minValue.setValue(minValue);
    this.parts.controls.maxValue.setValue(maxValue);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.selectedData?.behavior !== CIS_CHIP_BEHAVIORS.LOCK) {
        this.minInput?.nativeElement.focus();
      }
    }, 10);
  }

  onFocusIn() {
    if (this.focused) {
      this.focused.emit(true);
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused.emit(false);
      this.stateChanges.next();
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  onChange = (change: any) => {
    return change;
  };

  onTouched = () => {
    //ignore
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(val: INumberRange | null): void {
    this.value = val;
  }
}
