import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';

import { INTERCEPTOR_SKIPPERS } from '../constants';

export interface IGeoIPResponse {
  city: {
    city: {
      names: {
        en: string;
      };
    };
    country: {
      isoCode: string;
      names: {
        en: string;
      };
    };
  };
}

export interface CountryResponse {
  name: string;
}
@Injectable({
  providedIn: 'root',
})
export class ThirdPartyApiService {
  constructor(private http: HttpClient) {}

  getLocationInfo(
    ipAddress: string
  ): Observable<CommonResponseDTO<IGeoIPResponse>> {
    // mock the localhost to dummy ip
    const possibleLocalIpAddressList = [
      'localhost',
      '127.0.0.1',
      '::ffff:127.0.0.1',
      '0.0.0.0',
    ];
    ipAddress = possibleLocalIpAddressList.some((ip) => ip === ipAddress)
      ? '175.157.42.63'
      : ipAddress;

    const headers = new HttpHeaders()
      .set(INTERCEPTOR_SKIPPERS.JWT_INTERCEPTOR, 'valid')
      .set(INTERCEPTOR_SKIPPERS.TUNNELING_QUERY_INTERCEPTOR, 'valid');
    headers.append('Access-Control-Allow-Origin', '*');

    const params = new HttpParams().append('ip', ipAddress);

    return this.http.get<CommonResponseDTO<IGeoIPResponse>>(
      URLS.THIRD_PARTY_GEO_IP,
      {
        headers,
        params,
      }
    );
  }

  getAllCountries(): Observable<CommonResponseDTO<CountryResponse[]>> {
    return this.http
      .get<CommonResponseDTO<CountryResponse[]>>(
        URLS.THIRD_PARTY_COUNTRIES_LIST
      )
      .pipe(
        // external data type interface
        map((res: any) => {
          return res.map((countriesArray) => countriesArray.name);
        })
      );
  }
}
