import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';

import { ReleaseResponseDTO } from '../../../setup/releases/services/release.service';

@Component({
  selector: 'app-release-check',
  templateUrl: './release-check.component.html',
  styleUrls: ['./release-check.component.scss'],
})
export class ReleaseCheckComponent implements OnInit {
  releaseNode: string;
  versionNumber: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReleaseResponseDTO,
    private metaService: Meta
  ) {
    this.onAddCacheControls();
  }

  ngOnInit(): void {
    this.releaseNode = this.data.change_log;
    this.versionNumber = this.data.version_no;
  }

  onAddCacheControls(): void {
    this.metaService.addTag({
      name: 'cache-control',
      content: 'no-cache, no-store, must-revalidate',
    });
    this.metaService.addTag({ name: 'pragma', content: 'no-cache' });
    this.metaService.addTag({ name: 'expires', content: '0' });
  }

  reloadPage() {
    window.location.reload();
  }
}
