import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  CommonModule,
  DatePipe,
  PercentPipe,
  TitleCasePipe,
} from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Injector,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MAT_COLOR_FORMATS,
  NGX_MAT_COLOR_FORMATS,
  NgxMatColorPickerModule,
} from '@angular-material-components/color-picker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MtxNativeDatetimeModule } from '@ng-matero/extensions/core';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import {
  ConnectionServiceModule,
  ConnectionServiceOptions,
  ConnectionServiceOptionsToken,
} from 'angular-connection-service';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ImageCropperModule } from 'ngx-image-cropper';
// third-party modules
import { SocketIoModule } from 'ngx-socket-io';
import { ToastrModule } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';

import { environment } from '../environments/environment';

// services
import { AuthService } from './modules/auth/services/auth.service';
import { ConfigurableFieldsModule } from './modules/configurable-fields/configurable-fields.module';
import { CoreModule } from './modules/core/core.module';
import { HelpAndSupportSkeletonComponent } from './modules/references/components/skeleton-references/skeleton-references.component';
// since this popup is opened from the side menu
import { AddReferenceCategoryComponent } from './modules/references/popups/add-reference-category/add-reference-category-popup.component';
import { CurrenciesService } from './modules/setup/currencies/services/currencies.service';
import { ExternalSystemsService } from './modules/setup/external-systems/services/external-systems.service';
import { ClientDataService } from './modules/setup/general/services/client-data.service';
import { SystemDataService } from './modules/setup/general/services/system-data.service';
import { IamConfigService } from './modules/setup/iam-config/services/iam-config.service';
import { LanguagesService } from './modules/setup/languages/services/languages.service';
import { MailBoxConfigService } from './modules/setup/mailbox/pages/services/mailbox-config.service';
import { NotificationConfigService } from './modules/setup/notifications/services/notification-config.service';
import { PWAService } from './modules/setup/pwa/services/pwa.service';
import { ReleaseService } from './modules/setup/releases/services/release.service';
import { SecurityService } from './modules/setup/security/services/security.service';
import { StorageSetupService } from './modules/setup/storage/services/storage-setup.service';
import { SupportService } from './modules/setup/support/services/support.service';
import { ProgressToastComponent } from './modules/shared/components/progress.toast/progress-toast.component';
import { SharedModule } from './modules/shared/shared.module';
import { WidgetModule } from './modules/widgets/widget.module';
// components
import { AboutComponent } from './pages/about/about.component';
import { ViewReleaseNoteComponent } from './pages/about/popups/view-release-note/view-release-note.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NotificationPanelComponent } from './pages/dashboard/notification-panel/notification-panel.component';
import { MenuItemsComponent } from './pages/dashboard/side-menu/menu-items/menu-items.component';
import { SideMenuComponent } from './pages/dashboard/side-menu/side-menu.component';
import { DeactivateModuleComponent } from './pages/deactivate-module/deactivate-module.component';
import { DiaryComponent } from './pages/diary/diary.component';
import { DialogAddReminderTaskComponent } from './pages/diary/popups/add-reminder-task/add-reminder-task.component';
import { ViewReminderComponent } from './pages/diary/popups/view-reminder/view-reminder.component';
import { ViewTaskComponent } from './pages/diary/popups/view-task/view-task.component';
import { HomeComponent } from './pages/home/home.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NoticesComponent } from './pages/notices/notices.component';
import { DialogViewNoticeComponent } from './pages/notices/popups/view-notice/view-notice.component';
import { SkeletonNoticesComponent } from './pages/notices/skeleton-notices/skeleton-notices.component';
import { PersonalizationComponent } from './pages/personalization/personalization.component';
import { ArchivedListComponent } from './pages/post-it-notes/popups/archived-list/archived-list.component';
import { CreateNoteComponent } from './pages/post-it-notes/popups/create-note/create-note.component';
import { PostItNotesComponent } from './pages/post-it-notes/post-it-notes.component';
import { SkeletonPostItNotesComponent } from './pages/post-it-notes/skeleton-post-it-notes/skeleton-post-it-notes.component';
import { ConnectionsDataFieldsComponent } from './pages/profile/connections-data-fields/connections-data-fields.component';
import { ImageCropperDialogComponent } from './pages/profile/image-cropper-dialog/image-cropper-dialog.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AddQuickActionComponent } from './pages/quick-actions/popups/add-quick-action/add-quick-action.component';
import { QuickActionsComponent } from './pages/quick-actions/quick-actions.component';
import { SkeletonQuickActionsComponent } from './pages/quick-actions/skeleton-quick-actions/skeleton-quick-actions.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { AppComponent } from './app.component';
import { AppInitializer } from './app.initializer';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';

// required for AOT compilation
function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/api/assets/languages/');
}

function appInitializerFactory(translateService: TranslateService) {
  return () => {
    const service = translateService.use('en');
    return lastValueFrom(service);
  };
}

const components = [
  AboutComponent,
  AccessDeniedComponent,
  DashboardComponent,
  HomeComponent,
  AddQuickActionComponent,
  QuickActionsComponent,
  MaintenanceComponent,
  DeactivateModuleComponent,
  NotFoundComponent,
  ProfileComponent,
  ResetPasswordComponent,
  PostItNotesComponent,
  CreateNoteComponent,
  ArchivedListComponent,
  SideMenuComponent,
  MenuItemsComponent,
  ImageCropperDialogComponent,
  ConnectionsDataFieldsComponent,
  NoticesComponent,
  NotificationPanelComponent,
  DialogViewNoticeComponent,
  DiaryComponent,
  DialogAddReminderTaskComponent,
  SkeletonQuickActionsComponent,
  SkeletonNoticesComponent,
  HelpAndSupportSkeletonComponent,
  SkeletonPostItNotesComponent,
  ProgressToastComponent,
  PersonalizationComponent,
  ViewTaskComponent,
  ViewReminderComponent,
  ViewReleaseNoteComponent,
  AddReferenceCategoryComponent,
];

const thirdPartyModules = [
  NgbModule,
  SocketIoModule.forRoot({ url: environment.API_URL }),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
  DragDropModule,
  NgxMatColorPickerModule,
  ImageCropperModule,
  ToastrModule.forRoot({ maxOpened: 4, preventDuplicates: true }),
  ConnectionServiceModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: environment.production,
    registrationStrategy: 'registerImmediately',
  }),
  KeyboardShortcutsModule.forRoot(),
  MtxNativeDatetimeModule,
  MtxDatetimepickerModule,
  CalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  }),
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, ...components],
  imports: [
    CKEditorModule,
    CoreModule,
    CommonModule,
    SharedModule,
    ConfigurableFieldsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MaterialModule,
    WidgetModule,
    Sentry.TraceModule,
    ...thirdPartyModules,
  ],
  providers: [
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // ignore
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializer,
      multi: true,
      deps: [
        AuthService,
        MailBoxConfigService,
        ReleaseService,
        SecurityService,
        ClientDataService,
        SystemDataService,
        LanguagesService,
        NotificationConfigService,
        PWAService,
        IamConfigService,
        StorageSetupService,
        SupportService,
        ExternalSystemsService,
        CurrenciesService,
        TranslateService,
        Injector,
        Meta,
      ],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {
      provide: MAT_COLOR_FORMATS,
      useValue: NGX_MAT_COLOR_FORMATS,
    },
    {
      provide: ConnectionServiceOptionsToken,
      useValue: <ConnectionServiceOptions>{
        enableHeartbeat: true,
        heartbeatUrl: '/api',
        requestMethod: 'get',
        heartbeatInterval: 60000,
        heartbeatRetryInterval: 1000000,
      },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
    Title,
    TitleCasePipe,
    PercentPipe,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
