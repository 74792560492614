import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { STORAGE_QUOTA_UNIT, THRESHOLD_TYPES, URLS } from '@shared/constants';
import { CommonResponseDTO, IStorageQuota } from '@shared/interfaces';

export enum DMS_STATUS {
  INTERNAL = 'INTERNAL', // internal Storage is in use
  EXTERNAL = 'EXTERNAL', // external Storage is in use
  INTERNAL_PENDING = 'INTERNAL_PENDING', // internal Storage is in use
  EXTERNAL_PENDING = 'EXTERNAL_PENDING', // external Storage is in use
  INTERNAL_DISCONNECTED = 'INTERNAL_DISCONNECTED', // internal Storage is in use
}

export enum CONFIG_STATUS {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export interface Config {
  url?: string;
  description?: string;
  status?: CONFIG_STATUS;
  connected_on?: string;
  deactivated_on?: string;
  valid_till?: string;
}
export interface StorageRecycleBinRequestDTO {
  recycle_bin_enabled: boolean;
  recycle_bin_number_of_days: number;
}

export interface StorageServiceURLRequestDTO {
  syncfusion_service_url: string;
}

export interface StorageQuotaRequestDTO {
  quota: number;
  threshold_value: number;
  is_enabled: boolean;
  is_exceeded: boolean;
  quota_unit: STORAGE_QUOTA_UNIT;
  threshold_unit: STORAGE_QUOTA_UNIT;
  threshold_type: THRESHOLD_TYPES;
  storage_size: number;
}

export interface UserStorageQuotaRequestDTO {
  is_user_enabled: boolean;
  user_quota: number;
  user_quota_unit: STORAGE_QUOTA_UNIT;
}

export interface QuotaUpdateRequestDTO {
  storage_quota: StorageQuotaRequestDTO;
  user_quota?: UserStorageQuotaRequestDTO;
}

export interface StorageQuotaResponseDTO {
  quota: number;
  threshold_value: number;
  is_enabled: boolean;
  is_exceeded: boolean;
  quota_unit: STORAGE_QUOTA_UNIT;
  threshold_unit: STORAGE_QUOTA_UNIT;
  storage_size: number;
}

export interface UserStorageQuotaResponseDTO {
  is_user_enabled: boolean;
  user_quota: number;
  user_quota_unit: STORAGE_QUOTA_UNIT;
}

export interface StorageConfig {
  storage: {
    status: DMS_STATUS;
    centralizedDMS?: boolean;
    current_config?: {
      url: string;
      client_id: string;
      client_secret?: string;
      connected_on?: Date;
      deactivated_on?: Date;
    };
    new_config?: {
      url: string;
      client_id: string;
      client_secret?: string;
      valid_till: Date;
    };
  };
  recycle_bin: StorageRecycleBinRequestDTO;
  syncfusion_web_service: StorageServiceURLRequestDTO;
  storage_quota: StorageQuotaRequestDTO;
  user_quota: UserStorageQuotaRequestDTO;
}

interface IDeleteStorageConfig {
  status: CONFIG_STATUS;
  url: string;
}

export interface StorageConfigRequestDTO {
  use_internal_storage: boolean;
  config?: {
    url: string;
    client_id: string;
    client_secret?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class StorageSetupService {
  public readonly dataStore = new BehaviorSubject<StorageConfig | undefined>(
    undefined
  );

  constructor(private http: HttpClient) {}

  getAllConfigData(): Observable<CommonResponseDTO<StorageConfig>> {
    return this.http
      .get<CommonResponseDTO<StorageConfig>>(URLS.STORAGE_SETUP_STORAGE)
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }

  updateStorageConfig(
    data: StorageConfigRequestDTO
  ): Observable<CommonResponseDTO<any>> {
    return this.http
      .patch<CommonResponseDTO<any>>(URLS.STORAGE_SETUP_STORAGE, data)
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }

  updateRecycleBinConfig(
    data: StorageRecycleBinRequestDTO
  ): Observable<CommonResponseDTO<StorageConfig>> {
    return this.http
      .patch<CommonResponseDTO<StorageConfig>>(
        URLS.STORAGE_SETUP_RECYCLE_BIN,
        data
      )
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }

  updateSyncfusionWebServiceConfig(
    data: StorageServiceURLRequestDTO
  ): Observable<CommonResponseDTO<StorageConfig>> {
    return this.http
      .patch<CommonResponseDTO<StorageConfig>>(
        URLS.STORAGE_SETUP_SYNCFUSION_SERVICE_URL,
        data
      )
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }

  deleteIamConfig(
    data: IDeleteStorageConfig
  ): Observable<CommonResponseDTO<StorageConfig>> {
    return this.http
      .patch<CommonResponseDTO<StorageConfig>>(
        `${URLS.STORAGE_SETUP_STORAGE}/delete`,
        data
      )
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }

  updateStorageQuotaConfig(
    data: IStorageQuota
  ): Observable<CommonResponseDTO<StorageConfig>> {
    return this.http
      .patch<CommonResponseDTO<StorageConfig>>(
        `${URLS.STORAGE_SETUP_STORAGE_QUOTA}`,
        data
      )
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }

  getTotalStorageSize(): Observable<any> {
    const url = `${URLS.STORAGE_SETUP_STORAGE}/size`;
    return this.http.get(url).pipe(
      tap((res) => {
        const currentDataStore = { ...this.dataStore.value };
        currentDataStore.storage_quota.storage_size = 0;

        res.data.forEach((storage) => {
          currentDataStore.storage_quota.storage_size += storage.totalSize;
        });

        this.dataStore.next(currentDataStore);
      })
    );
  }
}
