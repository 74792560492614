export enum PERMISSION_TYPES {
  SYSTEM = 'SYSTEM',
  CUSTOM = 'CUSTOM',
  VIRTUAL = 'VIRTUAL',
}

// list only common permissions here
export enum PERMISSION_ACTIONS {
  READ = 'READ',
  WRITE = 'WRITE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
}

export interface PermissionDescription {
  permission: string;
  description: string;
}

// always have the ACTION:MODULE format
// also add a relevant description to above object array in the added format
export enum SPECIAL_PERMISSION_ACTIONS {
  // Login related permissions
  AUTHORIZED_ADMIN_LOGIN = 'AUTHORIZED_ADMIN:LOGIN',

  // Identities-hierarchy related permissions
  IDENTITIES_HIERARCHY_VIEW_SELF = 'VIEW_SELF_HIERARCHY:IDENTITIES',
  IDENTITIES_HIERARCHY_VIEW_SUBORDINATES = 'VIEW_SUBORDINATES_HIERARCHY:IDENTITIES',
  IDENTITIES_HIERARCHY_VIEW_ALL = 'VIEW_ALL_HIERARCHY:IDENTITIES',

  // Identities special permissions
  IDENTITIES_RESEND_CREDENTIALS = 'RESEND_CREDENTIALS:IDENTITIES',

  // Notifications related permissions
  VIEW_ALL_NOTIFICATION = 'VIEW_ALL_NOTIFICATION',

  // Logs related permissions
  VIEW_SYSTEM_LOGS = 'VIEW_SYSTEM:LOGS',
  VIEW_ALL_LOGS = 'VIEW_ALL:LOGS',
  HIERARCHY_LOGS = 'HIERARCHY:LOGS',

  // Storage Admin permissions
  VIEW_SYSTEM_ENTITIES = 'VIEW_SYSTEM_ENTITIES:STORAGE',
  READ_ALL_ENTITIES = 'READ_ALL_ENTITIES:STORAGE',
  WRITE_ALL_ENTITIES = 'WRITE_ALL_ENTITIES:STORAGE',
  EDIT_ALL_ENTITIES = 'EDIT_ALL_ENTITIES:STORAGE',
  DELETE_ALL_ENTITIES = 'DELETE_ALL_ENTITIES:STORAGE',
  RESTORE_ALL_ENTITIES = 'RESTORE_ALL_ENTITIES:STORAGE', // TODO:@sanjeewa use this to restore files from trash
  INTERNAL_SHARE_ALL_ENTITIES = 'INTERNAL_SHARE_ALL_ENTITIES:STORAGE',
  EXTERNAL_SHARE_ALL_ENTITIES = 'EXTERNAL_SHARE_ALL_ENTITIES:STORAGE',
  PUBLIC_SHARE_ALL_ENTITIES = 'PUBLIC_SHARE_ALL_ENTITIES:STORAGE',

  // Storage quota permissions
  READ_STORAGE_QUOTA = 'READ:STORAGE_QUOTA',
  WRITE_STORAGE_QUOTA = 'WRITE:STORAGE_QUOTA',
  READ_USER_QUOTA = 'READ:USER_QUOTA',
  WRITE_USER_QUOTA = 'WRITE:USER_QUOTA',

  // Identities Public API related permissions
  IDENTITIES_INTEGRATION = 'INTEGRATION:IDENTITIES',
  READ_IDENTITIES_API_KEYS = 'READ:IDENTITIES_API_KEYS',
  EDIT_IDENTITIES_API_KEYS = 'EDIT:IDENTITIES_API_KEYS',
  WRITE_IDENTITIES_API_KEYS = 'WRITE:IDENTITIES_API_KEYS',
  DELETE_IDENTITIES_API_KEYS = 'DELETE:IDENTITIES_API_KEYS',
  RESTORE_IDENTITIES_API_KEYS = 'RESTORE:IDENTITIES_API_KEYS',

  // Storage Public API related permissions
  DRIVE_INTEGRATION = 'INTEGRATION:STORAGE_DRIVE',
  READ_STORAGE_API_KEYS = 'READ:STORAGE_API_KEYS',
  EDIT_STORAGE_API_KEYS = 'EDIT:STORAGE_API_KEYS',
  WRITE_STORAGE_API_KEYS = 'WRITE:STORAGE_API_KEYS',
  DELETE_STORAGE_API_KEYS = 'DELETE:STORAGE_API_KEYS',
  RESTORE_STORAGE_API_KEYS = 'RESTORE:STORAGE_API_KEYS',

  // Interfaces related permissions
  SYSTEM_INTEGRATION = 'INTEGRATION:SYSTEM',

  // Profile related permissions
  EDIT_PROFILE = 'EDIT:PROFILE',

  // Storage entities related permissions
  READ_ENTITIES = 'READ:ENTITIES',
  WRITE_ENTITIES = 'WRITE:ENTITIES',
  INTERNAL_SHARE_ENTITIES = 'SHARE_INTERNAL:ENTITIES',
  EXTERNAL_SHARE_ENTITIES = 'SHARE_EXTERNAL:ENTITIES',
  PUBLIC_SHARE_ENTITIES = 'SHARE_PUBLIC:ENTITIES',
  DELETE_ENTITIES = 'DELETE:ENTITIES',
  EDIT_ENTITIES = 'EDIT:ENTITIES',

  // Trash special permissions
  READ_TRASH_INFO = 'READ:TRASH_INFO',

  // Widgets
  READ_ALL_WIDGET_LAYOUTS = 'READ:ALL_WIDGET_LAYOUTS',
  WRITE_ALL_WIDGET_LAYOUTS = 'WRITE:ALL_WIDGET_LAYOUTS',

  // Messages
  ALLOW_REPORTS_TO_IDENTITY = 'ALLOW_REPORTS_TO_IDENTITY:MESSAGES',
  ALLOW_ALL_SUBORDINATE_IDENTITIES = 'ALLOW_ALL_SUBORDINATE_IDENTITIES:MESSAGES',
  ALLOW_IMMEDIATE_SUBORDINATE_IDENTITIES = 'ALLOW_IMMEDIATE_SUBORDINATE_IDENTITIES:MESSAGES',
  ALLOW_REPORTS_TO_ROLE = 'ALLOW_REPORTS_TO_ROLE:MESSAGES',
  ALLOW_ALL_SUBORDINATE_ROLES = 'ALLOW_ALL_SUBORDINATE_ROLES:MESSAGES',
  ALLOW_IMMEDIATE_SUBORDINATE_ROLES = 'ALLOW_IMMEDIATE_SUBORDINATE_ROLES:MESSAGES',
}

export enum PERMISSIONS_TYPES {
  ENTITIES = 'ENTITIES',
  STORAGE_API_KEYS = 'STORAGE_API_KEYS',
  STORAGE = 'STORAGE',
  STORAGE_QUOTA = 'STORAGE_QUOTA',
  USER_QUOTA = 'USER_QUOTA',
  STORAGE_TRASH = 'STORAGE_TRASH',
  TRASH_INFO = 'TRASH_INFO',
  VIEW_ALL_NOTIFICATION = 'VIEW_ALL_NOTIFICATION',
  NOTIFICATIONS = 'NOTIFICATIONS',
  ALL_WIDGET_LAYOUTS = 'ALL_WIDGET_LAYOUTS',
  WIDGET = 'WIDGET',
  INTEGRATIONS = 'INTEGRATIONS',
  SYSTEM = 'SYSTEM',
  IDENTITIES = 'IDENTITIES',
  IDENTITIES_API_KEYS = 'IDENTITIES_API_KEYS',
}

export enum REFERENCE_PERMISSION_ACTIONS {
  // for the category itself
  READ_CATEGORY = 'READ_CATEGORY',
  WRITE_CATEGORY = 'WRITE_CATEGORY',
  EDIT_CATEGORY = 'EDIT_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  RESTORE_CATEGORY = 'RESTORE_CATEGORY',

  // for the items added inside the category
  READ_CATEGORY_ITEMS = 'READ_CATEGORY_ITEMS',
  WRITE_CATEGORY_ITEMS = 'WRITE_CATEGORY_ITEMS',
  APPROVE_CATEGORY_ITEMS = 'APPROVE_CATEGORY_ITEMS',
  EDIT_CATEGORY_ITEMS = 'EDIT_CATEGORY_ITEMS',
  DELETE_CATEGORY_ITEMS = 'DELETE_CATEGORY_ITEMS',
  RESTORE_CATEGORY_ITEMS = 'RESTORE_CATEGORY_ITEMS',
}

export enum INTEGRATION_PERMISSION_ACTIONS {
  //for endpoints
  READ_ENDPOINTS = 'READ:ENDPOINTS',
  WRITE_ENDPOINTS = 'WRITE:ENDPOINTS',
  EDIT_ENDPOINTS = 'EDIT:ENDPOINTS',
  DELETE_ENDPOINTS = 'DELETE:ENDPOINTS',
  RESTORE_ENDPOINTS = 'RESTORE:ENDPOINTS',
  VERIFY_ENDPOINTS = 'VERIFY:ENDPOINTS',

  //for mappings
  READ_MAPPINGS = 'READ:MAPPINGS',
  WRITE_MAPPINGS = 'WRITE:MAPPINGS',
  EDIT_MAPPINGS = 'EDIT:MAPPINGS',
  DELETE_MAPPINGS = 'DELETE:MAPPINGS',
  RESTORE_MAPPINGS = 'RESTORE:MAPPINGS',
  VERIFY_MAPPINGS = 'VERIFY:MAPPINGS',
}

export enum ANALYZING_MODES {
  EVERY = 'every',
  SOME = 'some',
  ALLOW_OWN_IDENTITY = 'allowOwnIdentity',
}

export enum PERMISSION_METADATA_KEYS {
  PERMISSIONS = 'permissions',
  ANALYZING_MODE = 'analyzing_mode',
}

export enum DEFAULT_ROLES {}
// DEFAULT_ROLES example : IT_ADMIN = 'IT Admin'

export const DEFAULT_ROLE_CONFIG = {
  // [DEFAULT_ROLES.IT_ADMIN]: {
  //   is_default: false,
  //   permissions: [],
  // },
};

interface IDefaultQuickActions {
  icon: string;
  title: string;
  link?: string;
  key?: DEFAULT_QUICK_ACTIONS_KEY;
  permissions: string[];
}

export enum DEFAULT_QUICK_ACTIONS_KEY {
  CALL_US = 'call_us',
}

export const DEFAULT_QUICK_ACTIONS: IDefaultQuickActions[] = [
  //{
  //  icon: 'call',
  //  title: 'dashboard.quick.call-us',
  //  key: DEFAULT_QUICK_ACTIONS_KEY.CALL_US,
  //  permissions: []
  //},
];
