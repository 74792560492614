<div class="horizontal-center-container pt-5">
  <div>
    <div class="d-flex justify-content-center">
      <lottie-player
        src="/assets/animations/under-construction.json"
        background="transparent"
        speed="1"
        style="width: 300px; height: 300px; margin-left: 50px"
        loop
        autoplay
        alt="maintenance"
      ></lottie-player>
    </div>
    <div>
      <h5 class="title flex-center text-center">
        {{ 'pages.deactivate.title' | translate }}
      </h5>
      <p class="flex-center text-center">
        {{ 'pages.deactivate.advice' | translate }}
      </p>
      <p class="flex-center text-center" style="margin-top: -20px">
        <a routerLink="/app/launchpad" class="link" routerLinkActive="active">{{
          'pages.deactivate.launchpad' | translate
        }}</a>
        {{ 'pages.deactivate.end' | translate }}
      </p>
    </div>
  </div>
</div>
