import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { IRichTextPreview } from '@shared/interfaces';

@Component({
  selector: 'app-rich-text-viewer',
  templateUrl: './rich-text-viewer.component.html',
  styleUrls: ['./rich-text-viewer.component.scss'],
})
export class RichTextViewerComponent implements OnInit {
  popup_icon: string;
  popup_title: string;
  popup_subtitle: string;
  richTextData: string;
  richTextContent: SafeHtml;
  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<RichTextViewerComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: IRichTextPreview
  ) {}

  ngOnInit(): void {
    this.popup_icon = this.data.icon ?? 'wysiwyg';
    this.popup_title =
      this.data.topic ??
      this.translate.instant('shared.rich-text-previewer.title');
    this.popup_subtitle =
      this.data.subtopic ??
      this.translate.instant('shared.rich-text-previewer.subtitle');
    this.richTextData = this.data.content ?? '-';
    this.richTextContent = this.sanitizer.bypassSecurityTrustHtml(
      this.addImageStyles(this.richTextData)
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  addImageStyles(content: string): string {
    return content?.replace(/<img[^>]*>/g, (match) =>
      match.replace(/(<img[^>]*)(>)/, `$1 style="width: 100%;"$2`)
    );
  }
}
