import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { EVENT_TYPE } from '@shared/constants';
import { IReminder, ITask } from '@shared/interfaces';

import {
  LoggedUserService,
  UserInfoResponseDTO,
} from '../../../../modules/auth/services';
import { noWhitespaceValidator } from '../../../../modules/core/helpers/form-field-white-space-validator';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../../../services/snackbar.service';
import { DiaryService } from '../../services/diary.service';

export interface IAddDialogResponse<T> {
  data: T;
  isReminder: boolean;
}

export interface IEditReminderTask {
  eventType: EVENT_TYPE;
  task?: ITask;
  reminder?: IReminder;
  date?: Date;
  start_date?: Date;
  end_date?: Date;
}

@Component({
  selector: 'app-add-reminder-task',
  templateUrl: './add-reminder-task.component.html',
  styleUrls: ['./add-reminder-task.component.scss'],
})
export class DialogAddReminderTaskComponent implements OnInit, OnDestroy {
  isLoading = false;
  eventTypes = EVENT_TYPE;
  isReminder = true;
  isEdit = false;
  todayDate = new Date();
  minDate = new Date();
  maxDate = new Date();

  addReminderForm = new FormGroup({
    title: new FormControl(null, [Validators.required, noWhitespaceValidator]),
    date: new FormControl(new Date(), [
      Validators.required,
      this.dateTimeValidator.bind(this),
    ]),
    time: new FormControl(null, [
      Validators.required,
      this.startTimeValidator.bind(this),
    ]),
    link: new FormControl(null),
    repeat: new FormControl(null, [Validators.required]),
  });

  addTaskForm = new FormGroup({
    title: new FormControl(null, [Validators.required, noWhitespaceValidator]),
    start_date: new FormControl(new Date(), [Validators.required]),
    end_date: new FormControl(new Date(), [Validators.required]),
    start_time: new FormControl(new Date(), [
      Validators.required,
      this.startTimeValidator.bind(this),
    ]),
    end_time: new FormControl(new Date(), [
      Validators.required,
      this.dateTimeValidator.bind(this),
    ]),
    all_day: new FormControl(false),
    description: new FormControl(null, [
      Validators.required,
      noWhitespaceValidator,
    ]),
  });

  private subscriptions = new Subscription();
  loggedUser: UserInfoResponseDTO;

  constructor(
    private dialogRef: MatDialogRef<DialogAddReminderTaskComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: IEditReminderTask,
    private diaryService: DiaryService,
    private snackBar: SnackbarService,
    private translate: TranslateService,
    private loggedUserService: LoggedUserService
  ) {}

  ngOnInit(): void {
    this.minDate = new Date();
    this.maxDate = new Date();

    this.subscriptions.add(
      this.loggedUserService.dataStore.subscribe((loggedUser) => {
        this.loggedUser = loggedUser;
      })
    );
    if (this.data.eventType) this.isEdit = true;
    this.initForms();
    this.subscriptions.add(
      this.addTaskForm.get('start_time')?.valueChanges.subscribe(() => {
        this.addTaskForm.get('end_time')?.updateValueAndValidity();
      })
    );
    this.subscriptions.add(
      this.addTaskForm.get('end_date')?.valueChanges.subscribe(() => {
        this.addTaskForm.get('end_time')?.updateValueAndValidity();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForms() {
    if (this.data.date && this.data.start_date && this.data.end_date) {
      this.addReminderForm.controls.date.setValue(this.data?.date);
      this.addTaskForm.controls.start_date.setValue(this.data?.start_date);
      this.addTaskForm.controls.end_date.setValue(this.data?.end_date);
    }

    if (this.isEdit && this.data.eventType === EVENT_TYPE.REMINDER) {
      this.addReminderForm.controls.title.setValue(this.data?.reminder?.title);
      this.addReminderForm.controls.date.setValue(
        new Date(this.data?.reminder?.date)
      );
      this.addReminderForm.controls.time.setValue(
        new Date(this.data?.reminder?.time)
      );
      this.addReminderForm.controls.link.setValue(this.data?.reminder?.link);
      this.addReminderForm.controls.repeat.setValue(
        this.data?.reminder?.repeat
      );

      this.addReminderForm.markAsPristine();
    } else if (this.isEdit && this.data.eventType === EVENT_TYPE.TASK) {
      this.isReminder = false;
      this.addTaskForm.controls.title.setValue(this.data?.task?.title);
      this.addTaskForm.controls.start_date.setValue(
        new Date(this.data?.task?.start_date)
      );
      this.addTaskForm.controls.end_date.setValue(
        new Date(this.data?.task?.end_date)
      );
      this.addTaskForm.controls.start_time.setValue(
        new Date(this.data?.task?.start_time)
      );
      this.addTaskForm.controls.end_time.setValue(
        new Date(this.data?.task?.end_time)
      );
      this.addTaskForm.controls.all_day.setValue(this.data?.task?.all_day);
      this.addTaskForm.controls.description.setValue(
        this.data?.task?.description
      );
    }
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  onChangeToTask() {
    this.isReminder = false;
    this.addTaskForm.controls.end_date.markAsTouched();
    this.addTaskForm.controls.end_time.markAsTouched();
  }

  onClickReminderSave() {
    const reminder: IReminder = {
      ...this.addReminderForm.value,
      relevant_user: this.loggedUser._id,
      title: this.addReminderForm.value.title,
      date: new Date(this.addReminderForm.value.date),
      time: new Date(this.addReminderForm.value.time),
      repeat: this.addReminderForm.value.repeat,
      link: this.addReminderForm.value.link || '',
    };

    if (this.isEdit) {
      this.diaryService
        .updateReminder(reminder, this.data.reminder._id.toString())
        .subscribe({
          next: (res) => {
            const data: IAddDialogResponse<IReminder> = {
              data: res.data,
              isReminder: true,
            };
            this.dialogRef.close(data);
            this.snackBar.success(
              SUCCESS_TYPES.SAVED,
              this.translate.instant('pages.diary.popups.reminder')
            );
          },
          error: () => {
            this.snackBar.error(
              this.translate.instant(
                'pages.diary.error-messages.reminder-update-failed'
              )
            );
          },
        });
    } else {
      this.diaryService.addReminder(reminder).subscribe({
        next: (res) => {
          const data: IAddDialogResponse<IReminder> = {
            data: res.data,
            isReminder: true,
          };
          this.dialogRef.close(data);
          this.snackBar.success(
            SUCCESS_TYPES.SAVED,
            this.translate.instant('pages.diary.popups.reminder')
          );
        },
        error: () => {
          this.snackBar.error(
            this.translate.instant(
              'pages.diary.error-messages.reminder-add-failed'
            )
          );
        },
      });
    }
  }

  onClickTaskSave() {
    const task: ITask = {
      ...this.addTaskForm.value,
      relevant_user: this.loggedUser._id,
      title: this.addTaskForm.value.title,
      start_date: new Date(this.addTaskForm.value.start_date),
      end_date: this.addTaskForm.value.end_date
        ? new Date(this.addTaskForm.value.end_date)
        : null,
      start_time: new Date(this.addTaskForm.value.start_time),
      end_time: this.addTaskForm.value.end_time
        ? new Date(this.addTaskForm.value.end_time)
        : null,
      all_day: this.addTaskForm.value.all_day,
      description: this.addTaskForm.value.description,
    };

    if (this.isEdit) {
      this.diaryService
        .updateTask(task, this.data.task._id.toString())
        .subscribe({
          next: (res) => {
            const data: IAddDialogResponse<ITask> = {
              data: res.data,
              isReminder: false,
            };
            this.dialogRef.close(data);
            this.snackBar.success(
              SUCCESS_TYPES.SAVED,
              this.translate.instant('pages.diary.popups.task')
            );
          },
          error: () => {
            this.snackBar.error(
              this.translate.instant(
                'pages.diary.error-messages.task-update-failed'
              )
            );
          },
        });
    } else {
      this.diaryService.addTask(task).subscribe({
        next: (res) => {
          const data: IAddDialogResponse<ITask> = {
            data: res.data,
            isReminder: false,
          };
          this.dialogRef.close(data);
          this.snackBar.success(
            SUCCESS_TYPES.SAVED,
            this.translate.instant('pages.diary.popups.task')
          );
        },
        error: () => {
          this.snackBar.error(
            this.translate.instant('pages.diary.error-messages.task-add-failed')
          );
        },
      });
    }
  }

  startTimeValidator(control: AbstractControl): ValidationErrors | null {
    const formGroup = control.parent;
    if (formGroup) {
      const startTime = control.value;
      const currentTime = new Date();

      if (startTime && startTime < currentTime) {
        return { startTimeValid: true };
      }
    }
    return null;
  }

  dateTimeValidator(control: AbstractControl): ValidationErrors | null {
    const formGroup = control.parent;
    if (formGroup) {
      const startDate = formGroup.get('start_date')?.value;
      const startTime = formGroup.get('start_time')?.value;
      const endDate = formGroup.get('end_date')?.value;
      const endTime = control.value;

      if (startDate && startTime && endDate && endTime) {
        const startDateTime = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startTime.getHours(),
          startTime.getMinutes()
        );
        const endDateTime = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          endTime.getHours(),
          endTime.getMinutes()
        );

        return startDateTime <= endDateTime ? null : { dateTimeValid: true };
      }
    }
    return null;
  }
}
