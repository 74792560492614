export enum REQUEST_TYPES {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
}

export const requestTypesWithBody = [
  REQUEST_TYPES.POST,
  REQUEST_TYPES.PUT,
  REQUEST_TYPES.PATCH,
];
