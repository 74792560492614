import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cis-date-chip-year-month-date-picker-search-filter',
  templateUrl: 'year-month-date-picker.component.html',
  styleUrls: ['year-month-date-picker.component.scss'],
})
export class YearMonthDatePickerComponent implements AfterViewInit, OnDestroy {
  @Input() loading?: boolean;
  @Input() isChipLock?: boolean;
  @Input() inputWidth?: number;
  @Input() formControl: FormControl = new FormControl();
  @Input() placeholder?: string;
  @Input() disabled?: boolean;
  @Input() readonly?: boolean;
  @Input() label?: string;
  @Input() openPicker?: Observable<void>;

  @ViewChild('picker') datePicker?: MatDatepicker<Date>;
  private onDestroy$ = new Subject<void>();

  ngAfterViewInit(): void {
    this.openPicker?.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.datePicker?.open();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
