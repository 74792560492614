import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { FlowControlService } from '../../../../../services/flow-control.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../../../../services/snackbar.service';
import {
  TemplateRequestDTO,
  TemplateResponseDTO,
  TemplateService,
} from '../../../services/template.service';
import { PdfEditorComponent } from '../pdf-editor/pdf-editor.component';
import * as sample from '../sample.json';

@Component({
  selector: 'app-add-new-template',
  templateUrl: './add-new-template.component.html',
  styleUrls: ['./add-new-template.component.scss'],
})
export class AddNewTemplateComponent {
  name = new UntypedFormControl();
  loading = false;
  template: TemplateResponseDTO;

  @ViewChild('editor') private pdfEditor: PdfEditorComponent;

  constructor(
    flowControlService: FlowControlService,
    route: ActivatedRoute,
    private templateService: TemplateService,
    private router: Router,
    private dialogRef: MatDialogRef<AddNewTemplateComponent>,
    private snackBar: SnackbarService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data: TemplateResponseDTO
  ) {
    flowControlService.updateRouteInfo(
      this.router.url.split('?')[0],
      route.snapshot.data
    );

    this.name.setValue(data?.name || '');
    this.template = data;
  }

  editorLoaded() {
    if (this.template) {
      this.pdfEditor.editor.loadDesign(this.template.design);
    } else {
      this.pdfEditor.editor.loadDesign(sample);
    }
  }

  showMergeTagsError() {
    this.snackBar.error(
      this.translate.instant('references.templates.merge-tags-error')
    );
  }

  private async parseTemplate(): Promise<TemplateRequestDTO> {
    return new Promise((resolve, reject) => {
      if (!this.name.value) {
        return reject(
          new Error(
            this.translate.instant('references.templates.name-required')
          )
        );
      }

      this.pdfEditor.editor.exportHtml((data) => {
        const { html, design } = data;
        const sheet: TemplateRequestDTO = {
          name: this.name.value,
          design,
          html,
        };

        resolve(sheet);
      }, reject);
    });
  }

  private handleError(err: Error) {
    this.snackBar.error(
      err.message ||
        this.translate.instant('references.templates.template-parse-error')
    );
  }

  async openMergeTags() {
    this.loading = true;
    this.parseTemplate()
      .then((template) => {
        this.loading = false;

        this.dialogRef.close({
          saved: false,
          template,
        });
      })
      .catch((err) => this.handleError(err));
  }

  async saveTemplate() {
    this.loading = true;

    this.parseTemplate()
      .then((sheet) => {
        this.templateService.saveNewTemplate(sheet).subscribe({
          next: (data) => {
            this.loading = false;
            if (data.success) {
              this.snackBar.success(SUCCESS_TYPES.CREATED);
              this.dialogRef.close({
                saved: true,
                template: data.data,
              });
            }
          },
          error: (err) => {
            this.snackBar.error(
              err.message ||
                this.translate.instant('references.templates.err-save-template')
            );
          },
        });
      })
      .catch((err) => this.handleError(err))
      .finally(() => {
        this.loading = false;
      });
  }
}
