import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';

import {
  combineAndFormatDateTime,
  formatDateAsString,
  formatDateTime,
  formatDateWithoutTime,
  formatMonthOnly,
  formatShortenedDate,
  formatTime,
} from '@shared/utils';

import {
  GeneralSetupResponseDTO,
  SystemDataService,
} from '../modules/setup/general/services/system-data.service';

export interface IDateTimeParams {
  dateTime?: string | Date;
  dateOnly?: Date;
  timeOnly?: Date;
}
@Injectable({
  providedIn: 'root',
})
export class DateTimeFormatService implements OnDestroy {
  systemInformation: GeneralSetupResponseDTO;
  private onDestroy$ = new Subject<void>();

  constructor(systemDataService: SystemDataService) {
    systemDataService.dataStore
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.systemInformation = data;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  formatDateTime(dateTime: string | Date | moment.Moment) {
    // TODO: Set the formats in the SystemDataService, and pass into this method appropriately
    // Do the same thing in apps\api\src\common\services\date-time-format.service.ts as well
    return formatDateTime(dateTime);
  }

  formatTime(dateTime: string | Date | moment.Moment) {
    // TODO: Set the formats in the SystemDataService, and pass into this method appropriately
    // Do the same thing in apps\api\src\common\services\date-time-format.service.ts as well
    return formatTime(dateTime);
  }

  formatDateWithoutTime(date: string | Date) {
    // TODO: Set the formats in the SystemDataService, and pass into this method appropriately
    // Do the same thing in apps\api\src\common\services\date-time-format.service.ts as well
    return formatDateWithoutTime(date);
  }

  combineAndFormatDateTime(date: Date | string, time: Date | string) {
    // TODO: Set the formats in the SystemDataService, and pass into this method appropriately
    // Do the same thing in apps\api\src\common\services\date-time-format.service.ts as well
    return combineAndFormatDateTime(date, time);
  }

  formatDateAsString(date: string | Date) {
    // TODO: Set the formats in the SystemDataService, and pass into this method appropriately
    // Do the same thing in apps\api\src\common\services\date-time-format.service.ts as well
    return formatDateAsString(date);
  }

  formatShortenedDate(date: string | Date) {
    // TODO: Set the formats in the SystemDataService, and pass into this method appropriately
    // Do the same thing in apps\api\src\common\services\date-time-format.service.ts as well
    return formatShortenedDate(date);
  }

  formatMonthOnly(date: string | Date) {
    // TODO: Set the formats in the SystemDataService, and pass into this method appropriately
    // Do the same thing in apps\api\src\common\services\date-time-format.service.ts as well
    return formatMonthOnly(date);
  }
}
