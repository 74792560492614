import {
  INTEGRATION_PERMISSION_ACTIONS,
  MODULES,
  PERMISSION_ACTIONS,
  REFERENCE_PERMISSION_ACTIONS,
} from '@shared/constants';

export const generatePermissionKey = (
  module: MODULES,
  action:
    | PERMISSION_ACTIONS
    | REFERENCE_PERMISSION_ACTIONS
    | INTEGRATION_PERMISSION_ACTIONS
    | string,
  subModule?: string
) => {
  const subModulePermissionString = subModule ? `:${subModule}` : '';
  const permissionString = `${action}:${module}${subModulePermissionString}`;

  return permissionString
    .replace(/[^a-zA-Z\d\s:]/g, ' ')
    .replace(/\s/g, '_')
    .toUpperCase();
};

export function getModulePermission(
  module: MODULES,
  actions: (
    | PERMISSION_ACTIONS
    | REFERENCE_PERMISSION_ACTIONS
    | INTEGRATION_PERMISSION_ACTIONS
  )[],
  subModule?: string
): string[] {
  return actions.map((action) =>
    generatePermissionKey(module, action, subModule)
  );
}
