import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../../modules/auth/services';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnDestroy {
  private systemSubs: Subscription;
  constructor(private router: Router, private authService: AuthService) {}

  logoutMaintenanceMode(): void {
    this.authService.logout().then(() => {
      this.router.navigate(['/app/auth/login']);
    });
  }

  ngOnDestroy(): void {
    this.systemSubs.unsubscribe();
  }
}
