import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { decryptJSONObject, encryptJSONObject } from '@shared/utils';

import { INTERCEPTOR_SKIPPERS } from '../../../constants';
import {
  SecurityRequestDTO,
  SecurityService,
} from '../../setup/security/services/security.service';

interface EncryptedRequestBody {
  d?: string;
}

@Injectable()
export class TunnellingInterceptor implements HttpInterceptor {
  securityConfig: SecurityRequestDTO;

  constructor(private securityService: SecurityService) {
    this.securityService.dataStore.subscribe((change) => {
      this.securityConfig = change;
    });
  }

  intercept<T extends EncryptedRequestBody>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    request = this.encryptRequest(request);

    return next.handle(request).pipe(
      map((event: HttpEvent<T>) => {
        if (
          event instanceof HttpResponse &&
          event.body?.d &&
          this.securityConfig?.secure_tunneling &&
          this.securityConfig?.encryption_key
        ) {
          const decryptedData = decryptJSONObject(
            event.body.d,
            this.securityConfig.encryption_key
          );
          return event.clone({
            body: decryptedData.error ? event.body : decryptedData.data,
          });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.error && error.error.d) {
          if (
            this.securityConfig?.secure_tunneling &&
            this.securityConfig?.encryption_key
          ) {
            const decrypted = decryptJSONObject(
              error.error.d,
              this.securityConfig.encryption_key
            );
            if (!decrypted.error) {
              error.error.data = decrypted.data;
            }
          }
          error.error.d = undefined;
        }

        throw error;
      })
    );
  }

  private encryptRequest<T extends EncryptedRequestBody>(
    request: HttpRequest<T>
  ): HttpRequest<T> {
    const config = {
      params: undefined,
      body: undefined,
    };

    if (
      !request.headers.has(INTERCEPTOR_SKIPPERS.TUNNELING_BODY_INTERCEPTOR) &&
      request.body &&
      Object.keys(request.body).length > 0
    ) {
      if (
        this.securityConfig?.secure_tunneling &&
        this.securityConfig?.encryption_key
      ) {
        const encryptedBody = encryptJSONObject(
          request.body,
          this.securityConfig.encryption_key
        );
        config.body = {
          d: encryptedBody,
        };
      }
    }

    if (
      !request.headers.has(INTERCEPTOR_SKIPPERS.TUNNELING_QUERY_INTERCEPTOR) &&
      request.params &&
      request.params.keys().length > 0
    ) {
      const queryKeys = request.params.keys();
      const params = {};

      queryKeys.forEach((key) => {
        params[key] = request.params.get(key);
      });

      if (
        this.securityConfig?.secure_tunneling &&
        this.securityConfig?.encryption_key
      ) {
        const encryptedParams = encryptJSONObject(
          params,
          this.securityConfig.encryption_key
        );
        config.params = new HttpParams().append('q', encryptedParams);
      }
    }

    if (request.headers.has(INTERCEPTOR_SKIPPERS.TUNNELING_QUERY_INTERCEPTOR)) {
      const headers = request.headers.delete(
        INTERCEPTOR_SKIPPERS.TUNNELING_QUERY_INTERCEPTOR
      );
      request = request.clone({ headers });
    }

    if (request.headers.has(INTERCEPTOR_SKIPPERS.TUNNELING_BODY_INTERCEPTOR)) {
      const headers = request.headers.delete(
        INTERCEPTOR_SKIPPERS.TUNNELING_BODY_INTERCEPTOR
      );
      request = request.clone({ headers });
    }

    return request.clone(config);
  }
}
