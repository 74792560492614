<h2 mat-dialog-title>
  {{ 'references.define-custom-variable.title' | translate }}
</h2>
<mat-divider></mat-divider>
<div #mainContainer class="add-edit-category container">
  <mat-dialog-content class="main-container">
    <mat-dialog-content class="mat-typography">
      <form class="container">
        <div class="row filter-row">
          <mat-form-field appearance="fill" width="100%" class="filter-element">
            <mat-label>{{
              'references.define-custom-variable.variable-name-title'
                | translate
            }}</mat-label>
            <input
              matInput
              required
              type="text"
              [formControl]="variableName"
              placeholder="{{
                'references.define-custom-variable.variable-name-placeholder'
                  | translate
              }}"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" width="100%" class="filter-element">
            <mat-label>{{
              'references.define-custom-variable.default-value-title'
                | translate
            }}</mat-label>
            <input
              matInput
              [type]="customVariable.type.toLowerCase()"
              [formControl]="defaultValue"
              placeholder="{{
                'references.define-custom-variable.default-value-placeholder'
                  | translate
              }}"
            />
          </mat-form-field>
        </div>
      </form>
    </mat-dialog-content>
  </mat-dialog-content>
</div>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">
    {{ 'references.define-custom-variable.cancel' | translate }}
  </button>
  <button mat-raised-button (click)="save()" cdkFocusInitial color="primary">
    {{ 'references.define-custom-variable.save' | translate }}
  </button>
</mat-dialog-actions>
