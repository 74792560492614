<ng-container
  *ngFor="let menuItem of menuItems; let i = index"
  class="dashboard__sidenav-list_item"
>
  <!-- section -->
  <mat-list-item
    *ngIf="
      menuItem.type === 'section' && filter(menuItem.face) && !iconOnlyMode
    "
    style="
      height: 25px;
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.1);
    "
    [disabled]="menuItem.loading"
  >
    <span
      style="padding-left: 8px; font-size: 10px"
      *ngIf="!iconOnlyMode"
      matLine
      >{{ menuItem.face }}</span
    >
  </mat-list-item>

  <!-- Icon only mode (start) -->
  <mat-list-item
    id="side_menu_list_item_{{
      menuItem.face.toLowerCase().replaceAll(' ', '_')
    }}"
    attr.data-testid="side_menu_list_item_{{
      menuItem.face.toLowerCase().replaceAll(' ', '_')
    }}"
    *ngIf="
      (menuItem.type === 'link' &&
        filter(menuItem.face) &&
        !menuItem.children &&
        isVisible(menuItem.key)) ||
      (iconOnlyMode && menuItem.children && filterMatch(i))
    "
    class="dashboard__sidenav-list_item menu-left-border"
    [routerLink]="
      !iconOnlyMode || !menuItem.children ? menuItem.link : undefined
    "
    [routerLinkActive]="['accent-color', 'accent-border-color']"
    [class.mat-list-item-content]="iconOnlyMode"
    [routerLinkActiveOptions]="activeMatchOptionsExact"
    [disabled]="menuItem.loading"
    (click)="itemSelect(menuItem)"
  >
    <h4 style="padding-left: 5px" *ngIf="!iconOnlyMode" matLine>
      {{ menuItem.face }}
    </h4>
    <mat-icon *ngIf="menuItem.children && !iconOnlyMode && !menuItem.loading">
      keyboard_arrow_right
    </mat-icon>

    <!-- ----------------------------- List Items without children for Icon only mode ----------------------------------- -->
    <button
      id="side_menu_list_item_ico_{{
        menuItem.face.toLowerCase().replaceAll(' ', '_')
      }}"
      attr.data-testid="side_menu_list_item_ico_{{
        menuItem.face.toLowerCase().replaceAll(' ', '_')
      }}"
      [matTooltip]="menuItem.tooltip"
      matTooltipPosition="right"
      [disableRipple]="true"
      mat-button
      class="m-0 p-0"
      *ngIf="!menuItem.children"
    >
      <mat-icon [ngStyle]="{ 'padding-left': iconOnlyMode ? '0px' : '8px' }">
        <span *ngIf="!menuItem.loading">{{ menuItem.icon }}</span>
        <mat-spinner *ngIf="menuItem.loading" [diameter]="27"></mat-spinner>
      </mat-icon>
    </button>

    <!-- ----------------------------- List Items with children for Icon only mode ----------------------------------- -->
    <button
      id="side_menu_list_item_expandable_ico_{{
        menuItem.face.toLowerCase().replaceAll(' ', '_')
      }}"
      attr.data-testid="side_menu_list_item_expandable_ico_{{
        menuItem.face.toLowerCase().replaceAll(' ', '_')
      }}"
      [matTooltip]="menuItem.tooltip"
      matTooltipPosition="right"
      class="m-0 p-0"
      mat-button
      [disableRipple]="true"
      [matMenuTriggerFor]="afterMenu"
      #afterMenuTrigger="matMenuTrigger"
      *ngIf="iconOnlyMode && menuItem.children"
      (click)="afterMenuTrigger.openMenu()"
    >
      <mat-icon *ngIf="!menuItem.loading">{{ menuItem.icon }}</mat-icon>
      <mat-icon *ngIf="menuItem.loading">
        <mat-spinner [diameter]="27"></mat-spinner>
      </mat-icon>
    </button>

    <div class="dropdown-content movelist">
      <mat-menu #afterMenu="matMenu" class="side-menu">
        <div *ngFor="let subMenuItem of menuItem.children">
          <mat-list-item
            disableRipple
            *ngIf="menuItem.type === 'group' && filter(subMenuItem.face)"
            class="dashboard__sidenav-list_item"
            [ngStyle]="{ 'margin-top': !iconOnlyMode ? '0px' : '40px' }"
            [disabled]="subMenuItem.loading"
          >
            <button
              id="side_menu_list_item_ico_{{
                menuItem.face.toLowerCase().replaceAll(' ', '_')
              }}_sub_menu_item_{{
                subMenuItem.face.toLowerCase().replaceAll(' ', '_')
              }}"
              attr.data-testid="side_menu_list_item_ico_{{
                menuItem.face.toLowerCase().replaceAll(' ', '_')
              }}_sub_menu_item_{{
                subMenuItem.face.toLowerCase().replaceAll(' ', '_')
              }}"
              *ngIf="subMenuItem.type !== 'button'"
              disableRipple
              mat-menu-item
              class="mat-menu-item-contents"
              [routerLinkActive]="['accent-color']"
              [routerLink]="subMenuItem.link"
              [routerLinkActiveOptions]="activeMatchOptions"
            >
              {{ subMenuItem.face }}
              <mat-icon
                style="padding-right: 10px; padding-left: 25px"
                [routerLinkActive]="['accent-color']"
                [routerLink]="subMenuItem.link"
                [routerLinkActiveOptions]="activeMatchOptions"
                >{{ subMenuItem.icon }}
              </mat-icon>
            </button>
          </mat-list-item>
          <mat-list-item
            disableRipple
            *ngIf="
              menuItem.type === 'group' &&
              filter(subMenuItem.face) &&
              subMenuItem.type === 'button'
            "
            class="dashboard__sidenav-list_item"
            [ngStyle]="{ 'margin-top': !iconOnlyMode ? '0px' : '40px' }"
            [disabled]="subMenuItem.loading"
          >
            <div class="button-container">
              <button
                mat-stroked-button
                (click)="itemSelect(subMenuItem)"
                class="button-popup"
              >
                {{ subMenuItem.face }}
              </button>
            </div>
          </mat-list-item>
        </div>
      </mat-menu>
    </div>
  </mat-list-item>
  <!-- Icon only mode (end) -->

  <!-- ----------------------------- List Items with children ----------------------------------- -->
  <mat-accordion
    id="side_menu_list_item_expandable_{{
      menuItem.face.toLowerCase().replaceAll(' ', '_')
    }}"
    attr.data-testid="side_menu_list_item_expandable_{{
      menuItem.face.toLowerCase().replaceAll(' ', '_')
    }}"
    displayMode="flat"
    *ngIf="
      menuItem.children &&
      !iconOnlyMode &&
      (filter(menuItem.face) || bulkFilter(menuItem)) &&
      filterMatch(i)
    "
    class="child-headers-align"
    multi
  >
    <mat-expansion-panel
      style="padding: 0px; border: none"
      class="rounded-0"
      [expanded]="menuItem.key !== 'REFERENCES' ? menuItem.expanded : false"
      (expandedChange)="menuItem.expanded = $event"
      (opened)="beforeExpand(menuItem)"
    >
      <mat-expansion-panel-header
        class="menu-left-border rounded-0"
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
        [routerLinkActive]="['accent-color', 'accent-border-color']"
        [routerLinkActiveOptions]="{ exact: false }"
        #parentMenu="routerLinkActive"
      >
        <mat-panel-title style="font-size: 12.8px">
          {{ menuItem.face }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container
        *ngFor="let subMenuItem of menuItem.children"
        class="dashboard__sidenav-list_item"
      >
        <div>
          <mat-list-item
            *ngIf="
              subMenuItem.type === 'section' &&
              filter(subMenuItem.face) &&
              !iconOnlyMode
            "
            style="
              height: 25px;
              background-color: rgba(0, 0, 0, 0.1);
              pointer-events: none;
            "
            [disabled]="subMenuItem.loading"
          >
            <span
              style="padding-left: 8px; font-size: 10px"
              *ngIf="!iconOnlyMode"
              matLine
              >{{ subMenuItem.face }}</span
            >
          </mat-list-item>
          <mat-list-item
            id="side_menu_list_item_{{
              menuItem.face.toLowerCase().replaceAll(' ', '_')
            }}_sub_menu_item_{{
              subMenuItem.face.toLowerCase().replaceAll(' ', '_')
            }}"
            attr.data-testid="side_menu_list_item_{{
              menuItem.face.toLowerCase().replaceAll(' ', '_')
            }}_sub_menu_item_{{
              subMenuItem.face.toLowerCase().replaceAll(' ', '_')
            }}"
            disableRipple
            *ngIf="
              (subMenuItem.type === 'link' &&
                filter(subMenuItem.face) &&
                !subMenuItem.children &&
                isVisible(subMenuItem.key)) ||
              (iconOnlyMode && subMenuItem.children)
            "
            class="dashboard__sidenav-list_item menu-left-border child-element"
            [routerLinkActive]="['accent-color']"
            [routerLink]="subMenuItem.link"
            [routerLinkActiveOptions]="activeMatchOptions"
            [class.mat-list-item-content]="iconOnlyMode"
            [disabled]="subMenuItem.loading"
            (click)="itemSelect(subMenuItem)"
          >
            <h4 style="padding-left: 5px" *ngIf="!iconOnlyMode" matLine>
              {{ subMenuItem.face }}
            </h4>
            <mat-icon
              *ngIf="
                subMenuItem.children && !iconOnlyMode && !subMenuItem.loading
              "
            >
              keyboard_arrow_right
            </mat-icon>
            <!-- ----------------------------- List Items without children for Icon only mode ----------------------------------- -->

            <button
              id="side_menu_list_item_ico_{{
                menuItem.face.toLowerCase().replaceAll(' ', '_')
              }}_submenu_item_no_children_{{
                subMenuItem.face.toLowerCase().replaceAll(' ', '_')
              }}"
              attr.data-testid="side_menu_list_item_ico_{{
                menuItem.face.toLowerCase().replaceAll(' ', '_')
              }}_submenu_item_no_children_{{
                subMenuItem.face.toLowerCase().replaceAll(' ', '_')
              }}"
              [disableRipple]="true"
              mat-button
              class="m-0 p-0"
              *ngIf="!subMenuItem.children"
            >
              <mat-icon
                [ngStyle]="{ 'padding-left': iconOnlyMode ? '0px' : '8px' }"
              >
                <span *ngIf="!subMenuItem.loading">{{ subMenuItem.icon }}</span>
                <mat-spinner
                  *ngIf="subMenuItem.loading"
                  [diameter]="27"
                ></mat-spinner>
              </mat-icon>
            </button>
          </mat-list-item>
          <div
            *ngIf="subMenuItem.type === 'button'"
            class="button-container"
            style="margin-bottom: 10px"
          >
            <button
              id="side_menu_list_item_{{
                menuItem.face.toLowerCase().replaceAll(' ', '_')
              }}_submenu_item_btn{{
                subMenuItem.face
                  .toLowerCase()
                  .replace('+', '')
                  .replaceAll(' ', '_')
              }}"
              attr.data-testid="side_menu_list_item_{{
                menuItem.face.toLowerCase().replaceAll(' ', '_')
              }}_submenu_item_btn{{
                subMenuItem.face
                  .toLowerCase()
                  .replace('+', '')
                  .replaceAll(' ', '_')
              }}"
              mat-stroked-button
              (click)="itemSelect(subMenuItem)"
            >
              {{ subMenuItem.face }}
            </button>
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>

  <div
    id="side_menu_list_item_btn_{{
      menuItem.face.toLowerCase().replaceAll(' ', '_')
    }}"
    attr.data-testid="side_menu_list_item_btn_{{
      menuItem.face.toLowerCase().replaceAll(' ', '_')
    }}"
    *ngIf="menuItem.type === 'button'"
    class="button-container"
  >
    <button mat-stroked-button (click)="itemSelect(menuItem)">
      {{ menuItem.face }}
    </button>
  </div>
</ng-container>
