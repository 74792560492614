import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import {
  CommonResponseDTO,
  IApiKey,
  IPublicKeyResponse,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ExternalSystemKeysService {
  constructor(private http: HttpClient) {}

  saveGeneratedPublicApiKey(
    apiKey: string,
    reference: string
  ): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_SAVE_KEY,
    });
    return this.http.post<CommonResponseDTO<IPublicKeyResponse>>(url, {
      apiKey,
      reference,
    });
  }

  generatePublicApiKey(): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GENERATE_NEW_KEY,
    });
    return this.http.get<CommonResponseDTO<IPublicKeyResponse>>(url);
  }

  getGeneratedKeys(): Observable<CommonResponseDTO<IApiKey[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ALL,
    });
    return this.http.get<CommonResponseDTO<IApiKey[]>>(url);
  }

  getGeneratedKey(
    id: string
  ): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ONE,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<IPublicKeyResponse>>(url);
  }

  deleteKey(id: string): Observable<CommonResponseDTO<IApiKey>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_DELETE_ONE,
      params: { id },
    });
    return this.http.delete<CommonResponseDTO<IApiKey>>(url);
  }

  restoreKey(id: string): Observable<CommonResponseDTO<IApiKey>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_RESTORE_ONE,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IApiKey>>(url, {});
  }
}
