/* eslint-disable @typescript-eslint/ban-types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';

export interface IPwaOptions {
  is_enabled: boolean;
}

type PWAResponse = CommonResponseDTO<IPwaOptions>;

@Injectable({
  providedIn: 'root',
})
export class PWAService {
  public dataStore = new BehaviorSubject<IPwaOptions>(undefined);

  constructor(private http: HttpClient) {}

  loadPWAData(): Observable<PWAResponse> {
    return this.http.get<PWAResponse>(URLS.PWA).pipe(
      tap(({ data }) => {
        this.dataStore.next(data);
      })
    );
  }

  updatePWASettings(pwaOptions: IPwaOptions, successCallback?: Function): void {
    const headers = {};
    this.http
      .put<CommonResponseDTO<IPwaOptions>>(URLS.PWA, pwaOptions, headers)
      .subscribe(({ success, data }) => {
        if (success) {
          this.dataStore.next(data);
          successCallback?.(); // TODO:@oshen handle error
        }
      });
  }
}
