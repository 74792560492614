import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { MODULES, PERMISSION_ACTIONS } from '@shared/constants';
import { getModulePermission } from '@shared/utils';

import {
  LoggedUserService,
  UserInfoResponseDTO,
} from '../../modules/auth/services';
import { ModulesService } from '../../modules/modules/services/modules.service';
import {
  IAM_STATUS,
  IamConfigService,
} from '../../modules/setup/iam-config/services/iam-config.service';
import {
  DMS_STATUS,
  StorageSetupService,
} from '../../modules/setup/storage/services/storage-setup.service';
import { FlowControlService } from '../../services/flow-control.service';
import {
  IShowModule,
  PermissionFiltrationService,
} from '../../services/permission-filtration.service';

export interface IModuleIconData {
  routerLink: string;
  tooltip: string;
  image: string;
  alt: string;
  title: string;
  searchKey: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  moduleFilterValue = '';
  filteredModuleCards = [];
  loggedUserInfo: UserInfoResponseDTO;
  noticeCount = 0;
  isNoticesModuleEnabled = false;
  isNoticesModuleReadPermission = false;
  private subscriptions = new Subscription();

  isInternalIAM = true;
  isInternalStorage = true;

  isEmptyModules = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    route: ActivatedRoute,
    flowControlService: FlowControlService,
    private permissionFiltrationService: PermissionFiltrationService,
    private loggedUserService: LoggedUserService,
    private modulesService: ModulesService,
    private storageConfigService: StorageSetupService,
    private iamConfigService: IamConfigService
  ) {
    flowControlService.updateRouteInfo(
      router.url.split('?')[0],
      route.snapshot.data
    );
  }

  ngOnInit() {
    this.initializeLoggedUserData();
    this.initializeModulesData();
    this.router.navigate([], {
      queryParams: {
        returnUrl: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeLoggedUserData(): void {
    this.loggedUserService.dataStore.subscribe((data) => {
      this.loggedUserInfo = data;
      this.isNoticesModuleReadPermission =
        this.permissionFiltrationService.validatePermissions({
          permissions: getModulePermission(MODULES.NOTICES, [
            PERMISSION_ACTIONS.READ,
          ]),
        });
      this.initializeIamConfig();
      this.initializeConnectedStorageConfig();
      this.getAllModules();
    });
  }

  initializeModulesData(): void {
    this.subscriptions.add(
      this.modulesService
        .listenToAvailability([MODULES.NOTICES])
        .subscribe((change) => {
          this.isNoticesModuleEnabled = change;
        })
    );
  }

  private initializeIamConfig(): void {
    this.subscriptions.add(
      this.iamConfigService.dataStore.subscribe((data) => {
        const iamStatus = data?.iam?.status;
        if (
          iamStatus === IAM_STATUS.INTERNAL ||
          iamStatus === IAM_STATUS.INTERNAL_DISCONNECTED ||
          iamStatus === IAM_STATUS.INTERNAL_PENDING
        ) {
          this.isInternalIAM = true;
        } else {
          this.isInternalIAM = false;
        }
      })
    );
  }

  private initializeConnectedStorageConfig(): void {
    this.subscriptions.add(
      this.storageConfigService.dataStore.subscribe((data) => {
        const storageStatus = data?.storage?.status;
        if (
          storageStatus === DMS_STATUS.INTERNAL ||
          storageStatus === DMS_STATUS.INTERNAL_DISCONNECTED ||
          storageStatus === DMS_STATUS.INTERNAL_PENDING ||
          storageStatus === DMS_STATUS.EXTERNAL_PENDING
        ) {
          this.isInternalStorage = true;
        } else {
          this.isInternalStorage = false;
        }
      })
    );
  }
  getAllModules() {
    this.translate
      .get([
        'pages.home.setup-module-tooltip',
        'pages.home.setup',
        'pages.home.knowledgebase-module-tooltip',
        'pages.home.knowledgebase',
        'pages.home.logs-module-tooltip',
        'pages.home.logs',
        'pages.home.permissions-module-tooltip',
        'pages.home.permissions',
        'pages.home.identities-module-tooltip',
        'pages.home.identities',
        'pages.home.modules',
        'pages.home.modules-module-tooltip',
        'pages.home.messages',
        'pages.home.messages-module-tooltip',
        'pages.home.apps',
        'pages.home.apps-module-tooltip',
        'pages.home.storages',
        'pages.home.storages-module-tooltip',
        'pages.home.connections',
        'pages.home.connections-module-tooltip',
        'pages.home.roles',
        'pages.home.roles-module-tooltip',
        'pages.home.drives',
        'pages.home.drives-module-tooltip',
        'pages.home.recycleBin',
        'pages.home.recycleBin-module-tooltip',
        'pages.home.notices',
        'pages.home.notices-module-tooltip',
      ])
      .subscribe((translations) => {
        const allModuleCards: IShowModule[] = [];
        this.modulesService.dataStore.subscribe((data) => {
          if (data) {
            data
              .filter((item) => !item?.is_hidden)
              .forEach((item) => {
                switch (item.key) {
                  case MODULES.MODULES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/modules',
                        tooltip:
                          translations['pages.home.modules-module-tooltip'],
                        image: 'assets/modules/svg/Modules.svg',
                        alt: 'modules',
                        title: translations['pages.home.modules'],
                        searchKey: 'modules',
                      },
                      allowedPermissions: getModulePermission(MODULES.MODULES, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.APPS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/apps',
                        tooltip: translations['pages.home.apps-module-tooltip'],
                        image: 'assets/modules/svg/Apps.svg',
                        alt: 'apps',
                        title: translations['pages.home.apps'],
                        searchKey: 'apps',
                      },
                      allowedPermissions: getModulePermission(MODULES.APPS, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.AUTHORIZATION_PERMISSIONS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/authorization/permissions',
                        tooltip:
                          translations['pages.home.permissions-module-tooltip'],
                        image: 'assets/modules/svg/Permissions.svg',
                        alt: 'permissions',
                        title: translations['pages.home.permissions'],
                        searchKey: 'permissions',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.AUTHORIZATION_PERMISSIONS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.AUTHORIZATION_ROLES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/authorization/roles',
                        tooltip:
                          translations['pages.home.roles-module-tooltip'],
                        image: 'assets/modules/svg/Roles.svg',
                        alt: 'roles',
                        title: translations['pages.home.roles'],
                        searchKey: 'roles',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.AUTHORIZATION_ROLES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.IDENTITIES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/identities',
                        tooltip:
                          translations['pages.home.identities-module-tooltip'],
                        image: 'assets/modules/svg/Identity.svg',
                        alt: 'identities',
                        title: translations['pages.home.identities'],
                        searchKey: 'identities',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.IDENTITIES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.CONNECTIONS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/connections',
                        tooltip:
                          translations['pages.home.connections-module-tooltip'],
                        image: 'assets/modules/svg/Connections.svg',
                        alt: 'connections',
                        title: translations['pages.home.connections'],
                        searchKey: 'connections',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.CONNECTIONS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.MESSAGES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/messages',
                        tooltip:
                          translations['pages.home.messages-module-tooltip'],
                        image: 'assets/modules/svg/Messages.svg',
                        alt: 'messages',
                        title: translations['pages.home.messages'],
                        searchKey: 'messages',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.MESSAGES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.STORAGE_DRIVE:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/storage/drives',
                        tooltip:
                          translations['pages.home.drives-module-tooltip'],
                        image: 'assets/modules/svg/Drive.svg',
                        alt: 'drives',
                        title: translations['pages.home.drives'],
                        searchKey: 'drives',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.STORAGE_DRIVE,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.STORAGE_RECYCLE_BIN:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/storage/trash',
                        tooltip:
                          translations['pages.home.recycleBin-module-tooltip'],
                        image: 'assets/modules/svg/Recycle Bin.svg',
                        alt: 'recycle bin',
                        title: translations['pages.home.recycleBin'],
                        searchKey: 'trash',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.STORAGE_RECYCLE_BIN,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.NOTICES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/notices',
                        tooltip:
                          translations['pages.home.notices-module-tooltip'],
                        image: 'assets/modules/svg/News.svg',
                        alt: 'notices',
                        title: translations['pages.home.notices'],
                        searchKey: 'notices',
                      },
                      allowedPermissions: getModulePermission(MODULES.NOTICES, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.KNOWLEDGEBASE:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/knowledgebase',
                        tooltip:
                          translations[
                            'pages.home.knowledgebase-module-tooltip'
                          ],
                        image: 'assets/modules/svg/Knowledgebase.svg',
                        alt: 'knowledgebase',
                        title: translations['pages.home.knowledgebase'],
                        searchKey: 'knowledgebase articles',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.KNOWLEDGEBASE,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.LOGS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/logs',
                        tooltip: translations['pages.home.logs-module-tooltip'],
                        image: 'assets/modules/svg/Logs.svg',
                        alt: 'logs',
                        title: translations['pages.home.logs'],
                        searchKey: 'logs',
                      },
                      allowedPermissions: getModulePermission(MODULES.LOGS, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.SETUP:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/setup/general',
                        tooltip:
                          translations['pages.home.setup-module-tooltip'],
                        image: 'assets/modules/svg/Setup.svg',
                        alt: 'setup',
                        title: translations['pages.home.setup'],
                        searchKey: 'setup',
                      },
                      allowedPermissions: getModulePermission(MODULES.SETUP, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;
                }
              });
          }

          let filteredModules = allModuleCards;

          if (!this.isInternalIAM) {
            filteredModules = filteredModules.filter((module) => {
              return !(
                module.data.routerLink.includes('/app/identities') ||
                module.data.routerLink.includes('/app/connections') ||
                module.data.routerLink.includes('/app/apps')
              );
            });
          }

          if (!this.isInternalStorage) {
            filteredModules = filteredModules.filter(
              (module) => !module.data.routerLink.includes('/app/storage/')
            );
          }

          if (this.loggedUserInfo) {
            this.filteredModuleCards =
              this.permissionFiltrationService.filterModules(filteredModules);
          } else {
            this.filteredModuleCards = [];
          }
        });
      });
  }

  filter(str: string) {
    return (
      str.toLowerCase().search(this.moduleFilterValue.trim().toLowerCase()) > -1
    );
  }

  getNoticeCount(count) {
    this.noticeCount = count;
  }

  checkFilterEmpty() {
    this.isEmptyModules =
      this.filteredModuleCards.find((value) => this.filter(value.searchKey)) ==
      undefined;
  }
}
