<button
  mat-button
  [matTooltip]="'Loading...'"
  class="create-quick-action w-100 app-border"
>
  <div class="created-quick-action">
    <button
      mat-icon-button
      class="created-quick-action_icon skeleton-loader-item"
      disabled
    >
      <mat-icon class="mr-2" style="margin-left: 1.2rem"></mat-icon>
    </button>
    <strong class="quick-action-title skeleton-loader-item"></strong>
  </div>
  <button
    mat-icon-button
    class="created-quick-action_drag skeleton-loader-item"
    disabled
  >
    <mat-icon></mat-icon>
  </button>
</button>
