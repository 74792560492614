<mat-card
  class="widget__container"
  *ngIf="hasPermissions && clickable"
  (dblclick)="onClickWidget()"
  matRipple
>
  <mat-card-content *ngIf="isLoading || !loggedUserInfo">
    <app-count-widget-skeleton></app-count-widget-skeleton>
  </mat-card-content>

  <mat-card-content
    *ngIf="!isLoading && hasFailed && !loggedUserInfo"
    class="widget__content"
  >
    <div class="load-error">
      <div class="error-content">
        <mat-icon class="error-icon">error_outline</mat-icon>
        <div class="error-message">
          <div class="pl-1 error-text">
            <span class="failed-message-bold">{{
              'widgets.error.failed-to-load' | translate
            }}</span>
            <span class="failed-message">{{
              'widgets.error.refresh' | translate
            }}</span>
          </div>
        </div>
        <button mat-icon-button (click)="refreshWidget()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>

  <mat-card-content
    class="widget__content"
    *ngIf="!hasFailed && !isLoading && loggedUserInfo"
  >
    <div class="widget-details">
      <mat-icon class="mat-widget-icon">
        {{ widget?.icon }}
      </mat-icon>

      <div class="card-details ml-3">
        <div class="description">
          {{ widget?.title | translate }}
        </div>
        <span class="count">
          {{ count?.toLocaleString('en-US') }}
        </span>
      </div>
    </div>

    <button mat-icon-button (click)="refreshWidget()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-content>
</mat-card>

<mat-card class="widget__container" *ngIf="hasPermissions && !clickable">
  <mat-card-content *ngIf="isLoading">
    <app-count-widget-skeleton></app-count-widget-skeleton>
  </mat-card-content>

  <mat-card-content *ngIf="!isLoading && hasFailed" class="widget__content">
    <div class="load-error">
      <div class="error-content">
        <mat-icon class="error-icon">error_outline</mat-icon>
        <div class="error-message">
          <div class="pl-1 error-text">
            <span class="failed-message-bold">{{
              'widgets.error.failed-to-load' | translate
            }}</span>
            <span class="failed-message">{{
              'widgets.error.refresh' | translate
            }}</span>
          </div>
        </div>
        <button mat-icon-button (click)="refreshWidget()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>

  <mat-card-content class="widget__content" *ngIf="!hasFailed && !isLoading">
    <div class="widget-details">
      <mat-icon class="mat-widget-icon">
        {{ widget?.icon }}
      </mat-icon>

      <div class="card-details ml-3">
        <div class="description">
          {{ widget?.title | translate }}
        </div>
        <span class="count">
          {{ count?.toLocaleString('en-US') }}
        </span>
      </div>
    </div>

    <button mat-icon-button (click)="refreshWidget()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-content>
</mat-card>
