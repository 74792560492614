<mat-chip
  (click)="$event.stopPropagation()"
  (focus)="$event.stopPropagation()"
  class="mat-chip"
  [disabled]="loading"
  [removable]="
    (isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()) &&
    !loading
  "
  (removed)="remove(selectedData)"
  attr.data-testid="cis_select_{{
    selectedData?.name?.toLowerCase()?.replace(' ', '_')
  }}"
>
  <label class="has-float-label">
    <el-autocomplete
      #chipInput
      class="autocomplete"
      (focusOn)="inputFocusOn = true && !loading"
      (focusOff)="inputFocusOn = false"
      [options]="(options | async) ?? []"
      [label]="selectedData?.name ?? '' | translate"
      appearance="fill"
      (itemsSelected)="updateChipValues($event)"
      [selected]="filterSelect.value"
      [readonly]="selectedDataChipProperties?.properties?.disabled ?? false"
      [disabled]="selectedDataChipProperties?.properties?.disabled ?? false"
      [displayOnlyMetaFunctionalities]="true"
      attr.data-testid="cis_select_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}_autocomplete"
    >
    </el-autocomplete>
    <div
      [ngClass]="{
        'label-focus': inputFocusOn || !!filterSelect.value,
        'label-not-focus': !(inputFocusOn || !!filterSelect.value)
      }"
      attr.data-testid="cis_select_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}_label"
    >
      {{ selectedData?.name ?? '' | translate }}
    </div>
  </label>
  <mat-icon
    matChipRemove
    *ngIf="
      isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()
    "
    [style.cursor]="loading ? 'context-menu' : 'pointer'"
    style="vertical-align: text-top"
    attr.data-testid="cis_select_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_btn_cancel"
    >cancel</mat-icon
  >
  <mat-icon
    *ngIf="isChipMandatory()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_select_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_mandatory"
    >push_pin</mat-icon
  >
  <mat-icon
    *ngIf="isChipLock()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_select_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_locked"
    >lock</mat-icon
  >
</mat-chip>
