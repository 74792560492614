import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import {
  CommonResponseDTO,
  IAnalyzeFormulaResponse,
  IEvaluateFormulaResponse,
  IFormulaElement,
  IKeyValuePair,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

type AnalyzeFormulaResponse = CommonResponseDTO<IAnalyzeFormulaResponse>;

type EvaluateFormulaResponse = CommonResponseDTO<IEvaluateFormulaResponse>;

@Injectable({
  providedIn: 'root',
})
export class FormulaService {
  constructor(private http: HttpClient) {}

  public analyzeFormula(
    formula: IFormulaElement[]
  ): Observable<AnalyzeFormulaResponse> {
    const url = generateURL({ endpoint: ENDPOINTS.FORMULA_ANALYZE });
    const body = { formula };

    return this.http.post<AnalyzeFormulaResponse>(url, body);
  }

  public evaluateFormula(
    formula: IFormulaElement[],
    params: IKeyValuePair<string>[]
  ): Observable<EvaluateFormulaResponse> {
    const url = generateURL({ endpoint: ENDPOINTS.FORMULA_EVALUATE });
    const body = { formula, params };

    return this.http.post<EvaluateFormulaResponse>(url, body);
  }
}
