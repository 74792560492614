<!-- radial bar -->
<div
  *ngIf="properties.barType === 'radial'"
  class="set-size"
  [style.font-size.em]="radial.size"
  attr.data-testid="dlt_progress_bar_radial_{{
    properties.label.toLowerCase().replace(' ', '_')
  }}"
>
  <div>
    <div class="pie-wrapper progress-radial style-2">
      <span class="label" [style.color]="properties.labelColor">
        {{ properties.label }}
        <span class="smaller"></span>
      </span>
      <div class="pie">
        <div
          class="half-circle left-side"
          [style.border-color]="properties.primaryColor"
          [style.transform]="'rotate(' + properties.progress * 3.6 + 'deg)'"
          [style.border]="
            properties.progress > 0
              ? properties.primaryColor + ' ' + radial.depth / 16 + 'em solid'
              : properties.secondaryColor + ' ' + radial.depth / 16 + 'em solid'
          "
        ></div>
        <div
          class="half-circle"
          [ngClass]="{
            'right-side': properties.progress <= 50,
            'right-side2': properties.progress > 50
          }"
          [style.border-color]="
            properties.progress > 50
              ? properties.primaryColor
              : properties.secondaryColor
          "
          [style.border]="
            properties.progress > 50
              ? properties.primaryColor + ' ' + radial.depth / 16 + 'em solid'
              : properties.secondaryColor + ' ' + radial.depth / 16 + 'em solid'
          "
        ></div>
      </div>
      <div
        class="shadow"
        [style.border]="
          properties.secondaryColor + ' ' + radial.depth / 16 + 'em solid'
        "
      ></div>
    </div>
  </div>
</div>

<!-- linear bar -->
<div
  *ngIf="properties.barType === 'linear'"
  [style.height.px]="linear.depth"
  [style.background-color]="properties.secondaryColor"
  class="progress-linear"
  attr.data-testid="dlt_progress_bar_linear_{{
    properties.label.toLowerCase().replace(' ', '_')
  }}"
>
  <div
    class="bar stripped active"
    [style.width.%]="properties.progress"
    [style.height.px]="linear.depth"
    [style.line-height.px]="linear.depth"
    [style.background-color]="properties.primaryColor"
  >
    <span
      [style.color]="properties.labelColor"
      [style.font-size.px]="linear.fontSize"
    >
      {{ properties.label }}
    </span>
  </div>
</div>
