import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';

import { SnackbarService } from '../../../../services/snackbar.service';

export interface IColorPaletteRequest {
  name: string;
  primaryColor: string;
  accentColor: string;
}

export interface IColorPaletteResponse {
  _id: string;
  name: string;
  path: string;
  primaryColor: string;
  accentColor: string;
}

@Injectable({
  providedIn: 'root',
})
export class ColorPaletteService {
  private _dataStore = new BehaviorSubject<IColorPaletteResponse[]>(undefined);

  public dataStore = this._dataStore.asObservable();

  constructor(
    private http: HttpClient,
    private snackBar: SnackbarService,
    private translate: TranslateService
  ) {}

  createNewColorPalette(
    colorPaletteData: IColorPaletteRequest,
    successCallback?: (data: IColorPaletteResponse) => void
  ): void {
    const headers = {};
    this.http
      .post<CommonResponseDTO<IColorPaletteResponse>>(
        URLS.COLOR_PALETTE_BASE_URL,
        colorPaletteData,
        headers
      )
      .subscribe({
        next: ({ data }) => {
          this.fetchAllColorPalettes(() => successCallback?.(data));
        },
        error: (error) => {
          if (error.error.message === 'ERR_INVALID_PALLET_INFO') {
            this.snackBar.error(
              this.translate.instant('setup.appearance.monaco.snackbar')
            );
          } else {
            this.snackBar.error(error.error.error + '.');
          }
        },
      });
  }

  fetchAllColorPalettes(successCallback?: () => void): void {
    const headers = {};
    this.http
      .get<CommonResponseDTO<IColorPaletteResponse[]>>(
        URLS.COLOR_PALETTE_BASE_URL,
        headers
      )
      .subscribe(({ success, data }) => {
        if (success) {
          this._dataStore.next(data);
          successCallback?.();
        }
      });
  }

  deleteColorPalette(colorPaletteId: string, successCallback?: () => void) {
    const url = `${URLS.COLOR_PALETTE_BASE_URL}/${colorPaletteId}`;
    const headers = {};
    this.http
      .delete<CommonResponseDTO<IColorPaletteResponse>>(url, headers)
      .subscribe(({ success }) => {
        if (success) {
          this.fetchAllColorPalettes(successCallback);
        }
      });
  }
}
