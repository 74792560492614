import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { FilesService } from '../../../../services/files.service';

/**
 * this image component can be used to fetch and display the images from the DMS
 * regardless of the external/internal configuration
 */

export interface IImagePreview {
  alt: string;
  ngStyle?: { [style_class: string]: any };
  ngClass?: string | string[] | Set<string> | { [style_class: string]: any };
}

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements OnChanges, OnDestroy {
  @Input() entityId: string; // should pass the entity id from the DMS

  @Input() imageConfig: IImagePreview;

  tokenSub: Subscription;
  imageUri: string;

  constructor(private filesService: FilesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const imageId = changes.entityId?.currentValue;
    if (imageId) {
      this.tokenSub = this.filesService
        .getDMSFilePreviewUrlListener(imageId)
        .subscribe((uri) => {
          this.imageUri = uri;
        });
    }
  }

  ngOnDestroy(): void {
    this.tokenSub.unsubscribe();
  }
}
