import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSliderChange } from '@angular/material/slider';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';

import { BaseChipComponent } from '../../base-chip/base-chip.component';
import { CIS_CHIP_TYPES } from '../../constants';
import { ChipInputSearchHelper } from '../../helpers/chip-input-search.helper';
import { SliderChipProperties } from '../../types/chip-properties/slider';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-cis-slider-chip-search-filter',
  templateUrl: './slider-chip-search-filter.component.html',
  styleUrls: ['./slider-chip-search-filter.component.scss'],
})
export class SliderChipSearchFilterComponent
  extends BaseChipComponent
  implements OnInit, OnDestroy
{
  @Output() isSliderOpen = new EventEmitter<boolean>();

  slider = new FormControl();
  openSlider = false;

  min = 1;
  max = 100;
  tickInterval = 0;
  stepSize = 1;
  override minWidth = 115;

  constructor(chipInputSearchHelper: ChipInputSearchHelper) {
    super(chipInputSearchHelper);

    this.slider.valueChanges
      .pipe(startWith(''), debounceTime(200))
      .subscribe((chipValue: number) => {
        if (this.selectedData?.chipProperties.type !== CIS_CHIP_TYPES.SLIDER)
          return;

        this.selectedData.chipProperties.value = chipValue;
        this.editedData.emit(this.selectedData);
      });
  }

  @HostListener('document:click')
  clickedOut() {
    this.openSlider = false;
  }

  ngOnInit(): void {
    if (this.selectedData?.chipProperties.type !== CIS_CHIP_TYPES.SLIDER)
      return;

    const chipProperties: SliderChipProperties =
      this.selectedData?.chipProperties;

    if (chipProperties) {
      this.min = chipProperties.properties.minLimit ?? this.min;
      this.max = chipProperties.properties.maxLimit ?? this.max;
      this.tickInterval =
        chipProperties.properties.tickInterval ?? this.tickInterval;
      this.stepSize = chipProperties.properties.stepSize ?? this.stepSize;
    }

    this.slider.setValue(this.selectedData.chipProperties.value);
    this.minWidth = this.selectedData.size || this.minWidth;

    this.slider.valueChanges
      .pipe(debounceTime(50), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.formControl = this.slider;
        this.value = this.slider.value;
        if (this.selectedData?.validations) {
          super.validate_value();
        }
        super.resize(this.minWidth);
      });
    this.formControl = this.slider;
    this.value = this.slider.value;
    super.resize(this.minWidth);
  }

  sliderValueChange(e: MatSliderChange) {
    this.slider.setValue(e.value);
  }

  onSliderBtnClick() {
    this.openSlider = !this.openSlider;
    this.isSliderOpen.emit(this.openSlider);
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
