import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { CONTACT_NUMBER_TYPES } from '@shared/constants';
import {
  IConfigurableFieldConfigResponse,
  IContactNumber,
  IContactNumberPopupConfig,
} from '@shared/interfaces';
import { isRequired } from '@shared/utils';

import { IPhoneNumberInputChange } from '../../../shared/components/phone-number-input/types';

type IContactNumberFormGroup = FormGroup<{
  is_primary: FormControl<boolean>;
  type: FormControl<CONTACT_NUMBER_TYPES>;
  phone_number: FormControl<IPhoneNumberInputChange>;
}>;

interface DialogData {
  contactData: IContactNumber[];
  fieldConfig: IConfigurableFieldConfigResponse;
  isRequired: boolean;
  popupConfig?: IContactNumberPopupConfig;
}

@Component({
  selector: 'app-set-contact-numbers',
  templateUrl: './set-contact-numbers.popup.component.html',
  styleUrls: ['./set-contact-numbers.popup.component.scss'],
})
export class SetContactNumbersPopupComponent implements OnInit {
  CONTACT_NUMBER_TYPES = CONTACT_NUMBER_TYPES;
  contactNumbers: IContactNumberFormGroup[] = [];
  isRequired = false;
  @ViewChild('contactNumberType') contactNumberType: MatSelect;

  constructor(
    private dialogRef: MatDialogRef<SetContactNumbersPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private field: DialogData
  ) {
    this.isRequired = field.isRequired;
  }

  ngOnInit(): void {
    if (this.field.contactData && this.field.contactData.length > 0) {
      this.field.contactData.forEach((element) => {
        this.contactNumbers?.push(
          this.generateContactNumberFormGroup(
            element,
            this.field?.popupConfig?.block_default
          )
        );
      });
    } else {
      this.addNewContact(undefined, this.field?.popupConfig?.block_default);
      this.contactNumbers?.[0]?.get('is_primary')?.setValue(true);
    }
    if (this.field?.popupConfig?.default_field) {
      this.setDefaultField();
    }
  }

  addNewContact(
    number?: IContactNumber,
    primary_disabled: boolean = this.field?.popupConfig?.block_default
  ) {
    this.contactNumbers?.push(
      this.generateContactNumberFormGroup(number, primary_disabled)
    );
  }

  private setDefaultField() {
    this.contactNumbers?.[0]
      ?.get('type')
      ?.setValue(this.field.popupConfig.default_field);
    this.contactNumbers?.[0]?.get('type')?.disable();
  }

  private generateContactNumberFormGroup(
    number?: IContactNumber,
    primary_disabled: boolean = false
  ): IContactNumberFormGroup {
    return new FormGroup({
      is_primary: new FormControl({
        value: number?.is_primary || false,
        disabled: primary_disabled,
      }),
      type: new FormControl(number?.type, [Validators.required]),
      phone_number: new FormControl(
        {
          ...number?.phone_number,
          valid: !!number?.phone_number,
        },
        [Validators.required]
      ),
    });
  }

  onDeleteClicked(index: number) {
    this.contactNumbers?.splice(index, 1);
  }

  radioChange(index: number) {
    if (this.field?.popupConfig?.block_default) {
      return;
    }
    if (!this.contactNumbers?.[index]?.get('is_primary')?.value) {
      // Remove other primary contacts
      // make the new one as primary contact
      this.contactNumbers?.forEach((_controls, _index) => {
        _controls?.get('is_primary').setValue(index === _index);
      });
    }
  }

  handleOnChangePhoneNumber(
    event: IPhoneNumberInputChange,
    index: number
  ): void {
    const phoneNumberControl = this.contactNumbers[index].get('phone_number');

    phoneNumberControl.setValue(event);
    phoneNumberControl.markAsDirty();
    phoneNumberControl.markAsTouched();
  }

  onSaveClicked() {
    const contactNumbers = this.contactNumbers
      .map((element) => {
        if (element) {
          const contact = element.getRawValue();

          if (contact?.phone_number?.valid) return contact;

          return null;
        }

        return null;
      })
      .filter(Boolean);
    this.dialogRef.close(contactNumbers);
  }

  isValid() {
    if (
      !isRequired(this.field?.fieldConfig?.checkboxes) &&
      this.contactNumbers?.every((contactNumber) => contactNumber.pristine)
    )
      return true;

    let hasPrimary = false;

    for (const contactNumber of this.contactNumbers) {
      if (
        !contactNumber.valid ||
        !contactNumber.controls?.phone_number?.value?.valid
      )
        return false;

      hasPrimary = hasPrimary || contactNumber.controls.is_primary.value;
    }

    return hasPrimary;
  }

  clearAll() {
    this.contactNumbers = [];
    this.addNewContact();
    this.contactNumbers[0].get('is_primary').setValue(true);
    if (this.field?.popupConfig?.default_field) {
      this.setDefaultField();
    }
  }
}
