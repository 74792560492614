import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { take } from 'rxjs';

import { USER_TYPES } from '@shared/constants';
import { IHealthResponse } from '@shared/interfaces';

import { environment } from '../environments/environment';

import { LoggedUserService } from './modules/auth/services';
import { ReleaseCheckComponent } from './modules/core/popups/release-check/release-check.component';
import { ColorSchemeService } from './modules/setup/appearance/services/color-scheme.service';
import { StyleManagerService } from './modules/setup/appearance/services/style-manager.service';
import {
  ClientDataService,
  ClientSetupResponseDTO,
} from './modules/setup/general/services/client-data.service';
import {
  GeneralSetupResponseDTO,
  SystemDataService,
} from './modules/setup/general/services/system-data.service';
import { ReleaseService } from './modules/setup/releases/services/release.service';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';

const outerRoutes = ['maintenance', 'access-denied'];
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  logoImageSource = '';
  routingReady = false;
  systemData: GeneralSetupResponseDTO;
  clientData: ClientSetupResponseDTO;

  hasReleasePopupOpened = false;

  maintenanceMode = false;
  maintenanceDialogRef: MatDialogRef<MaintenanceComponent>;

  favIcon: HTMLLinkElement = document.querySelector('#favicon');
  logoLoader: HTMLImageElement = document.querySelector('#logo-loader');

  constructor(
    private colorSchemeService: ColorSchemeService,
    public styleManager: StyleManagerService,
    private systemDataService: SystemDataService,
    private clientDataService: ClientDataService,
    private titleService: Title,
    private renderer: Renderer2,
    private releaseDataService: ReleaseService,
    private dialog: MatDialog,
    private swUpdate: SwUpdate,
    private loggedUserService: LoggedUserService,
    public router: Router,
    private metaService: Meta
  ) {
    // system information
    this.systemDataService.dataStore.subscribe((data) => {
      if (data) {
        this.systemData = data;
        this.updateSiteInfo();

        if (typeof data?.color_palette !== 'string') {
          this.installTheme(data?.color_palette?.path);
          this.favIcon.href = data?.logo_path_light;
        }

        if (data.maintenance_mode) {
          this.loggedUserService.dataStore.subscribe((userData) => {
            if (userData && userData?.user_type !== USER_TYPES.EL_ADMIN) {
              this.maintenanceMode = true;
              this.maintenanceDialogRef = this.dialog.open(
                MaintenanceComponent,
                {
                  width: '100vw',
                  height: '100vh',
                  maxWidth: '100vw',
                  maxHeight: '100vh',
                  hasBackdrop: false,
                  panelClass: 'offline-dialog',
                  data: { maintenance_mode: true },
                }
              );
            } else {
              this.maintenanceDialogRef?.close?.();
              this.maintenanceMode = false;
            }
          });
        } else {
          this.maintenanceDialogRef?.close?.();
          this.maintenanceMode = false;
        }
      }
    });

    // client information
    this.clientDataService.dataStore.subscribe((data) => {
      if (data) {
        this.clientData = data;
        this.updateSiteInfo();
        this.logoLoader.src = data?.logo_path_light;
        this.logoImageSource = data?.logo_path_light;
      }
    });

    // release information
    this.onCheckRelease();

    this.releaseDataService
      .startReleaseCheck()
      .subscribe((healthResponse: IHealthResponse) => {
        if (
          healthResponse &&
          healthResponse?.version &&
          healthResponse.version != environment.VERSION
        ) {
          this.releaseDataService.getLatestRelease1();
          this.onCheckRelease();
        }
      });

    this.colorSchemeService.load();

    this.router.events.subscribe((event) => {
      const url = (event as RouterEvent).url;
      const isAuthModule = url?.includes('app/auth');
      const isOuterRoute = outerRoutes.includes(url);
      switch (true) {
        case event instanceof NavigationStart: {
          this.routingReady = !(isAuthModule || isOuterRoute);
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
          this.routingReady = true;
          break;

        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    if (environment.production) {
      this.swUpdate.versionUpdates.subscribe();
      this.swUpdate.checkForUpdate();
    }
  }

  ngAfterViewInit() {
    const loader = this.renderer.selectRootElement('#logo-loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  onCheckRelease() {
    if (!this.hasReleasePopupOpened) {
      this.releaseDataService.releases.pipe(take(1)).subscribe((releases) => {
        if (this.releaseDataService.hasNewVersion(releases)) {
          this.releaseDataService
            .getSingleRelease(releases[0]?._id)
            .pipe(take(1))
            .subscribe((resSingle) => {
              this.hasReleasePopupOpened = true;
              this.dialog
                .open(ReleaseCheckComponent, {
                  width: '100vw',
                  height: '100vh',
                  maxWidth: '100vw',
                  maxHeight: '100vh',
                  hasBackdrop: false,
                  panelClass: 'offline-dialog',
                  data: resSingle.data,
                })
                .afterClosed()
                .subscribe(() => {
                  this.hasReleasePopupOpened = false;
                });
            });
        } else {
          this.metaService.removeTag('name="cache-control"');
          this.metaService.removeTag('name="pragma"');
          this.metaService.removeTag('name="expires"');
        }
      });
    }
  }

  private installTheme(themePath: string) {
    this.styleManager.setStyle('theme', `${themePath}`);
  }

  public updateSiteInfo() {
    this.titleService.setTitle(
      `${this.systemData?.name || 'Enterprise Resource Planning System'} | ${
        this.clientData?.name || 'EchonLabs (Pvt) Ltd.'
      }`
    );

    // TODO:@oshen add meta tags
    // <meta property="og:locale" content="language code of the system goes here" />
    // <meta property="og:type" content="type of the page goes here" />
    // <meta property="og:title" content="title of the page goes here" />
    // <meta property="og:description" content="description of the page goes here" />
    // <meta property="og:image" content="image of the page url goes here" />
    // <meta property="og:url" content="url of the page goes here" />
  }

  onCheckingNotAvailableRouters(): boolean {
    // Add router links which needs to whitelist including footer on those pages
    const whitelistRouters = ['print'];

    return whitelistRouters.some((route) => this.router.url.includes(route));
  }
}
