import { FORMULA_ELEMENT_TYPES, OPERATOR_TYPES } from '@shared/constants';
import { IFormulaOperatorElement } from '@shared/interfaces';

export const basicOperators: IFormulaOperatorElement[] = [
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.BASIC,
      displayText: 'Add',
      symbol: '+',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.BASIC,
      displayText: 'Subtract',
      symbol: '-',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.BASIC,
      displayText: 'Divide',
      symbol: '/',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.BASIC,
      displayText: 'Multiply',
      symbol: '*',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.BASIC,
      displayText: 'Power',
      symbol: '**',
    },
  },
];
