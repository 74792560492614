import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseChipComponent } from '../../base-chip/base-chip.component';
import { ChipInputSearchHelper } from '../../helpers/chip-input-search.helper';
import { ChipInputSearchService } from '../../services/chip-input-search.service';
import { ParamsKeyValuePair } from '../../types';
import { AutocompleteChipProperties } from '../../types/chip-properties/autocomplete';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-cis-autocomplete-chip-search-filter',
  templateUrl: './autocomplete-chip-search-filter.component.html',
  styleUrls: ['./autocomplete-chip-search-filter.component.scss'],
})
export class AutocompleteChipSearchFilterComponent
  extends BaseChipComponent
  implements OnInit, OnDestroy
{
  autoComplete = new FormControl();
  options: ParamsKeyValuePair[] = [];
  filteredOptions?: Observable<ParamsKeyValuePair[]>;

  get selectedDataChipProperties(): AutocompleteChipProperties | undefined {
    return this.selectedData?.chipProperties as AutocompleteChipProperties;
  }

  constructor(
    chipInputSearchHelper: ChipInputSearchHelper,
    private chipInputSearchService: ChipInputSearchService
  ) {
    super(chipInputSearchHelper);
    this.autoComplete.valueChanges
      .pipe(startWith(''), debounceTime(200))
      .subscribe((chipValue: string) => {
        if (this.selectedDataChipProperties) {
          this.selectedDataChipProperties.value = chipValue;
        }
        this.editedData.emit(this.selectedData);
      });
  }

  ngOnInit(): void {
    const properties: any = this.selectedDataChipProperties?.properties;
    this.options = properties.options;
    this.minWidth = this.selectedData?.size || this.minWidth;
    this.filteredOptions = this.autoComplete.valueChanges.pipe(
      startWith(''),
      map((value: any) => (typeof value === 'string' ? value : value?.value)),
      map((key: string) => (key ? this._filter(key) : this.options.slice()))
    );
    this.autoComplete.setValue(this.selectedDataChipProperties?.value);
    this.autoComplete.valueChanges
      .pipe(debounceTime(50), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.value = this.autoComplete.value;
        this.formControl = this.autoComplete;
        super.resize(this.minWidth);
        if (this.selectedData?.validations) {
          super.validate_value();
        }
      });
    this.formControl = this.autoComplete;
    this.value = this.autoComplete.value;
    super.resize(this.minWidth);
    this.setDependableChip();
  }

  private _filter(key: string): ParamsKeyValuePair[] {
    const filterValue = key.toLowerCase();

    return this.options.filter((option) =>
      option.key.toLowerCase().includes(filterValue)
    );
  }

  setDependableChip() {
    this.chipInputSearchService.dependableChip
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (
          data &&
          data.dependable &&
          (data.chipProperties as AutocompleteChipProperties).key ===
            this.selectedDataChipProperties?.key
        ) {
          this.selectedData = data;
          this.options = this.selectedDataChipProperties.properties.options;
        }
      });
  }

  override styleObject(): {
    marginBottom: string;
    'width.px': number | undefined;
  } {
    if (this.selectedDataChipProperties?.value) {
      return { marginBottom: '12px', 'width.px': this.textWidth };
    } else {
      return { marginBottom: '-4px', 'width.px': this.textWidth };
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
