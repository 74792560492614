<h1 mat-dialog-title class="d-flex align-items-center mb-0">
  <mat-icon> account_box </mat-icon>
  {{ 'pages.profile.upload-image' | translate }}
</h1>
<mat-card-subtitle class="subtitle">{{
  'pages.profile.upload-image-subtitle' | translate
}}</mat-card-subtitle>
<div mat-dialog-content class="box">
  <div>
    <app-image-picker
      #system_dark_picker
      (fileSubmitted)="selectImage($event)"
      [fileName]="'Profile Picture'"
      [widthSize]="'256px'"
      [hightSize]="'256px'"
      (onRemoved)="removeImage()"
      [multiple]="false"
    >
    </app-image-picker>
  </div>
</div>
<div mat-dialog-actions style="justify-content: right">
  <button
    mat-button
    [mat-dialog-close]="false"
    [matTooltip]="'pages.profile.cancel' | translate"
  >
    {{ 'pages.profile.cancel' | translate }}
  </button>
  <button
    mat-raised-button
    [disabled]="!file"
    class="mat-focus-indicator mr-2 mat-raised-button mat-button-base mat-primary"
    [matTooltip]="'pages.profile.save' | translate"
    cdkFocusInitial
    (click)="imageSubmit()"
  >
    {{ 'pages.profile.save' | translate }}
  </button>
</div>
