<mat-form-field
  *ngIf="!displayOnlyMetaFunctionalities; else metaFunctionality"
  [ngClass]="{ autocomplete: appearance === 'outline' }"
  [ngStyle]="{ opacity: disabled ? '0.5' : '1' }"
  style="width: 100%"
  [appearance]="appearance"
>
  <mat-label>{{ label }}</mat-label>

  <input matInput style="display: none" [formControl]="textInput" />

  <ng-container *ngTemplateOutlet="metaFunctionality"></ng-container>

  <button matSuffix class="material-icons" mat-icon-button (click)="onClick()">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>

  <mat-error *ngIf="textInput.hasError('required') && !hideError">
    {{ !!customError ? customError : label + ' is required' }}
  </mat-error>
</mat-form-field>

<ng-template #metaFunctionality let-formControl="formControl">
  <div (click)="onClick()">
    <input
      type="text"
      matInput
      [placeholder]="placeHolder"
      #autocompleteInput
      [matAutocomplete]="auto"
      [formControl]="textInput"
      [readonly]="!freeTextEnabled || disabled || readonly"
      [disabled]="disabled"
      [required]="required"
      (keyup)="onFreeTextInput(autocompleteInput.value)"
      (keyup.enter)="onFreeTextSelect(); optionPanelOpen()"
      (blur)="onFreeTextSelect(); optionPanelOpen()"
      autocomplete="disabled"
      (focus)="focusChanged(true, auto)"
      (blur)="focusChanged(false, auto)"
    />

    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      class="single-autocomplete"
      (opened)="focusChanged(true, auto)"
      (closed)="focusChanged(false, auto)"
    >
      <span *ngIf="!disabled">
        <div
          class="input-container"
          style="display: flex; justify-content: space-between"
        >
          <input
            type="text"
            matInput
            [placeholder]="dropdownPlaceholder"
            #innerInput
            class="inner-input"
            [formControl]="filterInput"
            autocomplete="disabled"
          />

          <button
            *ngIf="filterInput.value"
            mat-icon-button
            (click)="filterClear(); optionPanelOpen()"
            matTooltip="Clear"
          >
            <mat-icon
              class="clear-icon"
              [ngStyle]="{ cursor: readonly ? 'default' : 'pointer' }"
            >
              clear
            </mat-icon>
          </button>
        </div>

        <div *ngIf="loaderText">
          <mat-option disabled>
            <span class="d-flex align-items-center justify-content-between">
              <span>{{ loaderText }}</span>
              <mat-spinner diameter="25"></mat-spinner>
            </span>
          </mat-option>
        </div>
        <div *ngIf="isFieldsLoading">
          <mat-option disabled>
            <div class="px-0 py-2 m-0 flex-container">
              <h3
                class="pb-2 skeleton-load-item field-name-skeleton-middle"
              ></h3>
            </div>
          </mat-option>
        </div>

        <div *ngIf="!loaderText && !isFieldsLoading">
          <mat-option
            class="mat-option-autocomplete"
            *ngIf="filteredOptionsArray && filteredOptionsArray.length === 0"
            disabled
            >{{ optionsEmpty }}</mat-option
          >
          <mat-option
            class="mat-option-autocomplete"
            *ngIf="
              multiSelect &&
              !hideTheAllOption &&
              filteredOptionsArray &&
              filteredOptionsArray.length !== 0
            "
            (click)="onSelectAll()"
          >
            <span *ngIf="multiSelect" matTooltip="All"> All </span>
          </mat-option>
          <mat-option
            class="mat-option-autocomplete"
            *ngIf="
              multiSelect &&
              !optionItemDisabled &&
              !hideTheAllOption &&
              filteredOptionsArray &&
              filteredOptionsArray.length !== 0
            "
            (click)="onSelectAll()"
          >
            <span>
              <mat-checkbox
                [checked]="selectAll"
                disabled
                [class.mat-checkbox-disabled]="false"
              >
                All
              </mat-checkbox>
            </span>
          </mat-option>

          <mat-option
            class="mat-option-autocomplete"
            *ngFor="let option of filteredOptions | async"
            [value]="option.value"
            (click)="onSelect(option); optionPanelOpen()"
            [disabled]="option?.disabled && optionItemDisabled"
          >
            <span
              *ngIf="!multiSelect"
              [matTooltip]="option.displayValue || option.value"
            >
              {{ option.displayValue ?? option.value }}
            </span>
            <span *ngIf="multiSelect">
              <mat-checkbox
                [checked]="option.selected"
                disabled
                [class.mat-checkbox-disabled]="false"
              >
                {{ option.displayValue ?? option.value }}
              </mat-checkbox>
            </span>
          </mat-option>
        </div>
      </span>
    </mat-autocomplete>
  </div>
</ng-template>
