<mat-chip
  [matTooltip]="placeholders[0] || ''"
  (click)="$event.stopPropagation()"
  (focus)="$event.stopPropagation()"
  [disabled]="loading"
  [removable]="
    (isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()) &&
    !loading
  "
  (removed)="remove(selectedData)"
  attr.data-testid="cis_date_{{
    selectedData?.name?.toLowerCase()?.replace(' ', '_')
  }}"
>
  <div
    style="display: none"
    #hiddenLabel
    class="label"
    attr.data-testid="cis_date_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_label"
  >
    {{ selectedData?.name ?? '' | translate }}
  </div>

  <cis-date-chip-year-month-date-picker-search-filter
    *ngIf="pickerMode === 'year-month-date'"
    [loading]="loading"
    [isChipLock]="isChipLock()"
    [inputWidth]="
      getInputWidth(date.value?.toString()?.length ?? 0, hiddenLabel)
    "
    [formControl]="date"
    [placeholder]="placeholders[0]"
    [disabled]="isChipLock() || loading"
    [readonly]="isChipChiningParams() || isChipLock()"
    [label]="selectedData?.name ?? '' | translate"
    [openPicker]="openPicker.asObservable()"
    attr.data-testid="cis_date_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_year_month_date"
  ></cis-date-chip-year-month-date-picker-search-filter>

  <cis-date-chip-year-month-picker-search-filter
    *ngIf="pickerMode === 'year-month'"
    [loading]="loading"
    [isChipLock]="isChipLock()"
    [inputWidth]="
      getInputWidth(date.value?.toString()?.length ?? 0, hiddenLabel)
    "
    [formControl]="date"
    [placeholder]="placeholders[0]"
    [disabled]="isChipLock() || loading"
    [readonly]="isChipChiningParams() || isChipLock()"
    [label]="selectedData?.name ?? '' | translate"
    [openPicker]="openPicker.asObservable()"
    attr.data-testid="cis_date_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_year_month"
  ></cis-date-chip-year-month-picker-search-filter>

  <cis-date-chip-year-picker-search-filter
    *ngIf="pickerMode === 'year'"
    [loading]="loading"
    [isChipLock]="isChipLock()"
    [inputWidth]="
      getInputWidth(date.value?.toString()?.length ?? 0, hiddenLabel)
    "
    [formControl]="date"
    [placeholder]="placeholders[0]"
    [disabled]="isChipLock() || loading"
    [readonly]="isChipChiningParams() || isChipLock()"
    [label]="selectedData?.name ?? '' | translate"
    [openPicker]="openPicker.asObservable()"
    attr.data-testid="cis_date_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_year"
  ></cis-date-chip-year-picker-search-filter>

  <mat-icon
    matChipRemove
    *ngIf="
      isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()
    "
    [style.cursor]="loading ? 'context-menu' : 'pointer'"
    attr.data-testid="cis_date_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_btn_cancel"
    >cancel</mat-icon
  >
  <mat-icon
    *ngIf="isChipMandatory()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_date_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_mandatory"
    >push_pin
  </mat-icon>
  <mat-icon
    attr.data-testid="cis_date_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_locked"
    *ngIf="isChipLock()"
    style="vertical-align: text-top; cursor: default"
    >lock</mat-icon
  >
</mat-chip>
