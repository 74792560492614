import { Component } from '@angular/core';

@Component({
  selector: 'app-bar-chart-skeleton',
  templateUrl: './bar-chart-skeleton.component.html',
  styleUrls: ['./bar-chart-skeleton.component.scss'],
})
export class BarChartSkeletonComponent {
  widgets = [1, 2, 3, 4];
}
