import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

export enum SCREEN_SIZE {
  XS,
  SM,
  MD,
  LG,
  XL,
}

export const defaultScreenSize = {
  size: SCREEN_SIZE.MD,
  innerWidth: window.innerWidth,
};

export interface ScreenSize {
  size: SCREEN_SIZE;
  innerWidth: number;
}

@Injectable({
  providedIn: 'root',
})
export class ResizeService {
  get onWindowResize(): Observable<ScreenSize> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject: Subject<ScreenSize>;

  constructor() {
    this.resizeSubject = new Subject();
  }

  onResize(size: ScreenSize) {
    this.resizeSubject.next(size);
  }
}
