import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';

import { environment } from '../../../../../environments/environment';
import { IColorPaletteResponse } from '../../appearance/services/color-palette.api.service';

export interface GeneralSetupResponseDTO {
  name: string;
  description: string;
  web_url: string;
  footer: string;
  currency?: string;
  time_zone?: string;
  logo_path_dark?: string;
  logo_path_light?: string;
  bottom_banner_path?: string;
  color_palette?: IColorPaletteResponse | string;
  error_logging_enable?: boolean;
  error_logs_notification_emails?: string[];
  maintenance_mode?: boolean;
  sentry_logging_enable?: boolean;
}

type SetupResponse = CommonResponseDTO<GeneralSetupResponseDTO>;

@Injectable({
  providedIn: 'root',
})
export class SystemDataService {
  public dataStore = new BehaviorSubject<GeneralSetupResponseDTO | undefined>(
    undefined
  );

  constructor(private http: HttpClient) {}

  loadSystemData(): Observable<SetupResponse> {
    return this.http.get<SetupResponse>(URLS.SYSTEM_DATA_SYSTEM).pipe(
      tap(({ data }) => {
        this.dataStore.next(data);
      })
    );
  }

  updateSystemData(
    system: Partial<GeneralSetupResponseDTO>
  ): Observable<SetupResponse> {
    const body: Partial<GeneralSetupResponseDTO> = {
      ...system,
      web_url: environment.WEB_URL,
    };
    return this.http.patch<SetupResponse>(URLS.SYSTEM_DATA_SYSTEM, body).pipe(
      tap((res) => {
        this.dataStore.next(res.data);
      })
    );
  }

  addErrorLogEmail(body: { email: string }): Observable<SetupResponse> {
    return this.http
      .post<SetupResponse>(URLS.SYSTEM_DATA_ERROR_LOGS_EMAIL, body)
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }

  deleteErrorLogEmail(email: string): Observable<SetupResponse> {
    return this.http
      .delete<SetupResponse>(`${URLS.SYSTEM_DATA_ERROR_LOGS_EMAIL}/${email}`)
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }
}
