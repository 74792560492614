<div
  matAutocompleteOrigin
  #origin="matAutocompleteOrigin"
  (click)="mainInput.focus()"
  style="overflow: visible"
>
  <mat-card
    class="header-container"
    [ngClass]="
      trigger.panelOpen
        ? 'header-container--no-radius cis-shadow'
        : 'header-container--radius'
    "
    attr.data-testid="cis_page_{{
      title.toLowerCase().replace(' ', '_')
    }}_field"
  >
    <div class="left-container d-none d-md-flex" style="overflow: hidden">
      <mat-progress-spinner
        *ngIf="loading"
        class="search-spinner"
        color="accent"
        mode="indeterminate"
        [diameter]="30"
        attr.data-testid="cis_page_{{
          title.toLowerCase().replace(' ', '_')
        }}_progress_indicator"
      >
      </mat-progress-spinner>
      <button
        [color]="config.searchButtonColor"
        [matTooltip]="config.clearButtonTooltip ?? ''"
        class=""
        mat-icon-button
        [ngClass]="
          !loading &&
          (closeButtonAvailable ||
            selectedChips.length ||
            chipCtrl.value?.length)
            ? 'slide-in-left'
            : 'slide-out-left'
        "
        (click)="$event.stopPropagation(); trigger.closePanel(); reset()"
        attr.data-testid="cis_page_{{
          title.toLowerCase().replace(' ', '_')
        }}_btn_close_panel"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div
      class="wrapper"
      [ngClass]="
        loading ||
        closeButtonAvailable ||
        selectedChips.length ||
        chipCtrl.value?.length
          ? 'wrapper--no-margin'
          : 'wrapper--left-margin'
      "
    >
      <div class="inner-container">
        <mat-chip-list
          attr.data-testid="cis_page_{{
            title.toLowerCase().replace(' ', '_')
          }}_chips_list"
          #chipList
          class="mr-2"
        >
          <span *ngFor="let selectedChip of selectedChips" class="my-1">
            <el-cis-text-chip-search-filter
              *ngIf="selectedChip.chipProperties.type === chipsTypes.TEXT"
              [selectedData]="selectedChip"
              (editedData)="editedSelectedChipsValues($event)"
              (removeData)="remove($event)"
              [loading]="loading"
            >
            </el-cis-text-chip-search-filter>

            <cis-date-chip-search-filter
              *ngIf="selectedChip.chipProperties.type === chipsTypes.DATE"
              [selectedData]="selectedChip"
              (editedData)="editedSelectedChipsValues($event)"
              (removeData)="remove($event)"
              [loading]="loading"
            >
            </cis-date-chip-search-filter>

            <el-cis-date-range-chip-search-filter
              *ngIf="selectedChip.chipProperties.type === chipsTypes.DATE_RANGE"
              [labelSuffix]="config.dateRangeLabelSuffix ?? ''"
              [selectedData]="selectedChip"
              (editedData)="editedSelectedChipsValues($event)"
              (removeData)="remove($event)"
              [loading]="loading"
            >
            </el-cis-date-range-chip-search-filter>

            <el-cis-number-chip-search-filter
              *ngIf="selectedChip.chipProperties.type === chipsTypes.NUMBER"
              [selectedData]="selectedChip"
              (editedData)="editedSelectedChipsValues($event)"
              (removeData)="remove($event)"
              [loading]="loading"
            >
            </el-cis-number-chip-search-filter>

            <el-cis-number-range-chip-search-filter
              *ngIf="
                selectedChip.chipProperties.type === chipsTypes.NUMBER_RANGE
              "
              [selectedData]="selectedChip"
              (editedData)="editedSelectedChipsValues($event)"
              (removeData)="remove($event)"
              [loading]="loading"
            >
            </el-cis-number-range-chip-search-filter>

            <el-cis-slider-chip-search-filter
              *ngIf="selectedChip.chipProperties.type === chipsTypes.SLIDER"
              [selectedData]="selectedChip"
              (editedData)="editedSelectedChipsValues($event)"
              (removeData)="remove($event)"
              [loading]="loading"
              (isSliderOpen)="trigger.closePanel()"
            >
            </el-cis-slider-chip-search-filter>

            <el-cis-autocomplete-chip-search-filter
              *ngIf="
                selectedChip.chipProperties.type === chipsTypes.AUTOCOMPLETE
              "
              [selectedData]="selectedChip"
              (editedData)="editedSelectedChipsValues($event)"
              (removeData)="remove($event)"
              [loading]="loading"
            >
            </el-cis-autocomplete-chip-search-filter>

            <el-cis-select-chip-search-filter
              *ngIf="selectedChip.chipProperties.type === chipsTypes.SELECT"
              [selectedData]="selectedChip"
              (editedData)="editedSelectedChipsValues($event)"
              (removeData)="remove($event)"
              [loading]="loading"
            ></el-cis-select-chip-search-filter>

            <el-cis-filter-select-chip-search-filter
              *ngIf="
                selectedChip.chipProperties.type === chipsTypes.FILTER_SELECT
              "
              [selectedData]="selectedChip"
              (editedData)="editedSelectedChipsValues($event)"
              (removeData)="remove($event)"
              [loading]="loading"
            >
            </el-cis-filter-select-chip-search-filter>
          </span>
        </mat-chip-list>
        <textarea
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="1"
          [readOnly]="loading"
          [style.cursor]="loading ? 'context-menu' : 'auto'"
          [placeholder]="getPlaceHolder()"
          #chipInput
          #mainInput
          (blur)="this.closeButtonAvailable = false"
          [formControl]="chipCtrl"
          [matAutocomplete]="auto"
          [matAutocompleteConnectedTo]="origin"
          #trigger="matAutocompleteTrigger"
          autocomplete="off"
          [value]="chipCtrl.value"
          class="search-input"
          (focus)="trigger.openPanel()"
          attr.data-testid="cis_page_{{
            title.toLowerCase().replace(' ', '_')
          }}_text_area"
        ></textarea>
      </div>
      <div class="search-container">
        <button
          mat-flat-button
          color="accent"
          [matTooltip]="config.searchButtonText ?? ''"
          (click)="
            $event.stopPropagation(); trigger.closePanel(); onClickSearch()
          "
          attr.data-testid="cis_page_{{
            title.toLowerCase().replace(' ', '_')
          }}_btn_search"
        >
          {{ config.searchButtonText }}
        </button>
      </div>
    </div>
    <div class="right-container d-none d-md-flex">
      <button
        mat-icon-button
        [color]="config.searchButtonColor"
        [matTooltip]="config.expandTooltip ?? ''"
        (click)="$event.stopPropagation(); toggle(trigger)"
        attr.data-testid="cis_page_{{
          title.toLowerCase().replace(' ', '_')
        }}_btn_toggle_panel"
      >
        <mat-icon
          [ngClass]="trigger.panelOpen ? 'icon-expanded' : 'icon-collapsed'"
          attr.data-testid="cis_page_{{
            title.toLowerCase().replace(' ', '_')
          }}_icon_toggle_panel"
          >expand_more</mat-icon
        >
      </button>
    </div>
  </mat-card>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let section of (filteredSections | async) ?? []; let i = index"
      style="height: max-content; width: 100%; font-size: 14px"
      [disabled]="true"
      attr.data-testid="cis_page_{{
        title.toLowerCase().replace(' ', '_')
      }}_section_{{ section.section.toLowerCase().replace(' ', '_') }}"
    >
      <button mat-icon-button (click)="$event.stopPropagation()">
        <mat-icon
          class="cis-section-header"
          *ngIf="!panelClose[i]"
          style="margin-left: 7px"
          (click)="panelClose[i] = true"
          attr.data-testid="cis_page_{{
            title.toLowerCase().replace(' ', '_')
          }}_section_{{
            section.section.toLowerCase().replace(' ', '_')
          }}_btn_collapse"
          >expand_more
        </mat-icon>
        <mat-icon
          class="cis-section-header"
          *ngIf="panelClose[i]"
          style="margin-left: 7px"
          (click)="this.panelClose[i] = false"
          attr.data-testid="cis_page_{{
            title.toLowerCase().replace(' ', '_')
          }}_section_{{
            section.section.toLowerCase().replace(' ', '_')
          }}_btn_expand"
          >chevron_right
        </mat-icon>
      </button>
      <span
        attr.data-testid="cis_page_{{
          title.toLowerCase().replace(' ', '_')
        }}_section_{{ section.section.toLowerCase().replace(' ', '_') }}"
        class="cis-section-header"
        >{{ section.section }}</span
      >
      <mat-accordion>
        <mat-expansion-panel
          class="available-chip-container"
          [expanded]="!panelClose[i]"
        >
          <mat-chip-list>
            <mat-chip
              [disabled]="chip.dependable ? !chip.dependableChipEnabled : false"
              style="cursor: pointer"
              *ngFor="let chip of section.filters"
              (click)="
                chip.dependable
                  ? chip.dependableChipEnabled
                    ? selected(chip)
                    : ''
                  : selected(chip)
              "
              attr.data-testid="cis_page_{{
                title.toLowerCase().replace(' ', '_')
              }}_section_{{
                section.section.toLowerCase().replace(' ', '_')
              }}_chip_{{ chip.name.toLowerCase().replace(' ', '_') }}"
            >
              {{ chip.name | translate }}
            </mat-chip>
          </mat-chip-list>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-option>
  </mat-autocomplete>
</div>
