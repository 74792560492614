import { AUTH_TYPE, GRANT_TYPE } from '@shared/constants';

export const getAuthType = (auth: AUTH_TYPE): GRANT_TYPE => {
  switch (auth) {
    case AUTH_TYPE.OAUTH_2_PKCE:
      return GRANT_TYPE.CODE;

    default:
      return GRANT_TYPE.REFRESH_TOKEN;
  }
};
