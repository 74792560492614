<div
  class="w-100"
  style="position: relative"
  [ngClass]="{ 'no-mouse': disabled }"
>
  <ngx-intl-tel-input
    matInput
    [cssClass]="
      phoneInput.touched &&
      !disabled &&
      (phoneInput.invalid || phoneInput.hasError('required'))
        ? 'error-phone-number-input'
        : 'phone-number-input'
    "
    [id]="dataTestId"
    [attr.data-testid]="dataTestId"
    [enableAutoCountrySelect]="true"
    [enablePlaceholder]="true"
    [searchCountryFlag]="true"
    [preferredCountries]="['lk']"
    [searchCountryField]="[SearchCountryField.All]"
    [selectFirstCountry]="true"
    [selectedCountryISO]="country"
    [maxLength]="15"
    [phoneValidation]="true"
    [separateDialCode]="true"
    [numberFormat]="phoneNumberFormat.International"
    name="phoneInput"
    [formControl]="phoneInput"
    [disabled]="disabled"
  >
  </ngx-intl-tel-input>
  <mat-icon *ngIf="icon" class="phone-field-icon" matSuffix>{{
    icon
  }}</mat-icon>
  <mat-hint
    *ngIf="
      hint &&
      (!phoneInput.touched ||
        (phoneInput.touched &&
          !phoneInput.invalid &&
          !phoneInput.hasError('required')))
    "
    class="neo-phone-number-input__error"
    >{{ hint }}</mat-hint
  >
  <mat-error
    class="neo-phone-number-input__error"
    *ngIf="phoneInput.touched && !disabled && phoneInput.hasError('required')"
    >{{ 'setup.support.form.phone-number-required' | translate }}</mat-error
  >
  <mat-error
    class="neo-phone-number-input__error"
    *ngIf="
      phoneInput.touched &&
      !disabled &&
      phoneInput.invalid &&
      !phoneInput.hasError('required')
    "
    >{{ 'setup.support.form.phone-number-invalid' | translate }}</mat-error
  >
</div>
