import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { FILE_TYPES } from '@shared/constants';
import { generateFileTypeRegex } from '@shared/utils';

import { SnackbarService } from '../../../../services/snackbar.service';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
})
export class ImagePickerComponent implements OnChanges {
  @Input() multiple = false;
  @Input() allowedTypesRegex = generateFileTypeRegex([FILE_TYPES.IMAGE]);
  @Input() allowedTypesNames = 'images';
  @Input() fileName = '';
  @Input() widthSize = '';
  @Input() hightSize = '';
  @Input() defaultFiles: File[] = [];
  @Output() fileSubmitted = new EventEmitter<File[] | File>();
  @Output() onRemoved = new EventEmitter<boolean>();

  max_size = 2; // mb
  files: File[] = [];
  constructor(private snackBar: SnackbarService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultFiles?.currentValue[0]) {
      this.files = changes.defaultFiles.currentValue;
    }
  }

  onUpload(event) {
    if (this.files && this.files.length >= 1 && !this.multiple) {
      this.onRemove(this.files[0]);
    }
    this.files.push(...event.addedFiles);

    // TODO:@oshen add to translate
    for (const element of this.files) {
      if (element.type.match(this.allowedTypesRegex) == null) {
        this.snackBar.error(`Only ${this.allowedTypesNames} are supported. `);
        this.onRemove(element);
      } else if (element.size > this.max_size * 1024 * 1024) {
        this.snackBar.error('File size should be less than 2MB.');
        this.onRemove(element);
      } else if (element.size == 0) {
        this.snackBar.error('Failed to upload image.');
        this.onRemove(element);
      } else {
        this.fileSubmitted.emit(this.files[0]);
      }
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.onRemoved.emit(true);
  }

  removeAllFiles() {
    this.files = [];
  }
}
