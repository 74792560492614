import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CIS_CHIP_TYPES } from '../constants';
import {
  CISQueryParams,
  IChipSearchFilterElement,
  ParamsKeyValuePair,
} from '../types';

@Injectable({ providedIn: 'root' })
export class ChipInputSearchHelper {
  constructor(private translate: TranslateService) {}

  setChipKeyValues(
    chip: IChipSearchFilterElement,
    currentParams: CISQueryParams
  ): IChipSearchFilterElement {
    switch (chip.chipProperties?.type) {
      case CIS_CHIP_TYPES.TEXT:
      case CIS_CHIP_TYPES.DATE:
      case CIS_CHIP_TYPES.AUTOCOMPLETE:
      case CIS_CHIP_TYPES.FILTER_SELECT:
      case CIS_CHIP_TYPES.NUMBER:
      case CIS_CHIP_TYPES.SELECT:
      case CIS_CHIP_TYPES.SLIDER: {
        chip.chipProperties.value = currentParams[chip.chipProperties.key];
        break;
      }

      case CIS_CHIP_TYPES.DATE_RANGE:
      case CIS_CHIP_TYPES.NUMBER_RANGE: {
        chip.chipProperties.minValue = currentParams[
          chip.chipProperties.minKey
        ] as number | Date | undefined;
        chip.chipProperties.maxValue = currentParams[
          chip.chipProperties.maxKey
        ] as number | Date | undefined;
        break;
      }
    }

    return chip;
  }

  getChipKeyValues(chip: IChipSearchFilterElement): ParamsKeyValuePair[] {
    switch (chip.chipProperties?.type) {
      case CIS_CHIP_TYPES.TEXT:
      case CIS_CHIP_TYPES.DATE:
      case CIS_CHIP_TYPES.AUTOCOMPLETE:
      case CIS_CHIP_TYPES.FILTER_SELECT:
      case CIS_CHIP_TYPES.NUMBER:
      case CIS_CHIP_TYPES.SELECT:
      case CIS_CHIP_TYPES.SLIDER:
        return [
          {
            key: chip.chipProperties.key,
            value: chip.chipProperties.value,
          },
        ];

      case CIS_CHIP_TYPES.DATE_RANGE:
      case CIS_CHIP_TYPES.NUMBER_RANGE:
        return [
          {
            key: chip.chipProperties.minKey,
            value: chip.chipProperties.minValue,
          },
          {
            key: chip.chipProperties.maxKey,
            value: chip.chipProperties.maxValue,
          },
        ];
    }
  }

  getChipPlaceholders(chip?: IChipSearchFilterElement): string[] {
    if (!chip) return [];

    const placeHolders: string[] = [];

    switch (chip.chipProperties.type) {
      case CIS_CHIP_TYPES.TEXT:
      case CIS_CHIP_TYPES.DATE:
      case CIS_CHIP_TYPES.AUTOCOMPLETE:
      case CIS_CHIP_TYPES.FILTER_SELECT:
      case CIS_CHIP_TYPES.NUMBER:
      case CIS_CHIP_TYPES.SELECT:
      case CIS_CHIP_TYPES.SLIDER: {
        const placeHolder: string =
          chip.chipProperties.placeholder ?? this.translate.instant(chip.name);
        placeHolders.push(placeHolder);
        break;
      }

      case CIS_CHIP_TYPES.DATE_RANGE:
      case CIS_CHIP_TYPES.NUMBER_RANGE: {
        const minPlaceHolder: string =
          chip.chipProperties.minPlaceholder ??
          this.translate.instant(chip.name);
        const maxPlaceHolder: string =
          chip.chipProperties.maxPlaceholder ??
          this.translate.instant(chip.name);
        placeHolders.push(minPlaceHolder, maxPlaceHolder);
        break;
      }
    }

    return placeHolders;
  }

  checkForChipKeyInclusive(
    chip: IChipSearchFilterElement,
    keys: string[] = []
  ): boolean {
    switch (chip.chipProperties?.type) {
      case CIS_CHIP_TYPES.TEXT:
      case CIS_CHIP_TYPES.DATE:
      case CIS_CHIP_TYPES.AUTOCOMPLETE:
      case CIS_CHIP_TYPES.FILTER_SELECT:
      case CIS_CHIP_TYPES.NUMBER:
      case CIS_CHIP_TYPES.SELECT:
      case CIS_CHIP_TYPES.SLIDER:
        return keys?.includes(chip.chipProperties.key);

      case CIS_CHIP_TYPES.DATE_RANGE:
      case CIS_CHIP_TYPES.NUMBER_RANGE:
        return (
          keys?.includes(chip.chipProperties.minKey) &&
          keys?.includes(chip.chipProperties.maxKey)
        );
    }
  }

  chipHasValue(chip?: IChipSearchFilterElement): boolean {
    if (!chip) return false;

    switch (chip.chipProperties?.type) {
      case CIS_CHIP_TYPES.TEXT:
      case CIS_CHIP_TYPES.DATE:
      case CIS_CHIP_TYPES.AUTOCOMPLETE:
      case CIS_CHIP_TYPES.FILTER_SELECT:
      case CIS_CHIP_TYPES.NUMBER:
      case CIS_CHIP_TYPES.SELECT:
      case CIS_CHIP_TYPES.SLIDER:
        return !!chip.chipProperties.key && !!chip.chipProperties.value;

      case CIS_CHIP_TYPES.DATE_RANGE:
      case CIS_CHIP_TYPES.NUMBER_RANGE:
        return (
          !!chip.chipProperties.minKey &&
          !!chip.chipProperties.maxKey &&
          !!chip.chipProperties.minValue &&
          !!chip.chipProperties.maxValue
        );
    }
  }
}
