<div class="horizontal-center-container pt-5">
  <div class="d-flex justify-content-center">
    <lottie-player
      src="/assets/animations/access-denied.json"
      background="transparent"
      speed="1"
      style="width: 350px; height: 350px"
      loop
      autoplay
      alt="access-denied"
    ></lottie-player>
  </div>
</div>

<div class="row justify-content-md-center">
  <div class="col-12 d flex-center text-center">
    <h3>{{ 'pages.access-denied.error-title' | translate }}</h3>
  </div>

  <div class="col d flex-center text-center">
    <p>
      {{ 'pages.access-denied.description-line1' | translate }}
      {{ 'pages.access-denied.description-line2' | translate }}<br />
      {{ 'pages.access-denied.description-line3' | translate }}
    </p>
  </div>
</div>
