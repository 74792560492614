import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
})
export class ImageCropperDialogComponent {
  croppedImage: any = '';
  file: File;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public imageChangedEvent: unknown
  ) {}

  selectImage(event) {
    this.file = event;
  }

  removeImage() {
    this.file = null;
  }

  imageSubmit(): void {
    this.dialogRef.close(this.file);
  }
}
