import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CONTACT_EMAIL_TYPES } from '@shared/constants';
import {
  IConfigurableFieldConfigResponse,
  IContactEmail,
} from '@shared/interfaces';
import { emailValidator, isRequired } from '@shared/utils';

type IContactEmailFormGroup = FormGroup<{
  is_primary: FormControl<boolean>;
  type: FormControl<CONTACT_EMAIL_TYPES>;
  email: FormControl<string>;
}>;

interface DialogData {
  contactData: IContactEmail[];
  fieldConfig: IConfigurableFieldConfigResponse;
  isRequired: boolean;
}

@Component({
  selector: 'app-set-emails',
  templateUrl: './set-emails.popup.component.html',
})
export class SetContactEmailsPopupComponent implements OnInit {
  CONTACT_EMAIL_TYPES = CONTACT_EMAIL_TYPES;
  contactEmails: IContactEmailFormGroup[] = [];
  isRequired = false;

  constructor(
    private dialogRef: MatDialogRef<SetContactEmailsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private field: DialogData
  ) {
    this.isRequired = field.isRequired;
  }

  ngOnInit(): void {
    if (this.field.contactData && this.field.contactData.length > 0) {
      this.field.contactData.forEach((element) => {
        this.contactEmails?.push(this.generateContactEmailFormGroup(element));
      });
    } else {
      this.addNewContact();
      this.contactEmails?.[0]?.get('is_primary')?.setValue(true);
    }
  }

  addNewContact() {
    this.contactEmails?.push(this.generateContactEmailFormGroup());
    const anyPrimary = this.contactEmails.some(
      (contactEmail) => contactEmail.get('is_primary').value
    );
    if (!anyPrimary) {
      this.contactEmails?.[0]?.get('is_primary')?.setValue(true);
    }
  }

  private generateContactEmailFormGroup(
    email?: IContactEmail
  ): IContactEmailFormGroup {
    return new FormGroup({
      is_primary: new FormControl(email?.is_primary || false),
      type: new FormControl(email?.type, [Validators.required]),
      email: new FormControl(email?.email || '', [
        Validators.required,
        emailValidator(),
      ]),
    });
  }

  onDeleteClicked(index: number) {
    const contactEmailFormGroup = this.contactEmails[index];
    if (contactEmailFormGroup.get('is_primary').value === true) {
      if (this.contactEmails.length > 0) {
        this.contactEmails?.splice(index, 1);
        this.contactEmails[0].get('is_primary').setValue(true);
      }
    } else {
      this.contactEmails?.splice(index, 1);
    }
  }

  radioChange(index: number) {
    if (!this.contactEmails?.[index]?.get('is_primary')?.value) {
      // Remove other primary contacts
      // make the new one as primary contact
      this.contactEmails?.forEach((_controls, _index) => {
        _controls?.get('is_primary').setValue(index === _index);
      });
    }
  }

  onSaveClicked() {
    const contactEmails = this.contactEmails
      .map((element) => {
        if (element) {
          if (element.valid) return element.value as IContactEmail;
        }

        return null;
      })
      .filter(Boolean);

    this.dialogRef.close(contactEmails);
  }

  isNotValid() {
    if (
      !isRequired(this.field.fieldConfig?.checkboxes) &&
      this.contactEmails.every((contactEmail) =>
        this.isManualFieldsPristine(contactEmail)
      )
    )
      return false;

    let hasPrimary = false;
    for (const contactEmail of this.contactEmails) {
      if (
        !contactEmail.valid ||
        !contactEmail.get('email').valid ||
        !contactEmail.get('type').valid
      )
        return true;

      hasPrimary = hasPrimary || contactEmail.get('is_primary').value;
    }

    return !hasPrimary;
  }

  private isManualFieldsPristine(group: IContactEmailFormGroup) {
    const keys = ['type', 'email'];

    return keys.every((key) => group?.get(key).pristine);
  }

  clearAll() {
    this.contactEmails = [];
    this.addNewContact();
    this.contactEmails[0].get('is_primary').setValue(true);
  }
}
