<div class="container">
  <div class="add-edit-mergeTags container">
    <div>
      <app-monaco-editor
        size="large"
        [code]="code"
        [errors]="jsonEditorErrors"
        [language]="MONACO_SUPPORTED_LANGUAGES.JSON"
        (codeChange)="mergeTagsSave($event)"
        (errorOutput)="jsonEditorErrors = $event.errors ?? []"
      >
      </app-monaco-editor>
      <mat-dialog-actions align="end" class="mt-3">
        <button mat-button [mat-dialog-close]="false" (click)="cancel()">
          {{ 'references.add-new-reference-category.close' | translate }}
        </button>
        <button
          mat-raised-button
          (click)="save()"
          cdkFocusInitial
          color="primary"
        >
          {{ 'references.define-custom-variable.save' | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
