<mat-card class="mt-3">
  <div class="d-flex justify-content-between mb-3">
    <div class="d-flex align-items-center title-container">
      <mat-icon>notes</mat-icon>
      <mat-card-title class="px-2">
        <h6 class="title-align">
          {{ 'pages.post-it-notes.root.title' | translate }}
        </h6>
      </mat-card-title>
    </div>
    <div class="d-flex justify-content-between">
      <button
        mat-icon-button
        [matTooltip]="'pages.post-it-notes.root.tooltip-add' | translate"
        (click)="onClickAddNote()"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="'pages.post-it-notes.root.tooltip-archived' | translate"
        (click)="onClickArchive()"
      >
        <mat-spinner
          *ngIf="archiveIconLoading"
          style="margin: auto"
          [diameter]="25"
        ></mat-spinner>
        <mat-icon *ngIf="!archiveIconLoading">archive</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div *ngIf="isLoading">
    <app-skeleton-post-it-notes
      [skeletonNotes]="2"
    ></app-skeleton-post-it-notes>
  </div>
  <div *ngIf="!isLoading">
    <mat-accordion
      [multi]="true"
      cdkDropList
      *ngIf="notes?.length > 0; else noNotes"
      (cdkDropListDropped)="drop($event)"
      class="px-1"
    >
      <mat-expansion-panel
        cdkDrag
        *ngFor="let note of notes; let i = index"
        hideToggle
        (expandedChange)="panelExpanded($event, i)"
        [style.background]="
          note.loading ? 'transparent' : note.background_color
        "
        [ngClass]="{ 'app-border': note.loading }"
      >
        <mat-expansion-panel-header class="note-header">
          <div class="d-flex align-items-center note-header_title">
            <div *ngIf="note.loading" cdkDragHandle style="cursor: move">
              <button
                mat-icon-button
                class="note-header_icon skeleton-loader-item"
                disabled
              >
                <mat-icon></mat-icon>
              </button>
            </div>

            <div
              *ngIf="!note.loading"
              cdkDragHandle
              style="cursor: move; margin-bottom: auto"
            >
              <mat-icon [style.color]="note.text_color" class="drag-icon"
                >drag_indicator</mat-icon
              >
            </div>

            <div
              *ngIf="!note.loading"
              class="px-2 note-header_title_text"
              [class.note-header_title_text]="note.collapsed"
              [style.color]="note.text_color"
            >
              {{ note.title }}
            </div>
            <div
              *ngIf="note.loading"
              class="px-2 loading-color skeleton-title-text skeleton-loader-item"
            ></div>
          </div>
          <div
            *ngIf="!note.loading"
            class="note-header_date d-flex align-items-center"
            [style.color]="note.text_color"
          >
            {{ note.start_date }}
          </div>
          <div
            *ngIf="note.loading"
            class="skeleton-date-text d-flex align-items-center loading-color skeleton-loader-item"
          ></div>
        </mat-expansion-panel-header>
        <p
          *ngIf="!note.loading"
          [style.color]="note.text_color"
          class="description"
        >
          {{ note.description }}
        </p>
        <p
          *ngIf="note.loading"
          class="ml-2 mb-1 skeleton-note-expanded-description skeleton-loader-item"
        ></p>
        <p
          *ngIf="note.loading"
          class="ml-2 skeleton-note-expanded-description skeleton-loader-item"
        ></p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <ng-template #noNotes>
    <app-no-results-found *ngIf="!isLoading"></app-no-results-found>
  </ng-template>
</mat-card>
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
