<h6 mat-dialog-title class="d-flex align-items-center mb-0">
  <mat-icon class="mr-2">notes</mat-icon>
  {{ 'pages.post-it-notes.create-note.title' | translate }}
</h6>
<div class="popup-subtitle mb-1">
  {{ 'pages.post-it-notes.create-note.subtitle' | translate }}
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="createNoteForm" class="my-3">
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>{{
        'pages.post-it-notes.create-note.title-label' | translate
      }}</mat-label>
      <input
        type="text"
        matInput
        [placeholder]="
          'pages.post-it-notes.create-note.title-placeholder' | translate
        "
        formControlName="title"
        #title
        maxlength="70"
      />
      <mat-hint align="end">{{ title.value?.length || 0 }}/70</mat-hint>
      <mat-error>{{
        'pages.post-it-notes.create-note.title-required-error' | translate
      }}</mat-error>
    </mat-form-field>
    <div class="container">
      <div class="row">
        <mat-form-field class="col-md pl-0 pr-1" appearance="fill">
          <mat-label>{{
            'pages.post-it-notes.create-note.startDate-label' | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="startDatePicker"
            [placeholder]="
              'pages.post-it-notes.create-note.startDate-label' | translate
            "
            (click)="startDatePicker.open()"
            formControlName="start_date"
          />

          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-error>{{
            'pages.post-it-notes.create-note.startDate-required-error'
              | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="col-md pl-1 pr-0" appearance="fill">
          <mat-label>{{
            'pages.post-it-notes.create-note.endDate-label' | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="endDatePicker"
            [min]="createNoteForm.controls.start_date.value"
            [placeholder]="
              'pages.post-it-notes.create-note.endDate-label' | translate
            "
            (click)="endDatePicker.open()"
            formControlName="end_date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
          <mat-error
            *ngIf="createNoteForm.controls.end_date.hasError('invalidDate')"
            >{{
              'pages.post-it-notes.create-note.endDate-invalid-error'
                | translate
            }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <mat-form-field class="col-md pl-0 pr-1" appearance="fill">
          <mat-label>{{
            'pages.post-it-notes.create-note.backgroundColor-label' | translate
          }}</mat-label>
          <input
            [ngxMatColorPicker]="pickerBg"
            matInput
            [placeholder]="
              'pages.post-it-notes.create-note.backgroundColor-label'
                | translate
            "
            formControlName="background_color"
          />
          <ngx-mat-color-toggle
            matSuffix
            [for]="pickerBg"
          ></ngx-mat-color-toggle>
          <ngx-mat-color-picker #pickerBg></ngx-mat-color-picker>
        </mat-form-field>

        <mat-form-field class="col-md pl-1 pr-0" appearance="fill">
          <mat-label>{{
            'pages.post-it-notes.create-note.textColor-label' | translate
          }}</mat-label>
          <input
            [ngxMatColorPicker]="pickerText"
            matInput
            [placeholder]="
              'pages.post-it-notes.create-note.textColor-label' | translate
            "
            formControlName="text_color"
          />
          <ngx-mat-color-toggle
            matSuffix
            [for]="pickerText"
          ></ngx-mat-color-toggle>
          <ngx-mat-color-picker #pickerText></ngx-mat-color-picker>
        </mat-form-field>
      </div>
    </div>

    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>{{
        'pages.post-it-notes.create-note.description-label' | translate
      }}</mat-label>
      <textarea
        matInput
        [placeholder]="
          'pages.post-it-notes.create-note.description-placeholder' | translate
        "
        formControlName="description"
      ></textarea>
      <mat-error>{{
        'pages.post-it-notes.create-note.description-required-error' | translate
      }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="button-style" (click)="onClickCancel()">
    {{ 'pages.post-it-notes.create-note.cancel-btn' | translate }}
  </button>
  <button
    mat-flat-button
    [disabled]="createNoteForm.invalid"
    color="primary"
    class="button-style"
    (click)="onClickCreate()"
  >
    {{ 'pages.post-it-notes.create-note.save-btn' | translate }}
  </button>
</mat-dialog-actions>
