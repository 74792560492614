import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { AuthService } from './modules/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const access_token = this.authService.access_token;
    const tokenData = this.authService.access_token_decoded;

    if (
      access_token &&
      tokenData?.exp &&
      moment.utc().isBefore(moment.unix(tokenData.exp))
    ) {
      return true;
    } else {
      return this.router.createUrlTree(['/app/auth/login'], {
        queryParams: { returnUrl: state.url },
      });
    }
  }
}
