import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IReminder } from '@shared/interfaces';

import { DateTimeFormatService } from '../../../../services/date-time-format.service';

@Component({
  selector: 'app-view-reminder',
  templateUrl: './view-reminder.component.html',
  styleUrls: ['./view-reminder.component.scss'],
})
export class ViewReminderComponent implements OnInit {
  title: string;
  dateAndTime: string;
  link: string;

  constructor(
    private dialogRef: MatDialogRef<ViewReminderComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IReminder,
    private dateTimeFormatService: DateTimeFormatService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.title = this.data.title;
    this.dateAndTime = this.dateTimeFormatService.combineAndFormatDateTime(
      this.data.date,
      this.data.time
    );
    this.link = this.data.link;
  }

  onClickClose() {
    this.dialogRef.close();
  }
}
