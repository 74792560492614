<div class="row widget__content">
  <div matRipple [matRippleCentered]="false" class="notice-box d-flex col-12">
    <div class="pr-2 pl-2 date-align">
      <small class="notice-box_date skeleton-loader-item"></small>
    </div>

    <div class="pl-2 title-body-align">
      <div class="title skeleton-loader-item"></div>
      <div class="body skeleton-loader-item"></div>
    </div>
  </div>
</div>
