import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { VARIABLE_DATA_TYPES } from '@shared/constants';

@Component({
  selector: 'app-define-custom-variable',
  templateUrl: './define-custom-variable.component.html',
  styleUrls: ['./define-custom-variable.component.scss'],
})
export class DialogContentDefineCustomVariableComponent implements OnInit {
  variableName = new UntypedFormControl();
  defaultValue = new UntypedFormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public customVariable: {
      type: VARIABLE_DATA_TYPES;
      variableName: string;
      defaultValue: string | number | Date;
    },
    private dialogRef: MatDialogRef<DialogContentDefineCustomVariableComponent>
  ) {}

  ngOnInit(): void {
    if (this.customVariable) {
      this.variableName.setValue(this.customVariable.variableName);
      this.defaultValue.setValue(this.customVariable.defaultValue);
    }
  }

  save(): void {
    if (this.variableName.valid && this.variableName.value?.length > 0) {
      this.dialogRef.close({
        variableName: this.variableName.value,
        defaultValue: this.defaultValue.value,
      });
    }
  }
}
