<!-- Only single action button -->
<button
  *ngIf="buttons.length === 1"
  mat-raised-button
  class="basic-btn shadow"
  (click)="onClickAction(buttons[0].action)"
  [matTooltip]="buttons[0].tooltip | translate"
  [id]="buttons[0].id"
  [attr.data-testid]="buttons[0].dataTestId"
  color="accent"
>
  <div class="d-flex justify-content-start align-items-center">
    <mat-spinner *ngIf="buttons[0].loading" diameter="25"></mat-spinner>
    <mat-icon *ngIf="!buttons[0].loading">{{ buttons[0].icon }}</mat-icon>
    <p class="btn-text">
      {{ buttons[0].tooltip | translate }}
    </p>
  </div>
</button>

<eco-fab-speed-dial
  *ngIf="buttons.length > 1"
  #fab
  class="fab-button"
  [style.right]="rightAlign + 'px'"
  style="transition: all 0.3s ease"
>
  <eco-fab-speed-dial-trigger [spin]="true">
    <button
      mat-fab
      color="accent"
      matTooltipPosition="left"
      matTooltip="{{
        (fab.open
          ? 'shared.speed-dial.tooltip-collapse'
          : 'shared.speed-dial.tooltip-expand'
        ) | translate
      }}  (Alt/ ⌥ + E)"
      id="speedDialButton"
      [disabled]="disabled"
      class="shadow"
    >
      <mat-icon class="spin180">add</mat-icon>
    </button>
  </eco-fab-speed-dial-trigger>

  <!-- Multiple Action buttons present -->
  <eco-fab-speed-dial-actions class="multi-btn">
    <button
      *ngFor="let button of buttons"
      [id]="button.id"
      [attr.data-testid]="button.dataTestId"
      mat-mini-fab
      color="accent"
      class="shadow rounded"
      (click)="onClickAction(button.action)"
      [disabled]="disabled || button.loading"
    >
      <div class="d-flex justify-content-start mx-2">
        <mat-spinner *ngIf="button.loading" diameter="25"></mat-spinner>
        <mat-icon *ngIf="!button.loading">{{ button.icon }}</mat-icon>
        <p class="btn-text">{{ button.tooltip | translate }}</p>
      </div>
    </button>
  </eco-fab-speed-dial-actions>
</eco-fab-speed-dial>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
