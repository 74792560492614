<mat-card
  class="widget__container"
  *ngIf="hasPermissions && clickable"
  (dblclick)="onClickWidget()"
  matRipple
>
  <mat-card-content *ngIf="isLoading || !loggedUserInfo">
    <app-count-widget-skeleton></app-count-widget-skeleton>
    <div
      [style.background-color]="barBackground"
      id="color-bar-background"
      class="color-bar"
    ></div>
  </mat-card-content>

  <mat-card-content
    *ngIf="!isLoading && hasFailed && !loggedUserInfo"
    class="widget__content"
  >
    <div class="load-error">
      <div class="error-content">
        <mat-icon class="error-icon">error_outline</mat-icon>
        <div class="error-message">
          <div class="pl-1 error-text">
            <span class="failed-message-bold">{{
              'widgets.error.failed-to-load' | translate
            }}</span>
            <span class="failed-message">{{
              'widgets.error.refresh' | translate
            }}</span>
          </div>
        </div>
        <button mat-icon-button (click)="refreshWidget()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="!hasFailed && !isLoading && loggedUserInfo">
    <div class="widget__content">
      <div class="widget-details">
        <div class="card-details ml-3">
          <span class="count">
            {{ value?.toLocaleString('en-US') }}
          </span>
          <div class="description">
            {{ widget?.title | translate }}
          </div>
        </div>
      </div>

      <div>
        <button mat-icon-button (click)="refreshWidget()">
          <mat-icon>refresh</mat-icon>
        </button>

        <div *ngIf="widget?.subtitle" class="sub-container">
          <span [style.color]="barBackground" class="sub-value" id="sub-value">
            {{ subValue?.toLocaleString('en-US') }}
          </span>
          <div class="sub-title">
            {{ widget?.subtitle | translate }}
          </div>
        </div>
      </div>
    </div>
    <div
      id="color-bar-background"
      [style.background-color]="barBackground"
      class="color-bar"
    ></div>
  </mat-card-content>
</mat-card>

<mat-card class="widget__container" *ngIf="hasPermissions && !clickable">
  <mat-card-content *ngIf="isLoading">
    <app-count-widget-skeleton></app-count-widget-skeleton>
    <div
      id="color-bar-background"
      [style.background-color]="barBackground"
      class="color-bar"
    ></div>
  </mat-card-content>

  <mat-card-content *ngIf="!isLoading && hasFailed">
    <div class="widget__content">
      <div class="load-error">
        <div class="error-content">
          <mat-icon class="error-icon">error_outline</mat-icon>
          <div class="error-message">
            <div class="pl-1 error-text">
              <span class="failed-message-bold">{{
                'widgets.error.failed-to-load' | translate
              }}</span>
              <span class="failed-message">{{
                'widgets.error.refresh' | translate
              }}</span>
            </div>
          </div>
          <button mat-icon-button (click)="refreshWidget()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="!hasFailed && !isLoading">
    <div class="widget__content">
      <div class="widget-details">
        <div class="card-details ml-3">
          <span class="count">
            {{ value?.toLocaleString('en-US') }}
          </span>
          <div class="description">
            {{ widget?.title | translate }}
          </div>
        </div>
      </div>

      <div>
        <button mat-icon-button (click)="refreshWidget()">
          <mat-icon>refresh</mat-icon>
        </button>

        <div *ngIf="widget?.subtitle" class="sub-container">
          <span [style.color]="barBackground" class="sub-value" id="sub-value">
            {{ subValue?.toLocaleString('en-US') }}
          </span>
          <div class="sub-title">
            {{ widget?.subtitle | translate }}
          </div>
        </div>
      </div>
    </div>
    <div
      id="color-bar-background"
      [style.background-color]="barBackground"
      class="color-bar"
    ></div>
  </mat-card-content>
</mat-card>
