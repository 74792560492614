import { ErrorHandler, Injectable } from '@angular/core';
import { captureException } from '@sentry/angular';

import {
  DebuggingService,
  ErrorLogRequestDTO,
} from '../modules/setup/debugging/services/debugging.service';
import { SystemDataService } from '../modules/setup/general/services/system-data.service';
@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
  constructor(
    private debuggingService: DebuggingService,
    private systemData: SystemDataService
  ) {
    super();
  }

  override handleError(error: Error) {
    // Don't remove this. This is to show the error in console.
    console.error(error);

    if (this.systemData.dataStore.value?.sentry_logging_enable) {
      captureException(error);
    }

    if (this.systemData.dataStore.value?.error_logging_enable) {
      const data: ErrorLogRequestDTO = {
        error_message: error.message,
        error_name: error.name,
        error_stack: error.stack,
      };
      this.debuggingService.createErrorLog(data).subscribe();
    }
  }
}
