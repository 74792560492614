import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';

import { IJwtPayload } from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  public decodeJwt(token: string): [error: boolean, data: IJwtPayload] {
    try {
      const tokenData: IJwtPayload = jwt_decode.default(token);
      const expiryMoment = moment.unix(tokenData.exp);
      const currentMoment = moment.utc();
      if (expiryMoment.isBefore(currentMoment)) {
        // expired token
        return [true, tokenData];
      }
      return [false, tokenData];
    } catch (error) {
      return [true, { data: {} } as any];
    }
  }
}
