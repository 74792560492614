import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IMarkerData } from '../../../../shared/components/monaco-editor/monaco-editor.component';
import { MONACO_SUPPORTED_LANGUAGES } from '../../../../shared/components/monaco-editor/monaco-editor.types';

@Component({
  selector: 'app-merge-tags-editor',
  templateUrl: './merge-tags-editor.component.html',
  styleUrls: ['./merge-tags-editor.component.scss'],
})
export class MergeTagsEditorComponent {
  code: string;
  merge_tags: object;
  title: string;
  MONACO_SUPPORTED_LANGUAGES = MONACO_SUPPORTED_LANGUAGES;
  jsonEditorErrors: IMarkerData[] = [];

  constructor(
    private dialogRef: MatDialogRef<MergeTagsEditorComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      merge_tags: object;
      title: string;
    }
  ) {
    this.merge_tags = this.data.merge_tags;
    this.code = JSON.stringify(this.merge_tags);
    this.title = this.data.title;
  }

  mergeTagsSave({ data }) {
    this.merge_tags = data;
  }

  save() {
    this.dialogRef.close(this.merge_tags);
  }

  cancel() {
    this.dialogRef.close();
  }
}
