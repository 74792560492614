import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export enum NOTE_TYPE {
  WARN = 'WARN',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  DEFAULT = 'DEFAULT',
}

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnChanges {
  @Input() note = '';
  @Input() type?: NOTE_TYPE = NOTE_TYPE.DEFAULT;
  @Input() heading: string;
  @Input() centerIcon = false;
  @Input() icon = 'info';

  bgColor: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type?.currentValue) {
      switch (changes.type.currentValue) {
        case NOTE_TYPE.WARN:
          this.bgColor = 'rgba(245, 182, 66, 0.3)';
          break;

        case NOTE_TYPE.ERROR:
          this.bgColor = 'rgba(255, 0, 0, 0.3)';
          break;

        case NOTE_TYPE.SUCCESS:
          this.bgColor = 'rgba(23, 167, 0, 0.47)';
          break;

        default:
          this.bgColor = 'rgba(0, 0, 0, 0.1)';
          break;
      }
    }
  }
}
