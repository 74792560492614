<mat-card-title mat-dialog-title class="d-flex align-items-center mb-0">
  <mat-icon class="mr-2">keyboard</mat-icon>
  {{ 'core.keyboard-shortcuts.title' | translate }}
</mat-card-title>
<mat-card-subtitle class="mb-1">{{
  'core.keyboard-shortcuts.sub-title' | translate
}}</mat-card-subtitle>
<mat-divider></mat-divider>

<mat-dialog-content>
  <div class="row">
    <div class="col-12 col-md-6">
      <h6>{{ 'core.keyboard-shortcuts.basic.title' | translate }}</h6>

      <div
        class="d-flex justify-content-between list-item"
        *ngFor="let keyboardShortcutItem of basicKeyboardShortcutsList"
      >
        <div class="description">{{ keyboardShortcutItem.description }}</div>
        <div class="keys col-auto px-0 text-right">
          <span
            class="badge badge-pill"
            style="background-color: #c9c9c9"
            *ngIf="keyboardShortcutItem.firstKey"
            >{{ keyboardShortcutItem.firstKey }}</span
          >
          <span>&nbsp;</span>
          <span style="color: #c9c9c9" *ngIf="keyboardShortcutItem.firstKey"
            >+</span
          >
          <span>&nbsp;</span>
          <span class="badge badge-pill" style="background-color: #c9c9c9">{{
            keyboardShortcutItem.secondKey
          }}</span>
        </div>
      </div>

      <h6>{{ 'core.keyboard-shortcuts.navigation.title' | translate }}</h6>

      <div
        class="d-flex justify-content-between list-item"
        *ngFor="let keyboardShortcutItem of navigationKeyboardShortcutsList"
      >
        <div class="description">{{ keyboardShortcutItem.description }}</div>
        <div class="keys col-auto px-0 text-right">
          <span
            class="badge badge-pill"
            style="background-color: #c9c9c9"
            *ngIf="keyboardShortcutItem.firstKey"
            >{{ keyboardShortcutItem.firstKey }}</span
          >
          <span>&nbsp;</span>
          <span style="color: #c9c9c9" *ngIf="keyboardShortcutItem.firstKey"
            >+</span
          >
          <span>&nbsp;</span>
          <span class="badge badge-pill" style="background-color: #c9c9c9">{{
            keyboardShortcutItem.secondKey
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 pl-md-4">
      <h6>{{ 'core.keyboard-shortcuts.side-menu.title' | translate }}</h6>

      <div
        class="d-flex justify-content-between list-item"
        *ngFor="let keyboardShortcutItem of sideMenuKeyboardShortcutsList"
      >
        <div class="description">{{ keyboardShortcutItem.description }}</div>
        <div class="keys col-auto px-0 text-right">
          <span class="badge badge-pill" style="background-color: #c9c9c9">
            Shift
          </span>
          <span>&nbsp;</span>
          <span style="color: #c9c9c9">+</span>
          <span>&nbsp;</span>
          <span class="badge badge-pill" style="background-color: #c9c9c9">{{
            keyboardShortcutItem.secondKey
          }}</span>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    data-testid="shortcuts_popup_btn_close"
    mat-button
    [mat-dialog-close]="false"
  >
    {{ 'core.keyboard-shortcuts.close' | translate }}
  </button>
</mat-dialog-actions>
