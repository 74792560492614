import { WIDGET_SIZES } from '@shared/constants';

export const getWidgetLayoutFromSize = (size: WIDGET_SIZES) => {
  switch (size) {
    case WIDGET_SIZES.SMALL:
      return {
        w: 6,
        h: 1,
      };

    case WIDGET_SIZES.MEDIUM:
      return {
        w: 6,
        h: 3,
      };

    case WIDGET_SIZES.LARGE:
      return {
        w: 12,
        h: 4,
      };
  }
};
