import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  ClientDataService,
  ClientSetupResponseDTO,
} from '../../modules/setup/general/services/client-data.service';
import {
  GeneralSetupResponseDTO,
  SystemDataService,
} from '../../modules/setup/general/services/system-data.service';
import { ReleaseService } from '../../modules/setup/releases/services/release.service';
import { FlowControlService } from '../../services/flow-control.service';

import { ViewReleaseNoteComponent } from './popups/view-release-note/view-release-note.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();

  systemInformation: GeneralSetupResponseDTO;
  clientInformation: ClientSetupResponseDTO;
  showMoreAboutEchonLabs = false;

  public version: string = environment.VERSION;
  public core_version: string = environment.CORE_VERSION;
  isReleaseDataAvailable = false;

  constructor(
    private systemDataService: SystemDataService,
    private clientDataService: ClientDataService,
    private matDialog: MatDialog,
    route: ActivatedRoute,
    router: Router,
    private releasesService: ReleaseService,
    flowControlService: FlowControlService
  ) {
    flowControlService.updateRouteInfo(
      router.url.split('?')[0],
      route.snapshot.data
    );
  }

  ngOnInit() {
    this.systemDataService.dataStore
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.systemInformation = data;
      });
    this.clientDataService.dataStore
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.clientInformation = data;
      });

    this.releasesService.getAllReleases().subscribe({
      next: (res) => {
        if (res.data.length > 0) {
          this.isReleaseDataAvailable = true;
        }
      },
    });
  }

  viewReleaseNote(): void {
    this.matDialog.open(ViewReleaseNoteComponent, {
      maxWidth: '600px',
      width: '90%',
      maxHeight: '80vh',
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  errorHandler(event, type: 'banner' | 'logo'): void {
    if (type === 'banner') {
      event.target.src = '/assets/not-found-placeholder-wide.svg';
    } else {
      event.target.src = '/assets/not-found-placeholder.svg';
    }
  }
}
