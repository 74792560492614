import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridstackComponent, NgGridStackOptions } from 'gridstack/dist/angular';
import { GridStackWidget } from 'gridstack';

import { WIDGET_CATEGORIES, WIDGET_TYPES } from '@shared/constants';
import { IStorageQuota, IWidgetUserLayout } from '@shared/interfaces';

import { DIALOG_SIZES } from '../../../../constants';
import { StorageSetupService } from '../../../setup/storage/services/storage-setup.service';
import { WidgetApiService } from '../../services/widgets.api.service';
import { WidgetPanelComponent } from '../widget-panel/widget-panel.component';
import { BarChartComponent } from '../widgets/bar-chart.component/bar-chart.component';
import { CountWidgetComponent } from '../widgets/count-widget/count-widget.component';
import { DonutWidgetComponent } from '../widgets/donut-widget/donut-widget.component';
import { GroupBarChartComponent } from '../widgets/group-bar-chart/group-bar-chart.component';
import { LineChartComponent } from '../widgets/line-chart/line-chart.component';
import { NumberCardWidgetComponent } from '../widgets/number-card-widget/number-card-widget.component';

import {
  LoggedUserService,
  UserInfoResponseDTO,
} from './../../../auth/services/logged-user.service';

export interface WidgetDialogData {
  userWidgetsLayout: IWidgetUserLayout;
}
@Component({
  selector: 'app-widgets',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss'],
})
export class WidgetContainerComponent implements OnInit, OnChanges {
  @ViewChild('gridContainer', { static: false }) gridContainerRef: ElementRef;
  @ViewChild(GridstackComponent) gridComp?: GridstackComponent;

  loggedUserInfo: UserInfoResponseDTO;
  userWidgetsLayout: IWidgetUserLayout;
  WIDGET_TYPES = WIDGET_TYPES;
  isLoading = false;
  userId: string;
  storageConfig: IStorageQuota;
  widgetData;

  constructor(
    private widgetApiService: WidgetApiService,
    public elementRef: ElementRef,
    @Inject(DOCUMENT) public document: Document,
    public dialog: MatDialog,
    private loggedIdentity: LoggedUserService,
    private storageConfigService: StorageSetupService
  ) {
    GridstackComponent.addComponentToSelectorType([
      CountWidgetComponent,
      LineChartComponent,
      BarChartComponent,
      DonutWidgetComponent,
      NumberCardWidgetComponent,
      GroupBarChartComponent,
    ]);
  }

  gridOptions: NgGridStackOptions = {
    margin: 5,
    disableResize: true,
    cellHeight: 5,
    children: [],
    cellHeightUnit: 'rem',
    disableDrag: true,
    disableOneColumnMode: true,
  };

  ngOnInit() {
    this.storageConfigService.dataStore.subscribe({
      next: (res) => {
        this.storageConfig = res.storage_quota;
      },
    });

    this.getUserProfile();
    this.isLoading = true;
    this.loggedIdentity.dataStore.subscribe((data) => {
      this.loggedUserInfo = data;
      if (data) {
        this.getWidgetsData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.gridComp?.grid.batchUpdate(true);

    if (changes.gridComp?.currentValue) {
      this.updateGripOptions(changes.userWidgetsLayout?.currentValue);
      if (changes.userWidgetsLayout.firstChange) {
        setTimeout(() => {
          this.updateGripOptions(changes.userWidgetsLayout?.currentValue);
        }, 50);
      }
    }
    this.gridComp?.grid.batchUpdate(false);
  }

  getUserProfile() {
    this.loggedIdentity.dataStore.subscribe((item) => {
      if (item?.connection.role !== null) {
        this.userId = item?.connection.role?.toString();
      }
    });
  }

  getWidgetsData() {
    this.widgetApiService.getCurrentUserWidgetLayout().subscribe({
      next: (res) => {
        if (res.data.data?.length !== 0) {
          if (!this.storageConfig.is_enabled) {
            res.data.data = res.data.data.filter(
              (item) => item.widget.category_key !== WIDGET_CATEGORIES.STORAGE
            );
          }

          const widgets = res.data.data?.map((layoutData) => {
            return {
              ...layoutData,
              //TODO: remove styles from the interface and cleanup the component @ranul
              styles: {
                'grid-column-start': layoutData.position.x + 1,
                'grid-column-end': layoutData.layout.w + 1,
                'grid-row-start': layoutData.position.y + 1,
                'grid-row-end': layoutData.layout.h + 1,
              },
            };
          });

          this.userWidgetsLayout = res.data || undefined;
          this.userWidgetsLayout.data = widgets || undefined;

          setTimeout(() => {
            this.updateGripOptions(this.userWidgetsLayout);
          }, 50);
          this.isLoading = false;
        } else {
          this.userWidgetsLayout = {
            data: [],
          };
          this.isLoading = false;
        }
      },
      error: () => {
        this.userWidgetsLayout = {
          data: [],
        };
        this.isLoading = false;
      },
    });
  }

  openDialog(): void {
    const data: WidgetDialogData = {
      userWidgetsLayout: this.userWidgetsLayout,
    };

    const dialogRef = this.dialog.open(WidgetPanelComponent, {
      ...DIALOG_SIZES.FULL_SCREEN,
      data,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isLoading = true;
      this.getWidgetsData();
    });
  }

  getItemStyles(
    colStart: number,
    colEnd: number,
    rowStart: number,
    rowEnd: number
  ) {
    return {
      'grid-column-start': colStart,
      'grid-column-end': colEnd,
      'grid-row-start': rowStart,
      'grid-row-end': rowEnd,
    };
  }

  updateGripOptions(layout: IWidgetUserLayout) {
    const newLayout: GridStackWidget[] = layout.data.map((item) => {
      return {
        selector: item.widget.type,
        x: item.position.x > -1 ? item.position.x : undefined,
        y: item.position.y > -1 ? item.position.y : undefined,
        h: item.layout.h,
        w: item.layout.w,
        autoPosition: item.position.x === -1 && item.position.y === -1,
        noResize: true,
        input: {
          widget: item.widget,
          closable: false,
          clickable: true,
        },
        id: item._id?.toString() ?? Date.now().toString(),
      };
    });
    this.gridComp?.grid?.load(newLayout);
  }
}
