import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';

export interface IExtraErrorLogs {
  key: string;
  value: any;
}
export interface ErrorLogRequestDTO {
  error_message: string;
  error_name: string;
  error_stack: string;
  extra?: IExtraErrorLogs[];
}

export interface ErrorLogResponseDTO {
  error_name: string;
  error_message: string;
  error_stack?: string;
  error_code?: string;
  error_type: string;
  nature: string;
  created_on: string;
  extra?: IExtraErrorLogs[];
}

type APIResponse = CommonResponseDTO<void>;
type ErrorLogListResponse = CommonResponseDTO<ErrorLogResponseDTO[]>;

@Injectable({
  providedIn: 'root',
})
export class DebuggingService {
  constructor(private http: HttpClient) {}

  fetchAll(params: HttpParams): Observable<ErrorLogListResponse> {
    const config = { params };
    return this.http.get<ErrorLogListResponse>(URLS.DEBUGGING_BASE_URL, config);
  }

  createErrorLog(formData: ErrorLogRequestDTO): Observable<APIResponse> {
    return this.http.post<APIResponse>(URLS.DEBUGGING_BASE_URL, formData);
  }
}
