import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'el-prettyjson',
  templateUrl: './prettyjson.component.html',
  styleUrls: ['./prettyjson.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrettyjsonComponent implements OnInit {
  @Input() jsonData: JSON;
  constructor() {
    return;
  }

  ngOnInit(): void {
    return;
  }

  pretty(jsonData: any) {
    jsonData = jsonData
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    return jsonData.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function (match) {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      }
    );
  }
}
