<div class="popup_container">
  <mat-card-title-group>
    <mat-card-title>
      <div class="row d-flex title">
        <mat-icon class="ml-3 mr-2 title--icon">new_releases</mat-icon>
        <h2 mat-dialog-title class="mb-0">
          {{ 'pages.about.popup.release-title' | translate }}
        </h2>
      </div>
    </mat-card-title>
    <mat-card-subtitle class="popup-subtitle">{{
      'pages.about.popup.release-description' | translate
    }}</mat-card-subtitle>
    <mat-card class="release_field">
      <mat-select [formControl]="selectedVersion">
        <mat-option *ngFor="let release of releases" [value]="release">{{
          release.version_no
        }}</mat-option>
      </mat-select>
    </mat-card>
  </mat-card-title-group>
  <mat-divider class="mb-3"></mat-divider>

  <mat-card
    class="d-flex justify-content-center changelog"
    style="border: none; padding: 0px"
  >
    <div *ngIf="isLoading" class="custom-loading-overlay">
      <mat-progress-bar color="accent" mode="indeterminate"> </mat-progress-bar>
    </div>
    <mat-card-content class="p-2 release-notes">
      <div [innerHTML]="releaseNote"></div>
    </mat-card-content>
  </mat-card>

  <mat-dialog-actions class="d-flex justify-content-end">
    <button mat-flat-button class="m-2" (click)="close()">
      {{ 'pages.about.popup.close' | translate }}
    </button>
  </mat-dialog-actions>
</div>
