import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { isArray } from 'lodash';

import { BaseChipComponent } from '../../base-chip/base-chip.component';
import { CIS_CHIP_TYPES } from '../../constants';
import { ChipInputSearchHelper } from '../../helpers/chip-input-search.helper';
import { ChipInputSearchService } from '../../services/chip-input-search.service';
import { ParamsKeyValuePair } from '../../types';
import { SelectChipProperties } from '../../types/chip-properties/select';
import { getOptionsAsPromiseArray } from '../../utils/promise-or-array';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-cis-select-chip-search-filter',
  templateUrl: './select-chip-search-filter.component.html',
  styleUrls: ['./select-chip-search-filter.component.scss'],
})
export class SelectChipSearchFilterComponent
  extends BaseChipComponent
  implements OnInit, OnDestroy
{
  multi = false;
  selectItems: FormControl<string | string[] | undefined> = new FormControl();
  options: Promise<ParamsKeyValuePair[]> = Promise.all([]);
  selectedItemLength = 0;
  selectedOption?: ParamsKeyValuePair;

  get selectedDataChipProperties(): SelectChipProperties | undefined {
    return this.selectedData?.chipProperties as SelectChipProperties;
  }

  constructor(
    chipInputSearchHelper: ChipInputSearchHelper,
    private chipInputSearchService: ChipInputSearchService
  ) {
    super(chipInputSearchHelper);
    this.selectItems.valueChanges
      .pipe(startWith(''), debounceTime(200))
      .subscribe(async (chipValue) => {
        if (Array.isArray(chipValue)) {
          const foundOption = (await this.options).find(
            (option) => option.value === chipValue[0]
          );
          if (foundOption) {
            this.selectedOption = foundOption;
            this.selectedItemLength =
              foundOption.key?.toString()?.length -
                2 +
                (chipValue.length > 1 ? 10 : 0) || 0;
          } else {
            this.selectedItemLength = 0;
          }
        } else {
          const foundOption = (await this.options).find(
            (option) => option.value === chipValue
          );
          if (foundOption) {
            this.selectedOption = foundOption;
            this.selectedItemLength =
              foundOption.key?.toString()?.length + 2 || 0;
          } else if (chipValue === 'ALL') {
            // TODO: set the length of the all text
            this.selectedItemLength = 3;
          }
        }

        if (this.selectedDataChipProperties) {
          this.selectedDataChipProperties.value = chipValue;
        }
        this.editedData.emit(this.selectedData);
      });
  }

  ngOnInit(): void {
    this.multi = this.selectedDataChipProperties?.multi || this.multi;
    this.options = getOptionsAsPromiseArray(
      this.selectedDataChipProperties?.properties?.options
    );

    this.selectItems.setValue(this.selectedDataChipProperties?.value);
    this.minWidth = this.selectedData?.size ?? this.minWidth;
    if (this.isChipLock() || this.isChipChiningParams()) {
      this.selectItems.disable();
    }
    this.selectItems.valueChanges
      .pipe(debounceTime(50), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.formControl = this.selectItems;
        this.value = this.selectItems.value;
        super.resize(this.minWidth);
        if (this.selectedData?.validations) {
          super.validate_value();
        }
      });
    this.formControl = this.selectItems;
    this.value = this.selectItems.value;
    super.resize(this.minWidth);
    this.setDependableChip();
  }

  setDependableChip() {
    this.chipInputSearchService.dependableChip
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (
          data &&
          data.dependable &&
          this.selectedDataChipProperties &&
          this.chipInputSearchHelper
            .getChipKeyValues(data)
            .map((keyValue) => keyValue.key)
            .includes(this.selectedDataChipProperties.key) &&
          data.chipProperties.type === CIS_CHIP_TYPES.SELECT
        ) {
          this.selectedData = data;
          this.options = getOptionsAsPromiseArray(
            this.selectedDataChipProperties?.properties?.options
          );
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  get suffixDependsOnSelectedItems() {
    if (isArray(this.selectItems.value) && this.selectItems.value.length > 1)
      return `(+ ${this.selectItems.value.length - 1} other${
        this.selectItems.value.length === 2 ? '' : 's'
      })`;

    return '';
  }
}
