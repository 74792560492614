<div class="container">
  <div class="row">
    <div class="col-12" *ngIf="loading" class="loading-overlay">
      <mat-progress-bar color="accent" mode="indeterminate"> </mat-progress-bar>
    </div>
    <div class="col-1">
      <button
        mat-icon-button
        matTooltip="{{
          'references.add-new-reference-category.close' | translate
        }}"
        class="close-button"
        [mat-dialog-close]="false"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="col-7">
      <mat-form-field appearance="outline">
        <input
          type="text"
          matInput
          placeholder="Untitled"
          required
          [formControl]="name"
        />
      </mat-form-field>
    </div>
    <div class="col-3">
      <button
        class="mergeTags-button"
        mat-raised-button
        (click)="openMergeTags()"
      >
        <mat-icon>sell</mat-icon
        >{{ 'references.define-external-api.add-button' | translate }}
      </button>
    </div>
    <div class="col-1">
      <button
        class="save-button"
        mat-raised-button
        (click)="saveTemplate()"
        color="primary"
      >
        <mat-icon>print</mat-icon>
        {{ 'references.define-custom-variable.save' | translate }}
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <app-pdf-editor
    [options]="{}"
    [mergeTags]="template?.merge_tags"
    (loaded)="editorLoaded()"
    (error_mergeTag)="showMergeTagsError()"
    #editor
  ></app-pdf-editor>
</div>
