<mat-card
  class="widget__container"
  *ngIf="hasPermissions && clickable"
  matRipple
  (dblclick)="onClickWidget()"
>
  <mat-card-content *ngIf="isLoading">
    <app-bar-chart-skeleton></app-bar-chart-skeleton>
  </mat-card-content>

  <mat-card-content
    *ngIf="(isFailedToLoad && !isLoading) || !loggedUserInfo"
    class="widget__content"
  >
    <div class="load-error">
      <div class="error-content">
        <mat-icon class="error-icon">error_outline</mat-icon>
        <div class="error-message">
          <div class="pl-1 error-text">
            <span class="failed-message-bold">{{
              'widgets.error.failed-to-load' | translate
            }}</span>
            <span class="failed-message">{{
              'widgets.error.refresh' | translate
            }}</span>
          </div>
        </div>
      </div>
      <button mat-icon-button (click)="refreshWidget()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-content>

  <div class="widget__details" *ngIf="!isFailedToLoad">
    <div class="widget__description">
      <span *ngIf="widgetKey === WIDGET_KEYS.QUOTA_SYSTEM" class="title"
        >{{ widget?.title | translate }}
      </span>
      <span *ngIf="widgetKey === WIDGET_KEYS.QUOTA_CONNECTED_APPS" class="title"
        >{{ connectedApp?.general?.name }}
        {{ 'widgets.storage.drive-quota' | translate }}</span
      >
      <span *ngIf="widgetKey === WIDGET_KEYS.USER_QUOTA" class="title">
        {{ 'widgets.storage.user-quota' | translate }}</span
      >

      <span *ngIf="widgetType === STORAGE_TYPES.SYSTEM" class="subtitle">{{
        'widgets.storage.sys-widget-description' | translate
      }}</span>
      <span *ngIf="widgetType === STORAGE_TYPES.CENTRALIZED" class="subtitle"
        >{{ 'widgets.storage.track-storage' | translate }}
        {{ connectedApp?.general?.name }}
        {{ 'widgets.storage.track-storage-desc' | translate }}</span
      >
      <span *ngIf="widgetType === STORAGE_TYPES.USER" class="subtitle">{{
        'widgets.storage.user-quota-description' | translate
      }}</span>
    </div>
    <button mat-icon-button (click)="refreshWidget()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>

  <mat-card-content
    style="overflow-x: scroll; overflow-y: hidden"
    class=""
    *ngIf="!isFailedToLoad && loggedUserInfo"
  >
    <div class="d-flex justify-content-center">
      <div class="pie-widget mx-3">
        <ngx-charts-pie-chart
          [view]="[200, 200]"
          [customColors]="customColors"
          [results]="single"
          [gradient]="gradient"
          [legend]="showLegend"
          [labels]="showLabels"
          [doughnut]="isDoughnut"
        >
          <ng-template #tooltipTemplate let-model="model">
            {{ displayInfo(model) }}
          </ng-template>
        </ngx-charts-pie-chart>
      </div>
      <div class="widget-text mx-3">
        <h4 class="mb-0">
          {{
            quotaConfig?.quota === -1
              ? ('widgets.storage.unlimited' | translate)
              : total + ' ' + unit
          }}
        </h4>
        <mat-hint class="mt-0">{{
          'widgets.storage.total-quota' | translate
        }}</mat-hint>
        <mat-divider class="my-3 mx-0"></mat-divider>
        <div class="row widget-data">
          <div
            *ngFor="let item of single"
            [ngStyle]="{
              margin: 'auto',
              'border-color': borderColor(item),
              color: exceededStyle(item)
            }"
            class="data-field"
          >
            <div
              class="header-container"
              [ngStyle]="{ display: 'flex', 'align-items': 'center' }"
            >
              <h4
                class="my-0"
                [ngStyle]="{ 'margin-right': 'auto', 'white-space': 'nowrap' }"
              >
                {{
                  item.value === -1
                    ? ('widgets.storage.unlimited' | translate)
                    : item.value.toFixed(2) + ' ' + unit
                }}
              </h4>
              <button
                *ngIf="
                  item.name === FIELD_NAMES.FREE_SPACE && thresholdExceeded
                "
                class="mx-0 px-0"
                mat-icon-button
              >
                <mat-icon
                  [matTooltip]="'widgets.storage.info-text' | translate"
                  class="info-icon"
                  >info_outline</mat-icon
                >
              </button>
            </div>
            <p class="my-0">
              {{
                quotaConfig?.quota === -1
                  ? ''
                  : ((item.value / total) * 100).toFixed(2) + '%'
              }}
            </p>
            <mat-hint
              [ngStyle]="{ color: exceededStyle(item) }"
              class="data-text"
              >{{ item.name }}</mat-hint
            >
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="widget__container" *ngIf="hasPermissions && !clickable">
  <mat-card-content *ngIf="isLoading">
    <app-bar-chart-skeleton></app-bar-chart-skeleton>
  </mat-card-content>

  <mat-card-content
    *ngIf="isFailedToLoad && !isLoading"
    class="widget__content"
  >
    <div class="load-error">
      <div class="error-content">
        <mat-icon class="error-icon">error_outline</mat-icon>
        <div class="error-message">
          <div class="pl-1 error-text">
            <span class="failed-message-bold">{{
              'widgets.error.failed-to-load' | translate
            }}</span>
            <span class="failed-message">{{
              'widgets.error.refresh' | translate
            }}</span>
          </div>
        </div>
      </div>
      <button mat-icon-button (click)="refreshWidget()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-content>

  <div class="widget__details" *ngIf="!isFailedToLoad">
    <div class="widget__description">
      <span *ngIf="widgetKey === WIDGET_KEYS.QUOTA_SYSTEM" class="title"
        >{{ widget?.title | translate }}
      </span>
      <span *ngIf="widgetKey === WIDGET_KEYS.QUOTA_CONNECTED_APPS" class="title"
        >{{ connectedApp?.general?.name }}
        {{ 'widgets.storage.drive-quota' | translate }}</span
      >
      <span *ngIf="widgetKey === WIDGET_KEYS.USER_QUOTA" class="title">
        {{ 'widgets.storage.user-quota' | translate }}</span
      >

      <span *ngIf="widgetType === STORAGE_TYPES.SYSTEM" class="subtitle">{{
        'widgets.storage.sys-widget-description' | translate
      }}</span>
      <span *ngIf="widgetType === STORAGE_TYPES.CENTRALIZED" class="subtitle"
        >{{ 'widgets.storage.track-storage' | translate }}
        {{ connectedApp?.general?.name }}
        {{ 'widgets.storage.track-storage-desc' | translate }}</span
      >
      <span *ngIf="widgetType === STORAGE_TYPES.USER" class="subtitle">{{
        'widgets.storage.user-quota-description' | translate
      }}</span>
    </div>
    <button mat-icon-button (click)="refreshWidget()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>

  <mat-card-content
    style="overflow-x: scroll; overflow-y: hidden"
    class=""
    *ngIf="!isFailedToLoad"
  >
    <div class="d-flex justify-content-center">
      <div class="pie-widget mx-3">
        <ngx-charts-pie-chart
          [view]="[200, 200]"
          [customColors]="customColors"
          [results]="single"
          [gradient]="gradient"
          [legend]="showLegend"
          [labels]="showLabels"
          [doughnut]="isDoughnut"
        >
          <ng-template #tooltipTemplate let-model="model">
            {{ displayInfo(model) }}
          </ng-template>
        </ngx-charts-pie-chart>
      </div>
      <div class="widget-text mx-3">
        <h4 class="mb-0">
          {{
            quotaConfig?.quota === -1
              ? ('widgets.storage.unlimited' | translate)
              : total + ' ' + unit
          }}
        </h4>
        <mat-hint class="mt-0">{{
          'widgets.storage.total-quota' | translate
        }}</mat-hint>
        <mat-divider class="my-3 mx-0"></mat-divider>
        <div class="row widget-data">
          <div
            *ngFor="let item of single"
            [ngStyle]="{
              margin: 'auto',
              'border-color': borderColor(item)
            }"
            class="data-field"
          >
            <h4 class="my-0">
              {{
                item.value === -1
                  ? ('widgets.storage.unlimited' | translate)
                  : item.value.toFixed(2) + ' ' + unit
              }}
            </h4>
            <p class="my-0">
              {{
                quotaConfig?.quota === -1
                  ? ''
                  : ((item.value / total) * 100).toFixed(2) + '%'
              }}
            </p>
            <mat-hint class="data-text">{{ item.name }}</mat-hint>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
