<mat-nav-list>
  <div *ngFor="let language of languages">
    <mat-list-item
      attr.data-testid="language_select_language_{{
        language.title.toLowerCase().replaceAll(' ', '_')
      }}"
      (click)="useLanguage(language.code, $event)"
      [ngClass]="{ 'primary-bg': language.code === translate.currentLang }"
    >
      <a>
        <span mat-line>{{ language.title }}</span>
        <span mat-line>{{ language.code }}</span>
      </a>
    </mat-list-item>
  </div>
</mat-nav-list>
