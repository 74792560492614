import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Types } from 'mongoose';
import { Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import {
  GenerateSecretsResponseDTO,
  InitiateHandshakeRequestDTO,
} from '@shared/dtos';
import { CommonResponseDTO, IHandshake, IStorage } from '@shared/interfaces';

import {
  QuotaUpdateRequestDTO,
  UserStorageQuotaRequestDTO,
  UserStorageQuotaResponseDTO,
} from '../../setup/storage/services/storage-setup.service';
import { EndpointData } from '../../shared/components/api-doc-endpoint/api-doc-endpoint.component';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private http: HttpClient) {}

  getAllConnectedApps(params: HttpParams) {
    return this.http.get<CommonResponseDTO<IStorage[]>>(URLS.STORAGE, {
      params,
    });
  }

  getConnectedStorages() {
    return this.http.get<CommonResponseDTO<IStorage>>(`${URLS.STORAGE}`);
  }

  generateSecrets() {
    return this.http.get<CommonResponseDTO<GenerateSecretsResponseDTO>>(
      `${URLS.STORAGE}/generate`
    );
  }

  getDocumentation(storageId: string) {
    return this.http.get<CommonResponseDTO<EndpointData[]>>(
      `${URLS.STORAGE}/${storageId}/docs`
    );
  }

  initiateHandshake(
    data: InitiateHandshakeRequestDTO
  ): Observable<CommonResponseDTO<IHandshake>> {
    return this.http.post<CommonResponseDTO<IHandshake>>(
      `${URLS.STORAGE}/handshake`,
      data
    );
  }

  connectNewStorage(data: IStorage): Observable<CommonResponseDTO<IStorage>> {
    return this.http.post<CommonResponseDTO<IStorage>>(
      `${URLS.STORAGE}/connect`,
      data
    );
  }

  public getConnectedStorage(
    id: string
  ): Observable<CommonResponseDTO<IStorage>> {
    return this.http.get<CommonResponseDTO<IStorage>>(`${URLS.STORAGE}/${id}`);
  }

  updateConnectedStorage(
    data: IStorage,
    id: string
  ): Observable<CommonResponseDTO<IStorage>> {
    const url = `${URLS.STORAGE}/${id}`;
    return this.http.put<CommonResponseDTO<IStorage>>(url, data);
  }

  statusConnect(id: string): Observable<CommonResponseDTO<IStorage>> {
    const url = `${URLS.STORAGE}/${id}/connect`;
    return this.http.patch<CommonResponseDTO<IStorage>>(url, {});
  }

  statusDisconnect(id: string): Observable<CommonResponseDTO<IStorage>> {
    const url = `${URLS.STORAGE}/${id}/disconnect`;
    return this.http.patch<CommonResponseDTO<IStorage>>(url, {});
  }

  updateStorageQuota(id: string, data: QuotaUpdateRequestDTO) {
    const url = `${URLS.STORAGE}/${id}/quota`;
    return this.http.patch<CommonResponseDTO<any>>(url, data);
  }

  updateUserStorageQuota(id: string, userQuota: UserStorageQuotaRequestDTO) {
    const url = `${URLS.STORAGE}/${id}/user-quota`;
    return this.http.patch<CommonResponseDTO<UserStorageQuotaResponseDTO>>(
      url,
      userQuota
    );
  }

  getStorageSize(id: Types.ObjectId | string): Observable<any> {
    const url = `${URLS.STORAGE}/${id}/size`;
    return this.http.get(url);
  }

  getTrashSize(id: Types.ObjectId | string): Observable<any> {
    const url = `${URLS.STORAGE}/deleted-size/${id}`;
    return this.http.get(url);
  }

  getPersonalUsedSize(storageId: string, params?: HttpParams): Observable<any> {
    const url = `${URLS.STORAGE}/${storageId}/user-storage`;
    if (params) return this.http.get(url, { params });
    return this.http.get(url);
  }

  createStorageQuotaNotification(storageId: string) {
    const url = `${URLS.STORAGE}/${storageId}/quota-notification`;
    return this.http.get(url);
  }

  createUserQuotaNotification(storageId: string): Observable<any> {
    const url = `${URLS.STORAGE}/${storageId}/user-quota-notification`;
    return this.http.get(url);
  }

  getPersonalUsedSizeWithID(params: HttpParams): Observable<any> {
    const url = `${URLS.STORAGE}/user-storage`;
    return this.http.get(url, { params });
  }
}
