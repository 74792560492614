import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { ElAutocompleteModule } from '@el-autocomplete';
import { ElDataListTableModule } from '@el-data-list-table';
import { MONACO_PATH, MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { TranslateModule } from '@ngx-translate/core';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { MaterialModule } from '../../material.module';
import { CommaSeparatedDirective } from '../core/directives/comma-separated.directive';
import { CommaSeparatedWithTwoDecimalDirective } from '../core/directives/comma-separated-with-two-decimals.directive';
import { PercentageDirective } from '../core/directives/percentage.directive';
import { PercentageWithThousandsDirective } from '../core/directives/percentage-three-digits.directive';

import { ActionButtonComponent } from './components/action-button/action-button.component';
import { ApiDocEndpointComponent } from './components/api-doc-endpoint/api-doc-endpoint.component';
import { DisplayDataInDLTComponent } from './components/display-data-in-dlt/display-data-in-dlt.component';
import { DragAndDropComponent } from './components/drag-and-drop/drag-and-drop.component';
import { ImagePickerComponent } from './components/image-picker/image-picker.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { LogoLoaderComponent } from './components/logo-loader/logo-loader.component';
import { MonacoEditorComponent } from './components/monaco-editor/monaco-editor.component';
import { NoResultsFoundComponent } from './components/no-results-found/no-results-found.component';
import { NoteComponent } from './components/note/note.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { DisplayPremiumSectionComponent } from './components/premium-displayer/premium-displayer.component';
import { PrettyjsonComponent } from './components/prettyjson/prettyjson.component';
import { ReferenceFormFieldComponent } from './components/reference-form-field/reference-form-field.component';
import { SizeDetectorComponent } from './components/size-detector/size-detector.component';
import { SkeletonDataListTableComponent } from './components/skeleton-data-list-table/skeleton-data-list-table.component';
import { SkeletonDiaryComponent } from './components/skeleton-diary/skeleton-diary.component';
import { ZoomComponent } from './components/zoom/zoom.component';
import { CamelCasePipe } from './pipes/camel-case.pipe';
import { CurrencySpacePipe } from './pipes/currency.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { OEmbedToIframePipe } from './pipes/media-embed.pipe';
import { ConfirmationPopupComponent } from './popups/confirmation-popup/confirmation-popup.component';
import { DocumentUploaderComponent } from './popups/document-uploader/document-uploader.component';
import { PublicClientCredentialGeneratePopupComponent } from './popups/public-client-credential-generate-popup/public-client-credential-generate-popup.component';
import { PublicKeyGeneratePopupComponent } from './popups/public-key-generate-popup/public-key-generate-popup.component';
import { RichTextViewerComponent } from './popups/rich-text-viewer/rich-text-viewer.component';
import { GetUserConfirmationService } from './services/get-user-confirmation/service';

const components = [
  ActionButtonComponent,
  DragAndDropComponent,
  ImagePickerComponent,
  LogoLoaderComponent,
  MonacoEditorComponent,
  NoResultsFoundComponent,
  SkeletonDataListTableComponent,
  ConfirmationPopupComponent,
  ZoomComponent,
  NoteComponent,
  ApiDocEndpointComponent,
  PublicKeyGeneratePopupComponent,
  PublicClientCredentialGeneratePopupComponent,
  PhoneNumberInputComponent,
  ReferenceFormFieldComponent,
  CommaSeparatedDirective,
  PercentageDirective,
  CommaSeparatedWithTwoDecimalDirective,
  PercentageWithThousandsDirective,
  PrettyjsonComponent,
  ImagePreviewComponent,
  SkeletonDiaryComponent,
  DisplayPremiumSectionComponent,
  DocumentUploaderComponent,
  SizeDetectorComponent,
  DisplayDataInDLTComponent,
  RichTextViewerComponent,
];

const pipes = [
  CamelCasePipe,
  CurrencySpacePipe,
  FileSizePipe,
  OEmbedToIframePipe,
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [...components, ...pipes],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    MonacoEditorModule,
    ElDataListTableModule,
    EcoFabSpeedDialModule,
    NgxIntlTelInputModule,
    KeyboardShortcutsModule.forRoot(),
    ElAutocompleteModule,
    CKEditorModule,
    DragDropModule,
    MtxDatetimepickerModule,
  ],
  exports: [...components, ...pipes],
  providers: [
    {
      provide: MONACO_PATH,
      useValue: 'https://unpkg.com/monaco-editor@0.21.3/min/vs',
    },
    GetUserConfirmationService,
  ],
})
export class SharedModule {}
