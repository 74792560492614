<mat-card>
  <mat-card-content>
    <div class="row m-1 widget__content">
      <div
        matRipple
        [matRippleCentered]="false"
        class="notice-box app-border mt-1 d-flex col-6"
        *ngFor="let widget of widgets"
      >
        <div class="pr-2 pl-2 date-align">
          <small class="notice-box_date skeleton-loader-item"></small>
        </div>

        <div class="pl-2 title-body-align">
          <div class="title skeleton-loader-item"></div>
          <div class="body skeleton-loader-item"></div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
