import { FORMULA_ELEMENT_TYPES, VARIABLE_DATA_TYPES } from '@shared/constants';
import { IFormulaConstantElement } from '@shared/interfaces';

export const generalConstants: IFormulaConstantElement[] = [
  {
    type: FORMULA_ELEMENT_TYPES.CONSTANT,
    constant: {
      type: VARIABLE_DATA_TYPES.NUMBER,
      value: undefined,
      displayText: 'Constant: Number',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.CONSTANT,
    constant: {
      type: VARIABLE_DATA_TYPES.TEXT,
      value: undefined,
      displayText: 'Constant: Text',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.CONSTANT,
    constant: {
      type: VARIABLE_DATA_TYPES.DATE,
      value: undefined,
      displayText: 'Constant: Date',
    },
  },
];
