import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notify-config-change-overlay',
  templateUrl: './notify-config-change-overlay.component.html',
  styleUrls: ['./notify-config-change-overlay.component.scss'],
})
export class NotifyConfigChangeOverlayComponent implements OnInit {
  config: string;

  constructor(
    public dialogRef: MatDialogRef<NotifyConfigChangeOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) private configType: string
  ) {}

  ngOnInit(): void {
    this.config = this.configType || 'App';
  }

  onClickRefresh(): void {
    location.reload();
  }
}
