import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { CIS_CHIP_BEHAVIORS, CIS_CHIP_TYPES } from '../constants';
import { ChipInputSearchHelper } from '../helpers/chip-input-search.helper';
import {
  IChipSearchFilterElement,
  IChipSearchFilterValidatorElement,
} from '../types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-cis-base-chip',
  template: ``,
  styleUrls: ['./base-chip.component..scss'],
})
export class BaseChipComponent {
  @Input() selectedData?: IChipSearchFilterElement;
  @Input() loading = true;
  @Output() editedData = new EventEmitter<IChipSearchFilterElement>();
  @Output() removeData = new EventEmitter<IChipSearchFilterElement>();
  minWidth = 90;
  maxWidth = 160;
  chipsBehaviour = CIS_CHIP_BEHAVIORS;
  textWidth?: number;
  errorMessage?: string;
  chipFocusOn?: boolean;
  inputFocusOn?: boolean;
  mainSearchFocus?: boolean;
  requiredMessage?: string;
  value: any;
  formControl?: FormControl | FormGroup;

  onDestroy$ = new Subject();

  constructor(public chipInputSearchHelper: ChipInputSearchHelper) {}

  resize(minWidth?: number) {
    this.minWidth = minWidth || this.minWidth;
    const wordLength: number =
      this.value && this.value?.toString().length < 31 ? 10 : 8;

    let placeholdersLength = 0;
    switch (this.selectedData?.chipProperties.type) {
      case CIS_CHIP_TYPES.DATE_RANGE:
      case CIS_CHIP_TYPES.NUMBER_RANGE:
        placeholdersLength +=
          (this.selectedData.chipProperties?.minPlaceholder?.length ?? 0) * 4;
        placeholdersLength +=
          (this.selectedData.chipProperties?.maxPlaceholder?.length ?? 0) * 4;
        break;
      default:
        placeholdersLength +=
          (this.selectedData?.chipProperties?.placeholder?.length ?? 0) * 4;
    }
    const length: number =
      (this.value ?? '').toString().length * wordLength ||
      placeholdersLength ||
      (this.selectedData?.name.length ?? 0) * 4 ||
      this.minWidth;
    const errorMessageLength = this.errorMessage
      ? this.errorMessage.length * 7
      : 0;
    this.textWidth = Math.max(this.minWidth, length, errorMessageLength);

    if (this.textWidth > this.maxWidth) {
      this.textWidth = this.maxWidth;
    } else if (this.textWidth < this.minWidth) {
      this.textWidth = this.minWidth;
    }
  }

  validate_value() {
    (this.selectedData?.validations ?? []).some(
      (validation: IChipSearchFilterValidatorElement) => {
        const check = validation.validator(
          this.formControl ?? new FormControl()
        );
        this.formControl?.setErrors(check);
        this.errorMessage = this.value ? validation.message : undefined;
        this.requiredMessage =
          validation.validator === Validators.required
            ? validation.message
            : undefined;
        return !!check;
      }
    );
  }

  isChipOptional() {
    return this.selectedData?.behavior === this.chipsBehaviour.OPTIONAL;
  }

  isChipPreselected() {
    return this.selectedData?.behavior === this.chipsBehaviour.PRESELECTED;
  }

  isChipLock() {
    return this.selectedData?.behavior === this.chipsBehaviour.LOCK;
  }

  isChipMandatory() {
    return this.selectedData?.behavior === this.chipsBehaviour.MANDATORY;
  }

  isChipChiningParams() {
    return this.selectedData?.behavior === this.chipsBehaviour.CHAINING_PARAMS;
  }

  isChipDefault() {
    return this.selectedData?.behavior === this.chipsBehaviour.DEFAULT;
  }

  styleObject() {
    if (
      this.chipInputSearchHelper.chipHasValue(this.selectedData) ||
      this.inputFocusOn
    ) {
      return { marginTop: '-8px', 'width.px': this.textWidth };
    } else if (!this.inputFocusOn) {
      return { marginBottom: '-12px', 'width.px': this.textWidth };
    } else {
      return { marginBottom: '--12px', 'width.px': this.textWidth };
    }
  }

  getInputWidth(length: number, label: HTMLElement): number {
    const labelWidth = label.offsetWidth;
    return Math.max(labelWidth, length * 7.5 || 0);
  }

  get placeholders() {
    return this.chipInputSearchHelper.getChipPlaceholders(this.selectedData);
  }

  remove(selectedChip?: IChipSearchFilterElement) {
    if (selectedChip) {
      this.removeData.emit(selectedChip);
    }
  }
}
