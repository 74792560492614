import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionService } from 'angular-connection-service';
import { Subscription } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import {
  GeneralSetupResponseDTO,
  SystemDataService,
} from '../../../setup/general/services/system-data.service';
import { OfflineComponent } from '../../popups/offline/offline.component';
import { ChooseALanguageBottomSheetComponent } from '../choose-a-language-bottom-sheet/choose-a-language-bottom-sheet.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  online = true;
  isConnected = true;
  year: number;

  public version: string = environment.VERSION;
  public core_version: string = environment.CORE_VERSION;

  private connectionSubs: Subscription;
  private subscriptions = new Subscription();

  systemInformation: GeneralSetupResponseDTO | undefined;
  offlineDialogRef: MatDialogRef<OfflineComponent>;
  footerText;

  constructor(
    private bottomSheet: MatBottomSheet,
    private translate: TranslateService,
    private connectionService: ConnectionService,
    private dialog: MatDialog,
    private systemDataService: SystemDataService
  ) {
    this.connectionSubs = this.connectionService
      .monitor(true)
      .subscribe((response) => {
        if (response.hasNetworkConnection) {
          this.offlineDialogRef?.close();
          this.online = true;
        } else {
          if (this.online) {
            const serverErrorOverlay = this.dialog.openDialogs.find(
              (dialogRef) => dialogRef.id === 'SERVER_ERROR_OVERLAY'
            );
            if (serverErrorOverlay) {
              serverErrorOverlay.close();
            }
            this.openOfflineDialog();
          }
          this.online = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.connectionSubs.unsubscribe();
  }

  ngOnInit() {
    this.initializeSystemData();

    const currentLang = localStorage.getItem('currentLang');

    if (currentLang) {
      this.translate.setDefaultLang(currentLang);
    } else {
      this.translate.setDefaultLang('en');
      localStorage.setItem('currentLang', 'en');
    }

    this.year = new Date().getFullYear();
  }

  private initializeSystemData(): void {
    this.subscriptions.add(
      this.systemDataService.dataStore.subscribe((data) => {
        this.systemInformation = data;

        if (/<a[\s\S]/.test(this.systemInformation?.footer)) {
          this.footerText = this.systemInformation?.footer.replace(
            /<a[\s\S]/,
            '<a target="_blank" '
          );
        } else {
          this.footerText = '';
        }
      })
    );
  }

  openBottomSheet(): void {
    this.bottomSheet.open(ChooseALanguageBottomSheetComponent);
  }

  private openOfflineDialog(): void {
    // this.offlineDialogRef = this.dialog.open(OfflineComponent, {
    //   width: '100vw',
    //   height: '100vh',
    //   maxWidth: '100vw',
    //   maxHeight: '100vh',
    //   hasBackdrop: false,
    //   panelClass: 'offline-dialog',
    // });
  }
}
