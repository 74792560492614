import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { URLS } from '@shared/constants';
import {
  CommonResponseDTO,
  IMailboxUserSettingsConfig,
} from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class MailboxApiService {
  constructor(private http: HttpClient) {}

  private readonly _dataStore = new BehaviorSubject<
    IMailboxUserSettingsConfig[]
  >([]);
  public dataStore = this._dataStore.asObservable();

  private readonly _activeUser =
    new BehaviorSubject<IMailboxUserSettingsConfig>(undefined);
  public activeUser = this._activeUser.asObservable();

  initMailboxConfigs(): Observable<
    CommonResponseDTO<IMailboxUserSettingsConfig[]>
  > {
    return this.http
      .get<CommonResponseDTO<IMailboxUserSettingsConfig[]>>(URLS.MAILBOX)
      .pipe(
        tap((res) => {
          this._dataStore.next(res.data);
          const activeUser = res.data.find((item) => item.isCurrent);

          this._activeUser.next(activeUser);
        })
      );
  }

  getAllConfigs(): Observable<CommonResponseDTO<IMailboxUserSettingsConfig[]>> {
    return this.http.get<CommonResponseDTO<IMailboxUserSettingsConfig[]>>(
      URLS.MAILBOX_SETTINGS
    );
  }

  syncConnection(
    id: string
  ): Observable<CommonResponseDTO<IMailboxUserSettingsConfig>> {
    const url = `${URLS.MAILBOX_SETTINGS}/${id}/sync`;
    return this.http
      .get<CommonResponseDTO<IMailboxUserSettingsConfig>>(url)
      .pipe(
        tap((res) => {
          const currentData = this._dataStore.getValue();
          if (currentData.length > 0) {
            const currentAccountIndex = currentData.findIndex(
              (item) => item._id.toString() === res.data._id.toString()
            );
            currentData[currentAccountIndex].isValidConnection =
              res.data.isValidConnection;
            this._dataStore.next([...currentData]);
          } else {
            this._dataStore.next([res.data]);
          }
        })
      );
  }

  createConfig(
    data: IMailboxUserSettingsConfig
  ): Observable<CommonResponseDTO<IMailboxUserSettingsConfig>> {
    return this.http
      .post<CommonResponseDTO<IMailboxUserSettingsConfig>>(
        URLS.MAILBOX_SETTINGS,
        data
      )
      .pipe(
        tap((res) => {
          const currentData = this._dataStore.getValue();
          this._activeUser.next(res.data);
          if (currentData.length > 0) {
            const currentAccountIndex = currentData.findIndex(
              (item) => item.isCurrent === true
            );
            currentData[currentAccountIndex].isCurrent = false;
            const updatedData = [...currentData, res.data];
            this._dataStore.next([...updatedData]);
          } else {
            this._dataStore.next([res.data]);
          }
        })
      );
  }

  updateConfig(
    data: IMailboxUserSettingsConfig
  ): Observable<CommonResponseDTO<IMailboxUserSettingsConfig>> {
    const url = `${URLS.MAILBOX_SETTINGS}/${data._id.toString()}`;
    return this.http
      .patch<CommonResponseDTO<IMailboxUserSettingsConfig>>(url, data)
      .pipe(
        tap((res) => {
          let currentData = this._dataStore.getValue();
          const index = currentData.findIndex(
            (item) => item._id.toString() === data._id.toString()
          );

          if (index !== -1) {
            currentData[index] = res.data;
          } else {
            currentData = [...currentData, res.data];
          }
          this._dataStore.next([...currentData]);
          if (res.data.isCurrent) {
            this._activeUser.next(res.data);
          }
        })
      );
  }

  deleteConfig(
    id: string
  ): Observable<CommonResponseDTO<IMailboxUserSettingsConfig[]>> {
    const url = `${URLS.MAILBOX_SETTINGS}/${id}`;
    return this.http
      .delete<CommonResponseDTO<IMailboxUserSettingsConfig[]>>(url)
      .pipe(
        tap((res) => {
          if (res.data.length > 0) {
            const currentData = this._dataStore.getValue();
            const changedActiveUser: IMailboxUserSettingsConfig = res.data.find(
              (item) => {
                return item.isCurrent;
              }
            );
            const index = currentData.findIndex((item) => item._id === id);

            if (index > -1) {
              currentData.splice(index, 1);
            }

            this._activeUser.next(changedActiveUser);

            if (currentData.length > 0) {
              this._dataStore.next([...currentData]);
            } else {
              this._dataStore.next([]);
              this._activeUser.next(undefined);
            }
          } else {
            this._dataStore.next([]);
            this._activeUser.next(undefined);
          }
        })
      );
  }
}
