export enum IAM_CONFIG_KEYS {
  BRUTE_FORCE_DURATION = 'BRUTE_FORCE_DURATION',
  BRUTE_FORCE_ENABLED = 'BRUTE_FORCE_ENABLED',
  BRUTE_FORCE_COUNT = 'BRUTE_FORCE_COUNT',
  PASSWORD_CONFIG_MAXIMUM = 'PASSWORD_CONFIG_MAXIMUM',
  PASSWORD_CONFIG_MINIMUM = 'PASSWORD_CONFIG_MINIMUM',
  PASSWORD_CONFIG_LENGTH_ENABLED = 'PASSWORD_CONFIG_LENGTH_ENABLED',
  PASSWORD_COMPLEXITY_NO_OF_UPPER_CASE = ' PASSWORD_COMPLEXITY_NO_OF_UPPER_CASE',
  PASSWORD_COMPLEXITY_NO_OF_UPPER_CASE_ENABLED = ' PASSWORD_COMPLEXITY_NO_OF_UPPER_CASE_ENABLED',
  PASSWORD_COMPLEXITY_NO_OF_LOWER_CASE = ' PASSWORD_COMPLEXITY_NO_OF_LOWER_CASE',
  PASSWORD_COMPLEXITY_NO_OF_LOWER_CASE_ENABLED = ' PASSWORD_COMPLEXITY_NO_OF_LOWER_CASE_ENABLED',
  PASSWORD_COMPLEXITY_NO_OF_SPECIAL_CASE = ' PASSWORD_COMPLEXITY_NO_OF_SPECIAL_CASE',
  PASSWORD_COMPLEXITY_NO_OF_SPECIAL_CASE_ENABLED = ' PASSWORD_COMPLEXITY_NO_OF_SPECIAL_CASE_ENABLED',
  PASSWORD_COMPLEXITY_NO_OF_NUMBERS = ' PASSWORD_COMPLEXITY_NO_OF_NUMBERS',
  PASSWORD_COMPLEXITY_NO_OF_NUMBERS_ENABLED = ' PASSWORD_COMPLEXITY_NO_OF_NUMBERS_ENABLED',
  PASSWORD_CONFIG_EXPIRY_PERIOD_ENABLED = ' PASSWORD_CONFIG_EXPIRY_PERIOD_ENABLED',
  PASSWORD_CONFIG_HISTORY_ENABLED = ' PASSWORD_CONFIG_HISTORY_ENABLED',
  TWO_FACTOR_AUTHENTICATION_ENABLED = '  TWO_FACTOR_AUTHENTICATION_ENABLED',
  TWO_FACTOR_AUTHENTICATION_EMAIL_ENABLED = '  TWO_FACTOR_AUTHENTICATION_EMAIL_ENABLED',
  PASSWORD_CONFIG_NO_OF_DAYS = 'PASSWORD_CONFIG_NO_OF_DAYS',
  PASSWORD_CONFIG_NO_OF_PREVIOUS = 'PASSWORD_CONFIG_NO_OF_PREVIOUS',

  SELF_PASSWORD_RESET = 'SELF_PASSWORD_RESET',
  FORGOT_PASSWORD_EMAIL_ACTIVE = 'FORGOT_PASSWORD_EMAIL_ACTIVE',
  FORGOT_PASSWORD_EMAIL_OTP = 'FORGOT_PASSWORD_EMAIL_OTP',
  FORGOT_PASSWORD_EMAIL_LINK = 'FORGOT_PASSWORD_EMAIL_LINK',
  FORGOT_PASSWORD_EMAIL_USERNAME_REQUIRED = 'FORGOT_PASSWORD_EMAIL_USERNAME_REQUIRED',

  FORGOT_PASSWORD_PHONE_ACTIVE = 'FORGOT_PASSWORD_PHONE_ACTIVE',
  FORGOT_PASSWORD_PHONE_USERNAME_REQUIRED = 'FORGOT_PASSWORD_PHONE_USERNAME_REQUIRED',

  SELF_REGISTRATION = 'SELF_REGISTRATION',
  UNIQUENESS_EMAIL = 'UNIQUENESS_EMAIL',
  UNIQUENESS_PHONE_NUMBER = 'UNIQUENESS_PHONE_NUMBER',
  REGISTER_BY = 'REGISTER_BY',
}

export enum REGISTER_BY {
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

export enum IAM_STATUS {
  INTERNAL = 'INTERNAL', // internal IAM is in use (0 config exist)
  EXTERNAL = 'EXTERNAL', // external IAM is in use (1 config exist)
  INTERNAL_PENDING = 'INTERNAL_PENDING', // internal IAM is in use & there is a pending external config (1 config exist)
  EXTERNAL_PENDING = 'EXTERNAL_PENDING', // external IAM is in use & there is a pending external config (2 configs exist)
  INTERNAL_DISCONNECTED = 'INTERNAL_DISCONNECTED', // internal IAM is in use (1 config exist)
}

export enum IAM_CONFIG_STATUS {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
}
