import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import {
  CommonResponseDTO,
  IRoleResponse,
  IWidget,
  IWidgetLayout,
  IWidgetUserLayout,
} from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class WidgetApiService {
  constructor(private http: HttpClient) {}

  getAllWidgets(): Observable<CommonResponseDTO<IWidget[]>> {
    return this.http.get<CommonResponseDTO<IWidget[]>>(URLS.WIDGETS);
  }

  getSingleWidget(id: string): Observable<CommonResponseDTO<IWidget>> {
    const url = `${URLS.WIDGETS}/${id}`;
    return this.http.get<CommonResponseDTO<IWidget>>(url);
  }

  getCurrentUserWidgetLayout(): Observable<
    CommonResponseDTO<IWidgetUserLayout>
  > {
    const url = `${URLS.WIDGET_LAYOUT}/user`;
    return this.http.get<CommonResponseDTO<IWidgetUserLayout>>(url);
  }

  getUserIdentityWidgetLayout(): Observable<
    CommonResponseDTO<IWidgetUserLayout>
  > {
    const url = `${URLS.WIDGET_LAYOUT}/identity`;
    return this.http.get<CommonResponseDTO<IWidgetUserLayout>>(url);
  }

  getRoleWidgetLayout(
    id: string
  ): Observable<CommonResponseDTO<IWidgetUserLayout>> {
    const url = `${URLS.WIDGET_LAYOUT}/roles/${id}`;
    return this.http.get<CommonResponseDTO<IWidgetUserLayout>>(url);
  }

  updateOrCreateLayout(
    data: IWidgetLayout
  ): Observable<CommonResponseDTO<IWidgetLayout>> {
    return this.http.patch<CommonResponseDTO<IWidgetLayout>>(
      URLS.WIDGET_LAYOUT,
      data
    );
  }

  getAllRoles(): Observable<CommonResponseDTO<IRoleResponse[]>> {
    const url = `${URLS.WIDGET_LAYOUT}/roles`;
    return this.http.get<CommonResponseDTO<IRoleResponse[]>>(url);
  }

  getWidgetData(url: string): Observable<CommonResponseDTO<unknown>> {
    const demoUrl = `${url}`;
    return this.http.get<CommonResponseDTO<unknown>>(demoUrl);
  }
}
