import { CONFIGURABLE_FIELD_DATA_TYPES } from './enums';

export const fieldTypesNotSupportingBulkUpload: CONFIGURABLE_FIELD_DATA_TYPES[] =
  [
    CONFIGURABLE_FIELD_DATA_TYPES.CURRENCY,
    CONFIGURABLE_FIELD_DATA_TYPES.DATE,
    CONFIGURABLE_FIELD_DATA_TYPES.BOOLEAN,
    CONFIGURABLE_FIELD_DATA_TYPES.FORMULA,
    CONFIGURABLE_FIELD_DATA_TYPES.VIEW,
    CONFIGURABLE_FIELD_DATA_TYPES.JSON,
    CONFIGURABLE_FIELD_DATA_TYPES.FILE,
    CONFIGURABLE_FIELD_DATA_TYPES.RICH_TEXT,
    CONFIGURABLE_FIELD_DATA_TYPES.ADDRESS,
    CONFIGURABLE_FIELD_DATA_TYPES.NAME,
    CONFIGURABLE_FIELD_DATA_TYPES.CONTACT_NUMBER,
    CONFIGURABLE_FIELD_DATA_TYPES.EMAIL,
    CONFIGURABLE_FIELD_DATA_TYPES.TIME,
  ];
