import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FlowControlService } from '../../services/flow-control.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent {
  constructor(
    router: Router,
    flowControlService: FlowControlService,
    route: ActivatedRoute
  ) {
    flowControlService.updateRouteInfo(
      router.url.split('?')[0],
      route.snapshot.data
    );
  }
}
