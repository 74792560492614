import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlTree } from '@angular/router';

import {
  DMS_STATUS,
  StorageSetupService,
} from '../services/storage-setup.service';

@Injectable({
  providedIn: 'root',
})
export class StorageGuard implements CanLoad {
  constructor(
    private router: Router,
    private location: Location,
    private storageConfigService: StorageSetupService
  ) {}

  restrict() {
    const destination = this.router
      .getCurrentNavigation()
      ?.extractedUrl?.toString();
    this.router
      .navigateByUrl('/app/access-denied', { skipLocationChange: true })
      .then(() => {
        this.location.replaceState(destination);
      });
  }

  canLoad(): Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      this.storageConfigService.dataStore.subscribe((data) => {
        const storageStatus = data?.storage?.status;
        if (
          storageStatus === DMS_STATUS.INTERNAL ||
          storageStatus === DMS_STATUS.INTERNAL_DISCONNECTED ||
          storageStatus === DMS_STATUS.INTERNAL_PENDING ||
          storageStatus === DMS_STATUS.EXTERNAL_PENDING
        ) {
          resolve(true);
        } else {
          console.log(
            `[DEBUG Storage Guard]: Navigated to the Access Denied page because of the storage config mismatch`
          );

          console.log('Current Storage Status:', storageStatus);

          this.restrict();
          resolve(false);
        }
      });
    });
  }
}
