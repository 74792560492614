<mat-card-title mat-dialog-title class="d-flex align-items-center mb-0">
  <mat-icon class="mr-2">push_pin</mat-icon>
  {{ 'pages.quick-actions.create.title' | translate }}
</mat-card-title>
<mat-card-subtitle class="popup-subtitle mb-1">{{
  'pages.quick-actions.create.sub-title' | translate
}}</mat-card-subtitle>
<mat-divider></mat-divider>

<mat-dialog-content style="overflow: overlay">
  <form class="w-100 quick-action-form" [formGroup]="quickActionFormGroup">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>{{ 'pages.quick-actions.form.title' | translate }}</mat-label>
      <input
        data-testid="add_quick_action_input_title"
        type="text"
        matInput
        [placeholder]="'pages.quick-actions.form.title-placeholder' | translate"
        formControlName="title"
        id="title"
        required
      />
      <mat-error
        *ngIf="
          quickActionFormGroup.controls.title.hasError('required') ||
          quickActionFormGroup.controls.title.hasError('whitespace')
        "
        >{{ 'pages.quick-actions.form.title-required' | translate }}</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>{{ 'pages.quick-actions.form.url' | translate }}</mat-label>
      <input
        data-testid="add_quick_action_input_link"
        type="text"
        matInput
        [placeholder]="'eg: ' + environmentUrl + '/profile'"
        id="link"
        formControlName="link"
        required
        [readonly]="data?.pathURL"
      />
      <mat-error
        *ngIf="quickActionFormGroup?.controls?.link?.hasError('required')"
        >{{ 'pages.quick-actions.form.url-required' | translate }}</mat-error
      >
      <mat-error
        *ngIf="
          quickActionFormGroup?.controls?.link?.hasError('pattern') &&
          !data?.pathURL
        "
      >
        {{ 'pages.quick-actions.form.url-pattern' | translate }}
      </mat-error>
    </mat-form-field>

    <div class="search-icon-box">
      <div class="search-icon-box-padding">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>{{
            'pages.quick-actions.form.icon' | translate
          }}</mat-label>
          <input
            data-testid="add_quick_action_input_icon"
            type="text"
            matInput
            placeholder="account_circle"
            id="icon"
            formControlName="icon"
            required
            (keyup)="applyFilter($event)"
            #filter
          />
          <button
            data-testid="add_quick_action_btn_clear_icon_input"
            [disableRipple]="true"
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearFilter()"
          >
            <mat-icon style="font-size: 18px">close</mat-icon>
          </button>
          <mat-error
            *ngIf="quickActionFormGroup?.controls?.icon?.hasError('required')"
            >{{
              'pages.quick-actions.form.icon-required' | translate
            }}</mat-error
          >
        </mat-form-field>
        <div style="padding: 0px 5px">
          <div class="skeleton-icons-list" *ngIf="isLoading">
            <span class="icon-box" *ngFor="let item of searchCount">
              <div>
                <div
                  class="material-icon skeleton-load-item skeleton-icon"
                ></div>
                <div style="display: flex">
                  <div
                    class="icon-name skeleton-load-item"
                    style="height: 8px; top: 2px"
                  ></div>
                </div>
              </div>
            </span>
          </div>
          <div *ngIf="recentlyUsedIcons.length > 0 && !isLoading">
            <div style="font-size: 12px">
              {{ 'pages.quick-actions.form.used-icon' | translate }}
            </div>

            <!-- recently used icons list -->
            <div class="select-icons-list">
              <span class="icon-box" *ngFor="let icon of recentlyUsedIcons">
                <div class="icon-box" (click)="onSelectIcon(icon.icon, $event)">
                  <mat-icon class="material-icon">{{ icon.icon }}</mat-icon>
                  <div style="display: flex">
                    <div class="icon-name">
                      {{ icon.icon }}
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>

          <div style="font-size: 12px; margin-bottom: 12px">
            {{ filter.value ? searchResults.length : icons.length }}
            {{ 'pages.quick-actions.form.matching-results' | translate }}
          </div>
          <div>
            <div class="skeleton-icons-list" *ngIf="isLoading">
              <span class="icon-box" *ngFor="let item of searchCount">
                <div>
                  <div
                    class="material-icon skeleton-load-item skeleton-icon"
                  ></div>
                  <div style="display: flex">
                    <div
                      class="icon-name skeleton-load-item"
                      style="height: 8px; top: 2px"
                    ></div>
                  </div>
                </div>
              </span>
            </div>
            <div class="icons-list" *ngIf="!isLoading">
              <span *ngIf="isIconClicked" class="icon-box">
                <div style="border: 1px solid #000000; border-radius: 4px">
                  <mat-icon class="material-icon">{{ clickedIcon }}</mat-icon>
                  <div style="display: flex">
                    <div class="icon-name">{{ clickedIcon }}</div>
                  </div>
                </div>
              </span>

              <div
                *ngFor="
                  let icon of filter.value ? searchResults : icons;
                  let i = index
                "
              >
                <span
                  data-testid="add_quick_action_btn_select_icon"
                  class="icon-box"
                  (click)="onSelectIcon(icon.name, $event)"
                >
                  <div>
                    <mat-icon class="material-icon">{{ icon.name }}</mat-icon>
                    <div style="display: flex">
                      <div class="icon-name">{{ icon.name }}</div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    data-testid="add_quick_action_btn_cancel"
    mat-button
    [mat-dialog-close]="false"
    id="cancel"
  >
    {{ 'pages.quick-actions.form.close' | translate }}
  </button>
  <button
    data-testid="add_quick_action_btn_save"
    mat-raised-button
    [disabled]="quickActionFormGroup.invalid"
    cdkFocusInitial
    (click)="onCreateQuickAction()"
    color="primary"
    id="save"
  >
    {{ 'pages.quick-actions.form.save' | translate }}
  </button>
</mat-dialog-actions>
