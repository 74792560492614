<div
  class="custom_toast"
  [style.display]="state.value === 'inactive' ? 'none' : ''"
>
  <div class="col-9">
    <div class="custom_toast__title">
      <div
        class="custom_toast__title--filename"
        *ngIf="payloadData.filename"
        [attr.aria-label]="title"
      >
        {{ payloadData.filename }}
      </div>
      <div
        class="custom_toast__title--size"
        *ngIf="payloadData.fileSize"
        [attr.aria-label]="title"
      >
        {{ payloadData.fileSize }}
      </div>
    </div>
  </div>
  <div class="col-3 text-right">
    <button mat-icon-button (click)="cancelEvent()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="options.progressBar" class="progress-bar">
  <mat-progress-bar
    mode="determinate"
    [value]="payloadData.progress"
    color="primary"
  ></mat-progress-bar>
</div>
