import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { INTERCEPTOR_SKIPPERS } from '../../../constants';
import { AuthService } from '../../auth/services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.headers.has(INTERCEPTOR_SKIPPERS.JWT_INTERCEPTOR)) {
      // add auth header with jwt if user is logged in and request is to the api url
      const access_token = this.authService.access_token;
      if (access_token) {
        request = request.clone({
          setHeaders: { Authorization: `Bearer ${access_token}` },
        });
      }
    }

    if (request.headers.has(INTERCEPTOR_SKIPPERS.JWT_INTERCEPTOR)) {
      const headers = request.headers.delete(
        INTERCEPTOR_SKIPPERS.JWT_INTERCEPTOR
      );
      return next.handle(request.clone({ headers }));
    }
    return next.handle(request);
  }
}
