import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';

import { NoticeDTO } from '../../modules/notices/services/notice.service';
import { DateTimeFormatService } from '../../services/date-time-format.service';

import { DialogViewNoticeComponent } from './popups/view-notice/view-notice.component';
import { NoticesService } from './services/notices.service';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.scss'],
})
export class NoticesComponent implements OnInit {
  isLoading = false;
  @Output() noticeCount: EventEmitter<number> = new EventEmitter();

  notices: NoticeDTO[] = [];

  constructor(
    private dialog: MatDialog,
    private noticesService: NoticesService,
    private dateTimeFormatService: DateTimeFormatService
  ) {}

  ngOnInit(): void {
    this.getNotices();
  }

  getNotices() {
    this.isLoading = true;
    this.noticesService.getAllNotices().subscribe({
      next: (res) => {
        this.notices = this.notices.length > 0 ? [] : this.notices;
        this.noticeCount.emit(res.data.length);
        res.data.forEach((notice) => {
          const result = this.checkForPublishing(notice);
          if (result) {
            this.notices.push({ ...notice });
          }
        });
        this.isLoading = false;
      },
      error: () => {
        this.notices = [];
        this.isLoading = false;
      },
    });
  }

  checkForPublishing(notice: NoticeDTO) {
    const startDate = moment(
      moment(new Date(notice.start_date)).format('DD-MM-YYYY'),
      'DD-MM-YYYY'
    );
    const endDate = moment(
      moment(new Date(notice.end_date)).format('DD-MM-YYYY'),
      'DD-MM-YYYY'
    );
    const startTime = moment(
      moment(notice.start_time).format('HH:mm'),
      'HH:mm'
    );
    const endTime = moment(moment(notice.end_time).format('HH:mm'), 'HH:mm');
    const nowDate = moment(
      moment(new Date()).format('DD-MM-YYYY'),
      'DD-MM-YYYY'
    );
    const nowTime = moment(moment(new Date()).format('HH:mm'), 'HH:mm');

    if (!notice.is_published) {
      return false;
    }

    if (startDate.isBefore(nowDate)) {
      if (!notice.end_date) {
        return true;
      }

      if (!notice.end_time) {
        return endDate.isSameOrAfter(nowDate);
      }

      if (endDate.isAfter(nowDate)) {
        return true;
      }

      if (endDate.isSame(nowDate)) {
        return endTime.isSameOrAfter(nowTime);
      }

      return false;
    }

    if (startDate.isSame(nowDate)) {
      if (startTime.isBefore(nowTime)) {
        if (endDate.isSame(nowDate)) {
          return endTime.isSameOrAfter(nowTime);
        }
        return true;
      }
      return startTime.isSame(nowTime);
    }

    return false;
  }

  setStartDate(date: Date) {
    return this.dateTimeFormatService.formatShortenedDate(date);
  }

  setBody(noticeBody: string) {
    return this.extractContent(noticeBody);
  }

  extractContent(s: string) {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  async onClickNotice(notice: NoticeDTO) {
    const viewNotice = await firstValueFrom(
      this.noticesService.getSingleNotice(notice)
    );

    this.dialog.open(DialogViewNoticeComponent, {
      data: viewNotice?.data,
      width: '30%',
      minWidth: '330px',
      maxWidth: '600px',
    });
  }
}
