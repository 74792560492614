<div class="zoom">
  <div
    id="zoom-in-button"
    mat-ripple
    class="app-border zoom__button zoom--top"
    (click)="onZoomIn.emit(true)"
  >
    <mat-icon>add</mat-icon>
  </div>
  <div
    id="zoom-out-button"
    mat-ripple
    class="app-border zoom__button zoom--bottom"
    (click)="onZoomOut.emit(true)"
  >
    <mat-icon>remove</mat-icon>
  </div>
</div>
