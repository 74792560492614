import { Component } from '@angular/core';

@Component({
  selector: 'app-line-chart-skeleton',
  templateUrl: './line-chart-skeleton.component.html',
  styleUrls: ['./line-chart-skeleton.component.scss'],
})
export class LineChartSkeletonComponent {
  widgets = [1, 2, 3, 4];
}
