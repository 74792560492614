import { DataListTableActionElement } from '@el-data-list-table';

import { REFERENCE_ACTIONS } from './reference.service';

export interface IReferenceFieldActionDataElement {
  categoryId: string;
  referenceID: string;
  fieldId: string;
  fieldName: string;
}

export const deleteReferenceAction = (
  id: string
): DataListTableActionElement => {
  return {
    icon: 'delete_outline',
    face: '',
    tooltip: 'Delete',
    action: REFERENCE_ACTIONS.DELETE,
    data: id.toString(),
  };
};

export const restoreReferenceAction = (
  _id: string
): DataListTableActionElement => {
  return {
    icon: 'restore_from_trash',
    face: '',
    tooltip: 'Restore',
    action: REFERENCE_ACTIONS.RESTORE,
    data: _id.toString(),
  };
};

export const editReferenceAction = (
  _id: string
): DataListTableActionElement => {
  return {
    icon: 'mode_edit',
    face: '',
    tooltip: 'Edit',
    action: REFERENCE_ACTIONS.EDIT,
    data: _id.toString(),
  };
};

export const testFormulaAction = (
  formulaElements: IReferenceFieldActionDataElement[]
): DataListTableActionElement | undefined => {
  const testSingleFormulaAction = (
    formulaElement: IReferenceFieldActionDataElement
  ): DataListTableActionElement => {
    return {
      icon: 'bug_report',
      face: `Test "${formulaElement.fieldName}"`,
      tooltip: `Test "${formulaElement.fieldName}"`,
      action: REFERENCE_ACTIONS.TEST_FORMULA,
      data: { ...formulaElement },
    };
  };

  if (!formulaElements.length) return undefined;

  if (formulaElements.length === 1)
    return testSingleFormulaAction(formulaElements[0]);

  return {
    icon: 'bug_report',
    face: 'Test Formula',
    tooltip: 'Test Formula',
    action: REFERENCE_ACTIONS.TEST_FORMULA,
    groupButton: true,
    data: {},
    groupedActions: formulaElements.map((formulaElement) =>
      testSingleFormulaAction(formulaElement)
    ),
  };
};

export const displayRichTextAction = (
  richTextElements: IReferenceFieldActionDataElement[]
): DataListTableActionElement | undefined => {
  const displaySingleRichTextAction = (
    richTextElement: IReferenceFieldActionDataElement
  ): DataListTableActionElement => {
    return {
      icon: 'visibility',
      face: '',
      tooltip: `Display "${richTextElement.fieldName}"`,
      action: REFERENCE_ACTIONS.DISPLAY_RICH_TEXT,
      data: { ...richTextElement },
    };
  };

  if (!richTextElements.length) return undefined;

  if (richTextElements.length === 1)
    return displaySingleRichTextAction(richTextElements[0]);

  return {
    icon: 'integration_instructions',
    face: 'Display rich text',
    tooltip: 'Display rich text',
    action: REFERENCE_ACTIONS.DISPLAY_RICH_TEXT,
    groupButton: true,
    data: {},
    groupedActions: richTextElements.map((richTextElement) =>
      displaySingleRichTextAction(richTextElement)
    ),
  };
};
