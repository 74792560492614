<h1 mat-dialog-title>
  {{ config }}&nbsp;{{
    'setup.storage-config.configuration-change-overlay.title' | translate
  }}
</h1>

<div mat-dialog-content>
  {{ 'setup.storage-config.configuration-change-overlay.subtitle' | translate }}
</div>
<div mat-dialog-actions class="mt-3 justify-content-center">
  <button
    data-testid="drive_config_change_overlay_btn_refresh"
    mat-flat-button
    color="primary"
    (click)="onClickRefresh()"
  >
    {{
      'setup.storage-config.configuration-change-overlay.refresh-button'
        | translate
    }}
  </button>
</div>
