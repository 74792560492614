import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO, IPhoneNumber } from '@shared/interfaces';

export class ClientSetupResponseDTO {
  name: string;
  tagline?: string;
  description: string;
  email: string;
  phone_number: IPhoneNumber;
  address?: string;
  website?: string;
  logo_path_dark?: string;
  logo_path_light?: string;
  background_path?: string;
  banner_path?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClientDataService {
  public readonly dataStore = new BehaviorSubject<ClientSetupResponseDTO>(
    undefined
  );

  constructor(private http: HttpClient) {}

  loadClientData(): Observable<CommonResponseDTO<ClientSetupResponseDTO>> {
    return this.http
      .get<CommonResponseDTO<ClientSetupResponseDTO>>(URLS.CLIENT_DATA_CLIENT)
      .pipe(
        tap(({ data }) => {
          this.dataStore.next(data);
        })
      );
  }

  updateClient(
    data: Partial<ClientSetupResponseDTO>
  ): Observable<CommonResponseDTO<ClientSetupResponseDTO>> {
    return this.http
      .patch<CommonResponseDTO<ClientSetupResponseDTO>>(
        URLS.CLIENT_DATA_CLIENT,
        data
      )
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }
}
