<mat-card-title mat-dialog-title class="d-flex align-items-center mb-0"
  ><mat-icon class="mr-2">vpn_key</mat-icon
  >{{ 'shared.generate-api-keys.title' | translate }}</mat-card-title
>
<mat-card-subtitle>{{
  this.data.id
    ? ('shared.generate-api-keys.subtitle' | translate)
    : ('shared.generate-api-keys.system-subtitle' | translate)
}}</mat-card-subtitle>
<mat-divider class="mb-3"></mat-divider>
<div mat-dialog-content>
  <mat-form-field class="w-100" appearance="fill">
    <mat-label>{{ 'storage.api-doc.api-key' | translate }}</mat-label>
    <input
      matInput
      [placeholder]="'storage.api-doc.api-key' | translate"
      [formControl]="key"
      type="password"
      [type]="hide ? 'password' : 'text'"
      required
      readonly
      id="apps-clientID"
    />
    <mat-error>{{ 'storage.api-doc.api-key-required' | translate }}</mat-error>
    <button
      type="button"
      id="login_btn_show_pwd"
      data-testid="login_btn_show_pwd"
      *ngIf="hide"
      mat-icon-button
      matSuffix
      (click)="hide = !hide"
      matTooltip="{{ 'auth.login.pwd-hidden' | translate }}"
    >
      <mat-icon>visibility_off</mat-icon>
    </button>
    <button
      type="button"
      id="login_btn_hide_pwd"
      data-testid="login_btn_hide_pwd"
      *ngIf="!hide"
      mat-icon-button
      matSuffix
      (click)="hide = !hide"
      matTooltip="{{ 'auth.login.pwd-visible' | translate }}"
    >
      <mat-icon>visibility</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field class="w-100" appearance="fill">
    <mat-label>{{
      'shared.generate-api-keys.reference' | translate
    }}</mat-label>
    <input
      matInput
      [placeholder]="
        'shared.generate-api-keys.reference_placeholder' | translate
      "
      [formControl]="reference"
      required
      id="key"
    />
    <mat-error
      *ngIf="reference.hasError('required') || reference.hasError('whitespace')"
      >{{ 'shared.generate-api-keys.reference-error' | translate }}</mat-error
    >
  </mat-form-field>

  <mat-form-field
    appearance="fill"
    style="width: 100%"
    *ngIf="
      data.apiType === publicApiTypes.IDENTITIES ||
      data.apiType === publicApiTypes.STORAGE ||
      data.apiType === publicApiTypes.SECURITY
    "
  >
    <mat-label>{{ 'shared.generate-api-keys.origins' | translate }}</mat-label>
    <mat-chip-list #originChips>
      <mat-chip
        *ngFor="let origin of originsArr; let i = index"
        (removed)="onRemove(i)"
      >
        {{ origin }}
        <button matChipRemove [attr.aria-label]="'remove ' + origin">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        [placeholder]="
          'shared.generate-api-keys.origins-placeholder' | translate
        "
        [matChipInputFor]="originChips"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="onAdd($event)"
        [formControl]="origins"
        (keyup)="onFocusOrigin()"
      />
    </mat-chip-list>
    <mat-error>{{
      'shared.generate-api-keys.origins-error' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field style="width: 100%" appearance="fill">
    <mat-label>{{ 'shared.generate-api-keys.remarks' | translate }}</mat-label>
    <textarea
      matInput
      [placeholder]="'shared.generate-api-keys.remarks' | translate"
      [formControl]="remarks"
    ></textarea>
  </mat-form-field>

  <!-- <app-note
    [type]="noteType"
    [note]="'shared.generate-api-keys.warning' | translate"
  ></app-note> -->
</div>
<mat-dialog-actions class="action-buttons">
  <div>
    <button
      mat-stroked-button
      (click)="onCopyClientId()"
      id="apps-copyClientID"
      [disabled]="key.invalid"
      matTooltip="{{
        'shared.generate-api-keys.copy-api-key-tooltip' | translate
      }}"
    >
      {{ 'shared.generate-api-keys.copy-api-key' | translate }}
    </button>
  </div>
  <div>
    <button
      mat-button
      [mat-dialog-close]="false"
      matTooltip="{{ 'shared.generate-api-keys.close-tooltip' | translate }}"
    >
      {{ 'shared.generate-api-keys.close' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="saveKey()"
      [disabled]="
        reference.invalid ||
        origins.hasError('regex') ||
        !isOriginsValid ||
        (data.apiKey && remarks.pristine && !isOriginEdited)
      "
      matTooltip="{{ 'shared.generate-api-keys.save-tooltip' | translate }}"
    >
      {{ 'shared.generate-api-keys.save' | translate }}
    </button>
  </div>
</mat-dialog-actions>
