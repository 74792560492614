<mat-card-title mat-dialog-title class="d-flex align-items-center mb-0">
  <mat-icon class="mr-2">{{ data.icon || 'description' }}</mat-icon>
  {{ data.title }}
</mat-card-title>
<mat-card-subtitle class="popup-subtitle mb-1">{{
  data.subtitle
}}</mat-card-subtitle>
<mat-divider></mat-divider>
<mat-divider></mat-divider>
<mat-dialog-content class="mt-3">
  <app-drag-and-drop
    style="width: 100%"
    [allowUpload]="true"
    [allowDelete]="false"
    [uploadDirectly]="false"
    [displayImg]="true"
    (triggerFileSelected)="triggerFileSelected($event)"
    [showUploadedFiles]="false"
    [allowedTypesNames]="data.allowedTypesNames"
    [allowedTypesRegex]="data.allowedTypesRegex"
    [maxSize]="data.maxSize"
    [remainingFilesCount]="100"
    [remainingFiles]="false"
    required
  >
  </app-drag-and-drop>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button
    mat-flat-button
    color="primary"
    (click)="onClose()"
    [matTooltip]="'shared.document-uploader.cancel-tooltip' | translate"
  >
    {{ 'shared.document-uploader.cancel' | translate }}
  </button>
</mat-dialog-actions>
