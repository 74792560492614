<div class="d-flex align-items-center w-100">
  <h6 class="mb-0">
    <mat-icon class="mr-3" style="font-size: 30px">newspaper</mat-icon>
  </h6>
  <div class="align-title ml-0">
    <div #header class="header">
      <mat-card-title #marqueeText class="mb-0">{{ title }}</mat-card-title>
    </div>
  </div>
</div>
<mat-card-subtitle
  >{{ 'pages.notices.view-notice.published-on' | translate }}
  {{ publishedOn }}</mat-card-subtitle
>
<mat-divider></mat-divider>
<mat-dialog-content style="height: 45vh">
  <app-image-preview
    class="body-style mt-3"
    *ngIf="notice?.featured_image"
    [entityId]="notice?.featured_image"
    [imageConfig]="imageConfig"
  >
  </app-image-preview>
  <div class="body-style mt-4">
    <div [innerHtml]="body | embed"></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="button-style" (click)="onClickClose()">
    {{ 'pages.notices.view-notice.close-btn' | translate }}
  </button>
</mat-dialog-actions>
