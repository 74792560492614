<div
  class="callout"
  [ngStyle]="{ 'background-color': bgColor }"
  [class.callout-extend]="!heading"
>
  <div
    class="callout_heading-container"
    [ngStyle]="{ 'align-items': centerIcon ? 'center' : 'flex-start' }"
  >
    <mat-icon [class.callout_icon]="heading">{{ icon }}</mat-icon>
    <strong
      *ngIf="heading"
      class="callout_heading"
      [class.callout_heading-extend]="heading"
      >{{ heading }}</strong
    >
  </div>

  <div class="callout__label" [class.callout_label-extend]="heading">
    {{ note }}
  </div>
</div>
