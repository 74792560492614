export enum CONTACT_NUMBER_TYPES {
  HOME = 'Home',
  MOBILE = 'Mobile',
}

export enum CONTACT_EMAIL_TYPES {
  PERSONAL = 'Personal',
  WORK = 'Work',
  EDUCATIONAL = 'Educational',
}
