export enum DLT_DATA_TYPES {
  BUTTON = 'button',
  CHIP = 'chip',
  INNER_HTML = 'inner_html',
  LINK = 'link',
  PLAIN = 'plain',
  SEPARATOR = 'separator',
  PROGRESS_BAR = 'progress_bar',
  EXPANSION_PANEL = 'expansion_panel',
}
