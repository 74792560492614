import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

export enum SCREEN_SIZE {
  // eslint-disable-next-line no-unused-vars
  XS,
  SM,
  MD,
  LG,
  XL,
}

interface ScreenResize {
  size: SCREEN_SIZE;
  innerWidth: number;
}

@Injectable({
  providedIn: 'root',
})
export class ResizeService {
  get onWindowResize(): Observable<ScreenResize> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject: Subject<ScreenResize>;

  constructor() {
    this.resizeSubject = new Subject();
  }

  onResize(size: ScreenResize) {
    this.resizeSubject.next(size);
  }
}
