import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, Subject, takeUntil } from 'rxjs';

import { SALUTATIONS } from '@shared/constants';
import { IConfigurableFieldConfigResponse, IName } from '@shared/interfaces';
import { isRequired } from '@shared/utils';
@Component({
  selector: 'app-set-name',
  templateUrl: './set-name.popup.component.html',
  styleUrls: ['./set-name.popup.component.scss'],
})
export class SetNamePopupComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  salutations = Object.values(SALUTATIONS);

  form = new FormGroup({
    salutation: new FormControl('', Validators.required),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    middle_name: new FormControl(''),
  });

  constructor(
    private matDialog: MatDialogRef<SetNamePopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private field: {
      value: IName;
      fieldConfig: IConfigurableFieldConfigResponse;
    }
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    if (this.field.value) {
      this.form.setValue({
        salutation: this.field.value.salutation ?? '',
        first_name: this.field.value.first_name ?? '',
        last_name: this.field.value.last_name ?? '',
        middle_name: this.field.value.middle_name ?? '',
      });
    }

    const capitalizeFirstLetter = (text: string) => {
      if (!text) return '';

      return text.toString().charAt(0).toUpperCase() + text.toString().slice(1);
    };

    this.form.controls.first_name.valueChanges
      .pipe(takeUntil(this.onDestroy$), debounceTime(50))
      .subscribe((firstName) => {
        this.form.controls.first_name.setValue(
          capitalizeFirstLetter(firstName)
        );
      });

    this.form.controls.last_name.valueChanges
      .pipe(takeUntil(this.onDestroy$), debounceTime(50))
      .subscribe((lastName) => {
        this.form.controls.last_name.setValue(capitalizeFirstLetter(lastName));
      });

    this.form.controls.middle_name.valueChanges
      .pipe(takeUntil(this.onDestroy$), debounceTime(50))
      .subscribe((middleName) => {
        this.form.controls.middle_name.setValue(
          capitalizeFirstLetter(middleName)
        );
      });
  }

  onSaveClicked() {
    this.matDialog.close(this.form.value);
  }

  clearAll() {
    this.form.reset();

    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  isValid() {
    if (!isRequired(this.field?.fieldConfig?.checkboxes) && this.form.pristine)
      return true;

    return this.form.valid;
  }
}
