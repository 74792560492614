export enum ENDPOINT_PARAM_TYPES {
  RUNTIME_COLLECT = 'RUNTIME_COLLECT',
  CURRENT_YEAR_NUMBER = 'CURRENT_YEAR_NUMBER',
  PREDEFINED = 'PREDEFINED',
  CURRENT_MONTH_NUMBER_ZERO_BASED = 'CURRENT_MONTH_NUMBER_ZERO_BASED',
  CURRENT_MONTH_NUMBER_ONE_BASED = 'CURRENT_MONTH_NUMBER_ONE_BASED',
  CURRENT_MONTH_NAME_THREE_CHARACTERS = 'CURRENT_MONTH_NAME_THREE_CHARACTERS',
}

export enum ENDPOINT_STATUS {
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFIED = 'VERIFIED',
}

export enum AUTHENTICATION_TYPES {
  CLIENT_CREDENTIAL = 'CLIENT_CREDENTIAL',
  API_KEY = 'API_KEY',
  NONE = 'NONE',
}

export enum ENDPOINT_SYSTEMS {
  CUSTOM = 'CUSTOM',
}

export const ENDPOINT_REGEX = `^/(?:[^/:]+|:[a-zA-Z0-9_]+)(?:/(?:[^/:]+|:[a-zA-Z0-9_]+))*$`;

export const PATH_PARAM_REGEX = /:(\w+_*)+/;
