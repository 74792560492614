//TODO:@ranul ADD COMMENTS
// add the angular component selectors as the type
export enum WIDGET_TYPES {
  COUNT = 'app-count-widget',
  LINE = 'app-line-chart',
  BAR = 'app-bar-chart',
  DONUT = 'app-donut-widget',
  NUMBER_CARD = 'app-number-card-widget',
  GROUP_BAR = 'app-group-bar-chart',
}

export enum WIDGET_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum WIDGET_CATEGORIES {
  LOGS = 'LOGS',
  DRIVES = 'DRIVES',
  MODULES = 'MODULES',
  CONNECTED_APPS = 'CONNECTED_APPS',
  CONNECTIONS = 'CONNECTIONS',
  IDENTITIES = 'IDENTITIES',
  MESSAGES = 'MESSAGES',
  NOTICES = 'NOTICES',
  PERMISSIONS = 'PERMISSIONS',
  ROLES = 'ROLES',
  PRIVILEGES = 'PRIVILEGES',
  REFERENCES = 'REFERENCES',
  KNOWLEDGEBASE = 'KNOWLEDGEBASE',
  ENDPOINTS = 'ENDPOINTS',
  MAPPINGS = 'MAPPINGS',
  STORAGE = 'STORAGE',
  TEST = 'TEST',
  ACTIVITIES = 'ACTIVITIES',
  AGENTS = 'AGENTS',
  CLAIMS = 'CLAIMS',
  COVER_NOTES = 'COVER_NOTES',
  ENDORSEMENTS = 'ENDORSEMENTS',
  INVOICES = 'INVOICES',
  LEADS = 'LEADS',
  POLICIES = 'POLICIES', // this is for group bar chart test
}

export enum WIDGET_KEYS {
  CONNECTED_APPS_COUNT = 'CONNECTED_APPS_COUNT',
  NOTICES_TYPE_COUNT = 'NOTICES_TYPE_COUNT',
  KNOWLEDGEBASE_TYPE_COUNT = 'KNOWLEDGEBASE_TYPE_COUNT',
  ROLES_TYPE_COUNT = 'ROLES_TYPE_COUNT',
  REFERENCES_COUNT = 'REFERENCES_COUNT',
  PRIVILEGES_COUNT = 'PRIVILEGES_COUNT',
  MESSAGES_TYPE_COUNT = 'MESSAGES_TYPE_COUNT',
  IDENTITIES_TYPE_COUNT = 'IDENTITIES_TYPE_COUNT',
  NOTICES_COUNT = 'NOTICES_COUNT',
  DRIVES_COUNT = 'DRIVES_COUNT',
  MESSAGES_COUNT = 'MESSAGES_COUNT',
  ROLES_COUNT = 'ROLES_COUNT',
  PERMISSION_COUNT = 'PERMISSION_COUNT',
  IDENTITIES_COUNT = 'IDENTITIES_COUNT',
  MODULES_COUNT = 'MODULES_COUNT',
  TEST_APPS_COUNT = 'TEST_APPS_COUNT',
  IDENTITIES_UNVERIFIED_COUNT = 'IDENTITIES_UNVERIFIED_COUNT',
  IDENTITIES_VERIFIED_COUNT = 'IDENTITIES_VERIFIED_COUNT',
  LOGS_COUNT = 'LOGS_COUNT',
  NOTICES_REPORT = 'NOTICES_REPORT',
  ENDPOINTS_COUNTS = 'ENDPOINTS_COUNTS',
  MAPPINGS_COUNTS = 'MAPPINGS_COUNTS',
  QUOTA_SYSTEM = 'QUOTA_SYSTEM',
  QUOTA_CONNECTED_APPS = 'QUOTA_CONNECTED_APPS',
  USER_QUOTA = 'USER_QUOTA',
  TEST_GROUP_BAR = 'TEST_GROUP_BAR', // This is for group bar chart test
}
