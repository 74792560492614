export enum ENDPOINTS {
  // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //
  // ASSETS
  ASSETS_IMAGES_UPLOAD,
  ASSETS_IMAGES_DELETE,

  // AUTH
  AUTH_REFRESH_TOKEN,
  AUTH_LOGIN,
  AUTH_PROFILE_IMAGE_UPLOAD,
  AUTH_CODE,
  AUTH_LOGIN_IMPERSONATE,
  AUTH_CHECK_USER,
  AUTH_USERNAME,
  AUTH_LOGOUT,
  AUTH_FORGOT_PASSWORD,
  AUTH_VALIDATE_TOKEN,
  AUTH_RESET_PASSWORD,
  AUTH_REGISTER,
  AUTH_VERIFY,
  AUTH_PROFILE,
  AUTH_SEND_OTP,

  // CONNECTED-APPS
  CONNECTED_APPS_CONNECT_NEW_APPS,
  CONNECTED_APPS_GET_ALL_APPS,
  CONNECTED_APPS_GET_ONE_APP,
  CONNECTED_APPS_UPDATE_ONE_APP,
  CONNECTED_APPS_DELETE_ONE_APP,
  CONNECTED_APPS_CONNECT_OR_DISCONNECT_APP,
  CONNECTED_APPS_GET_ALL_FIELDS_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_PERMISSIONS_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_ROLES_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_DOCUMENTATIONS_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_REFERENCES_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GENERATE_PUBLIC_API_KEY,
  CONNECTED_APPS_GENERATE_PUBLIC_CLIENT_CREDENTIALS,
  CONNECTED_APPS_GENERATE_SECRETS,
  CONNECTED_APPS_SAVE_GENERATED_PUBLIC_KEY,
  CONNECTED_APPS_SAVE_DELETED_PUBLIC_KEY,
  CONNECTED_APPS_SAVE_DELETED_CLIENT_SECRET,
  CONNECTED_APPS_UPDATE_GENERATED_PUBLIC_KEY,
  CONNECTED_APPS_GET_SPECIFIC_GENERATED_KEY,
  CONNECTED_APPS_GET_ALL_GENERATED_KEYS,
  CONNECTED_APPS_DELETE_KEY,
  CONNECTED_APPS_RESTORE_KEY,
  CONNECTED_APPS_HANDSHAKE,

  // CONNECTIONS
  CONNECTIONS_GET_ALL,
  CONNECTIONS_UPDATE,

  // IDENTITIES
  IDENTITIES_GET_ONE,
  IDENTITIES_GET_INTERNAL_FIELD,
  IDENTITIES_SUBORDINATES,
  IDENTITIES_SUBORDINATE_IDS,
  IDENTITIES_HIERARCHICAL,

  //INTEGRATION - ENDPOINTS
  INTEGRATION_ENDPOINTS_GET_ALL,
  INTEGRATION_ENDPOINTS_ADD_ONE,
  INTEGRATION_ENDPOINTS_UPDATE_ONE,
  INTEGRATION_ENDPOINTS_DELETE_ONE,
  INTEGRATION_ENDPOINTS_RESTORE_ONE,
  INTEGRATION_ENDPOINTS_VERIFY_ONE,

  //INTEGRATION - MAPPING
  INTEGRATION_MAPPINGS_ADD_ONE,
  INTEGRATION_MAPPINGS_GET_ALL,
  INTEGRATION_MAPPINGS_UPDATE_ONE,
  INTEGRATION_MAPPINGS_DELETE_ONE,
  INTEGRATION_MAPPINGS_RESTORE_ONE,
  INTEGRATION_MAPPINGS_VERIFY_ONE,

  // FILES
  FILES_UPLOAD,
  FILES_DOWNLOAD,
  FILES_DELETE,

  // JOB_QUEUE
  JOB_QUEUE_CHECK_STATUS,

  // MODULES
  MODULES_GET_ALL,
  MODULES_GET_ONE_BY_ID,
  MODULES_UPDATE,
  MODULES_RESET,

  // PERSONALIZATION
  PERSONALIZATION_GET,
  PERSONALIZATION_UPDATE,

  // NOTIFICATION
  NOTIFICATION_CERATE_WITH_USER,
  NOTIFICATION_CERATE_WITHOUT_USER,
  NOTIFICATION_CREATE_MULTIPLE_NOTIFICATIONS_WITH_USERS,
  NOTIFICATION_GET,
  NOTIFICATION_GET_USER_NOTIFICATIONS,
  NOTIFICATION_FILTER,
  NOTIFICATIONS_MARK_AS_READ,
  NOTIFICATIONS_MARK_ALL_AS_READ,

  // CONFIGURABLE_FIELDS - VALIDATIONS
  CONFIGURABLE_FIELDS_VALIDATIONS_NAME,
  CONFIGURABLE_FIELDS_VALIDATIONS_PHONE_NUMBER,
  CONFIGURABLE_FIELDS_VALIDATIONS_ADDRESS,
  CONFIGURABLE_FIELDS_VALIDATIONS_EMAIL,

  // REFERENCES - PREDEFINED_REFERENCES
  PREDEFINED_REFERENCES_GET_VALUE,

  // REFERENCES - REFERENCE_CATEGORIES
  REFERENCE_CATEGORIES_GET_ALL,
  REFERENCE_CATEGORIES_GET_SINGLE,
  REFERENCE_CATEGORIES_GET_EXTERNAL,
  REFERENCE_CATEGORIES_GET_EXTERNAL_DELETE,
  REFERENCE_CATEGORIES_ADD_NEW,
  REFERENCE_CATEGORIES_UPDATE,
  REFERENCE_CATEGORIES_DELETE,
  REFERENCE_CATEGORIES_RESTORE,
  REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  REFERENCE_CATEGORIES_REFERENCES_GET_ALL_V2,
  REFERENCE_CATEGORIES_REFERENCES_ADD_NEW,
  REFERENCE_CATEGORIES_REFERENCES_BULK_ADD,

  // REFERENCES - REFERENCES
  REFERENCE_EDIT,
  REFERENCE_DELETE,
  REFERENCE_RESTORE,
  REFERENCES_FIELDS_GET_VALUE,

  // REFERENCES - TEMPLATES
  TEMPLATES_ADD_NEW,
  TEMPLATES_UPDATE_ONE,
  TEMPLATES_GET_ONE,

  // REFERENCES - FORMULA
  FORMULA_ANALYZE,
  FORMULA_EVALUATE,

  // STORAGE - INTERNAL
  STORAGE_INTERNAL_CONTENT,
  STORAGE_INTERNAL_DELETE,
  STORAGE_INTERNAL_PREVIEW,
  STORAGE_INTERNAL_UPLOAD,
  STORAGE_INTERNAL_DOWNLOAD,
  STORAGE_INTERNAL_SHARE_INTERNALLY,

  // SETUP - IAM
  SETUP_IAM_AUTHORIZERS_AZURE,
  SETUP_IAM_HANDSHAKE,

  // SETUP - BACKUP_SCHEDULES
  SETUP_BACKUPS_SCHEDULES_ADD_NEW,
  SETUP_BACKUPS_SCHEDULES_GET_ALL,
  SETUP_BACKUPS_SCHEDULES_DELETE_ONE,
  SETUP_BACKUPS_SCHEDULES_ENABLE_DISABLE_ONE,

  // SETUP - BACKUPS
  SETUP_BACKUPS_BACKUP_NOW,
  SETUP_BACKUPS_GET_ALL,
  SETUP_BACKUPS_DOWNLOAD_ONE,
  SETUP_BACKUPS_DELETE_ONE,

  // SETUP - LANGUAGES
  SETUP_LANGUAGES_ADD_ONE,
  SETUP_LANGUAGES_GET_ALL,
  SETUP_LANGUAGES_GET_ONE,
  SETUP_LANGUAGES_UPDATE_ONE,
  SETUP_LANGUAGES_ENABLE_DISABLE_ONE,
  SETUP_LANGUAGES_DELETE_ONE,
  SETUP_LANGUAGES_REFRESH_ONE,
  SETUP_LANGUAGES_GET_CONTENT_OF_ONE,

  // SETUP - REFERENCE MAPPING
  SETUP_GET_ALL_REFERENCE_MAPPINGS,
  SETUP_GET_REFERENCE_MAPPING_BY_KEY,
  SETUP_UPDATE_REFERENCE_MAPPING,

  // SETUP - CURRENCIES
  SETUP_CURRENCIES_ADD_ONE,
  SETUP_CURRENCIES_GET_ALL,
  SETUP_CURRENCIES_GET_ONE,
  SETUP_CURRENCIES_UPDATE_ONE,
  SETUP_CURRENCIES_DELETE_ONE,
  SETUP_CURRENCIES_RESTORE_ONE,
  SETUP_CURRENCIES_TOGGLE_BASE_CURRENCY,

  // SETUP - EXTERNAL SYSTEMS
  SETUP_EXTERNAL_SYSTEMS_GET_ALL,
  SETUP_EXTERNAL_SYSTEM_GET_EXTERNAL_REFERENCE_CATEGORIES,
  SETUP_EXTERNAL_SYSTEMS_UPDATE_ONE,

  // SETUP - EXTERNAL SYSTEMS - KEYS

  SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ALL,
  SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ONE,
  SETUP_EXTERNAL_SYSTEMS_KEYS_DELETE_ONE,
  SETUP_EXTERNAL_SYSTEMS_KEYS_RESTORE_ONE,
  SETUP_EXTERNAL_SYSTEMS_KEYS_SAVE_KEY,
  SETUP_EXTERNAL_SYSTEMS_KEYS_GENERATE_NEW_KEY,

  // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //

  // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //
  // REFERENCES - REFERENCE_CATEGORIES
  CLIENT_REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  // REFERENCES - REFERENCES
  CLIENT_REFERENCES_FIELDS_GET_VALUE,
  // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //

  // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //
  // REFERENCES - REFERENCE_CATEGORIES
  EXTERNAL_REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  // REFERENCES - REFERENCES
  EXTERNAL_REFERENCES_FIELDS_GET_VALUE,
  // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //

  // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //
  // REFERENCES - REFERENCE_CATEGORIES
  IAM_REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  // REFERENCES - REFERENCES
  IAM_REFERENCES_FIELDS_GET_VALUE,
  // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //
}
