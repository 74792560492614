import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import { CommonResponseDTO, IJobItemStatusResponse } from '@shared/interfaces';
import { generateURL } from '@shared/utils';

@Injectable({ providedIn: 'root' })
export class JobQueueService {
  constructor(private http: HttpClient) {}

  /**
   * Check the status of a queued job, with an interval of 5s, until the job finishes in the API
   *
   * @param jobId id of the queued job, which is returned for the API
   * @returns a promise of IJobItemStatusResponse of type T
   */
  checkJobStatus<T>(jobId: string): Promise<IJobItemStatusResponse<T>> {
    return new Promise((resolve, reject) => {
      const url = generateURL({
        endpoint: ENDPOINTS.JOB_QUEUE_CHECK_STATUS,
        params: { id: jobId },
      });
      let subscription: Subscription;

      const interval = setInterval(() => {
        subscription?.unsubscribe?.();

        subscription = this.http
          .get<CommonResponseDTO<IJobItemStatusResponse<T>>>(url)
          .subscribe({
            next: (res) => {
              if (res?.data?.finished) {
                clearInterval(interval);
                resolve(res.data);
              }
            },
            error: reject,
          });
      }, 5000);
    });
  }
}
