import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Observable, Subject, takeUntil } from 'rxjs';

const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cis-date-chip-year-picker-search-filter',
  templateUrl: 'year-picker.component.html',
  styleUrls: ['year-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class YearPickerComponent implements AfterViewInit, OnDestroy {
  @Input() loading?: boolean;
  @Input() isChipLock?: boolean;
  @Input() inputWidth?: number;
  @Input() formControl: FormControl<Moment | null> = new FormControl();
  @Input() placeholder?: string;
  @Input() disabled?: boolean;
  @Input() readonly?: boolean;
  @Input() label?: string;
  @Input() openPicker?: Observable<void>;

  @ViewChild('picker') datePicker?: MatDatepicker<Date>;
  private onDestroy$ = new Subject<void>();

  ngAfterViewInit(): void {
    this.openPicker?.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.datePicker?.open();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setMonthAndYear(
    normalizedMonthAndYear: Event,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = moment(this.formControl?.value || new Date());

    ctrlValue.year(moment(normalizedMonthAndYear?.toString()).year());
    this.formControl?.setValue(ctrlValue);

    datepicker.close();
  }
}
