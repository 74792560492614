<div class="container">
  <div
    class="row"
    cdkDropList
    [cdkDropListOrientation]="screenWidth > 575 ? 'horizontal' : 'vertical'"
    (cdkDropListDropped)="drop($event)"
  >
    <ng-container *ngIf="loading">
      <ng-container *ngFor="let skeletonNote of skeletonNotes">
        <!-- loading  -->
        <div class="col-12 col-sm-6 col-lg-3" cdkDrag>
          <app-skeleton-quick-actions></app-skeleton-quick-actions>
        </div>
      </ng-container>
    </ng-container>
    <!-- content  -->
    <ng-container *ngIf="!loading">
      <div
        class="col-12 col-sm-6 col-lg-3"
        cdkDrag
        [cdkDragDisabled]="squickAction?.isSystem ?? false"
        *ngFor="let squickAction of systemQuickActions; let i = index"
      >
        <app-skeleton-quick-actions
          *ngIf="squickAction?.delete_loading_indicator"
        ></app-skeleton-quick-actions>
        <button
          attr.data-testid="quick_action_btn_system_{{
            squickAction.title.toLowerCase().replaceAll(' ', '_')
          }}"
          *ngIf="!squickAction?.delete_loading_indicator"
          mat-button
          (click)="
            onNavigateToQuickActionPage(squickAction?.link, squickAction?.key)
          "
          matTooltip="{{ squickAction.title }}"
          class="create-quick-action w-100 app-border"
          id="firstQuickAction"
        >
          <div class="created-quick-action">
            <mat-icon class="mr-2" style="margin-left: 1.2rem">{{
              squickAction.icon
            }}</mat-icon>
            <strong class="quick-action-title">{{ squickAction.title }}</strong>
          </div>
        </button>
      </div>
      <div
        class="col-12 col-sm-6 col-lg-3"
        cdkDrag
        *ngFor="let quickAction of quickActions; let i = index"
      >
        <app-skeleton-quick-actions
          *ngIf="quickAction?.delete_loading_indicator"
        ></app-skeleton-quick-actions>
        <button
          attr.data-testid="quick_action_btn_{{
            quickAction.title.toLowerCase().replaceAll(' ', '_')
          }}"
          *ngIf="!quickAction?.delete_loading_indicator"
          mat-button
          (click)="onNavigateToQuickActionPage(quickAction?.link, null)"
          matTooltip="{{ quickAction.title }}"
          class="create-quick-action w-100 app-border"
          id="firstQuickAction"
        >
          <div class="created-quick-action">
            <mat-icon class="mr-2" style="margin-left: 1.2rem">{{
              quickAction.icon
            }}</mat-icon>
            <strong class="quick-action-title">{{ quickAction.title }}</strong>
          </div>
          <mat-icon cdkDragHandle class="created-quick-action_drag"
            >drag_indicator</mat-icon
          >
        </button>
      </div>
    </ng-container>

    <div
      *ngIf="quickActions.length < maxQuickActions"
      class="col-12 col-sm-6 col-lg-3"
    >
      <button
        data-testid="quick_action_btn_add_quick_action"
        mat-button
        matTooltip="{{ 'pages.quick-actions.pin' | translate }}"
        (click)="onCreateQuickAction()"
        class="create-quick-action w-100 quick-action app-dashed-border"
      >
        <mat-icon class="mr-2" style="margin-left: 1.2rem">push_pin</mat-icon>
        <strong class="quick-action-title">{{
          'pages.quick-actions.pin' | translate
        }}</strong>
      </button>
    </div>
  </div>
</div>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
