import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { PersonalizationService } from '../../../../pages/personalization/services/personalization.service';
import {
  LanguagesResponseDTO,
  LanguagesService,
} from '../../../setup/languages/services/languages.service';

@Component({
  selector: 'app-choose-a-language-bottom-sheet',
  templateUrl: './choose-a-language-bottom-sheet.component.html',
  styleUrls: ['./choose-a-language-bottom-sheet.component.scss'],
})
export class ChooseALanguageBottomSheetComponent implements OnInit, OnDestroy {
  languages: LanguagesResponseDTO[] = [];
  private onDestroy$ = new Subject<void>();

  constructor(
    public translate: TranslateService,
    private _MyBottomSheetRef: MatBottomSheetRef<ChooseALanguageBottomSheetComponent>,
    private languageData: LanguagesService,
    private personalizationService: PersonalizationService
  ) {}

  ngOnInit() {
    this.languageData.dataStore
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.languages = data.filter((language) => language.is_enabled);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  useLanguage(language: string, event: MouseEvent): void {
    this.translate.use(language);
    this.personalizationService
      .updatePersonalizationData({ language })
      .subscribe();

    event.preventDefault();
    this._MyBottomSheetRef.dismiss();
  }
}
