import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss'],
})
export class ZoomComponent {
  @Output() onZoomIn = new EventEmitter<boolean>();
  @Output() onZoomOut = new EventEmitter<boolean>();
}
