<app-size-detector></app-size-detector>
<!-- begin:: Header Section -->
<div class="row header mb-1 mx-1">
  <mat-checkbox
    *ngIf="showSelectAllCheckbox"
    [disabled]="disableSelectAllCheckbox"
    class="select-all-checkbox"
    [(ngModel)]="selectAllCheckboxValue"
    (change)="onPressSelectAllCheckbox($event)"
    data-testid="dlt_checkbox_select_all"
    >Select All</mat-checkbox
  >

  <!-- Begin:: Number of Results -->
  <span
    data-testid="dlt_label_showing_results"
    class="header__pagination-counter"
  >
    Showing {{ data.length | number }} out of {{ totalCount | number }}
    result(s)
  </span>
  <!-- End:: Number of Results -->

  <span class="spacer"></span>
  <!-- Begin:: Header Actions -->
  <span class="header__list-options">
    <!-- Begin:: Results Filter -->
    <div class="row d-flex align-items-center m-0 p-0">
      <div class="dlt-search-chip">
        <input
          [placeholder]="
            !!filterValue
              ? 'Filter (' + filteredData.length + ' found)'
              : 'Filter'
          "
          [(ngModel)]="filterValue"
          (keyup)="filterData()"
          type="text"
          data-testid="dlt_input_filter_results"
        />
        <div class="search-icons">
          <span matSuffix>
            <mat-icon class="prefix-icon">filter_list</mat-icon>
          </span>
          <button
            class="close-btn"
            aria-label="Clear"
            (click)="filterValue = ''; filterData(); filter = false"
            [ngClass]="{ show: filterValue }"
            data-testid="dlt_filter_results_btn_close"
          >
            <mat-icon class="prefix-icon">close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <!-- End:: Results Filter -->

    <div class="row m-0 p-0">
      <!-- Begin:: Add to Collections -->
      <button
        *ngIf="getAllButtonEnabled"
        mat-icon-button
        matTooltip="Add all into Collection"
        (click)="getAll()"
        data-testid="dlt_btn_add_to_collection"
      >
        <mat-icon *ngIf="!generatingCsv">playlist_add</mat-icon>
      </button>
      <!-- End:: Add to Collections -->

      <!-- Begin:: Export to CSV -->
      <button
        *ngIf="downloadButtonEnabled"
        mat-icon-button
        matTooltip="Export as .csv file"
        (click)="exportAsCsv()"
        [disabled]="generatingCsv"
        data-testid="dlt_btn_export_to_csv"
      >
        <mat-icon
          data-testid="dlt_btn_export_to_csv_generated"
          *ngIf="!generatingCsv"
          >save_alt</mat-icon
        >
        <mat-icon
          data-testid="dlt_btn_export_to_csv_generating"
          *ngIf="generatingCsv"
        >
          <mat-progress-spinner
            [color]="'accent'"
            [mode]="'indeterminate'"
            [diameter]="30"
          >
          </mat-progress-spinner>
        </mat-icon>
      </button>
      <!-- End:: Export to CSV -->

      <!-- Begin: Column View Related -->
      <span *ngIf="columnViewEnabled">
        <!-- Begin:: Enable List View -->
        <button
          *ngIf="columnView"
          mat-icon-button
          matTooltip="List View"
          (click)="onClickListView()"
          data-testid="dlt_btn_list_view"
        >
          <mat-icon>reorder</mat-icon>
        </button>
        <!-- End:: Enable List View -->

        <!-- Begin:: Enable Column View -->
        <button
          *ngIf="!columnView"
          mat-icon-button
          matTooltip="Column View"
          (click)="onClickColumnView()"
          data-testid="dlt_btn_column_view"
        >
          <mat-icon>view_column</mat-icon>
        </button>
        <!-- End:: Enable Column View -->
      </span>
      <!-- End: Column View Related -->

      <!-- Begin:: Collapse All -->
      <button
        *ngIf="expandAll"
        mat-icon-button
        matTooltip="Collapse All"
        (click)="onClickCollapseAll()"
        data-testid="dlt_btn_collapse_all"
      >
        <mat-icon>unfold_less</mat-icon>
      </button>
      <!-- End:: Collapse All -->

      <!-- Begin:: Expand All -->
      <button
        *ngIf="!expandAll"
        mat-icon-button
        matTooltip="Expand All"
        (click)="onClickExpandAll()"
        data-testid="dlt_btn_expand_all"
      >
        <mat-icon>unfold_more</mat-icon>
      </button>
      <!-- End:: Expand All -->
    </div>
  </span>
  <!-- End:: Header Actions -->
</div>
<!-- End:: Header Section -->

<!-- Begin:: Table View -->
<mat-accordion [multi]="true" *ngIf="!columnView">
  <div
    [class.check-box-container]="items?.checkBoxConfig?.isVisible"
    *ngFor="let items of filteredData; let i = index"
    attr.data-testid="dlt_card_{{ i }}"
  >
    <mat-checkbox
      *ngIf="items?.checkBoxConfig?.isVisible && items.checkBoxConfig"
      [disabled]="
        (items && items.loading) || (items?.checkBoxConfig?.isDisabled ?? false)
      "
      [(ngModel)]="items.checkBoxConfig.isSelected"
      class="mr-2"
      [class.check-box-container__check-box]="items?.checkBoxConfig?.isVisible"
      (change)="onCheckBoxClicked($event, items, i)"
      attr.data-testid="dlt_{{ i }}_checkbox_is_selected"
    >
    </mat-checkbox>
    <mat-expansion-panel
      #panel
      [class.check-box-container__expansion-panel]="
        items?.checkBoxConfig?.isVisible
      "
      [disabled]="items && items.loading"
      (opened)="onExpandElement(items.index + 1)"
      (closed)="onCollapseElement(items.index + 1)"
      [expanded]="items && !items.loading && (items.expanded || expandAll)"
      attr.data-testid="dlt_{{ i }}_dlt_panel"
    >
      <!-- Begin:: Expansion Panel Header -->
      <mat-expansion-panel-header class="content__header">
        <!-- **** Icons are hidden for the Mobile view **** -->
        <mat-panel-title
          class="w-100 mx-0"
          [ngClass]="{ 'dlt-header': !panel?.expanded }"
          attr.data-testid="dlt_{{ i }}_dlt_panel_title"
        >
          <div class="content__header-row row w-100 m-0 p-0">
            <!-- Begin:: Icon & Loading Spinner -->
            <div style="width: 8.33%" *ngIf="!isMobileView || alwaysShowIcon">
              <!-- Begin:: Icon -->
              <div *ngIf="items && !items.avatar && !items.loading">
                <mat-icon
                  attr.data-testid="dlt_{{ i }}_dlt_panel_title_icon"
                  *ngIf="items && items.icon"
                  class="content__icon"
                >
                  {{ items.icon }}
                </mat-icon>
                <mat-icon
                  attr.data-testid="dlt_{{ i }}_dlt_panel_title_icon_no_content"
                  *ngIf="!items || !items.icon"
                  class="content__icon"
                >
                  label_important
                </mat-icon>
              </div>
              <!-- End:: Icon -->

              <!-- Begin:: Avatar -->
              <div
                attr.data-testid="dlt_{{ i }}_dlt_panel_title_avatar"
                *ngIf="items && items.avatar && !items.loading"
              >
                <img
                  [src]="items.avatar"
                  class="content__avatar-image"
                  alt=""
                  attr.data-testid="dlt_{{ i }}_dlt_panel_title_avatar_image"
                />
              </div>
              <!-- End:: Avatar -->

              <!-- Begin:: Spinner -->
              <mat-icon *ngIf="items && items.loading" class="content__icon">
                <mat-progress-spinner
                  [color]="'accent'"
                  [mode]="'indeterminate'"
                  [diameter]="30"
                  attr.data-testid="dlt_{{
                    i
                  }}_dlt_panel_title_progress_spinner"
                >
                </mat-progress-spinner>
              </mat-icon>
              <!-- End:: Spinner -->
            </div>

            <!-- Begin:: Loading Spinner for Mobile View -->
            <div
              style="width: 8.33%"
              *ngIf="isMobileView && !alwaysShowIcon && items && items.loading"
            >
              <!-- Begin:: Spinner -->
              <mat-icon>
                <mat-progress-spinner
                  [color]="'accent'"
                  [mode]="'indeterminate'"
                  [diameter]="30"
                  attr.data-testid="dlt_{{
                    i
                  }}_dlt_panel_title_progress_spinner_mobile"
                >
                </mat-progress-spinner>
              </mat-icon>
              <!-- End:: Spinner -->
            </div>
            <!-- Begin:: Loading Spinner for Mobile View -->
            <!-- End:: Icon & Loading Spinner -->

            <!-- Begin:: First Element -->
            <div
              [ngStyle]="{ width: isMobileView ? '91.66%' : '41.66%' }"
              class="px-0"
              [class.pl-4]="isMobileView && items.loading"
            >
              <!-- The content that previously was here, is now in ./dlt-elements-old/dlt-element-1.html -->
              <dlt-single-element
                *ngIf="items?.data?.[0]"
                [panel]="panel"
                [item]="items.data[0]"
                [headerRow]="true"
                (onDataElementClickAction)="dataElementActionClicked($event, 0)"
                attr.data-testid="dlt_{{ i }}_dlt_first_element_{{
                  items.data[0].key?.toLowerCase()?.replaceAll(' ', '_')
                }}"
              ></dlt-single-element>
            </div>
            <!-- End:: First Element -->

            <!-- Begin:: Second Element -->
            <!-- **** Second Element for Mobile view in Contents Sections -->
            <div style="width: 50%" *ngIf="!isMobileView">
              <!-- The content that previously was here, is now in ./dlt-elements-old/dlt-element-2.html -->
              <dlt-single-element
                *ngIf="items?.data?.[1]"
                [panel]="panel"
                [item]="items.data[1]"
                [headerRow]="true"
                (onDataElementClickAction)="dataElementActionClicked($event, 1)"
                attr.data-testid="dlt_{{ i }}_dlt_second_element_{{
                  items.data[1].key?.toLowerCase()?.replaceAll(' ', '_')
                }}"
              ></dlt-single-element>
            </div>
            <!-- End:: Second Element -->
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- End:: Expansion Panel Header -->

      <!-- Begin:: Expansion Panel Body -->
      <ng-template matExpansionPanelContent>
        <div class="row">
          <!-- Begin:: Second Element for Mobile View -->
          <div class="w-100 pl-3" *ngIf="isMobileView">
            <!-- The content that previously was here, is now in ./dlt-elements-old/dlt-element-2-mobile.html -->
            <dlt-single-element
              *ngIf="items?.data?.[1]"
              [panel]="panel"
              [item]="items.data[1]"
              [headerRow]="true"
              (onDataElementClickAction)="dataElementActionClicked($event, 1)"
              attr.data-testid="dlt_{{ i }}_dlt_second_element_mobile{{
                items.data[1].key?.toLowerCase()?.replaceAll(' ', '_')
              }}"
            ></dlt-single-element>
          </div>
          <!-- End:: Second Element for Mobile View -->

          <!-- Begin:: Body Contents -->

          <!--
            ****
            When implementing new content view; add it to the
              1. Full Width Content View
              2. Content with Separator
              3. Typical Content View
            ****
          -->

          <ng-container *ngIf="(items?.data?.length ?? 0) > 2">
            <!-- **** First Two Items were Removed; Displayed in Above Sections **** -->
            <div
              *ngFor="let item of items.data | slice : 2; index as j"
              class="pl-3"
              [class.w-33]="
                !isMobileView &&
                !(item && item.type && item.type === 'separator') &&
                !item.fullWidthColumn
              "
              [class.w-100]="
                isMobileView ||
                (item && item.type && item.type === 'separator') ||
                !!item.fullWidthColumn
              "
            >
              <!-- The content that previously was here, is now in ./dlt-elements-old/dlt-element-main.html -->
              <dlt-single-element
                *ngIf="item"
                [panel]="panel"
                [item]="item"
                (onDataElementClickAction)="dataElementActionClicked($event, i)"
                attr.data-testid="dlt_{{ i }}_dlt_element_{{
                  items.data[2 + j].key?.toLowerCase()?.replaceAll(' ', '_')
                }}"
              ></dlt-single-element>
            </div>
          </ng-container>
          <!-- End:: Body Contents -->
        </div>

        <!-- Begin:: Action Buttons -->
        <div
          *ngIf="
            items.actions && items.actions !== [] && items.actions.length > 0
          "
          class="action-button__row"
        >
          <span *ngFor="let action of items.actions">
            <!--Begin:: Group Action Buttons -->
            <ng-container
              *ngIf="
                !!action.groupButton &&
                action.groupedActions !== [] &&
                (action.groupedActions?.length ?? 0) > 0
              "
            >
              <!-- Begin:: Main Action Button -->
              <button
                mat-flat-button
                [matMenuTriggerFor]="createGroupAction"
                class="group-buttons action-button"
                [matTooltip]="action.tooltip"
                [disabled]="action?.disabled"
                attr.data-testid="dlt_{{ i }}_dlt_action_btn_{{
                  action.face.toLowerCase().replaceAll(' ', '_')
                }}"
              >
                <mat-icon>{{ action.icon }}</mat-icon>
                {{ action.face }}
                <mat-icon matSuffix class="material-icons"
                  >arrow_drop_down</mat-icon
                >
              </button>
              <!-- End:: Main Action Button -->

              <!-- Begin:: Sub Actions -->
              <mat-menu #createGroupAction="matMenu">
                <div
                  [ngClass]="
                    action?.scrollbarEnabled ?? false ? 'custom-menu' : ''
                  "
                >
                  <ng-container
                    *ngFor="let groupAction of action.groupedActions"
                  >
                    <!-- Begin:: Action button with text & icon -->
                    <button
                      mat-menu-item
                      class="group-buttons__contents"
                      *ngIf="
                        groupAction &&
                        groupAction.icon &&
                        groupAction.face &&
                        !action.slideToggle
                      "
                      [matTooltip]="groupAction.tooltip"
                      (click)="
                        triggerAction(panel, groupAction, items.index + 1)
                      "
                      [disabled]="items.loading || action?.disabled"
                      attr.data-testid="dlt_{{ i }}_dlt_group_action_btn_{{
                        groupAction.face.toLowerCase().replaceAll(' ', '_')
                      }}"
                    >
                      <mat-icon
                        *ngIf="groupAction.icon"
                        class="group-buttons__icons"
                        >{{ groupAction.icon }}</mat-icon
                      >
                      <span *ngIf="groupAction.face">{{
                        groupAction.face
                      }}</span>
                    </button>
                    <!-- End:: Action button with text & icon -->

                    <!-- Begin:: Action button with text only-->
                    <button
                      mat-menu-item
                      class="group-buttons__contents"
                      *ngIf="
                        groupAction &&
                        groupAction.face &&
                        !action.slideToggle &&
                        !(groupAction.icon || groupAction.icon !== '')
                      "
                      [matTooltip]="groupAction.tooltip"
                      (click)="
                        triggerAction(panel, groupAction, items.index + 1)
                      "
                      [disabled]="items.loading || action?.disabled"
                      attr.data-testid="dlt_{{ i }}_dlt_group_action_btn_{{
                        groupAction.face.toLowerCase().replaceAll(' ', '_')
                      }}"
                    >
                      <span *ngIf="groupAction.face">{{
                        groupAction.face
                      }}</span>
                      <mat-icon class="group-buttons__icons">{{
                        groupAction.icon
                      }}</mat-icon>
                    </button>
                    <!-- End:: Action button with text only-->

                    <!-- Begin:: Action button with icon only-->
                    <button
                      mat-menu-item
                      class="group-buttons__contents"
                      *ngIf="
                        groupAction &&
                        groupAction.icon &&
                        !action.slideToggle &&
                        !(groupAction.face || groupAction.face !== '')
                      "
                      [matTooltip]="groupAction.tooltip"
                      (click)="
                        triggerAction(panel, groupAction, items.index + 1)
                      "
                      [disabled]="items.loading || action?.disabled"
                      attr.data-testid="dlt_{{ i }}_dlt_group_action_btn_{{
                        groupAction.face.toLowerCase().replaceAll(' ', '_')
                      }}"
                    >
                      <mat-icon class="group-buttons__icons">{{
                        groupAction.icon
                      }}</mat-icon>
                    </button>
                    <!-- End:: Action button with icon only-->

                    <!-- Begin:: Slide Toggle Action Button -->
                    <mat-slide-toggle
                      *ngIf="
                        groupAction &&
                        groupAction.slideToggle &&
                        groupAction.slideToggleConfig
                      "
                      color="primary"
                      (change)="
                        triggerAction(panel, groupAction, items.index + 1)
                      "
                      [(ngModel)]="groupAction.slideToggleConfig.value"
                      [matTooltip]="groupAction.tooltip"
                      [disabled]="action?.disabled ?? false"
                      class="slide-toggle group-buttons__contents"
                      attr.data-testid="dlt_{{ i }}_dlt_slide_toggle_{{
                        groupAction.tooltip.toLowerCase().replaceAll(' ', '_')
                      }}"
                    >
                      <span slide-toggle__text>{{
                        groupAction?.slideToggleConfig?.value
                          ? groupAction?.slideToggleConfig?.onText
                          : groupAction?.slideToggleConfig?.offText
                      }}</span>
                    </mat-slide-toggle>
                    <!-- End:: Slide Toggle Action Button -->
                  </ng-container>
                </div>
              </mat-menu>
              <!-- End:: Sub Actions -->
            </ng-container>
            <!--End:: Group Action Buttons -->

            <!-- Begin:: None Group Action Buttons -->
            <ng-container *ngIf="!action.groupButton">
              <!-- Begin:: Action button with text & icon -->
              <button
                *ngIf="
                  action && action.icon && action.face && !action.slideToggle
                "
                class="action-button"
                mat-flat-button
                [matTooltip]="action.tooltip"
                (click)="triggerAction(panel, action, items.index + 1)"
                [disabled]="items.loading || action?.disabled"
                attr.data-testid="dlt_{{ i }}_dlt_action_{{
                  action.face.toLowerCase().replaceAll(' ', '_')
                }}"
              >
                <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
                <span *ngIf="action.face">{{ action.face }}</span>
              </button>
              <!-- End:: Action button with text & icon -->

              <!-- Being:: Action button with progress bar-->
              <button
                *ngIf="action && action.progressBar && !action.slideToggle"
                class="action-button action-button__progress-bar"
                mat-flat-button
                [matTooltip]="action.tooltip"
                [disabled]="action?.disabled"
                (click)="triggerAction(panel, action, items.index + 1)"
                attr.data-testid="dlt_{{ i }}_dlt_action_{{
                  action.tooltip.toLowerCase().replaceAll(' ', '_')
                }}"
              >
                <dlt-progress-bar
                  [properties]="action.progressBar"
                ></dlt-progress-bar
                ><br />
                <div>
                  <span class="action-button__progress-bar-tooltip">{{
                    action.tooltip
                  }}</span>
                </div>
              </button>
              <!-- Being:: Action button with progress bar-->

              <!-- Begin:: Action button with text only-->
              <button
                *ngIf="
                  action &&
                  action.face &&
                  !(action.icon || action.icon !== '') &&
                  !action.slideToggle
                "
                class="action-button"
                mat-flat-button
                [matTooltip]="action.tooltip"
                (click)="triggerAction(panel, action, items.index + 1)"
                [disabled]="items.loading || action?.disabled"
                attr.data-testid="dlt_{{ i }}_dlt_action_{{
                  action.tooltip.toLowerCase().replaceAll(' ', '_')
                }}"
              >
                <span *ngIf="action.face">{{ action.face }}</span>
                <mat-icon>{{ action.icon }}</mat-icon>
              </button>
              <!-- End:: Action button with text only-->

              <!-- Begin:: Action button with icon only-->
              <button
                *ngIf="
                  action &&
                  action.icon &&
                  !(action.face || action.face !== '') &&
                  !action.progressBar &&
                  !action.slideToggle
                "
                class="action-button"
                mat-icon-button
                [matTooltip]="action.tooltip"
                (click)="triggerAction(panel, action, items.index + 1)"
                [disabled]="items.loading || action?.disabled"
                attr.data-testid="dlt_{{ i }}_dlt_action_icon_btn_{{
                  action.icon.toLowerCase().replaceAll(' ', '_')
                }}"
              >
                <mat-icon>{{ action.icon }}</mat-icon>
              </button>
              <!-- End:: Action button with icon only-->

              <!-- Begin:: Slide Toggle Action Button -->
              <mat-slide-toggle
                *ngIf="action && action.slideToggle && action.slideToggleConfig"
                color="primary"
                (change)="triggerAction(panel, action, items.index + 1)"
                [(ngModel)]="action.slideToggleConfig.value"
                [matTooltip]="action.tooltip"
                [disabled]="action?.disabled ?? false"
                class="slide-toggle"
                attr.data-testid="dlt_{{ i }}_dlt_slide_toggle_{{
                  action.tooltip.toLowerCase().replaceAll(' ', '_')
                }}"
              >
                <span class="slide-toggle__text">{{
                  action?.slideToggleConfig?.value
                    ? action?.slideToggleConfig?.onText
                    : action?.slideToggleConfig?.offText
                }}</span>
              </mat-slide-toggle>
              <!-- End:: Slide Toggle Action Button -->
            </ng-container>
            <!-- End:: None Group Action Buttons -->
          </span>
        </div>
        <!-- End:: Action Buttons -->
      </ng-template>
      <!-- End:: Expansion Panel Body -->
    </mat-expansion-panel>
  </div>
</mat-accordion>
<!-- End:: Table View -->

<!-- Begin:: Column View -->
<div *ngIf="columnView && statesKey && states.length > 0">
  <div cdkDropListGroup>
    <div class="row flex-lg-nowrap mt-4 pb-4 pt-2 scrollbar">
      <div
        *ngFor="let state of states"
        [class.col-12]="isMobileView"
        [class.col-lg-4]="!isMobileView"
      >
        <mat-accordion [multi]="true">
          <div class="state-header">Status</div>
          <div class="state">{{ state | titlecase }}</div>
          <div class="column-view-body scrollbar">
            <div
              cdkDropList
              (cdkDropListDropped)="drop($event)"
              [cdkDropListData]="getColumnDataList(state)"
              class="drop-list"
              [cdkDropListDisabled]="!draggable"
            >
              <mat-expansion-panel
                #panel
                *ngFor="let items of getColumnDataList(state)"
                [disabled]="items && items.loading"
                [expanded]="
                  items && !items.loading && (items.expanded || expandAll)
                "
                cdkDrag
              >
                <!-- Begin:: Expansion Panel Header -->
                <mat-expansion-panel-header class="content__header">
                  <mat-panel-title>
                    <div class="col-sm-12">
                      <div class="row">
                        <div
                          class="col-sm-3 d-none d-sm-block"
                          *ngIf="!isMobileView || alwaysShowIcon"
                        >
                          <div *ngIf="items && !items.avatar && !items.loading">
                            <mat-icon
                              *ngIf="items && items.icon"
                              class="content__icon"
                              >{{ items.icon }}
                            </mat-icon>
                            <mat-icon
                              *ngIf="!items || !items.icon"
                              class="content__icon"
                            >
                              label_important
                            </mat-icon>
                          </div>
                          <div *ngIf="items && items.avatar && !items.loading">
                            <img
                              [src]="items.avatar"
                              class="content__avatar-image"
                              alt=""
                            />
                          </div>
                          <mat-icon
                            *ngIf="items && items.loading"
                            class="content__icon"
                          >
                            <mat-progress-spinner
                              [color]="'accent'"
                              [mode]="'indeterminate'"
                              [diameter]="30"
                            >
                            </mat-progress-spinner>
                          </mat-icon>
                        </div>
                        <div
                          [class.col-5]="!isMobileView"
                          [class.col-11]="isMobileView"
                          style="padding-left: 0px; padding-right: 0px"
                        >
                          <dlt-single-element
                            *ngIf="items?.data?.[0]"
                            [panel]="panel"
                            [item]="items.data[0]"
                            [headerRow]="true"
                            (onDataElementClickAction)="
                              dataElementActionClicked($event, 0)
                            "
                          ></dlt-single-element>
                        </div>
                      </div>
                    </div>
                  </mat-panel-title>
                  <mat-panel-description
                    *ngIf="items && items.loading && isMobileView"
                    class="d-sm-none"
                  >
                    <mat-icon>
                      <mat-progress-spinner
                        [color]="'accent'"
                        [mode]="'indeterminate'"
                        [diameter]="30"
                      >
                      </mat-progress-spinner>
                    </mat-icon>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <!-- End:: Expansion Panel Header -->

                <!-- Begin:: Expansion Panel Body -->
                <ng-template matExpansionPanelContent>
                  <div class="row">
                    <!-- Begin:: Contents for Mobile View -->
                    <div
                      class="col-sm-4 d-block d-sm-none"
                      *ngIf="isMobileView"
                    >
                      <dlt-single-element
                        *ngIf="items?.data?.[1]"
                        [panel]="panel"
                        [item]="items.data[1]"
                        [headerRow]="true"
                        (onDataElementClickAction)="
                          dataElementActionClicked($event, 1)
                        "
                      ></dlt-single-element>
                    </div>
                    <!-- End:: Contents for Mobile View -->

                    <ng-container *ngIf="(items?.data?.length ?? 0) > 2">
                      <div
                        *ngFor="let item of items.data | slice : 2"
                        [class.col-sm-6]="
                          !isMobileView &&
                          !(item && item.type && item.type === 'separator') &&
                          !item.fullWidthColumn
                        "
                        [class.col-sm-12]="
                          isMobileView ||
                          (item && item.type && item.type === 'separator') ||
                          !!item.fullWidthColumn
                        "
                      >
                        <dlt-single-element
                          *ngIf="item"
                          [panel]="panel"
                          [item]="item"
                        ></dlt-single-element>
                      </div>
                    </ng-container>
                  </div>

                  <ng-container
                    *ngIf="items && items.images && items.images !== []"
                  >
                    <div class="row mt-3">
                      <div *ngFor="let image of items.images">
                        <div class="col-sm-1">
                          <!-- <img class="zoom-in" [src]="image.thumb_url" style="max-width: 120px; max-height: 120px;">    -->
                          <!-- <app-image-with-loading [thumb]="image.thumb_url" [image]="image.image_url"> -->
                          <!-- </app-image-with-loading> -->
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Actions Buttons Related -->
                  <div *ngIf="items" class="row action-button-row">
                    <span>
                      <span
                        *ngIf="
                          items.actions &&
                          items.actions !== [] &&
                          items.actions.length > 0
                        "
                      >
                        <!-- General Action Buttons -->
                        <span *ngFor="let action of items.actions">
                          <ng-container *ngIf="!action.groupButton">
                            <!-- Action button with text & icon -->
                            <button
                              *ngIf="
                                action &&
                                action.icon &&
                                action.face &&
                                !action.slideToggle
                              "
                              class="action-button"
                              mat-flat-button
                              [matTooltip]="action.tooltip"
                              (click)="
                                triggerAction(panel, action, items.index + 1)
                              "
                              [disabled]="items.loading"
                            >
                              <mat-icon *ngIf="action.icon">{{
                                action.icon
                              }}</mat-icon>
                              <span *ngIf="action.face">{{ action.face }}</span>
                            </button>
                            <!-- Action button with progress bar-->
                            <button
                              *ngIf="
                                action &&
                                action.progressBar &&
                                !action.slideToggle
                              "
                              class="action-button action-button__progress-bar"
                              mat-flat-button
                              [matTooltip]="action.tooltip"
                              (click)="
                                triggerAction(panel, action, items.index + 1)
                              "
                            >
                              <dlt-progress-bar
                                [properties]="action.progressBar"
                              ></dlt-progress-bar
                              ><br />
                              <div>
                                <span
                                  class="action-button__progress-bar-tooltip"
                                  >{{ action.tooltip }}</span
                                >
                              </div>
                            </button>
                            <!-- Action button with text only-->
                            <button
                              *ngIf="
                                action &&
                                action.face &&
                                !(action.icon || action.icon !== '') &&
                                !action.slideToggle
                              "
                              class="action-button"
                              mat-flat-button
                              [matTooltip]="action.tooltip"
                              (click)="
                                triggerAction(panel, action, items.index + 1)
                              "
                              [disabled]="items.loading"
                            >
                              <span *ngIf="action.face">{{ action.face }}</span>
                              <mat-icon>{{ action.icon }}</mat-icon>
                            </button>
                            <!-- Action button with icon only-->
                            <button
                              *ngIf="
                                action &&
                                action.icon &&
                                !(action.face || action.face !== '') &&
                                !action.progressBar &&
                                !action.slideToggle
                              "
                              class="action-button"
                              mat-icon-button
                              [matTooltip]="action.tooltip"
                              (click)="
                                triggerAction(panel, action, items.index + 1)
                              "
                              [disabled]="items.loading"
                            >
                              <mat-icon>{{ action.icon }}</mat-icon>
                            </button>
                            <!-- Slide Toggle Action Button -->
                            <mat-slide-toggle
                              *ngIf="
                                action &&
                                action.slideToggle &&
                                action.slideToggleConfig
                              "
                              color="primary"
                              (change)="
                                triggerAction(panel, action, items.index + 1)
                              "
                              [(ngModel)]="action.slideToggleConfig.value"
                              [matTooltip]="action.tooltip"
                              class="slide-toggle"
                            >
                              <span class="slide-toggle__text">{{
                                action?.slideToggleConfig?.value
                                  ? action?.slideToggleConfig?.onText
                                  : action?.slideToggleConfig?.offText
                              }}</span>
                            </mat-slide-toggle>
                          </ng-container>
                          <!-- End of General Action Buttons -->

                          <!-- Group Action Buttons -->
                          <ng-container
                            *ngIf="
                              !!action.groupButton &&
                              action.groupedActions !== [] &&
                              (action.groupedActions?.length ?? 0) > 0
                            "
                          >
                            <!-- The button with hover for the web view -->
                            <button
                              *ngIf="!isMobileView"
                              mat-flat-button
                              #createGroupMenuTrigger="matMenuTrigger"
                              [matMenuTriggerFor]="createGroupAction"
                              class="group-buttons action-button"
                              (mouseover)="
                                createGroupMenuTrigger.openMenu();
                                $event.stopPropagation()
                              "
                              (mouseout)="$event.stopPropagation()"
                              [matTooltip]="action.tooltip"
                            >
                              <mat-icon>{{ action.icon }}</mat-icon>
                              {{ action.face }}
                              <mat-icon matSuffix class="material-icons"
                                >arrow_drop_down</mat-icon
                              >
                            </button>
                            <!-- The button without hover for the mobile view -->
                            <button
                              *ngIf="isMobileView"
                              mat-flat-button
                              [matMenuTriggerFor]="createGroupAction"
                              class="group-buttons action-button"
                              [matTooltip]="action.tooltip"
                            >
                              <mat-icon>{{ action.icon }}</mat-icon>
                              {{ action.face }}
                              <mat-icon matSuffix class="material-icons"
                                >arrow_drop_down</mat-icon
                              >
                            </button>
                            <!-- The Actions -->
                            <mat-menu #createGroupAction="matMenu">
                              <ng-container
                                *ngFor="
                                  let groupAction of action.groupedActions
                                "
                              >
                                <!-- Action button with text & icon -->
                                <button
                                  mat-menu-item
                                  class="group-buttons-contents"
                                  *ngIf="
                                    groupAction &&
                                    groupAction.icon &&
                                    groupAction.face &&
                                    !action.slideToggle
                                  "
                                  [matTooltip]="groupAction.tooltip"
                                  (click)="
                                    triggerAction(
                                      panel,
                                      groupAction,
                                      items.index + 1
                                    )
                                  "
                                  [disabled]="items.loading"
                                >
                                  <mat-icon
                                    *ngIf="groupAction.icon"
                                    class="group-buttons-icons"
                                    >{{ groupAction.icon }}
                                  </mat-icon>
                                  <span *ngIf="groupAction.face">{{
                                    groupAction.face
                                  }}</span>
                                </button>
                                <!-- Action button with text only-->
                                <button
                                  mat-menu-item
                                  class="group-buttons-contents"
                                  *ngIf="
                                    groupAction &&
                                    groupAction.face &&
                                    !(
                                      groupAction.icon ||
                                      groupAction.icon !== ''
                                    ) &&
                                    !action.slideToggle
                                  "
                                  [matTooltip]="groupAction.tooltip"
                                  (click)="
                                    triggerAction(
                                      panel,
                                      groupAction,
                                      items.index + 1
                                    )
                                  "
                                  [disabled]="items.loading"
                                >
                                  <span *ngIf="groupAction.face">{{
                                    groupAction.face
                                  }}</span>
                                  <mat-icon class="group-buttons-icons">{{
                                    groupAction.icon
                                  }}</mat-icon>
                                </button>
                                <!-- Action button with icon only-->
                                <button
                                  mat-menu-item
                                  class="group-buttons-contents"
                                  *ngIf="
                                    groupAction &&
                                    groupAction.icon &&
                                    !(
                                      groupAction.face ||
                                      groupAction.face !== ''
                                    ) &&
                                    !action.slideToggle
                                  "
                                  [matTooltip]="groupAction.tooltip"
                                  (click)="
                                    triggerAction(
                                      panel,
                                      groupAction,
                                      items.index + 1
                                    )
                                  "
                                  [disabled]="items.loading"
                                >
                                  <mat-icon class="group-buttons-icons">{{
                                    groupAction.icon
                                  }}</mat-icon>
                                </button>
                                <!-- Slide Toggle Action Button -->
                                <mat-slide-toggle
                                  *ngIf="
                                    groupAction &&
                                    groupAction.slideToggle &&
                                    groupAction.slideToggleConfig
                                  "
                                  color="primary"
                                  (change)="
                                    triggerAction(
                                      panel,
                                      groupAction,
                                      items.index + 1
                                    )
                                  "
                                  [(ngModel)]="
                                    groupAction.slideToggleConfig.value
                                  "
                                  [matTooltip]="groupAction.tooltip"
                                  class="slide-toggle group-buttons-contents"
                                >
                                  <span>{{
                                    groupAction?.slideToggleConfig?.value
                                      ? groupAction?.slideToggleConfig?.onText
                                      : groupAction?.slideToggleConfig?.offText
                                  }}</span>
                                </mat-slide-toggle>
                              </ng-container>
                            </mat-menu>
                            <!-- End of Group Action Buttons -->
                          </ng-container>
                        </span>
                      </span>
                      <!--End of Action Buttons -->
                    </span>
                  </div>
                  <!-- End of Actions Buttons Related -->
                </ng-template>
                <!-- End:: Expansion Panel Body -->
              </mat-expansion-panel>
            </div>
          </div>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
<!-- End:: Column View -->

<!-- Begin:: Footer -->
<div class="bottom-panel mt-3">
  <!-- Begin:: Number of Results Selector -->
  <div class="w-100">
    <mat-select
      *ngIf="showMoreButtonEnabled && totalCount > data.length"
      class="bottom-panel__results-drop-down"
      [(ngModel)]="pageSize"
      (selectionChange)="pageSizeChanged($event)"
      [disabled]="loadingShowMore"
      data-testid="dlt_select_list_size"
    >
      <mat-option
        *ngFor="let step of steps"
        [value]="step"
        attr.data-testid="dlt_select_list_size_option_{{ step }}"
        >{{ step }} results</mat-option
      >
    </mat-select>
  </div>
  <!-- End:: Number of Results Selector -->

  <!-- Begin:: Show More Button -->
  <div class="w-100">
    <button
      *ngIf="showMoreButtonEnabled && totalCount > data.length"
      class="bottom-panel__show-more-button"
      mat-button
      (click)="triggerShowMore()"
      [disabled]="loadingShowMore"
      data-testid="dlt_btn_show_more"
    >
      <mat-icon *ngIf="loadingShowMore">
        <mat-spinner diameter="20"> </mat-spinner> </mat-icon
      >Show {{ pageSize }} More
    </button>
  </div>
  <!-- End:: Show More Button -->
</div>
<!-- End:: Footer -->
