import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ELAutocompleteElement } from '@el-autocomplete';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { isArray } from 'lodash';

import { BaseChipComponent } from '../../base-chip/base-chip.component';
import { ChipInputSearchHelper } from '../../helpers/chip-input-search.helper';
import { FilterSelectChipProperties } from '../../types/chip-properties/filter-select';
import { getOptionsAsPromiseArray } from '../../utils/promise-or-array';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-cis-filter-select-chip-search-filter',
  templateUrl: './filter-select-chip-search-filter.component.html',
  styleUrls: ['./filter-select-chip-search-filter.component.scss'],
})
export class FilterSelectChipSearchFilterComponent
  extends BaseChipComponent
  implements OnInit, OnDestroy
{
  @Input() displayText?: string;
  @Output() itemsSelected = new EventEmitter<ELAutocompleteElement[]>();

  filterSelect = new FormControl();
  options: Promise<ELAutocompleteElement[]> = Promise.all([]);

  get selectedDataChipProperties(): FilterSelectChipProperties | undefined {
    return this.selectedData?.chipProperties as FilterSelectChipProperties;
  }

  constructor(chipInputSearchHelper: ChipInputSearchHelper) {
    super(chipInputSearchHelper);
    this.filterSelect.valueChanges
      .pipe(startWith(undefined), debounceTime(200))
      .subscribe((chipValue: string | undefined) => {
        if (this.selectedDataChipProperties) {
          this.selectedDataChipProperties.value = chipValue;
        }
        this.editedData.emit(this.selectedData);
      });
  }

  ngOnInit(): void {
    this.filterSelect.setValue(this.selectedDataChipProperties?.value);
    this.minWidth = this.selectedData?.size || this.minWidth;
    this.filterSelect.valueChanges
      .pipe(debounceTime(50), takeUntil(this.onDestroy$))
      .subscribe((newValue) => {
        if (!this.formControl) this.formControl = this.filterSelect;
        this.value = newValue;
        if (this.selectedData?.validations) {
          super.validate_value();
        }
        super.resize(this.minWidth);
      });

    if (!this.formControl) this.formControl = this.filterSelect;

    this.formControl.setValue(this.filterSelect.value);
    this.value = this.filterSelect.value;
    this.options = getOptionsAsPromiseArray(
      this.selectedDataChipProperties?.properties?.options
    );

    super.resize(this.minWidth);
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  updateChipValues(event: ELAutocompleteElement | ELAutocompleteElement[]) {
    if (isArray(event)) return;

    this.filterSelect.setValue(event.value);
  }
}
