import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseWidget, NgCompInputs } from 'gridstack/dist/angular';
import { indexOf } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

import {
  ANALYZING_MODES,
  STORAGE_QUOTA_BASE_UNIT,
  STORAGE_QUOTA_UNIT,
  WIDGET_KEYS,
} from '@shared/constants';
import {
  CommonResponseDTO,
  IDonutWidgetResponse,
  IStorage,
  IWidget,
  IWidgetQuotaResponse,
} from '@shared/interfaces';

import { MemoryUnitConversionService } from '../../../../../services/memory-unit-conversion.service';
import { PermissionFiltrationService } from '../../../../../services/permission-filtration.service';
import { SnackbarService } from '../../../../../services/snackbar.service';
import {
  LoggedUserService,
  UserInfoResponseDTO,
} from '../../../../auth/services';
import { ColorPaletteService } from '../../../../setup/appearance/services/color-palette.api.service';
import { ColorSchemeService } from '../../../../setup/appearance/services/color-scheme.service';
import { StorageService } from '../../../../storage/services/storage.service';
import { WidgetApiService } from '../../../services/widgets.api.service';

export enum STORAGE_TYPES {
  CENTRALIZED = 'CENTRALIZED',
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export enum FIELD_NAMES {
  USED_SPACE = 'Used Space',
  FREE_SPACE = 'Free Space',
}

export interface IWidgetData {
  name: string;
  value: number;
}

@Component({
  selector: 'app-donut-widget',
  templateUrl: 'donut-widget.component.html',
  styleUrls: ['donut-widget.component.scss'],
})
export class DonutWidgetComponent
  extends BaseWidget
  implements OnInit, OnChanges, OnDestroy
{
  @Input() widget: IWidget;
  @Input() clickable: boolean;
  @Input() closable: boolean;

  /**
   * Legend colors list
   * Defines the colors of the widget legends
   * Add more if there are more data fields
   */
  colors = ['#7AA3E5', '#A8385D', '#ff4081'];

  // data
  single: IWidgetData[];
  description: string;
  unit: STORAGE_QUOTA_UNIT;
  widgetCount: number;
  isMultiple = false;
  appsList: {
    appName: string;
    unit: STORAGE_QUOTA_UNIT;
    widgetData: IWidgetData[];
  }[] = [];
  widgetType: STORAGE_TYPES;
  usedSpace: number;
  freeSpace: number;
  total: number;
  quotaConfig: IWidgetQuotaResponse;

  STORAGE_TYPES = STORAGE_TYPES;
  WIDGET_KEYS = WIDGET_KEYS;
  FIELD_NAMES = FIELD_NAMES;

  loggedUserInfo: UserInfoResponseDTO;
  hasPermissions = false;
  isLoading = false;
  isFailedToLoad = false;

  // properties
  gradient = false;
  showLegend = false;
  showLabels = false;
  isDoughnut = true;
  view: [number, number];
  customColors = [];
  connectedApp: IStorage;

  widgetKey: string;

  thresholdExceeded = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private snackbar: SnackbarService,
    private widgetApiService: WidgetApiService,
    private permissionFiltrationService: PermissionFiltrationService,
    private colorSchemeService: ColorSchemeService,
    private memoryUnitConversionService: MemoryUnitConversionService,
    private colorPaletteService: ColorPaletteService,
    private storageService: StorageService,
    private loggedUserService: LoggedUserService
  ) {
    super();
  }

  public override serialize(): NgCompInputs | undefined {
    return this.widget ? { text: this.widget } : undefined;
  }

  isolateQuotaConnectedApps(expression: string): string {
    const parts = expression.split('::');
    const exp = new RegExp('QUOTA_CONNECTED_APPS');
    if (parts.length > 1) return parts[0];
    // To prevent already existing data from breaking the flow
    else if (exp.test(expression)) return 'QUOTA_CONNECTED_APPS';
    else return expression;
  }

  async ngOnInit() {
    this.widgetKey = this.isolateQuotaConnectedApps(this.widget.key);

    if (this.widgetKey === WIDGET_KEYS.QUOTA_CONNECTED_APPS)
      await this.storageService
        .getConnectedStorage(this.widget.associate_id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((app) => {
          if (app) this.connectedApp = app.data;
        });

    this.isLoading = true;
    this.loggedUserService.dataStore.subscribe((data) => {
      this.loggedUserInfo = data;
      if (data) {
        this.refreshWidget();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget?.currentValue?.endpoint) {
      this.isLoading = true;
      this.refreshWidget();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  onClickWidget() {
    switch (this.widgetKey) {
      case WIDGET_KEYS.QUOTA_SYSTEM:
        this.router.navigate([`/app/storage/drives`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_KEYS.QUOTA_CONNECTED_APPS:
        this.router.navigate([`/app/storage/drives`], {
          queryParams: { is_search: true, url: this.connectedApp.client_url },
        });
        break;

      case WIDGET_KEYS.USER_QUOTA:
        this.router.navigate([`/app/storage/drives`], {
          queryParams: { is_search: true },
        });
        break;
      // Add more routes as required
    }
  }

  refreshWidget() {
    this.isLoading = true;
    this.hasPermissions = this.permissionFiltrationService.validatePermissions({
      analyzingMode: ANALYZING_MODES.EVERY,
      permissions: this.widget.permissions,
    });

    this.total = 0;

    this.widgetApiService
      .getWidgetData(this.widget.endpoint)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (res: CommonResponseDTO<IDonutWidgetResponse>) => {
          this.isLoading = false;

          const config = res.data;

          if (config.quota.threshold) {
            if (config.quota.usedSpace > config.quota.threshold) {
              this.thresholdExceeded = true;
            }
          }
          this.quotaConfig = res.data.quota;

          this.widgetType = config.description as STORAGE_TYPES;

          this.total = this.memoryUnitConversionService.convertMemoryUnit(
            config.quota.quota,
            STORAGE_QUOTA_BASE_UNIT.BYTES,
            config.quota.unit
          );

          const freeSpace = config.quota.freeSpace;

          const widgetData = [
            {
              name: `${this.translate.instant('widgets.storage.free-space')}`,
              value:
                this.quotaConfig.quota === -1
                  ? -1
                  : this.memoryUnitConversionService.convertMemoryUnit(
                      freeSpace,
                      STORAGE_QUOTA_BASE_UNIT.BYTES,
                      this.quotaConfig.unit
                    ),
            },
            {
              name: this.translate.instant('widgets.storage.used-space'),
              value: this.memoryUnitConversionService.convertMemoryUnit(
                this.quotaConfig.usedSpace,
                STORAGE_QUOTA_BASE_UNIT.BYTES,
                this.quotaConfig.unit
              ),
            },
          ];

          this.single = widgetData;

          this.single.forEach((item) => {
            this.customColors.push({
              name: item.name,
              value: this.colors[indexOf(this.single, item)],
            });
          });
          this.unit = this.quotaConfig.unit;
          this.description = `${this.translate.instant(
            'widgets.storage.total'
          )} (${this.quotaConfig.unit})`;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  displayInfo(model: { name: string; value: number }) {
    return `${model.name} ${model.value.toFixed(2)} ${this.unit}`;
  }

  borderColor(item: IWidgetData) {
    return this.customColors[indexOf(this.single, item)].value;
  }

  exceededStyle(item: IWidgetData) {
    if (this.quotaConfig.threshold) {
      if (this.quotaConfig.threshold < this.quotaConfig.usedSpace) {
        if (item.name === FIELD_NAMES.FREE_SPACE) return 'red';
      }
      return null;
    }
    return null;
  }
}
