import { Injectable } from '@angular/core';

import { STORAGE_QUOTA_BASE_UNIT, STORAGE_QUOTA_UNIT } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class MemoryUnitConversionService {
  // conversion factor :: 1024 MiB -> 1 GiB
  // conversion factor :: 1000 MB -> 1 GB
  // Change accordingly
  private baseUnit = 1024;

  // Forward conversion
  bytesToKilobytes(bytes: number): number {
    return bytes / this.baseUnit;
  }

  kilobytesToMegabytes(kilobytes: number): number {
    return kilobytes / this.baseUnit;
  }

  megabytesToGigabytes(megabytes: number): number {
    return megabytes / this.baseUnit;
  }

  gigabytesToTerabytes(gigabytes: number): number {
    return gigabytes / this.baseUnit;
  }

  // Reverse conversion
  kilobytesToBytes(kilobytes: number): number {
    return kilobytes * this.baseUnit;
  }

  megabytesToKilobytes(megabytes: number): number {
    return megabytes * this.baseUnit;
  }

  gigabytesToMegabytes(gigabytes: number): number {
    return gigabytes * this.baseUnit;
  }

  terabytesToGigabytes(terabytes: number): number {
    return terabytes * this.baseUnit;
  }

  // Conversion methods
  // Forward
  bytesToMegabytes(bytes: number): number {
    return this.kilobytesToMegabytes(this.bytesToKilobytes(bytes));
  }

  bytesToGigabytes(bytes: number): number {
    return this.megabytesToGigabytes(this.bytesToMegabytes(bytes));
  }

  bytesToTerabytes(bytes: number): number {
    return this.gigabytesToTerabytes(this.bytesToGigabytes(bytes));
  }

  megabytesToTerabytes(megabytes: number): number {
    return this.bytesToTerabytes(this.megabytesToBytes(megabytes));
  }

  // Reverse
  megabytesToBytes(megabytes: number) {
    return this.kilobytesToBytes(this.megabytesToKilobytes(megabytes));
  }

  gigabytesToBytes(gigabytes: number) {
    return this.megabytesToBytes(this.gigabytesToMegabytes(gigabytes));
  }

  terabytesToBytes(terabytes: number) {
    return this.gigabytesToBytes(this.terabytesToGigabytes(terabytes));
  }

  terabytesToMegabytes(terabytes: number): number {
    return this.bytesToMegabytes(this.terabytesToBytes(terabytes));
  }

  convertMemoryUnit(
    value: number,
    from: STORAGE_QUOTA_UNIT | STORAGE_QUOTA_BASE_UNIT,
    to: STORAGE_QUOTA_UNIT | STORAGE_QUOTA_BASE_UNIT
  ) {
    switch (from) {
      case STORAGE_QUOTA_UNIT.MB:
        if (to === STORAGE_QUOTA_UNIT.MB) return value;
        else if (to === STORAGE_QUOTA_UNIT.GB) return value / this.baseUnit;
        else if (to === STORAGE_QUOTA_UNIT.TB)
          return this.bytesToTerabytes(this.megabytesToBytes(value));
        else return this.kilobytesToBytes(this.megabytesToKilobytes(value));

      case STORAGE_QUOTA_UNIT.GB:
        if (to === STORAGE_QUOTA_UNIT.MB) return value * this.baseUnit;
        else if (to === STORAGE_QUOTA_UNIT.GB) return value;
        else if (to === STORAGE_QUOTA_UNIT.TB) return value / this.baseUnit;
        else return this.megabytesToBytes(this.gigabytesToMegabytes(value));

      case STORAGE_QUOTA_UNIT.TB:
        if (to === STORAGE_QUOTA_UNIT.MB)
          return this.bytesToMegabytes(this.terabytesToBytes(value));
        else if (to === STORAGE_QUOTA_UNIT.GB) return value * this.baseUnit;
        else if (to === STORAGE_QUOTA_UNIT.TB) return value;
        else return this.gigabytesToBytes(this.terabytesToGigabytes(value));

      case STORAGE_QUOTA_BASE_UNIT.BYTES:
        if (to === STORAGE_QUOTA_UNIT.MB)
          return this.kilobytesToMegabytes(this.bytesToKilobytes(value));
        else if (to === STORAGE_QUOTA_UNIT.GB)
          return this.megabytesToGigabytes(this.bytesToMegabytes(value));
        else if (to === STORAGE_QUOTA_UNIT.TB)
          return this.gigabytesToTerabytes(this.bytesToGigabytes(value));
        else return value;
    }
    return null;
  }

  // Add more conversions as required
}
