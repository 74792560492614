import { EXTENSIONS, FILE_TYPES, MIME_TYPES } from '@shared/constants';

type Keys = keyof typeof FILE_TYPES;

export function getMimeTypesForFileType(fileType: FILE_TYPES): MIME_TYPES[] {
  switch (fileType) {
    case FILE_TYPES.CSV:
      return [MIME_TYPES.CSV];

    case FILE_TYPES.AUDIO:
      return [
        MIME_TYPES.AUDIO_AIFF,
        MIME_TYPES.AUDIO_MP3,
        MIME_TYPES.AUDIO_MP4,
        MIME_TYPES.AUDIO_WAV,
      ];

    case FILE_TYPES.DOCS:
      return [
        MIME_TYPES.DOCS_1,
        MIME_TYPES.DOCS_2,
        MIME_TYPES.DOCS_3,
        MIME_TYPES.DOCS_4,
      ];

    case FILE_TYPES.EML:
      return [MIME_TYPES.EMAIL];

    case FILE_TYPES.FOLDER:
      return [MIME_TYPES.FOLDER];

    case FILE_TYPES.IMAGE:
      return [
        MIME_TYPES.IMG_JPEG,
        MIME_TYPES.IMG_PNG,
        MIME_TYPES.IMG_BMP,
        MIME_TYPES.IMG_GIF,
        MIME_TYPES.IMG_ICO,
        MIME_TYPES.IMG_SVG,
        MIME_TYPES.IMG_SVG,
        MIME_TYPES.IMG_TIFF,
        MIME_TYPES.IMG_WEBP,
        MIME_TYPES.IMG_X_PNG,
      ];
    case FILE_TYPES.JSON:
      return [MIME_TYPES.APPLICATION_JSON];
    case FILE_TYPES.PDF:
      return [MIME_TYPES.PDF_APPLICATION, MIME_TYPES.PDF_TEXT];
    case FILE_TYPES.VIDEO:
      return [
        MIME_TYPES.VIDEO_3GP,
        MIME_TYPES.VIDEO_AVI,
        MIME_TYPES.VIDEO_FLV,
        MIME_TYPES.VIDEO_MKV,
        MIME_TYPES.VIDEO_MOV,
        MIME_TYPES.VIDEO_MP4,
        MIME_TYPES.VIDEO_MPEG,
        MIME_TYPES.VIDEO_WEBM,
        MIME_TYPES.VIDEO_WMV,
      ];
    case FILE_TYPES.EXCEL:
      return [MIME_TYPES.EXCEL_1, MIME_TYPES.EXCEL_2];
    case FILE_TYPES.ZIP:
      return [
        MIME_TYPES.COMPRESSED_7Z,
        MIME_TYPES.COMPRESSED_BZIP,
        MIME_TYPES.COMPRESSED_BZIP2,
        MIME_TYPES.COMPRESSED_GZIP,
        MIME_TYPES.COMPRESSED_RAR,
        MIME_TYPES.COMPRESSED_TAR,
        MIME_TYPES.COMPRESSED_X_ZIP,
        MIME_TYPES.COMPRESSED_ZIP,
      ];
    case FILE_TYPES.TEXT:
      return [MIME_TYPES.TEXT];
    case FILE_TYPES.RTF:
      return [MIME_TYPES.RTF];
    case FILE_TYPES.PPT:
      return [MIME_TYPES.POWERPOINT_1, MIME_TYPES.POWERPOINT_2];

    default:
      return [];
  }
}

export function getExtensionForMimeType(mimeType: MIME_TYPES): EXTENSIONS {
  switch (mimeType) {
    // images
    case MIME_TYPES.IMG_JPEG:
      return EXTENSIONS.JPEG;
    case MIME_TYPES.IMG_PNG:
      return EXTENSIONS.PNG;
    case MIME_TYPES.IMG_BMP:
      return EXTENSIONS.BMP;
    case MIME_TYPES.IMG_GIF:
      return EXTENSIONS.GIF;
    case MIME_TYPES.IMG_ICO:
      return EXTENSIONS.ICO;
    case MIME_TYPES.IMG_SVG:
      return EXTENSIONS.SVG;
    case MIME_TYPES.IMG_TIFF:
      return EXTENSIONS.TIFF;
    case MIME_TYPES.IMG_WEBP:
      return EXTENSIONS.WEBP;

    case MIME_TYPES.VIDEO_MP4:
      return EXTENSIONS.MP4;
    case MIME_TYPES.VIDEO_3GP:
      return EXTENSIONS._3GP;
    case MIME_TYPES.VIDEO_AVI:
      return EXTENSIONS.AVI;
    case MIME_TYPES.VIDEO_FLV:
      return EXTENSIONS.FLV;
    case MIME_TYPES.VIDEO_MKV:
      return EXTENSIONS.MKV;
    case MIME_TYPES.VIDEO_MOV:
      return EXTENSIONS.MOV;
    case MIME_TYPES.VIDEO_MPEG:
      return EXTENSIONS.MPEG;
    case MIME_TYPES.VIDEO_WEBM:
      return EXTENSIONS.WEBM;
    case MIME_TYPES.VIDEO_WMV:
      return EXTENSIONS.WMV;
    case MIME_TYPES.IMG_X_PNG:
      return EXTENSIONS.PNG;

    case MIME_TYPES.AUDIO_AIFF:
      return EXTENSIONS.AIFF;
    case MIME_TYPES.AUDIO_MP3:
      return EXTENSIONS.MP3;
    case MIME_TYPES.AUDIO_MP4:
      return EXTENSIONS.MP4;
    case MIME_TYPES.AUDIO_WAV:
      return EXTENSIONS.WAV;

    case MIME_TYPES.DOCS_1:
      return EXTENSIONS.DOC;
    case MIME_TYPES.DOCS_2:
      return EXTENSIONS.DOCX;
    case MIME_TYPES.DOCS_3:
      return EXTENSIONS.DOC;
    case MIME_TYPES.DOCS_4:
      return EXTENSIONS.DOC;

    case MIME_TYPES.EXCEL_1:
      return EXTENSIONS.XLS;
    case MIME_TYPES.EXCEL_2:
      return EXTENSIONS.XLSX;

    case MIME_TYPES.PDF_APPLICATION:
      return EXTENSIONS.PDF;
    case MIME_TYPES.PDF_TEXT:
      return EXTENSIONS.PDF;

    case MIME_TYPES.CSV:
      return EXTENSIONS.CSV;

    case MIME_TYPES.COMPRESSED_7Z:
      return EXTENSIONS._7Z;
    case MIME_TYPES.COMPRESSED_BZIP:
      return EXTENSIONS.BZIP;
    case MIME_TYPES.COMPRESSED_BZIP2:
      return EXTENSIONS.BZIP2;
    case MIME_TYPES.COMPRESSED_GZIP:
      return EXTENSIONS.GZIP;
    case MIME_TYPES.COMPRESSED_RAR:
      return EXTENSIONS.RAR;
    case MIME_TYPES.COMPRESSED_TAR:
      return EXTENSIONS.TAR;
    case MIME_TYPES.COMPRESSED_X_ZIP:
      return EXTENSIONS.ZIP;
    case MIME_TYPES.COMPRESSED_ZIP:
      return EXTENSIONS.ZIP;

    case MIME_TYPES.POWERPOINT_1:
      return EXTENSIONS.PPT;
    case MIME_TYPES.POWERPOINT_2:
      return EXTENSIONS.PPT;

    case MIME_TYPES.APPLICATION_JSON:
      return EXTENSIONS.JSON;

    case MIME_TYPES.TEXT:
      return EXTENSIONS.TXT;

    default:
      return EXTENSIONS.UNKNOWN;
  }
}

export function getFileIconPath(type: string): string {
  let icon = 'assets/icons/drive/default.svg';
  (Object.entries(FILE_TYPES) as any).forEach(
    ([fileType, value]: [any, any]): void => {
      const mimeTypes = getMimeTypesForFileType(FILE_TYPES[fileType as Keys]);
      if (mimeTypes.includes(type as MIME_TYPES)) {
        icon = 'assets/icons/drive/'.concat(value).concat('.svg');
      }
    }
  );

  return icon;
}
