export enum CIS_CHIP_BEHAVIORS {
  MANDATORY = 'mandatory',
  LOCK = 'lock',
  OPTIONAL = 'optional',
  PRESELECTED = 'pre_selected',
  CHAINING_PARAMS = 'chaining_params',
  DEFAULT = 'default',
}

export enum CIS_CHIP_TYPES {
  TEXT = 'text',
  NUMBER = 'number',
  NUMBER_RANGE = 'number_range',
  DATE = 'date',
  DATE_RANGE = 'date_range',
  SELECT = 'select',
  AUTOCOMPLETE = 'autocomplete',
  FILTER_SELECT = 'filter_select',
  SLIDER = 'slider',
}

export enum SECTIONS {
  MOST_FREQUENTLY_USED = 'MOST FREQUENTLY USED',
  GENERAL = 'GENERAL',
  ADVANCED = 'ADVANCED',
}
