<mat-chip
  (click)="$event.stopPropagation()"
  (focus)="$event.stopPropagation()"
  class="mat-chip"
  [disabled]="loading"
  [removable]="
    (isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()) &&
    !loading
  "
  (removed)="remove(selectedData)"
>
  <label class="has-float-label">
    <input
      #chipInput
      (focus)="inputFocusOn = true && !loading"
      (blur)="inputFocusOn = false"
      matInput
      [formControl]="slider"
      [readonly]="true"
      type="number"
      class="chip-input"
      (keydown)="$event.stopImmediatePropagation()"
      [placeholder]="placeholders[0]"
      style="margin-bottom: 1px"
      [style.margin-bottom]="slider.value ? '6px' : '-20px'"
      attr.data-testid="cis_slider_input_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}"
    />
    <div class="label">{{ selectedData?.name ?? '' | translate }}</div>
  </label>

  <button
    [disabled]="isChipLock() || isChipChiningParams() || loading"
    mat-icon-button
    matSuffix
    (click)="$event.stopImmediatePropagation(); onSliderBtnClick()"
    style="margin-right: -15px"
    [matTooltip]="openSlider ? 'Close Slider' : 'Open Slider'"
    attr.data-testid="cis_slider_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_btn_toggle_slider"
  >
    <mat-icon
      attr.data-testid="cis_slider_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}_btn_open_slider"
      *ngIf="!openSlider"
      [style.margin-top]="slider.value ? '30px' : '10px'"
    >
      keyboard_arrow_down</mat-icon
    >
    <mat-icon
      *ngIf="openSlider"
      [style.margin-top]="slider.value ? '30px' : '10px'"
      attr.data-testid="cis_slider_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}_btn_close_slider"
    >
      keyboard_arrow_up</mat-icon
    >
  </button>

  <mat-card class="slider-card" *ngIf="openSlider">
    <div>
      <label
        attr.data-testid="cis_slider_{{
          selectedData?.name?.toLowerCase()?.replace(' ', '_')
        }}_label_{{ min }}"
        style="left: 0"
        >{{ min }}</label
      >
      <label
        attr.data-testid="cis_slider_{{
          selectedData?.name?.toLowerCase()?.replace(' ', '_')
        }}_label_{{ max }}"
        style="float: right"
        >{{ max }}</label
      >
    </div>
    <mat-slider
      style="margin-top: -14px"
      [ngStyle]="styleObject()"
      [thumbLabel]="true"
      [value]="slider.value || 0"
      [min]="min"
      [max]="max"
      [tickInterval]="tickInterval"
      [step]="stepSize"
      aria-label="units"
      (input)="sliderValueChange($event)"
      attr.data-testid="cis_slider_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}"
    ></mat-slider>
  </mat-card>

  <mat-icon
    matChipRemove
    *ngIf="
      isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()
    "
    [style.cursor]="loading ? 'context-menu' : 'pointer'"
    style="vertical-align: text-top"
    attr.data-testid="cis_slider_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_btn_cancel"
    >cancel</mat-icon
  >
  <mat-icon
    *ngIf="isChipMandatory()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_slider_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_default"
    >push_pin</mat-icon
  >
  <mat-icon
    *ngIf="isChipLock()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_slider_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_locked"
    >lock</mat-icon
  >
</mat-chip>
