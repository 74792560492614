<mat-chip
  (click)="$event.stopPropagation()"
  (focus)="$event.stopPropagation()"
  class="mat-chip"
  [disabled]="loading"
  [removable]="
    (isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()) &&
    !loading
  "
  (removed)="remove(selectedData)"
  attr.data-testid="cis_number_range_{{
    selectedData?.name?.toLowerCase()?.replace(' ', '_')
  }}"
>
  <label class="has-float-label">
    <div [formGroup]="form" [ngStyle]="this.styleDivObject()">
      <el-cis-numeric-range-form-field-control
        formControlName="numberRange"
        (focused)="(inputFocusOn)"
        [selectedData]="selectedData"
        [placeholders]="placeholders"
        attr.data-testid="cis_number_range_{{
          selectedData?.name?.toLowerCase()?.replace(' ', '_')
        }}_number_range"
      ></el-cis-numeric-range-form-field-control>
    </div>
    <div class="label">{{ selectedData?.name ?? '' | translate }}</div>
  </label>

  <mat-icon
    matChipRemove
    *ngIf="
      isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()
    "
    [style.cursor]="loading ? 'context-menu' : 'pointer'"
    style="vertical-align: text-top"
    attr.data-testid="cis_number_range_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_btn_cancel"
    >cancel</mat-icon
  >
  <mat-icon
    matChipRemove
    *ngIf="isChipMandatory()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_number_range_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_mandatory"
    >push_pin</mat-icon
  >
  <mat-icon
    matChipRemove
    *ngIf="isChipLock()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_number_range_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_locked"
    >lock</mat-icon
  >
</mat-chip>
