import { FORMULA_ELEMENT_TYPES, OPERATOR_TYPES } from '@shared/constants';
import { IFormulaOperatorElement } from '@shared/interfaces';

export const logicalOperators: IFormulaOperatorElement[] = [
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.LOGICAL,
      displayText: 'AND',
      symbol: '&&',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.LOGICAL,
      displayText: 'OR',
      symbol: '||',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.LOGICAL,
      displayText: 'NOT',
      symbol: '!',
    },
  },
];
