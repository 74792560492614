<div class="horizontal-center-container pt-5">
  <div>
    <div class="d-flex justify-content-center">
      <lottie-player
        src="/assets/animations/maintenance.json"
        background="transparent"
        speed="1"
        style="width: 300px; height: 300px"
        loop
        autoplay
        alt="maintenance"
      ></lottie-player>
    </div>
  </div>
</div>

<div class="content">
  <div class="col-12">
    <h3 class="text-center">{{ 'pages.maintenance.sorry' | translate }}</h3>
  </div>

  <div class="col">
    <p class="text-center">
      {{ 'pages.maintenance.description' | translate }}<br />
      {{ 'pages.maintenance.thank_you' | translate }}
    </p>
  </div>
</div>

<div style="display: flex; justify-content: center; margin-top: 3%">
  <button mat-button (click)="logoutMaintenanceMode()">
    {{ 'pages.maintenance.logout' | translate }}
  </button>
</div>
