import { enc, TripleDES } from 'crypto-js';

export function encryptJSONObject(object: any, key: string): string {
  return TripleDES.encrypt(JSON.stringify(object), key).toString();
}

export function decryptJSONObject(
  cipher: string,
  key: string
): { error: boolean; data?: any } {
  try {
    const transformed = cipher.replace(/ /g, '+');
    const jsonString = TripleDES.decrypt(transformed, key).toString(enc.Utf8);
    return {
      error: false,
      data: JSON.parse(jsonString),
    };
  } catch {
    return {
      error: true,
      data: undefined,
    };
  }
}
