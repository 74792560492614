import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';
import { generateURL } from '@shared/utils';

export interface LanguagesResponseDTO {
  _id: string;
  title: string;
  path: string;
  code: string;
  is_enabled: boolean;
}

export interface LanguagesRequestDTO {
  title: string;
  code: string;
}

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  public readonly dataStore = new BehaviorSubject<LanguagesResponseDTO[]>([]);

  constructor(private http: HttpClient) {}

  private fetchAll(): Observable<CommonResponseDTO<LanguagesResponseDTO[]>> {
    const url = generateURL({ endpoint: ENDPOINTS.SETUP_LANGUAGES_GET_ALL });
    return this.http.get<CommonResponseDTO<LanguagesResponseDTO[]>>(url).pipe(
      tap((res) => {
        this.dataStore.next(res.data);
      })
    );
  }

  getAllLanguages(forcedFetch = false): Promise<LanguagesRequestDTO[]> {
    return new Promise((resolve, reject) => {
      const downloadedLanguages = this.dataStore.value;

      if (forcedFetch || downloadedLanguages.length === 0) {
        firstValueFrom(this.fetchAll())
          .then((data) => {
            resolve(data.data);
          })
          .catch(reject);
      } else {
        resolve(downloadedLanguages);
      }
    });
  }

  getLanguageContentById(
    languageId: string
  ): Observable<CommonResponseDTO<string>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_LANGUAGES_GET_CONTENT_OF_ONE,
      params: { id: languageId },
    });
    return this.http.get<CommonResponseDTO<string>>(url);
  }

  createNewLanguage(
    body: LanguagesRequestDTO
  ): Observable<CommonResponseDTO<LanguagesResponseDTO>> {
    const url = generateURL({ endpoint: ENDPOINTS.SETUP_LANGUAGES_ADD_ONE });
    return this.http
      .post<CommonResponseDTO<LanguagesResponseDTO>>(url, body)
      .pipe(
        tap((res) => {
          const languages = this.dataStore.value;
          languages.push(res.data);
          this.dataStore.next(languages);
        })
      );
  }

  private updateLanguageInDataStore(newLanguage: LanguagesResponseDTO) {
    const languages = this.dataStore.value;
    const languageIndex = languages.findIndex(
      (language) => language._id === newLanguage._id
    );
    if (languageIndex > -1) languages[languageIndex] = newLanguage;

    this.dataStore.next(languages);
  }

  updateLanguageById(
    languageId: string,
    data: { code: string; data: any }
  ): Observable<CommonResponseDTO<LanguagesResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_LANGUAGES_UPDATE_ONE,
      params: { id: languageId },
    });
    const body = {
      translations: JSON.stringify(data.data),
    };

    return this.http
      .patch<CommonResponseDTO<LanguagesResponseDTO>>(url, body)
      .pipe(
        tap((res) => {
          this.updateLanguageInDataStore(res.data);
        })
      );
  }

  enableDisableLanguage(
    languageId: string,
    is_enabled: boolean
  ): Observable<CommonResponseDTO<LanguagesResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_LANGUAGES_ENABLE_DISABLE_ONE,
      params: { id: languageId },
    });
    return this.http
      .patch<CommonResponseDTO<LanguagesResponseDTO>>(url, { is_enabled })
      .pipe(
        tap((res) => {
          this.updateLanguageInDataStore(res.data);
        })
      );
  }

  deleteLanguage(
    languageId: string
  ): Observable<CommonResponseDTO<LanguagesResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_LANGUAGES_DELETE_ONE,
      params: { id: languageId },
    });
    return this.http.delete<CommonResponseDTO<LanguagesResponseDTO>>(url).pipe(
      tap((res) => {
        const newLanguages = this.dataStore.value.filter(
          (language) => language._id !== res.data._id
        );
        this.dataStore.next(newLanguages);
      })
    );
  }

  refreshLanguageFile(
    languageId: string
  ): Observable<CommonResponseDTO<LanguagesResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_LANGUAGES_REFRESH_ONE,
      params: { id: languageId },
    });
    return this.http.get<CommonResponseDTO<LanguagesResponseDTO>>(url);
  }
}
