import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../../../services/snackbar.service';
import { INotes } from '../../post-it-notes.component';
import { PostItNotesService } from '../../services/post-it-notes.service';

@Component({
  selector: 'app-archived-list',
  templateUrl: './archived-list.component.html',
  styleUrls: ['./archived-list.component.scss'],
})
export class ArchivedListComponent {
  noteChecked = false;

  constructor(
    private dialogRef: MatDialogRef<ArchivedListComponent>,
    private postItNotesService: PostItNotesService,
    private snackBar: SnackbarService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: INotes[]
  ) {}

  allComplete = false;

  updateAllComplete() {
    this.allComplete = this.data && this.data.every((note) => note.selected);
    if (this.data.filter((note) => note.selected).length > 0) {
      this.noteChecked = true;
    } else {
      this.noteChecked = false;
    }
  }

  someComplete(): boolean {
    if (!this.data) {
      return false;
    }
    return (
      this.data.filter((note) => note.selected).length > 0 && !this.allComplete
    );
  }

  setAll(selected: boolean) {
    this.allComplete = selected;
    if (!this.data) {
      return;
    }
    this.data.forEach((note) => (note.selected = selected));

    if (selected) {
      this.noteChecked = true;
    } else {
      this.noteChecked = false;
    }
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  onClickRestore() {
    const notesToRestore = this.data
      .filter((note) => {
        return note.selected === true;
      })
      .map((note) => note._id);

    const noteIDs: string[] = [];

    notesToRestore.forEach((noteID) => {
      noteIDs.push(noteID);
    });

    this.postItNotesService.restoreNotes({ ids: noteIDs }).subscribe({
      next: () => {
        this.dialogRef.close(notesToRestore);
        this.snackBar.success(
          SUCCESS_TYPES.RESTORED,
          this.translate.instant('pages.post-it-notes.note')
        );
      },
      error: () => {
        if (notesToRestore.length === 1) {
          this.snackBar.error(
            this.translate.instant(
              'pages.post-it-notes.archive-list.single-note-restore-error-snackbar'
            )
          );
        }
        if (notesToRestore.length > 1) {
          this.snackBar.error(
            this.translate.instant(
              'pages.post-it-notes.archive-list.multiple-notes-restore-error-snackbar'
            )
          );
        }
      },
    });
  }

  onClickDelete() {
    const notesToDelete = this.data
      .filter((note) => {
        return note.selected === true;
      })
      .map((note) => note._id);

    const noteIDs: string[] = [];

    notesToDelete.forEach((noteID) => {
      noteIDs.push(noteID);
    });

    this.postItNotesService.deleteNotes({ ids: noteIDs }).subscribe({
      next: () => {
        this.dialogRef.close();
        this.snackBar.success(
          SUCCESS_TYPES.DELETED,
          this.translate.instant('pages.post-it-notes.note')
        );
      },
      error: () => {
        if (notesToDelete.length === 1) {
          this.snackBar.error(
            this.translate.instant(
              'pages.post-it-notes.archive-list.single-note-delete-error-snackbar'
            )
          );
        }
        if (notesToDelete.length > 1) {
          this.snackBar.error(
            this.translate.instant(
              'pages.post-it-notes.archive-list.multiple--notes-delete-error-snackbar'
            )
          );
        }
      },
    });
  }
}
