export enum CONNECTED_APP_STATUS {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export const CONNECTED_APP_ACTIONS = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  VIEW_CONNECTIONS: 'VIEW_CONNECTIONS',
  VIEW_PERMISSIONS: 'VIEW_PERMISSIONS',
  VIEW_DOCUMENTATION: 'VIEW_DOCUMENTATION',
  CONNECT: 'CONNECT',
  DISCONNECT: 'DISCONNECT',
};

export const CONNECTED_APP_SPEED_DIAL = {
  ADD_NEW: 'ADD_NEW',
};
