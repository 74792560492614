<div></div>
<div class="row">
  <div class="col">
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>{{ title }}</mat-label>
      <input
        matInput
        [id]="inputFieldId"
        [attr.data-testid]="inputFieldId"
        [formControl]="displayValue"
        [placeholder]="placeholder"
        [required]="required"
        [readonly]="true"
        (click)="
          !(setBtnLoading || disabled || readonly) ? onSetClicked() : null
        "
      />
      <button
        type="button"
        [id]="btnId"
        [attr.data-testid]="btnId"
        mat-button
        matSuffix
        class="btn-adjust__post-icon"
        (click)="onSetClicked()"
        [matTooltip]="'configuration-fields.set' | translate"
        [disabled]="disabled || readonly || setBtnLoading"
        id="configuration-fields-set-btn"
      >
        <mat-spinner
          *ngIf="setBtnLoading"
          diameter="25"
          style="top: 6px"
        ></mat-spinner>
        <div *ngIf="!setBtnLoading">
          {{ 'configuration-fields.set' | translate }}
        </div>
      </button>
      <mat-error
        *ngIf="
          errorMessage &&
          !(
            displayValue.hasError('required') ||
            displayValue.hasError('whitespace')
          )
        "
        >{{ errorMessage }}</mat-error
      >
      <mat-error
        *ngIf="
          displayValue.hasError('required') ||
          displayValue.hasError('whitespace')
        "
        >{{ title }}&nbsp;is required</mat-error
      >
    </mat-form-field>
  </div>
</div>
