<mat-chip
  [matTooltip]="chipInput.value || placeholders[0] || ''"
  (click)="$event.stopPropagation()"
  (focus)="$event.stopPropagation()"
  class="mat-chip"
  [disabled]="loading"
  [removable]="
    (isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()) &&
    !loading
  "
  (removed)="remove(selectedData)"
  attr.data-testid="cis_autocomplete{{
    selectedData?.name?.toLowerCase()?.replace(' ', '_')
  }}"
>
  <label class="has-float-label">
    <input
      #chipInput
      [style.width.px]="
        getInputWidth(chipInput?.value?.toString()?.length ?? 0, label)
      "
      class="chip-input"
      (click)="$event.stopPropagation()"
      (focus)="$event.stopPropagation(); inputFocusOn = true"
      [formControl]="autoComplete"
      [matAutocomplete]="auto"
      (keydown)="$event.stopImmediatePropagation()"
      [placeholder]="placeholders[0]"
      (blur)="inputFocusOn = false"
      [readonly]="isChipChiningParams() || isChipLock()"
      type="text"
      attr.data-testid="cis_autocomplete{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}_input_value"
    />
    <div #label class="label">{{ selectedData?.name ?? '' | translate }}</div>
  </label>

  <mat-autocomplete
    attr.data-testid="cis_autocomplete{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_autocomplete"
    #auto="matAutocomplete"
    [panelWidth]="155"
  >
    <mat-option
      *ngFor="let option of (filteredOptions | async) ?? []"
      [value]="option.value"
      attr.data-testid="cis_autocomplete{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}_autocomplete_option_{{ option.key.toLowerCase().replace(' ', '_') }}"
    >
      {{ option.key }}
    </mat-option>
  </mat-autocomplete>

  <mat-icon
    matChipRemove
    *ngIf="
      isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()
    "
    style="vertical-align: text-top; cursor: pointer"
    attr.data-testid="cis_autocomplete{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_btn_cancel"
    >cancel</mat-icon
  >
  <mat-icon
    *ngIf="isChipMandatory()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_autocomplete{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_mandatory"
    >push_pin</mat-icon
  >
  <mat-icon
    *ngIf="isChipLock()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_autocomplete{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_locked"
    >lock</mat-icon
  >
</mat-chip>
