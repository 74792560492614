export enum REPEAT_TYPE {
  NEVER = 'Never',
  EVERY_DAY = 'Every Day',
  EVERY_WEEK = 'Every Week',
  EVERY_MONTH = 'Every Month',
  EVERY_YEAR = 'Every Year',
}

export enum EVENT_TYPE {
  REMINDER = 'REMINDER',
  TASK = 'TASK',
}
