import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import {
  CommonResponseDTO,
  IReminder,
  IReminderRequest,
  IReminderResponse,
  ITask,
  ITaskRequest,
} from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DiaryService {
  constructor(private http: HttpClient) {}

  addReminder(
    reminder: IReminderRequest
  ): Observable<CommonResponseDTO<IReminder>> {
    return this.http.post<CommonResponseDTO<IReminder>>(
      URLS.DIARY_REMINDER,
      reminder
    );
  }

  addTask(task: ITaskRequest): Observable<CommonResponseDTO<ITask>> {
    return this.http.post<CommonResponseDTO<ITask>>(URLS.DIARY_TASK, task);
  }

  getAllReminders(): Observable<CommonResponseDTO<IReminderResponse[]>> {
    return this.http.get<CommonResponseDTO<IReminderResponse[]>>(
      URLS.DIARY_REMINDER,
      {}
    );
  }

  getReminder(id: string): Observable<CommonResponseDTO<IReminderRequest>> {
    const url = `${URLS.DIARY_REMINDER}/${id}`;
    return this.http.get<CommonResponseDTO<IReminderRequest>>(url, {});
  }

  getAllTasks(): Observable<CommonResponseDTO<ITaskRequest[]>> {
    return this.http.get<CommonResponseDTO<ITaskRequest[]>>(
      URLS.DIARY_TASK,
      {}
    );
  }

  getTask(id: string): Observable<CommonResponseDTO<ITaskRequest>> {
    const url = `${URLS.DIARY_TASK}/${id}`;
    return this.http.get<CommonResponseDTO<ITaskRequest>>(url, {});
  }

  updateReminder(
    reminder: IReminderRequest,
    id: string
  ): Observable<CommonResponseDTO<IReminder>> {
    const url = `${URLS.DIARY_REMINDER}/${id}`;
    return this.http.put<CommonResponseDTO<IReminder>>(url, reminder);
  }

  updateTask(
    task: ITaskRequest,
    id: string
  ): Observable<CommonResponseDTO<ITaskRequest>> {
    const url = `${URLS.DIARY_TASK}/${id}`;
    return this.http.put<CommonResponseDTO<ITaskRequest>>(url, task);
  }

  deleteReminder(id: string) {
    const url = `${URLS.DIARY_REMINDER}/${id}`;
    return this.http.delete<CommonResponseDTO<IReminderRequest>>(url);
  }

  deleteTask(id: string) {
    const url = `${URLS.DIARY_TASK}/${id}`;
    return this.http.delete<CommonResponseDTO<ITaskRequest>>(url);
  }
}
