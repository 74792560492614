import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';

import { BaseChipComponent } from '../../base-chip/base-chip.component';
import { ChipInputSearchHelper } from '../../helpers/chip-input-search.helper';
import { NumberChipProperties } from '../../types/chip-properties/number';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-cis-number-chip-search-filter',
  templateUrl: './number-chip-search-filter.component.html',
  styleUrls: ['./number-chip-search-filter.component.scss'],
})
export class NumberChipSearchFilterComponent
  extends BaseChipComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('chipInput') chipInput?: ElementRef<HTMLInputElement>;
  number = new FormControl();

  get selectedDataChipProperties(): NumberChipProperties | undefined {
    return this.selectedData?.chipProperties as NumberChipProperties;
  }

  constructor(chipInputSearchHelper: ChipInputSearchHelper) {
    super(chipInputSearchHelper);
    this.number.valueChanges
      .pipe(startWith(''), debounceTime(200))
      .subscribe((chipValue: number | undefined) => {
        if (this.selectedDataChipProperties) {
          this.selectedDataChipProperties.value = chipValue;
        }
        this.editedData.emit(this.selectedData);
      });
  }

  ngOnInit(): void {
    this.number.setValue(this.selectedDataChipProperties?.value);
    this.minWidth = this.selectedData?.size || this.minWidth;
    this.number.valueChanges
      .pipe(debounceTime(50), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.formControl = this.number;
        this.value = this.number.value;
        if (this.selectedData?.validations) {
          super.validate_value();
        }
        super.resize(this.minWidth);
      });
    this.formControl = this.number;
    this.value = this.number.value;
    super.resize(this.minWidth);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.isChipLock()) {
        this.chipInput?.nativeElement.focus();
      }
    }, 10);
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
