<mat-chip
  [matTooltip]="textInput.value || placeholders[0] || ''"
  (click)="$event.stopPropagation()"
  (focus)="$event.stopPropagation()"
  [disabled]="loading"
  [removable]="
    (isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()) &&
    !loading
  "
  (removed)="remove(selectedData)"
>
  <label class="has-float-label">
    <input
      #textInput
      [style.width.px]="getInputWidth(textInput.value.toString().length, label)"
      class="chip-input"
      attr.data-testid="cis_text_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}"
      (click)="$event.stopPropagation()"
      (focus)="$event.stopPropagation()"
      [formControl]="longText"
      (keydown)="$event.stopImmediatePropagation()"
      [placeholder]="placeholders[0]"
      [readonly]="isChipChiningParams() || isChipLock()"
    />
    <div #label class="label">{{ selectedData?.name ?? '' | translate }}</div>
  </label>

  <mat-icon
    matChipRemove
    *ngIf="
      isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()
    "
    attr.data-testid="cis_text_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_btn_cancel"
    [style.cursor]="loading ? 'context-menu' : 'pointer'"
    >cancel
  </mat-icon>
  <mat-icon
    attr.data-testid="cis_text_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_mandatory"
    *ngIf="isChipMandatory()"
    style="cursor: default"
    >push_pin</mat-icon
  >
  <mat-icon
    attr.data-testid="cis_text_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_locked"
    *ngIf="isChipLock()"
    style="cursor: default"
    >lock</mat-icon
  >
</mat-chip>
