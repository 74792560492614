import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  IAMPasswordConfig,
  NumberOfPasswordPolicyFields,
  PasswordPolicyRegex,
} from './types';

const extractNumbersFromPolicy = (
  policy: IAMPasswordConfig
): NumberOfPasswordPolicyFields => {
  return {
    minLength: policy.length_check_enabled ? policy.min_length : 0,
    maxLength: policy.length_check_enabled ? policy.max_length : 0,
    numOfSpecialCharacters: policy.special_character_check_enabled
      ? policy.number_of_special_characters
      : 0,
    numberOfCapitalLetters: policy.uppercase_check_enabled
      ? policy.number_of_uppercase
      : 0,
    numOfSimpleLetters: policy.lowercase_check_enabled
      ? policy.number_of_lowercase
      : 0,
    numOfNumbers: policy.number_check_enabled ? policy.number_of_numbers : 0,
    numberOfExpiryDays: policy.expiry_period_enabled
      ? policy.number_of_expiry_days
      : 0,
    numberOf_historyChecks: policy.password_history_check_enabled
      ? policy.number_of_history_checks
      : 0,
  };
};

@Injectable({ providedIn: 'root' })
export class PasswordService {
  constructor(private translate: TranslateService) {}

  generateRandomPassword(
    passwordPolicy: IAMPasswordConfig,
    passRegex: PasswordPolicyRegex
  ): string {
    if (!passRegex.regExp) {
      return Math.random().toString(36).slice(-8);
    }
    const {
      maxLength,
      numOfSimpleLetters,
      numberOfCapitalLetters,
      numOfSpecialCharacters,
      numOfNumbers,
    } = extractNumbersFromPolicy(passwordPolicy);
    let generatedPwd = '';
    let upperChars = '';
    let lowerChars = '';
    let specialChars = '';
    let numberChars = '';
    let shufflePwd = [];

    const capitalLettersAll = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const simpleLettersAll = 'abcdefghijklmnopqrstuvwxyz';
    const specialCharactersAll = '?@#$%&!.,`*~":;<>+=[\\]()}{^|_/-';
    const numbersAll = '1234567890';
    const randomCharactersAll =
      capitalLettersAll + simpleLettersAll + specialCharactersAll + numbersAll;

    while (generatedPwd.length < maxLength) {
      while (upperChars.length < numberOfCapitalLetters) {
        upperChars += capitalLettersAll.charAt(
          Math.floor(Math.random() * capitalLettersAll.length)
        );
      }
      while (lowerChars.length < numOfSimpleLetters) {
        lowerChars += simpleLettersAll.charAt(
          Math.floor(Math.random() * simpleLettersAll.length)
        );
      }
      while (specialChars.length < numOfSpecialCharacters) {
        specialChars += specialCharactersAll.charAt(
          Math.floor(Math.random() * specialCharactersAll.length)
        );
      }
      while (numberChars.length < numOfNumbers) {
        numberChars += numbersAll.charAt(
          Math.floor(Math.random() * numbersAll.length)
        );
      }
      generatedPwd = generatedPwd.concat(
        upperChars,
        lowerChars,
        specialChars,
        numberChars
      );
      while (maxLength - generatedPwd.length > 0) {
        generatedPwd += randomCharactersAll.charAt(
          Math.floor(Math.random() * randomCharactersAll.length)
        );
      }
    }

    shufflePwd = generatedPwd.split('');
    const len = shufflePwd.length;

    for (let i = len - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const tmp = shufflePwd[i];
      shufflePwd[i] = shufflePwd[j];
      shufflePwd[j] = tmp;
    }
    generatedPwd = shufflePwd.join('');
    return generatedPwd;
  }

  async generatePasswordRegexByPolicy(
    passwordPolicy: IAMPasswordConfig
  ): Promise<PasswordPolicyRegex> {
    const {
      minLength,
      maxLength,
      numOfSimpleLetters,
      numberOfCapitalLetters,
      numOfSpecialCharacters,
      numOfNumbers,
    } = extractNumbersFromPolicy(passwordPolicy);

    return new Promise<PasswordPolicyRegex>((resolve) => {
      let regString = '';
      let validationErrorMessage = '';

      if (
        minLength === 0 &&
        maxLength === 0 &&
        numOfSimpleLetters === 0 &&
        numberOfCapitalLetters === 0 &&
        numOfSpecialCharacters === 0 &&
        numOfNumbers === 0
      ) {
        return resolve({
          regExp: null,
          validationErrorMessage: null,
        });
      }

      this.translate
        .get(
          [
            'setup.iam-config.password-policy.should-contain',
            'setup.iam-config.password-policy.lowercase.singular',
            'setup.iam-config.password-policy.lowercase.plural',
            'setup.iam-config.password-policy.uppercase.singular',
            'setup.iam-config.password-policy.uppercase.plural',
            'setup.iam-config.password-policy.special-chars.singular',
            'setup.iam-config.password-policy.special-chars.plural',
            'setup.iam-config.password-policy.numbers.singular',
            'setup.iam-config.password-policy.numbers.plural',
            'setup.iam-config.password-policy.same-characters',
            'setup.iam-config.password-policy.min-max-characters',
          ],
          {
            minLength,
            maxLength,
            numOfSimpleLetters,
            numberOfCapitalLetters,
            numOfSpecialChars: numOfSpecialCharacters,
            numOfNumbers,
          }
        )
        .subscribe((translations) => {
          validationErrorMessage +=
            translations['setup.iam-config.password-policy.should-contain'];

          if (numOfSimpleLetters === 1) {
            regString += `(?=(.*[a-z]){${numOfSimpleLetters}})`;
            validationErrorMessage += ` ${translations['setup.iam-config.password-policy.lowercase.singular']}`;
          }

          if (numOfSimpleLetters > 1) {
            regString += `(?=(.*[a-z]){${numOfSimpleLetters}})`;
            validationErrorMessage += ` ${translations['setup.iam-config.password-policy.lowercase.plural']}`;
          }

          if (numberOfCapitalLetters === 1) {
            regString += `(?=(.*[A-Z]){${numberOfCapitalLetters}})`;
            validationErrorMessage += `, ${translations['setup.iam-config.password-policy.uppercase.singular']}`;
          }

          if (numberOfCapitalLetters > 1) {
            regString += `(?=(.*[A-Z]){${numberOfCapitalLetters}})`;
            validationErrorMessage += `, ${translations['setup.iam-config.password-policy.uppercase.plural']}`;
          }

          if (numOfSpecialCharacters === 1) {
            regString += `(?=(.*[?@#$%&!.,*~":;<\`\\>+=[\\]()}'{^|_/\\\\-]){${numOfSpecialCharacters}})`;
            validationErrorMessage += `, ${translations['setup.iam-config.password-policy.special-chars.singular']}`;
          }

          if (numOfSpecialCharacters > 1) {
            regString += `(?=(.*[?@#$%&!.,*~":;<\`\\>+=[\\]()}'{^|_/\\\\-]){${numOfSpecialCharacters}})`;
            validationErrorMessage += `, ${translations['setup.iam-config.password-policy.special-chars.plural']}`;
          }

          if (numOfNumbers === 1) {
            regString += `(?=(.*[0-9]){${numOfNumbers}})`;
            validationErrorMessage += `, ${translations['setup.iam-config.password-policy.numbers.singular']}`;
          }

          if (numOfNumbers > 1) {
            regString += `(?=(.*[0-9]){${numOfNumbers}})`;
            validationErrorMessage += `, ${translations['setup.iam-config.password-policy.numbers.plural']}`;
          }

          if (minLength === 0 && maxLength === 0) {
            regString = `^` + regString + `$`;
            validationErrorMessage += `.`;
          }

          if (minLength === maxLength && minLength !== 0) {
            regString = `^` + regString + `.{${minLength},${maxLength}}$`;
            validationErrorMessage += `, ${translations['setup.iam-config.password-policy.same-characters']}.`;
          }

          if (minLength < maxLength) {
            regString = `^` + regString + `.{${minLength},${maxLength}}$`;
            validationErrorMessage += `, ${translations['setup.iam-config.password-policy.min-max-characters']}.`;
          }

          resolve({
            regExp: new RegExp(regString),
            validationErrorMessage,
          });
        });
    });
  }
}
