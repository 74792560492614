export enum FILE_TYPES {
  FOLDER = 'folder',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  ZIP = 'zip',
  JSON = 'json',
  PDF = 'pdf',
  DOCS = 'docs',
  EXCEL = 'excel',
  CSV = 'csv',
  EML = 'eml',
  PPT = 'ppt',
  BINARY = 'bin',
  TEXT = 'txt',
  RTF = 'rtf',
}

export enum MIME_TYPES {
  // images
  IMG_JPEG = 'image/jpeg',
  IMG_JPG = 'image/jpg',
  IMG_PNG = 'image/png',
  IMG_X_PNG = 'image/x-png',
  IMG_BMP = 'image/bmp',
  IMG_GIF = 'image/gif',
  IMG_ICO = 'image/vnd.microsoft.icon',
  IMG_SVG = 'image/svg+xml',
  IMG_TIFF = 'image/tiff',
  IMG_WEBP = 'image/webp',

  // video
  VIDEO_MP4 = 'video/mp4',
  VIDEO_3GP = 'video/3gpp',
  VIDEO_MKV = 'video/x-matroska',
  VIDEO_MOV = 'video/quicktime',
  VIDEO_WMV = 'video/x-ms-wmv',
  VIDEO_FLV = 'video/x-flv',
  VIDEO_AVI = 'video/avi',
  VIDEO_WEBM = 'video/webm',
  VIDEO_MPEG = 'video/mpeg',

  // audio
  AUDIO_MP3 = 'audio/mpeg',
  AUDIO_WAV = 'audio/wav',
  AUDIO_AIFF = 'audio/x-aiff',
  AUDIO_MP4 = 'audio/mp4',

  // compressed (application)
  COMPRESSED_ZIP = 'application/zip',
  COMPRESSED_BZIP = 'application/x-bzip',
  COMPRESSED_BZIP2 = 'application/x-bzip2',
  COMPRESSED_GZIP = 'application/gzip',
  COMPRESSED_RAR = 'application/vnd.rar',
  COMPRESSED_TAR = 'application/x-tar',
  COMPRESSED_7Z = 'application/x-7z-compressed',
  COMPRESSED_X_ZIP = 'application/x-zip-compressed',

  // word documents
  DOCS_1 = 'application/msword',
  DOCS_2 = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOCS_3 = 'application/doc',
  DOCS_4 = 'application/ms-doc',

  // slides
  POWERPOINT_1 = 'application/vnd.ms-powerpoint',
  POWERPOINT_2 = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',

  // spreadsheets
  EXCEL_1 = 'application/vnd.ms-excel',
  EXCEL_2 = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',

  // pdf
  PDF_APPLICATION = 'application/pdf',
  PDF_TEXT = 'text/pdf',

  // application misc
  APPLICATION_JSON = 'application/json',

  EMAIL = 'message/rfc822',

  FOLDER = 'folder',

  BINARY = 'application/octet-stream',

  TEXT = 'text/plain',
  RTF = 'text/rtf',
}

export enum EXTENSIONS {
  AAC = 'aac', // AAC audio
  ABW = 'abw', // AbiWord document
  ARC = 'arc', // Archive document (multiple files embedded)
  AVIF = 'avif', // AVIF image
  AIFF = 'aiff', // AIFF audio
  AVI = 'avi', // AVI: Audio Video Interleave
  FLV = 'flv', // FLV Video Type
  MKV = 'mkv', // MKV Video Type
  MOV = 'mov', // QuickTime movie
  WMV = 'wmv', // Windows Media Video
  AZW = 'azw', // Amazon Kindle eBook format
  BIN = 'bin', // Any kind of binary data	application/octet-stream
  BMP = 'bmp', // Windows OS/2 Bitmap Graphics	image/bmp
  BZ = 'bz', // BZip archive	application/x-bzip
  BZ2 = 'bz2', // BZip2 archive	application/x-bzip2
  CDA = 'cda', // CD audio	application/x-cdf
  CSH = 'csh', // C-Shell script	application/x-csh
  CSS = 'css', // Cascading Style Sheets (CSS)	text/css
  CSV = 'csv', // Comma-separated values (CSV)	text/csv
  DOC = 'doc', // Microsoft Word	application/msword
  DOCX = 'docx', // Microsoft Word (OpenXML)	application/vnd.openxmlformats-officedocument.wordprocessingml.document
  EOT = 'eot', //MS Embedded OpenType fonts	application/vnd.ms-fontobject
  EPUB = 'epub', // Electronic publication (EPUB)	application/epub+zip
  GZ = 'gz', // GZip Compressed Archive	application/gzip
  GIF = 'gif', // Graphics Interchange Format (GIF)	image/gif
  HTM = 'htm', // HyperText Markup Language (HTML)	text/html
  HTML = 'html', // HyperText Markup Language (HTML)	text/html
  ICO = 'ico', // Icon format	image/vnd.microsoft.icon
  ICS = 'ics', // iCalendar format	text/calendar
  JAR = 'jar', // Java Archive (JAR)	application/java-archive
  JPEG = 'jpeg', // JPEG images	image/jpeg
  JPG = 'jpg', // JPEG images	image/jpeg
  JS = 'js', // JavaScript	text/javascript (Specifications: HTML and RFC 9239)
  JSON = 'json', // JSON format	application/json
  JSONLD = 'jsonld', // JSON-LD format	application/ld+json
  MID = 'mid', // Musical Instrument Digital Interface (MIDI)	audio/midi, audio/x-midi
  MIDI = 'midi', // Musical Instrument Digital Interface (MIDI)	audio/midi, audio/x-midi
  MJS = 'mjs', // JavaScript module	text/javascript
  MP3 = 'mp3', // MP3 audio	audio/mpeg
  MP4 = 'mp4', // MP4 video	video/mp4
  MPEG = 'mpeg', // MPEG Video	video/mpeg
  MPKG = 'mpkg', // Apple Installer Package	application/vnd.apple.installer+xml
  OPD = 'odp', // OpenDocument presentation document	application/vnd.oasis.opendocument.presentation
  ODS = 'ods', // OpenDocument spreadsheet document	application/vnd.oasis.opendocument.spreadsheet
  ODT = 'odt', // OpenDocument text document	application/vnd.oasis.opendocument.text
  OGA = 'oga', // OGG audio	audio/ogg
  OGV = 'ogv', // OGG video	video/ogg
  OGX = 'ogx', // OGG	application/ogg
  OPUS = 'opus', // Opus audio	audio/opus
  OTF = 'otf', // OpenType font	font/otf
  PNG = 'png', // Portable Network Graphics	image/png
  PDF = 'pdf', // Adobe Portable Document Format (PDF)	application/pdf
  PHP = 'php', // Hypertext Preprocessor (Personal Home Page)	application/x-httpd-php
  PPT = 'ppt', // Microsoft PowerPoint	application/vnd.ms-powerpoint
  PPTX = 'pptx', // Microsoft PowerPoint (OpenXML)	application/vnd.openxmlformats-officedocument.presentationml.presentation
  RAR = 'rar', // RAR archive	application/vnd.rar
  RTF = 'rtf', // Rich Text Format (RTF)	application/rtf
  SH = 'sh', // Bourne shell script	application/x-sh
  SVG = 'svg', // Scalable Vector Graphics (SVG)	image/svg+xml
  TAR = 'tar', // Tape Archive (TAR)	application/x-tar
  TIF = 'tif', // Tagged Image File Format (TIFF)	image/tiff
  TIFF = 'tiff', // Tagged Image File Format (TIFF)	image/tiff
  TS = 'ts', // MPEG transport stream	video/mp2t
  TTF = 'ttf', // TrueType Font	font/ttf
  TXT = 'txt', // Text, (generally ASCII or ISO 8859-n)	text/plain
  VSD = 'vsd', // Microsoft Visio	application/vnd.visio
  WAV = 'wav', // Waveform Audio Format	audio/wav
  WEBA = 'weba', // WEBM audio	audio/webm
  WEBM = 'webm', // WEBM video	video/webm
  WEBP = 'webp', // WEBP image	image/webp
  WOFF = 'woff', // Web Open Font Format (WOFF)	font/woff
  WOFF2 = 'woff2', // Web Open Font Format (WOFF)	font/woff2
  XHTML = 'xhtml', // XHTML	application/xhtml+xml
  XLS = 'xls', // Microsoft Excel	application/vnd.ms-excel
  XLSX = 'xlsx', // Microsoft Excel (OpenXML)	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  XML = 'xml', // XML	application/xml is recommended as of RFC 7303 (section 4.1), but text/xml is still used sometimes. You can assign a specific MIME type to a file with .xml extension depending on how its contents are meant to be interpreted. For instance, an Atom feed is application/atom+xml, but application/xml serves as a valid default.
  XUL = 'xul', // XUL	application/vnd.mozilla.xul+xml
  ZIP = 'zip', // ZIP archive	application/zip
  BZIP = 'bzip', // BZip2 archive	application/x-bzip2
  BZIP2 = 'bzip2', // BZip2 archive	application/x-bzip2
  GZIP = 'gz', // GZip Compressed Archive	application/x-gzip
  _3GP = '3gp', // 3GPP audio/video container	video/3gpp; audio/3gpp if it doesn't contain video
  _3G2 = '3g2', // 3GPP2 audio/video container	video/3gpp2; audio/3gpp2 if it doesn't contain video
  _7Z = '7z', // 7-zip archive	application/x-7z-compressed
  UNKNOWN = '',
}
