import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import {
  ReleaseResponseDTO,
  ReleaseService,
} from '../../../../modules/setup/releases/services/release.service';

@Component({
  selector: 'app-view-release-note',
  templateUrl: './view-release-note.component.html',
  styleUrls: ['./view-release-note.component.scss'],
})
export class ViewReleaseNoteComponent implements OnInit, OnDestroy {
  releaseNote: string;
  releases: ReleaseResponseDTO[] = [];

  selectedVersion = new UntypedFormControl();
  subscription: Subscription;

  loading = false;
  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<ViewReleaseNoteComponent>,
    private releasesService: ReleaseService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.releasesService.getAllReleases().subscribe({
      next: (res) => {
        this.releases = res.data;
        if (res.data && res.data.length > 0) {
          this.selectedVersion.setValue(res.data[0]);
          this.releasesService.getSingleRelease(res.data?.[0]?._id).subscribe({
            next: (resSingle) => {
              this.loading = false;
              this.releaseNote = resSingle.data.change_log;
            },
            error: () => {
              this.loading = false;
            },
          });
        } else {
          this.loading = false;
          this.dialogRef.close();
        }
      },
      error: () => {
        this.loading = false;
      },
    });

    this.subscription.add(
      this.selectedVersion.valueChanges.subscribe((change) => {
        this.isLoading = true;
        this.releasesService
          .getSingleRelease(change?._id)
          .subscribe((resSingle) => {
            this.releaseNote = resSingle.data.change_log;
            this.isLoading = false;
          });
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
