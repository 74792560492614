<mat-card-title class="d-flex align-items-center">
  <mat-icon class="mr-2">notifications_active</mat-icon>
  {{ title }}
</mat-card-title>
<mat-card-subtitle class="popup-subtitle">{{ dateAndTime }}</mat-card-subtitle>

<mat-divider class="mb-3"></mat-divider>

<mat-dialog-content>
  <div class="body-style">
    <a [href]="link" target="_blank">{{ link }}</a>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button (click)="onClickClose()">
    {{ 'pages.notices.view-notice.close-btn' | translate }}
  </button>
</mat-dialog-actions>
