import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MonacoEditorConstructionOptions,
  MonacoEditorLoaderService,
  MonacoStandaloneCodeEditor,
} from '@materia-ui/ngx-monaco-editor';
import { filter, take } from 'rxjs/operators';

import { MONACO_SUPPORTED_LANGUAGES } from './monaco-editor.types';

export type IMarkerData = monaco.editor.IMarkerData;
export interface IMonacoEditorResponse {
  modelId: string;
  data: string;
}
export interface IMonacoEditorError {
  modelId: string;
  errors: IMarkerData[];
}

@Component({
  selector: 'app-monaco-editor',
  templateUrl: './monaco-editor.component.html',
  styleUrls: ['./monaco-editor.component.scss'],
})
export class MonacoEditorComponent implements OnInit, OnChanges {
  @ViewChild('editor') editor: MonacoStandaloneCodeEditor;
  @Input() language = MONACO_SUPPORTED_LANGUAGES.SCSS;
  @Input() code: string;
  @Input() size: 'large' | 'big' | 'small' | 'medium' = 'small';
  @Input() disabled = false;
  @Input() errors: IMarkerData[] = [];
  @Output() codeChange = new EventEmitter<IMonacoEditorResponse>();
  @Output() errorOutput = new EventEmitter<IMonacoEditorError>();
  @Output() init = new EventEmitter<string>();

  editorOptions: MonacoEditorConstructionOptions;
  private modelId: string;

  constructor(private monacoLoaderService: MonacoEditorLoaderService) {
    this.monacoLoaderService.isMonacoLoaded$
      .pipe(
        filter((isLoaded) => isLoaded),
        take(1)
      )
      .subscribe(() => {
        monaco.editor.defineTheme('myCustomTheme', {
          base: 'vs', // can also be vs or hc-black
          inherit: true, // can also be false to completely replace the builtin rules
          rules: [
            {
              token: 'comment',
              foreground: 'ffa500',
              fontStyle: 'italic underline',
            },
            { token: 'comment.js', foreground: '008800', fontStyle: 'bold' },
            { token: 'comment.css', foreground: '0000ff' }, // will inherit fontStyle from `comment` above
          ],
          colors: {},
        });
        monaco.editor.setModelMarkers = (_model, _owner, errors) => {
          this.errorOutput.emit({ modelId: _model.id, errors });
        };
      });
  }

  ngOnInit(): void {
    this.editorOptions = {
      theme: 'myCustomTheme',
      language: this.language,
      roundedSelection: true,
      autoIndent: 'full',
      readOnly: this.disabled,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.updateEditorOptions();
    }
  }

  editorInit(editor: MonacoStandaloneCodeEditor) {
    this.editor = editor;
    this.modelId = editor.getModel().id;
    this.init.emit(this.modelId);
  }

  onDirChange(event: string) {
    this.codeChange.emit({ modelId: this.modelId, data: event });
  }

  updateEditorOptions() {
    this.editorOptions = {
      ...this.editorOptions,
      readOnly: this.disabled,
    };
  }
}
