<div class="horizontal-center-container py-5">
  <div>
    <div class="d-flex justify-content-center">
      <lottie-player
        src="/assets/animations/no-results.json"
        background="transparent"
        speed="1"
        style="width: 200px; height: 150px"
        loop
        autoplay
        alt="no-results"
      ></lottie-player>
    </div>
    <div>
      <h6 class="title flex-center text-center">
        {{ title || 'shared.no-result-found.title' | translate }}
      </h6>
      <p *ngIf="!disableDescription" class="flex-center text-center">
        {{ description || 'shared.no-result-found.description' | translate }}
      </p>
    </div>
  </div>
</div>
