import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ELAutocompleteElement } from '@el-autocomplete';
import { Types } from 'mongoose';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import {
  ENDPOINTS,
  REFERENCE_FILTER_COMPARISON_OPERATORS,
  REFERENCE_FILTER_COMPARISON_TYPES,
  REFERENCE_MAPPINGS,
} from '@shared/constants';
import {
  CommonResponseDTO,
  IConfigurableFieldDataTypes,
  IReferenceFieldValueResponse,
  IReferenceMappingSetupRequest,
  IReferenceMappingSetupResponse,
  IReferenceResponse,
} from '@shared/interfaces';
import { asyncEvery, generateURL } from '@shared/utils';

import { ReferenceService } from '../../../references/services/reference.service';

type IReferenceMappingDataStoreElement = {
  [key: string]: IReferenceMappingSetupResponse;
};

type IReferencesAccordingToMapping = {
  [key: string]: ELAutocompleteElement[];
};

type OneReferenceMappingResponse =
  CommonResponseDTO<IReferenceMappingSetupResponse>;

@Injectable({ providedIn: 'root' })
export class ReferenceMappingService {
  private dataStore = new BehaviorSubject<IReferenceMappingDataStoreElement>(
    {}
  );

  private referencesAccordingToMappingId =
    new BehaviorSubject<IReferencesAccordingToMapping>({});

  constructor(
    private http: HttpClient,
    private referenceService: ReferenceService
  ) {}

  getMappingByKey(
    key: REFERENCE_MAPPINGS
  ): Promise<IReferenceMappingSetupResponse> {
    return new Promise((resolve, reject) => {
      const allStoredData = this.dataStore.value ?? {};
      const preLoadedMapping = allStoredData[key];

      if (preLoadedMapping) resolve(preLoadedMapping);

      const url = generateURL({
        endpoint: ENDPOINTS.SETUP_GET_REFERENCE_MAPPING_BY_KEY,
        params: { key },
      });
      firstValueFrom(this.http.get<OneReferenceMappingResponse>(url))
        .then((mapping) => {
          allStoredData[key] = mapping?.data;
          this.dataStore.next(allStoredData);

          resolve(mapping?.data);
        })
        .catch(reject);
    });
  }

  updateMapping(
    id: string,
    mapping: IReferenceMappingSetupRequest
  ): Promise<IReferenceMappingSetupResponse> {
    return new Promise((resolve, reject) => {
      const url = generateURL({
        endpoint: ENDPOINTS.SETUP_UPDATE_REFERENCE_MAPPING,
        params: { id },
      });
      firstValueFrom(this.http.patch<OneReferenceMappingResponse>(url, mapping))
        .then((updatedMapping) => {
          const allStoredData = this.dataStore.value ?? {};
          allStoredData[updatedMapping.data.key] = updatedMapping.data;
          this.dataStore.next(allStoredData);

          resolve(updatedMapping.data);
        })
        .catch(reject);
    });
  }

  async filterReferencesAccordingToMapping(
    mapping: IReferenceMappingSetupResponse,
    otherValue?: { [key: string]: IConfigurableFieldDataTypes }
  ): Promise<ELAutocompleteElement[]> {
    if (!mapping.category_id || !mapping.field_id) return [];

    const oldFiltered =
      this.referencesAccordingToMappingId.value[mapping._id.toString()];

    if (oldFiltered) return oldFiltered;

    const referencesToDropdowns = async (
      references: IReferenceResponse[]
    ): Promise<ELAutocompleteElement[]> => {
      if (!references.length) return [];

      const elements = await Promise.all(
        references.map(async (reference) => {
          const fieldValue =
            await this.referenceService.getFieldValueFromBackend({
              referenceId: reference._id.toString(),
              fieldId: mapping.field_id.toString(),
            });

          if (!fieldValue || typeof fieldValue.value !== 'string') return null;

          return {
            value: reference._id.toString(),
            displayValue: fieldValue.value.toString(),
            originalData: reference,
          };
        })
      );

      return elements.filter(Boolean);
    };

    const allReferencesUnderCategory =
      await this.referenceService.getReferences(
        mapping.category_id.toString(),
        'valid'
      );

    if (!mapping.filters.length)
      return referencesToDropdowns(allReferencesUnderCategory);

    const filteredReferences = [];

    for (const reference of allReferencesUnderCategory) {
      const everyOkay = await asyncEvery(mapping.filters, async (filter) => {
        const refField = reference.reference.find(
          (refField) => refField.field_id === filter.my_field_id
        );
        if (!refField) return false;

        let comparingOtherValue: Types.ObjectId | IConfigurableFieldDataTypes;
        switch (filter.compare_with) {
          case REFERENCE_FILTER_COMPARISON_TYPES.DIRECT: {
            comparingOtherValue = filter.compare_value;
            break;
          }
          case REFERENCE_FILTER_COMPARISON_TYPES.OTHER_FIELD: {
            comparingOtherValue = otherValue[filter.compare_value.toString()];
            break;
          }
        }
        if (!comparingOtherValue) return false;

        let refFieldValue: IReferenceFieldValueResponse;

        try {
          refFieldValue = await this.referenceService.getFieldValueFromBackend({
            referenceId: reference._id.toString(),
            fieldId: refField.field_id.toString(),
          });
        } catch {
          return false;
        }

        if (!refFieldValue) return false;

        switch (filter.comparison_operation) {
          case REFERENCE_FILTER_COMPARISON_OPERATORS.EQUAL: {
            return refFieldValue.value === comparingOtherValue;
          }
          case REFERENCE_FILTER_COMPARISON_OPERATORS.NOT_EQUAL: {
            return refFieldValue.value !== comparingOtherValue;
          }
          case REFERENCE_FILTER_COMPARISON_OPERATORS.GREATER_THEN: {
            return refFieldValue.value > comparingOtherValue;
          }
          case REFERENCE_FILTER_COMPARISON_OPERATORS.GREATER_THEN_OR_EQUAL: {
            return refFieldValue.value >= comparingOtherValue;
          }
          case REFERENCE_FILTER_COMPARISON_OPERATORS.LESS_THEN: {
            return refFieldValue.value < comparingOtherValue;
          }
          case REFERENCE_FILTER_COMPARISON_OPERATORS.LESS_THEN_OR_EQUAL: {
            return refFieldValue.value <= comparingOtherValue;
          }
          default: {
            return false;
          }
        }
      });

      if (everyOkay) filteredReferences.push(reference);
    }

    return referencesToDropdowns(filteredReferences);
  }
}
