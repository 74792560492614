import { cloneDeep } from 'lodash';

import { CONFIGURABLE_FIELD_CHECKBOX_TYPES } from '@shared/constants';
import { IConfigurableFieldConfigCheckBox } from '@shared/interfaces';

const validate = (
  checkboxes: IConfigurableFieldConfigCheckBox[],
  type: CONFIGURABLE_FIELD_CHECKBOX_TYPES
) => checkboxes.find((checkbox) => checkbox.type === type)?.isChecked || false;

export const isConstant = (
  checkboxes: IConfigurableFieldConfigCheckBox[] = []
) => validate(checkboxes, CONFIGURABLE_FIELD_CHECKBOX_TYPES.CONSTANT);

export const isRequired = (
  checkboxes: IConfigurableFieldConfigCheckBox[] = []
) => validate(checkboxes, CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED);

export const isUnique = (checkboxes: IConfigurableFieldConfigCheckBox[] = []) =>
  validate(checkboxes, CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE);

export const isSort = (checkboxes: IConfigurableFieldConfigCheckBox[] = []) =>
  validate(checkboxes, CONFIGURABLE_FIELD_CHECKBOX_TYPES.SORT);

export const isDefault = (
  checkboxes: IConfigurableFieldConfigCheckBox[] = []
) => {
  // since required fields are always default
  return (
    isRequired(checkboxes) ||
    validate(checkboxes, CONFIGURABLE_FIELD_CHECKBOX_TYPES.DEFAULT)
  );
};

export const setBackCheckboxes = (
  defaultCheckboxes: IConfigurableFieldConfigCheckBox[],
  checkboxes: IConfigurableFieldConfigCheckBox[] = []
): IConfigurableFieldConfigCheckBox[] => {
  return defaultCheckboxes.map((defaultCheckbox) => {
    const oldCheckbox = checkboxes.find(
      (checkbox) => checkbox?.key && checkbox?.key === defaultCheckbox?.key
    );

    if (!oldCheckbox) return cloneDeep(defaultCheckbox);

    return {
      ...defaultCheckbox,
      isChecked: oldCheckbox.isChecked,
    };
  });
};
