import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingOverlayService {
  private _fullScreenLoading = new BehaviorSubject<boolean>(false);
  public fullScreenLoading = this._fullScreenLoading.asObservable();

  private _pageContentLoading = new BehaviorSubject<boolean>(false);
  public pageContentLoading = this._pageContentLoading.asObservable();

  showFullScreenLoading() {
    this._fullScreenLoading.next(true);
  }

  hideFullScreenLoading() {
    this._fullScreenLoading.next(false);
  }

  showPageContentLoading() {
    this._pageContentLoading.next(true);
  }

  hidePageContentLoading() {
    this._pageContentLoading.next(false);
  }
}
