import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ELAutocompleteElement } from '@el-autocomplete';
import { isArray } from 'lodash';

import {
  ICityReference,
  IConfigurableFieldConfigResponse,
  IContactAddress,
  IContactAddressRequest,
  IReference,
  IReferenceMappingSetupResponse,
} from '@shared/interfaces';
import { getArrangedAddress, isRequired } from '@shared/utils';

import { noWhitespaceValidator } from '../../../core/helpers/form-field-white-space-validator';
import { AddressReferenceService } from '../../../references/services';

export type IAddressFormGroup = FormGroup<{
  is_primary: FormControl<boolean>;
  address: FormGroup<{
    line1: FormControl<string>;
    line2: FormControl<string>;
    line3: FormControl<string>;
    country: FormControl<string>;
    state: FormControl<string>;
    district: FormControl<string>;
    city: FormControl<string>;
    postal_code: FormControl<string>;
  }>;
}>;

interface ReferenceMappingData {
  countryListMappingData: IReferenceMappingSetupResponse;
  stateListMappingData: IReferenceMappingSetupResponse;
  districtListMappingData: IReferenceMappingSetupResponse;
  cityListMappingData: IReferenceMappingSetupResponse;
  postalCodeListMappingData: IReferenceMappingSetupResponse;
  countryListForDropDownData: ELAutocompleteElement[];
}

interface DialogData {
  contactData: IContactAddress[];
  fieldConfig: IConfigurableFieldConfigResponse;
  isRequired: boolean;
  referenceMappingData: ReferenceMappingData;
}

interface DropdownsListArray {
  statesListForDropdown: ELAutocompleteElement[];
  districtsListForDropdown: ELAutocompleteElement[];
  citiesListForDropdown: ELAutocompleteElement[];
  postalCodesListForDropdown: ELAutocompleteElement[];
}

@Component({
  selector: 'app-set-addresses',
  templateUrl: './set-addresses.popup.component.html',
  styleUrls: ['./set-addresses.popup.component.scss'],
})
export class SetAddressesPopupComponent implements OnInit {
  addresses: IAddressFormGroup[] = [];
  isRequired = false;

  countryListMapping: IReferenceMappingSetupResponse;
  countryListForDropDown: ELAutocompleteElement[] = [];
  stateListMapping: IReferenceMappingSetupResponse;
  districtListMapping: IReferenceMappingSetupResponse;
  cityListMapping: IReferenceMappingSetupResponse;
  postalCodeListMapping: IReferenceMappingSetupResponse;

  dropdownsList: DropdownsListArray[] = [];

  loadingStates = false;
  loadingDistricts = false;
  loadingCities = false;
  loadingPostalCodes = false;

  isPopupDirty = false;

  constructor(
    private dialogRef: MatDialogRef<SetAddressesPopupComponent>,
    private addressReferenceService: AddressReferenceService,
    @Inject(MAT_DIALOG_DATA) private field: DialogData
  ) {
    this.isRequired = field.isRequired;
  }

  private getFormControl(index: number, control: string) {
    return this.addresses[index]?.controls?.address?.get(
      control
    ) as FormControl<string>;
  }

  async ngOnInit(): Promise<void> {
    this.initReferenceMapping();

    if (this.field?.contactData && this.field.contactData?.length > 0) {
      this.field.contactData?.forEach((element, index) => {
        const stateForDropdown: ELAutocompleteElement[] = [
          {
            value: element.address.stateData?._id.toString(),
            displayValue: element.address.stateData?.name,
            originalData: element.address.stateData,
          },
        ];

        console.log(stateForDropdown);
        const districtForDropdown: ELAutocompleteElement[] = [
          {
            value: element.address.districtData._id.toString(),
            displayValue: element.address.districtData.name,
            originalData: element.address.districtData,
          },
        ];
        const cityForDropdown: ELAutocompleteElement[] = [
          {
            value: element.address.cityData._id.toString(),
            displayValue: element.address.cityData.name,
            originalData: element.address.cityData,
          },
        ];
        this.addresses.push(this.generateAddressFormGroup(element));
        this.dropdownsList.push({
          citiesListForDropdown: cityForDropdown,
          districtsListForDropdown: districtForDropdown,
          postalCodesListForDropdown: [],
          statesListForDropdown: stateForDropdown,
        });

        // To Set the values for dropdown elements
        this.getFormControl(index, 'country').setValue(
          element?.address?.country || ''
        );
        this.getFormControl(index, 'city').setValue(
          element?.address?.city || ''
        );
        this.getFormControl(index, 'state').setValue(
          element?.address?.state || ''
        );
        this.getFormControl(index, 'district').setValue(
          element?.address?.district || ''
        );
      });
    } else {
      this.addNewAddresses();
      this.addresses[0]?.get('is_primary')?.setValue(true);
    }
  }

  initReferenceMapping() {
    this.countryListMapping =
      this.field?.referenceMappingData.countryListMappingData;
    this.stateListMapping =
      this.field?.referenceMappingData.stateListMappingData;
    this.districtListMapping =
      this.field?.referenceMappingData.districtListMappingData;
    this.cityListMapping = this.field?.referenceMappingData.cityListMappingData;
    this.postalCodeListMapping =
      this.field?.referenceMappingData.postalCodeListMappingData;

    this.countryListForDropDown =
      this.field?.referenceMappingData.countryListForDropDownData;
  }

  addNewAddresses() {
    this.isPopupDirty = true;
    this.dropdownsList.push({
      statesListForDropdown: [],
      districtsListForDropdown: [],
      citiesListForDropdown: [],
      postalCodesListForDropdown: [],
    });
    this.addresses.push(this.generateAddressFormGroup());
    if (this.addresses.length === 1) {
      this.addresses[0].get('is_primary')?.setValue(true);
    }
  }

  private generateAddressFormGroup(address?: IContactAddress) {
    return new FormGroup({
      is_primary: new FormControl(!!address?.is_primary),
      address: new FormGroup({
        line1: new FormControl(address?.address?.line1, [
          Validators.required,
          noWhitespaceValidator,
        ]),
        line2: new FormControl(address?.address?.line2),
        line3: new FormControl(address?.address?.line3),
        country: new FormControl(address?.address?.country, [
          Validators.required,
        ]),
        state: new FormControl(address?.address?.state, [Validators.required]),
        district: new FormControl(address?.address?.district, [
          Validators.required,
        ]),
        city: new FormControl(address?.address?.city, [Validators.required]),
        postal_code: new FormControl(
          {
            value: address?.address?.postal_code,
            disabled: true,
          },
          [Validators.required]
        ),
      }),
    });
  }

  onDeleteClicked(index: number) {
    this.isPopupDirty = true;
    this.addresses.splice(index, 1);
    this.dropdownsList.splice(index, 1);

    const primaryIndex = this.addresses.findIndex(
      (address) => address.get('is_primary')?.value
    );

    if (primaryIndex === -1 || this.addresses.length === 1) {
      this.addresses[0]?.get('is_primary')?.setValue(true);
    }
  }

  radioChange(index: number) {
    this.isPopupDirty = true;
    if (!this.addresses[index]?.get('is_primary')?.value) {
      this.addresses.forEach((address, i) => {
        address?.get('is_primary')?.setValue(index === i);
      });
    }

    if (this.addresses.length === 1) {
      this.addresses[0].get('is_primary')?.setValue(true);
    }
  }

  onSaveClicked() {
    const addressRes: IContactAddressRequest[] = [];
    this.addresses.forEach((element) => {
      if (element?.valid) {
        addressRes.push({
          is_primary: element.controls.is_primary.value,
          address: {
            city: element.controls.address.controls.city.value,
            country: element.controls.address.controls.country.value,
            district: element.controls.address.controls.district.value,
            line1: element.controls.address.controls.line1.value,
            postal_code: element.controls.address.controls.postal_code.value,
            state: element.controls.address.controls.state.value,
            line2: element.controls.address.controls.line2.value,
            line3: element.controls.address.controls.line3.value,
          },
        });
      }
    });

    this.dialogRef.close(addressRes);
  }

  getCurrentAddressForHeaderSection(address: IAddressFormGroup, index: number) {
    const addressValue: IContactAddress = {
      ...address.value,
    } as IContactAddress;
    if (addressValue?.address?.city) {
      const countryDisplayValue = this.countryListForDropDown.find(
        (country) => country.value === addressValue.address.country
      )?.displayValue;
      addressValue.address.country =
        countryDisplayValue ?? addressValue.address.country;

      const stateDisplayValue = this.dropdownsList[
        index
      ].statesListForDropdown.find(
        (state) => state.value === addressValue.address.state
      )?.displayValue;
      addressValue.address.state =
        stateDisplayValue ?? addressValue.address.state;

      const districtDisplayValue = this.dropdownsList[
        index
      ].districtsListForDropdown.find(
        (district) => district.value === addressValue.address.district
      )?.displayValue;
      addressValue.address.district =
        districtDisplayValue ?? addressValue.address.district;

      const cityDisplayValue = this.dropdownsList[
        index
      ].citiesListForDropdown.find(
        (city) => city.value === addressValue.address.city
      )?.displayValue;
      addressValue.address.city = cityDisplayValue ?? addressValue.address.city;

      return getArrangedAddress(addressValue);
    }

    return null;
  }

  private clearAddressFields(index: number, control: string) {
    this.getFormControl(index, control)?.setValue(null);
    this.getFormControl(index, control)?.markAsUntouched();
    this.getFormControl(index, control)?.setErrors(null);
  }

  private setValueToAddressFields(
    index: number,
    control: string,
    value: string
  ) {
    this.getFormControl(index, control)?.setValue(value);
    this.getFormControl(index, control)?.markAsDirty();
    this.getFormControl(index, control)?.setErrors(null);
  }

  async updateCountryValues(
    e: ELAutocompleteElement | ELAutocompleteElement[],
    i: number
  ) {
    if (!e || isArray(e)) return;

    const currentValue = this.getFormControl(i, 'country')?.value;
    if (e.value !== currentValue) {
      this.loadingStates = true;

      this.setValueToAddressFields(i, 'country', e.value);
      this.clearAddressFields(i, 'state');
      this.clearAddressFields(i, 'district');
      this.clearAddressFields(i, 'city');
      this.clearAddressFields(i, 'postal_code');

      this.dropdownsList[i].statesListForDropdown =
        await this.addressReferenceService.getStatesToAddressPopup(e.value);

      this.dropdownsList[i].districtsListForDropdown = [];
      this.dropdownsList[i].citiesListForDropdown = [];
      this.dropdownsList[i].postalCodesListForDropdown = [];
      this.loadingStates = false;
    }
  }

  async updateStateValues(
    e: ELAutocompleteElement | ELAutocompleteElement[],
    i: number
  ) {
    if (!e || isArray(e)) return;

    const currentValue = this.getFormControl(i, 'state')?.value;
    if (e.value !== currentValue) {
      this.loadingStates = true;

      this.setValueToAddressFields(i, 'state', e.value);
      this.clearAddressFields(i, 'district');
      this.clearAddressFields(i, 'city');
      this.clearAddressFields(i, 'postal_code');

      this.dropdownsList[i].districtsListForDropdown =
        await this.addressReferenceService.getDistrictsToAddressPopup(e.value);
      this.dropdownsList[i].citiesListForDropdown = [];
      this.dropdownsList[i].postalCodesListForDropdown = [];
      this.loadingStates = false;
    }
  }

  async updateDistrictValues(
    e: ELAutocompleteElement | ELAutocompleteElement[],
    i: number
  ) {
    if (!e || isArray(e)) return;

    const currentValue = this.getFormControl(i, 'district')?.value;
    if (e.value !== currentValue) {
      this.loadingDistricts = true;

      this.setValueToAddressFields(i, 'district', e.value);
      this.clearAddressFields(i, 'city');
      this.clearAddressFields(i, 'postal_code');

      this.dropdownsList[i].citiesListForDropdown =
        await this.addressReferenceService.getCitiesToAddressPopup(e.value);
      this.dropdownsList[i].postalCodesListForDropdown = [];
      this.loadingDistricts = false;
    }
  }

  async updateCityValues(
    e: ELAutocompleteElement | ELAutocompleteElement[],
    i: number
  ) {
    if (!e || isArray(e)) return;

    const currentValue = this.getFormControl(i, 'city')?.value;
    if (e.value !== currentValue) {
      this.loadingCities = true;
      this.loadingPostalCodes = true;
      this.setValueToAddressFields(i, 'city', e.value);

      const postalCode = (e.originalData as IReference<ICityReference>)
        ?.postal_code;

      this.setValueToAddressFields(i, 'postal_code', postalCode.toString());

      this.loadingCities = false;
      this.loadingPostalCodes = false;
    }

    //When adding a drop-down to postal code. Follow above pattern
    if (this.getFormControl(i, 'city')?.value) {
      this.getFormControl(i, 'postal_code')?.enable();
    } else {
      this.getFormControl(i, 'postal_code')?.disable();
    }
  }

  isValid() {
    if (
      !isRequired(this.field?.fieldConfig?.checkboxes) &&
      this.addresses?.every((address) =>
        this.isManualFieldsPristine(address)
      ) &&
      !this.isPopupDirty
    ) {
      return false;
    }

    if (
      isRequired(this.field?.fieldConfig?.checkboxes) &&
      this.addresses?.every((address) =>
        this.isManualFieldsPristine(address)
      ) &&
      !this.isPopupDirty
    ) {
      return false;
    }

    return this.addresses.every((address) =>
      ['line1', 'country', 'state', 'district', 'city', 'postal_code'].every(
        (key) => {
          const control = address.controls.address.get(key);
          return (
            control.valid &&
            control.value &&
            (this.isPopupDirty ||
              !this.addresses?.every((address) =>
                this.isManualFieldsPristine(address)
              ))
          );
        }
      )
    );
  }

  private isManualFieldsPristine(group: IAddressFormGroup) {
    const keys = [
      'line1',
      'line2',
      'line3',
      'country',
      'state',
      'district',
      'city',
      'postal_code',
    ];

    return keys.every((key) => group.controls.address.get(key).pristine);
  }

  clearAll() {
    this.addresses = [];
    this.addNewAddresses();
    this.addresses[0]?.controls.is_primary?.setValue(true);
  }

  async updateDropdownOnFocus(entity: string, index: number) {
    if (entity === 'state') {
      this.loadingStates = true;
      this.dropdownsList[index].statesListForDropdown =
        await this.addressReferenceService.getStatesToAddressPopup(
          this.getFormControl(index, 'country').value
        );
      this.loadingStates = false;
    } else if (entity === 'district') {
      this.loadingDistricts = true;
      this.dropdownsList[index].districtsListForDropdown =
        await this.addressReferenceService.getDistrictsToAddressPopup(
          this.getFormControl(index, 'state').value
        );
      this.loadingDistricts = false;
    } else if (entity === 'city') {
      this.loadingCities = true;
      this.dropdownsList[index].citiesListForDropdown =
        await this.addressReferenceService.getCitiesToAddressPopup(
          this.getFormControl(index, 'district').value
        );
      this.loadingCities = false;
    }
  }
}
