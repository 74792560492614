<mat-expansion-panel [expanded]="expandAll">
  <mat-expansion-panel-header class="heading">
    <mat-panel-title class="heading__icon">
      <span [ngClass]="'heading__icon--' + data.method">{{
        data.method | uppercase
      }}</span>
    </mat-panel-title>
    <mat-panel-description class="heading__title">{{
      data.title
    }}</mat-panel-description>
  </mat-expansion-panel-header>
  <div class="content">
    <mat-divider></mat-divider>
    <code class="content__url mt-3 app-border">{{ data.url }} </code>
    <button
      mat-icon-button
      class="content__url__copy"
      (click)="onCopy(data.url)"
      [matTooltip]="'apps.create-new-app.connection.copy' | translate"
    >
      <mat-icon>content_copy</mat-icon>
    </button>

    <div
      [innerHTML]="data.description"
      style="margin-top: 5px; margin-bottom: 20px"
    ></div>

    <!-- Path Parameters section -->
    <div class="content__code-block" *ngIf="data?.pathParamInfo?.length > 0">
      <div class="content__code-block__title">Path Parameters</div>

      <mat-table
        #table
        [dataSource]="data.pathParamInfo"
        aria-label="modification-notes"
      >
        <ng-container matColumnDef="parameter">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Name</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{ element.param }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Description</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{
              element?.description || '-'
            }}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="pathParamsColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: pathParamsColumns"></mat-row>
      </mat-table>
    </div>

    <!-- Query parameters section -->
    <div class="content__code-block" *ngIf="data?.parameters?.length > 0">
      <div class="content__code-block__title">Query Parameters</div>

      <mat-table
        #table
        [dataSource]="data.parameters"
        aria-label="modification-notes"
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Name</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{ element.name }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Description</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{
              element?.description || '-'
            }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="schema-type">
          <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Type</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{
              element?.schema?.type || '-'
            }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="required">
          <mat-header-cell *matHeaderCellDef>Required</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Required</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">
              {{ element?.required ? 'Yes' : 'No' }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="example">
          <mat-header-cell *matHeaderCellDef>Example</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Example</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{ element?.example || '-' }}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="queryParamsColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: queryParamsColumns"></mat-row>
      </mat-table>
    </div>

    <!-- Headers section -->
    <div class="content__code-block" *ngIf="data?.headers?.length > 0">
      <div class="content__code-block__title">Headers</div>

      <mat-table
        #table
        [dataSource]="data.headers"
        aria-label="modification-notes"
      >
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Name</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{ element.key }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="value">
          <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Value</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{ element.value }}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="headerColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: headerColumns"></mat-row>
      </mat-table>
    </div>

    <!-- Request body section -->
    <div class="content__code-block" *ngIf="data.body">
      <div class="content__code-block__title">
        Request Body {{ requestBodyType }}
      </div>
      <div
        *ngIf="requestBodyType !== '(multipart/form-data)'"
        class="content__code-block__code app-border"
      >
        <el-prettyjson [jsonData]="data.body"></el-prettyjson>
        <button
          mat-icon-button
          class="content__code-block__copy"
          (click)="onCopy(data.body)"
          [matTooltip]="'apps.create-new-app.connection.copy' | translate"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </div>

      <mat-table
        *ngIf="requestBodyType === '(multipart/form-data)'"
        #table
        [dataSource]="multipartData"
        aria-label="modification-notes"
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Name</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{ element.name }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="format">
          <mat-header-cell *matHeaderCellDef>Format</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Format</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{ element.format }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Description</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{ element.description }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Type</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{ element.type }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="example">
          <mat-header-cell *matHeaderCellDef>Example</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Example</span
            ><span class="font-weight-bold">:</span>
            <span class="ml-3 column-value">{{ element.example }}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="multipartColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: multipartColumns"></mat-row>
      </mat-table>
    </div>

    <!-- Responses section -->
    <div class="content__code-block" *ngIf="data.responses">
      <div class="content__code-block__title">Responses</div>
      <mat-accordion multi>
        <mat-expansion-panel
          *ngFor="let response of data.responses"
          [disabled]="!response.body"
          [ngStyle]="{ 'padding-right': !response.body ? '5px' : '' }"
        >
          <mat-expansion-panel-header>
            <mat-panel-title
              class="response-code"
              [ngStyle]="{ color: response.statusCode < 400 ? 'green' : 'red' }"
              >{{ response.statusCode }}</mat-panel-title
            >
            <mat-panel-description
              class="response-title"
              [ngStyle]="{ color: response.statusCode < 400 ? 'green' : 'red' }"
              >{{
                response.description && response.description !== ''
                  ? response.description
                  : response.statusCode < 400
                  ? 'Successful'
                  : ''
              }}</mat-panel-description
            >
          </mat-expansion-panel-header>
          <div *ngIf="response.body">
            <mat-divider style="position: relative"></mat-divider>
            <br />
            <div class="content__code-block__code">
              &nbsp;&nbsp;
              <el-prettyjson [jsonData]="response.body"></el-prettyjson>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</mat-expansion-panel>
