import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';

import {
  ACCOUNT_STATES,
  AUTHORIZER_TYPES,
  ENDPOINTS,
  EXTERNAL_SYSTEMS,
  USER_TYPES,
} from '@shared/constants';
import {
  CommonResponseDTO,
  IConfigurableFieldValue,
  IConnectedAppResponse,
  IContactNumber,
  IIdentityResponse,
  IStorageEntityResponse,
  IUploadFileToDMS,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

import { IConnectionResponse } from '../../connections/services/connections.service';

export interface IConnectionField {
  field_id: string;
  value: any;
}

export interface UserInfoResponseDTO {
  _id: string;
  salutation: string;
  first_name: string;
  last_name?: string;
  email?: string;
  profile_image?: string;
  profile_image_url?: string;
  user_type: USER_TYPES;
  account_state?: ACCOUNT_STATES;
  created_on?: Date;
  internal_fields?: IConfigurableFieldValue[];
  connection?: IConnectionResponse<IConnectedAppResponse, IIdentityResponse>;
  last_login: Date;
  ip_address: string;
  username?: string;
  authorizer?: AUTHORIZER_TYPES;
  phone_number?: IContactNumber[];

  // aggregated
  all_connections: IConnectionResponse<
    IConnectedAppResponse,
    IIdentityResponse
  >[];
}

export interface UserProfilePicResponseDTO {
  img: any;
}

@Injectable({
  providedIn: 'root',
})
export class LoggedUserService {
  private readonly _dataStore = new BehaviorSubject<UserInfoResponseDTO>(null);
  private readonly _identityImpersonatingStore =
    new BehaviorSubject<IIdentityResponse>(null);

  public dataStore = this._dataStore.asObservable();
  public identityImpersonatingStore =
    this._identityImpersonatingStore.asObservable();

  constructor(private http: HttpClient) {}

  public impersonateIdentity(identity: IIdentityResponse) {
    this._identityImpersonatingStore.next(identity);
  }

  public updateUser(userDetails?: UserInfoResponseDTO): void {
    Sentry.setUser({
      email: userDetails.email,
      id: userDetails._id,
      segment: userDetails.user_type,
      username: userDetails.username,
    });

    this._dataStore.next({ ...userDetails });
  }

  public clearUser(): void {
    Sentry.setUser(null);
    this._dataStore.next(null);
    this._identityImpersonatingStore.next(null);
  }

  public getLatestUserProfile(): void {
    const url = generateURL({ endpoint: ENDPOINTS.AUTH_PROFILE });

    this.http
      .get<CommonResponseDTO<UserInfoResponseDTO>>(url)
      .subscribe(async (res) => {
        this.updateUser(res.data);
      });
  }

  public updateUserProfile(
    data: Partial<UserInfoResponseDTO>
  ): Observable<CommonResponseDTO<UserInfoResponseDTO>> {
    const url = generateURL({ endpoint: ENDPOINTS.AUTH_PROFILE });

    return this.http
      .patch<CommonResponseDTO<UserInfoResponseDTO>>(url, data)
      .pipe(tap((res) => this.updateUser(res.data)));
  }

  uploadProfileDataToDMS(
    entity: IUploadFileToDMS<File>,
    externalSystem?: EXTERNAL_SYSTEMS
  ): Observable<CommonResponseDTO<IStorageEntityResponse>> {
    const { file, ...rest } = entity;

    const createEntity = this.createEntity(rest, externalSystem);

    return createEntity.pipe(
      switchMap((res) => this.uploadToBucket(file, res.data.upload_url))
    );
  }

  private uploadToBucket(
    file: File,
    uploadUrl: string
  ): Observable<CommonResponseDTO<IStorageEntityResponse>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<CommonResponseDTO<IStorageEntityResponse>>(
      uploadUrl,
      formData
    );
  }

  private createEntity(
    info: Omit<IUploadFileToDMS<File>, 'file'>,
    externalSystem?: EXTERNAL_SYSTEMS
  ): Observable<CommonResponseDTO<IStorageEntityResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.AUTH_PROFILE_IMAGE_UPLOAD,
    });

    const body = {
      ...info,
      ...(externalSystem ? { external_system: externalSystem } : {}),
    };

    return this.http.post<CommonResponseDTO<IStorageEntityResponse>>(url, body);
  }
}
