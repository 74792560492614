export enum REQUEST_KEYS {
  GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS',
  UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION',
  CREATE_NEW_NOTIFICATION = 'CREATE_NEW_NOTIFICATION',
}

export enum RESPONSE_KEYS {
  COUNT = 'COUNT',
  ALL_NOTIFICATIONS = 'ALL_NOTIFICATIONS',
  STORAGE_CONFIG = 'STORAGE_CONFIG',
  IAM_CONFIG = 'IAM_CONFIG',
  MESSAGE_RESPONSES = 'MESSAGE_RESPONSES',
}

export enum VISIBILITY_TYPE {
  ALL = 'all',
  PERSONAL = 'personal',
}

// This implementation done to support mobile apps as well. Discuss with the mobile team before any changes
// NOTE: when you define a new type here you need to handle that type on following places
// apps/web/src/app/modules/notifications/pages/notification/notification.component.ts -> get_action_elements
// apps/web/src/app/modules/setup/notifications/services/notifications.service.ts -> openNotification
// libs/interfaces/src/lib/notifications/notification.ts -> INotificationAction
export enum NOTIFICATION_ACTION_TYPES {
  OPEN_URL = 'OPEN_URL',
  PREVIEW_RICH_TEXT = 'PREVIEW_RICH_TEXT',
  VIEW_MESSAGE = 'VIEW_MESSAGE',
  PREVIEW_DMS_ENTITY = 'PREVIEW_DMS_ENTITY',
  STORAGE_QUOTA_EXCEEDED = 'STORAGE_QUOTA_EXCEEDED',
  USER_QUOTA_EXCEEDED = 'USER_QUOTA_EXCEEDED',
}
