<h6 mat-dialog-title class="d-flex align-items-center mb-0">
  <mat-icon class="mr-2">archive</mat-icon>
  {{ 'pages.post-it-notes.archive-list.title' | translate }}
</h6>
<div class="popup-subtitle">
  {{ 'pages.post-it-notes.archive-list.subtitle' | translate }}
</div>
<mat-divider></mat-divider>

<ng-template #emptyArchive>
  <app-no-results-found></app-no-results-found>
</ng-template>

<mat-dialog-content
  style="height: 40vh"
  *ngIf="data.length > 0; else emptyArchive"
>
  <span>
    <mat-checkbox
      [checked]="allComplete"
      [indeterminate]="someComplete()"
      (change)="setAll($event.checked)"
    >
      <div class="p-2 select-all-text">
        {{ 'pages.post-it-notes.archive-list.select-all' | translate }}
      </div>
    </mat-checkbox>
  </span>
  <div>
    <div *ngFor="let note of data" class="d-flex align-items-center w-100 mb-1">
      <mat-checkbox
        [(ngModel)]="note.selected"
        (ngModelChange)="updateAllComplete()"
      ></mat-checkbox>
      <div
        class="d-flex justify-content-between align-items-center px-3 ml-3 single-item-style"
      >
        <div
          class="single-item-style_title"
          [style]="
            note.selected
              ? 'color: rgba(0, 0, 0, 1);'
              : 'color: rgba(0, 0, 0, 0.5);'
          "
        >
          {{ note.title }}
        </div>
        <div
          class="single-item-style_date"
          [style]="
            (note.selected
              ? 'color: rgba(0, 0, 0, 1);'
              : 'color: rgba(0, 0, 0, 0.5);') + 'font-size: 0.8rem;'
          "
        >
          {{ note.start_date }}
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flex-nowrap justify-content-end">
  <button mat-flat-button (click)="onClickCancel()">
    {{ 'pages.post-it-notes.archive-list.cancel-btn' | translate }}
  </button>
  <button
    mat-stroked-button
    [disabled]="!noteChecked"
    (click)="onClickRestore()"
  >
    {{ 'pages.post-it-notes.archive-list.restore-btn' | translate }}
  </button>
  <button
    mat-flat-button
    [disabled]="!noteChecked"
    color="primary"
    (click)="onClickDelete()"
  >
    {{ 'pages.post-it-notes.archive-list.delete-forever-btn' | translate }}
  </button>
</mat-dialog-actions>
