import { CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS } from '@shared/constants';

export const generateAdditionalFieldDropdownOptions = (
  method: CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS
) => {
  switch (method) {
    // DUMMY CONTENT
    case CONFIGURABLE_FIELD_DROPDOWN_OPTION_GENERATORS.LEADS_BRANCH:
      return [];
  }
};
