import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo-loader',
  templateUrl: './logo-loader.component.html',
  styleUrls: ['./logo-loader.component.scss'],
})
export class LogoLoaderComponent {
  @Input() logo: string;
}
