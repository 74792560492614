import { IGenericObject } from '@shared/interfaces';

export interface CsvToJson {
  success: boolean;
  data?: IGenericObject<string>[];
  error?: {
    message: string;
  };
}

export const csvToJSON = (csv: string, keysOrder: string[]): CsvToJson => {
  const lines = csv.split('\n');

  const result: IGenericObject<string>[] = [];
  const headersList = lines.shift().trim().replaceAll('"', '');
  if (headersList === keysOrder.join(',')) {
    const headers = headersList.split(',');

    for (const line of lines) {
      const obj: IGenericObject<string> = {};
      const currentLineValues = line.trim().split(',');

      if (headers.length <= currentLineValues.length) {
        const withoutQuotations = (value: string) => {
          const lastChatIndex = value.length - 1;
          if (value.charAt(0) === '"' && value.charAt(lastChatIndex) === '"') {
            return value.substring(1, lastChatIndex);
          } else {
            return value;
          }
        };

        headers.forEach((header, index) => {
          // Clear double quotations
          obj[header] = withoutQuotations(currentLineValues[index]);
        });

        if (headers.length < currentLineValues.length) {
          const lastIndex = keysOrder.length - 1;
          const lastKey = keysOrder[lastIndex];
          obj[lastKey] += `, ${currentLineValues
            .slice(headers.length)
            .join(', ')}`;

          // Clear double quotations
          obj[lastKey] = withoutQuotations(obj[lastKey]);
        }
        result.push(obj);
      }
    }

    return {
      success: true,
      data: result,
    };
  } else {
    return {
      success: false,
      error: {
        message: 'INVALID_KEYS_ORDER',
      },
    };
  }
};
