import { FormControl, ValidationErrors } from '@angular/forms';

import { environment } from '../../../../environments/environment';

export const noWhitespaceValidator = (control: FormControl) => {
  if (!control.value) return null;
  if (typeof control.value !== 'string') return null;

  const isWhitespace = control.value.trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
};

export const noAllowSelfDomainValidator = (
  control: FormControl
): ValidationErrors => {
  const selfDomain = environment.WEB_URL;
  if (control.value?.toString().includes(selfDomain)) {
    return { selfDomain: true };
  }
  return null;
};
