import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FlowControlService } from '../../../../../services/flow-control.service';
import { loadScript } from '../loadScript';

// TODO:@lathes Use 'namespace' instead of 'module' to declare custom TypeScript modules
// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace unlayer {
  function init(object: any);
  function createEditor(object: {
    id: string;
    features: {
      textEditor: {
        tables: boolean;
        spellChecker: boolean;
        cleanPaste: boolean;
      };
    };
    designTagsConfig: { delimiter: string[] };
    displayMode: string;
    source: { name: string; version: string };
    projectId?: number;
    tools?: object;
    appearance?: object;
    locale?: string;
    mergeTags?: object;
    designTags?: object;
  });
  function loadDesign(object: any);
  function saveDesign(Function: any);
  function exportHtml(Function: any);
  function setBodyValues(object: unknown);
}

export interface UnlayerOptions {
  projectId?: number;
  tools?: object;
  appearance?: object;
  locale?: string;
  mergeTags?: object;
  designTags?: object;
}

let lastEditorId = 0;

@Component({
  selector: 'app-pdf-editor',
  templateUrl: './pdf-editor.component.html',
  styleUrls: ['./pdf-editor.component.scss'],
})
export class PdfEditorComponent implements AfterViewInit {
  @Input() editorId: string;
  @Input() options: UnlayerOptions = {};
  @Input() projectId: number;
  @Input() tools: object;
  @Input() appearance: object;
  @Input() locale: string;
  @Input() mergeTags: string | object;
  @Input() minHeight = '500px';
  @Input() print: any;
  @Input() designTags: object;
  @Output() error_mergeTag = new EventEmitter();
  @Output() loaded = new EventEmitter();

  editor: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    flowControlService: FlowControlService
  ) {
    flowControlService.updateRouteInfo(
      this.router.url.split('?')[0],
      route.snapshot.data
    );
    this.editorId = `editor-${++lastEditorId}`;
  }

  ngAfterViewInit() {
    loadScript(this.loadEditor.bind(this));
  }

  protected loadEditor() {
    const options: UnlayerOptions = this.options || {};

    if (this.projectId) {
      options.projectId = this.projectId;
    }

    if (this.tools) {
      options.tools = this.tools;
    }

    if (this.appearance) {
      options.appearance = this.appearance;
    }

    if (this.locale) {
      options.locale = this.locale;
    }
    if (this.mergeTags) {
      try {
        options.mergeTags = JSON.parse(this.mergeTags.toString());
      } catch {
        options.mergeTags = {};
        this.error_mergeTag.emit();
      }
    }
    if (this.designTags) {
      options.designTags = this.designTags;
    }
    this.editor = unlayer.createEditor({
      ...options,
      id: this.editorId,
      features: {
        textEditor: {
          tables: true,
          spellChecker: true,
          cleanPaste: false,
        },
      },
      designTagsConfig: {
        delimiter: ['[[', ']]'],
      },
      displayMode: 'email',
      source: {
        name: 'angular-pdf-editor',
        version: '0.1.1',
      },
    });
    // TODO:@lathes use the default font family
    this.editor.setBodyValues({
      contentWidth: '100%',
      fontFamily: {
        label: 'Helvetica',
        value: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      },
    });

    this.loaded.emit({});
  }

  public loadDesign(data: object) {
    this.editor.loadDesign(data);
  }

  public saveDesign(cb: (data: object) => void) {
    this.editor.saveDesign(cb);
  }

  public exportHtml(cb: (data: object) => void) {
    this.editor.exportHtml(cb);
  }
}
