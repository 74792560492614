import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';
import { generateURL } from '@shared/utils';

export interface CurrenciesResponseDTO {
  _id: string;
  is_deleted: boolean;
  name: string;
  code: string;
  country: string;
  is_base_currency: boolean;
}

export interface CurrenciesRequestDTO {
  name: string;
  code: string;
  country: string;
}

@Injectable({
  providedIn: 'root',
})
export class CurrenciesService {
  public readonly dataStore = new BehaviorSubject<CurrenciesResponseDTO[]>(
    null
  );
  private _baseCurrency = new BehaviorSubject<CurrenciesResponseDTO>(undefined);

  constructor(private http: HttpClient) {}

  get baseCurrency() {
    return this._baseCurrency.getValue();
  }

  getAllCurrencies(): Observable<CommonResponseDTO<CurrenciesResponseDTO[]>> {
    const url = generateURL({ endpoint: ENDPOINTS.SETUP_CURRENCIES_GET_ALL });
    return this.http.get<CommonResponseDTO<CurrenciesResponseDTO[]>>(url).pipe(
      tap((res) => {
        this.dataStore.next(res.data);
      })
    );
  }

  getBaseCurrency(): Observable<CommonResponseDTO<CurrenciesResponseDTO[]>> {
    const url = generateURL({ endpoint: ENDPOINTS.SETUP_CURRENCIES_GET_ALL });
    return this.http
      .get<CommonResponseDTO<CurrenciesResponseDTO[]>>(url, {
        params: { is_base_currency: true },
      })
      .pipe(
        tap((res) => {
          this._baseCurrency.next(res.data[0]);
        })
      );
  }

  getCurrencyById(
    currencyId: string
  ): Observable<CommonResponseDTO<CurrenciesResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_CURRENCIES_GET_ONE,
      params: { id: currencyId },
    });
    return this.http.get<CommonResponseDTO<CurrenciesResponseDTO>>(url);
  }

  addCurrency(
    currencyData: CurrenciesRequestDTO
  ): Observable<CommonResponseDTO<CurrenciesResponseDTO>> {
    const url = generateURL({ endpoint: ENDPOINTS.SETUP_CURRENCIES_ADD_ONE });
    return this.http
      .post<CommonResponseDTO<CurrenciesResponseDTO>>(url, currencyData)
      .pipe(
        tap((res) => {
          const currencies = this.dataStore.value;
          currencies.push(res.data);
          this.dataStore.next(currencies);
        })
      );
  }

  toggleBaseCurrency(
    currencyId: string
  ): Observable<CommonResponseDTO<CurrenciesResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_CURRENCIES_TOGGLE_BASE_CURRENCY,
      params: { id: currencyId },
    });
    return this.http
      .patch<CommonResponseDTO<CurrenciesResponseDTO>>(url, {})
      .pipe(
        tap((res) => {
          this.updateCurrencyInDataStore(res.data, true);
        })
      );
  }

  editCurrency(
    currencyDataId: string,
    currencyData: CurrenciesResponseDTO
  ): Observable<CommonResponseDTO<CurrenciesResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_CURRENCIES_UPDATE_ONE,
      params: { id: currencyDataId },
    });
    return this.http
      .put<CommonResponseDTO<CurrenciesResponseDTO>>(url, currencyData)
      .pipe(
        tap((res) => {
          this.updateCurrencyInDataStore(res.data, false);
        })
      );
  }

  deleteCurrency(
    currencyId: string
  ): Observable<CommonResponseDTO<CurrenciesResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_CURRENCIES_DELETE_ONE,
      params: { id: currencyId },
    });
    return this.http
      .patch<CommonResponseDTO<CurrenciesResponseDTO>>(url, {})
      .pipe(
        tap((res) => {
          this.updateCurrencyInDataStore(res.data, false);
        })
      );
  }

  restoreCurrency(
    currencyId: string
  ): Observable<CommonResponseDTO<CurrenciesResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_CURRENCIES_RESTORE_ONE,
      params: { id: currencyId },
    });
    return this.http
      .patch<CommonResponseDTO<CurrenciesResponseDTO>>(url, {})
      .pipe(
        tap((res) => {
          this.updateCurrencyInDataStore(res.data, false);
        })
      );
  }

  private updateCurrencyInDataStore(
    newCurrency: CurrenciesResponseDTO,
    isToggleBaseCurrency: boolean
  ) {
    const currencies = this.dataStore.value;

    if (isToggleBaseCurrency) {
      const previousBaseCurrencyIndex = currencies.findIndex(
        (currency) => currency.is_base_currency === true
      );
      if (previousBaseCurrencyIndex > -1)
        currencies[previousBaseCurrencyIndex].is_base_currency = false;
    }

    const currencyIndex = currencies.findIndex(
      (currency) => currency._id === newCurrency._id
    );
    if (currencyIndex > -1) currencies[currencyIndex] = newCurrency;

    this.dataStore.next(currencies);
  }
}
