import { IKeyValuePair } from '@shared/interfaces';

export const enumToKeyValuePairs = (
  enumToConvert: { [key: string]: string | number },
  keyTransformation?: (originalValue: string) => string
): IKeyValuePair<string>[] => {
  return Object.entries(enumToConvert).map(([key, value]) => {
    let stringKey = key;

    if (keyTransformation) stringKey = keyTransformation(stringKey);

    return {
      key: stringKey,
      value: value.toString(),
    };
  });
};
