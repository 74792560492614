import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ElAutocompleteModule } from '@el-autocomplete';
// EL libraries
import { ELChipInputSearchModule } from '@el-chip-input-search';
import { TranslateModule } from '@ngx-translate/core';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

import { MaterialModule } from '../../material.module';

// components
import { ChooseALanguageBottomSheetComponent } from './components/choose-a-language-bottom-sheet/choose-a-language-bottom-sheet.component';
import { DarkModeComponent } from './components/dark-mode/dark-mode.component';
import { FooterComponent } from './components/footer/footer.component';
import { KeyboardShortcutsComponent } from './components/keyboard-shortcuts/keyboard-shortcuts.component';
import { KeyboardShortcutsListComponent } from './components/keyboard-shortcuts/popups/keyboard-shortcuts-list/keyboard-shortcuts-list.component';
import { ServerErrorOverlayComponent } from './components/server-error-overlay/server-error-overlay.component';
// directives
import { AutofocusDirective } from './directives/auto-focus.directive';
import { ErrorInterceptor } from './interceptors/error.interceptor';
// interceptors
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { TunnellingInterceptor } from './interceptors/tunnelling.interceptor';
// popups
import { OfflineComponent } from './popups/offline/offline.component';
import { ReleaseCheckComponent } from './popups/release-check/release-check.component';

const popups = [OfflineComponent, ReleaseCheckComponent];

const components = [
  ChooseALanguageBottomSheetComponent,
  DarkModeComponent,
  KeyboardShortcutsComponent,
  FooterComponent,
  ServerErrorOverlayComponent,
  KeyboardShortcutsListComponent,
];

const directives = [AutofocusDirective];

const elModules = [ELChipInputSearchModule, ElAutocompleteModule];

@NgModule({
  declarations: [...popups, ...components, ...directives],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    KeyboardShortcutsModule.forRoot(),
    ...elModules,
  ],
  exports: [...components, ...popups, ...directives, ...elModules],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TunnellingInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. You should only import Core modules in the AppModule only.'
      );
    }
  }
}
