<mat-chip
  [matTooltip]="
    startDate?.value
      ? startDate.value + ' - ' + endDate.value
      : (selectedData?.name ?? '' | translate) + ' ' + labelSuffix
  "
  (click)="$event.stopPropagation()"
  (focus)="$event.stopPropagation()"
  [disabled]="loading"
  [removable]="
    (isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()) &&
    !loading
  "
  (removed)="remove(selectedData)"
  attr.data-testid="cis_date_range_{{
    selectedData?.name?.toLowerCase()?.replace(' ', '_')
  }}"
>
  <mat-datepicker-toggle
    #toggle
    class="prefix-icon"
    [disabled]="isChipLock() || loading"
    [disableRipple]="true"
    [for]="picker"
    attr.data-testid="cis_date_range_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_toggle"
  ></mat-datepicker-toggle>
  <label
    class="range-float-label"
    [ngClass]="{ 'label-after-value': startDate.value }"
  >
    <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
      <input
        matStartDate
        #startDate
        class="chip-input"
        formControlName="start"
        (click)="picker.open(); $event.stopPropagation()"
        (keydown)="$event.stopImmediatePropagation()"
        readonly
        attr.data-testid="cis_date_range_{{
          selectedData?.name?.toLowerCase()?.replace(' ', '_')
        }}_input_start_date"
      />

      <input
        matEndDate
        #endDate
        class="chip-input"
        formControlName="end"
        (click)="picker.open(); $event.stopPropagation()"
        (keydown)="$event.stopImmediatePropagation()"
        [placeholder]="placeholders[1]"
        readonly
        attr.data-testid="cis_date_range_{{
          selectedData?.name?.toLowerCase()?.replace(' ', '_')
        }}_input_end_date"
      />
    </mat-date-range-input>
    <div
      #label
      class="label"
      [ngClass]="startDate.value || endDate.value ? 'selected' : ''"
      attr.data-testid="cis_date_range_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}_label"
    >
      {{ selectedData?.name ?? '' | translate }}&nbsp;{{ labelSuffix }}
    </div>
  </label>

  <mat-date-range-picker
    attr.data-testid="cis_date_range_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_date_range_picker"
    #picker
  ></mat-date-range-picker>

  <mat-icon
    matChipRemove
    *ngIf="
      isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()
    "
    [style.cursor]="loading ? 'context-menu' : 'pointer'"
    attr.data-testid="cis_date_range_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_btn_cancel"
    >cancel
  </mat-icon>
  <mat-icon
    attr.data-testid="cis_date_range_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_mandatory"
    *ngIf="isChipMandatory()"
    style="cursor: default"
    >push_pin</mat-icon
  >
  <mat-icon
    attr.data-testid="cis_date_range_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_locked"
    *ngIf="isChipLock()"
    style="cursor: default"
    >lock</mat-icon
  >
</mat-chip>
