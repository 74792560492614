import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import {
  CommonResponseDTO,
  IApiKey,
  IDeletedApiKey,
  IDeletedClientSecret,
  IPublicKeyResponse,
  ISecurityKey,
} from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SecurityKeysService {
  constructor(private http: HttpClient) {}

  generatePublicApiKey(): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = `${URLS.SECURITY_BASE_URL}/keys/generate`;
    return this.http.get<CommonResponseDTO<IPublicKeyResponse>>(url);
  }
  generatePublicClientCredential(): Observable<
    CommonResponseDTO<IPublicKeyResponse>
  > {
    const url = `${URLS.SECURITY_BASE_URL}/keys/generate-client-credentials`;
    return this.http.get<CommonResponseDTO<IPublicKeyResponse>>(url);
  }

  saveGeneratedPublicApiKey({
    id,
    key,
    secret,
    reference,
    origins,
    remarks,
  }: {
    id?: string;
    key?: string;
    secret?: string;
    reference: string;
    origins: string[];
    remarks: string;
  }): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = `${URLS.SECURITY_BASE_URL}/keys/${id}`;
    return this.http.post<CommonResponseDTO<IPublicKeyResponse>>(url, {
      apiKey: key,
      apiSecret: secret,
      reference,
      origins,
      remarks,
    });
  }

  saveDeletedApiKey(
    key: string,
    referer: string
  ): Observable<CommonResponseDTO<IDeletedApiKey>> {
    const url = `${URLS.SECURITY_BASE_URL}/keys/save-deleted-api-key`;
    return this.http.post<CommonResponseDTO<IDeletedApiKey>>(url, {
      key,
      referer,
    });
  }

  saveDeletedClientSecret(
    secret: string,
    referer: string
  ): Observable<CommonResponseDTO<IDeletedClientSecret>> {
    const url = `${URLS.SECURITY_BASE_URL}/keys/save-deleted-client-secret`;
    return this.http.post<CommonResponseDTO<IDeletedClientSecret>>(url, {
      secret,
      referer,
    });
  }

  updatePublicApiKeyOrSecret({
    id,
    key,
    secret,
    origins,
    remarks,
  }: {
    id: string;
    key?: string;
    secret?: string;
    origins: string[];
    remarks: string;
  }): Observable<CommonResponseDTO<IApiKey>> {
    const url = `${URLS.SECURITY_BASE_URL}/keys/${id}`;
    return this.http.patch<CommonResponseDTO<IApiKey>>(url, {
      key,
      secret,
      origins,
      remarks,
    });
  }

  updatePublicWebhook({
    id,
    apiKey,
  }: {
    id: string;
    apiKey: string;
  }): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = `${URLS.SECURITY_BASE_URL}/keys/public-webhook/${id}`;
    return this.http.patch<CommonResponseDTO<IPublicKeyResponse>>(url, {
      apiKey,
    });
  }

  getGeneratedKey(
    id: string
  ): Observable<CommonResponseDTO<IPublicKeyResponse>> {
    const url = `${URLS.SECURITY_BASE_URL}/keys/${id}`;
    return this.http.get<CommonResponseDTO<IPublicKeyResponse>>(url);
  }

  getGeneratedKeys(): Observable<CommonResponseDTO<IApiKey[]>> {
    const url = `${URLS.SECURITY_BASE_URL}/keys`;
    return this.http.get<CommonResponseDTO<IApiKey[]>>(url);
  }

  getWebhooks(): Observable<CommonResponseDTO<ISecurityKey[]>> {
    const url = `${URLS.SECURITY_BASE_URL}/keys/public-webhooks`;
    return this.http.get<CommonResponseDTO<ISecurityKey[]>>(url);
  }

  deleteKey(id: string): Observable<CommonResponseDTO<IApiKey>> {
    const url = `${URLS.SECURITY_BASE_URL}/keys/${id}`;
    return this.http.delete<CommonResponseDTO<IApiKey>>(url);
  }

  restoreKey(id: string): Observable<CommonResponseDTO<IApiKey>> {
    const url = `${URLS.SECURITY_BASE_URL}/keys/${id}/restore`;
    return this.http.patch<CommonResponseDTO<IApiKey>>(url, {});
  }
}
