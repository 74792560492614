import { capitalize } from 'lodash';

import {
  CONFIGURABLE_FIELD_CHECKBOX_TYPES,
  CONFIGURABLE_FIELD_DATA_TYPES,
  PREDEFINED_REFERENCE_CATEGORIES,
  REFERENCE_CATEGORY_TYPES,
  SYSTEM_GENERATED_REFERENCE_SECTIONS,
} from '@shared/constants';
import {
  IConfigurableFieldConfig,
  IConfigurableFieldConfigCheckBox,
  IReferenceCategory,
} from '@shared/interfaces';

export const getPredefinedReferenceCategoryNames = () =>
  Object.values(PREDEFINED_REFERENCE_CATEGORIES).map(String);

export const getPredefinedReferenceCategories = () =>
  getPredefinedReferenceCategoryNames().map((predefinedCategoryType) => {
    const categoryName = capitalize(
      predefinedCategoryType.replaceAll('_', ' ')
    );

    return {
      _id: predefinedCategoryType,
      name: predefinedCategoryType,
      type: REFERENCE_CATEGORY_TYPES.INTERNAL,
      section: SYSTEM_GENERATED_REFERENCE_SECTIONS.PREDEFINED,
      fields: getPredefinedReferenceCategoryFields(predefinedCategoryType),
      description: `Pre-defined ${categoryName} reference category`,
      is_deleted: false,
      non_editable: true,
      helpAndSupport: [],
      is_public: false,
    } as IReferenceCategory;
  });

export const getPredefinedReferenceCategoryFields = (
  categoryName: string
): IConfigurableFieldConfig[] => {
  const checkboxes: IConfigurableFieldConfigCheckBox[] = [
    {
      key: 'Default',
      isChecked: true,
      type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.DEFAULT,
    },
    {
      key: 'Required',
      isChecked: true,
      type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
    },
  ];
  const uniqueCheckbox = {
    key: 'Unique',
    isChecked: true,
    type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
  };

  const commonFields: IConfigurableFieldConfig[] = [
    {
      _id: 'name',
      name: 'name',
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      checkboxes: [...checkboxes],
    },
    {
      _id: 'code',
      name: 'code',
      type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      checkboxes: [...checkboxes, uniqueCheckbox],
    },
  ];

  switch (categoryName) {
    case PREDEFINED_REFERENCE_CATEGORIES.COUNTRIES: {
      return [...commonFields];
    }
    case PREDEFINED_REFERENCE_CATEGORIES.STATES: {
      return [
        ...commonFields,
        {
          _id: 'country',
          name: 'country',
          type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
          checkboxes: [...checkboxes],
        },
      ];
    }
    case PREDEFINED_REFERENCE_CATEGORIES.DISTRICTS: {
      return [
        ...commonFields,
        {
          _id: 'state',
          name: 'state',
          type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
          checkboxes: [...checkboxes],
        },
      ];
    }
    case PREDEFINED_REFERENCE_CATEGORIES.CITIES: {
      return [
        ...commonFields,
        {
          _id: 'district',
          name: 'district',
          type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
          checkboxes: [...checkboxes],
        },
        {
          _id: 'postal_code',
          name: 'postal_code',
          type: CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
          checkboxes: [...checkboxes, uniqueCheckbox],
        },
      ];
    }
    default: {
      return [];
    }
  }
};
