<h6 mat-dialog-title class="d-flex align-items-center mb-0">
  <mat-icon class="mr-2">collections_bookmark</mat-icon>
  {{ 'pages.diary.root.title' | translate }}
</h6>
<div class="popup-subtitle mb-1">
  {{ 'pages.diary.popups.subtitle' | translate }}
</div>

<mat-divider></mat-divider>

<mat-dialog-content style="height: 60vh">
  <mat-button-toggle-group
    class="mt-3"
    style="height: 35px"
    [value]="!isEdit ? 'reminder' : isReminder ? 'reminder' : 'task'"
  >
    <mat-button-toggle
      *ngIf="!isEdit || (isEdit && data.eventType === eventTypes.REMINDER)"
      [disabled]="data?.eventType === eventTypes.REMINDER"
      class="d-flex align-items-center"
      value="reminder"
      (change)="isReminder = true"
    >
      <mat-icon aria-hidden="false" style="font-size: 20px; margin-top: 0.2rem"
        >notifications_active
      </mat-icon>
      <span class="ml-1" style="font-size: 15px">{{
        'pages.diary.popups.reminder' | translate
      }}</span>
    </mat-button-toggle>
    <mat-button-toggle
      *ngIf="!isEdit || (isEdit && data.eventType === eventTypes.TASK)"
      [disabled]="data?.eventType === eventTypes.TASK"
      class="d-flex align-items-center"
      value="task"
      (change)="onChangeToTask()"
    >
      <mat-icon aria-hidden="false" style="font-size: 20px; margin-top: 0.2rem"
        >work
      </mat-icon>
      <span class="ml-1" style="font-size: 15px">{{
        'pages.diary.popups.task' | translate
      }}</span>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <form *ngIf="isReminder" [formGroup]="addReminderForm" class="my-3">
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>{{ 'pages.diary.diary-fields.title' | translate }}</mat-label>
      <input
        type="text"
        matInput
        [placeholder]="
          'pages.diary.diary-fields.reminder-placeholder' | translate
        "
        formControlName="title"
      />
      <mat-error>{{
        'pages.diary.error-messages.title-error' | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>{{ 'pages.diary.diary-fields.date' | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="datePicker"
        (click)="datePicker.open()"
        [placeholder]="'pages.diary.diary-fields.date' | translate"
        formControlName="date"
        [min]="minDate"
        [max]="todayDate"
        readonly
      />

      <mat-datepicker-toggle
        matSuffix
        [for]="datePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
      <mat-error>{{
        'pages.diary.error-messages.date-error' | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>{{ 'pages.diary.diary-fields.time' | translate }}</mat-label>
      <input
        placeholder="{{
          'pages.diary.diary-fields.time-placeholder' | translate
        }}"
        matInput
        formControlName="time"
        [mtxDatetimepicker]="datetimePicker"
        (click)="datetimePicker.open()"
        readonly
        required
      />
      <mtx-datetimepicker-toggle
        [for]="datetimePicker"
        matSuffix
      ></mtx-datetimepicker-toggle>
      <mtx-datetimepicker
        #datetimePicker
        type="time"
        startView="clock"
        color="primary"
        mode="portrait"
        [twelvehour]="true"
        [touchUi]="false"
      >
      </mtx-datetimepicker>
      <mat-error *ngIf="addReminderForm.controls.time.hasError('required')">{{
        'pages.diary.error-messages.time-error' | translate
      }}</mat-error>
      <mat-error
        *ngIf="addReminderForm.controls.time.hasError('startTimeValid')"
      >
        {{ 'pages.diary.error-messages.start-time-earlier' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>{{ 'pages.diary.diary-fields.link' | translate }}</mat-label>
      <input
        type="text"
        matInput
        [placeholder]="'pages.diary.diary-fields.link-placeholder' | translate"
        formControlName="link"
      />
    </mat-form-field>

    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>{{ 'pages.diary.diary-fields.repeat' | translate }}</mat-label>
      <mat-select formControlName="repeat">
        <mat-option value="Never">{{
          'pages.diary.popups.never' | translate
        }}</mat-option>
        <mat-option value="Every Day">{{
          'pages.diary.popups.every-day' | translate
        }}</mat-option>
        <mat-option value="Every Week">{{
          'pages.diary.popups.every-week' | translate
        }}</mat-option>
        <mat-option value="Every Month">{{
          'pages.diary.popups.every-month' | translate
        }}</mat-option>
        <mat-option value="Every Year">{{
          'pages.diary.popups.every-year' | translate
        }}</mat-option>
      </mat-select>
      <mat-error>{{
        'pages.diary.error-messages.repeat-error' | translate
      }}</mat-error>
    </mat-form-field>
  </form>

  <form *ngIf="!isReminder" [formGroup]="addTaskForm" class="my-3">
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>{{ 'pages.diary.diary-fields.title' | translate }}</mat-label>
      <input
        type="text"
        matInput
        [placeholder]="'pages.diary.diary-fields.task-placeholder' | translate"
        formControlName="title"
      />
      <mat-error>{{
        'pages.diary.error-messages.title-error' | translate
      }}</mat-error>
    </mat-form-field>
    <div class="row">
      <mat-form-field class="col-md-6" appearance="fill">
        <mat-label>{{
          'pages.diary.diary-fields.start-date' | translate
        }}</mat-label>
        <input
          matInput
          [matDatepicker]="startDatePicker"
          (click)="startDatePicker.open()"
          [placeholder]="'pages.diary.diary-fields.start-date' | translate"
          formControlName="start_date"
          [min]="minDate"
          [max]="todayDate"
          readonly
        />

        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
        <mat-error
          *ngIf="addTaskForm.controls.start_date.hasError('required')"
          >{{
            'pages.diary.error-messages.start-date-error' | translate
          }}</mat-error
        >
      </mat-form-field>

      <mat-form-field class="col-md-6" appearance="fill">
        <mat-label>{{
          'pages.diary.diary-fields.end-date' | translate
        }}</mat-label>
        <input
          matInput
          [matDatepicker]="endDatePicker"
          (click)="endDatePicker.open()"
          [placeholder]="'pages.diary.diary-fields.end-date' | translate"
          formControlName="end_date"
          [min]="addTaskForm.controls.start_date.value || minDate"
          readonly
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
        <mat-error *ngIf="addTaskForm.controls.end_date.hasError('required')">{{
          'pages.diary.error-messages.end-date-error' | translate
        }}</mat-error>
        <mat-error>{{
          'pages.diary.error-messages.end-date-earlier' | translate
        }}</mat-error>
      </mat-form-field>
    </div>

    <mat-slide-toggle
      class="mb-3"
      labelPosition="before"
      formControlName="all_day"
      >{{ 'pages.diary.popups.all-day' | translate }}</mat-slide-toggle
    >

    <div class="row" *ngIf="!addTaskForm.get('all_day').value">
      <mat-form-field appearance="fill" class="col-md-6">
        <mat-label>{{
          'pages.diary.diary-fields.start-time' | translate
        }}</mat-label>
        <input
          placeholder="{{ 'pages.diary.diary-fields.start-time' | translate }}"
          matInput
          formControlName="start_time"
          [mtxDatetimepicker]="startTimePicker"
          (click)="startTimePicker.open()"
          readonly
          required
        />
        <mtx-datetimepicker-toggle
          [for]="startTimePicker"
          matSuffix
        ></mtx-datetimepicker-toggle>
        <mtx-datetimepicker
          #startTimePicker
          type="time"
          startView="clock"
          color="primary"
          mode="portrait"
          [twelvehour]="true"
          [touchUi]="false"
        >
        </mtx-datetimepicker>
        <mat-error
          *ngIf="addTaskForm.controls.start_time.hasError('required')"
          >{{
            'pages.diary.error-messages.start-time-error' | translate
          }}</mat-error
        >
        <mat-error
          *ngIf="addTaskForm.get('start_time')?.hasError('startTimeValid')"
          >{{
            'pages.diary.error-messages.start-time-earlier' | translate
          }}</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-md-6">
        <mat-label>{{
          'pages.diary.diary-fields.end-time' | translate
        }}</mat-label>
        <input
          placeholder="{{ 'pages.diary.diary-fields.end-time' | translate }}"
          matInput
          formControlName="end_time"
          [mtxDatetimepicker]="endTimePicker"
          (click)="endTimePicker.open()"
          readonly
          required
        />

        <mtx-datetimepicker-toggle
          [for]="endTimePicker"
          matSuffix
        ></mtx-datetimepicker-toggle>
        <mtx-datetimepicker
          #endTimePicker
          type="time"
          startView="clock"
          color="primary"
          mode="portrait"
          [twelvehour]="true"
          [touchUi]="false"
        >
        </mtx-datetimepicker>
        <mat-error *ngIf="addTaskForm.controls.end_time.hasError('required')">{{
          'pages.diary.error-messages.end-time-error' | translate
        }}</mat-error>
        <mat-error>{{
          'pages.diary.error-messages.end-time-earlier' | translate
        }}</mat-error>
      </mat-form-field>
    </div>

    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>{{
        'pages.diary.diary-fields.description' | translate
      }}</mat-label>
      <textarea
        matInput
        [placeholder]="
          'pages.diary.diary-fields.description-placeholder' | translate
        "
        formControlName="description"
      ></textarea>
      <mat-error>{{
        'pages.diary.error-messages.description-error' | translate
      }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button class="button-style" (click)="onClickCancel()">
    {{ 'pages.diary.diary-actions.cancel' | translate }}
  </button>
  <button
    *ngIf="isReminder"
    mat-flat-button
    [disabled]="addReminderForm.invalid || addReminderForm.pristine"
    color="primary"
    class="button-style"
    (click)="onClickReminderSave()"
  >
    {{ 'pages.diary.diary-actions.save' | translate }}
  </button>
  <button
    *ngIf="!isReminder"
    mat-flat-button
    [disabled]="addTaskForm.invalid || addTaskForm.pristine"
    color="primary"
    class="button-style"
    (click)="onClickTaskSave()"
  >
    {{ 'pages.diary.diary-actions.save' | translate }}
  </button>
</mat-dialog-actions>
