import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  ChangeData,
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { Subscription } from 'rxjs';

import { IPhoneNumberInputChange } from './types';

@Component({
  selector: 'el-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
})
export class PhoneNumberInputComponent implements OnChanges, OnInit, OnDestroy {
  @Input() value: ChangeData;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() icon: string;
  @Input() hint: string;
  @Input() dataTestId?: string;

  @Output() onChange: EventEmitter<IPhoneNumberInputChange> =
    new EventEmitter();

  phoneInput: FormControl<ChangeData> = new FormControl();

  subscription: Subscription;

  fistChange = true;

  country: CountryISO = CountryISO.SriLanka;
  SearchCountryField = SearchCountryField;
  countryISO = CountryISO;
  phoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.SriLanka,
    CountryISO.UnitedKingdom,
  ];

  ngOnInit(): void {
    this.subscription = this.phoneInput.valueChanges.subscribe((change) => {
      if (!this.fistChange) {
        this.onChange.emit({ ...(change || {}), valid: this.phoneInput.valid });
      } else {
        this.fistChange = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.value && changes?.value?.isFirstChange()) {
      const value: ChangeData = changes?.value?.currentValue;
      this.country =
        CountryISO[this.getKeyName(value?.countryCode)?.toLowerCase()];

      this.phoneInput.setValue(changes.value.currentValue);
    }

    if (changes.required) {
      if (changes.required.currentValue) {
        this.phoneInput.setValidators(Validators.required);
        this.phoneInput.updateValueAndValidity();
      } else {
        this.phoneInput.clearValidators();
        this.phoneInput.updateValueAndValidity();
      }
    }

    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.phoneInput.disable();
      } else {
        this.phoneInput.enable();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getKeyName(value: string) {
    return Object.entries(CountryISO).find(([, val]) => val === value)?.[0];
  }
}
