import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { RESPONSE_STATUSES } from '@shared/constants';
import {
  IRoleResponse,
  IWidget,
  IWidgetLayout,
  IWidgetLayoutPopulatedProperties,
  IWidgetUserLayout,
} from '@shared/interfaces';
import { getWidgetLayoutFromSize } from '@shared/utils';

import { MenuItem } from '../../../../pages/dashboard/side-menu/services/side-menu.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../../../services/snackbar.service';
import { RolesService } from '../../../permissions/services/roles.service';
import { WidgetApiService } from '../../services/widgets.api.service';
import { WidgetDialogData } from '../widget-container/widget-container.component';

@Component({
  selector: 'el-base-neo-widget-panel',
  templateUrl: './widget-panel.component.html',
  styleUrls: ['./widget-panel.component.scss'],
})
export class WidgetPanelComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private snackbar: SnackbarService,
    public widgetService: WidgetApiService,
    public rolesService: RolesService,
    private dialogRef: MatDialogRef<WidgetPanelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WidgetDialogData
  ) {}

  isLoading = false;

  roles: IRoleResponse[];
  modules: MenuItem[] = [];
  widgets: IWidget[];
  isDisabled = false;

  currentLayout: IWidgetUserLayout;
  myLayout: IWidgetUserLayout;
  selectedRole = 'MINE';

  ngOnInit() {
    this.currentLayout = this.data?.userWidgetsLayout ?? { data: [] };
    this.getAllWidgets();
    this.getAllRoles();
  }

  getRoleFromLayout() {
    if (this.data?.userWidgetsLayout?.role) {
      this.roles.forEach((item) => {
        if (item._id === this.currentLayout.role) {
          this.selectedRole = item._id.toString();
        }
      });
    }
  }

  checkForSelectedWidgets(): void {
    if (this.roles?.length <= 2 && this.selectedRole !== 'MINE')
      this.isDisabled = true;

    if (this.selectedRole === 'MINE') this.isDisabled = false;

    this.widgets = this.widgets.map((widget) => {
      if (this.currentLayout && this.currentLayout.data?.length !== 0) {
        const isSelected = this.currentLayout.data?.some(
          (item) => item.widget?._id === widget._id
        );

        return {
          ...widget,
          isSelected,
        };
      }
      return {
        ...widget,
        isSelected: false,
      };
    });
  }

  getAllWidgets(): void {
    this.widgetService.getAllWidgets().subscribe((res) => {
      this.widgets = res.data;
      this.checkForSelectedWidgets();
    });
  }

  getAllRoles(): void {
    this.widgetService.getAllRoles().subscribe({
      next: (res) => {
        if (res.data) {
          this.roles = res.data;
          this.getRoleFromLayout();
        }
      },
      error: () => {
        this.snackbar.error(
          this.translate.instant('widgets.error.failed-to-load-roles')
        );
      },
    });
  }

  getRoleBasedUserWidgetLayout(selectedRole: string): void {
    if (selectedRole === 'MINE') {
      this.widgetService.getUserIdentityWidgetLayout().subscribe({
        next: (res) => {
          if (res.data && !res.data.role) {
            this.currentLayout = res.data;
            this.checkForSelectedWidgets();
          } else {
            this.currentLayout = {
              data: [],
            };
            this.checkForSelectedWidgets();
          }
        },
      });
    } else {
      this.widgetService.getRoleWidgetLayout(selectedRole).subscribe({
        next: (res) => {
          if (res.data && res.data.data?.length !== 0) {
            this.currentLayout = res.data;
            this.checkForSelectedWidgets();
          } else {
            this.currentLayout = {
              data: [],
            };
            this.checkForSelectedWidgets();
          }
        },
        error: (error) => {
          if (error.status === RESPONSE_STATUSES.NOT_FOUND) {
            this.currentLayout = {
              data: [],
            };
            this.checkForSelectedWidgets();
          } else {
            this.snackbar.error(
              this.translate.instant('widgets.layout.failed-to-load')
            );
          }
        },
      });
    }
  }

  onClickResetLayout() {
    this.currentLayout = {
      data: this.currentLayout.data.filter(() => false),
    };
    this.checkForSelectedWidgets();
  }

  getAvailableXSlot(): number {
    let y = 0;
    this.currentLayout.data.forEach((item) => {
      if (y > item.position.y) {
        y++;
      }
    });
    return y;
  }
  getAvailableYSlot(): number {
    return 0;
  }

  onAddWidget(widget: IWidget): void {
    const maxY = 100;
    const newWidget: IWidgetLayoutPopulatedProperties = {
      widget,
      position: {
        x: -1,
        y: -1,
      },
      layout: getWidgetLayoutFromSize(widget.size),
      styles: {
        'grid-column-start': 1,
        'grid-column-end': getWidgetLayoutFromSize(widget.size).w,
        'grid-row-start': maxY,
        'grid-row-end': getWidgetLayoutFromSize(widget.size).h,
      },
    };

    this.currentLayout = {
      ...this.currentLayout,
      data: [...this.currentLayout.data, newWidget],
    };
    this.checkForSelectedWidgets();
  }

  onRemoveWidget(widget: IWidget): void {
    this.currentLayout = {
      ...this.currentLayout,
      data: this.currentLayout.data.filter(
        (item) => item.widget.key !== widget.key
      ),
    };
    this.checkForSelectedWidgets();
  }

  onChangeUserWidgetLayout(widgetLayout: IWidgetUserLayout): void {
    this.data.userWidgetsLayout = widgetLayout;
  }

  onChangeLayout(layout: IWidgetUserLayout): void {
    this.currentLayout = layout;
  }

  onSave(): void {
    this.isLoading = true;
    let payload: IWidgetLayout = {
      data: [],
    };

    if (this.selectedRole !== 'MINE') {
      payload = {
        ...this.currentLayout,
        role: this.selectedRole,
        data: this.currentLayout.data.map((item) => ({
          ...item,
          widget: item.widget._id.toString(),
        })),
      };
    } else {
      payload = {
        ...this.currentLayout,
        data: this.currentLayout.data.map((item) => ({
          ...item,
          widget: item.widget._id.toString(),
        })),
      };
    }

    this.widgetService.updateOrCreateLayout(payload).subscribe({
      next: (res) => {
        if (res.success) {
          this.isLoading = false;
          this.dialogRef.close(payload);
          this.snackbar.success(
            SUCCESS_TYPES.CREATED,
            this.translate.instant('widgets.layout.save-success')
          );
        }
      },
      error: () => {
        this.isLoading = false;
        this.snackbar.error(
          this.translate.instant('widgets.layout.save-failed')
        );
      },
    });
  }
}
