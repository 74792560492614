import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LanguagesService } from '../../../setup/languages/services/languages.service';
import { SupportService } from '../../../setup/support/services/support.service';

@Component({
  selector: 'app-server-error-overlay',
  templateUrl: './server-error-overlay.component.html',
  styleUrls: ['./server-error-overlay.component.scss'],
})
export class ServerErrorOverlayComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  hasTranslations: boolean;

  supportEnabled: boolean;
  supportPhoneNumber: string;
  supportWeb: string;
  supportEmail: string;

  constructor(
    public dialogRef: MatDialogRef<ServerErrorOverlayComponent>,
    private languageDataService: LanguagesService,
    private supportService: SupportService
  ) {}

  closeOverlay(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.languageDataService.dataStore
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((language) => {
        this.hasTranslations = language?.length > 0;
      });

    this.supportService.dataStore.subscribe((data) => {
      this.supportEnabled = data?.support_enable;
      if (data?.email_enable) this.supportEmail = data?.email;
      if (data?.url_enable) this.supportWeb = data?.url;
      if (data?.phone_enable)
        this.supportPhoneNumber = data?.phone?.internationalNumber?.replace(
          / /g,
          ''
        );
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
