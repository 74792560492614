import { USER_TYPES } from '@shared/constants';
import { IConnectedAppResponse, IIdentityResponse } from '@shared/interfaces';

import { IConnectionResponse } from '../../connections/services/connections.service';

export const validatePermissionsForLoggedUser = (
  identity: {
    user_type: USER_TYPES;
    connection?: IConnectionResponse<IConnectedAppResponse, IIdentityResponse>;
  },
  checkingPermissions: string[] = [],
  analyzingMode: 'every' | 'some' = 'every'
) => {
  if (identity?.user_type === USER_TYPES.EL_ADMIN) return true;

  return checkingPermissions[analyzingMode]((permission) =>
    (identity?.connection?.permissions || []).includes(permission)
  );
};
