import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO, IPhoneNumber } from '@shared/interfaces';

export interface ISupport {
  support_enable: boolean;
  url_enable?: boolean;
  url?: string;
  email_enable?: boolean;
  email?: string;
  phone_enable?: boolean;
  phone?: IPhoneNumber;
}

export interface SupportRequestDTO {
  support_enable: boolean;
  url_enable?: boolean;
  url?: string;
  email_enable?: boolean;
  email?: string;
  phone_enable?: boolean;
  phone?: IPhoneNumber;
}

export interface SupportResponseDTO {
  support_enable: boolean;
  url_enable?: boolean;
  url?: string;
  email_enable?: boolean;
  email?: string;
  phone_enable?: boolean;
  phone?: IPhoneNumber;
}

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  public readonly dataStore = new BehaviorSubject<ISupport | undefined>(
    undefined
  );

  constructor(private http: HttpClient) {}

  getAllSupportData(): Observable<CommonResponseDTO<ISupport>> {
    return this.http.get<CommonResponseDTO<ISupport>>(URLS.SUPPORT).pipe(
      tap((res) => {
        this.dataStore.next(res.data);
      })
    );
  }

  updateSupport(
    data: SupportRequestDTO
  ): Observable<CommonResponseDTO<ISupport>> {
    return this.http
      .patch<CommonResponseDTO<ISupport>>(URLS.SUPPORT, data)
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }

  updateSupportToggle(data): Observable<CommonResponseDTO<ISupport>> {
    return this.http
      .patch<CommonResponseDTO<ISupport>>(URLS.SUPPORT_SUPPORT_ENABLE, data)
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }
}
