import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ELAutocompleteElement } from '@el-autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

import {
  CONFIGURABLE_FIELD_CHECKBOX_TYPES,
  CONFIGURABLE_FIELD_DATA_TYPES,
  FIELD_LAYOUT_TYPES,
  FILE_TYPES,
} from '@shared/constants';
import {
  IReferenceCategoryResponse,
  IUploadFileToDMS,
  MultiFieldData,
} from '@shared/interfaces';
import {
  generateFileTypeRegex,
  isConstant,
  isRequired,
  isUnique,
} from '@shared/utils';

import { FilesService } from '../../../../services/files.service';
import { SnackbarService } from '../../../../services/snackbar.service';
import Base64UploaderPlugin from '../../../../utils/Base64Upload';
import { ConnectedAppsService } from '../../../connected-apps/services/connected-apps.service';
import { TemplateMergeTagsService } from '../../../references/popups/templates/template-merge-tags.service';
import {
  TemplateResponseDTO,
  TemplateService,
} from '../../../references/services/template.service';
import { CurrenciesService } from '../../../setup/currencies/services/currencies.service';
import { IMarkerData } from '../../../shared/components/monaco-editor/monaco-editor.component';
import { MONACO_SUPPORTED_LANGUAGES } from '../../../shared/components/monaco-editor/monaco-editor.types';
import { DisplayConfigurableFieldService } from '../../services';
import {
  IConfigureConfigurableFieldElement,
  IDisplayConfigurableFieldElement,
} from '../../types';
interface FieldValueAggregationResult {
  field_id: string;
  values: string[];
}
@Component({
  selector: 'app-display-single-configurable-field',
  templateUrl: './display-single-configurable-field.component.html',
  styleUrls: ['./display-single-configurable-field.component.scss'],
})
export class DisplaySingleConfigurableFieldComponent
  implements OnInit, OnDestroy
{
  @Input() fieldItem: IDisplayConfigurableFieldElement;
  @Input() referenceCategory?: IReferenceCategoryResponse;
  @Input() isDefault?: boolean = true;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() disableUniqueFields = false;
  @Input() fields: IConfigureConfigurableFieldElement[] = [];

  @Output() onFieldValueChange?: EventEmitter<void> = new EventEmitter();
  currencyCode: string;
  editor = ClassicEditor;
  editorConfig = {
    extraPlugins: [Base64UploaderPlugin],
    removePlugins: ['MediaEmbed'],
  };
  private onDestroyInternalData$ = new Subject();

  maxSize = 150;
  allowedTypesNames = '*.docx/ *.xlsx/ *.jpeg/ *.png/ *.pdf/ *.csv and *.eml';
  allowedTypesRegex = generateFileTypeRegex([
    FILE_TYPES.PDF,
    FILE_TYPES.IMAGE,
    FILE_TYPES.DOCS,
    FILE_TYPES.EXCEL,
    FILE_TYPES.CSV,
  ]);
  FIELD_LAYOUT_TYPES = FIELD_LAYOUT_TYPES;
  MONACO_SUPPORTED_LANGUAGES = MONACO_SUPPORTED_LANGUAGES;
  CONFIGURABLE_FIELD_DATA_TYPES = CONFIGURABLE_FIELD_DATA_TYPES;
  customValidationInvalid = false;
  jsonEditorErrors: IMarkerData[] = [];
  internalFieldData: FieldValueAggregationResult[];
  constructor(
    private templateService: TemplateService,
    private templateMergeTagsService: TemplateMergeTagsService,
    private displayConfigurableFieldService: DisplayConfigurableFieldService,
    private snackBar: SnackbarService,
    private translate: TranslateService,
    private filesService: FilesService,
    private connectedAppsService: ConnectedAppsService,
    private currenciesService: CurrenciesService
  ) {}

  ngOnInit(): void {
    this.connectedAppsService.$additionalFieldsUniqueness
      .pipe(takeUntil(this.onDestroyInternalData$))
      .subscribe({
        next: (res) => {
          this.internalFieldData = res;
        },
      });
    this.initializeFormControlValue();
    this.checkAndClearIfUnique();
    this.currencyCode = this.currenciesService.baseCurrency.code;

    if (this.disabled) {
      this.fieldItem.field_value.disable();
    }
    this.fieldItem.field_value.valueChanges
      .pipe(takeUntil(this.onDestroyInternalData$))
      .subscribe((value) => {
        this.handleFieldValueChange(value, this.fieldItem);
      });
  }

  handleFieldValueChange(
    value: any,
    _fieldItem: IDisplayConfigurableFieldElement
  ): void {
    const uniqueField = _fieldItem.configuration.checkboxes.some(
      (checkbox) =>
        checkbox.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE &&
        checkbox.isChecked === true
    );

    if (uniqueField) {
      const fieldId = _fieldItem.configuration._id;

      const fieldData = this.internalFieldData.find(
        (interField) => interField.field_id === fieldId
      );
      let isUnique = false;

      if (fieldData) {
        if (typeof value === 'string') {
          isUnique = fieldData.values.some((val) =>
            value.trim().length == 0
              ? false
              : value.replace(/\s+/g, ' ').trim() ===
                val.replace(/\s+/g, ' ').trim()
          );
          this.displayConfigurableFieldService.listenToUniqueChange(isUnique);
        } else {
          isUnique = fieldData.values.some((val) => value === val);
        }

        if (isUnique) {
          _fieldItem.field_value.setErrors({ unique: true });
        } else {
          _fieldItem.field_value.setErrors(null);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroyInternalData$.next(null);
    this.onDestroyInternalData$.complete();
  }

  initializeFormControlValue() {
    const fieldValue = this.fieldItem.field_value.value;
    if (
      this.fieldItem.configuration.type ===
      CONFIGURABLE_FIELD_DATA_TYPES.DATE_TIME
    ) {
      if (fieldValue) {
        const dateValue = new Date(fieldValue);
        if (!isNaN(dateValue.getTime())) {
          this.fieldItem.field_value.setValue(dateValue);
        }
      } else {
        this.fieldItem.field_value.setValue(undefined);
      }
    }
  }

  async setUpView() {
    let savedTemplate: TemplateResponseDTO;

    if (this.fieldItem.field_value.value) {
      savedTemplate = await this.templateService.getTemplateById(
        this.fieldItem.field_value.value
      );
    }

    const template = await this.templateMergeTagsService.openEditor(
      savedTemplate
    );

    if (template) {
      this.fieldItem.templateName = template.name;
      this.fieldItem.field_value.setValue(template._id);
      this.fieldItem.field_value.markAsDirty();
      this.fieldItem.field_value.markAsTouched();
    }
  }

  onJSONChange({ data }) {
    this.fieldItem.field_value.setValue(data);
    this.fieldItem.field_value.markAsDirty();
    this.fieldItem.field_value.markAsTouched();
  }

  // FIXME:@chethana find a better way to do this
  // FIXME:@chethana This way does not handle all the file types
  // FIXME:@chethana and also since this function is called from the HTML it gets called everytime the HTML rerender, which is too much
  // FIXME:@chethana use the util function get the icon path which has been used everywhere else
  // FIXME:@chethana use import { lookup } from 'mime-types', FILE_TYPES and MIME_TYPES constants and getMimeTypesForFileType util function to get the expected behaviour. Not sure which will be needed
  getThumbnail(file_path: string): string[] {
    if (file_path && file_path.includes('.')) {
      const extension = file_path.split('.').pop();
      switch (extension) {
        case 'png':
          return ['../../../../../assets/pngIcon.png']; // no need to give the full path, just assets/pngIcon.png is enough (not sure whether you need to add a slash / before assets. try it out)
        case 'jpg':
        case 'jpeg':
          return ['../../../../../assets/jpeg.png'];
        case 'pdf':
          return ['../../../../../assets/pdf.png'];
        case 'docx':
          return ['../../../../../assets/word.png'];
        case 'xlsx':
          return ['../../../../../assets/excel.png'];
        default:
          return ['../../../../../assets/csv.png'];
      }
    }
    return [];
  }

  isRequired() {
    return isRequired(this.fieldItem.configuration.checkboxes);
  }

  checkAndClearIfUnique(): void {
    if (this.isUnique() && this.disableUniqueFields) {
      this.fieldItem.field_value.reset();
      this.fieldItem.field_value.disable();
    }
  }

  isUnique() {
    if (
      isUnique(this.fieldItem.configuration.checkboxes) &&
      this.disableUniqueFields
    ) {
      this.fieldItem.field_value.reset();
      this.fieldItem.field_value.disable();
    } else if (
      !isUnique(this.fieldItem.configuration.checkboxes) &&
      this.disableUniqueFields &&
      this.fieldItem.field_value.disabled
    ) {
      this.fieldItem.field_value.enable();
    }
    return (
      isUnique(this.fieldItem.configuration.checkboxes) &&
      this.disableUniqueFields
    );
  }

  isConstant() {
    return isConstant(this.fieldItem.configuration.checkboxes);
  }

  triggerFileUploaded(file: File) {
    if (file.type.match(this.allowedTypesRegex)) {
      const uploadData: IUploadFileToDMS<File> = {
        file,
        generate_name: true,
        is_system: false,
        is_public: false,
        path: 'Fields', // TODO: where should these files be uploaded?
        tags: ['Fields'], // TODO: should pass the tags
      };

      this.filesService.uploadFileToDMS(uploadData).subscribe({
        next: (res) => {
          this.fieldItem.field_value.setValue(res.data._id);
          this.fieldItem.field_value.markAsDirty();
          this.fieldItem.field_value.markAsTouched();
        },
        error: () => {
          // TODO: handle the error
        },
      });
    } else {
      this.snackBar.warn(
        this.translate.instant('configurable-fields.restricted-file-type')
      );
    }
  }

  onOtherValueEmitted(data: MultiFieldData) {
    this.fieldItem.field_value.setValue(data.value);
    this.fieldItem.field_value.markAsDirty();
    this.fieldItem.field_value.markAsTouched();
    this.fieldValueChanged();
  }

  onDropdownItemSelect(
    data: ELAutocompleteElement | ELAutocompleteElement[]
  ): void {
    if (
      !data ||
      isArray(data) ||
      this.fieldItem.field_value.value === data.value
    )
      return;

    this.fieldItem.field_value.setValue(data.value);
    this.fieldItem.field_value.markAsDirty();
    this.fieldItem.field_value.markAsTouched();

    this.fieldValueChanged();
  }

  checkboxValueChange(isChecked: boolean, checkbox_index: number) {
    this.fieldItem.configuration.checkboxOptions[checkbox_index].checked =
      isChecked;

    // when field is required, check if at least one checkbox is selected
    this.customValidationInvalid =
      this.isRequired() &&
      this.fieldItem.configuration.checkboxOptions.findIndex(
        (option) => option.checked
      ) === -1;

    this.fieldValueChanged();
  }

  radioButtonChange(event: MatRadioChange) {
    this.fieldItem.configuration.radioButtonOptions.forEach((option) => {
      option.checked = option.value === event.value;
    });

    // when field is required, check if a radio button is selected
    this.customValidationInvalid =
      this.isRequired() && !this.fieldItem.field_value.value?.toString();

    this.fieldValueChanged();
  }

  onUpdateFormula(formula: string) {
    this.fieldItem.field_value.setValue(formula);
    this.fieldItem.field_value.markAsTouched();
  }

  fieldValueChanged() {
    this.onFieldValueChange.emit();
  }

  resetErrorState() {
    if (this.fieldItem.field_value.invalid) {
      this.fieldItem.field_value.setErrors(null);
    }
  }
}
