import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { ENDPOINTS, REFERENCE_PERMISSION_ACTIONS } from '@shared/constants';
import {
  CommonResponseDTO,
  IExternalSystem,
  IGenericObject,
  IReferenceCategoryResponse,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

import { LoggedUserService, UserInfoResponseDTO } from '../../../auth/services';
import { validateReferenceModulePermissions } from '../../../core/helpers/permission-validator.helper';
import { notDeleted } from '../../../references/helpers/not-deleted-filter.helper';

type AllReferenceCategoriesResponse = CommonResponseDTO<
  IReferenceCategoryResponse[]
>;

@Injectable({
  providedIn: 'root',
})
export class ExternalSystemsService {
  private loggedUser: UserInfoResponseDTO;

  private readonly _dataStore = new BehaviorSubject<IExternalSystem[]>([]);
  dataStore = this._dataStore.asObservable();

  private readonly _systemWiseExternalReferenceCategories = new BehaviorSubject<
    IGenericObject<IReferenceCategoryResponse[]>
  >({});
  systemWiseExternalReferenceCategories =
    this._systemWiseExternalReferenceCategories.asObservable();

  constructor(loggedUserService: LoggedUserService, private http: HttpClient) {
    loggedUserService.dataStore.subscribe((loggedUser) => {
      this.loggedUser = loggedUser;
    });
  }

  getExternalSystemsData(): Observable<CommonResponseDTO<IExternalSystem[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_GET_ALL,
    });
    return this.http.get<CommonResponseDTO<IExternalSystem[]>>(url).pipe(
      tap((res) => {
        this._dataStore.next(res.data);
      })
    );
  }

  getConnectedExternalReferenceCategories(
    systemId?: string
  ): Observable<CommonResponseDTO<IReferenceCategoryResponse[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.REFERENCE_CATEGORIES_GET_EXTERNAL,
      params: { id: systemId?.toString() },
    });

    return this.http.get<CommonResponseDTO<IReferenceCategoryResponse[]>>(url);
  }

  deleteExternalCategory(
    categoryId: string
  ): Observable<CommonResponseDTO<IReferenceCategoryResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.REFERENCE_CATEGORIES_GET_EXTERNAL_DELETE,
      params: { id: categoryId.toString() },
    });

    return this.http.delete<CommonResponseDTO<IReferenceCategoryResponse>>(url);
  }

  updateExternalSystemsData(
    data: IExternalSystem
  ): Observable<CommonResponseDTO<IExternalSystem>> {
    const url = generateURL({
      endpoint: ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_UPDATE_ONE,
      params: { id: data._id?.toString() },
    });
    return this.http.patch<CommonResponseDTO<IExternalSystem>>(url, data).pipe(
      tap((res) => {
        const currentData = this._dataStore.value;
        const currentDataIndex = currentData.findIndex(
          (externalSystem) => externalSystem._id === data._id
        );

        if (currentDataIndex) {
          currentData[currentDataIndex] = res.data;
        } else {
          currentData.push(res.data);
        }

        this._dataStore.next(currentData);
      })
    );
  }

  getAllReferenceCategoriesExternal(
    systemId: string
  ): Promise<IReferenceCategoryResponse[]> {
    const systemWiseExternalReferenceCategories =
      this._systemWiseExternalReferenceCategories.value;

    return new Promise((resolve, reject) => {
      if (systemWiseExternalReferenceCategories[systemId]) {
        return resolve(systemWiseExternalReferenceCategories[systemId]);
      }

      const url = generateURL({
        endpoint:
          ENDPOINTS.SETUP_EXTERNAL_SYSTEM_GET_EXTERNAL_REFERENCE_CATEGORIES,
        params: {
          id: systemId,
        },
      });

      this.http.get<AllReferenceCategoriesResponse>(url).subscribe({
        next: ({ data }) => {
          const validCategories = data.filter(notDeleted);

          if (
            validateReferenceModulePermissions(
              this.loggedUser,
              validCategories,
              REFERENCE_PERMISSION_ACTIONS.READ_CATEGORY
            ) !== 'none'
          ) {
            systemWiseExternalReferenceCategories[systemId] = validCategories;
            this._systemWiseExternalReferenceCategories.next(
              systemWiseExternalReferenceCategories
            );

            resolve(validCategories);
          } else {
            reject(new Error('references.root.no-privileged'));
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }
}
