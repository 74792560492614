import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable, take } from 'rxjs';

import { IamConfigService } from '../services/iam-config.service';

@Injectable({
  providedIn: 'root',
})
export class UserManagementGuard implements CanActivate {
  constructor(
    private iamConfigService: IamConfigService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((resolve) => {
      this.iamConfigService.dataStore.pipe(take(1)).subscribe((data) => {
        const { configCb } = route.data;
        const isAllowedCb = configCb(data.user_management);

        if (isAllowedCb) {
          resolve(true);
        } else {
          resolve(this.router.createUrlTree(['/access-denied']));
        }
      });
    });
  }
}
