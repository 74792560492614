<mat-chip
  [matTooltip]="chipInput.value || placeholders[0] || ''"
  (click)="$event.stopPropagation()"
  (focus)="$event.stopPropagation()"
  class="mat-chip"
  [disabled]="loading"
  [removable]="
    (isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()) &&
    !loading
  "
  (removed)="remove(selectedData)"
  attr.data-testid="cis_number_{{
    selectedData?.name?.toLowerCase()?.replace(' ', '_')
  }}"
>
  <label class="has-float-label">
    <input
      #chipInput
      type="number"
      class="chip-input"
      [placeholder]="placeholders[0]"
      style="margin-bottom: 1px"
      (focus)="inputFocusOn = true && !loading"
      (blur)="inputFocusOn = false"
      [style.width.px]="getInputWidth(chipInput.value.toString().length, label)"
      [formControl]="number"
      [readonly]="isChipLock() || loading || isChipChiningParams()"
      (keydown)="$event.stopImmediatePropagation()"
      attr.data-testid="cis_number_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}_input_number"
    />
    <div
      attr.data-testid="cis_number_{{
        selectedData?.name?.toLowerCase()?.replace(' ', '_')
      }}_label"
      #label
      class="label"
    >
      {{ selectedData?.name ?? '' | translate }}
    </div>
  </label>

  <mat-icon
    matChipRemove
    *ngIf="
      isChipOptional() ||
      isChipPreselected() ||
      isChipChiningParams() ||
      isChipDefault()
    "
    [style.cursor]="loading ? 'context-menu' : 'pointer'"
    style="vertical-align: text-top"
    attr.data-testid="cis_number_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_btn_cancel"
    >cancel</mat-icon
  >
  <mat-icon
    *ngIf="isChipMandatory()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_number_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_mandatory"
    >push_pin</mat-icon
  >
  <mat-icon
    *ngIf="isChipLock()"
    style="vertical-align: text-top; cursor: default"
    attr.data-testid="cis_number_{{
      selectedData?.name?.toLowerCase()?.replace(' ', '_')
    }}_icon_locked"
    >lock</mat-icon
  >
</mat-chip>
