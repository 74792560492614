import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-post-it-notes',
  templateUrl: './skeleton-post-it-notes.component.html',
  styleUrls: ['./skeleton-post-it-notes.component.scss'],
})
export class SkeletonPostItNotesComponent implements OnInit {
  @Input() skeletonNotes = 1;
  notes = [];

  ngOnInit(): void {
    this.notes = Array(this.skeletonNotes)
      .fill(0)
      .map((_x, i) => i);
  }
}
