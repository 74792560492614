import { ENDPOINT_PARAM_TYPES } from '@shared/constants';

export function getPreDefinedDynamicValues(
  type: ENDPOINT_PARAM_TYPES
): string | undefined {
  const currentDate = new Date();

  if (type === ENDPOINT_PARAM_TYPES.CURRENT_YEAR_NUMBER) {
    return String(currentDate.getFullYear());
  } else if (type === ENDPOINT_PARAM_TYPES.CURRENT_MONTH_NUMBER_ZERO_BASED) {
    return String(currentDate.getMonth());
  } else if (type === ENDPOINT_PARAM_TYPES.CURRENT_MONTH_NUMBER_ONE_BASED) {
    return String(currentDate.getMonth() + 1);
  } else if (
    type === ENDPOINT_PARAM_TYPES.CURRENT_MONTH_NAME_THREE_CHARACTERS
  ) {
    return currentDate.toLocaleString('en-US', { month: 'short' });
  }

  return undefined;
}
