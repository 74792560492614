import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FlowControlService } from '../../services/flow-control.service';

@Component({
  selector: 'app-deactivate-module',
  templateUrl: './deactivate-module.component.html',
  styleUrls: ['./deactivate-module.component.scss'],
})
export class DeactivateModuleComponent {
  constructor(
    router: Router,
    flowControlService: FlowControlService,
    route: ActivatedRoute
  ) {
    flowControlService.updateRouteInfo(
      router.url.split('?')[0],
      route.snapshot.data
    );
  }
}
