<div style="height: 100%; width: 100%; margin-top: 2rem">
  <div class="row top-bar">
    <div class="col-3 today-area">
      <button mat-icon-button class="skeleton-loader-item" disabled></button>
    </div>
    <div class="col-4 month-area">
      <button
        mat-icon-button
        class="skeleton-loader-item w-100"
        disabled
      ></button>
    </div>
    <div class="col-5 date-format">
      <button mat-icon-button class="skeleton-loader-item" disabled></button>
      <button
        mat-icon-button
        class="skeleton-loader-item d-s-none"
        disabled
      ></button>
      <button
        mat-icon-button
        class="skeleton-loader-item d-s-none"
        disabled
      ></button>
    </div>
  </div>

  <div *ngFor="let row of rows" class="row m-3">
    <div *ngFor="let column of columns" class="m-0 p-0">
      <button
        mat-icon-button
        class="skeleton-loader-item box"
        disabled
      ></button>
    </div>
  </div>
</div>
