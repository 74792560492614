import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { ConnectionService } from 'angular-connection-service';
import { Observable, throwError } from 'rxjs';

import { RESPONSE_STATUSES } from '@shared/constants';

import { INTERCEPTOR_SKIPPERS } from '../../../constants';
import { SnackbarService } from '../../../services/snackbar.service';
import { AuthService } from '../../auth/services';
import { ServerErrorOverlayComponent } from '../components/server-error-overlay/server-error-overlay.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  isInternalServerError = false;
  isUnreachableServerError = false;
  isOnline = false;
  firstTime = true;

  serverErrorDialogRef: MatDialogRef<ServerErrorOverlayComponent>;

  constructor(
    private authService: AuthService,
    private snackBar: SnackbarService,
    private dialog: MatDialog,
    private connectionService: ConnectionService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.connectionService?.monitor().subscribe((response) => {
      this.isOnline = response.hasNetworkConnection;

      if (response.hasNetworkConnection) {
        if (response.hasInternetAccess) {
          if (this.isUnreachableServerError && !this.isInternalServerError) {
            this.serverErrorDialogRef?.close();
          }
          this.isUnreachableServerError = false;
        } else {
          if (
            !this.isInternalServerError &&
            !this.isUnreachableServerError &&
            !this.firstTime
          ) {
            this.firstTime = false;
            this.openInternalServerErrorDialog();
          }
          this.isUnreachableServerError = true;
        }
      }
    });
  }

  openInternalServerErrorDialog() {
    this.serverErrorDialogRef = this.dialog.open(ServerErrorOverlayComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      hasBackdrop: false,
      panelClass: 'offline-dialog',
      id: 'SERVER_ERROR_OVERLAY',
    });

    this.serverErrorDialogRef.afterClosed().subscribe(() => {
      this.isInternalServerError = false;
      this.isUnreachableServerError = false;
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request?.headers.has(INTERCEPTOR_SKIPPERS.ERROR_INTERCEPTOR)) {
      return next?.handle(request.clone()).pipe(
        catchError(
          (err: {
            error: { error?: string; statusCode: number; message: string };
            status?: number;
          }) => {
            switch (err?.error?.statusCode ?? err?.status) {
              case RESPONSE_STATUSES.UNAUTHORIZED: {
                if (
                  !(
                    this.router.url.includes('login') ||
                    this.router.url.includes('verify')
                  ) &&
                  (err?.error?.error || err?.error?.message)
                ) {
                  this.authService.logout().then(() => {
                    this.dialog.closeAll();

                    this.snackBar.error(
                      this.translate.instant('auth.login.session-expired')
                    );
                    this.router.navigate(['/app/auth/login'], {
                      queryParams: {
                        returnUrl: this.router.url,
                      },
                    });
                  });
                }
                return null;
              }
              case RESPONSE_STATUSES.PRECONDITION_FAILED:
                this.authService.logout().then(() => {
                  this.dialog.closeAll();
                  this.router.navigate(['/app/auth/login']);
                  this.snackBar.error(
                    this.translate.instant('auth.login.session-expired')
                  );
                });
                return null;

              case RESPONSE_STATUSES.FORBIDDEN:
                this.snackBar.error(
                  this.translate.instant('auth.login.not-authorized')
                );
                return null;

              case RESPONSE_STATUSES.TOO_MANY_REQUESTS:
                this.snackBar.warn(
                  this.translate.instant('auth.login.too-many-request')
                );
                return null;

              case RESPONSE_STATUSES.CONFLICT:
              case RESPONSE_STATUSES.PAYLOAD_TOO_LARGE:
              case RESPONSE_STATUSES.NOT_FOUND:
              case RESPONSE_STATUSES.BAD_REQUEST:
              case RESPONSE_STATUSES.UNPROCESSABLE_ENTITY:
                // do not handle these
                // should handle locally
                return throwError(() => err);

              case RESPONSE_STATUSES.OFFLINE:
                return null;

              default:
                if (!this.isInternalServerError && this.isOnline) {
                  this.openInternalServerErrorDialog();
                  this.isInternalServerError = true;
                  return null;
                }
                return throwError(() => err);
            }
          }
        )
      );
    }

    if (request.headers.has(INTERCEPTOR_SKIPPERS.ERROR_INTERCEPTOR)) {
      const headers = request.headers.delete(
        INTERCEPTOR_SKIPPERS.ERROR_INTERCEPTOR
      );
      return next.handle(request.clone({ headers }));
    }

    return next.handle(request);
  }
}
