import { Component, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AuthService } from '../../../auth/services';
import {
  CONFIRMATION_TYPES,
  IConfirmationPopup,
  IConfirmationPopupValues,
} from '../../services/get-user-confirmation/service';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent {
  values: IConfirmationPopupValues;
  hide = true;

  authentication = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmationPopup,
    public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    private authService: AuthService
  ) {
    switch (data.type) {
      case CONFIRMATION_TYPES.DELETE:
        this.values = {
          icon: 'delete_outline',
          color: 'warn',
        };
        break;

      case CONFIRMATION_TYPES.RESTORE:
        this.values = {
          icon: 'restore_from_trash',
          color: 'primary',
        };
        break;

      case CONFIRMATION_TYPES.DISABLE:
        this.values = {
          icon: 'toggle_off',
          color: 'accent',
        };
        break;

      case CONFIRMATION_TYPES.ENABLE:
        this.values = {
          icon: 'toggle_on',
          color: 'accent',
        };
        break;

      case CONFIRMATION_TYPES.REMOVE:
        this.values = {
          icon: 'do_not_disturb_on',
          color: 'warn',
        };
        break;

      default:
        this.values = {
          ...data,
          color: data.color || 'primary',
        };
        break;
    }
  }

  onClickYes(): void {
    if (this.data.need_authentication) {
      this.validatePassword();
    } else {
      this.dialogRef.close(true);
    }
  }

  validatePassword() {
    const data = {
      password: this.authentication.controls.password.value,
    };
    this.authService.checkUser(data).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: () => {
        this.authentication.controls.password.setErrors({ incorrect: true });
      },
    });
  }
}
