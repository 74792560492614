<div *ngIf="isLoading" class="loading-overlay">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="container pt-3">
  <div class="row">
    <div class="col-12 col-md-7 mb-3">
      <mat-card>
        <mat-card-title>
          <h6 class="d-flex align-items-center custom-style-font mb-0">
            <mat-icon class="heading-icon mr-2">vpn_key</mat-icon
            ><strong>{{
              'pages.reset-password.title-reset-password' | translate
            }}</strong>
          </h6>
        </mat-card-title>
        <mat-card-subtitle>{{
          'pages.reset-password.sub-title' | translate
        }}</mat-card-subtitle>
        <mat-divider></mat-divider>
        <mat-card-content class="mt-4">
          <form [formGroup]="resetPasswordFormGroup">
            <mat-form-field class="w-100" appearance="fill">
              <mat-label>{{
                'pages.reset-password.current-password' | translate
              }}</mat-label>
              <input
                data-testid="reset_pwd_input_current_password"
                id="password"
                [type]="isHidden ? 'password' : 'text'"
                matInput
                formControlName="current_password"
                [placeholder]="
                  'pages.reset-password.current-password' | translate
                "
                autocomplete="current-password"
                required
              />
              <button
                data-testid="reset_pwd_btn_toggle_show_password"
                mat-icon-button
                matSuffix
              >
                <mat-icon (click)="isHidden = !isHidden">{{
                  isHidden ? 'visibility' : 'visibility_off'
                }}</mat-icon>
              </button>
              <mat-error
                *ngIf="
                  resetPasswordFormGroup.controls.current_password.hasError(
                    'required'
                  )
                "
                >{{ 'pages.reset-password.required-ps' | translate }}</mat-error
              >
              <mat-error
                *ngIf="
                  resetPasswordFormGroup.controls.current_password.hasError(
                    'incorrect'
                  )
                "
                >{{ 'pages.reset-password.valid-ps' | translate }}</mat-error
              >
            </mat-form-field>

            <mat-form-field class="w-100" appearance="fill">
              <mat-label>{{
                'pages.reset-password.new-password' | translate
              }}</mat-label>
              <input
                data-testid="reset_pwd_input_new_password"
                id="new-password"
                type="password"
                matInput
                [placeholder]="'pages.reset-password.new-password' | translate"
                autocomplete="new-password"
                formControlName="new_password"
                required
              />
              <mat-icon matSuffix>lock</mat-icon>
              <mat-error
                *ngIf="
                  resetPasswordFormGroup.controls.new_password.hasError(
                    'required'
                  )
                "
                >{{
                  'pages.reset-password.required-new-password' | translate
                }}</mat-error
              >
              <mat-error
                *ngIf="
                  resetPasswordFormGroup.controls.new_password.hasError(
                    'pattern'
                  )
                "
              >
                {{ 'pages.reset-password.password-invalid-error' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-100" appearance="fill">
              <mat-label>{{
                'pages.reset-password.confirm-new-password' | translate
              }}</mat-label>
              <input
                data-testid="reset_pwd_input_conf_new_password"
                id="confirm-password"
                type="password"
                matInput
                [placeholder]="
                  'pages.reset-password.confirm-new-password' | translate
                "
                autocomplete="new-password"
                formControlName="confirm_password"
                required
              />
              <mat-icon matSuffix>lock</mat-icon>
              <mat-error
                *ngIf="
                  resetPasswordFormGroup.controls.confirm_password.hasError(
                    'required'
                  )
                "
                >{{
                  'pages.reset-password.required-pwd' | translate
                }}</mat-error
              >
              <mat-error
                *ngIf="
                  resetPasswordFormGroup.controls.confirm_password.hasError(
                    'unMatching'
                  )
                "
                >{{ 'pages.reset-password.equal' | translate }}</mat-error
              >
            </mat-form-field>
          </form>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions class="d-flex justify-content-end p-2 pt-3">
          <button
            data-testid="reset_pwd_btn_submit_form"
            mat-raised-button
            [disabled]="
              resetPasswordFormGroup.pristine || resetPasswordFormGroup.invalid
            "
            color="accent"
            type="button"
            (click)="onSubmit()"
            [matTooltip]="'pages.reset-password.update' | translate"
          >
            {{ 'pages.reset-password.update' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-12 col-md-5 mb-3">
      <mat-card>
        <mat-card-title-group>
          <mat-card-title>
            <h6>
              <strong>{{ 'pages.reset-password.help' | translate }}</strong>
            </h6>
          </mat-card-title>
          <mat-card-subtitle>{{
            'pages.reset-password.help-and-sup-description' | translate
          }}</mat-card-subtitle>
        </mat-card-title-group>

        <mat-divider></mat-divider>

        <mat-card-content style="margin-top: 14px">
          <mat-accordion multi="true">
            <mat-expansion-panel
              style="margin-top: 14px"
              expanded="true"
              *ngIf="passwordValidationPattern"
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="d-flex align-items-center">
                  <mat-icon class="mr-2">vpn_key</mat-icon
                  ><strong>{{
                    'pages.reset-password.new-password' | translate
                  }}</strong>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>{{ passwordErrorMessage }}</p>
            </mat-expansion-panel>
            <mat-expansion-panel expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title class="d-flex align-items-center">
                  <mat-icon class="mr-2">account_circle</mat-icon
                  ><strong>{{
                    'pages.reset-password.your-account' | translate
                  }}</strong>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                {{
                  'pages.reset-password.your-account-description' | translate
                }}
              </p>
              <div class="row d-flex justify-content-end">
                <button
                  data-testid="reset_pwd_btn_goto_profile"
                  routerLink="/app/profile"
                  [matTooltip]="'pages.reset-password.go-to' | translate"
                  mat-raised-button
                  color="primary"
                >
                  {{ 'pages.reset-password.go-to' | translate }}
                </button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
