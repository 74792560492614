import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import {
  CommonResponseDTO,
  IEndpointRequest,
  IEndpointResponse,
  IMapping,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

export type IEndpointResponseWithMappings = IEndpointResponse & {
  mappings: IMapping[];
};

@Injectable({
  providedIn: 'root',
})
export class EndpointsService {
  private readonly _dataStore = new BehaviorSubject<
    IEndpointResponseWithMappings[]
  >([]);
  public dataStore = this._dataStore.asObservable();

  constructor(private http: HttpClient) {}

  public async getEndpointsWithMappings(): Promise<
    IEndpointResponseWithMappings[]
  > {
    const loadedEndpoints = this._dataStore.value;

    if (loadedEndpoints.length > 0) return loadedEndpoints;

    const url = generateURL({
      endpoint: ENDPOINTS.INTEGRATION_ENDPOINTS_GET_ALL,
    });
    const config = { params: { with_mappings: 'true' } };
    const endpoints = await firstValueFrom(
      this.http.get<CommonResponseDTO<IEndpointResponseWithMappings[]>>(
        url,
        config
      )
    );

    this._dataStore.next(endpoints.data);

    return endpoints.data;
  }

  public getEndpoints(
    params: HttpParams
  ): Observable<CommonResponseDTO<IEndpointResponse[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.INTEGRATION_ENDPOINTS_GET_ALL,
    });
    const config = { params };
    return this.http.get<CommonResponseDTO<IEndpointResponse[]>>(url, config);
  }

  public createEndpoint(
    endpoint: IEndpointRequest
  ): Observable<CommonResponseDTO<IEndpointResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.INTEGRATION_ENDPOINTS_ADD_ONE,
    });
    return this.http.post<CommonResponseDTO<IEndpointResponse>>(url, endpoint);
  }

  public updateAnEndpoint(
    endpoint: IEndpointRequest,
    id: string
  ): Observable<CommonResponseDTO<IEndpointResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.INTEGRATION_ENDPOINTS_UPDATE_ONE,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IEndpointResponse>>(url, endpoint);
  }

  public deleteEndpoint(
    id: string
  ): Observable<CommonResponseDTO<IEndpointResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.INTEGRATION_ENDPOINTS_DELETE_ONE,
      params: { id },
    });
    return this.http.delete<CommonResponseDTO<IEndpointResponse>>(url);
  }

  public restoreEndpoint(
    id: string
  ): Observable<CommonResponseDTO<IEndpointResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.INTEGRATION_ENDPOINTS_RESTORE_ONE,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IEndpointResponse>>(url, {});
  }

  public verifyEndpoint(
    id: string
  ): Observable<CommonResponseDTO<IEndpointResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.INTEGRATION_ENDPOINTS_VERIFY_ONE,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IEndpointResponse>>(url, {});
  }
}
