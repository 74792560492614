import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';

import { MenuItem } from '../services/side-menu.service';

@Component({
  selector: 'app-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss'],
})
export class MenuItemsComponent {
  @Input() iconOnlyMode = true;
  @Input() menuItems: MenuItem[] = [];
  @Input() searchValue = '';
  @Output() onClicked = new EventEmitter<MenuItem>();
  @Output() onExpanded = new EventEmitter<MenuItem>();

  activeMatchOptions: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    queryParams: 'ignored',
    paths: 'subset',
    fragment: 'exact',
  };

  activeMatchOptionsExact: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    queryParams: 'ignored',
    paths: 'exact',
    fragment: 'exact',
  };

  itemSelect(menuItem: MenuItem) {
    if (this.iconOnlyMode) {
      if (!menuItem.children) {
        this.onClicked.emit(menuItem);
      }
    } else {
      this.onClicked.emit(menuItem);
    }
  }

  filter(str: string) {
    return str.toLowerCase().search(this.searchValue.toLowerCase()) > -1;
  }

  filterMatch(menuItemIndex: number): boolean {
    // return true if list item name matches the filter value
    // or if at least one of the list item's children match the filter value
    return (
      this.filter(this.menuItems[menuItemIndex].face) ||
      this.menuItems[menuItemIndex].children?.some((child) =>
        this.filter(child.face)
      )
    );
  }

  beforeExpand(item: MenuItem) {
    this.onExpanded.emit(item);
    this.menuItems.forEach((menuItem) => {
      if (menuItem !== item) {
        menuItem.expanded = false;
      }
    });
  }

  isVisible(moduleName: string): boolean {
    return !!moduleName;
  }

  bulkFilter(menuItem: MenuItem) {
    let foundStatus = false;
    menuItem.children.forEach((item) => {
      if (this.filter(item.searchKey)) {
        foundStatus = true;
      }
    });
    if (foundStatus && this.searchValue.length > 0) {
      menuItem.expanded = true;
    } else {
      menuItem.expanded = false;
    }
    return foundStatus;
  }

  collapseAll() {
    this.menuItems.forEach((item) => {
      item.expanded = false;
    });
  }
}
