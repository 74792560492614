<mat-accordion cdkDropList class="px-1">
  <mat-expansion-panel
    cdkDrag
    *ngFor="let note of notes"
    hideToggle
    class="mb-1 app-border"
  >
    <mat-expansion-panel-header class="note-header">
      <div class="d-flex align-items-center note-header_title">
        <div cdkDragHandle style="cursor: move">
          <button
            mat-icon-button
            class="note-header_icon skeleton-loader-item"
            disabled
          >
            <mat-icon></mat-icon>
          </button>
        </div>
        <div
          class="px-2 loading-color note-header_title-text skeleton-loader-item"
        ></div>
      </div>
      <div
        class="note-header_date d-flex align-items-center loading-color skeleton-loader-item"
      ></div>
    </mat-expansion-panel-header>
    <p class="ml-2 mb-1 note-expanded-description skeleton-loader-item"></p>
    <p class="ml-2 note-expanded-description skeleton-loader-item"></p>
  </mat-expansion-panel>
</mat-accordion>
