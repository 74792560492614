export enum MONACO_SUPPORTED_LANGUAGES {
  PLAINTEXT = 'plaintext',
  ABAP = 'abap',
  APEX = 'apex',
  AZCLI = 'azcli',
  BAT = 'bat',
  BICEP = 'bicep',
  CAMELIO = 'camelio',
  CLOJURE = 'clojure',
  COFFEESCRIPT = 'coffeescript',
  C = 'c',
  CPP = 'cpp',
  CSHARP = 'csharp',
  CSP = 'csp',
  CSS = 'css',
  CYPHER = 'cypher',
  DART = 'dart',
  DOCKERFILE = 'dockerfile',
  ECL = 'ecl',
  ELIXIR = 'elixir',
  FLOW9 = 'flow9',
  FSHARP = 'fsharp',
  GO = 'go',
  GRAPHQL = 'graphql',
  HTML = 'html',
  INI = 'ini',
  JAVA = 'java',
  JAVASCRIPT = 'javascript',
  KOTLIN = 'kotlin',
  LESS = 'less',
  LUA = 'lua',
  MARKDOWN = 'markdown',
  MYSQL = 'mysql',
  OBJECTIVEC = 'objectivec',
  PASCAL = 'pascal',
  PERL = 'perl',
  PGSQL = 'pgsql',
  PHP = 'php',
  POWERSHELL = 'powershell',
  PYTHON = 'python',
  R = 'r',
  RUBY = 'ruby',
  RUST = 'rust',
  SCALA = 'scala',
  SCSS = 'scss',
  SHELL = 'shell',
  SQL = 'sql',
  TYPESCRIPT = 'typescript',
  VB = 'vb',
  XML = 'xml',
  YAML = 'yaml',
  JSON = 'json',
}
