<mat-card-content
  *ngIf="notices?.length > 0"
  style="overflow-y: scroll; height: fit-content; max-height: 230px"
>
  <div
    matRipple
    [matRippleCentered]="false"
    class="notice-box app-border mt-3 d-flex mr-2"
    *ngFor="let notice of notices"
  >
    <div class="pl-2 title-body-align">
      <div class="title skeleton-loader-item"></div>
      <div class="body skeleton-loader-item"></div>
    </div>
    <div class="pr-2 date-align">
      <small class="notice-box_date skeleton-loader-item"></small>
    </div>
  </div>
</mat-card-content>
