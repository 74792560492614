import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import {
  CommonResponseDTO,
  IImapConfig,
  IMailboxSetupConfig,
  ISmtpConfig,
  MAILBOX_CONFIG_TYPES,
} from '@shared/interfaces'; // Adjust the path as per your project structure

@Injectable({
  providedIn: 'root',
})
export class MailBoxConfigService {
  public readonly dataStore = new BehaviorSubject<IMailboxSetupConfig>(
    undefined
  );

  constructor(private http: HttpClient) {}

  fetchMailboxConfig(): Observable<CommonResponseDTO<IMailboxSetupConfig>> {
    return this.http
      .get<CommonResponseDTO<IMailboxSetupConfig>>(URLS.SETUP_MAILBOX_CONFIG)
      .pipe(
        tap(({ data }) => {
          this.dataStore.next(data);
        })
      );
  }

  testConnection(
    config: ISmtpConfig | IImapConfig,
    type: MAILBOX_CONFIG_TYPES
  ): Observable<CommonResponseDTO<{ isConnected: boolean }>> {
    const data = {
      ...config,
      type,
    };
    return this.http.post<CommonResponseDTO<{ isConnected: boolean }>>(
      URLS.SETUP_MAILBOX_CONFIG_TEST_EMAIL,
      data
    );
  }

  updateSystemMailboxConfig(
    mailboxConfig?: IMailboxSetupConfig
  ): Observable<CommonResponseDTO<IMailboxSetupConfig>> {
    const url = URLS.SETUP_MAILBOX_CONFIG;
    const body = mailboxConfig;
    return this.http
      .put<CommonResponseDTO<IMailboxSetupConfig>>(url, body)
      .pipe(
        tap((res) => {
          this.dataStore.next(res.data);
        })
      );
  }

  // updateSystemMailboxSignature(
  //   body: IMailboxSignature
  // ): Observable<CommonResponseDTO<IMailboxSetupConfig>> {
  //   const url = URLS.SETUP_MAILBOX_CONFIG;
  //   return this.http
  //     .put<CommonResponseDTO<IMailboxSetupConfig>>(url, body)
  //     .pipe(
  //       tap((res) => {
  //         this.dataStore.next(res.data);
  //       })
  //     );
  // }
}
