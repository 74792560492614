import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { TemplateResponseDTO } from '../../services/template.service';

import { AddNewTemplateComponent } from './add-new-template/add-new-template.component';
import { MergeTagsEditorComponent } from './merge-tags-editor/merge-tags-editor.component';

const EDITOR_DIALOG_CONFIGS = {
  width: `${window.screen.width}px`,
  height: `${window.screen.height}px`,
  disableClose: true,
};

@Injectable({ providedIn: 'root' })
export class TemplateMergeTagsService {
  private _dataStore = new BehaviorSubject<TemplateResponseDTO | null>(null);
  public readonly dataStore = this._dataStore.asObservable();

  constructor(private dialog: MatDialog) {}

  async openEditor(
    template?: TemplateResponseDTO
  ): Promise<TemplateResponseDTO> {
    return new Promise((resolve) =>
      this.openAddNewTemplateDialog(template, resolve)
    );
  }

  private openAddNewTemplateDialog(
    template: TemplateResponseDTO,
    resolve: (template?: TemplateResponseDTO) => void
  ) {
    this._dataStore.next(template || null);

    const dialogConfig: MatDialogConfig = {
      ...EDITOR_DIALOG_CONFIGS,
      data: template,
    };
    firstValueFrom(
      this.dialog.open(AddNewTemplateComponent, dialogConfig).afterClosed()
    ).then((dialogData: { template: TemplateResponseDTO; saved: boolean }) => {
      if (dialogData) {
        if (dialogData.saved) {
          this._dataStore.next(null);
          resolve(dialogData.template);
        } else {
          this.openMergeTagEditorDialog(dialogData.template, resolve);
        }
      } else {
        resolve();
      }
    });
  }

  private openMergeTagEditorDialog(
    template: TemplateResponseDTO,
    resolve: (template?: TemplateResponseDTO) => void
  ) {
    this._dataStore.next(template);

    firstValueFrom(
      this.dialog
        .open(MergeTagsEditorComponent, EDITOR_DIALOG_CONFIGS)
        .afterClosed()
    ).then((merge_tags: object) => {
      const template = this._dataStore.value;

      if (merge_tags) {
        template.merge_tags = merge_tags;
      }

      this.openAddNewTemplateDialog(template, resolve);
    });
  }
}
