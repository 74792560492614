import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Color } from '@angular-material-components/color-picker';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { hexToRgb } from '@shared/utils';

import { noWhitespaceValidator } from '../../../../modules/core/helpers/form-field-white-space-validator';
import { SystemDataService } from '../../../../modules/setup/general/services/system-data.service';
import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../../../services/snackbar.service';
import { PostItNotesService } from '../../services/post-it-notes.service';

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss'],
})
export class CreateNoteComponent implements OnInit, OnDestroy {
  isLoading = true;

  createNoteForm = new UntypedFormGroup({
    title: new UntypedFormControl(null, [
      Validators.required,
      noWhitespaceValidator,
    ]),
    start_date: new UntypedFormControl(new Date(), Validators.required),
    end_date: new UntypedFormControl(null),
    background_color: new UntypedFormControl(null),
    text_color: new UntypedFormControl(null),
    description: new UntypedFormControl(null, [
      Validators.required,
      noWhitespaceValidator,
    ]),
  });

  systemDataSub: Subscription;
  createNoteSub: Subscription;

  constructor(
    private dialogRef: MatDialogRef<CreateNoteComponent>,
    private postItNotesService: PostItNotesService,
    private snackBar: SnackbarService,
    private translate: TranslateService,
    private systemDataService: SystemDataService
  ) {}

  ngOnInit(): void {
    this.createNoteSub = this.createNoteForm.valueChanges.subscribe(
      (change) => {
        const startDate = new Date(change.end_date).setHours(0, 0, 0, 0);
        const endDate = new Date(change.start_date).setHours(0, 0, 0, 0);

        if (startDate < endDate && change.end_date) {
          this.createNoteForm.controls.end_date.setErrors({
            invalidDate: true,
          });
        }
      }
    );

    this.systemDataSub = this.systemDataService.dataStore.subscribe((data) => {
      if (data && typeof data.color_palette !== 'string') {
        const bgColor = hexToRgb(data.color_palette.primaryColor);
        this.createNoteForm.controls.background_color.setValue(
          new Color(bgColor.r, bgColor.g, bgColor.b)
        );
        this.createNoteForm.controls.text_color.setValue(
          new Color(255, 255, 255)
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.systemDataSub.unsubscribe();
    this.createNoteSub.unsubscribe();
  }

  componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  onClickCreate() {
    const text_color: Color = this.createNoteForm.value.text_color;
    const background_color: Color = this.createNoteForm.value.background_color;

    const body = {
      title: this.createNoteForm.value.title,
      start_date: this.createNoteForm.value.start_date,
      end_date: this.createNoteForm.value.end_date,
      background_color: background_color?.toHexString() || undefined,
      text_color: text_color?.toHex8String() || undefined,
      description: this.createNoteForm.value.description,
    };

    this.postItNotesService.createNote(body).subscribe({
      next: (res) => {
        this.dialogRef.close(res.data._id);
        this.snackBar.success(
          SUCCESS_TYPES.CREATED,
          this.translate.instant('pages.post-it-notes.note')
        );
      },
    });
  }
}
