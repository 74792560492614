<div class="header-items">
  <div class="header">
    <mat-card-title>
      <mat-icon class="mr-2">widgets</mat-icon>
      {{ 'widgets.panel.title' | translate }}
    </mat-card-title>

    <mat-card-subtitle>
      {{ 'widgets.panel.subtitle' | translate }}
    </mat-card-subtitle>
  </div>
  <div class="option-select">
    <button mat-stroked-button class="mx-1" (click)="onClickResetLayout()">
      Reset Layout
    </button>

    <button mat-stroked-button>
      <mat-select
        class="select"
        [(value)]="selectedRole"
        (selectionChange)="getRoleBasedUserWidgetLayout(selectedRole)"
      >
        <mat-option *ngFor="let role of roles" [value]="role._id">
          {{ role.role }}
        </mat-option>
      </mat-select>
    </button>

    <button
      mat-button
      mat-icon-button
      aria-label="Close"
      [mat-dialog-close]="false"
      [matTooltip]="'app.targets.configuration.cancel' | translate"
      class="close-button"
    >
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
</div>

<mat-divider></mat-divider>

<div
  mat-dialog-content
  class="d-flex dialog-container"
  style="overflow-y: auto"
>
  <app-widget-menu
    style="overflow-y: auto"
    [widgets]="widgets"
    [isDisabled]="isDisabled"
    (onAdd)="onAddWidget($event)"
    (onRemove)="onRemoveWidget($event)"
  ></app-widget-menu>

  <mat-divider [vertical]="true" class="mr-3"></mat-divider>

  <div class="w-100 py-3" style="overflow-y: auto">
    <app-dynamic-container
      style="overflow-y: auto"
      [widgets]="widgets"
      [isDisabled]="isDisabled"
      [layout]="currentLayout"
      (updatedLayout)="onChangeLayout($event)"
    ></app-dynamic-container>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ 'widgets.panel.cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="isDisabled"
    cdkFocusInitial
    (click)="onSave()"
  >
    {{ 'widgets.panel.save' | translate }}
  </button>
</mat-dialog-actions>
