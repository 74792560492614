import { Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-notices',
  templateUrl: './skeleton-notices.component.html',
  styleUrls: ['./skeleton-notices.component.scss'],
})
export class SkeletonNoticesComponent {
  notices = [1, 2];
}
