import { Component } from '@angular/core';

@Component({
  selector: 'app-widgets-container-skeleton',
  templateUrl: './widget-container-skeleton.component.html',
  styleUrls: ['./widget-container-skeleton.component.scss'],
})
export class WidgetContainerSkeletonComponent {
  widgets = [1, 2, 3, 4];
}
