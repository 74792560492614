import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Types } from 'mongoose';
import { from, mergeMap, Observable } from 'rxjs';

import { ENDPOINTS, STORAGE_QUOTA_UNIT, URLS } from '@shared/constants';
import {
  CommonResponseDTO,
  IBaseEntity,
  IConfigurableFieldValueRequest,
  IConnectedAppResponse,
  IIdentityResponse,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

export interface IConnectionField {
  field_id: string;
  value: any;

  // TODO:@lathes need to handle from the backend aggregation
  // field?: IConfigurableFieldConfig; // aggregated
}

export interface IConnectionResponse<AppType, IdentityType>
  extends IBaseEntity {
  _id?: string;
  identity: IdentityType;
  app: AppType;
  role?: Types.ObjectId;
  role_name: string;
  full_name?: string;
  permissions: string[];
  data_fields: IConnectionField[];
  effective_from_date: Date;
  expire_on_date?: Date;
  is_active: boolean;
  custom_quota?: number;
  custom_quota_unit?: STORAGE_QUOTA_UNIT;
}

export interface UserConnectionRequestDTO {
  _id?: string;
  app: string;
  fields: IConfigurableFieldValueRequest[];
}

export class ConnectionRequestDTO {
  _id?: string;
  identity: string;
  app: string;
  role?: Types.ObjectId;
  role_name: string;
  permissions: string[];
  external_fields?: IConnectionField[];
  internal_fields?: IConfigurableFieldValueRequest[];
  effective_from_date: Date;
  expire_on_date?: Date;
  is_active: boolean;
}

export interface FilteredConnectionIdentitiesWithRolesDTO {
  identity: IIdentityResponse;
  role_name: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionsService {
  constructor(private http: HttpClient) {}

  getAllConnections(
    params?: HttpParams
  ): Observable<
    CommonResponseDTO<
      IConnectionResponse<IConnectedAppResponse, IIdentityResponse>[]
    >
  > {
    //api/connections/external-info
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTIONS_GET_ALL,
    });
    return this.http.get<
      CommonResponseDTO<
        IConnectionResponse<IConnectedAppResponse, IIdentityResponse>[]
      >
    >(url, { params });
  }

  createConnections(
    connections: ConnectionRequestDTO[]
  ): Observable<
    CommonResponseDTO<
      IConnectionResponse<IConnectedAppResponse, IIdentityResponse>
    >
  > {
    const url = generateURL({
      endpoint: ENDPOINTS.CONNECTIONS_GET_ALL,
    });
    return from(connections).pipe(
      mergeMap((connection) => {
        return this.http.post<
          CommonResponseDTO<
            IConnectionResponse<IConnectedAppResponse, IIdentityResponse>
          >
        >(url, connection);
      })
    );
  }

  updateConnections(
    connections: ConnectionRequestDTO[]
  ): Observable<
    CommonResponseDTO<
      IConnectionResponse<IConnectedAppResponse, IIdentityResponse>
    >
  > {
    return from(connections).pipe(
      mergeMap((connection) => {
        const url = generateURL({
          endpoint: ENDPOINTS.CONNECTIONS_UPDATE,
          params: { id: connection._id },
        });
        return this.http.put<
          CommonResponseDTO<
            IConnectionResponse<IConnectedAppResponse, IIdentityResponse>
          >
        >(url, connection);
      })
    );
  }

  updateUserConnections(
    connection: UserConnectionRequestDTO
  ): Observable<CommonResponseDTO<IConnectionResponse<string, string>>> {
    const url = `${URLS.CONNECTIONS}/${connection._id}/user`;
    return this.http.put<
      CommonResponseDTO<IConnectionResponse<string, string>>
    >(url, connection);
  }

  changeStatusOfUserConnection(
    id: string
  ): Observable<
    CommonResponseDTO<
      IConnectionResponse<IConnectedAppResponse, IIdentityResponse>
    >
  > {
    const url = `${URLS.CONNECTIONS}/${id}`;
    return this.http.patch<
      CommonResponseDTO<
        IConnectionResponse<IConnectedAppResponse, IIdentityResponse>
      >
    >(url, {});
  }

  deleteConnection(
    id: string
  ): Observable<
    CommonResponseDTO<
      IConnectionResponse<IConnectedAppResponse, IIdentityResponse>
    >
  > {
    const url = `${URLS.CONNECTIONS}/${id}`;
    return this.http.delete<
      CommonResponseDTO<
        IConnectionResponse<IConnectedAppResponse, IIdentityResponse>
      >
    >(url);
  }

  getStorageConfig(params: any) {
    this.http.get(`${URLS.CONNECTIONS}/${params}`);
  }
}
