<mat-nav-list class="sidenav-list" tabindex="0">
  <mat-card class="menu-header">
    <img
      fetchpriority="high"
      class="single-logo"
      *ngIf="colorSchemeService.currentActive() === 'light' && !iconOnlyMode"
      src="{{ clientInformation?.logo_path_light }}"
      (error)="logoErrorHandler($event)"
      alt="org-logo"
    />
    <img
      fetchpriority="high"
      class="single-logo"
      *ngIf="colorSchemeService.currentActive() === 'dark' && !iconOnlyMode"
      src="{{ clientInformation?.logo_path_dark }}"
      (error)="logoErrorHandler($event)"
      alt="org-logo"
    />

    <mat-list-item
      class="dashboard__sidenav-list_item"
      [routerLinkActive]="['accent-color']"
      routerLink="/app/launchpad"
      [routerLinkActiveOptions]="activeMatchOptions"
      (mwlClick)="onClickLaunchPad()"
    >
      <mat-icon
        [ngStyle]="{ 'padding-left': !iconOnlyMode ? '16px' : '21px' }"
        matListIcon
      >
        dashboard
      </mat-icon>
      <h1 style="padding-top: 2px" *ngIf="!iconOnlyMode" matLine>
        {{ 'pages.dashboard.side-menu.dashboard' | translate }}
      </h1>
    </mat-list-item>

    <!-- ----------------------------- Side panel Search ----------------------------------- -->
    <mat-divider></mat-divider>
    <div
      *ngIf="!iconOnlyMode"
      class="no-padding pr-1 pl-1 d-flex my-2 justify-content-center"
      style="font-size: 12px"
    >
      <div class="side-menu-search-chip">
        <input
          [placeholder]="'pages.home.filter' | translate"
          [(ngModel)]="searchValue"
          type="text"
        />
        <div class="search-icons">
          <span matSuffix>
            <mat-icon class="prefix-icon">filter_list</mat-icon>
          </span>
          <button
            class="close-btn"
            aria-label="Clear"
            (click)="searchValue = ''"
            [ngClass]="{ show: searchValue }"
          >
            <mat-icon class="prefix-icon">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider *ngIf="!iconOnlyMode"></mat-divider>
  </mat-card>
  <div class="menu-container">
    <mat-list [ngClass]="{ 'menu-list': !iconOnlyMode }">
      <app-menu-items
        [iconOnlyMode]="iconOnlyMode"
        [menuItems]="menuItems"
        [searchValue]="searchValue"
        (onClicked)="itemSelect($event)"
        (onExpanded)="itemExpanded($event)"
      ></app-menu-items>
    </mat-list>
  </div>
</mat-nav-list>
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
