<div class="align-items-center justify-content-between d-flex">
  <div>
    <h1 mat-dialog-title class="d-flex align-items-center mb-0">
      <mat-icon class="mr-2">smartphone</mat-icon>
      {{ 'configuration-fields.set-contact-number.title' | translate }}
    </h1>
    <mat-card-subtitle
      >{{ 'configuration-fields.set-contact-number.subtitle' | translate }}
    </mat-card-subtitle>
  </div>
  <div class="mb-2">
    <button
      type="button"
      (click)="addNewContact()"
      mat-stroked-button
      id="configuration_fields_btn_add_contact_no"
      data-testid="configuration_fields_btn_add_contact_no"
      [matTooltip]="
        'configuration-fields.set-contact-number.add-new-btn' | translate
      "
      (focus)="contactNumberType.focus()"
    >
      +&nbsp;{{
        'configuration-fields.set-contact-number.add-new-btn' | translate
      }}
    </button>
  </div>
</div>

<mat-divider></mat-divider>

<mat-dialog-content class="contact-number mt-4">
  <div *ngFor="let contactNumber of contactNumbers; let i = index">
    <form [formGroup]="contactNumber">
      <ng-container>
        <div class="row">
          <div class="col-12">
            <mat-radio-group formControlName="is_primary">
              <mat-radio-button
                [attr.data-testid]="'configuration_fields_radio_primary_' + i"
                class="pt-3"
                color="primary"
                [value]="true"
                (click)="$event.stopPropagation(); radioChange(i)"
                >{{
                  'configuration-fields.set-contact-number.use-primary'
                    | translate
                }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row d-flex align-items-center pb-2">
          <div class="col-md-auto justify-content-start">
            <mat-form-field
              style="width: 100%; font-size: 14px"
              appearance="fill"
            >
              <mat-label>{{
                'configuration-fields.set-contact-number.type' | translate
              }}</mat-label>
              <mat-select
                #contactNumberType
                formControlName="type"
                id="configuration_fields_sel_phone_type"
                data-testid="configuration_fields_sel_phone_type"
              >
                <mat-option
                  id="configuration_fields_sel_phone_type_{{
                    CONTACT_NUMBER_TYPES.MOBILE.toLowerCase()
                  }}"
                  attr.data-testid="configuration_fields_sel_phone_type_{{
                    CONTACT_NUMBER_TYPES.MOBILE.toLowerCase()
                  }}"
                  [value]="CONTACT_NUMBER_TYPES.MOBILE"
                  >{{
                    'configuration-fields.set-contact-number.contact-types.mobile'
                      | translate
                  }}</mat-option
                >
                <mat-option
                  id="configuration_fields_sel_phone_type_{{
                    CONTACT_NUMBER_TYPES.HOME.toLowerCase()
                  }}"
                  attr.data-testid="configuration_fields_sel_phone_type_{{
                    CONTACT_NUMBER_TYPES.HOME.toLowerCase()
                  }}"
                  [value]="CONTACT_NUMBER_TYPES.HOME"
                  >{{
                    'configuration-fields.set-contact-number.contact-types.home'
                      | translate
                  }}</mat-option
                >
              </mat-select>
              <mat-error>{{
                'configuration-fields.set-contact-number.type-error' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col justify-content-end">
            <el-phone-number-input
              class="w-100"
              dataTestId="configuration_fields_input_phone_no"
              [value]="contactNumber.get('phone_number')?.value"
              (onChange)="handleOnChangePhoneNumber($event, i)"
              [required]="true"
            ></el-phone-number-input>
          </div>
          <div
            class="d-flex justify-content-center"
            style="overflow: visible; padding-right: 30px"
          >
            <button
              class="mb-2 center"
              mat-icon-button
              [disabled]="contactNumber.get('is_primary').value === true"
              (click)="onDeleteClicked(i)"
              id="configuration_fields_btn_delete_contact"
              data-testid="configuration_fields_btn_delete_contact"
              [matTooltip]="
                'configuration-fields.set-contact-number.delete-btn' | translate
              "
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>

        <mat-divider *ngIf="i !== contactNumbers.length - 1"></mat-divider>
      </ng-container>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  class="d-flex justify-content-end"
  style="overflow: visible"
>
  <button
    mat-button
    [mat-dialog-close]="false"
    id="configuration_fields_btn_set_contact_cancel"
    data-testid="configuration_fields_btn_set_contact_cancel"
    [matTooltip]="
      'configuration-fields.set-contact-number.btn-cancel' | translate
    "
  >
    {{ 'configuration-fields.set-contact-number.btn-cancel' | translate }}
  </button>
  <button
    type="button"
    (click)="clearAll()"
    mat-stroked-button
    id="configuration_fields_btn_set_contact_clear"
    data-testid="configuration_fields_btn_set_contact_clear"
    [matTooltip]="
      'configuration-fields.set-contact-number.clear-all-btn' | translate
    "
  >
    {{ 'configuration-fields.set-contact-number.clear-all-btn' | translate }}
  </button>
  <button
    [disabled]="!isValid()"
    type="submit"
    mat-raised-button
    (click)="onSaveClicked()"
    color="primary"
    class="m-2"
    id="configuration_fields_btn_set_contact_save"
    data-testid="configuration_fields_btn_set_contact_save"
    [matTooltip]="
      'configuration-fields.set-contact-number.btn-save' | translate
    "
  >
    {{ 'configuration-fields.set-contact-number.btn-save' | translate }}
  </button>
</mat-dialog-actions>
