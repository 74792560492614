<div *ngIf="isLoading" class="loading-overlay special-position">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<mat-card-title mat-dialog-title class="d-flex align-items-center mb-0"
  ><mat-icon class="mr-2">link</mat-icon>
  {{ title }}
</mat-card-title>
<mat-card-subtitle>{{
  'references.add-new-reference-category.main-subtitle' | translate
}}</mat-card-subtitle>
<mat-divider></mat-divider>
<mat-dialog-content class="main-container">
  <div class="mt-3 align-items-center d-flex">
    <div>
      <h6 class="py-1 mb-0">
        {{
          'references.add-new-reference-category.main-sub-heading' | translate
        }}
      </h6>
      <mat-card-subtitle>{{
        'references.add-new-reference-category.sub-subtitle' | translate
      }}</mat-card-subtitle>
    </div>
  </div>
  <mat-divider style="margin-bottom: 20px"></mat-divider>
  <mat-dialog-content>
    <form [formGroup]="referenceCategoryFormGroup">
      <mat-form-field appearance="fill" class="main-form-field">
        <mat-label>{{
          'references.add-new-reference-category.section-label' | translate
        }}</mat-label>
        <input
          type="text"
          placeholder="{{
            'references.add-new-reference-category.section-placeholder'
              | translate
          }}"
          matInput
          formControlName="section"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            *ngFor="let section of filteredSectionList | async"
            [value]="section"
          >
            {{ section }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="fill" class="main-form-field">
        <mat-label>{{
          'references.add-new-reference-category.name-label' | translate
        }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="category_name"
          [disabled]="hasChildren"
          required
          (blur)="onCheckCategoryAdd()"
          placeholder="{{
            'references.add-new-reference-category.name-placeholder' | translate
          }}"
        />
        <mat-error
          *ngIf="
            referenceCategoryFormGroup.get('category_name').hasError('required')
          "
          >{{
            'references.add-new-reference-category.name-error-hint' | translate
          }}</mat-error
        >

        <mat-error
          *ngIf="
            referenceCategoryFormGroup.get('category_name').hasError('sameName')
          "
        >
          {{
            'references.add-new-reference-category.category-already-exist'
              | translate
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="main-form-field">
        <mat-label>{{
          'references.add-new-reference-category.description-label' | translate
        }}</mat-label>
        <textarea
          matInput
          formControlName="category_description"
          [disabled]="hasChildren"
          required
          placeholder="{{
            'references.add-new-reference-category.description-placeholder'
              | translate
          }}"
        >
        </textarea>
        <mat-error>{{
          'references.add-new-reference-category.description-error-hint'
            | translate
        }}</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <form [formGroup]="referenceCategoryFormGroup">
    <div>
      <mat-checkbox formControlName="publicReference" color="primary">
        {{
          'references.add-new-reference-category.public-reference-check-box-description'
            | translate
        }}
      </mat-checkbox>
    </div>
  </form>

  <mat-card-content>
    <form [formGroup]="referenceCategoryFormGroup">
      <div class="mt-3 align-items-center d-flex">
        <div>
          <h6 class="py-1 mb-0">
            {{
              'references.add-new-reference-category.help-and-support'
                | translate
            }}
          </h6>
          <mat-card-subtitle>{{
            'references.add-new-reference-category.help-and-support-subtitle'
              | translate
          }}</mat-card-subtitle>
        </div>
        <span style="flex: 1 1 auto"></span>
        <div class="ml-3">
          <button (click)="addSection()" mat-stroked-button>
            +&nbsp;{{
              'references.add-new-reference-category.add-new-section'
                | translate
            }}
          </button>
        </div>
      </div>
      <mat-divider style="margin-bottom: 15px"></mat-divider>
      <div class="container px-0" formArrayName="helpAndSupport">
        <ng-container
          *ngFor="let helpFG of helpAndSupportFormArray; let i = index"
          [formGroup]="helpFG"
        >
          <mat-divider *ngIf="i > 0" style="margin-bottom: 15px"></mat-divider>

          <div class="row" style="padding-top: 10px">
            <div class="col" style="flex: 11">
              <mat-form-field
                class="mb-2"
                appearance="fill"
                style="width: 100%"
              >
                <mat-label>{{
                  'references.add-new-reference-category.title-placeholder'
                    | translate
                }}</mat-label>
                <input
                  type="text"
                  #titleField
                  matInput
                  placeholder="{{
                    'references.add-new-reference-category.title-placeholder'
                      | translate
                  }}"
                  formControlName="help_and_support_title"
                  required
                />
                <mat-error>{{
                  'references.add-new-reference-category.title-error-hint'
                    | translate
                }}</mat-error>
              </mat-form-field>

              <div class="row pt-2 mt-2 pb-2">
                <div class="col-12">
                  {{
                    'references.add-new-reference-category.description'
                      | translate
                  }}
                </div>
              </div>
              <div class="mt-1 mb-3">
                <ckeditor
                  class="cke_contents"
                  #ckeEditor
                  [editor]="editor"
                  [config]="editorConfig"
                  formControlName="help_and_support_description"
                  style="width: 100%"
                  required
                ></ckeditor>
                <mat-error
                  *ngIf="
                    helpFG.get('help_and_support_description').touched &&
                    helpFG
                      .get('help_and_support_description')
                      .hasError('required')
                  "
                  class="message-error ml-2 mt-1 smaller-text"
                  >{{
                    'references.add-new-reference-category.description-error-hint'
                      | translate
                  }}
                </mat-error>
              </div>
            </div>
            <div
              style="
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin-top: 8px;
              "
            >
              <button
                *ngIf="i > 0"
                (click)="removeHelpAndSupport(i)"
                mat-icon-button
                [disabled]="isLoading"
                [matTooltip]="'integrations.delete' | translate"
                type="button"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </mat-card-content>

  <div>
    <div class="mt-3 align-items-center d-flex">
      <div>
        <h6 class="py-1 mb-0">
          {{
            'references.add-new-reference-category.second-sub-heading'
              | translate
          }}
        </h6>
        <mat-card-subtitle>{{
          'references.add-new-reference-category.field-subheading' | translate
        }}</mat-card-subtitle>
      </div>
      <span style="flex: 1 1 auto"></span>
      <div class="ml-3">
        <button (click)="addForm()" mat-stroked-button [disabled]="hasChildren">
          +&nbsp;{{
            'references.add-new-reference-category.add-new-field' | translate
          }}
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <form class="">
      <div class="container px-0">
        <app-configure-configurable-field
          [fields]="fields"
          [disabled]="hasChildren"
          [fieldTypesOptions]="fieldTypesOptions"
          [activeReferenceCategories]="activeReferenceCategories"
          [referenceCategory]="referenceCategory"
          [editMode]="editMode"
          [collectKeyFromUser]="false"
          (onCheckValidity)="checkValidity()"
          (onClickCheckBox)="onClickCheckBox($event)"
          (onSetReferenceValues)="setReferenceValues($event)"
          (onFieldDeleted)="onFieldDeleted()"
          [isDynamicStyles]="true"
          [disableUniqueFields]="true"
        >
        </app-configure-configurable-field>
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">
    {{ 'references.add-new-reference-category.close' | translate }}
  </button>
  <button
    mat-raised-button
    (click)="save()"
    cdkFocusInitial
    color="primary"
    [disabled]="
      !formDataChanges ||
      referenceCategoryFormGroup.get('category_name').invalid ||
      referenceCategoryFormGroup.get('category_description').invalid ||
      referenceCategoryFormGroup.get('section').invalid ||
      referenceCategoryFormGroup.get('helpAndSupport').invalid ||
      (isAllFieldPristine &&
        isAllCheckBoxesPristine &&
        referenceCategoryFormGroup.get('section').pristine &&
        referenceCategoryFormGroup.get('publicReference').pristine &&
        referenceCategoryFormGroup.get('category_name').pristine &&
        referenceCategoryFormGroup.get('category_description').pristine &&
        referenceCategoryFormGroup.get('helpAndSupport').pristine &&
        !fieldRemoved) ||
      (((referenceCategoryFormGroup.get('reference_type').value ===
        REFERENCE_CATEGORY_TYPES.INTERNAL &&
        (fields?.length === 0 || isAnyFieldsInvalid)) ||
        (referenceCategoryFormGroup.get('reference_type').value ===
          REFERENCE_CATEGORY_TYPES.EXTERNAL &&
          (referenceCategoryFormGroup.get('connected_system').invalid ||
            referenceCategoryFormGroup.get('connected_category').invalid))) &&
        !hasChildren)
    "
  >
    {{ 'references.add-new-reference-category.save' | translate }}
  </button>
</mat-dialog-actions>
