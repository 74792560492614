<ngx-dropzone
  style="background: none; color: unset; border: 2px dashed; height: 100%"
  (change)="onUpload($event)"
  color="black"
  [multiple]="false"
>
  <ngx-dropzone-label>
    <p><span class="material-icons"> upload </span></p>
    <p>
      {{ 'shared.image-picker.only' | translate }}
      <strong>.*png.*jpg.*jpeg</strong>
      {{ 'shared.image-picker.files' | translate }} <strong>2MB</strong>
      {{ 'shared.image-picker.allow' | translate }}
      <br />
      {{ 'shared.image-picker.size' | translate }} {{ widthSize }}
      {{ 'shared.image-picker.by' | translate }} {{ hightSize }}.
    </p>
    <p class="drag">
      {{ 'shared.image-picker.drag' | translate }} {{ fileName }}
    </p>
  </ngx-dropzone-label>

  <ngx-dropzone-image-preview
    ngProjectAs="ngx-dropzone-preview"
    *ngFor="let file of files"
    [file]="file"
    [removable]="true"
    (removed)="onRemove(file)"
  >
  </ngx-dropzone-image-preview>
</ngx-dropzone>
