import { FORMULA_ELEMENT_TYPES, OPERATOR_TYPES } from '@shared/constants';
import { IFormulaOperatorElement } from '@shared/interfaces';

export const conditionalOperators: IFormulaOperatorElement[] = [
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.CONDITIONAL,
      displayText: 'IF',
      symbol: 'IF',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.CONDITIONAL,
      displayText: 'THEN',
      symbol: 'THEN',
    },
  },
  {
    type: FORMULA_ELEMENT_TYPES.OPERATOR,
    operator: {
      type: OPERATOR_TYPES.CONDITIONAL,
      displayText: 'ELSE',
      symbol: 'ELSE',
    },
  },
];
