import { DLT_DATA_TYPES } from '../constants';
import { DataListTableDataElement } from '../types';

export const getValueFromDataElement = (
  dataElement: DataListTableDataElement
): { key: string; value: string } | undefined => {
  switch (dataElement.type) {
    case undefined:
    case DLT_DATA_TYPES.PLAIN:
    case DLT_DATA_TYPES.CHIP:
    case DLT_DATA_TYPES.LINK:
    case DLT_DATA_TYPES.PROGRESS_BAR:
      return { key: dataElement.key, value: dataElement.value.toString() };
    case DLT_DATA_TYPES.INNER_HTML:
      return { key: dataElement.key, value: dataElement.htmlString };
    case DLT_DATA_TYPES.SEPARATOR: {
      if (dataElement.data) {
        return getValueFromDataElement(dataElement.data);
      }
    }
  }

  return undefined;
};
