import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
} from '@angular/core';

import { ResizeService, SCREEN_SIZE } from '../../services/screen-size.service';

@Component({
  selector: 'app-size-detector',
  templateUrl: './size-detector.component.html',
  styleUrls: ['./size-detector.component.scss'],
})
export class SizeDetectorComponent implements AfterViewInit {
  prefix = 'is-';
  sizes = [
    { id: SCREEN_SIZE.XS, name: 'xs', css: 'd-block d-sm-none' },
    { id: SCREEN_SIZE.SM, name: 'sm', css: 'd-none d-sm-block d-md-none' },
    { id: SCREEN_SIZE.MD, name: 'md', css: 'd-none d-md-block d-lg-none' },
    { id: SCREEN_SIZE.LG, name: 'lg', css: 'd-none d-lg-block d-xl-none' },
    { id: SCREEN_SIZE.XL, name: 'xl', css: 'd-none d-xl-block' },
  ];

  constructor(
    private elementRef: ElementRef,
    private resizeSvc: ResizeService
  ) {}

  @HostListener('window:resize', [])
  onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const currentSize = this.sizes.find((x) => {
      // get the HTML element
      const el = this.elementRef.nativeElement.querySelector(
        `.${this.prefix}${x.id}`
      );

      // check its display property value
      return window.getComputedStyle(el).display !== 'none';
    });

    this.resizeSvc.onResize({
      size: currentSize?.id ?? SCREEN_SIZE.MD,
      innerWidth: window.innerWidth,
    });
  }
}
