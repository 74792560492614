import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { ChipInputSearchHelper } from './helpers/chip-input-search.helper';
import { ChipInputSearchService } from './services/chip-input-search.service';
import { DateChipProperties } from './types/chip-properties/date';
import { FilterSelectChipProperties } from './types/chip-properties/filter-select';
import { NumberChipProperties } from './types/chip-properties/number';
import { SelectChipProperties } from './types/chip-properties/select';
import { TextChipProperties } from './types/chip-properties/text';
import { CIS_CHIP_BEHAVIORS, CIS_CHIP_TYPES } from './constants';
import {
  CISConfig,
  IChipSearchFilterElement,
  IChipsSearchFilterSection,
  ParamsKeyValuePair,
  ParamsKeyValuePairTypeExtended,
} from './types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-chip-input-search',
  templateUrl: './el-chip-input-search.component.html',
  styleUrls: ['./el-chip-input-search.component.scss'],
})
export class ElChipInputSearchComponent implements OnInit, OnDestroy {
  configDefault = {
    titlePrefix: 'Search in',
    searchButtonText: 'Search',
    searchButtonTooltip: 'Perform a search with the given parameters',
    clearButtonTooltip: 'Clear',
    expandTooltip: 'More',
    collapseTooltip: 'Less',
    sectionExpandTooltip: 'Expand section',
    sectionCollapseTooltip: 'Collapse section',
    chipCloseTooltip: 'Remove the chip',
    lockTooltip: 'Cannot remove this chip',
    searchButtonColor: 'primary',
    allOptionsText: 'All',
    dateRangeLabelSuffix: '(From - To)',
  };

  @Input() title = '';
  @Input() config: CISConfig = this.configDefault;

  addOnBlur = true;

  readonly separatorKeysCodes = [ENTER, COMMA];
  readonly chipsBehaviour = CIS_CHIP_BEHAVIORS;
  readonly chipsTypes = CIS_CHIP_TYPES;
  screenWidth: number;
  minWidth = 150;
  textWidth?: number;

  readonly chipCtrl = new FormControl('');
  readonly filteredSections: Observable<IChipsSearchFilterSection[]>;

  allChipsData: IChipsSearchFilterSection[] = [];
  selectedChips: IChipSearchFilterElement[] = [];
  lockChips: IChipSearchFilterElement[] = [];
  mandatoryChips: IChipSearchFilterElement[] = [];
  preSelectedChips: IChipSearchFilterElement[] = [];
  optionalChips: IChipSearchFilterElement[] = [];
  chainingParamsChips: IChipSearchFilterElement[] = [];
  defaultChips: IChipSearchFilterElement[] = [];

  loading = false;
  closeButtonAvailable = false;
  panelClose: boolean[] = [];
  originalValues: ParamsKeyValuePairTypeExtended[] = [];
  onDestroy$ = new Subject();
  selectedChipsCtrl = new FormControl();
  filterValues: ParamsKeyValuePair[] = [];

  @ViewChild('chipInput') chipInput?: ElementRef<HTMLInputElement>;
  @ViewChild('autosize') autosize?: CdkTextareaAutosize;

  constructor(
    private chipInputSearchService: ChipInputSearchService,
    private chipInputSearchHelper: ChipInputSearchHelper,
    private cd: ChangeDetectorRef,
    private _ngZone: NgZone
  ) {
    this.filteredSections = this.chipCtrl.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      startWith(''),
      debounceTime(200),
      map((chipName: string | null) =>
        chipName ? this._filter(chipName) : this._removeItemFromList()
      )
    );
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
  }

  @HostListener('window:resize')
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('keypress', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.onClickSearch();
    }
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.autosize?.resizeToFitContent(true));
  }

  ngOnInit() {
    this.bindWithChipSearchFilterService();
    this.cd.detectChanges();
    this.onParamsChange();

    this.config = { ...this.configDefault, ...this.config };
  }

  onParamsChange() {
    this.chipInputSearchService.onParamsChanged
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.chipInputSearchService.loading) {
          this.resetChipsArrays();
          this.bindWithChipSearchFilterService();
        }
      });
  }

  remove(chip: IChipSearchFilterElement) {
    const index = this.selectedChips.indexOf(chip);
    chip = this.initChipsOriginalValues(chip);

    this.selectedChips.splice(index, 1);
    this.chipCtrl.setValue('');

    if (chip.dependable_keys?.length) {
      const dependableChipIndex = this.selectedChips.findIndex((chip) =>
        this.selectedChips.filter((_chip) =>
          this.chipInputSearchHelper.checkForChipKeyInclusive(
            chip,
            _chip.dependable_keys
          )
        )
      );
      this.selectedChips.splice(dependableChipIndex, 1);

      this.filteredSections
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((filteredSections) => {
          filteredSections.forEach((sections) => {
            const found = sections.filters.find((filter) =>
              this.chipInputSearchHelper.checkForChipKeyInclusive(
                filter,
                chip?.dependable_keys
              )
            );

            if (found) {
              found.dependableChipEnabled = false;
            }
          });
        });

      this._changeDependedChipValue(chip);
    }
  }

  private initChipsOriginalValues(
    chipDataFilter: IChipSearchFilterElement
  ): IChipSearchFilterElement {
    switch (chipDataFilter.chipProperties.type) {
      case CIS_CHIP_TYPES.DATE_RANGE: {
        const chipProperties = chipDataFilter.chipProperties;
        chipDataFilter.chipProperties.minValue = undefined;
        chipDataFilter.chipProperties.maxValue = undefined;
        const dateRangeOriginalValues = this.originalValues.filter(
          (value) => value.type === CIS_CHIP_TYPES.DATE_RANGE
        );
        const chipValMin = dateRangeOriginalValues.find(
          (value) => value.key === chipProperties.minKey
        );
        const chipValMax = dateRangeOriginalValues.find(
          (value) => value.key === chipProperties.maxKey
        );

        if (chipValMin)
          chipDataFilter.chipProperties.minValue = chipValMin.value as Date;
        if (chipValMax)
          chipDataFilter.chipProperties.maxValue = chipValMax.value as Date;
        break;
      }
      case CIS_CHIP_TYPES.NUMBER_RANGE: {
        const chipProperties = chipDataFilter.chipProperties;
        chipDataFilter.chipProperties.minValue = undefined;
        chipDataFilter.chipProperties.maxValue = undefined;
        const dateRangeOriginalValues = this.originalValues.filter(
          (value) => value.type === CIS_CHIP_TYPES.NUMBER_RANGE
        );
        const chipValMin = dateRangeOriginalValues.find(
          (value) => value.key === chipProperties.minKey
        );
        const chipValMax = dateRangeOriginalValues.find(
          (value) => value.key === chipProperties.maxKey
        );

        if (chipValMin)
          chipDataFilter.chipProperties.minValue = chipValMin.value as number;
        if (chipValMax)
          chipDataFilter.chipProperties.maxValue = chipValMax.value as number;
        break;
      }
      case CIS_CHIP_TYPES.TEXT: {
        const chipProperties =
          chipDataFilter.chipProperties as TextChipProperties;
        chipDataFilter.chipProperties.value = undefined;
        const dateRangeOriginalValues = this.originalValues.filter(
          (value) => value.type === CIS_CHIP_TYPES.TEXT
        );
        const chipVal = dateRangeOriginalValues.find(
          (value) => value.key === chipProperties.key
        );

        if (chipVal)
          chipDataFilter.chipProperties.value = chipVal.value as string;
        break;
      }
      // This is not a mistake. Two cases below can be merged.
      case CIS_CHIP_TYPES.SLIDER:
      case CIS_CHIP_TYPES.NUMBER: {
        const chipProperties =
          chipDataFilter.chipProperties as NumberChipProperties;
        chipDataFilter.chipProperties.value = undefined;
        const dateRangeOriginalValues = this.originalValues.filter(
          (value) => value.type === CIS_CHIP_TYPES.TEXT
        );
        const chipVal = dateRangeOriginalValues.find(
          (value) => value.key === chipProperties.key
        );

        if (chipVal)
          chipDataFilter.chipProperties.value = chipVal.value as number;
        break;
      }
      case CIS_CHIP_TYPES.DATE: {
        const chipProperties =
          chipDataFilter.chipProperties as DateChipProperties;
        chipDataFilter.chipProperties.value = undefined;
        const dateRangeOriginalValues = this.originalValues.filter(
          (value) => value.type === CIS_CHIP_TYPES.TEXT
        );
        const chipVal = dateRangeOriginalValues.find(
          (value) => value.key === chipProperties.key
        );

        if (chipVal)
          chipDataFilter.chipProperties.value = chipVal.value as Date;
        break;
      }
      // This is not a mistake. Two cases below can be merged.
      case CIS_CHIP_TYPES.AUTOCOMPLETE:
      case CIS_CHIP_TYPES.SELECT: {
        const chipProperties =
          chipDataFilter.chipProperties as SelectChipProperties;
        // Added value reset since it is not resetting to initial value.
        chipDataFilter.chipProperties.value = undefined;
        const dateRangeOriginalValues = this.originalValues.filter(
          (value) => value.type === CIS_CHIP_TYPES.TEXT
        );
        const chipVal = dateRangeOriginalValues.find(
          (value) => value.key === chipProperties.key
        );

        if (chipVal)
          chipDataFilter.chipProperties.value = chipVal.value as string;

        break;
      }
      case CIS_CHIP_TYPES.FILTER_SELECT: {
        const chipProperties =
          chipDataFilter.chipProperties as FilterSelectChipProperties;
        chipDataFilter.chipProperties.value = undefined;
        const dateRangeOriginalValues = this.originalValues.filter(
          (value) => value.type === CIS_CHIP_TYPES.TEXT
        );
        const chipVal = dateRangeOriginalValues.find(
          (value) => value.key === chipProperties.key
        );

        if (chipVal)
          chipDataFilter.chipProperties.value = chipVal.value as string;
        break;
      }
    }

    return chipDataFilter;
  }

  onClickSearch() {
    this.selectedChips = this.selectedChips.filter((chip) =>
      this.chipInputSearchHelper.chipHasValue(chip)
    );
    this.chipCtrl.setValue('');
    this.filterValues = this.chipInputSearchService.convertChipsToFilterValues(
      this.selectedChips
    );

    const searchWithoutChips =
      this.chipInputSearchService.searchWithoutChips.getValue();

    // filter from value entered on search bar without chips
    if (searchWithoutChips)
      this.filterValues.push({
        key: 'search_key',
        value: this.chipInput?.nativeElement.value,
      });

    this.bindWithChipSearchFilterService();

    this.chipInputSearchService.filterValues.next(this.filterValues);
    this.chipInputSearchService.isLoading.next(true);
    this.chipInputSearchService.onSearchClicked.next();
  }

  editedSelectedChipsValues(chip: IChipSearchFilterElement) {
    if (
      chip?.dependable_keys?.length &&
      this.chipInputSearchHelper.chipHasValue(chip)
    ) {
      this.filteredSections
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((filteredSections) => {
          filteredSections.forEach((sections) => {
            const found = sections.filters.find((filter) =>
              this.chipInputSearchHelper.checkForChipKeyInclusive(
                filter,
                chip?.dependable_keys
              )
            );
            if (found) {
              found.dependableChipEnabled = true;
            }
          });
        });
    }

    const index = this.selectedChips.indexOf(chip);
    this.selectedChips[index] = chip;
    if (
      this.chipInputSearchHelper.chipHasValue(chip) &&
      chip.dependable_keys?.length
    ) {
      this._changeDependedChipValue(chip);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _changeDependedChipValue(chip: IChipSearchFilterElement) {
    // TODO: Sampath. Fix this
    // let chips = this.selectedChips.filter((selectedChip) =>
    //   this.checkForDependableKeyInclusive(selectedChip, chip.dependable_keys)
    // );
    // if (chips.length > 0) {
    //   chips[0] = this.chipInputSearchService.setOptionsToChip(
    //     chips[0],
    //     chip.chipProperties.value?.toString()
    //   );
    //   const index = this.selectedChips.indexOf(chips[0]);
    //   this.selectedChips[index] = chips[0];
    //   this.chipInputSearchService.dependableChip.next(chips[0]);
    // } else {
    //   (chip.dependable_keys ?? []).forEach((key) => {
    //     const sections = this.allChipsData.filter((section) =>
    //       section.filters.filter((_chip) => _chip.chipProperties.key === key)
    //     );
    //     chips = sections[0].filters.filter(
    //       (_chip) => _chip.chipProperties.key === key
    //     );
    //     chips[0] = this.chipInputSearchService.setOptionsToChip(
    //       chips[0],
    //       chip.chipProperties.value?.toString()
    //     );
    //     const index = sections[0].filters.indexOf(chips[0]);
    //     sections[0].filters[index] = chips[0];
    //     const index1 = this.allChipsData.indexOf(sections[0]);
    //     this.allChipsData[index1] = sections[0];
    //   });
    // }
  }

  toggle(trigger: MatAutocompleteTrigger) {
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
      this.chipInput?.nativeElement.focus();
    }
  }

  private getSelectedChipsKeys(): string[] {
    const keys: string[] = [];

    this.selectedChips.forEach((selectedChip) => {
      this.chipInputSearchHelper
        .getChipKeyValues(selectedChip)
        .forEach((keyValuePair) => {
          keys.push(keyValuePair.key);
        });
    });

    return keys;
  }

  selected(chip: IChipSearchFilterElement) {
    if (
      !this.chipInputSearchHelper.checkForChipKeyInclusive(
        chip,
        this.getSelectedChipsKeys()
      )
    ) {
      this.selectedChips.push(chip);
      this.chipCtrl.setValue('');
    }
  }

  private _filter(value: string): IChipsSearchFilterSection[] {
    const filterValue = value.toLowerCase();
    const selectedChipsKeys: string[] = [];
    this.selectedChips.forEach((selectedChip) => {
      const keys = this.chipInputSearchHelper
        .getChipKeyValues(selectedChip)
        .map((keyValue) => keyValue.key);
      selectedChipsKeys.push(...keys);
    });
    return this.allChipsData.reduce((acc, curr) => {
      const sectionChips = curr.filters.filter(
        (chip) =>
          chip.name.toLowerCase().includes(filterValue) &&
          !this.chipInputSearchHelper.checkForChipKeyInclusive(
            chip,
            selectedChipsKeys
          ) &&
          chip.behavior !== CIS_CHIP_BEHAVIORS.CHAINING_PARAMS
      );
      if (sectionChips.length > 0) {
        acc.push({
          section: curr.section,
          filters: sectionChips,
        });
      }

      return acc;
    }, [] as IChipsSearchFilterSection[]);
  }

  private _removeItemFromList(): IChipsSearchFilterSection[] {
    const selectedChipsKeys: string[] = [];

    this.selectedChips.forEach((selectedChip) => {
      const keys = this.chipInputSearchHelper
        .getChipKeyValues(selectedChip)
        .map((keyValue) => keyValue.key);
      selectedChipsKeys.push(...keys);
    });

    return this.allChipsData.reduce((acc, curr) => {
      const sectionChips = curr.filters.filter(
        (chip) =>
          !this.chipInputSearchHelper.checkForChipKeyInclusive(
            chip,
            selectedChipsKeys
          ) && chip.behavior !== CIS_CHIP_BEHAVIORS.CHAINING_PARAMS
      );

      const ini_sectionChips = sectionChips.filter(
        (chip) => chip.behavior !== CIS_CHIP_BEHAVIORS.CHAINING_PARAMS
      );

      sectionChips.forEach((chip) => {
        const chipKeyValues = this.chipInputSearchHelper.getChipKeyValues(chip);

        const pairValue = this.originalValues.filter((value) =>
          chipKeyValues.map((keyValue) => keyValue.key).includes(value.key)
        );
        if (pairValue.length > 0) {
          // chip.chipProperties.value = pairValue[0].value;
        }
      });

      if (ini_sectionChips.length > 0) {
        acc.push({
          section: curr.section,
          filters: ini_sectionChips,
        });
      }
      return acc;
    }, [] as IChipsSearchFilterSection[]);
  }

  private resetChipsArrays() {
    this.allChipsData = [];
    this.selectedChips = [];
    this.lockChips = [];
    this.mandatoryChips = [];
    this.preSelectedChips = [];
    this.optionalChips = [];
    this.chainingParamsChips = [];
    this.defaultChips = [];
  }

  private resetChipSearchFilter() {
    this.chipCtrl.setValue('');
    this.resetChipsArrays();
    this.loading = false;
    this.closeButtonAvailable = false;
    this.panelClose = [];
    this.originalValues = [];
  }

  private bindWithChipSearchFilterService() {
    this.chipInputSearchService.isLoading
      .pipe(takeUntil(this.onDestroy$), debounceTime(50))
      .subscribe((value) => {
        this.loading = value;
      });

    this.chipInputSearchService.onClearChipSearchFilter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.resetChipSearchFilter();
      });

    this.chipInputSearchService.chipsData
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.resetChipsArrays();
        this.allChipsData = data;

        this.allChipsData.forEach((section) => {
          section.filters.forEach((chip) => {
            if (chip.behavior === CIS_CHIP_BEHAVIORS.LOCK) {
              this.lockChips.push(chip);
            } else if (chip.behavior === CIS_CHIP_BEHAVIORS.MANDATORY) {
              this.mandatoryChips.push(chip);
            } else if (chip.behavior === CIS_CHIP_BEHAVIORS.PRESELECTED) {
              this.preSelectedChips.push(chip);
            } else if (
              chip.behavior === CIS_CHIP_BEHAVIORS.CHAINING_PARAMS &&
              this.chipInputSearchHelper.chipHasValue(chip)
            ) {
              this.chainingParamsChips.push(chip);
            } else if (chip.behavior === CIS_CHIP_BEHAVIORS.DEFAULT) {
              this.defaultChips.push(chip);
            } else if (this.chipInputSearchHelper.chipHasValue(chip)) {
              this.optionalChips.push(chip);
            }

            if (chip.dependable_keys && chip.dependable_keys.length > 0) {
              this._changeDependedChipValue(chip);
            }
          });
        });
        this.selectedChips = this.defaultChips.concat(
          this.lockChips,
          this.mandatoryChips,
          this.preSelectedChips,
          this.chainingParamsChips,
          this.optionalChips
        );
        this.chipCtrl.updateValueAndValidity();
      });
    this.chipInputSearchService.isLoading.next(false);
    this.chipInputSearchService.onParamsChanged.next();
  }

  getPlaceHolder() {
    return this.loading ? '' : this.config.titlePrefix + ' ' + this.title;
  }

  reset() {
    this.chipCtrl.setValue('');
    this.selectedChips = [];
    this.closeButtonAvailable = false;
    this.filteredSections
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((filteredSections) => {
        filteredSections.forEach((sections) => {
          sections.filters.forEach((filter) => {
            if (filter?.dependable) {
              filter.dependableChipEnabled = false;
            }
          });
        });
      });
    this.selectedChips = this.lockChips.concat(this.mandatoryChips);
  }

  ngOnDestroy() {
    this.resetChipsArrays();
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
