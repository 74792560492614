import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import {
  PUBLIC_API_TYPES,
  RESPONSE_MESSAGES,
  URL_NO_TRAILING_SLASH_REGEX,
} from '@shared/constants';
import {
  CommonResponseDTO,
  IApiKey,
  IPublicKeyResponse,
  ISecurityKey,
} from '@shared/interfaces';

import {
  SnackbarService,
  SUCCESS_TYPES,
} from '../../../../services/snackbar.service';
import { ConnectedAppsKeysService } from '../../../connected-apps/services/connected-apps-keys.service';
import { noWhitespaceValidator } from '../../../core/helpers/form-field-white-space-validator';
import { SecurityKeysService } from '../../../setup/security/services/security-keys.service';
import { StorageKeysService } from '../../../storage/services/storage-keys.service';
import { NOTE_TYPE } from '../../components/note/note.component';

export interface IPublicClientCredentialsGeneratePopupInputs {
  apiType: string;
  id?: string;
  secret: string;
  clientId: string;
  apiKey?: IApiKey;
  securityKey?: ISecurityKey;
}

@Component({
  selector: 'app-public-key-generate-popup',
  templateUrl: './public-client-credential-generate-popup.component.html',
  styleUrls: ['./public-client-credential-generate-popup.component.scss'],
})
export class PublicClientCredentialGeneratePopupComponent implements OnInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  readonly publicApiTypes = PUBLIC_API_TYPES;
  noteType: NOTE_TYPE = NOTE_TYPE.WARN;

  hide = true;

  reference = new FormControl(null, [
    Validators.required,
    noWhitespaceValidator,
  ]);
  clientId = new FormControl(null, Validators.required);
  clientSecret = new FormControl(null, Validators.required);
  origins = new FormControl(null);
  remarks = new FormControl(null);

  @ViewChild('originChips') originChips: MatChipList;

  originsArr = [];
  isOriginsValid = true;
  isOriginEdited = false;

  isLoading = false;
  generatedKeyId;

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private connectedAppsKeysService: ConnectedAppsKeysService,
    private securityKeyService: SecurityKeysService,
    private storageKeysService: StorageKeysService,
    public dialogRef: MatDialogRef<PublicClientCredentialGeneratePopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IPublicClientCredentialsGeneratePopupInputs
  ) {}

  ngOnInit(): void {
    if (this.data.secret && this.data.clientId) {
      this.clientSecret.setValue(this.data.secret);
      this.clientId.setValue(this.data.clientId);
    }

    if (this.data.apiKey) {
      this.data.apiKey.origins.forEach((origin) => {
        this.originsArr.push(origin);
      });
      this.clientId.setValue(this.data.clientId);
      this.clientId.disable();
      this.clientSecret.setValue(this.data.apiKey.secret);
      this.clientSecret.disable();
      this.reference.setValue(this.data.apiKey.reference);
      this.reference.disable();
      this.remarks.setValue(this.data.apiKey.remarks);
      this.remarks.markAsPristine();
      this.origins.markAsPristine();
    }

    if (this.data.securityKey) {
      this.reference.setValue(this.data.securityKey.reference);
      this.reference.disable();
    }
  }

  onFocusOrigin(): void {
    if (this.origins.valueChanges) {
      this.isOriginEdited = true;
    }
  }

  saveKey() {
    if (this.data.apiKey) {
      switch (this.data.apiType) {
        case PUBLIC_API_TYPES.IDENTITIES:
          this.updateIdentitiesClientCredentials();
          break;
        case PUBLIC_API_TYPES.STORAGE:
          this.updateStoragesClientCredentials();
          break;
        case PUBLIC_API_TYPES.SECURITY:
          this.updateSecurityClientCredentials();
          break;
      }
    } else {
      switch (this.data.apiType) {
        case PUBLIC_API_TYPES.IDENTITIES:
          this.saveIdentitiesClientCredentials();
          break;
        case PUBLIC_API_TYPES.STORAGE:
          this.saveStoragesClientCredentials();
          break;

        case PUBLIC_API_TYPES.SECURITY:
          this.saveSecurityClientCredentials();
          break;
        default:
          break;
      }
    }
  }

  private _saveApiKey(
    observable: Observable<CommonResponseDTO<IPublicKeyResponse>>,
    successMessage = this.translate.instant(
      'shared.generate-client-credentials.client-credentials-snackbar'
    ),
    notUniqueErrorMessage = this.translate.instant(
      'apps.root.key-generate-duplicate-reference'
    ),
    generalErrorMessage = this.translate.instant(
      'apps.root.client-credential-generate-failed'
    )
  ) {
    this.isLoading = true;
    observable.subscribe({
      next: (response) => {
        this.isLoading = false;
        this.generatedKeyId = response.data;
        this.snackbar.success(SUCCESS_TYPES.GENERATED, successMessage);
        this.dialogRef.close(this.generatedKeyId);
      },
      error: (err) => {
        this.isLoading = false;
        this.snackbar.error(
          err?.error?.message === RESPONSE_MESSAGES.REFERENCE_NOT_UNIQUE
            ? notUniqueErrorMessage
            : generalErrorMessage
        );
      },
    });
  }

  private _updateApiKey(
    observable: Observable<CommonResponseDTO<IApiKey>>,
    successMessage = this.translate.instant(
      'shared.generate-client-credentials.client-credentials-snackbar'
    ),
    notUniqueErrorMessage = this.translate.instant(
      'apps.root.key-generate-duplicate-reference'
    ),
    generalErrorMessage = this.translate.instant(
      'apps.root.client-credential-generate-failed'
    )
  ) {
    this.isLoading = true;
    observable.subscribe({
      next: (response) => {
        this.isLoading = false;
        this.generatedKeyId = response.data;
        this.snackbar.success(SUCCESS_TYPES.UPDATED, successMessage);
        this.dialogRef.close(this.generatedKeyId);
      },
      error: (err) => {
        this.isLoading = false;
        this.snackbar.error(
          err?.error?.message === RESPONSE_MESSAGES.REFERENCE_NOT_UNIQUE
            ? notUniqueErrorMessage
            : generalErrorMessage
        );
      },
    });
  }

  saveSecurityClientCredentials(): void {
    const observable = this.securityKeyService.saveGeneratedPublicApiKey({
      id: this.data.securityKey._id.toString(),
      key: this.data.clientId,
      secret: this.data.secret,
      reference: this.reference.value,
      origins: this.originsArr,
      remarks: this.remarks.value,
    });

    this._saveApiKey(observable);
  }

  updateSecurityClientCredentials(): void {
    const observable = this.securityKeyService.updatePublicApiKeyOrSecret({
      id: this.data.apiKey._id.toString(),
      secret: this.data.apiKey.secret,
      origins: this.originsArr,
      remarks: this.remarks.value,
    });
    this._updateApiKey(observable);
  }

  saveStoragesClientCredentials(): void {
    const observable = this.storageKeysService.saveGeneratedPublicApiKey({
      storageId: this.data.id,
      secret: this.data.secret,
      key: this.data.clientId,
      reference: this.reference.value,
      origins: this.originsArr,
      remarks: this.remarks.value,
    });

    this._saveApiKey(observable);
  }

  updateStoragesClientCredentials(): void {
    const observable = this.storageKeysService.updatePublicApiKeyOrSecret({
      id: this.data.apiKey._id.toString(),
      secret: this.data.secret,
      storageId: this.data.id,
      origins: this.originsArr,
      remarks: this.remarks.value,
    });
    this._updateApiKey(observable);
  }

  saveIdentitiesClientCredentials(): void {
    const observable = this.connectedAppsKeysService.saveGeneratedPublicApiKey({
      secret: this.data.secret,
      appId: this.data.id,
      reference: this.reference.value,
      origins: this.originsArr,
      remarks: this.remarks.value,
      key: this.clientId.value,
    });
    this._saveApiKey(observable);
  }

  updateIdentitiesClientCredentials(): void {
    const observable = this.connectedAppsKeysService.updatePublicApiKeyOrSecret(
      {
        id: this.data.apiKey._id.toString(),
        appId: this.data.id,
        secret: this.data.secret,
        origins: this.originsArr,
        remarks: this.remarks.value,
      }
    );
    this._updateApiKey(observable);
  }

  onCopyClientId() {
    navigator.clipboard.writeText(this.clientId.value);
    this.snackbar.success(
      SUCCESS_TYPES.COPIED,
      this.translate.instant('shared.generate-client-credentials.client-id')
    );
  }

  onCopyClientSecret() {
    navigator.clipboard.writeText(this.clientSecret.value);
    this.snackbar.success(
      SUCCESS_TYPES.COPIED,
      this.translate.instant('shared.generate-client-credentials.client-secret')
    );
  }

  onAdd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.originsArr.push(value);
      this.isOriginEdited = true;
    }

    event.chipInput?.clear();
    this.validateOrigins();
  }

  onRemove(index: number): void {
    if (index >= 0) {
      this.originsArr.splice(index, 1);
      this.isOriginEdited = true;
    }
    this.validateOrigins();
  }

  validateOrigins(): void {
    let isValid = true;
    this.originsArr.forEach((origin) => {
      const valid = URL_NO_TRAILING_SLASH_REGEX.test(origin);

      if (!valid) {
        isValid = false;
      }
    });

    this.origins.markAsDirty();
    this.origins.markAsTouched();
    this.origins.setErrors({ regex: !isValid });
    this.isOriginsValid = isValid;
    this.originChips.errorState = !isValid;
  }
}
