import {
  Component,
  DoCheck,
  EventEmitter,
  HostListener,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ELAutocompleteElement } from '@el-autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'lodash';
import { startWith, Subject, takeUntil } from 'rxjs';

import {
  COMPARISON_TYPES,
  CONFIGURABLE_FIELD_CHECKBOX_TYPES,
  CONFIGURABLE_FIELD_DATA_TYPES,
  CRITERIA_COMPARE_TYPES,
  CRITERIA_TYPES,
  FIELD_MODE_TYPES,
} from '@shared/constants';
import {
  IConfigurableFieldConfigResponse,
  IKeyValuePair,
  IReferenceCategoryResponse,
  IReferenceResponse,
} from '@shared/interfaces';
import {
  isConstant,
  isDefault,
  isRequired,
  isUnique,
  referenceCategoriesToElAutocomplete,
  referenceCategoryFieldsToElAutocomplete,
} from '@shared/utils';

import {
  defaultScreenSize,
  ResizeService,
  ScreenSize,
} from '../../../shared/services/screen-size.service';
import {
  ConfigurableFieldValueService,
  ConfigureConfigurableFieldService,
} from '../../services';
import {
  IConfigureConfigurableFieldElement,
  IDisplayConfigurableFieldElement,
} from '../../types';

@Component({
  selector: 'app-configure-configurable-field',
  templateUrl: './configure-configurable-field.component.html',
  styleUrls: ['./configure-configurable-field.component.scss'],
})
export class ConfigureConfigurableFieldComponent
  implements DoCheck, OnInit, OnDestroy
{
  @Input() fields: IConfigureConfigurableFieldElement[];
  @Input() disabled: boolean;
  @Input() collectKeyFromUser: boolean;
  @Input() fieldTypesOptions: IKeyValuePair<string>[];
  @Input() fieldWidthOptions: IKeyValuePair<string>[];
  @Input() criteriaTypes: IKeyValuePair<string>[];
  @Input() criteriaCompareTypes: IKeyValuePair<string>[];
  @Input() activeReferenceCategories: IReferenceCategoryResponse[];
  @Input() editMode: boolean;
  @Input() isDividersVisible = true;
  @Input() disableUniqueFields: boolean;
  @Input() isDynamicStyles: boolean;
  @Output() onCheckValidity: EventEmitter<boolean> = new EventEmitter();
  @Output() onFieldDeleted: EventEmitter<number> = new EventEmitter();
  @Output() onClickCheckBox: EventEmitter<{
    fieldIndex: number;
    checkboxIndex: number;
  }> = new EventEmitter();
  @Output() onSetReferenceValues: EventEmitter<number> = new EventEmitter();
  @Output() onAdditionalFieldSelectionChange: EventEmitter<{
    value: string;
    field_index: number;
    additional_field_index: number;
  }> = new EventEmitter();
  @Output() onTypeCheckbox: EventEmitter<number> = new EventEmitter();
  @Output() onTypeRadioButton: EventEmitter<number> = new EventEmitter();
  @Output() onDeleteCriteria: EventEmitter<{
    field_id: number;
    option_id: number;
  }> = new EventEmitter();
  @Output() onDependentFieldChange: EventEmitter<void> = new EventEmitter();

  @Output() onCheckboxDefaultChange: EventEmitter<{
    field_id: number;
    option_id: number;
    checkboxBehaviour: boolean;
  }> = new EventEmitter();

  @Output() onRadioButtonDefaultChange: EventEmitter<{
    field_id: number;
    option_id: number;
  }> = new EventEmitter();

  @Output() onSetReferencesForSelectedField: EventEmitter<{
    field_index: number;
    ref_category_id: string;
    ref_field_id: string;
  }> = new EventEmitter();
  @Output() onAddCriteria: EventEmitter<number> = new EventEmitter();
  @Output() onAddRadioButton: EventEmitter<number> = new EventEmitter();
  @Output() onAddCheckbox: EventEmitter<number> = new EventEmitter();
  @Output() onDeleteRadioButton: EventEmitter<{
    field_id: number;
    option_id: number;
  }> = new EventEmitter();
  @Output() onDeleteCheckbox: EventEmitter<{
    field_id: number;
    option_id: number;
  }> = new EventEmitter();
  booleanLabels = { trueLabel: '', falseLabel: '' };
  @Output() pristineChange = new EventEmitter<boolean>();
  private initialBooleanLabelsState: { trueLabel: string; falseLabel: string };

  referenceCategories: ELAutocompleteElement[] = [];
  fieldTypes = CONFIGURABLE_FIELD_DATA_TYPES;
  fieldComparisonTypes = Object.entries(COMPARISON_TYPES)?.map(
    ([key, value]) => ({
      value: value.toString(),
      key: key.replace(/_/g, ' '),
    })
  );

  dynamicStyles: any = {};
  screenSize: ScreenSize = defaultScreenSize;
  private readonly onDestroy$ = new Subject<void>();
  private fieldsIterableDiffer: IterableDiffer<IConfigureConfigurableFieldElement>;
  private activeReferenceCategoriesIterableDiffer: IterableDiffer<IReferenceCategoryResponse>;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setDynamicStyles();
  }

  constructor(
    _iterableDiffers: IterableDiffers,
    private translate: TranslateService,
    private configurableFieldValueService: ConfigurableFieldValueService,
    private configureConfigurableFieldService: ConfigureConfigurableFieldService,
    private resizeService: ResizeService
  ) {
    this.fieldsIterableDiffer = _iterableDiffers
      .find(this.fields ?? [])
      .create();
    this.activeReferenceCategoriesIterableDiffer = _iterableDiffers
      .find(this.activeReferenceCategories ?? [])
      .create();

    this.resizeService.onWindowResize.subscribe((size) => {
      this.screenSize = size;
    });
  }

  ngOnInit(): void {
    this.setDynamicStyles();
    this.initialBooleanLabelsState = { ...this.booleanLabels };
  }

  ngDoCheck(): void {
    const fieldsChanges = this.fieldsIterableDiffer.diff(this.fields);
    if (fieldsChanges && this.fields) {
      this.fields.forEach((field, index) => {
        this.makeTheDefaultValueFieldMandatoryAccordingToCheckBoxes(index);
        this.setCheckboxValueIntoFalseIfNeeded(index);

        if (isRequired(field.checkboxes)) {
          const defaultCheckbox = field.checkboxes.find(
            (option) =>
              option.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.DEFAULT
          );

          if (defaultCheckbox) {
            defaultCheckbox.disabled = true;
          }
        }

        if (field._id) {
          if (isDefault(field.checkboxes) && !isUnique(field.checkboxes)) {
            const uniqueCheckbox = field.checkboxes.find(
              (option) =>
                option.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE
            );

            if (uniqueCheckbox) {
              uniqueCheckbox.disabled = true;
              uniqueCheckbox.isChecked = false;
            }

            const defaultCheckbox = field.checkboxes.find(
              (option) =>
                option.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.DEFAULT
            );

            if (defaultCheckbox) {
              defaultCheckbox.isChecked = true;
              defaultCheckbox.disabled = false;
            }
          }
        }

        if (this.disabled || field.mandatory) {
          field.uniqueID.disable();
        }

        if (!this.collectKeyFromUser && !field.mandatory && field.uniqueID) {
          field.name.valueChanges
            .pipe(takeUntil(this.onDestroy$), startWith(field.name.value ?? ''))
            .subscribe((name) => {
              field.uniqueID.setValue(name);
            });
        }
      });

      this.checkRadioButtonOptionsUniqueness(this.fields);
      this.checkCheckboxOptionsUniqueness(this.fields);
    }

    const activeReferenceCategoriesChanges =
      this.activeReferenceCategoriesIterableDiffer.diff(
        this.activeReferenceCategories
      );
    if (activeReferenceCategoriesChanges && this.activeReferenceCategories) {
      this.referenceCategories = referenceCategoriesToElAutocomplete(
        this.activeReferenceCategories
      );
    }
  }

  onBooleanLabelsPristine() {
    const isPristine =
      JSON.stringify(this.booleanLabels) !==
      JSON.stringify(this.initialBooleanLabelsState);

    this.pristineChange.emit(isPristine);
  }

  private setDynamicStyles(): void {
    if (this.isDynamicStyles) {
      const windowWidth = window.innerWidth;
      const marginLeft =
        windowWidth > 1100 || (395 < windowWidth && windowWidth < 425)
          ? -15
          : windowWidth > 900
          ? -10
          : windowWidth > 700
          ? -5
          : 0;

      this.dynamicStyles = { 'margin-left': `${marginLeft}px` };
    } else {
      this.dynamicStyles = {};
    }
  }

  onReferenceCategorySelected(
    index: number,
    selected: ELAutocompleteElement | ELAutocompleteElement[]
  ) {
    if (isArray(selected)) return;

    this.fields[index].reference_category.setValue(selected.value);
    this.fields[index].selectedCategory = selected.originalData;

    this.fields[index].referenceFieldsForSelectedCategory =
      referenceCategoryFieldsToElAutocomplete(
        this.fields[index].selectedCategory?.fields ?? []
      );

    if (
      !this.fields[index].referenceFieldsForSelectedCategory.some(
        (field) => field.value === this.fields[index].reference_field.value
      )
    ) {
      this.fields[index].reference_field.setValue(null);
      this.fields[index].reference_field.markAsDirty();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  checkRadioButtonOptionsUniqueness(
    fields?: IConfigureConfigurableFieldElement[],
    newOption: { fieldIndex: number; optionIndex: number } = null
  ) {
    let allFields: IConfigureConfigurableFieldElement[] = [];
    if (fields) {
      allFields = fields;
    } else {
      allFields = this.fields;
    }

    setTimeout(() => {
      if (newOption) {
        // if new radio button option is added
        const addedOption =
          allFields[newOption.fieldIndex].radioButtonOptions[
            newOption.optionIndex
          ];

        addedOption.value.valueChanges
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((change) => {
            // if value changed field doesn't have a unique value, set notUnique error
            if (
              allFields[newOption.fieldIndex].radioButtonOptions
                .filter(
                  (_option, index) =>
                    index !==
                    allFields[newOption.fieldIndex].radioButtonOptions.length -
                      1
                )
                .map((otherOptions) => otherOptions.value.value)
                .includes(change)
            ) {
              addedOption.value.setErrors({ notUnique: true });
            } else {
              const radioButtonOptions = allFields[
                newOption.fieldIndex
              ].radioButtonOptions.filter(
                (_option, index) =>
                  index !==
                  allFields[newOption.fieldIndex].radioButtonOptions.length - 1
              );

              radioButtonOptions.forEach((element) => {
                // remove notUnique error from all unique radio button value fields
                if (
                  radioButtonOptions.filter(
                    (item) => item.value.value === element.value.value
                  ).length === 1
                ) {
                  const currentErrors = element.value.errors;
                  if (currentErrors) {
                    delete currentErrors['notUnique'];
                    if (Object.keys(currentErrors).length === 0) {
                      element.value.setErrors(null);
                    } else {
                      element.value.setErrors(currentErrors);
                    }
                  }
                }
              });
            }
          });
      } else {
        allFields.forEach((field) => {
          field.radioButtonOptions?.forEach((option, i) => {
            option.value.valueChanges
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((change) => {
                // if value changed field doesn't have a unique value, set notUnique error
                if (
                  field.radioButtonOptions
                    .filter((_option, index) => index !== i)
                    .map((otherOptions) => otherOptions.value.value)
                    .includes(change)
                ) {
                  option.value.setErrors({ notUnique: true });
                } else {
                  const radioButtonOptions = field.radioButtonOptions.filter(
                    (_option, index) => index !== i
                  );

                  radioButtonOptions.forEach((element) => {
                    // remove notUnique error from all unique radio button value fields
                    if (
                      radioButtonOptions.filter(
                        (item) => item.value.value === element.value.value
                      ).length === 1
                    ) {
                      const currentErrors = element.value.errors;
                      if (currentErrors) {
                        delete currentErrors['notUnique'];
                        if (Object.keys(currentErrors).length === 0) {
                          element.value.setErrors(null);
                        } else {
                          element.value.setErrors(currentErrors);
                        }
                      }
                    }
                  });
                }
              });
          });
        });
      }
    }, 3000);
  }

  checkCheckboxOptionsUniqueness(
    fields?: IConfigureConfigurableFieldElement[],
    newOption: { fieldIndex: number; optionIndex: number } = null
  ) {
    let allFields = [];
    if (fields) {
      allFields = fields;
    } else {
      allFields = this.fields;
    }

    setTimeout(() => {
      if (newOption) {
        // if new checkbox option is added
        const addedOption =
          allFields[newOption.fieldIndex].checkboxOptions[
            newOption.optionIndex
          ];

        addedOption.value.valueChanges
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((change) => {
            // if value changed field doesn't have a unique value, set notUnique error
            if (
              allFields[newOption.fieldIndex].checkboxOptions
                .filter(
                  (_option, index) =>
                    index !==
                    allFields[newOption.fieldIndex].checkboxOptions.length - 1
                )
                .map((otherOptions) => otherOptions.value.value)
                .includes(change)
            ) {
              addedOption.value.setErrors({ notUnique: true });
            } else {
              const checkboxOptions = allFields[
                newOption.fieldIndex
              ].checkboxOptions.filter(
                (_option, index) =>
                  index !==
                  allFields[newOption.fieldIndex].checkboxOptions.length - 1
              );

              checkboxOptions.forEach((element) => {
                // remove notUnique error from all unique radio button value fields
                if (
                  checkboxOptions.filter(
                    (item) => item.value.value === element.value.value
                  ).length === 1
                ) {
                  const currentErrors = element.value.errors;
                  if (currentErrors) {
                    delete currentErrors['notUnique'];
                    if (Object.keys(currentErrors).length === 0) {
                      element.value.setErrors(null);
                    } else {
                      element.value.setErrors(currentErrors);
                    }
                  }
                }
              });
            }
          });
      } else {
        allFields.forEach((field) => {
          field.checkboxOptions?.forEach((option, i) => {
            option.value.valueChanges
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((change) => {
                // if value changes field doesn't have a unique value, set notUnique error
                if (
                  field.checkboxOptions
                    .filter((_option, index) => index !== i)
                    .map((otherOptions) => otherOptions.value.value)
                    .includes(change)
                ) {
                  option.value.setErrors({ notUnique: true });
                } else {
                  const checkboxOptions = field.checkboxOptions.filter(
                    (_option, index) => index !== i
                  );

                  checkboxOptions.forEach((element) => {
                    // remove notUnique error from all unique radio button value fields
                    if (
                      checkboxOptions.filter(
                        (item) => item.value.value === element.value.value
                      ).length === 1
                    ) {
                      const currentErrors = element.value.errors;
                      if (currentErrors) {
                        delete currentErrors['notUnique'];
                        if (Object.keys(currentErrors).length === 0) {
                          element.value.setErrors(null);
                        } else {
                          element.value.setErrors(currentErrors);
                        }
                      }
                    }
                  });
                }
              });
          });
        });
      }
    }, 3000);
  }

  private makeTheDefaultValueFieldMandatoryAccordingToCheckBoxes(
    field_index: number
  ) {
    this.fields[
      field_index
    ].displayTheDefaultFieldItem.configuration.checkboxes = [
      {
        isChecked: isConstant(this.fields[field_index].checkboxes),
        key: CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
        type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED,
      },
      {
        isChecked: isUnique(this.fields[field_index].checkboxes),
        key: CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
        type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.UNIQUE,
      },
      {
        isChecked: isDefault(this.fields[field_index].checkboxes),
        key: CONFIGURABLE_FIELD_CHECKBOX_TYPES.DEFAULT,
        type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.DEFAULT,
      },
    ];
  }

  private setCheckboxValueIntoFalseIfNeeded(field_index: number): void {
    if (!this.hasDefaultValue(this.fields[field_index])) {
      const checkboxIndex = this.fields[field_index].checkboxes.findIndex(
        (checkbox) =>
          checkbox.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.CONSTANT
      );

      if (checkboxIndex > -1) {
        this.fields[field_index].checkboxes[checkboxIndex].isChecked = false;
      }
    }

    if (!this.isReference(field_index)) {
      const checkboxIndex = this.fields[field_index].checkboxes.findIndex(
        (checkbox) => checkbox.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.SORT
      );

      if (checkboxIndex > -1) {
        this.fields[field_index].checkboxes[checkboxIndex].isChecked = false;
      }
    }
  }

  removeForm(index: number) {
    this.fields.splice(index, 1);
    this.onFieldDeleted.emit(index);
    this.onCheckValidity.emit(true);
  }

  isText(index: number) {
    return (
      this.fields[index]?.type.value === CONFIGURABLE_FIELD_DATA_TYPES.TEXT
    );
  }

  isReference(index: number) {
    return (
      this.fields[index]?.type.value ===
      CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES
    );
  }

  isNumber(index: number) {
    return (
      this.fields[index]?.type.value === CONFIGURABLE_FIELD_DATA_TYPES.NUMBER
    );
  }

  isBoolean(index: number) {
    return (
      this.fields[index]?.type.value === CONFIGURABLE_FIELD_DATA_TYPES.BOOLEAN
    );
  }

  isDate(index: number) {
    return (
      this.fields[index]?.type.value === CONFIGURABLE_FIELD_DATA_TYPES.DATE
    );
  }

  isCheckbox(index: number) {
    return (
      this.fields[index]?.type.value === CONFIGURABLE_FIELD_DATA_TYPES.CHECKBOX
    );
  }

  isRadioButton(index: number) {
    return (
      this.fields[index]?.type.value ===
      CONFIGURABLE_FIELD_DATA_TYPES.RADIO_BUTTON
    );
  }

  onCheckClicked(field_index: number, checkbox_index: number) {
    this.fields[field_index].checkboxes[checkbox_index].isChecked =
      !this.fields[field_index].checkboxes[checkbox_index].isChecked;

    this.makeTheDefaultValueFieldMandatoryAccordingToCheckBoxes(field_index);

    this.onClickCheckBox.emit({
      fieldIndex: field_index,
      checkboxIndex: checkbox_index,
    });
  }

  getFieldValue(ref: IReferenceResponse, field_id: string): Promise<string> {
    return this.configurableFieldValueService.fieldValueToString({
      valueResponse: ref.reference.find((r) => r.field_id === field_id),
    });
  }

  hasDefaultValue(field: IConfigureConfigurableFieldElement) {
    const fieldType = field.type.value;

    const allowedTypes = [
      CONFIGURABLE_FIELD_DATA_TYPES.TEXT,
      CONFIGURABLE_FIELD_DATA_TYPES.NUMBER,
      CONFIGURABLE_FIELD_DATA_TYPES.CURRENCY,
      CONFIGURABLE_FIELD_DATA_TYPES.DATE,
      CONFIGURABLE_FIELD_DATA_TYPES.DATE_TIME,
      CONFIGURABLE_FIELD_DATA_TYPES.BOOLEAN,
      CONFIGURABLE_FIELD_DATA_TYPES.ADDRESS,
      CONFIGURABLE_FIELD_DATA_TYPES.NAME,
      CONFIGURABLE_FIELD_DATA_TYPES.CONTACT_NUMBER,
      CONFIGURABLE_FIELD_DATA_TYPES.EMAIL,
    ];

    return allowedTypes.includes(fieldType);
  }

  setReferenceValues(
    index: number,
    selected: ELAutocompleteElement | ELAutocompleteElement[]
  ) {
    if (isArray(selected)) return;

    this.fields[index].reference_field.setValue(selected.value);

    this.onSetReferenceValues.emit(index);
    this.onSetReferencesForSelectedField.emit({
      field_index: index,
      ref_category_id: this.fields[index].reference_category.value,
      ref_field_id: this.fields[index].reference_field.value,
    });
  }

  onAdditionalFieldSelectionChanged(
    value: string,
    field_index: number,
    additional_field_index: number
  ) {
    this.onAdditionalFieldSelectionChange.emit({
      value,
      field_index,
      additional_field_index,
    });
  }

  checkConstant(field: IConfigureConfigurableFieldElement) {
    return isConstant(field?.checkboxes);
  }

  onFieldTypeChange(field_index: number) {
    const field = this.fields[field_index];
    const newConfig: IConfigurableFieldConfigResponse = {
      name: this.translate.instant('configurable-fields.default-value'),
      type: field.type.value,
      booleanLabels: {
        trueLabel: field.booleanLabels?.trueLabel?.value,
        falseLabel: field.booleanLabels?.falseLabel?.value,
      },
    };

    field.displayTheDefaultFieldItem.configuration = newConfig;
    field.displayTheDefaultFieldItem.field_value.setValue('');
    this.setCheckboxValueIntoFalseIfNeeded(field_index);

    field.reference_category.setValue('');
    field.reference_field.setValue('');

    if (
      newConfig.type !== CONFIGURABLE_FIELD_DATA_TYPES.TEXT &&
      field.regexField
    ) {
      field.regexField.regexType.setValue('');
      field.regexField.regex.setValue('');
    }

    if (newConfig.type === CONFIGURABLE_FIELD_DATA_TYPES.CHECKBOX) {
      this.onTypeCheckbox.emit(field_index);
    } else if (newConfig.type === CONFIGURABLE_FIELD_DATA_TYPES.RADIO_BUTTON) {
      this.onTypeRadioButton.emit(field_index);
    }
  }

  checkboxBehaviour(
    event: MatCheckboxChange,
    field_id: number,
    option_id: number
  ) {
    this.onCheckboxDefaultChange.emit({
      field_id,
      option_id,
      checkboxBehaviour: event.checked,
    });
  }

  radioButtonBehaviour(field_id: number, option_id: number) {
    this.onRadioButtonDefaultChange.emit({
      field_id,
      option_id,
    });
  }

  private isMarkedAsRequired(field_index: number) {
    const requiredCheckboxIndex = (
      this.fields[field_index].checkboxes ?? []
    ).findIndex(
      (checkbox) => checkbox.type === CONFIGURABLE_FIELD_CHECKBOX_TYPES.REQUIRED
    );

    if (requiredCheckboxIndex === -1) return false;

    return this.fields[field_index].checkboxes[requiredCheckboxIndex].isChecked;
  }

  isCheckboxDisabled(field_index: number, checkbox_index: number) {
    const disableConstantChip =
      this.fields[field_index].checkboxes[checkbox_index].type ===
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.CONSTANT &&
      !this.hasDefaultValue(this.fields[field_index]);

    const disableSortChip =
      this.fields[field_index].checkboxes[checkbox_index].type ===
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.SORT &&
      !this.isReference(field_index);

    const disableDefaultChip =
      this.fields[field_index].checkboxes[checkbox_index].type ===
        CONFIGURABLE_FIELD_CHECKBOX_TYPES.DEFAULT &&
      this.isMarkedAsRequired(field_index);

    return disableConstantChip || disableSortChip || disableDefaultChip;
  }

  onRegexTypeChange(type: 'email' | 'custom', i: number) {
    if (type === 'email') {
      this.fields[i].regexField.regex.setValue(
        `^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$`
      );
    } else {
      this.fields[i].regexField.regex.setValue('');
    }
  }

  getCriteriaTypes(i: number) {
    if (!this.isReference(i)) {
      return this.criteriaTypes?.filter(
        (type) => type.value !== CRITERIA_TYPES.FILTER
      );
    }
    return this.criteriaTypes;
  }

  getCriteriaCompareTypes(i: number) {
    if (!this.isReference(i)) {
      return this.criteriaCompareTypes?.filter(
        (type) => type.value !== CRITERIA_COMPARE_TYPES.SOMETHING_ELSE
      );
    }
    return this.criteriaCompareTypes;
  }

  getDependentFieldType(
    uniqueID: string
  ): CONFIGURABLE_FIELD_DATA_TYPES | null {
    return this.fields.find((field) => field.uniqueID.value === uniqueID)?.type
      ?.value;
  }

  getComparisonTypes(uniqueID: string) {
    const dependentFieldType = this.fields.find(
      (field) => field.uniqueID.value === uniqueID
    )?.type.value;
    return this.fieldComparisonTypes.filter((type) => {
      if (
        [
          COMPARISON_TYPES.GREATER_THAN,
          COMPARISON_TYPES.GREATER_THAN_OR_EQUAL,
          COMPARISON_TYPES.LESS_THAN,
          COMPARISON_TYPES.LESS_THAN_OR_EQUAL,
        ].includes(type.value as COMPARISON_TYPES)
      ) {
        return (
          dependentFieldType === CONFIGURABLE_FIELD_DATA_TYPES.DATE ||
          dependentFieldType === CONFIGURABLE_FIELD_DATA_TYPES.NUMBER
        );
      }

      return true;
    });
  }

  getComparableFields(field_id: number, criteria_index: number): any {
    const dependentFieldUniqueID =
      this.fields[field_id].criteria[criteria_index].dependent_field?.value;

    const foundDependableField = this.fields.find(
      (field) => field.uniqueID.value === dependentFieldUniqueID
    );

    if (foundDependableField) {
      switch (foundDependableField.type.value) {
        case CONFIGURABLE_FIELD_DATA_TYPES.CHECKBOX:
          return foundDependableField.checkboxOptions;

        case CONFIGURABLE_FIELD_DATA_TYPES.RADIO_BUTTON:
          return foundDependableField.radioButtonOptions;

        default:
          break;
      }
    }
  }

  getCompareValueField(
    fieldFormControl: FormControl,
    uniqueID: string
  ): IDisplayConfigurableFieldElement {
    const fieldConfig = this.fields.find(
      (field) => field.uniqueID.value === uniqueID
    );

    if (!fieldConfig)
      return {
        field_value: fieldFormControl,
        configuration: {
          name: 'Compare Value',
          type: CONFIGURABLE_FIELD_DATA_TYPES.NONE,
        },
      };

    return {
      field_value: fieldFormControl,
      configuration: {
        ...this.configureConfigurableFieldService.getValueFromSingleFieldConfig(
          fieldConfig
        ),
        name: 'Compare Value',
        checkboxes: [],
      },
    };
  }

  getDependentFields(
    field_index: number
  ): IConfigureConfigurableFieldElement[] {
    return this.fields.filter(
      (field, index) =>
        index !== field_index &&
        ![
          CONFIGURABLE_FIELD_DATA_TYPES.ADDRESS,
          CONFIGURABLE_FIELD_DATA_TYPES.CONTACT_NUMBER,
          CONFIGURABLE_FIELD_DATA_TYPES.EMAIL,
          CONFIGURABLE_FIELD_DATA_TYPES.NAME,
          CONFIGURABLE_FIELD_DATA_TYPES.FILE,
          CONFIGURABLE_FIELD_DATA_TYPES.FORMULA,
          CONFIGURABLE_FIELD_DATA_TYPES.JSON,
          CONFIGURABLE_FIELD_DATA_TYPES.RICH_TEXT,
          CONFIGURABLE_FIELD_DATA_TYPES.VIEW,
        ].includes(field.type.value)
    );
  }

  getDependentReferenceFieldValues(dependentUniqueID: string) {
    // used when a field in not a reference type field, and has a criteria with a reference type dependent field
    // returns dependent field's reference values
    const dependentField = this.fields.find(
      (field) => field.uniqueID.value === dependentUniqueID
    );

    return dependentField.referencesForSelectedField;
  }

  onClickDeleteCriteria(field_id: number, option_id: number) {
    this.onDeleteCriteria.emit({ field_id, option_id });
  }

  dependentFieldChange() {
    this.onDependentFieldChange.emit();
  }

  addNewCriteria(index: number) {
    this.onAddCriteria.emit(index);
  }

  onClickDeleteRadioButtonField(field_id: number, option_id: number) {
    this.onDeleteRadioButton.emit({ field_id, option_id });
  }

  onClickDeleteCheckboxField(field_id: number, option_id: number) {
    this.onDeleteCheckbox.emit({ field_id, option_id });
  }

  addNewRadioCheckboxOption(index: number) {
    let eventEmitter: EventEmitter<number>;
    let optionIndex: number;
    let validityChecker: (
      fields: IConfigureConfigurableFieldElement[],
      newOption: { fieldIndex: number; optionIndex: number }
    ) => void;

    if (this.isCheckbox(index)) {
      eventEmitter = this.onAddCheckbox;
      optionIndex = this.fields[index].checkboxOptions.length - 1;
      validityChecker = this.checkCheckboxOptionsUniqueness;
    } else if (this.isRadioButton(index)) {
      eventEmitter = this.onAddRadioButton;
      optionIndex = this.fields[index].radioButtonOptions.length - 1;
      validityChecker = this.checkRadioButtonOptionsUniqueness;
    } else {
      return;
    }

    eventEmitter.emit(index);

    validityChecker(null, {
      fieldIndex: index,
      optionIndex,
    });
  }

  isAdvanced(field: IConfigureConfigurableFieldElement) {
    return field?.mode === FIELD_MODE_TYPES.ADVANCED;
  }
}
