import { Component } from '@angular/core';

@Component({
  selector: 'app-skeleton-data-list-table',
  templateUrl: './skeleton-data-list-table.component.html',
  styleUrls: ['./skeleton-data-list-table.component.scss'],
})
export class SkeletonDataListTableComponent {
  panelOpenState = false;
  items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  expandAll = false;
}
