import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { URLS } from '@shared/constants';
import {
  MaterialIconResponseDTO,
  MaterialIconsListResponseDTO,
} from '@shared/dtos';
import { CommonResponseDTO } from '@shared/interfaces';

export interface CreateQuickAction {
  title: string;
  link: string;
  icon: string;
}
export interface IQuickAction extends CreateQuickAction {
  _id?: string;
  index_number: number;
  delete_loading_indicator?: boolean;
}

export interface ISQuickAction extends IQuickAction {
  //Added to support hardcoded quick actions
  isSystem?: boolean;
  key?: string;
}

export interface IDraggedQuickActionIndexChangeDTO {
  current_quick_action_id: string;
  above_quick_action_id?: string;
  below_quick_action_id?: string;
}

@Injectable({ providedIn: 'root' })
export class QuickActionService {
  public readonly iconsList = new BehaviorSubject<MaterialIconResponseDTO[]>(
    []
  );
  public readonly createdQuickActions = new BehaviorSubject<IQuickAction[]>([]);

  constructor(private http: HttpClient) {}

  getMaterialIconsList(): Observable<
    CommonResponseDTO<MaterialIconsListResponseDTO>
  > {
    return this.http
      .get<CommonResponseDTO<MaterialIconsListResponseDTO>>(
        URLS.QUICK_ACTION_ICON_LIST
      )
      .pipe(
        tap((res) => {
          this.iconsList.next(res.data.icons);
        })
      );
  }

  getAllQuickActions(): Observable<CommonResponseDTO<IQuickAction[]>> {
    return this.http
      .get<CommonResponseDTO<IQuickAction[]>>(URLS.QUICK_ACTIONS)
      .pipe(
        tap((res) => {
          this.createdQuickActions.next(res.data);
        })
      );
  }

  createQuickAction(
    data: CreateQuickAction
  ): Observable<CommonResponseDTO<IQuickAction>> {
    this.createdQuickActions.next([]);
    return this.http
      .post<CommonResponseDTO<IQuickAction>>(URLS.QUICK_ACTIONS, data)
      .pipe(
        tap((res) => {
          if (res) {
            this.getAllQuickActions();
          }
        })
      );
  }

  changePosition(
    data: IDraggedQuickActionIndexChangeDTO
  ): Observable<CommonResponseDTO<IQuickAction>> {
    return this.http.patch<CommonResponseDTO<IQuickAction>>(
      URLS.QUICK_ACTION_REORDER_ACTION,
      data
    );
  }

  deleteQuickActions(
    id: string
  ): Observable<CommonResponseDTO<IQuickAction[]>> {
    const url = `${URLS.QUICK_ACTIONS}/${id}`;
    return this.http.delete<CommonResponseDTO<IQuickAction[]>>(url).pipe(
      tap((res) => {
        if (res) {
          this.createdQuickActions.next([]);
          this.getAllQuickActions();
        }
      })
    );
  }
}
