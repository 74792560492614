import { basicOperators } from './data-basic-operators-list';
import { bracketOperators } from './data-bracket-operators-list';
import { comparativeOperators } from './data-comparative-operators-list';
import { conditionalOperators } from './data-conditional-operators-list';
import { customVariables } from './data-custom-variables-list';
import { dataReferences } from './data-data-references-list';
import { externalData } from './data-external-data-list';
import { generalConstants } from './data-general-constants-list';
import { logicalOperators } from './data-logical-operators-list';

export default {
  conditionalOperators,
  bracketOperators,
  basicOperators,
  comparativeOperators,
  dataReferences,
  generalConstants,
  customVariables,
  logicalOperators,
  externalData,
};
