import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import {
  ASSIGNMENT_TYPES,
  ENDPOINT_PARAM_TYPES,
  RUNTIME_COLLECTABLE_FIELDS,
} from '@shared/constants';
import {
  IConfigurableFieldConfigResponse,
  IEndpoint,
  IEndpointParam,
  IFormulaIntegrationElementParam,
  IFormulaIntegrationMappingElementData,
  IKeyValuePair,
  IReferenceCategoryResponse,
} from '@shared/interfaces';
import { enumToKeyValuePairs, getPreDefinedDynamicValues } from '@shared/utils';

import { ReferenceCategoryService } from '../../../../../references/services/reference-category.service';

export interface DefineIntegrationPopupData {
  integration: IFormulaIntegrationMappingElementData;
  localReference: IReferenceCategoryResponse;
}

@Component({
  selector: 'app-define-integration-popup',
  templateUrl: 'define-integration-popup.component.html',
  styleUrls: ['define-integration-popup.component.scss'],
})
export class DialogContentDefineIntegrationComponent
  implements OnInit, OnDestroy
{
  endpoint: IEndpoint;
  foreignReferenceFields: IConfigurableFieldConfigResponse[];
  localReference: IReferenceCategoryResponse;
  categoryArray: IReferenceCategoryResponse[] = [];
  ASSIGNMENT_TYPES = ASSIGNMENT_TYPES;
  runtimeCollectableFields: IKeyValuePair<string>[] = [];

  fields: {
    parameterName: FormControl<string>;
    assignmentType: FormControl<ASSIGNMENT_TYPES>;
    assignmentValue: FormControl<string>;
    disable: FormControl<boolean>;
    predefinedValeHint: FormControl<string>;
  }[] = [];

  private onDestroy$ = new Subject<void>();

  constructor(
    titleCasePipe: TitleCasePipe,
    private dialogRef: MatDialogRef<DialogContentDefineIntegrationComponent>,
    private referenceCategoryService: ReferenceCategoryService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    private data: DefineIntegrationPopupData
  ) {
    this.localReference = data?.localReference;
    this.endpoint = data.integration.endpoint;
    this.fields = [];

    const pushField = (param: IEndpointParam) => {
      const paramName = param.name;
      let assignmentType: ASSIGNMENT_TYPES;
      let assignmentValue: string;
      let predefinedValeHint: string;
      let disable: boolean;

      switch (param.type) {
        case ENDPOINT_PARAM_TYPES.RUNTIME_COLLECT: {
          assignmentType = ASSIGNMENT_TYPES.LOCAL;
          assignmentValue = '';
          predefinedValeHint = '';
          disable = false;
          break;
        }
        case ENDPOINT_PARAM_TYPES.PREDEFINED: {
          assignmentType = ASSIGNMENT_TYPES.PREDEFINED_STATIC;
          assignmentValue = param.name;
          predefinedValeHint = this.translate.instant(
            'configurable-fields.formula-builder.define-integration.predefined-static-hint',
            { predefinedValue: param.predefined_value }
          );
          disable = true;
          break;
        }
        case ENDPOINT_PARAM_TYPES.CURRENT_YEAR_NUMBER:
        case ENDPOINT_PARAM_TYPES.CURRENT_MONTH_NUMBER_ZERO_BASED:
        case ENDPOINT_PARAM_TYPES.CURRENT_MONTH_NUMBER_ONE_BASED:
        case ENDPOINT_PARAM_TYPES.CURRENT_MONTH_NAME_THREE_CHARACTERS: {
          assignmentType = ASSIGNMENT_TYPES.PREDEFINED_DYNAMIC;
          assignmentValue = param.type;
          predefinedValeHint = this.translate.instant(
            'configurable-fields.formula-builder.define-integration.predefined-static-hint',
            { predefinedValue: getPreDefinedDynamicValues(param.type) }
          );
          disable = true;
          break;
        }
      }

      this.fields.push({
        parameterName: new FormControl(paramName, Validators.required),
        assignmentType: new FormControl(assignmentType, Validators.required),
        assignmentValue: new FormControl(assignmentValue, Validators.required),
        predefinedValeHint: new FormControl(predefinedValeHint),
        disable: new FormControl(disable),
      });
    };

    this.endpoint.path_params.forEach((param) => {
      pushField(param);
    });
    this.endpoint.query_params.forEach((param) => {
      pushField(param);
    });

    this.runtimeCollectableFields = enumToKeyValuePairs(
      RUNTIME_COLLECTABLE_FIELDS,
      (key) => titleCasePipe.transform(key.replace(/_/g, ' '))
    );
  }

  ngOnInit(): void {
    this.referenceCategoryService.activeCategories
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((activeCategories) => {
        this.categoryArray = activeCategories;
      });
    if (this.data.integration.params.length > 0) {
      this.data.integration.params.forEach((param) => {
        const field = this.fields.find(
          (field) => field.parameterName.value === param.parameterName
        );
        if (field) {
          field.assignmentType.setValue(param.assignmentType);
          field.assignmentValue.setValue(param.assignmentValue);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async onChangeCompareWithField(index: number) {
    const field = this.fields?.[index];

    if (!field) return;

    field.assignmentValue.setValue(undefined);
    field.assignmentValue.markAsUntouched();
  }

  addForm() {
    this.fields.push({
      parameterName: new FormControl('', Validators.required),
      assignmentType: new FormControl(
        ASSIGNMENT_TYPES.LOCAL,
        Validators.required
      ),
      assignmentValue: new FormControl('', Validators.required),
      predefinedValeHint: new FormControl(''),
      disable: new FormControl(false),
    });
  }

  removeForm(index: number) {
    this.fields.splice(index, 1);
    if (this.fields.length === 0) {
      this.addForm();
    }
  }

  save() {
    const newFields: IFormulaIntegrationElementParam[] = this.fields
      .map(({ assignmentValue, assignmentType, parameterName }) => {
        if (
          assignmentValue.valid &&
          assignmentType.valid &&
          parameterName.valid
        ) {
          return {
            parameterName: parameterName.value,
            assignmentType: assignmentType.value,
            assignmentValue: assignmentValue.value,
          };
        }
        return null;
      })
      .filter(Boolean);

    this.dialogRef.close(newFields);
  }

  isAllValid() {
    const valid = this.fields.every(
      ({ assignmentValue, assignmentType, parameterName }) => {
        return (
          assignmentValue.valid && assignmentType.valid && parameterName.valid
        );
      }
    );
    return !valid;
  }
}
