<div *ngIf="isLoading">
  <app-widgets-container-skeleton></app-widgets-container-skeleton>
</div>
<div *ngIf="!isLoading">
  <div class="grid-stack-container">
    <gridstack [options]="gridOptions"></gridstack>
  </div>
  <button
    (click)="openDialog()"
    mat-stroked-button
    class="w-100 d-flex mt-2 align-items-center justify-content-center py-2"
  >
    <mat-icon class="mr-1">widgets</mat-icon
    >{{ 'widgets.container.manage-widgets' | translate }}
  </button>
</div>
