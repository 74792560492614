<mat-card-title mat-dialog-title class="d-flex align-items-center mb-0">
  <mat-icon class="mr-2">{{ popup_icon }}</mat-icon>
  {{ popup_title }}
</mat-card-title>
<mat-card-subtitle class="popup-subtitle mb-1"
  >{{ popup_subtitle }}
</mat-card-subtitle>
<mat-divider></mat-divider>

<mat-dialog-content class="mt-3 mr-0 ml-0 dialog-content-box">
  <div [innerHTML]="richTextContent"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">
    {{ 'identities.choose-app-to-connect.close-btn' | translate }}
  </button>
</mat-dialog-actions>
